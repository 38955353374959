import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, tap, switchMap, catchError, of, Subject } from "rxjs";
import { ActivityAreas } from "src/app/constants";
import { SubjectEvents } from "src/app/constants/subject-events.constant";
import {
	HealthCenterSelectionItem,
	PrescriberPatientsSelection,
} from "src/app/models/prescribers/prescriber-patients-selection";
import { AuthService } from "src/app/services/auth.service";
import { Logger } from "src/app/services/logger.service";
import { MedicalUsersService } from "src/app/services/medical-users.service";

const log = new Logger("HealthCenterSelectionComponent");

@Component({
	selector: "app-health-center-selector",
	templateUrl: "./health-center-selector.component.html",
})
export class HealthCenterSelectorComponent implements OnInit {
	private readonly authService = inject(AuthService);
	private readonly formBuilder = inject(FormBuilder);
	private readonly medicalUsersService = inject(MedicalUsersService);

	@Output() readonly healthCenterSelectorActivation = new EventEmitter<boolean>();
	@Output() readonly prescriberIdChange = new EventEmitter<string>();
	@Output() readonly prescriberPhoneChange = new EventEmitter<string>();
	@Input() forceOneColumn = false;

	formGroup!: FormGroup;
	isActive = true;
	isComponentLoading = true;
	isComponentOnError = false;
	isScyova = false;
	isRespiratory = false;
	availablePrescriberIds: string[] = [];
	healthCenters: HealthCenterSelectionItem[] = [];

	private _onChangeSubject$: Subject<string> = new Subject<string>();
	prescriberSelectionData$: Observable<PrescriberPatientsSelection | undefined> = this._onChangeSubject$
		.asObservable()
		.pipe(
			tap(_ => {
				this.isComponentLoading = true;
				this.isComponentOnError = false;
			}),
			switchMap(() =>
				this.medicalUsersService
					.getPrescriberCards(this.authService.user.currentMedicalUserId, this.authService.user.activityArea)
					.pipe(
						catchError((err: any) => {
							this.isComponentOnError = true;
							log.error(err);
							return of(undefined);
						})
					)
			),
			tap(e => {
				if (e) {
					this.isActive =
						e.nbTotalWorkplaces > 1 &&
						((this.isScyova && e.nbScyovaWorkplaces > 1) ||
							(this.isRespiratory && e.nbRespiratoryWorkplaces > 1));
					this.healthCenters = e.healthCenters;

					const prescriberId = e.messagePreferencePrescriberId ?? this.authService.user.scopePrescriberIds[0];

					this.availablePrescriberIds = e.availablePrescriberIds;
					this.healthCenterSelectorActivation.emit(this.isActive);
					this.prescriberIdChange.emit(prescriberId);

					setTimeout(() => {
						this.patchHealthCenterId(prescriberId);
						this.patchPrescriberId(prescriberId);
					}, 0);
				}
				this.isComponentLoading = false;
			})
		);

	ngOnInit(): void {
		setTimeout(() => this._onChangeSubject$.next(SubjectEvents.onInit), 0);
		this.formGroup = this.formBuilder.group({
			healthCenterId: ["", Validators.required],
			prescriberId: ["", Validators.required],
		});

		this.isScyova = this.authService.user.activityArea === ActivityAreas.scyova;
		this.isRespiratory = this.authService.user.activityArea === ActivityAreas.respiratory;
	}

	onHealthCenterSelectionChanged(_: any, healthCenterId: string): void {
		const healthCenter = this.healthCenters.find(hc => hc.id === healthCenterId);
		if (healthCenter && healthCenter.prescriberCards) {
			const ownerPrescriberId = healthCenter.prescriberCards.find(prescriber => prescriber.isOwner)!.id;
			const ownerPrescriberPhone = healthCenter.prescriberCards.find(prescriber => prescriber.isOwner)!.phone;

			this.prescriberPhoneChange.emit(ownerPrescriberPhone);
			this.prescriberIdChange.emit(ownerPrescriberId);
		}
	}

	onPrescriberSelectionChanged(_: any, prescriberId: string): void {
		this.prescriberIdChange.emit(prescriberId);
	}

	private patchHealthCenterId(prescriberId: string): void {
		this.healthCenters.forEach(hc => {
			if (hc.prescriberCards?.find(prescriber => prescriber.id === prescriberId)) {
				this.formGroup.patchValue({ healthCenterId: hc.id, prescriberId: prescriberId });
				this.onHealthCenterSelectionChanged(null, hc.id);
			}
		});
	}

	private patchPrescriberId(prescriberId: string): void {
		this.onPrescriberSelectionChanged(null, prescriberId);
		this.formGroup.patchValue({ prescriberId: prescriberId });
	}

	selectHealthCenter(prescriberId: string) {
		this.patchHealthCenterId(prescriberId);
	}
}
