import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { TableItem, TableView } from "src/app/@shared/types/table/table-view";
import { TableCellTypes } from "src/app/@shared/constants/table-cell-types.constant";
import { TableMatPaginatorIntl } from "src/app/@shared/components/table/table-paginator/table-standard-paginator";
import { TableStandardActionButton } from "./table-standard-action-button";

@Component({
	selector: "app-table-standard",
	templateUrl: "table-standard.component.html",
})
export class TableStandardComponent extends TableMatPaginatorIntl {
	protected tableCellTypes = TableCellTypes;

	@ViewChild(MatPaginator)
	protected paginator!: MatPaginator;

	@Input() isLoadingResults = true;
	@Input() isTableHeaderSmall? = false;
	@Input() isTableHasNoActions? = false;
	@Input() isTableRowWithLink? = false;
	@Input() isOnError = false;
	@Input() items?: TableItem[];
	@Input() nbTotalFilteredItems = 0;
	@Input() nbTotalItems = 0;
	@Input() noRowLabel = "Aucun élément trouvé";
	@Input() activatePagination = true;
	@Input() paginationLabel = "Sélectionner la page des éléments";
	@Input() pageSizeOptions = [20, 50, 100];
	@Input() selectAllButtonLabel = "Action";
	@Input() selectAllLabel = "Sélectionner tous les éléments";
	@Input() tableView?: TableView;
	@Input() displayHeaders = true;
	@Input() actionButtons: TableStandardActionButton[] = [];
	@Output() tableChange = new EventEmitter<boolean>();
	@Output() selectAllButtonClick = new EventEmitter<string[]>();

	get selectedIds(): string[] {
		return this.items?.filter(e => e.isSelected && e.id).map(e => e.id) ?? [];
	}

	get nbSelectedItems(): number {
		return this.selectedIds.length;
	}

	reloadTable(resetPageIndex: boolean): void {
		if (resetPageIndex) this.paginator.pageIndex = 0;

		if (this.tableView) {
			this.tableView.pageIndex = this.paginator.pageIndex;
			this.tableView.pageSize = this.paginator.pageSize;
		}

		this.tableChange.emit(true);
	}

	toggleSelectAllRows(): void {
		if (!this.tableView) return;

		this.tableView.isSelectAllItems = !this.tableView.isSelectAllItems;

		if (!this.items) return;

		this.items.forEach(e => (e.isSelected = this.tableView!.isSelectAllItems));
	}

	doSelectAllButtonAction(): void {
		this.selectAllButtonClick.emit(this.selectedIds);
	}
}
