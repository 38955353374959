import { Component } from "@angular/core";

@Component({
	selector: "app-fiche-integration-html",
	templateUrl: "fiche-integration-html-v2.component.html",
	styleUrls: ["./fiche-integration-html.component.scss"],
})
export class FicheIntegrationHtmlComponent {
	isMenuNoteOpen = false;
	isMenuXSOpen = false;
	isMenuOpen = false;
	isTagOpen = false;

	constructor() {}

	openCloseGraph(): void {
		this.isMenuOpen = !this.isMenuOpen;
	}
	openCloseXSMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isMenuXSOpen = false;
			return;
		}

		this.isMenuXSOpen = !this.isMenuXSOpen;
	}

	openCloseNoteMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isMenuNoteOpen = false;
			return;
		}

		this.isMenuNoteOpen = !this.isMenuNoteOpen;
	}

	goToHeadOfPage() {
		const x = document.querySelector("#content");
		if (x) {
			x.scrollIntoView();
		}
	}

	btnTag(forceToClose: boolean): void {
		if (forceToClose) {
			this.isTagOpen = false;
			return;
		}
		this.isTagOpen = !this.isTagOpen;
	}
}
