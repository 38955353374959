export class HealthCenterTypes {
	static readonly assistedLivingFacilities: string = "assisted-living-facilities";
	static readonly doctors: string = "doctors";
	static readonly homeHealthProviders: string = "home-health-providers";
	static readonly homeMedicalCare: string = "home-medical-care";
	static readonly insuranceCompanies: string = "insurance-companies";
	static readonly laboratories: string = "laboratories";
	static readonly medicalCenters: string = "medical-centers";
	static readonly others: string = "others";
	static readonly unknown: string = "unknown";

	static formatMessage(message: string, ...params: any[]): string {
		const regexp = /{(\d+)}/g;
		return message.replace(regexp, function (match, number): string {
			return number < params.length && typeof params[number] != "undefined" ? params[number] : "";
		});
	}

	static readonly messageDialogNewMember: string =
		"Je souhaite le rattachement d'une personne à un établissement :  \n\n \
	- Nom de l'établissement : {0} \n \
	- Nom de la personne :  \n \
	- Prénom de la personne : \n \
	- RPPS si compte prescripteur : \n \
	- Email si compte secrétaire :";

	static readonly messageDialogRemoveMember: string =
		"Je souhaite le retrait d'une personne à un établissement : \n\n \
	- Nom de l'établissement : {0} \n \
	- Nom de la personne : {1} \n \
	- Prénom de la personne : {2}\n \
	- RPPS : {3}";

	static readonly messageDialogNewHealthCenter: string =
		"Je souhaite l’activation ou la création de l'établissement ci-dessous : \n \n \
	- Nom de l'établissement : \n \
	- Type d'établissement : \n \
	- Adresse de l'établissement :";

	static readonly messageDialogType: string = "newHealthCenter";

	static readonly allAvailables: string[] = [
		HealthCenterTypes.assistedLivingFacilities,
		HealthCenterTypes.doctors,
		HealthCenterTypes.homeHealthProviders,
		HealthCenterTypes.homeMedicalCare,
		HealthCenterTypes.insuranceCompanies,
		HealthCenterTypes.laboratories,
		HealthCenterTypes.medicalCenters,
		HealthCenterTypes.others,
		HealthCenterTypes.unknown,
	];

	static readonly singularLabelMappingRecords: Record<string, string> = {
		[HealthCenterTypes.assistedLivingFacilities]: "EHPAD",
		[HealthCenterTypes.doctors]: "Médecin",
		[HealthCenterTypes.homeHealthProviders]: "PSAD",
		[HealthCenterTypes.homeMedicalCare]: "HAD",
		[HealthCenterTypes.insuranceCompanies]: "Mutuelle",
		[HealthCenterTypes.laboratories]: "Laboratoire",
		[HealthCenterTypes.medicalCenters]: "Centre hospitalier",
		[HealthCenterTypes.others]: "Autre",
		[HealthCenterTypes.unknown]: "Inconnu",
	};

	static readonly iconMappingRecords: Record<string, string> = {
		[HealthCenterTypes.assistedLivingFacilities]: "icon-clinique",
		[HealthCenterTypes.doctors]: "icon-cabinet",
		[HealthCenterTypes.homeHealthProviders]: "icon-cabinet",
		[HealthCenterTypes.homeMedicalCare]: "icon-cabinet",
		[HealthCenterTypes.insuranceCompanies]: "icon-cabinet",
		[HealthCenterTypes.laboratories]: "icon-cabinet",
		[HealthCenterTypes.medicalCenters]: "icon-patients-hospitalises",
		[HealthCenterTypes.others]: "icon-cabinet",
		[HealthCenterTypes.unknown]: "icon-cabinet",
	};
}
