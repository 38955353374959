import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";

export type ValidationResult = Record<string, any>;

export class UserValidator {
	/**
	 * Validate an email address
	 * @returns {ValidatorFn}
	 */
	static emailValidator(): ValidatorFn {
		return (control: AbstractControl) => {
			if (!control?.value || !Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")(control)) {
				return null;
			}

			return { email: true };
		};
	}

	/**
	 * check if password is strong enough
	 * Minimum six characters, at least one uppercase letter,
	 * one lowercase letter, one number and one special char
	 * @returns
	 */
	static passwordsComplexityValidator(): ValidatorFn {
		const passwordRegex =
			/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\])(?=.{8,})\S+$/;

		return (control: AbstractControl) => {
			if (!control?.value || !passwordRegex.test(control?.value)) {
				control.setErrors({ required: true });
				return { passwordComplexity: true };
			}

			control.setErrors(null);
			return null;
		};
	}

	/**
	 *
	 * @param controlName
	 * @param matchControlName
	 * @returns
	 */
	static passwordMatchingValidator(controlName: string, matchControlName: string): ValidatorFn {
		return (controls: AbstractControl) => {
			const control = controls.get(controlName);
			const matchControl = controls.get(matchControlName);

			if (!matchControl?.errors && control?.value !== matchControl?.value) {
				matchControl?.setErrors({
					matching: {
						actualValue: matchControl?.value,
						requiredValue: control?.value,
					},
				});
				return { matching: true };
			}
			return null;
		};
	}
}
