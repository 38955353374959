<div
	class="d-flex align-items-center justify-content-center align-items-center"
	*ngIf="item"
	style="position: relative">
	<ng-container [ngSwitch]="item.comparorType">
		<ng-container *ngSwitchCase="'date'">
			<div class="d-flex">
				<span *ngIf="item.value === null" class="obs-green"> {{ valueItems[0] }} </span>
				<span *ngIf="item.value" class="text-medium obs-red"> {{ valueItems[1] }} </span>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="'boolean'">
			<div class="d-flex">
				<span *ngIf="item.value === true" class="obs-green"> {{ valueItems[0] }} </span>
				<span *ngIf="item.value === false" class="text-medium obs-red"> {{ valueItems[1] }} </span>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="'string'">
			<div class="d-flex">
				<span *ngIf="item.value === 'true'" class="obs-green"> {{ valueItems[0] }} </span>
				<span *ngIf="item.value === 'false'" class="text-medium obs-red"> {{ valueItems[1] }} </span>
			</div>
		</ng-container>
	</ng-container>
</div>
