import { Injectable } from "@angular/core";
import { Observable, catchError, of } from "rxjs";
import { FileAccessToken } from "../models/files/file-access-token";
import { Logger } from "./logger.service";
import { FilesService } from "./files.service";
import { AppConfigService, AuthService, NotificationService } from ".";
import { DocumentItem } from "../models";
import { HttpClient } from "@angular/common/http";

const log = new Logger("DocumentsService");

@Injectable({
	providedIn: "root",
})
export class DocumentsService {
	constructor(
		private _authService: AuthService,
		private _fileService: FilesService,
		private _notificationService: NotificationService,
		private _appConfigService: AppConfigService,
		private _httpClient: HttpClient
	) {}

	getPatientPolyDocuments(patientId: string): Observable<DocumentItem[]> {
		return this._httpClient.get<DocumentItem[]>(`/files/documents/patient/${patientId}/polysomnography-documents`);
	}

	downloadDocument(documentId: string, documentName: string, documentDate: Date, eventDate?: Date): void {
		this.securelyDownload("files/documents/", documentId, documentName, documentDate, undefined, eventDate);
	}

	downloadDocumentWithAccessId(accessId: string, fileName: string): void {
		const rootPath = this._appConfigService.appConfig.hostApi;
		const url = `${rootPath}/files/documents/${accessId}/${fileName}`;
		window.open(url, "_blank");
	}

	getDocumentUrl(accessId: string, fileName: string): string {
		const rootPath = this._appConfigService.appConfig.hostApi;
		return `${rootPath}/files/documents/${accessId}/${fileName}`;
	}

	getNewTabDocumentUrl(accessId: string, fileName: string): string {
		const rootPath = this._appConfigService.appConfig.hostApi;
		return `${rootPath}/files/documents/${accessId}/tab/${fileName}`;
	}

	downloadPatientInteractionDocument(
		documentId: string,
		documentName: string,
		documentDate: Date,
		patientFullName?: string,
		eventDate?: Date
	): void {
		this.securelyDownload(
			"files/patient-interaction-documents/",
			documentId,
			documentName,
			documentDate,
			patientFullName,
			eventDate
		);
	}

	private securelyDownload(
		path: string,
		documentId: string,
		documentName: string,
		documentDate: Date,
		patientFullName?: string,
		eventDate?: Date
	): void {
		const rootPath = this._appConfigService.appConfig.hostApi;

		this._fileService
			.getEphemeralFileAccessTokenId(documentId, this._authService.user.currentMedicalUserId)
			.pipe(
				catchError(error => {
					log.debug(error);
					this._notificationService.showErrorMessage("Erreur lors de la récupération du document");
					return of(undefined);
				})
			)
			.subscribe((fileAccessToken: FileAccessToken | undefined) => {
				if (fileAccessToken) {
					let url =
						`${rootPath}/${path}` +
						`${fileAccessToken.ephemeralFileAccessTokenId}/` +
						`${documentName}/?` +
						`fileDate=${documentDate}`;

					if (patientFullName) {
						url += `&patientFullName=${patientFullName}`;
					}

					if (eventDate) {
						url += `&eventDate=${eventDate}`;
					}

					window.open(url);
				}
			});
	}
}
