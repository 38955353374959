import { Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppConfig, AppVersion } from "../types/app-config";
import { environment } from "../../environments/environment";
import { CacheKeys } from "../constants/cache-keys.constant";
import { StorageService } from "./storage.service";

declare let require: any;

@Injectable({
	providedIn: "root",
})
export class AppConfigService {
	private readonly storageService = inject(StorageService);

	private _appConfig?: AppConfig;
	get appConfig(): AppConfig {
		if (!this._appConfig) return this.buildAppConfig();
		return this._appConfig;
	}

	get isPreviewMode(): boolean {
		const isPreviewMode = this.storageService.retrieveCacheAsBoolean(CacheKeys.isPreviewMode);
		if (!isPreviewMode) {
			this.storageService.saveCacheValue(CacheKeys.isPreviewMode, false);
		}
		return isPreviewMode;
	}

	private _onPreviewModeChange$ = new BehaviorSubject<boolean>(this.isPreviewMode);
	onPreviewModeChange$ = this._onPreviewModeChange$.asObservable();

	buildAppConfig(): AppConfig {
		return new AppConfig({
			amCharts5LicenseKey: environment.amCharts5LicenseKey,
			appCompanyName: environment.appCompanyName,
			appCompanyUrl: environment.appCompanyUrl,
			appDisplayName: environment.appDisplayName,
			appDomain: environment.appDomain,
			appName: environment.appName,
			clientId: environment.clientId,
			clientRoot: environment.clientRoot,
			clientSecret: environment.clientSecret,
			hostApi: environment.hostApi,
			hostAuth: environment.hostAuth,
			hostHub: environment.hostHub,
			isFeaturePreviewOn: this.transformToBoolean(environment.isFeaturePreviewOn),
			pandalabProject: environment.pandalabProject,
			version: new AppVersion({
				build: require("../../../package.json").version,
				name: require("../../../package.json").versionName,
				version: require("../../../package.json").versionNumber,
			}),
			secureRoutes: environment.secureRoutes.split(";"),
			userSessionNbMinutes: this.transformToNumber(environment.userSessionNbMinutes),
		});
	}

	changePreviewMode(): void {
		this.storageService.saveCacheValue(CacheKeys.isPreviewMode, this.isPreviewMode);
		this._onPreviewModeChange$.next(this.isPreviewMode);
	}

	private transformToBoolean(value: string): boolean {
		return value === "true";
	}

	private transformToNumber(value: string): number {
		if (!value) return 0;
		return +value;
	}
}
