export class PrescriberRole {
	static readonly prescriber: string = "prescriber";
	static readonly specialist: string = "specialist-prescriber";
	static readonly renewal: string = "renewal-prescriber";
}

export class PrescriptionSigningStatus {
	static readonly toSignComingSoon: string = "to-sign-early;to-sign-future";
	static readonly toSignEarly: string = "to-sign-early";
	static readonly toSignFuture: string = "to-sign-future";
	static readonly inProgress: string = "in-progress";
	static readonly toSignPast: string = "to-sign-past";
	static readonly upToDate: string = "up-to-date";
	static readonly unknown: string = "unknown";

	public static determineDisplayStatus(status: string): string {
		switch (status) {
			case PrescriptionSigningStatus.upToDate:
				return "À jour";
			case PrescriptionSigningStatus.inProgress:
				return "Validation en cours";
			case PrescriptionSigningStatus.toSignPast:
				return "À signer";
			case PrescriptionSigningStatus.toSignEarly:
			case PrescriptionSigningStatus.toSignFuture:
				return "À venir";
			case PrescriptionSigningStatus.unknown:
				return "Indéterminé";
			default:
				return status;
		}
	}
}

export class PrescriptionRenewalType {
	static readonly change: string = "modification";
	static readonly initial: string = "init";
	static readonly annualRenew: string = "annual-renew";
	static readonly firstRenew: string = "first-renew";

	public static determineDisplayType(type: string): string {
		switch (type) {
			case PrescriptionRenewalType.change:
				return "Changement";
			case PrescriptionRenewalType.initial:
				return "Initial";
			case PrescriptionRenewalType.annualRenew:
				return "Renouvellement";
			case PrescriptionRenewalType.firstRenew:
				return "1er renouvellement";
			default:
				return type;
		}
	}
}

export class ScyovaProgramStateLabels {
	static readonly activeProgram: string = "Programme actif";
	static readonly prematureTermination: string = "Arrêt prématuré du programme";
	static readonly programCompleted: string = "Programme achevé";
	static readonly programStartWithoutPump: string = "Début de programme sans mise sous pompe";
}

export class ScyovaProgramStateCodes {
	static readonly activeProgram: string = "active-program";
	static readonly prematureTermination: string = "premature-termination";
	static readonly programCompleted: string = "program-completed";
	static readonly programStartWithoutPump: string = "program-start-without-pump";

	static readonly labelMappings: Record<string, string> = {
		[ScyovaProgramStateCodes.activeProgram]: ScyovaProgramStateLabels.activeProgram,
		[ScyovaProgramStateCodes.prematureTermination]: ScyovaProgramStateLabels.prematureTermination,
		[ScyovaProgramStateCodes.programCompleted]: ScyovaProgramStateLabels.programCompleted,
		[ScyovaProgramStateCodes.programStartWithoutPump]: ScyovaProgramStateLabels.programStartWithoutPump,
	};
	static readonly cssPictogramMappings: Record<string, string | null> = {
		[ScyovaProgramStateCodes.activeProgram]: null,
		[ScyovaProgramStateCodes.prematureTermination]: null,
		[ScyovaProgramStateCodes.programCompleted]: "icon-check-file",
		[ScyovaProgramStateCodes.programStartWithoutPump]: null,
	};
	static readonly cssColorMappings: Record<string, string | null> = {
		[ScyovaProgramStateCodes.activeProgram]: "obs-green",
		[ScyovaProgramStateCodes.prematureTermination]: "obs-red",
		[ScyovaProgramStateCodes.programCompleted]: "icon-check-file text-green",
		[ScyovaProgramStateCodes.programStartWithoutPump]: "obs-blue",
	};
	static readonly cssStatusCodeMappings: Record<string, string> = {
		[ScyovaProgramStateCodes.activeProgram]: "scyova-program-state-active",
		[ScyovaProgramStateCodes.prematureTermination]: "scyova-program-state-premature-termination",
		[ScyovaProgramStateCodes.programCompleted]: "scyova-program-state-active",
		[ScyovaProgramStateCodes.programStartWithoutPump]: "scyova-program-state-without-pump",
	};
}

export class ScyovaDocumentTypeCodes {
	static readonly empowermentGrid: string = "empowerment-grid";
	static readonly medicalInstruction: string = "medical-instruction";
	static readonly report: string = "report";

	static readonly iconMappings: Record<string, string> = {
		[ScyovaDocumentTypeCodes.empowermentGrid]: "icon-table-2",
		[ScyovaDocumentTypeCodes.medicalInstruction]: "",
		[ScyovaDocumentTypeCodes.report]: "icon-ordonance",
	};
}

export class PrescriptionDocumentTypeCodes {
	static readonly DAP: string = "dap";
	static readonly DEP: string = "dep";
	static readonly Ordo: string = "ordo";
}

export class PrescriptionSignaturePlatforms {
	static readonly amelink: string = "amelink";
	static readonly pandalab: string = "pandalab";

	static isAmelink(platform: string | null | undefined): boolean {
		return platform ? platform === this.amelink : false;
	}

	static isPandalab(platform: string | null | undefined): boolean {
		return platform ? platform === this.pandalab : false;
	}
}
