import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import moment from "moment";

export function DateRangeValidator(
	startDateControlName: string,
	endDateControlName: string,
	nbDays: number,
	maxStartDateAllowed: Date | null,
	minEndDateAllowed: Date | null
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!startDateControlName || !endDateControlName || !nbDays) {
			return null;
		}

		const startDateControl = control.get(startDateControlName);
		const endDateControl = control.get(endDateControlName);

		if (!startDateControl || !endDateControl) {
			return null;
		}

		const startDate = startDateControl.value;
		const endDate = endDateControl.value;

		if (startDate && maxStartDateAllowed) {
			const isStartDateAllowed = moment(startDate).diff(moment(maxStartDateAllowed), "days") <= 0;
			if (!isStartDateAllowed) return { wrongMaxStartDate: true };
		}

		if (endDate && minEndDateAllowed) {
			const isEndDateAllowed = moment(endDate).diff(moment(minEndDateAllowed), "days") >= 0;
			if (!isEndDateAllowed) return { wrongMinEndDate: true };
		}

		if (startDate && endDate) {
			const isDateRangeAllowed = Math.abs(moment(endDate).diff(moment(startDate), "days")) <= nbDays;
			return isDateRangeAllowed ? null : { wrongDateRange: true };
		}

		return null;
	};
}
