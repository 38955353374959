<div class="row py-3">
	<div class="col-12">
		<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0">
			Pas encore de messages envoyés à
			<span class="text-capitalize">{{ appConfig.appCompanyName }}</span> ?
		</span>
	</div>

	<div class="col-12">
		<div class="row">
			<div class="fs-wide-screen">
				<div class="bg-white position-relative rounded shadow p-3 overflow-hidden">
					<div class="w-60 w-xs-100 position-relative z-index-2">
						<p>
							À ce jour vous n’avez pas encore de messages à
							<span class="text-capitalize">{{ appConfig.appCompanyName }}</span
							>.
						</p>
						<p class="mb-0">
							Si vous souhaitez nous contacter n’hésitez pas à utiliser l’espace dédié à cet effet ou à
							téléphoner à votre agent de terrain privilégié :
						</p>
						<div class="d-flex justify-content-center justify-content-sm-end">
							<div class="d-flex">
								<button
									[ngClass]="{ disabled: !(isPrescriber || isSuperAdmin || isSecretary) }"
									class="btn btn-circle d-flex"
									(click)="openMessageDialog()">
									<span class="pin-circle btn pin-circle-xl">
										<i
											class="icon-message fs-4 position-absolute top-50 start-50 translate-middle"></i>
									</span>
									<span
										class="align-self-center rounded-pill border color-primary border-2 py-1 px-3 ps-5 margin-left-m40 text-uppercase text-medium">
										Envoyer un message
									</span>
								</button>
							</div>
						</div>
						<p>
							Dans cette page, vous retrouverez l’ensemble des messages que vous nous enverrez. Tout sera
							détaillé : l’objet, la date et le contenu de l’envoi.
						</p>
					</div>
					<div class="position-absolute end-0 bottom-0 z-index-1 text-end d-none d-sm-block">
						<img
							src="assets/img/bg-no-messages-{{ appConfig.appCompanyName }}.png"
							class="img-fluid w-70"
							alt="Vous n'avez pas de message" />
					</div>
					<div class="position-absolute end-0 bottom-0 z-index-1 text-end d-block d-sm-none">
						<img
							src="assets/img/dashboard/bg-whiteboard.png"
							class="img-fluid w-70 position-relative bg-no-list"
							alt="Vous n'avez pas de message" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
