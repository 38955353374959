<div
	*ngIf="item"
	class="d-flex align-items-center justify-content-center align-items-center"
	style="position: relative">
	<span
		[ngClass]="item.textCssClass"
		class="text-nowrap text-bold"
		style="align-items: center; display: flex; flex-flow: column">
		<span>Du {{ item.startDate | date: "dd/MM/yyyy" }}</span>
		<span *ngIf="item.endDate"> au {{ item.endDate | date: "dd/MM/yyyy" }} </span>
		<span *ngIf="item.subValue">
			{{ item.subValue }}
		</span>
	</span>
</div>
