<div *ngIf="item" class="d-flex align-items-center justify-content-center align-items-center">
	<span class="text-wrap w-100">
		<div class="flex-grow-1 align-self-center" [ngClass]="item.textCssClass">
			<ng-container *ngIf="item.forceCasing">
				{{ item.value | titlecase }}
			</ng-container>
			<ng-container *ngIf="!item.forceCasing">
				{{ item.value }}
			</ng-container>

			<span *ngIf="item.importantValue" class="text-bold">{{ item.importantValue | uppercase }}</span>
		</div>
	</span>
</div>
