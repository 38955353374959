import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, tap } from "rxjs";
import { LegalDocumentTypeCodes } from "src/app/constants";
import {
	UserAgreementLegalDocument,
	UserAgreementStatus,
	AcceptLegalDocument,
	AcceptLegalDocumentRequest,
} from "src/app/models";
import { TransformDateForObservable } from "src/app/decorators";
import { Logger } from "./logger.service";

const log = new Logger("AgreementsService");

@Injectable({
	providedIn: "root",
})
export class AgreementsService {
	private _userTermsAndConditionsAgreementStatusSubject = new ReplaySubject<UserAgreementStatus>(1);
	userTermsAndConditionsAgreementStatus$: Observable<UserAgreementStatus> =
		this._userTermsAndConditionsAgreementStatusSubject.asObservable();

	private _termsAndConditionsAcceptanceSubject = new ReplaySubject<string | null>(1);
	termsAndConditionsAcceptance$: Observable<string | null> = this._termsAndConditionsAcceptanceSubject.asObservable();

	private _currentUserAgreementLegalDocument?: UserAgreementLegalDocument;

	get currentUserAgreementLegalDocument(): UserAgreementLegalDocument | undefined {
		return this._currentUserAgreementLegalDocument;
	}

	constructor(private _httpClient: HttpClient) {}

	@TransformDateForObservable
	getUserTermsAndConditionsAgreementStatus(): Observable<UserAgreementStatus> {
		return this._httpClient.get<UserAgreementStatus>(
			`/agreements/types/${LegalDocumentTypeCodes.termsAndConditions}/user-status`
		);
	}

	getLegalDocument(documentType: LegalDocumentTypeCodes): Observable<UserAgreementLegalDocument> {
		return this._httpClient.get<UserAgreementLegalDocument>(`/agreements/types/${documentType}`);
	}

	acceptLegalDocument(legalDocumentId: string): Observable<AcceptLegalDocument> {
		const command = { legalDocumentId } as AcceptLegalDocumentRequest;
		return this._httpClient
			.post<AcceptLegalDocument>(`/agreements/accept`, command)
			.pipe(tap(acceptance => this._termsAndConditionsAcceptanceSubject.next(acceptance.id)));
	}

	ensureUserTermsAndConditionsAgreement(): void {
		// no need to remove subscription --> one time call
		this.getUserTermsAndConditionsAgreementStatus().subscribe(userAgreementStatus => {
			log.info(`Agreement acceptance needed: ${userAgreementStatus.isAgreementAcceptanceRequired}`);
			this._currentUserAgreementLegalDocument = userAgreementStatus.currentLegalDocument;
			this._userTermsAndConditionsAgreementStatusSubject.next(userAgreementStatus);

			if (!userAgreementStatus.isAgreementAcceptanceRequired) {
				this._termsAndConditionsAcceptanceSubject.next(null);
			}
		});
	}
}
