import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import {
	BrowseRespiratoryPatientsCommand,
	PatientCard,
	PatientDetailsItem,
	RespiratoryPatientAnalysis,
	SearchPatientsResult,
	DetermineTreatmentsSigningPlatformsRequest,
	DetermineSigningPlatformResponse,
	DeterminePrescriptionsSigningPlatformsRequest,
	BrowsePrescriptionsByPrescriberCommand,
	PagerPrescription,
} from "src/app/models";
import { URLEncoder } from "src/app/types";

@Injectable({
	providedIn: "root",
})
export class RespiratoryMedicalUsersService {
	private readonly httpClient = inject(HttpClient);

	browsePatients(
		medicalUserId: string,
		command: BrowseRespiratoryPatientsCommand
	): Observable<RespiratoryPatientAnalysis> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
			fromString: command.generateUrlQuery(),
		});

		return this.httpClient.get<RespiratoryPatientAnalysis>(
			`/respiratory/medical-users/${medicalUserId}/patients/browse`,
			{
				params: params,
			}
		);
	}

	browsePrescriptionsByPrescriber(
		medicalUserId: string,
		command: BrowsePrescriptionsByPrescriberCommand
	): Observable<PagerPrescription> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});

		return this.httpClient.get<PagerPrescription>(
			`/respiratory/medical-users/${medicalUserId}/prescriptions/browse`,
			{
				params: params,
			}
		);
	}

	searchPatientsByTerms(
		medicalUserId: string,
		terms: string,
		forcedPrescriberIds: string[] = []
	): Observable<SearchPatientsResult> {
		let params = new HttpParams().set("terms", terms);

		for (const id of forcedPrescriberIds) {
			params = params.append("forcedPrescriberIds", id);
		}

		return this.httpClient.get<SearchPatientsResult>(
			`/respiratory/medical-users/${medicalUserId}/patients/search`,
			{
				params: params,
			}
		);
	}

	getPatientCard(medicalUserId: string, patientId: string): Observable<PatientCard> {
		return this.httpClient.get<PatientCard>(
			`/respiratory/medical-users/${medicalUserId}/patients/${patientId}/card`
		);
	}

	getPatientDetailsByPatient(medicalUserId: string, patientId: string): Observable<PatientDetailsItem> {
		return this.httpClient.get<PatientDetailsItem>(
			`/respiratory/medical-users/${medicalUserId}/patients/${patientId}/details`
		);
	}

	determineTreatmentSigningPlatforms(
		medicalUserId: string,
		request: DetermineTreatmentsSigningPlatformsRequest
	): Observable<DetermineSigningPlatformResponse> {
		const options = {
			params: request.getHttpParams(),
		};
		return this.httpClient.get<DetermineSigningPlatformResponse>(
			`/respiratory/medical-users/${medicalUserId}/signature/platforms/treatments`,
			options
		);
	}

	determinePrescriptionsSigningPlatforms(
		medicalUserId: string,
		request: DeterminePrescriptionsSigningPlatformsRequest
	): Observable<DetermineSigningPlatformResponse> {
		const options = {
			params: request.getHttpParams(),
		};
		return this.httpClient.get<DetermineSigningPlatformResponse>(
			`/respiratory/medical-users/${medicalUserId}/signature/platforms/prescriptions`,
			options
		);
	}
}
