<div>
	<div class="mx-auto w-50 d-flex flex-column text-center align-items-center justify-content-center mt-5">
		<div class="icon-logo-{{ appConfig.appCompanyName }} fs-45 mb-3">
			<span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
			><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
			><span class="path9"></span><span class="path10"></span><span class="path11"></span
			><span class="path12"></span><span class="path13"></span><span class="path14"></span
			><span class="path15"></span><span class="path16"></span><span class="path17"></span
			><span class="path18"></span><span class="path19"></span><span class="path20"></span
			><span class="path21"></span><span class="path22"></span><span class="path23"></span
			><span class="path24"></span><span class="path25"></span><span class="path26"></span
			><span class="path27"></span><span class="path28"></span><span class="path29"></span
			><span class="path30"></span><span class="path31"></span><span class="path32"></span>
		</div>
		<img src="assets/img/page-403-{{ appConfig.appCompanyName }}.svg" class="mb-3 img-fluid" alt="Forbidden" />
		<span class="fs-3 text-color-primary text-bold text-uppercase mb-3"> Accès refusé ! </span>
		<p>{{ forbiddenMessage }}</p>
	</div>
	<div class="d-flex justify-content-center mt-5">
		<button
			*ngIf="displayAdminRedirect"
			(click)="redirectToAdmin()"
			class="btn btn-primary d-inline-block d-lg-inline-block mt-3">
			Retour à l'impersonation
		</button>
		<button
			*ngIf="displayDashboardRedirect"
			(click)="redirectToDashboard()"
			class="btn btn-primary d-inline-block d-lg-inline-block mt-3">
			Retour au tableau de bord
		</button>
	</div>
</div>
