<div *ngIf="item" class="d-flex align-items-center justify-content-center align-items-center">
	<div class="text-nowrap" style="align-items: center; display: flex; flex-flow: column">
		<span *ngIf="item.firstLineValue" [ngClass]="item.invertBoldCase ? '' : 'text-bold'">
			{{ item.firstLineValue }}
		</span>
		<span *ngIf="item.firstLineValueAsDate" class="text-bold">
			{{ item.firstLineValueAsDate | date: "dd/MM/yyyy" }}
		</span>
		<span *ngIf="item.firstLineValueAsStringDate" class="text-bold">
			{{ item.firstLineValueAsStringDate | lunarDateString }}
		</span>
		<span *ngIf="item.secondLineValue" [ngClass]="item.invertBoldCase ? 'text-bold' : ''">
			{{ item.secondLineValue }}
		</span>
		<span *ngIf="item.secondLineValueAsDate">
			{{ item.secondLineValueAsDate | date: "dd/MM/yyyy" }}
		</span>
	</div>
</div>
