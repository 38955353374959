import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalPatientIndicatorsComponent } from "../prescriber/@shared/components/modals/modal-patient-indicators/modal-patient-indicators.component";

@Component({
	selector: "app-prescriber-integration-html",
	templateUrl: "prescriber-integration-html.component.html",
	styleUrls: ["./prescriber-integration-html.component.scss"],
})
export class PrescriberIntegrationHtmlComponent {
	isContactOpen = false;

	constructor(private _dialog: MatDialog) {}

	isTagOpen = false;

	openContactList(): void {
		this.isContactOpen = !this.isContactOpen;
	}

	openDialog(): void {
		this._dialog.open(ModalPatientIndicatorsComponent, {});
	}
}
