export class FormatTableColumnTypes {
	static readonly actions: string = "actions";
	static readonly actionsWithLabel: string = "actionsWithLabel";
	static readonly active: string = "active";
	static readonly comments: string = "comments";
	static readonly date: string = "date";
	static readonly dateTime: string = "dateTime";
	static readonly dateRange: string = "dateRange";
	static readonly dateRangeNullable: string = "dateRangeNullable";
	static readonly device: string = "device";
	static readonly displayPerson: string = "displayPerson";
	static readonly documents: string = "documents";
	static readonly duration: string = "duration";
	static readonly durationProgress: string = "durationProgress";
	static readonly healthCenterMembers: string = "healthCenterMembers";
	static readonly interaction: string = "interaction";
	static readonly isLocked: string = "isLocked";
	static readonly isLocalAdmin: string = "isLocalAdmin";
	static readonly messageStatus: string = "messageStatus";
	static readonly none: string = "none";
	static readonly patientInterventionType: string = "patientInterventionType";
	static readonly prescriptionStatus: string = "prescriptionStatus";
	static readonly readMoreText: string = "readMoreText";
	static readonly status: string = "status";
	static readonly select: string = "select";
	static readonly tag: string = "tag";
	static readonly text: string = "text";
	static readonly title: string = "title";
	static readonly treatmentTag: string = "treatmentTag";
	static readonly twoLines: string = "twoLines";
	static readonly twoLinesAddress: string = "twoLinesAddress";
	static readonly upper: string = "upper";
}
