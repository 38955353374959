import { Component, Input } from "@angular/core";
import { TableCellMultipleLinesString } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-multiple-lines-string",
	templateUrl: "table-cell-multiple-lines-string.component.html",
})
export class TableCellMultipleLinesStringComponent {
	@Input() item?: TableCellMultipleLinesString;
}
