import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthorityHttpParam } from "../interceptors/http-params/authority-http-param";
import { Observable } from "rxjs";
import { BrowseEventsRequest } from "../models/patients/browse-patient-event-by-prescriber";
import { URLEncoder } from "../types/url-encoder";
import {
	PagerUser,
	SearchSecretaryUserResponse,
	SendAccountActivationNotificationResponse,
	SwitchUserStatusCommand,
	UpdateMediviewUserPasswordResponse,
	UpdatePasswordUserMediviewCommand,
} from "src/app/models/users/user.interface";
import {
	AdminResetUsersCommand,
	CreateUserMediviewCommand,
	UpdateUserMediviewCommand,
	UserDataClaims,
} from "src/app/models/users/user.interface";

@Injectable({
	providedIn: "root",
})
export class UserProfileService {
	constructor(private _httpClient: HttpClient) {}

	browseUsers(command: BrowseEventsRequest): Observable<PagerUser> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerUser>(`/users/browse`, {
			params: params,
		});
	}

	updateUser(user: UpdateUserMediviewCommand): Observable<UpdateUserMediviewCommand> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.put<UpdateUserMediviewCommand>(`/api/mediview/users/`, user, { params: params });
	}

	createUser(user: CreateUserMediviewCommand): Observable<CreateUserMediviewCommand> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.post<CreateUserMediviewCommand>(`/api/mediview/users/`, user, { params: params });
	}

	getUserDataClaimsByUserId(userId: string): Observable<UserDataClaims> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.get<UserDataClaims>(`/api/mediview/users/${userId}/claims`, {
			params: params,
		});
	}

	resetPassword(userName: string): Observable<AdminResetUsersCommand> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.get<AdminResetUsersCommand>(
			`/api/mediview/users/admin-reset-password?userName=${userName}`,
			{
				params: params,
			}
		);
	}

	sendAccountActivationNotification(userId: string): Observable<SendAccountActivationNotificationResponse> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.post<SendAccountActivationNotificationResponse>(
			`/api/mediview/users/${userId}/send-activation-notification`,
			null,
			{
				params: params,
			}
		);
	}

	searchSecretariesByTerms(terms: string): Observable<SearchSecretaryUserResponse> {
		const params = new AuthorityHttpParam(true, {
			encoder: new URLEncoder(),
			fromString: `terms=${terms}`,
		});
		return this._httpClient.get<SearchSecretaryUserResponse>(`/api/mediview/users/secretary-search`, {
			params: params,
		});
	}

	updatePassword(command: UpdatePasswordUserMediviewCommand): Observable<UpdateMediviewUserPasswordResponse> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.put<UpdateMediviewUserPasswordResponse>(`/api/mediview/account/password`, command, {
			params: params,
		});
	}

	switchLockoutStatus(command: SwitchUserStatusCommand): Observable<SwitchUserStatusCommand> {
		const params = new AuthorityHttpParam(true);
		return this._httpClient.post<SwitchUserStatusCommand>(
			`/api/mediview/users/switch-user-lockout-status`,
			command,
			{
				params: params,
			}
		);
	}
}
