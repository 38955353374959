import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { SignalRSubscribeConstant } from "src/app/constants/signalr.constant";
import { SubjectEvents } from "../constants/subject-events.constant";
import { AppConfigService } from "./app-config.service";
import { AuthService } from "./auth.service";
import { Logger } from "./logger.service";

import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";

const log = new Logger("SignalRService");

@Injectable({
	providedIn: "root",
})
export class SignalRService implements OnDestroy {
	private _connection: HubConnection;
	private _lastMedicalUserId?: string;
	private _onAuthorizationChangeSubscription?: Subscription;
	private _onHubGroupRegistrationSubscription?: Subscription;
	private _onHubGroupRemoveRegistrationSubscription?: Subscription;

	_onPatientSharedSubject = new BehaviorSubject<string>(SubjectEvents.onInit);
	patientShare$: Observable<string> = this._onPatientSharedSubject.asObservable();

	_onNotifyPrescriptionChangesSubject = new Subject<string>();
	notifyPrescriptionChanges$ = this._onNotifyPrescriptionChangesSubject.asObservable();

	constructor(
		private _authService: AuthService,
		private _httpClient: HttpClient,
		private _appConfigService: AppConfigService
	) {
		this._connection = new HubConnectionBuilder()
			.withUrl(this._appConfigService.appConfig.hostHub, {
				withCredentials: false,
				accessTokenFactory: () => this._authService.accessToken,
			})
			.configureLogging(LogLevel.Warning)
			.build();

		this.subscribeToAuthorizationChange();
		this.subscribeToReceivedEvents();
		log.info("Start SignalR connection");
	}

	ngOnDestroy(): void {
		this._onAuthorizationChangeSubscription?.unsubscribe();
		this._onHubGroupRegistrationSubscription?.unsubscribe();
		this._onHubGroupRemoveRegistrationSubscription?.unsubscribe();
	}

	runRegistration(currentMedicalUserId: string): void {
		// first connection or new connection --> disconnected and a current medical userId
		if (this._connection.state === HubConnectionState.Disconnected && currentMedicalUserId) {
			this.initConnection(currentMedicalUserId);
			return;
		}

		// Nothing changed or the connection id --> not disconnected and same last and current medical user id
		if (
			!this._connection.connectionId ||
			(this._connection.state !== HubConnectionState.Disconnected &&
				!currentMedicalUserId &&
				!this._lastMedicalUserId &&
				currentMedicalUserId === this._lastMedicalUserId)
		) {
			return;
		}

		// New impersonation --> not disconnected and without last medical user id
		if (
			this._connection.state !== HubConnectionState.Disconnected &&
			currentMedicalUserId &&
			!this._lastMedicalUserId
		) {
			this.ensureHubConnected();
			this.registerUserToGroupHub(this._connection.connectionId, currentMedicalUserId);
			return;
		}

		// End of impersonation --> not disconnected and without current medical user id
		if (
			this._connection.state !== HubConnectionState.Disconnected &&
			!currentMedicalUserId &&
			this._lastMedicalUserId
		) {
			this.ensureHubConnected();
			this.unregisterUserToGroupHub(this._connection.connectionId, this._lastMedicalUserId);
			return;
		}
	}

	private subscribeToAuthorizationChange(): void {
		this._onAuthorizationChangeSubscription?.unsubscribe();
		this._onAuthorizationChangeSubscription = this._authService.authorizationResult$.subscribe(
			authorizationResult => {
				if (!authorizationResult.user || !authorizationResult.isAuthenticated) return;
				this.runRegistration(authorizationResult?.user?.currentMedicalUserId);
			}
		);
	}

	private initConnection(currentMedicalUserId: string): void {
		this._connection
			.start()
			.then(() => {
				this.ensureHubConnected();
				if (!this._connection.connectionId) return;
				this.registerUserToGroupHub(this._connection.connectionId, currentMedicalUserId);
			})
			.catch(reason => {
				log.error(reason);
			});
	}

	private registerUserToGroupHub(connectionId: string, medicalUserId: string): void {
		this._lastMedicalUserId = medicalUserId;

		this._onHubGroupRegistrationSubscription?.unsubscribe();
		this._onHubGroupRegistrationSubscription = this._httpClient
			.post<boolean>(`/signalr/group`, { connectionId, medicalUserId })
			.subscribe();

		log.info(`SignalR add registration to group: ${medicalUserId}`);
	}

	private unregisterUserToGroupHub(connectionId: string, medicalUserId: string): void {
		this._lastMedicalUserId = undefined;

		this._onHubGroupRemoveRegistrationSubscription?.unsubscribe();
		this._onHubGroupRemoveRegistrationSubscription = this._httpClient
			.delete<boolean>(`/signalr/group`, {
				body: { connectionId, medicalUserId },
			})
			.subscribe();
		log.info(`SignalR remove registration to group: ${medicalUserId}`);
	}

	private ensureHubConnected(): void {
		if (!this._connection.connectionId) throw new Error("SignalR is not connected yet.");
	}

	// Subscribe to all event from the hub
	private subscribeToReceivedEvents(): void {
		this._connection.on(SignalRSubscribeConstant.patientShare, () => {
			this._onPatientSharedSubject.next(SubjectEvents.onNewEvent);
		});
		this._connection.on(SignalRSubscribeConstant.notifyPrescriptionChanges, () => {
			this._onNotifyPrescriptionChangesSubject.next(SubjectEvents.onNewEvent);
		});
	}
}
