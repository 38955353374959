import { Injectable } from "@angular/core";
import {
	FormArray,
	FormGroup,
	FormControl,
	ValidationErrors,
	AbstractControl,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { Logger } from "./logger.service";

const log = new Logger("FormsService");

@Injectable({
	providedIn: "root",
})
export class FormsService {
	constructor() {}

	static clearFormArray = (formArray: FormArray) => {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
	};

	addFormArrayValue(formGroup: FormGroup, key: string, value: string) {
		const checkArray: FormArray = formGroup.get(key) as FormArray;

		if (!checkArray) return;

		checkArray.push(new FormControl(value));
	}

	getNullableBooleanValue(value: any): boolean | null {
		if (!value) return null;

		return value === "true" ? true : value === "false" ? false : null;
	}

	getNullableNumberValue(value: any): number | null {
		if (!value) return null;

		return typeof value === "string" && value.trim() === "" ? null : (value as number);
	}

	logFormValidationErrors(formGroup: FormGroup) {
		log.debug("Validation Errors: ");

		let totalErrors = 0;

		Object.keys(formGroup.controls).forEach(key => {
			const control: ValidationErrors = formGroup.controls[key];
			if (control) {
				const controlErrors: ValidationErrors = control["errors"];
				if (controlErrors != null) {
					totalErrors++;
					Object.keys(controlErrors).forEach(keyError => {
						log.debug(
							"Key control: " + key + ", keyError: " + keyError + ", err value: ",
							controlErrors[keyError]
						);
					});
				}
			}
		});

		log.debug("Number of errors: ", totalErrors);
	}

	hasAnyRequiredError = (formGroup: FormGroup) => {
		let result = false;
		Object.keys(formGroup.controls).forEach(key => {
			if (this.hasRequiredError(formGroup, key)) result = true;
		});

		return result;
	};

	hasAnyOtherError = (formGroup: FormGroup) => {
		let result = false;
		Object.keys(formGroup.controls).forEach(key => {
			if (this.hasOtherError(formGroup, key)) result = true;
		});

		return result;
	};

	hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
		return formGroup.controls[controlName].hasError(errorName);
	};

	hasGroupError = (formGroup: FormGroup, innerGroupName: string, controlName: string, errorName: string) => {
		const innerFormGroup = formGroup.controls[innerGroupName] as FormGroup;
		return innerFormGroup.controls[controlName].hasError(errorName);
	};

	hasOtherError = (formGroup: FormGroup, controlName: string) => {
		let result = false;
		const errors = formGroup.controls[controlName].errors;
		if (!errors) return false;
		Object.keys(errors).forEach(key => {
			if (key != "required") result = true;
		});

		return result;
	};

	hasRequiredError = (formGroup: FormGroup, controlName: string) => {
		return this.hasError(formGroup, controlName, "required");
	};

	onArrayCheckboxChange(formGroup: FormGroup, key: string, e: any) {
		const checkArray: FormArray = formGroup.get(key) as FormArray;

		if (!e.target) return;

		if (e.target.checked) {
			checkArray.push(new FormControl(e.target.value));
		} else {
			let i = 0;
			checkArray.controls.forEach((item: AbstractControl) => {
				if (item.value == e.target.value) {
					checkArray.removeAt(i);
					return;
				}
				i++;
			});
		}
	}

	onlyOneValueRequiredValidator(otherControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if ((control.value && otherControl.value) || (!control.value && !otherControl.value))
				return { onlyOneValueRequired: { value: control.value } };
			else return null;
		};
	}

	requiredUnlessOneFilledValidator(...controls: AbstractControl[]) {
		let isAllControlsEmpty = false;
		const initialControlsValidity: Record<string, boolean> = {};

		if (controls.every(control => !control.value)) {
			isAllControlsEmpty = true;
		}

		controls.forEach(control => {
			initialControlsValidity[control.value] = control.valid;
		});

		if (isAllControlsEmpty) {
			controls.forEach(control => {
				control.setValidators([Validators.required]);
			});
		} else {
			controls.forEach(control => {
				control.clearValidators();
			});
		}

		controls.forEach(control => {
			if (initialControlsValidity[control.value] !== control.valid) {
				control.markAsTouched();
			}
			control.updateValueAndValidity();
		});
	}

	minLengthArrayValidator = (min: number) => {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value.length >= min) return null;
			return { MinLengthArray: true };
		};
	};

	setMinLengthArrayValidatorWithCondition(formGroup: FormGroup, key: string, min: number, condition: boolean): void {
		if (condition) formGroup.controls[key].setValidators(this.minLengthArrayValidator(min));
		else formGroup.controls[key].setValidators([]);
		formGroup.controls[key].updateValueAndValidity();
	}

	setRequiredValidator(formGroup: FormGroup, key: string): void {
		this.setValidatorWithCondition(formGroup, key, Validators.required, true);
	}

	setRequiredValidatorWithCondition(formGroup: FormGroup, key: string, condition: boolean): void {
		this.setValidatorWithCondition(formGroup, key, Validators.required, condition);
	}

	setValidator(formGroup: FormGroup, key: string, validator: ValidatorFn): void {
		this.setValidatorWithCondition(formGroup, key, validator, true);
	}

	setValidatorWithCondition(formGroup: FormGroup, key: string, validator: ValidatorFn, condition: boolean): void {
		if (condition) formGroup.controls[key].addValidators(validator);
		else formGroup.controls[key].removeValidators(validator);
		formGroup.controls[key].updateValueAndValidity();
	}

	resetAllValidators(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach(key => {
			const control = formGroup.get(key);

			if (control instanceof FormControl) {
				control.clearValidators();
				control.updateValueAndValidity();
				return;
			}

			if (control instanceof FormGroup) {
				this.resetAllValidators(control);
				return;
			}
		});
	}
}
