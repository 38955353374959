import { Component, Input } from "@angular/core";
import { TableCellSelect, TableItem } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-select",
	templateUrl: "table-cell-select.component.html",
})
export class TableCellSelectComponent {
	@Input() item?: TableCellSelect;
	@Input() rowItem?: TableItem;

	toggleSelectRow(): void {
		if (!this.rowItem) return;

		this.rowItem.isSelected = !this.rowItem.isSelected;
	}
}
