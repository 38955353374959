<div *ngIf="item" class="d-flex align-items-center justify-content-center align-items-center">
	<div *ngFor="let action of item.items">
		<a
			*ngIf="action.link"
			[routerLink]="action.link.routerLink"
			[queryParams]="action.link.params"
			target="{{ action.link.targetBlank ? '_blank' : '_self' }}"
			title="{{ action.title ? action.title : 'Voir' }}"
			[class.disabled]="action.isDisabled"
			class="btn pin-circle me-2 {{ action.iconCssClass }}">
			<i
				[ngClass]="
					action.iconCssClass === '' || action.iconCssClass === null ? 'icon-see' : action.iconCssClass
				"
				class="{{ action.iconCssClass }} position-absolute top-50 start-50 translate-middle"></i>
		</a>
		<button
			*ngIf="action.button"
			(click)="callFunction(action.button.action)"
			type="button"
			title="{{ action.title ? action.title : 'Voir' }}"
			class="btn pin-circle me-2 {{ action.iconCssClass }}"
			[class.disabled]="action.isDisabled"
			[disabled]="action.isDisabled">
			<i
				[ngClass]="action.icon === '' || action.icon === null ? 'icon-see' : action.icon"
				class="{{ action.iconCssClass }} position-absolute top-50 start-50 translate-middle"></i>
		</button>
	</div>
</div>
