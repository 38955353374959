export class FromToDetermination {
	from!: Date;
	to!: Date;

	constructor(init?: Partial<FromToDetermination>) {
		Object.assign(this, init);
	}

	setFrom(nb30DaysToRemove = 1, nbDaysFromNow = 2): void {
		this.from = this.getDateByFilters(true, nb30DaysToRemove, nbDaysFromNow);
	}

	setTo(nb30DaysToRemove = 0, nbDaysFromNow = 2): void {
		this.to = this.getDateByFilters(false, nb30DaysToRemove, nbDaysFromNow);
	}

	private getDateByFilters(isFromDate: boolean, nb30DaysToRemove: number, nbDaysFromNow: number): Date {
		const filterDate = new Date();
		let dayOfTheMonth = filterDate.getDate() - nb30DaysToRemove * 30 - nbDaysFromNow;

		if (isFromDate) {
			dayOfTheMonth += 1;
		}

		filterDate.setDate(dayOfTheMonth);
		return filterDate;
	}
}
