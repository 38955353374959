import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class UrlService {
	private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>("");
	previousUrl$: Observable<string> = this.previousUrl.asObservable();
	currentPreviousUrl!: string;

	setPreviousUrl(previousUrl: string) {
		this.currentPreviousUrl = previousUrl;
		this.previousUrl.next(previousUrl);
	}
}
