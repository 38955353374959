<div
	*ngIf="items && tableView"
	[ngClass]="{
		'table-header-small': isTableHeaderSmall,
		'table-row-linked': isTableRowWithLink,
		'listing-patient': !isTableHeaderSmall,
		'table-responsive-lg': !isTableHasNoActions,
	}">
	<div class="bg-white table-rounded pb-3 px-3 pt-1 position-relative">
		<!-- Select all buttons -->
		<div class="d-flex align-items-center ps-3 mt-2" *ngIf="tableView.showSelectAllItems">
			<label>
				<span class="checkbox-wrap checkbox-primary custom-checkbox ms-2 text-ultra-light">
					<input
						type="checkbox"
						(click)="$event.stopPropagation()"
						(change)="toggleSelectAllRows()"
						[checked]="tableView.isSelectAllItems"
						[disabled]="tableView.selectAllButtonDisabled" />
				</span>
				<span *ngIf="selectAllLabel" class="d-inline-block px-3 text-ultra-light fs-smaller">{{
					selectAllLabel
				}}</span>
			</label>
			<button
				*ngIf="tableView.showSelectAllButton"
				class="btn btn-primary"
				type="button"
				[disabled]="nbSelectedItems === 0 || tableView.selectAllButtonDisabled"
				(click)="doSelectAllButtonAction()">
				{{ selectAllButtonLabel }}
			</button>

			@for (actionButton of actionButtons; track $index) {
				@if (actionButton.visiblePredicate()) {
					<button
						class="btn btn-primary ms-2"
						type="button"
						[disabled]="!actionButton.enablePredicate() || tableView.selectAllButtonDisabled"
						(click)="actionButton.action()">
						{{ actionButton.label }}
					</button>
				}
			}
		</div>

		<!-- Table -->
		<table
			class="table table-striped align-middle"
			[ngClass]="{ 'table-loader': isLoadingResults }"
			cdk-table
			[dataSource]="items">
			<col *ngFor="let column of tableView.displayColumns" span="1" [style.width]="column.width" />

			<!-- Cell rows -->
			<ng-container *ngFor="let column of tableView.displayColumns" [cdkColumnDef]="column.columnName">
				<td cdk-cell *cdkCellDef="let row; let rowNumber = index" [ngSwitch]="column.cellType">
					<ng-container *ngSwitchCase="tableCellTypes.actionsList">
						<app-table-cell-actions-list [item]="row[column.columnName]"></app-table-cell-actions-list>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.codeState">
						<app-table-cell-code-state [item]="row[column.columnName]"></app-table-cell-code-state>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.date">
						<app-table-cell-date [item]="row[column.columnName]"></app-table-cell-date>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.dateRange">
						<app-table-cell-date-range [item]="row[column.columnName]"></app-table-cell-date-range>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.documents">
						<app-table-cell-document [items]="row[column.columnName]"></app-table-cell-document>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.duration">
						<app-table-cell-duration [item]="row[column.columnName]"></app-table-cell-duration>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.healthCenterMembers">
						<app-table-cell-health-center-members
							[items]="row[column.columnName]"
							[row]="row.id"></app-table-cell-health-center-members>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.messageStatus">
						<app-table-cell-message-status [item]="row[column.columnName]"></app-table-cell-message-status>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.multipleLinesString">
						<app-table-cell-multiple-lines-string
							[item]="row[column.columnName]"></app-table-cell-multiple-lines-string>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.observancePeriodsStatus">
						<app-table-cell-observance-periods-status
							[item]="row[column.columnName]"></app-table-cell-observance-periods-status>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.patientInterventionType">
						<app-table-cell-patient-intervention-type
							[item]="row[column.columnName]"></app-table-cell-patient-intervention-type>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.person">
						<app-table-cell-person [item]="row[column.columnName]"></app-table-cell-person>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.prescriptionDateRangeStatus">
						<app-table-cell-prescription-date-range-and-status
							[item]="row[column.columnName]"></app-table-cell-prescription-date-range-and-status>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.readMore">
						<app-table-cell-read-more-text [item]="row[column.columnName]"> </app-table-cell-read-more-text>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.select">
						<app-table-cell-select [item]="row[column.columnName]" [rowItem]="row"></app-table-cell-select>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.status">
						<app-table-cell-status [item]="row[column.columnName]"></app-table-cell-status>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.string">
						<app-table-cell-string [item]="row[column.columnName]"></app-table-cell-string>
					</ng-container>
					<ng-container *ngSwitchCase="tableCellTypes.tag">
						<app-table-cell-tag [items]="row[column.columnName]"></app-table-cell-tag>
					</ng-container>
				</td>
			</ng-container>

			<!-- Header rows -->
			<ng-container [cdkColumnDef]="column.headerColumnName" *ngFor="let column of tableView.displayColumns">
				<th cdk-header-cell *cdkHeaderCellDef class="text-center">
					<app-table-header
						[columnName]="column.columnName"
						[filters]="tableView.filters"
						[filterChoiceList]="column.filterChoiceList"
						[filterOnly]="column.filterOnly"
						[filterColumnName]="column.filterColumnName"
						[filterToolTip]="column.filterToolTip"
						[filterType]="column.filterTableColumnType"
						[headerLabel]="column.headerLabel"
						[headerSubLabel]="column.headerSubLabel"
						[headerWithoutShadow]="column.headerWithoutShadow"
						[isMobile]="tableView.isMobile"
						[sort]="tableView.sort"
						[sortColumnName]="column.sortColumnName"
						[sortToolTip]="column.sortToolTip"
						(filterChange)="reloadTable(true)"
						(sortChange)="reloadTable(true)">
					</app-table-header>
				</th>
			</ng-container>

			<!-- Filters header rows -->
			<ng-container
				[cdkColumnDef]="column.headerFilterColumnName"
				*ngFor="let column of tableView.displayColumns">
				<th cdk-header-cell *cdkHeaderCellDef>
					<app-table-header-display-filter
						[columnName]="column.columnName"
						[filters]="tableView.filters"
						[filterType]="column.filterTableColumnType"
						[isMobile]="tableView.isMobile"
						(filterChange)="reloadTable(true)">
					</app-table-header-display-filter>
				</th>
			</ng-container>

			<!-- loader header rows -->
			<ng-container cdkColumnDef="loader">
				<th cdk-header-cell *cdkHeaderCellDef [attr.colspan]="tableView.nbVisibleColumns">
					<app-table-loader [isFullLoader]="false"></app-table-loader>
				</th>
			</ng-container>

			<!-- Headers -->
			<thead *ngIf="displayHeaders" class="thead-primary">
				<tr cdk-header-row *cdkHeaderRowDef="tableView.headerColumnNames"></tr>
				<tr cdk-header-row *cdkHeaderRowDef="tableView.headerFilterColumnNames" class="alert"></tr>
				<tr
					[ngClass]="{ 'loader-hidden': !isLoadingResults }"
					cdk-header-row
					*cdkHeaderRowDef="tableView.loaderColumnNames"
					class="loader"></tr>
			</thead>

			<!-- Rows -->
			<tbody>
				<tr cdk-row *cdkRowDef="let row; columns: tableView.displayColumnNames" class="position-relative"></tr>
			</tbody>

			<!-- No rows -->
			<tr class="mat-row" *cdkNoDataRow>
				<td class="mat-cell" *ngIf="!isOnError" [attr.colspan]="tableView.nbVisibleColumns">
					{{ noRowLabel }}
				</td>
				<td class="mat-cell" *ngIf="isOnError" [attr.colspan]="tableView.nbVisibleColumns">
					{{ noRowLabel }} car une erreur est survenue
				</td>
			</tr>
		</table>
		<ng-container *ngIf="activatePagination">
			<mat-paginator
				class="custom-paginator"
				(page)="reloadTable(false)"
				[length]="nbTotalFilteredItems"
				[pageIndex]="tableView.pageIndex"
				[pageSize]="tableView.pageSize"
				[pageSizeOptions]="pageSizeOptions"
				showFirstLastButtons="true"
				attr.aria-label="{{ paginationLabel }}"
				role="navigation"></mat-paginator>
		</ng-container>
	</div>
</div>
