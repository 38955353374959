import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Scopes } from "src/app/constants/scopes.constant";
import { AuthService } from "src/app/services/auth.service";
import { Logger } from "src/app/services/logger.service";
import { User } from "src/app/types/user";

const log = new Logger("HomeComponent");

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit {
	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _titleService: Title
	) {
		this._titleService.setTitle("Home");
	}

	ngOnInit(): void {
		const user: User = this._authService.user;

		if (user.hasScopes([Scopes.admin, Scopes.superAdmin, Scopes.medicalTechnicalAdvisor])) {
			log.debug(`Navigate to admin`);
			this._router.navigate(["/admin"]);
		}

		if (user.hasScopes([Scopes.prescriber, Scopes.mediviewLocalAdminAsdia, Scopes.mediviewLocalAdminElivie])) {
			log.debug(`Navigate to prescriber/dashboard`);
			this._router.navigate(["prescriber/dashboard"]);
		}
	}
}
