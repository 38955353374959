<div
	*ngIf="item"
	class="d-flex align-items-center justify-content-center align-items-center"
	style="position: relative">
	<span *ngIf="item.withIcon" class="pin-circle-patient-file">
		<i [ngClass]="item.iconCssClass" class="fs-3">&nbsp;</i>
	</span>

	<span class="text-nowrap d-flex" style="align-items: center; display: flex; flex-flow: column">
		<ng-container *ngIf="item.date; else noDateTimeValue">
			<span>{{ item.date | date: item.format }}</span>
		</ng-container>
		<span *ngIf="!item.hideSubValue">{{ item.subValue }} </span>

		<ng-template #noDateTimeValue> - </ng-template>
	</span>
</div>
