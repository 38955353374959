export class ObservanceStatusCodes {
	static readonly ge4h: string = "ge4h";
	static readonly ge2h_lt4h: string = "ge2h-lt4h";
	static readonly ge001h_lt2h: string = "ge001h-lt2h";
	static readonly lt001h: string = "lt001h";
	static readonly noTransmission: string = "no-transmission";
	static readonly initialPeriod: string = "initial-period";
	static readonly notMonitored: string = "not-monitored";
	static readonly remoteMonitoringRefusal: string = "remote-monitoring-refusal";
}

export class ObservanceLevelCodes {
	static readonly ge4h: string = "ge4h";
	static readonly ge2h_lt4h: string = "ge2h-lt4h";
	static readonly ge001h_lt2h: string = "ge001h-lt2h";
	static readonly lt001h: string = "lt001h";
	static readonly notMonitored: string = "not-monitored";
	static readonly noTransmission: string = "no-transmission";
}

export enum ObservancePeriodTypes {
	Nb112Hours,
	Nb56Hours,
	NbLess56Hours,
}
