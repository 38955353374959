import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BrowsePaging } from "src/app/@shared";
import { CacheKeys } from "src/app/constants";
import {
	TreatmentList,
	CreateAnnouncementCommand,
	PagerAnnouncement,
	UpdateAnnouncementCommand,
	SetAnnouncementStatusResponse,
} from "src/app/models";
import { URLEncoder } from "src/app/types";
import { StorageService } from "src/app/services";

@Injectable({
	providedIn: "root",
})
export class AnnouncementsService {
	get isFromSigninCallback(): boolean {
		return this._storageService.retrieveCacheAsBoolean(CacheKeys.isFromSigninCallback);
	}

	constructor(
		private _httpClient: HttpClient,
		private _storageService: StorageService
	) {}

	saveIsFromSigninCallback(value: boolean): void {
		this._storageService.saveCache(CacheKeys.isFromSigninCallback, value);
	}

	getTreatmentsMappings(): Observable<TreatmentList> {
		return this._httpClient.get<TreatmentList>(`/announcements/treatments`);
	}

	createAnnouncement(command: CreateAnnouncementCommand): Observable<CreateAnnouncementCommand> {
		return this._httpClient.post<CreateAnnouncementCommand>(
			`/announcements
			`,
			command
		);
	}

	browseAnnouncements(command: BrowsePaging): Observable<PagerAnnouncement> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
			fromString: command.generateUrlQuery(),
		});

		return this._httpClient.get<PagerAnnouncement>(`/announcements/search`, {
			params: params,
		});
	}

	updateAnnouncement(command: UpdateAnnouncementCommand): Observable<UpdateAnnouncementCommand> {
		return this._httpClient.put<UpdateAnnouncementCommand>(`/announcements`, command);
	}

	setAnnouncementReadingState(
		announcementId: string,
		markedAsRead: boolean
	): Observable<SetAnnouncementStatusResponse> {
		const params = new HttpParams().set("markedAsRead", markedAsRead);
		return this._httpClient.get<SetAnnouncementStatusResponse>(
			`/announcements/${announcementId}/set-reading-state`,
			{
				params: params,
			}
		);
	}
}
