import { Component, Input } from "@angular/core";
import { TableCellString } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-string",
	templateUrl: "table-cell-string.component.html",
})
export class TableCellStringComponent {
	@Input() item?: TableCellString;
}
