export const CacheKeysPrefix = "custom.";

export class CacheKeys {
	static readonly appVersion: string = "__appVersion";
	static readonly impersonation: string = "__impersonation";
	static readonly isPreviewMode: string = "__isPreviewMode";
	static readonly isFromSigninCallback: string = "__isFromSigninCallback";
	static readonly activityArea: string = "__activityArea";
	static readonly hasMultipleActivityArea: string = "__hasMultipleActivityArea";
	static readonly scopePrescriberIds: string = "__scopePrescriberIds";
	static readonly userConnection = "__userSession";
}

export class TableCacheKeys {
	static readonly tableAnnouncements: string = "__tableAnnouncements";
	static readonly tableHealthCenters: string = "__tableHealthCenters";
	static readonly tableHealthCenterMembers: string = "__tableHealthCenterMembers";
	static readonly tableMedicalTechnicalAdvisorMessages: string = "__tableMedicalTechnicalAdvisorMessages";
	static readonly tableMedicalTechnicalAdvisorMessagesMobile: string = "__tableMedicalTechnicalAdvisorMessagesMobile";
	static readonly tableMessages: string = "__tableMessages";
	static readonly tablePatients: string = "__tablePatients";
	static readonly tablePatientsMobile: string = "__tablePatientsMobile";
	static readonly tableScyovaPatients: string = "__tableScyovaPatients";
	static readonly tableScyovaPatientsMobile: string = "__tableScyovaPatientsMobile";
	static readonly tablePrescriberMessages: string = "__tablePrescriberMessages";
	static readonly tablePrescriberMessagesMobile: string = "__tablePrescriberMessagesMobile";
	static readonly tablePrescriptions: string = "__tablePrescriptions";
	static readonly tablePrescriptionsMobile: string = "__tablePrescriptionsMobile";
	static readonly tableDashboardIndicators: string = "__tableDashboardIndicators";
	static readonly tableDashboardIndicatorsMobile: string = "__tableDashboardIndicatorsMobile";
	static readonly tableUsers: string = "__tableUsers";
}

export class IdentityCacheKeys {
	static readonly nonce: string = "nonce";
	static readonly pkceVerifier: string = "PKCE_verifier";
}

// TODO: To integrate
export class CacheFilterKeys {
	static readonly healthCenters: string = "health-centers-filters";
	static readonly message: string = "message-filters";
	static readonly patients: string = "patients-filters";
	static readonly prescription: string = "prescription-filters";
	static readonly users: string = "users-filters";
}
