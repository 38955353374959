import { Component } from "@angular/core";
import { AppConfigService } from "src/app/services/app-config.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Logger } from "src/app/services/logger.service";
import { environment } from "src/environments/environment";
import { AppConfig } from "src/app/types/app-config";

const log = new Logger("NotFoundComponent");

@Component({
	selector: "app-not-found",
	templateUrl: "not-found.component.html",
	styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
	isDesignIntegration: boolean = environment.isDesignIntegration;
	appConfig!: AppConfig;

	constructor(
		appConfigService: AppConfigService,
		private _titleService: Title,
		private _router: Router
	) {
		log.debug("Design :", this.isDesignIntegration);
		this.appConfig = appConfigService.appConfig;
		this._titleService.setTitle("Not Found");
	}

	redirectToDashboard(): void {
		log.debug(`Navigate to /prescriber/dashboard`);
		this._router.navigate(["/prescriber/dashboard"]);
	}
}
