<ng-container *ngIf="isFullLoader; else insideLoader">
	<div
		class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
		style="z-index: 999; backdrop-filter: blur(6px)">
		<div class="spinner-grow" style="width: 3vw; height: 3vw" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</ng-container>

<ng-template #insideLoader>
	<div class="position-absolute d-flex justify-content-center w-100" style="z-index: 999">
		<div class="spinner-grow" style="width: 3vw; height: 3vw" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</ng-template>
