export class FavoriteConstantMessage {
	static readonly message = {
		error: "Erreur lors de la suppression des favoris",
		favoriteAdd: "Le patient a bien été ajouté aux favoris",
		favoriteRemove: "Le patient a bien été supprimé des favoris",
		favoritePatientToRemove: "Supprimer le patient des favoris",
		favoritePatientToAdd: "Ajouter le patient aux favoris",
		favoriteResponseDeleted: "favorite deleted",
		favoriteResponseAdded: "favorite added",
	};
}
