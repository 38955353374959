import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, ReplaySubject, Subject, timer } from "rxjs";
import { delay, filter, map, switchMap, takeUntil, tap } from "rxjs/operators";
import {
	AuthConfig,
	LoginOptions,
	OAuthErrorEvent,
	OAuthInfoEvent,
	OAuthService,
	OAuthStorage,
	OAuthSuccessEvent,
} from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { environment } from "src/environments";
import { Logger } from "src/app/services/logger.service";
import { AuditService, StorageService } from "src/app/services";
import {
	AppConfig,
	AuthorizationResult,
	Impersonation,
	SerializableUserConnection,
	User,
	UserConnection,
} from "src/app/types";
import { ActivityAreas, AuditTrailEventCodes, AuthLogoutEvent, CacheKeys } from "src/app/constants";

const log = new Logger("AuthService");

@Injectable({
	providedIn: "root",
})
export class AuthService implements OnDestroy {
	constructor(
		private _router: Router,
		private _oauthService: OAuthService,
		private _oauthStorage: OAuthStorage,
		private _storageService: StorageService,
		private _auditService: AuditService
	) {
		this.registerEvents();
	}

	private _authorizationResult: AuthorizationResult = new AuthorizationResult(false, false, false);
	private _authorizationResultSubject = new ReplaySubject<AuthorizationResult>(1);
	private _isIdentityConfigurationDone = false;
	private _isIdentityServerOnError = false;
	private _isIdentityServerOnErrorSubject = new BehaviorSubject<boolean>(this._isIdentityServerOnError);
	private _isLoadDiscoveryDocumentDone = false;
	private _ngUnsubscribe = new Subject<void>();
	private _appConfig?: AppConfig;
	private _userSessionDuration = 1440;
	private _stateUrl?: string;

	private _onRetryOnFail$ = this._isIdentityServerOnErrorSubject.pipe(
		takeUntil(this._ngUnsubscribe),
		filter(isIdentityServerOnError => isIdentityServerOnError),
		tap(_ => log.debug("setup retry Identity Server access (next try 10s)")),
		delay(10000),
		tap(_ => log.debug("setup retry Identity Server access: new attempt")),
		switchMap(() => this.configure()),
		tap(isConfigurationSuccess => log.debug(`setup retry Identity Server access: ${isConfigurationSuccess}`))
	);
	private _onAuthErrorEvents$: Observable<OAuthErrorEvent> = this._oauthService.events.pipe(
		takeUntil(this._ngUnsubscribe),
		filter(e => e instanceof OAuthErrorEvent),
		map(e => e as OAuthErrorEvent),
		tap(async e => await this.handleErrorEvents(e))
	);
	private _onAuthSuccessEvents$: Observable<OAuthSuccessEvent> = this._oauthService.events.pipe(
		takeUntil(this._ngUnsubscribe),
		filter(e => e instanceof OAuthSuccessEvent),
		map(e => e as OAuthSuccessEvent),
		tap(e => this.handleSuccessEvents(e))
	);
	private _onAuthInfoEvents$: Observable<OAuthInfoEvent> = this._oauthService.events.pipe(
		takeUntil(this._ngUnsubscribe),
		filter(e => e instanceof OAuthInfoEvent),
		map(e => e as OAuthInfoEvent),
		tap(e => this.handleInfoEvents(e))
	);
	private _onAuthSilentRefreshEvents$: Observable<OAuthInfoEvent> = this._oauthService.events.pipe(
		takeUntil(this._ngUnsubscribe),
		filter(e => ["silently_refreshed", "token_refreshed"].indexOf(e.type) > -1),
		map(e => e as OAuthInfoEvent),
		tap(async _ => await this._oauthService.loadUserProfile())
	);

	// 300 000ms --> every 5 minutes after 5 minutes
	private _onUserConnectionCheck$: Observable<UserConnection | null> = timer(300000, 300000).pipe(
		takeUntil(this._ngUnsubscribe),
		switchMap(async () => this.checkAndAddUserSessionEndCheck())
	);

	isIdentityServerOnError$: Observable<boolean> = this._isIdentityServerOnErrorSubject.pipe(
		tap(isIdentityServerOnError => (this._isIdentityServerOnError = isIdentityServerOnError))
	);
	authorizationResult$: Observable<AuthorizationResult> = this._authorizationResultSubject.asObservable();

	get user(): User {
		return this._authorizationResult?.user;
	}

	get idToken(): string {
		return this._oauthService.getIdToken();
	}

	get accessToken(): string {
		return this._oauthService.getAccessToken();
	}

	get hasValidAccessToken(): boolean {
		if (!this._oauthService.hasValidAccessToken()) return false;
		const accessTokenExpiration: number = this._oauthService.getAccessTokenExpiration();
		if (!accessTokenExpiration) return false;
		return accessTokenExpiration.isInTheFuture();
	}

	get hasValidIdToken(): boolean {
		if (!this._oauthService.hasValidAccessToken()) return false;
		const idTokenExpiration: number = this._oauthService.getIdTokenExpiration();
		if (!idTokenExpiration) return false;
		return idTokenExpiration.isInTheFuture();
	}

	get authorizationHeader(): string {
		return this._oauthService.authorizationHeader();
	}

	get stateUrl(): string | undefined {
		return this._stateUrl;
	}

	ngOnDestroy(): void {
		this._ngUnsubscribe.next();
		this._ngUnsubscribe.complete();
	}

	login(targetUrl?: string): boolean {
		this._oauthService.initCodeFlow(targetUrl || this._router.url);
		return true;
	}

	async tryForceLogin(targetUrl?: string): Promise<void> {
		let customRedirectUri: string | undefined = targetUrl || this._router.url;
		customRedirectUri = customRedirectUri !== "/" ? customRedirectUri : undefined;
		await this._oauthService.initLoginFlow(customRedirectUri);
	}

	async logout(tokenRevokedLogoutEvent: boolean): Promise<void> {
		try {
			// TODO: Clear specific info inside cache
			Impersonation.resetImpersonationInsideCache();
			this._storageService.removeCacheValue(CacheKeys.userConnection);

			if (tokenRevokedLogoutEvent)
				this._storageService.saveCacheValue(AuthLogoutEvent.tokenRevokedLogoutEvent, tokenRevokedLogoutEvent);
			await this._oauthService.revokeTokenAndLogout();
		} catch (e: unknown) {
			log.error(e);
		}
	}

	resetImpersonation(): void {
		this._authorizationResult.user.loadDefaultScopes(true);
		this._authorizationResult.user.resetActivityArea();
		this.notifyAuthorizationResultChange();
	}

	impersonateMedicalUser(
		medicalUserId: string,
		agencies: string,
		familyName: string,
		givenName: string,
		hasMultipleActivityArea: boolean,
		hasNoActivityArea: boolean,
		specificRoles: string[],
		prescriberIds?: string[]
	): void {
		this._authorizationResult.user.impersonateMedicalUser(
			medicalUserId,
			agencies,
			familyName,
			givenName,
			specificRoles,
			hasMultipleActivityArea,
			hasNoActivityArea
		);

		this.notifyAuthorizationResultChange();

		if (prescriberIds) this.changeUserPrescriberIdsScope(prescriberIds);
	}

	loadUserWithClaims(): User {
		const claims: any = this._oauthService.getIdentityClaims();

		if (!claims) return new User();

		const user = new User({
			id: claims.sub,
			familyName: claims.family_name,
			givenName: claims.given_name,
			medicalUserId: claims.medical_user_id,
			medicalTechnicalAdvisorId: claims.medical_technical_advisor_id,
			roles: claims.role,
			username: claims.unique_name,
			title: claims.title,
			hasMultipleActivityArea: this._storageService.getActivityAreaSettings(claims.medical_user_id),
		});

		const prescriberIds = this._storageService.getScopePrescriberIds(user.currentMedicalUserId);
		const activityArea = this._storageService.getActivityArea(user.currentMedicalUserId);

		user.assignScopePrescriberIds(prescriberIds);

		if (activityArea) user.assignActivityArea(activityArea);

		return user;
	}

	changeActivityAreaSettings(hasMultipleActivityArea: boolean): void {
		this._storageService.setActivityAreaSettings(this.user.currentMedicalUserId, hasMultipleActivityArea);
		this.user.hasMultipleActivityArea = hasMultipleActivityArea;
	}

	changeUserPrescriberIdsScope(prescriberIds: string[]): void {
		this._storageService.setScopePrescriberIds(this.user.currentMedicalUserId, prescriberIds);
		this.user.assignScopePrescriberIds(prescriberIds);
	}

	changeUserActivityAreaToRespiratory(): void {
		this._storageService.setActivityArea(this.user.currentMedicalUserId, ActivityAreas.respiratory);
		this.user.assignActivityArea(ActivityAreas.respiratory);
		this.notifyAuthorizationResultChange();
	}

	changeUserActivityAreaToScyova(): void {
		this._storageService.setActivityArea(this.user.currentMedicalUserId, ActivityAreas.scyova);
		this.user.assignActivityArea(ActivityAreas.scyova);
		this.notifyAuthorizationResultChange();
	}

	async configureStartup(appConfig: AppConfig): Promise<boolean> {
		try {
			log.debug("Start identity configuration");
			this._appConfig = appConfig;
			this._userSessionDuration = appConfig.userSessionNbMinutes;
			this.configureOAuthService(appConfig);
			await this.loadDiscoveryDocument();

			const loginOption = new LoginOptions();
			loginOption.preventClearHashAfterLogin = false;

			let isLoginSuccess = await this._oauthService.tryLogin(loginOption);

			// no valid access token --> try get one with silent refresh
			if (!this.hasValidAccessToken) {
				log.debug("No valid access token --> try to get a valid one through silent refresh");
				isLoginSuccess = await this.silentRefresh(false);
			}

			// user connected --> load claims
			if (isLoginSuccess) {
				await this._oauthService.loadUserProfile();
				// set up the automatic silent refresh
				this._oauthService.setupAutomaticSilentRefresh();
			} else {
				this.notifyAuthorizationResultChange();
			}

			this.tryRegisterRedirectFromUrl();

			// notify that identity is up
			this._isIdentityServerOnErrorSubject.next(false);

			await this.ensureUserConnection();
			await this.checkAndAddUserSessionEndCheck();

			return true;
		} catch (error) {
			log.error(error);
			// notify that identity is down
			this._isIdentityServerOnErrorSubject.next(true);
			this.notifyAuthorizationResultChange();
			return false;
		}
	}

	async ensureUserConnection(force = false): Promise<void> {
		if (!this.hasValidAccessToken) return;

		let userConnection = this.getUserConnectionFromCache();

		// Do nothings
		if (!!userConnection && !force) {
			log.debug("User connection info already added");
			return;
		}

		// Extends
		if (!!userConnection && force) {
			userConnection.extends(this._userSessionDuration);
			this._storageService.saveCache(CacheKeys.userConnection, UserConnection.serialize(userConnection));
			log.debug("Extends user connection");
			this._auditService.logUserSessionAsync(AuditTrailEventCodes.userConnectionExtendSession, userConnection);
			return;
		}

		userConnection = UserConnection.create(this._userSessionDuration);
		this._storageService.saveCache(CacheKeys.userConnection, UserConnection.serialize(userConnection));
		log.info("User connection info added", userConnection);
		this._auditService.logUserSessionAsync(AuditTrailEventCodes.userConnectionStartSession, userConnection);
	}

	private async configure(): Promise<boolean> {
		if (!this._appConfig) return false;

		return this.configureStartup(this._appConfig);
	}

	private async checkAndAddUserSessionEndCheck(): Promise<UserConnection | null> {
		const userConnection = this.getUserConnectionFromCache();
		await this.checkUserSessionEnd(userConnection);
		return userConnection;
	}

	private getUserConnectionFromCache(): UserConnection | null {
		const userConnection = this._storageService.retrieveCacheValue(
			CacheKeys.userConnection
		) as SerializableUserConnection;
		return UserConnection.deserialize(userConnection);
	}

	private async checkUserSessionEnd(userConnection: UserConnection | null): Promise<void> {
		if (!userConnection) {
			log.warn("User connection info is empty");
			return;
		}

		if (userConnection.isExpired()) {
			log.debug("User session is no more active");

			await this._auditService.logUserSession(AuditTrailEventCodes.userConnectionEndSession, userConnection);
			await this.logout(true);
			return;
		}

		if (userConnection.isAlmostExpired()) {
			log.info(`User session will expire in about ${UserConnection.almostExpirationDateNbMinutes} minutes`);
			return;
		}

		log.debug("User session is still active");
	}

	private tryRegisterRedirectFromUrl(): void {
		log.debug("Register redirect from url", this._oauthService.state);
		if (
			!this._oauthService.state ||
			this._oauthService.state === "undefined" ||
			this._oauthService.state === "null"
		)
			return;

		let currentStateUrl = this._oauthService.state;

		if (!currentStateUrl.startsWith("/")) currentStateUrl = decodeURIComponent(currentStateUrl);

		this._stateUrl = currentStateUrl;
	}

	private notifyAuthorizationResultChange(): void {
		this._authorizationResult = this.getAuthorizationResult();
		log.debug("Notify authorization result change", this._authorizationResult);
		this._authorizationResultSubject.next(this._authorizationResult);
	}

	private getAuthorizationResult(): AuthorizationResult {
		if (this._isIdentityServerOnError) {
			return new AuthorizationResult(true, true, false);
		}

		const isAuthenticated = this.hasValidAccessToken;

		if (!isAuthenticated) {
			if (this.getUserConnectionFromCache()) {
				this._storageService.removeCacheValue(CacheKeys.userConnection);
			}
			return new AuthorizationResult(true, false, false);
		}

		const user = this.loadUserWithClaims();
		return new AuthorizationResult(true, false, true, user);
	}

	private async silentRefresh(forceLoginIfFailed = true): Promise<boolean> {
		try {
			await this._oauthService.silentRefresh();

			return true;
		} catch (silentRefreshError: any) {
			if (!forceLoginIfFailed) {
				log.debug("Silent refresh failed");
				return false;
			}
			// Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
			// Only the ones where it's reasonably sure that sending the
			// user to the IdServer will help.
			const errorResponsesRequiringUserInteraction = [
				"interaction_required",
				"login_required",
				"account_selection_required",
				"consent_required",
			];

			if (
				silentRefreshError &&
				silentRefreshError.reason &&
				silentRefreshError.reason.params &&
				errorResponsesRequiringUserInteraction.indexOf(silentRefreshError.reason.params.error) >= 0
			) {
				// 3. ASK FOR LOGIN:
				// At this point we know for sure that we have to ask the
				// user to log in, so we redirect them to the IdServer to
				// enter credentials.
				//
				// Enable this to ALWAYS force a user to login.
				this.login();
				//
				// Instead, we'll now do this:
				return true;
			}
			// We can't handle the truth, just pass on the problem to the
			// next handler.
			return false;
		}
	}

	private forceRemoveTokens(): void {
		this._oauthStorage.removeItem("refresh_token");
		this._oauthStorage.removeItem("access_token");
		this._oauthStorage.removeItem("id_token");
	}

	private async loadDiscoveryDocument(): Promise<void> {
		if (this._isLoadDiscoveryDocumentDone) return;

		await this._oauthService.loadDiscoveryDocument();
		this._isLoadDiscoveryDocumentDone = true;
	}

	private configureOAuthService(appConfig: AppConfig): void {
		if (this._isIdentityConfigurationDone) return;

		const authConfig: AuthConfig = new AuthConfig({
			clearHashAfterLogin: false,
			clientId: appConfig.clientId,
			dummyClientSecret: appConfig.clientSecret,
			issuer: `${appConfig.hostAuth}`,
			oidc: true,
			postLogoutRedirectUri: `${window.location.origin}/signout-callback`,
			redirectUri: `${window.location.origin}/signin-callback`,
			redirectUriAsPostLogoutRedirectUriFallback: true,
			responseType: "code", //code flow PKCE
			scope: "openid offline_access profile email mediview:user mediview:api skyconnect:api:basics skyconnect:api:creation",
			sessionChecksEnabled: false,
			// sessionChecksEnabled: true, // Tmp deactivate / Do not work with webpro.istep.fr
			// sessionCheckIntervall: 60 * 1000, // default 3 * 1000; // Tmp deactivate / Do not work with webpro.istep.fr
			showDebugInformation: !environment.production,
			silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,

			// useIdTokenHintForSilentRefresh: true,
			useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
			// silentRefreshTimeout: 5000, // For faster testing
			// timeoutFactor: 0.25, // For faster testing,
		});
		this._oauthService.configure(authConfig);
		this._oauthService.tokenValidationHandler = new JwksValidationHandler();
		this._isIdentityConfigurationDone = true;
	}

	private handleSuccessEvents(e: OAuthSuccessEvent): void {
		log.debug(e);

		if (e.type === "user_profile_loaded") {
			this.notifyAuthorizationResultChange();
		}
	}

	private handleInfoEvents(e: OAuthInfoEvent): void {
		log.debug(e);
	}

	private async handleErrorEvents(e: OAuthErrorEvent): Promise<void> {
		log.error(e);

		if (e.type === "user_profile_load_error") {
			this.forceRemoveTokens();
			return;
		}

		// linked with  UserSsoLifetime
		if (e.type == "code_error" && e.params && "error" in e.params && e.params.error === "login_required") {
			await this.logout(false);
			return;
		}
	}

	private registerEvents(): void {
		// This is tricky, as it might cause race conditions (where access_token is set in another
		// tab before everything is said and done there.
		// TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
		window.addEventListener("storage", event => {
			// The `key` is `null` if the event was caused by `.clear()`
			if (event.key !== "access_token" && event.key !== null) {
				return;
			}

			log.warn("Noticed changes to access_token (most likely from another tab), updating isAuthenticated");
			const hasValidAccessToken = this.hasValidAccessToken;
			if (!hasValidAccessToken) {
				this.login();
			}
		});

		this._onAuthErrorEvents$.subscribe();
		this._onAuthInfoEvents$.subscribe();
		this._onAuthSuccessEvents$.subscribe();
		this._onRetryOnFail$.subscribe();
		this._onAuthSilentRefreshEvents$.subscribe();
		this._onUserConnectionCheck$.subscribe();
	}
}
