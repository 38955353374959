import { Component, Input } from "@angular/core";
import { TableCellActionsList } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-actions-list",
	templateUrl: "table-cell-actions-list.component.html",
})
export class TableCellActionsListComponent {
	@Input() item?: TableCellActionsList;

	callFunction(method: () => void): void {
		method();
	}
}
