export class AppConfig {
	amCharts5LicenseKey = "";
	appCompanyName = "";
	appCompanyUrl = "";
	appDisplayName = "";
	appDomain = "";
	appName = "";
	clientId = "";
	clientRoot = "";
	clientSecret = "";
	hostApi = "";
	hostAuth = "";
	hostHub = "";
	isFeaturePreviewOn = false;
	pandalabProject = "";
	secureRoutes: string[] = [];
	version: AppVersion = new AppVersion({});
	userSessionNbMinutes = 1440;

	constructor(init?: AppConfig) {
		Object.assign(this, init);
	}
}

export class AppVersion {
	build = "";
	name = "";
	version = "";

	constructor(init?: Partial<AppVersion>) {
		Object.assign(this, init);
	}
}
