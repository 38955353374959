export class FilesSizesConstants {
	// 15 MB <-- 15 * 1 024  * 1 024
	static readonly maxDocumentSize: number = 15728640;
	static readonly maxDocumentFilenameLength: number = 100;
}

export class FilesExtensionTypesConstants {
	static readonly doc: string = ".doc";
	static readonly docx: string = ".docx";
	static readonly jpeg: string = ".jpeg";
	static readonly jpg: string = ".jpg";
	static readonly pdf: string = ".pdf";
	static readonly png: string = ".png";

	static readonly availableDocumentExtensionTypes: string[] = [
		FilesExtensionTypesConstants.doc,
		FilesExtensionTypesConstants.docx,
		FilesExtensionTypesConstants.jpeg,
		FilesExtensionTypesConstants.jpg,
		FilesExtensionTypesConstants.pdf,
		FilesExtensionTypesConstants.png,
	];
}

export class FilesMimeTypesConstants {
	static readonly doc: string = "application/msword";
	static readonly docx: string = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
	static readonly jpg: string = "image/jpeg";
	static readonly pdf: string = "application/pdf";
	static readonly png: string = "image/png";

	static readonly availableDocumentMimeTypes: string[] = [
		FilesMimeTypesConstants.doc,
		FilesMimeTypesConstants.docx,
		FilesMimeTypesConstants.jpg,
		FilesMimeTypesConstants.pdf,
		FilesMimeTypesConstants.png,
	];
}

export class FilesCategoryCodes {
	static readonly cpapPrescriptionPolysomnography: string = "cpap-prescription-polysomnography";
	static readonly medicalUserMessage: string = "medical-user-message";
}
