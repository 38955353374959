export class DeviceModelsImages {
	static readonly airproductsBouteilleAirProductsX05T: string = "AIR-PRODUCTS_BOUTEILLE-AIR-PRODUCTS-X05T.jpg";
	static readonly airproductsBouteilleAirProductsX02T: string = "AIR-PRODUCTS_BOUTEILLE-AIR-PRODUCTS-X02T.jpg";
	static readonly airproductsBouteilleAirProductsX15T: string = "AIR-PRODUCTS_BOUTEILLE-AIR-PRODUCTS-X15T.jpg";

	static readonly almsAlpha200C: string = "ALMS_ALPHA-200C.jpeg";
	static readonly almsAlpha200P: string = "ALMS_ALPHA-200P.png";
	static readonly almsAlpha300: string = "ALMS_ALPHA-300.jpg";
	static readonly almsEove: string = "ALMS_EOVE-70.png";

	static readonly bmcG3A20: string = "BMC_G3-A20.jpg";
	static readonly bmcG3A202: string = "BMC_G3-A20-2.jpg";

	static readonly breasZ1Auto: string = "BREAS_Z1-AUTO.jpg";
	static readonly breasIsleep25: string = "BREAS_ISLEEP-25.jpg";
	static readonly breasPv102: string = "BREAS_PV102.jpg";
	static readonly breasVivo3: string = "BREAS_VIVO-3.jpg";
	static readonly breasVivo60: string = "BREAS_VIVO-60.jpg";
	static readonly breasVivo65: string = "BREAS_VIVO-65.jpg";

	static readonly cairemedicalLiberator45L: string = "CAIRE-MEDICAL_LIBERATOR-45L.jpg";
	static readonly cairemedicalHelios46LUniverselle: string = "CAIRE-MEDICAL_HELIOS-46L-UNIVERSELLE.jpg";

	static readonly cryopalFreelox12LUS: string = "CRYOPAL_FREELOX-12L-US.jpg";
	static readonly cryopalFreelox05LUS: string = "CRYOPAL_FREELOX-05L-US.jpg";
	static readonly cryopalFreelox44LCE: string = "CRYOPAL_FREELOX-44L-CE.jpg";
	static readonly cryopalFreelox305LUS: string = "CRYOPAL_FREELOX3-05L-US.jpg";
	static readonly cryopalFreelox12LCE: string = "CRYOPAL_FREELOX-12L-CE.jpg";
	static readonly cryopalFreelox32LUS: string = "CRYOPAL_FREELOX-32L-US.jpg";
	static readonly cryopalFreelox05LCE: string = "CRYOPAL_FREELOX-05L-CE.jpg";
	static readonly cryopalFreelox32LCE: string = "CRYOPAL_FREELOX-32L-CE.jpg";
	static readonly cryopalFreelox44LUS: string = "CRYOPAL_FREELOX-44L-US.jpg";

	static readonly devilbissPpcBlueAutoplus: string = "DEVILBISS_PPC-BLUE-AUTOPLUS.jpg";
	static readonly devilbissSleepcubeAuto: string = "DEVILBISS_SLEEPCUBE-AUTO.jpg";
	static readonly devilbissConcentrateurCompact525KS: string = "DEVILBISS_CONCENTRATEUR-COMPACT-525-KS.jpg";
	static readonly devilbissAerosolAirForceMax: string = "DEVILBISS_AEROSOL-AIR-FORCE-MAX.jpg";
	static readonly devilbissConcentrateur1025KS10L: string = "DEVILBISS_CONCENTRATEUR-1025-KS-10L.jpg";
	static readonly devilbissBouteille18LContinuIFill: string = "DEVILBISS_BOUTEILLE-18-L-CONTINU-POUR-I-FILL.jpg";
	static readonly devilbissBouteille29LContinuIFill: string = "DEVILBISS_BOUTEILLE-29-L-CONTINU-POUR-I-FILL.jpg";
	static readonly devilbissAerosolPulmoAide: string = "DEVILBISS_AEROSOLPULMO-AIDE.jpg";

	static readonly dtfmedicalAerosolAerodjinn: string = "DTF-MEDICAL_AEROSOL-AERODJINN.jpg";
	static readonly dtfmedicalAerosolManasonique: string = "DTF-MEDICAL_AEROSOL-MANOSONIQUE.jpg";
	static readonly dtfmedicalAerosolAtomisorBoxPlus: string = "DTF-MEDICAL_AEROSOL-ATOMISOR-BOX-PLUS.jpg";

	static readonly fischerpaykelIconAuto: string = "FISCHER-PAYKEL_ICON-AUTO.jpg";
	static readonly fisherPaykelMyairvo2: string = "FISHER-PAYKEL_MYAIRVO-2.jpg";
	static readonly fisherPaykelAirvo2: string = "FISHER-PAYKEL_AIRVO-2.jpg";

	static readonly gceConcentrateurZenOLiteBatterie16Cellules: string =
		"GCE_CONCENTR-ZEN-O-LITE-BATTERIE-16-CELLUL.jpg";
	static readonly gceConcentrateurZenOLiteBatterie8Cellules: string =
		"GCE_CONCENTR-ZEN-O-LITE-BATTERIE-8-CELLULE.jpg";
	static readonly gceConcentrateurZenO24Cellulles: string = "GCE_CONCENTRATEUR-ZEN-O-24-CELLULES.jpg";

	static readonly hawaAcapellaChoice: string = "HAWA_ACAPELLA-CHOICE.jpeg";

	static readonly hoffrichterLavi40: string = "HOFFRICHTER_LAVI-40.jpg";
	static readonly hoffrichterTrendIIIBilevelSt: string = "HOFFRICHTER_TREND-III-BILEVEL-ST.jpg";

	static readonly inogenG3HFBatterie16Celllules: string = "INOGEN_INOGEN-G3-HF-BATTERIE-16-CELLULES.jpg";
	static readonly inogenG3HFBatterie8Celllules: string = "INOGEN_INOGEN-G3-HF-BATTERIE-8-CELLULES.jpg";
	static readonly inogenG3Batterie8Celllules: string = "INOGEN_INOGEN-G3-BATTERIE-8-CELLULES.jpg";
	static readonly inogenG3Batterie16Celllules: string = "INOGEN_INOGEN-G3-BATTERIE-16-CELLULES.jpg";
	static readonly inogenG4Batterie8Cellules: string = "INOGEN_INOGEN-G4-BATTERIE-8-CELLULES.jpg";

	static readonly invacareBouteille17LContinuHomefill: string = "INVACARE_BOUTEILLE-17L-CONTINU-POUR-HOMEFILL.jpg";
	static readonly invacareCompresseurHomefill2: string = "INVACARE_COMPRESSEUR-HOMEFILL2.jpg";
	static readonly invacarePerfecto2V: string = "INVACARE_PERFECTO2V.jpg";
	static readonly invacarePlatinum9: string = "INVACARE_PLATINUM-9.jpg";
	static readonly invacareAerosolStratosPro: string = "INVACARE_AEROSOL-STRATOS-PRO.jpg";
	static readonly invacarePlatinum5: string = "INVACARE_PLATINUM-5.jpg";
	static readonly invacareAerosolEnvoy: string = "INVACARE_NPU-AEROSOLENVOY.jpg";
	static readonly invacareConcentrateurPortableSolo2: string = "INVACARE_CONCENTRATEUR-PORTABLE-SOLO-2.jpg";

	static readonly l3medicalEclipse5: string = "L3-MEDICAL_ECLIPSE-5.jpg";
	static readonly l3medicalAerosolMiniplus: string = "L3-MEDICAL_AEROSOL-MINIPLUS.jpg";
	static readonly l3medicalEclipse3: string = "L3-MEDICAL_ECLIPSE-III.jpg";
	static readonly l3medicalConcentrateurVisionaireAirsep: string = "L3-MEDICAL_CONCENTRATEUR-VISIONAIRE-AIRSEP.jpg";
	static readonly l3medicalAerolife2: string = "L3-MEDICAL_AEROLIFE-2.jpg";
	static readonly l3medicalClearway2: string = "L3-MEDICAL_CLEARWAY-2.png";
	static readonly l3medicalClearwayCoughAssistor: string = "L3-MEDICAL_CLEARWAY-COUGH-ASSISTOR.png";

	static readonly lindeBouteilleL05R: string = "LINDE_BOUTEILLE-LINDE-L05R.jpg";
	static readonly lindeBouteilleL05V: string = "LINDE_BOUTEILLE-LINDE-L05V.jpg";
	static readonly lindeBouteilleL02V: string = "LINDE_BOUTEILLE-LINDE-L02V.jpg";
	static readonly lindeBouteilleL15R: string = "LINDE_BOUTEILLE-LINDE-L15R.jpg";
	static readonly lindeBouteilleL02R: string = "LINDE_BOUTEILLE-LINDE-L02R.jpg";
	static readonly lindeBouteilleL15V: string = "LINDE_BOUTEILLE-LINDE-L15V.jpg";

	static readonly lowensteinLuisa: string = "LOWENSTEIN_LUISA.jpg";
	static readonly lowensteinPrisma: string = "LOWENSTEIN_PRISMA.jpg";
	static readonly lowensteinPrismaSmart: string = "LOWENSTEIN_PRISMA-SMART.jpg";
	static readonly lowensteinSmartWhite: string = "LOWENSTEIN_PRISMA-SMART-WHITE.jpg";
	static readonly lowensteinSomnoBalanceE: string = "LOWENSTEIN_SOMNOBALANCE-E.jpg";
	static readonly lowensteinSomnoClick: string = "LOWENSTEIN_SOMNOCLICK.jpg";
	static readonly lowensteinSomnocomfort: string = "LOWENSTEIN_SOMNOCOMFORT.jpg";
	static readonly lowensteinSomnosmart: string = "LOWENSTEIN_SOMNOSMART.jpg";
	static readonly lowensteinSomnosmartClick: string = "LOWENSTEIN_SOMNOSMART-CLICK.jpg";
	static readonly lowensteinSomnovent: string = "LOWENSTEIN_SOMNOVENT.jpg";
	static readonly lowensteinVent30: string = "LOWENSTEIN_PRISMA-VENT30.jpg";
	static readonly lowensteinVent50: string = "LOWENSTEIN_PRISMA-VENT50.jpg";
	static readonly lowensteinVentimotion: string = "LOWENSTEIN_VENTIMOTION.jpg";

	static readonly medifluxComfortCough: string = "MEDIFLUX_COMFORTCOUGH-2.jpeg";

	static readonly pariAerosolBoySX: string = "PARI_AEROSOL-PARI-BOY-SX.jpg";
	static readonly pariAerosolBoyPRO: string = "PARI_AEROSOL-PARI-BOY-PRO.jpg";
	static readonly pariAerosolEflow: string = "PARI_AEROSOL-EFLOW.jpg";
	static readonly pariAerosolVelox: string = "PARI_AEROSOL-VELOX.jpg";
	static readonly pariAerosolBoySXValise: string = "PARI_AEROSOL-PARI-BOY-SX-AVEC-VALISE.jpg";
	static readonly pariConcentrateur0éBasDebitOXY6000: string = "PARI_CONCENTRATEUR-O2-BAS-DEBIT-OXY-6000.jpg";
	static readonly pariAerosolTurboboyN: string = "PARI_AEROSOL-TURBOBOY-N.jpg";
	static readonly pariAerosolBoyMobilS: string = "PARI_AEROSOL-PARI-BOY-MOBILE-S.jpg";

	static readonly philipsDreamstation: string = "PHILIPS-SSD_DREAMSTATION.jpg";
	static readonly philipsDreamstation2: string = "PHILIPS-SSD_DREAMSTATION-2.jpg";
	static readonly philipsDreamstationBipapAutosv: string = "PHILIPS-SSD_DREAMSTATION-BIPAP-AUTOSV.jpg";
	static readonly philipsDreamstationBipapAvaps: string = "PHILIPS-SSD_DREAMSTATION-BIPAP-AVAPS.jpg";
	static readonly philipsDreamstationExpertB: string = "PHILIPS-SSD_DREAMSTATION-EXPERTB.jpg";
	static readonly philipsDreamstationExpertBPro: string = "PHILIPS-SSD_DREAMSTATION-EXPERTB-PRO.jpg";
	static readonly philipsDreamstationGo: string = "PHILIPS-SSD_DREAMSTATION-GO.jpg";
	static readonly philipsEverflo: string = "PHILIPS-SSD_EVERFLO.jpg";
	static readonly philipsPr1: string = "PHILIPS-SSD_PR1.jpg";
	static readonly philipsPr12012: string = "PHILIPS-SSD_PR1-2012.jpg";
	static readonly philipsPr1Aflex: string = "PHILIPS-SSD_PR1-AFLEX.jpg";
	static readonly philipsPr1BipapA40: string = "PHILIPS-SSD_PR1-BIPAP-A40-2014.jpg";
	static readonly philipsPr1BipapHarmony2: string = "PHILIPS-SSD_PR1-BIPAP-HARMONY-2.jpg";
	static readonly philipsPr1BipapMSeries: string = "PHILIPS-SSD_PR1-BIPAP-M-SERIES.jpg";
	static readonly philipsPr1BipapSOH: string = "PHILIPS-SSD_PR1-BIPAP-SOH.jpg";
	static readonly philipsRemstar: string = "PHILIPS-SSD_REMSTAR.jpg";
	static readonly philipsRemstarPro: string = "PHILIPS-SSD_REMSTAR-PRO.jpg";
	static readonly philipsTrilogyEvo: string = "PHILIPS-SSD_TRILOGY-EVO.jpg";
	static readonly philipsSimplygo: string = "PHILIPS-SSD_SIMPLYGO.jpg";
	static readonly philipsAerosolInnospireElegance: string = "PHILIPS-SSD_AEROSOL-INNOSPIRE-ELEGANCE.jpg";
	static readonly philipsAerosolInspirationElite: string = "PHILIPS-SSD_AEROSOL-INSPIRATION-ELITE.jpg";
	static readonly philipsAerosolInnospireDeluxe: string = "PHILIPS-SSD_AEROSOL-INNOSPIRE-DELUXE.jpg";
	static readonly philipsSimplygoMiniBatterieLongueDuree: string =
		"PHILIPS-SSD_SIMPLYGO-MINI-BATTERIE-LONGUE-DUREE.jpg";
	static readonly philipsNPUTrilogy100: string = "PHILIPS-SSD-NPU-TRILOGY-100.jpg";
	static readonly philipsInnospireGo: string = "PHILIPS-SSD_INNOSPIRE-GO.jpg";
	static readonly philipsBipapA40Remplacement: string = "PHILIPS-SSD_BIPAP-A40-REMPLACEMENT.jpg";
	static readonly philipsDreamstationExpertRepair: string = "PHILIPS-SSD_DREAMSTATION-EXPERT-REPAIR.jpg";
	static readonly philipsMillenium: string = "PHILIPS-SSD_MILLENIUM.jpg";
	static readonly philipsBibapAutoSvAdvanced: string = "PHILIPS-SSD_BIPAP-AUTO-SV-ADVANCED.jpg";
	static readonly philipsEverfloTUltrafill: string = "PHILIPS-SSD_EVERFLO-T-POUR-ULTRAFILL.jpg";
	static readonly philipsCoughtAssistE70: string = "PHILIPS-SSD_COUGHTASSIST-E70.jpg";

	static readonly resmedAirCuve: string = "RESMED_AIR-CURVE-10.jpg";
	static readonly resmedAirsense10: string = "RESMED_AIRSENSE10.jpg";
	static readonly resmedAirsense11: string = "RESMED_AIRSENSE11.jpg";
	static readonly resmedAirmini: string = "RESMED_AIRMINI.jpg";
	static readonly resmedAstral150: string = "RESMED_ASTRAL-150-VPAP-ST.jpg";
	static readonly resmedAutoset: string = "RESMED_AUTOSET-CS2.jpg";
	static readonly resmedAutosetSpirit: string = "RESMED_AUTOSET-SPIRIT.jpg";
	static readonly resmedLumis150VPAPST: string = "RESMED_LUMIS-150-VPAP-ST.jpg";
	static readonly resmedS6: string = "RESMED_S6.jpg";
	static readonly resmedS8: string = "RESMED_S8.jpg";
	static readonly resmedS9: string = "RESMED_S9.jpg";
	static readonly resmedStellar: string = "RESMED_STELLAR.jpg";
	static readonly resmedStellar150: string = "RESMED_STELLAR-150.jpg";

	static readonly responsiverespiratoryRespond: string = "RESPONSIVE-RESPIRATORY_RESPOND.jpg";

	static readonly scaleoConcentrateurHorizonS5: string = "SCALEO_CONCENTRATEUR-HORIZON-S5.jpg";
	static readonly scaleoInnogenG2HFBatterie12Cellules: string = "SCALEO_INOGEN-G2-HF-BATTERIE-12-CELLULES.jpg";
	static readonly scaleoInogenG5Batterie16Cellules: string = "SCALEO_INOGEN-G5-BATTERIE-16-CELLULES.jpg";
	static readonly scaleoInogenG4Batterie8Cellules: string = "SCALEO_INOGEN-G4-BATTERIE-8-CELLULES.jpg";
	static readonly scaleoInogenG3HFBatterie16Celllules: string = "SCALEO_INOGEN-G3-HF-BATTERIE-16-CELLULES.jpg";

	static readonly sefamDreamstar: string = "SEFAM_DREAMSTAR.jpg";
	static readonly sefamBox: string = "SEFAM_S-BOX.png";
	static readonly sefamGK420E: string = "SEFAM_GK420E.jpg";
	static readonly sefamPpcNea: string = "SEFAM_NEA-AUTO.png";

	static readonly systamAerosolLS2000: string = "SYSTAM_AEROSOL-LS2000.jpg";

	static readonly transcendAuto: string = "TRANSCEND_AUTO.jpg";
	static readonly transcendT3Auto: string = "TRANSCEND_T3-AUTO.jpg";

	static readonly unknownHelios300: string = "UNKNOWN_HELIOS-300.jpg";
	static readonly unknownCompanion1000: string = "UNKNOWN_COMPANION-1000.jpg";
	static readonly unknownHeliosMarathon: string = "UNKNOWN_HELIOS-MARATHON.jpg";
	static readonly unknownCompanion1000T: string = "UNKNOWN_COMPANION-1000T.jpg";
	static readonly unknownAerosolSalterAirPlus: string = "UNKNOWN_AEROSOL-SALTER-AIR-PLUS.jpg";
	static readonly unknownHighFlowStroller: string = "UNKNOWN_HIGH-FLOW-STROLLER.jpg";
	static readonly unknownConcentrateurIFill: string = "UNKNOWN_CONCENTRATEUR-I-FILL.jpg";
	static readonly unknownCompanion550: string = "UNKNOWN_COMPANION-550.jpg";
	static readonly unknownEverfloPediatrique01L: string = "UNKNOWN_EVERFLO-PEDIATRIQUE-01L.jpg";
	static readonly unknownStroller: string = "UNKNOWN_STROLLER.jpg";
	static readonly unknownSprint: string = "UNKNOWN_SPRINT.jpg";
	static readonly unknownCompanion41L: string = "UNKNOWN_COMPANION-41L.jpg";
}
export class DeviceModels {
	static readonly recordedDevice: Record<string, string> = {
		// AIR PRODUCTS
		"BOUTEILLE AIR PRODUCTS X05T": DeviceModelsImages.airproductsBouteilleAirProductsX05T,
		"BOUTEILLE AIR PRODUCTS X02T": DeviceModelsImages.airproductsBouteilleAirProductsX02T,
		"BOUTEILLE AIR PRODUCTS X15T": DeviceModelsImages.airproductsBouteilleAirProductsX15T,

		//ALMS_ALPHA
		"ALPHA 200 C": DeviceModelsImages.almsAlpha200C,
		"ALPHA 200C": DeviceModelsImages.almsAlpha200C,
		"ALPHA 200P": DeviceModelsImages.almsAlpha200P,
		"ALPHA 300": DeviceModelsImages.almsAlpha300,
		"EOVE-70": DeviceModelsImages.almsEove,

		// BMC
		"#PPC G3 A20 + MODEM": DeviceModelsImages.bmcG3A20,
		"PPC G3 A20 + MODEM": DeviceModelsImages.bmcG3A20,
		"PPC G3 A20 + MODEM 4G": DeviceModelsImages.bmcG3A202,

		// Breas
		"Z1 AUTO": DeviceModelsImages.breasZ1Auto,
		"ISLEEP20+": DeviceModelsImages.breasIsleep25,
		ISLEEP20I: DeviceModelsImages.breasIsleep25,
		ISLEEP22: DeviceModelsImages.breasIsleep25,
		"ISLEEP 20+ E-ADAPT": DeviceModelsImages.breasIsleep25,
		"ISLEEP 25": DeviceModelsImages.breasIsleep25,
		PV102: DeviceModelsImages.breasPv102,
		"VIVO 3": DeviceModelsImages.breasVivo3,
		"VIVO 60": DeviceModelsImages.breasVivo60,
		"VIVO 65": DeviceModelsImages.breasVivo65,

		//CAIRE MEDICAL
		"LIBERATOR 45L": DeviceModelsImages.cairemedicalLiberator45L,
		"HELIOS 46L UNIVERSELLE": DeviceModelsImages.cairemedicalHelios46LUniverselle,

		//CRYOPAL
		"#FREELOX 1.2L US": DeviceModelsImages.cryopalFreelox12LUS,
		"FREELOX 1.2L US": DeviceModelsImages.cryopalFreelox12LUS,
		"#FREELOX 0.5L US": DeviceModelsImages.cryopalFreelox05LUS,
		"FREELOX 0.5L US": DeviceModelsImages.cryopalFreelox05LUS,
		"#FREELOX 44L CE": DeviceModelsImages.cryopalFreelox44LCE,
		"FREELOX 44L CE": DeviceModelsImages.cryopalFreelox44LCE,
		"FREELOX3 0.5L US": DeviceModelsImages.cryopalFreelox305LUS,
		"FREELOX 1.2L CE": DeviceModelsImages.cryopalFreelox12LCE,
		"FREELOX 32L US": DeviceModelsImages.cryopalFreelox32LUS,
		"#FREELOX 0.5L CE": DeviceModelsImages.cryopalFreelox05LCE,
		"FREELOX 0.5L CE": DeviceModelsImages.cryopalFreelox05LCE,
		"#FREELOX 32L CE": DeviceModelsImages.cryopalFreelox32LCE,
		"FREELOX 32L CE": DeviceModelsImages.cryopalFreelox32LCE,
		"FREELOX 44L US": DeviceModelsImages.cryopalFreelox44LUS,

		// Devilbiss
		"PPC BLUE AUTOPLUS": DeviceModelsImages.devilbissPpcBlueAutoplus,
		"SLEEPCUBE BILEVEL S/T": DeviceModelsImages.devilbissSleepcubeAuto,
		"SLEEPCUBE AUTO": DeviceModelsImages.devilbissSleepcubeAuto,
		"CONCENTRATEUR COMPACT 525 KS": DeviceModelsImages.devilbissConcentrateurCompact525KS,
		"#AEROSOL AIR FORCE MAX": DeviceModelsImages.devilbissAerosolAirForceMax,
		"AEROSOL AIR FORCE MAX": DeviceModelsImages.devilbissAerosolAirForceMax,
		"CONCENTRATEUR 1025 KS 10L": DeviceModelsImages.devilbissAerosolAirForceMax,
		"BOUTEILLE 1.8L CONTINU POUR I-FILL": DeviceModelsImages.devilbissBouteille18LContinuIFill,
		"BOUTEILLE 2.9L CONTINU POUR I-FILL": DeviceModelsImages.devilbissBouteille29LContinuIFill,
		"AEROSOLPULMO-AIDE": DeviceModelsImages.devilbissAerosolPulmoAide,

		// DTF MEDICAL
		"AEROSOL AERODJINN+": DeviceModelsImages.dtfmedicalAerosolAerodjinn,
		"AEROSOL MANOSONIQUE": DeviceModelsImages.dtfmedicalAerosolManasonique,
		"AEROSOL ATOMISOR BOX PLUS": DeviceModelsImages.dtfmedicalAerosolAtomisorBoxPlus,

		// Fischer & Praykel
		"#ICON AUTO": DeviceModelsImages.fischerpaykelIconAuto,
		HC221: DeviceModelsImages.fischerpaykelIconAuto,
		"ICON AUTO": DeviceModelsImages.fischerpaykelIconAuto,
		"MYAIRVO 2": DeviceModelsImages.fisherPaykelMyairvo2,
		"AIRVO 2": DeviceModelsImages.fisherPaykelAirvo2,

		// GCE
		"CONCENTRATEUR ZEN-O 24 CELLULES": DeviceModelsImages.gceConcentrateurZenO24Cellulles,
		"CONCENTRATEUR ZEN-O LITE + BATTERIE 16 CELLULES":
			DeviceModelsImages.gceConcentrateurZenOLiteBatterie16Cellules,
		"CONCENTRATEUR ZEN-O LITE + BATTERIE 8 CELLULES": DeviceModelsImages.gceConcentrateurZenOLiteBatterie8Cellules,

		//HAWA
		"ACAPELLA CHOICE": DeviceModelsImages.hawaAcapellaChoice,

		// hoffrichter
		"#TREND III BILEVEL ST": DeviceModelsImages.hoffrichterTrendIIIBilevelSt,
		"LAVI 40": DeviceModelsImages.hoffrichterLavi40,
		"LAVI 45": DeviceModelsImages.hoffrichterLavi40,
		"TREND III AUTOBILEVEL ST": DeviceModelsImages.hoffrichterTrendIIIBilevelSt,
		"TREND III BILEVEL ST": DeviceModelsImages.hoffrichterTrendIIIBilevelSt,

		// INOGEN
		"#INOGEN G3 HF + BATTERIE 8 CELLULES": DeviceModelsImages.inogenG3HFBatterie8Celllules,
		"INOGEN G3 HF + BATTERIE 8 CELLULES": DeviceModelsImages.inogenG3HFBatterie8Celllules,
		"#INOGEN G3 + BATTERIE 8 CELLULES": DeviceModelsImages.inogenG3Batterie8Celllules,
		"INOGEN G3 + BATTERIE 8 CELLULES": DeviceModelsImages.inogenG3Batterie8Celllules,
		"#INOGEN G3 + BATTERIE 16 CELLULES": DeviceModelsImages.inogenG3Batterie16Celllules,
		"INOGEN G3 + BATTERIE 16 CELLULES": DeviceModelsImages.inogenG3Batterie16Celllules,

		// INVACARE
		"BOUTEILLE 1.7L CONTINU POUR HOMEFILL": DeviceModelsImages.invacareBouteille17LContinuHomefill,
		"COMPRESSEUR HOMEFILL2": DeviceModelsImages.invacareCompresseurHomefill2,
		PERFECTO2: DeviceModelsImages.invacarePerfecto2V,
		"PLATINUM 9": DeviceModelsImages.invacarePlatinum9,
		"AEROSOL STRATOS PRO": DeviceModelsImages.invacareAerosolStratosPro,
		"PLATINUM 5": DeviceModelsImages.invacarePlatinum5,
		"NPU/AEROSOLENVOY": DeviceModelsImages.invacareAerosolEnvoy,
		"CONCENTRATEUR PORTABLE SOLO 2": DeviceModelsImages.invacareConcentrateurPortableSolo2,

		// L3-MEDICAL
		"ECLIPSE 5": DeviceModelsImages.l3medicalEclipse5,
		"AEROSOL MINIPLUS": DeviceModelsImages.l3medicalAerosolMiniplus,
		"#ECLIPSE III": DeviceModelsImages.l3medicalEclipse3,
		"ECLIPSE III": DeviceModelsImages.l3medicalEclipse3,
		"CONCENTRATEUR VISIONAIRE AIRSEP": DeviceModelsImages.l3medicalConcentrateurVisionaireAirsep,
		"AEROLIFE 2": DeviceModelsImages.l3medicalAerolife2,
		"CLEARWAY 2": DeviceModelsImages.l3medicalClearway2,
		"CLEARWAY COUGH ASSISTOR": DeviceModelsImages.l3medicalClearwayCoughAssistor,

		//LINDE
		"BOUTEILLE LINDE L05R": DeviceModelsImages.lindeBouteilleL05R,
		"BOUTEILLE LINDE L05V": DeviceModelsImages.lindeBouteilleL05V,
		"BOUTEILLE LINDE L02V": DeviceModelsImages.lindeBouteilleL02V,
		"BOUTEILLE LINDE L15R": DeviceModelsImages.lindeBouteilleL15R,
		"BOUTEILLE LINDE L02R": DeviceModelsImages.lindeBouteilleL02R,
		"BOUTEILLE LINDE L15V": DeviceModelsImages.lindeBouteilleL15V,

		// Lowenstein
		"#PRISMA SMART": DeviceModelsImages.lowensteinPrismaSmart,
		"PRISMA 20 A": DeviceModelsImages.lowensteinPrisma,
		"PRISMA 25 S": DeviceModelsImages.lowensteinPrisma,
		"PRISMA 25 ST": DeviceModelsImages.lowensteinPrisma,
		"PRISMA 30 ST": DeviceModelsImages.lowensteinPrisma,
		"PRISMA CR": DeviceModelsImages.lowensteinPrisma,
		"PRISMA SMART": DeviceModelsImages.lowensteinPrismaSmart,
		"PRISMA SMART (VENTE)": DeviceModelsImages.lowensteinPrisma,
		"PRISMA SMART BLANCHE MAX": DeviceModelsImages.lowensteinSmartWhite,
		"PRISMA VENT30-C": DeviceModelsImages.lowensteinVent30,
		"PRISMA VENT 40": DeviceModelsImages.lowensteinVent30,
		"PRISMA VENT50-C": DeviceModelsImages.lowensteinVent50,
		"SOMNOBALANCE E": DeviceModelsImages.lowensteinSomnoBalanceE,
		SOMNOCLICK: DeviceModelsImages.lowensteinSomnoClick,
		SOMNOCOMFORT: DeviceModelsImages.lowensteinSomnocomfort,
		"SOMNOCOMFORT 2": DeviceModelsImages.lowensteinSomnocomfort,
		"SOMNOSMART 2": DeviceModelsImages.lowensteinSomnocomfort,
		"SOMNOSMART 2 AVEC": DeviceModelsImages.lowensteinSomnosmartClick,
		"SOMNOSMART 2 AVEC SOMNOCLICK": DeviceModelsImages.lowensteinSomnosmartClick,
		"SOMNOSOFT +": DeviceModelsImages.lowensteinSomnoBalanceE,
		"SOMNOSOFT 2E": DeviceModelsImages.lowensteinSomnoBalanceE,
		"SOMNOVENT AUTO ST": DeviceModelsImages.lowensteinSomnovent,
		"SOMNOVENT CR": DeviceModelsImages.lowensteinSomnovent,
		"SOMNOVENT ST": DeviceModelsImages.lowensteinSomnovent,
		"VENTILATEUR LUISA": DeviceModelsImages.lowensteinLuisa,
		VENTIMOTION: DeviceModelsImages.lowensteinVentimotion,
		"VENTIMOTION 2": DeviceModelsImages.lowensteinVentimotion,

		//MEDIFLUX
		"COMFORTCOUGH 2": DeviceModelsImages.medifluxComfortCough,

		// PARI
		"AEROSOL PARI BOY SX": DeviceModelsImages.pariAerosolBoySX,
		"AEROSOL PARI BOY PRO": DeviceModelsImages.pariAerosolBoyPRO,
		"AEROSOL EFLOW": DeviceModelsImages.pariAerosolEflow,
		"AEROSOL VELOX": DeviceModelsImages.pariAerosolVelox,
		"#AEROSOL PARI BOY SX AVEC VALISE": DeviceModelsImages.pariAerosolBoySXValise,
		"AEROSOL PARI BOY SX AVEC VALISE": DeviceModelsImages.pariAerosolBoySXValise,
		"#CONCENTRATEUR O2 BAS DEBIT OXY 6000": DeviceModelsImages.pariConcentrateur0éBasDebitOXY6000,
		"CONCENTRATEUR O2 BAS DEBIT OXY 6000": DeviceModelsImages.pariConcentrateur0éBasDebitOXY6000,
		"AEROSOL TURBOBOY": DeviceModelsImages.pariAerosolTurboboyN,
		"#AEROSOL PARI BOY MOBILE S": DeviceModelsImages.pariAerosolBoyMobilS,
		"AEROSOL PARI BOY MOBILE S": DeviceModelsImages.pariAerosolBoyMobilS,

		// Philips
		"#DREAMSTATION AUTO": DeviceModelsImages.philipsDreamstation,
		"#DREAMSTATION AUTO REMPLACEMENT": DeviceModelsImages.philipsDreamstation,
		"#DREAMSTATION AUTO REPAIR": DeviceModelsImages.philipsDreamstation,
		"#DREAMSTATION BIPAP AUTO BI-FLEX REMPLAC": DeviceModelsImages.philipsDreamstation,
		"#DREAMSTATION BIPAP AVAPS - REMPLACEMENT": DeviceModelsImages.philipsDreamstationBipapAvaps,
		"BIPAP A30": DeviceModelsImages.philipsPr1BipapA40,
		"BIPAP A30 - 2014": DeviceModelsImages.philipsPr1BipapA40,
		"BIPAP A40": DeviceModelsImages.philipsPr1BipapA40,
		"BIPAP A40 - 2014": DeviceModelsImages.philipsPr1BipapA40,
		"BIPAP A40 PRO": DeviceModelsImages.philipsPr1BipapA40,
		"BIPAP AUTO M-SERIES B-FLEX": DeviceModelsImages.philipsPr1BipapMSeries,
		"BIPAP HARMONY 2": DeviceModelsImages.philipsPr1BipapHarmony2,
		"BIPAP SOH": DeviceModelsImages.philipsPr1BipapSOH,
		"BIPAP ST PR1": DeviceModelsImages.philipsPr1,
		"DREAMSTATION 2 AVEC GSM + HUMIDIFICATEUR": DeviceModelsImages.philipsDreamstation2,
		"DREAMSTATION AUTO": DeviceModelsImages.philipsDreamstation,
		"DREAMSTATION AUTO REMPLACEMENT": DeviceModelsImages.philipsDreamstation,
		"DREAMSTATION AUTO REPAIR": DeviceModelsImages.philipsDreamstation,
		"DREAMSTATION BIPAP AUTOSV": DeviceModelsImages.philipsDreamstationBipapAvaps,
		"DREAMSTATION BIPAP AVAPS": DeviceModelsImages.philipsDreamstationBipapAvaps,
		"DREAMSTATION BIPAP AVAPS - REMPLACEMENT": DeviceModelsImages.philipsDreamstationBipapAvaps,
		"DREAMSTATION BIPAP AUTO BI-FLEX": DeviceModelsImages.philipsDreamstation,
		"DREAMSTATION BIPAP AUTO BI-FLEX REMPLAC": DeviceModelsImages.philipsDreamstation,
		"DREAMSTATION BIPAP AUTO SV REMPLACEMENT": DeviceModelsImages.philipsDreamstationBipapAutosv,
		"DREAMSTATION EXPERTB (P-FLEX)": DeviceModelsImages.philipsDreamstationExpertBPro,
		"DREAMSTATION GO": DeviceModelsImages.philipsDreamstationGo,
		EVERFLO: DeviceModelsImages.philipsEverflo,
		"EVERFLO (VENTE)": DeviceModelsImages.philipsEverflo,
		"PR1 AUTO A-FLEX": DeviceModelsImages.philipsPr1Aflex,
		"PR1 AUTO A-FLEX 2012": DeviceModelsImages.philipsPr12012,
		"PR1 AUTO P-FLEX 2012": DeviceModelsImages.philipsPr12012,
		"PR1 BIPAP AUTO BI-FLEX": DeviceModelsImages.philipsPr1Aflex,
		"PR1 BIPAP AUTO BI-FLEX 2012": DeviceModelsImages.philipsPr12012,
		"PR1 BIPAP AUTO SV ADVANCED": DeviceModelsImages.philipsPr1Aflex,
		"PR1 BIPAP AUTO SV ADVANCED 2012": DeviceModelsImages.philipsPr12012,
		"PR1 BIPAP AVAPS": DeviceModelsImages.philipsPr1Aflex,
		"PR1 BIPAP AVAPS 2012": DeviceModelsImages.philipsPr12012,
		"PR1 PRO C-FLEX": DeviceModelsImages.philipsPr1Aflex,
		"PR1 PRO C-FLEX 2012": DeviceModelsImages.philipsPr12012,
		"PR1 PROCHECK": DeviceModelsImages.philipsPr1,
		"REMSTAR AUTO": DeviceModelsImages.philipsRemstar,
		"REMSTAR AUTO M": DeviceModelsImages.philipsRemstar,
		"REMSTAR AUTO M A-FLEX": DeviceModelsImages.philipsRemstar,
		"REMSTAR C-FLEX": DeviceModelsImages.philipsRemstar,
		"REMSTAR PRO M": DeviceModelsImages.philipsRemstarPro,
		"REMSTAR PRO2": DeviceModelsImages.philipsRemstarPro,
		"TRILOGY EVO": DeviceModelsImages.philipsTrilogyEvo,
		SIMPLYGO: DeviceModelsImages.philipsSimplygo,
		"AEROSOL INNOSPIRE ELEGANCE": DeviceModelsImages.philipsAerosolInnospireElegance,
		"AEROSOL INSPIRATION ELITE": DeviceModelsImages.philipsAerosolInspirationElite,
		"AEROSOL INNOSPIRE DELUXE": DeviceModelsImages.philipsAerosolInnospireDeluxe,
		"SIMPLYGO MINI BATTERIE LONGUE DUREE": DeviceModelsImages.philipsSimplygoMiniBatterieLongueDuree,
		"npu/TRILOGY 100": DeviceModelsImages.philipsNPUTrilogy100,
		"INNOSPIRE GO": DeviceModelsImages.philipsInnospireGo,
		"BIPAP A40 REMPLACEMENT": DeviceModelsImages.philipsBipapA40Remplacement,
		"#DREAMSTATION EXPERT REPAIR": DeviceModelsImages.philipsDreamstationExpertRepair,
		"DREAMSTATION EXPERT REPAIR": DeviceModelsImages.philipsDreamstationExpertRepair,
		MILLENIUM: DeviceModelsImages.philipsMillenium,
		"BIPAP AUTO SV ADVANCED": DeviceModelsImages.philipsBibapAutoSvAdvanced,
		"EVERFLO T POUR ULTRAFILL": DeviceModelsImages.philipsEverfloTUltrafill,
		"COUGHTASSIST E70": DeviceModelsImages.philipsCoughtAssistE70,

		// Resmed
		"#AIRSENSE11 AUTOSET": DeviceModelsImages.resmedAirsense11,
		"AIR CURVE 10 CS PACEWAVE": DeviceModelsImages.resmedAirCuve,
		"AIR CURVE 10 V-AUTO": DeviceModelsImages.resmedAirCuve,
		"AIRMINI AUTOSET": DeviceModelsImages.resmedAirmini,
		"AIRSENSE10 AUTOSET": DeviceModelsImages.resmedAirsense10,
		"AIRSENSE10 AUTOSET FOR HER": DeviceModelsImages.resmedAirsense10,
		"AIRSENSE 10 ELITE": DeviceModelsImages.resmedAirsense10,
		"AIRSENSE11 AUTOSET": DeviceModelsImages.resmedAirsense11,
		"ASTRAL 150": DeviceModelsImages.resmedAstral150,
		"AUTOSET CS2": DeviceModelsImages.resmedAutoset,
		"AUTOSET SPIRIT": DeviceModelsImages.resmedAutosetSpirit,
		"LUMIS 100 VPAP ST": DeviceModelsImages.resmedLumis150VPAPST,
		"LUMIS 150 VPAP ST": DeviceModelsImages.resmedLumis150VPAPST,
		"LUMIS 150 VPAP ST (VENTE)": DeviceModelsImages.resmedLumis150VPAPST,
		"S6 PLUS": DeviceModelsImages.resmedS6,
		"S8 AUTO 25": DeviceModelsImages.resmedS8,
		"S8 AUTOSET SPIRIT": DeviceModelsImages.resmedS8,
		"S8 ELITE": DeviceModelsImages.resmedS8,
		"S8 ELITE 2 EASYBREATHE": DeviceModelsImages.resmedS8,
		"S8 SPIRIT 2 AVEC EASY BREATHE": DeviceModelsImages.resmedS8,
		"S9 AUTO 25": DeviceModelsImages.resmedS9,
		"S9 AUTOSET": DeviceModelsImages.resmedS9,
		"S9 AUTOSET CS": DeviceModelsImages.resmedS9,
		"S9 AUTOSET CS PACEWAVE": DeviceModelsImages.resmedS9,
		"S9 ELITE": DeviceModelsImages.resmedS9,
		"S9 V-AUTO": DeviceModelsImages.resmedS9,
		"S9 VPAP ST": DeviceModelsImages.resmedS9,
		"S9 VPAP ST IVAPS": DeviceModelsImages.resmedS9,
		"STELLAR 100": DeviceModelsImages.resmedStellar,
		"STELLAR 150": DeviceModelsImages.resmedStellar150,
		"VPAP IV ST": DeviceModelsImages.resmedS9,

		// Responsive Respiratory
		RESPOND: DeviceModelsImages.responsiverespiratoryRespond,

		// Sefam
		"DREAMSTAR AUTO + RESERVOIR - MARRON": DeviceModelsImages.sefamDreamstar,
		"DREAMSTAR AUTO + RESERVOIR - NOIRE": DeviceModelsImages.sefamDreamstar,
		"DREAMSTAR DUO ST AVEC RESERVOIR - NOIRE": DeviceModelsImages.sefamDreamstar,
		"DREAMSTAR INFO + RESERVOIR - NOIRE": DeviceModelsImages.sefamDreamstar,
		GK420E: DeviceModelsImages.sefamGK420E,
		GK420S: DeviceModelsImages.sefamGK420E,
		GK425ST: DeviceModelsImages.sefamGK420E,
		"S BOX": DeviceModelsImages.sefamBox,
		"S BOX V2": DeviceModelsImages.sefamBox,
		"PPC NEA AUTO": DeviceModelsImages.sefamPpcNea,

		// SYSTAM
		"AEROSOL LS2000+": DeviceModelsImages.systamAerosolLS2000,

		//SCALEO
		"CONCENTRATEUR HORIZON S5": DeviceModelsImages.scaleoConcentrateurHorizonS5,
		"INOGEN G2 HF + BATTERIE 12 CELLULES": DeviceModelsImages.scaleoInnogenG2HFBatterie12Cellules,
		"INOGEN G5 + BATTERIE 16 CELLULES": DeviceModelsImages.scaleoInogenG5Batterie16Cellules,
		"INOGEN G4 + BATTERIE 8 CELLULES": DeviceModelsImages.scaleoInogenG4Batterie8Cellules,
		"INOGEN G3 HF + BATTERIE 16 CELLULES": DeviceModelsImages.scaleoInogenG3HFBatterie16Celllules,
		"#INOGEN G3 HF + BATTERIE 16 CELLULES": DeviceModelsImages.scaleoInogenG3HFBatterie16Celllules,

		// Transcend
		"#TRANSCEND T3 AUTO": DeviceModelsImages.transcendT3Auto,
		"TRANSCEND AUTO": DeviceModelsImages.transcendAuto,
		"TRANSCEND T3 AUTO": DeviceModelsImages.transcendT3Auto,

		// Unknown
		"HELIOS 300": DeviceModelsImages.unknownHelios300,
		"COMPANION 1000": DeviceModelsImages.unknownCompanion1000,
		"HELIOS MARATHON": DeviceModelsImages.unknownHeliosMarathon,
		"COMPANION 1000T": DeviceModelsImages.unknownCompanion1000T,
		"AEROSOL SALTER AIR PLUS": DeviceModelsImages.unknownAerosolSalterAirPlus,
		"HIGH FLOW STROLLER": DeviceModelsImages.unknownHighFlowStroller,
		"CONCENTRATEUR I FILL": DeviceModelsImages.unknownConcentrateurIFill,
		"COMPANION 550": DeviceModelsImages.unknownCompanion550,
		"EVERFLO PEDIATRIQUE 0-1L": DeviceModelsImages.unknownEverfloPediatrique01L,
		STROLLER: DeviceModelsImages.unknownStroller,
		SPRINT: DeviceModelsImages.unknownSprint,
		"COMPANION 41L": DeviceModelsImages.unknownCompanion41L,
	};
}

export class DeviceModesMappings {
	static readonly acv: string = "acv";
	static readonly asv: string = "asv";
	static readonly asvauto: string = "asvauto";
	static readonly autoset: string = "autoset";
	static readonly autosetForHer: string = "autoset_for_her";
	static readonly autocpap: string = "autocpap";
	static readonly autosetAFlex: string = "autocpap with a-flex";
	static readonly autosetCFlex: string = "autocpap with c-flex";
	static readonly autosetPFlex: string = "autocpap with p-flex";
	static readonly cpap: string = "cpap";
	static readonly cpapCheckCFlexPlus: string = "cpap-check with c-flex+";
	static readonly cpapCheck: string = "cpap-check";
	static readonly cpapCFlexPlus: string = "cpap with c-flex+";
	static readonly cpapCFlex: string = "cpap with c-flex";
	static readonly ivaps: string = "ivaps";
	static readonly spont: string = "spont";
	static readonly spontTimed: string = "spont_timed";
	static readonly timed: string = "timed";
	static readonly vauto: string = "vauto";

	static readonly setModesDeviceModes = [
		this.cpapCheckCFlexPlus,
		this.cpapCheck,
		this.cpapCFlexPlus,
		this.cpapCFlex,
		this.cpap,
	];

	static readonly autoModesDeviceModes = [
		this.autocpap,
		this.autosetAFlex,
		this.autosetCFlex,
		this.autosetPFlex,
		this.autoset,
		this.autosetForHer,
	];

	static readonly pressureAuto: string = "pressure-auto";
	static readonly pressureConstant: string = "pressure-constant";

	static readonly deviceModesRecord: Record<string, string> = {
		[this.acv]: "ACV",
		[this.asv]: "ASV",
		[this.asvauto]: "ASVAuto",
		[this.autoset]: "Autoset",
		[this.autosetForHer]: "Autoset For Her",
		[this.autocpap]: "Autocpap",
		[this.autosetAFlex]: "Autocpap with a-flex",
		[this.autosetCFlex]: "Autocpap with c-flex",
		[this.autosetPFlex]: "Autocpap with p-flex",
		[this.cpap]: "CPAP",
		[this.cpapCheckCFlexPlus]: "CPAP-check with c-flex+",
		[this.cpapCheck]: "CPAP-check",
		[this.cpapCFlexPlus]: "CPAP with c-flex+",
		[this.cpapCFlex]: "CPAP with c-flex",
		[this.ivaps]: "IVAPS",
		[this.spont]: "Spont",
		[this.spontTimed]: "Spont Timed",
		[this.timed]: "Timed",
		[this.vauto]: "VAuto",
	};

	static readonly setModeDeviceModesRecord: Record<string, string> = {
		[this.acv]: "ACV",
	};

	static readonly autoModeDeviceModesRecord: Record<string, string> = {
		[this.acv]: "ACV",
	};
}
