import { Component, Input } from "@angular/core";
import { TableCellDocuments } from "src/app/@shared/types";

@Component({
	selector: "app-table-cell-document",
	templateUrl: "./table-cell-document.component.html",
})
export class TableCellDocumentComponent {
	@Input() items!: TableCellDocuments;
}
