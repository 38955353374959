<div *ngIf="items.healthCenterMembers" class="text-nowrap" style="flex-flow: column; position: relative">
	<ng-container *ngIf="accordeonRowClickedIds[row] !== row">
		<ng-container *ngFor="let member of items.healthCenterMembers.slice(0, 2)">
			<div class="align-self-start text-left">
				<ng-container *ngIf="isScopeSuperAdmin">
					<span class="text-bold">{{ member.prescriberId }} </span><span> &nbsp; - &nbsp; </span>
				</ng-container>
				<span>{{ member.givenName | titlecase }} &nbsp; </span>
				<span>{{ member.familyName | titlecase }}</span>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="accordeonRowClickedIds[row] === row">
		<ng-container *ngFor="let member of items.healthCenterMembers">
			<div class="align-self-start text-left">
				<ng-container *ngIf="isScopeSuperAdmin">
					<span class="text-bold">{{ member.prescriberId }} </span><span> &nbsp; - &nbsp; </span>
				</ng-container>
				<span>{{ member.givenName | titlecase }} &nbsp; </span>
				<span>{{ member.familyName | titlecase }}</span>
			</div>
		</ng-container>
	</ng-container>

	<!-- Members accordeon-->
	<ng-container *ngIf="items.healthCenterMembers.length > 2">
		<input
			type="checkbox"
			class="accordeon-health-centers d-block"
			id="accordeon-tab-{{ row }}"
			(click)="activeAccordeon(row)" />
		<label for="accordeon-tab-{{ row }}" style="top: 5px !important" class="d-block btn pin-circle">
			<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
		</label>
	</ng-container>
</div>
