export class DashboardIndicatorCategories {
	static readonly patients: string = "patients";
	static readonly prescriptions: string = "prescriptions";
}

export class DashboardIndicatorTypeCodes {
	static readonly card: string = "card";
	static readonly graph: string = "graph";
	static readonly list: string = "list";
}
