<div class="ajout-note rounded shadow bg-white position-fixed fadeInRight d-sm-none" [class.active]="isMenuXSOpen">
	<div class="position-absolute end-0 pe-2 close-xs-menu">
		<button class="btn btn-popover pin-circle position-relative shadow" (click)="closeBurgerMenu()">
			<i class="icon-tag-close position-absolute top-50 start-50 translate-middle"></i>
		</button>
	</div>
	<div class="d-flex align-items-center border-bottom pb-2">
		<span class="text-medium flew-grow-1 flex-fill"> Menu </span>
	</div>
	<div class="d-flex justify-content-start flex-column" style="min-height: 26vh">
		<ul class="components ps-3">
			<li class="border-bottom" *ngIf="canShowPatients" routerLinkActive="active">
				<a [routerLink]="urlPatients" class="d-flex text-decoration-none">
					<i class="icon-patients-menu fs-4 align-self-center"></i>
					<span class="py-4 px-2 text-regular fadeInRight text-nowrap"> Mes patients </span>
				</a>
			</li>

			<li>
				<a
					class="d-flex text-decoration-none cursor-pointer"
					(click)="openMessageDialog()"
					(keyup.enter)="openMessageDialog()"
					role="dialog">
					<i class="icon-message fs-5-5 align-self-center pb-1"></i>
					<span class="py-4 px-2 text-regular fadeInRight text-nowrap">
						Contacter <span class="text-capitalize">{{ appConfig.appCompanyName }}</span>
					</span>
				</a>
			</li>
		</ul>
	</div>
	<div class="sidebar-footer align-items-end">
		<ul class="components ps-3 bg-white fadeInTop hidden mb-0 pb-0 pt-1 rounded">
			<li class="border-bottom" *ngIf="isScopeMediviewLocalAdmin || isScopePrescriber">
				<a [routerLink]="['/prescriber/preferences']" class="d-flex text-decoration-none">
					<i class="icon-preferences fs-4 align-self-center"></i>
					<span class="py-4 px-2 text-regular fadeInRight text-nowrap">Préférences</span>
				</a>
			</li>
			<li *ngIf="isAuthenticated" [ngClass]="{ 'border-bottom': isImpersonated }">
				<a
					(click)="logout()"
					class="d-flex text-decoration-none cursor-pointer"
					(keyup.enter)="logout()"
					role="dialog">
					<i class="icon-deconnexion fs-4 align-self-center"></i>
					<span class="py-4 px-2 text-regular fadeInRight text-nowrap">D&eacute;connexion</span>
				</a>
			</li>
			<li *ngIf="isImpersonated">
				<a
					(click)="resetImpersonation()"
					class="d-flex text-decoration-none cursor-pointer"
					(keyup.enter)="resetImpersonation()"
					role="dialog">
					<i class="icon-switch fs-4 align-self-center"></i>
					<span class="py-4 px-2 text-regular fadeInRight text-nowrap">Reset impersonation</span>
				</a>
			</li>
		</ul>
	</div>
	<div class="d-flex justify-content-between mt-2 fs-11">
		<div class="d-flex flex-column text-start ps-3">
			<span class="text-color-primary"> © 2024 SantéCie </span>
			<a
				*ngIf="legalNotice$ | async as legalNotice"
				(click)="openLegalDocumentModal(legalNotice)"
				(keyup.enter)="openLegalDocumentModal(legalNotice)"
				role="dialog"
				class="text-color-primary cursor-pointer">
				Mentions Légales
			</a>
		</div>
		<div class="d-flex flex-column text-end">
			<a
				*ngIf="userTermsAndConditionsAgreementStatus$ | async as userTermsAndConditionsAgreementStatus"
				(click)="openLegalDocumentModal(userTermsAndConditionsAgreementStatus.currentLegalDocument)"
				(keyup.enter)="openLegalDocumentModal(userTermsAndConditionsAgreementStatus.currentLegalDocument)"
				role="dialog"
				class="text-color-primary cursor-pointer">
				CGU
			</a>
			<a
				*ngIf="privacyPolicy$ | async as privacyPolicy"
				(click)="openLegalDocumentModal(privacyPolicy)"
				(keyup.enter)="openLegalDocumentModal(privacyPolicy)"
				role="dialog"
				class="text-color-primary text-nowrap cursor-pointer">
				Politique de confidentialité
			</a>
		</div>
	</div>
</div>
