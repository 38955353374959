<div
	*ngIf="items"
	class="d-flex align-items-center justify-content-center align-items-center flex-wrap"
	style="position: relative">
	<ng-container *ngFor="let tag of items.value; let index = index">
		<span
			class="rounded-pill p-1 ps-2 me-2 d-flex"
			style="padding-left: 10px; white-space: nowrap"
			[ngClass]="{ 'bg-odd': index % 2 !== 0 }">
			{{ tag }}
		</span>
		<br />
	</ng-container>
</div>
