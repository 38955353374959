export interface IDeviceItemResponse {
	deviceName: string;
	isBottle: boolean;
	isVAA: boolean;
	provider: string;
	serialNumber: string;
	settings: IDeviceSettingItemResponse;
	subFamilyCode: string;
	therapyDate: Date;
	treatment: string;
}

export interface IDeviceSettingItemResponse {
	ai: IDeviceSetting | null;
	aiMax: IDeviceSetting | null;
	aiMin: IDeviceSetting | null;
	epap: IDeviceSetting | null;
	fr: IDeviceSetting | null;
	ipap: IDeviceSetting | null;
	ipapMax: IDeviceSetting | null;
	ipapMin: IDeviceSetting | null;
	gradiant: IDeviceSetting | null;
	mainModeVni: IDeviceSetting | null;
	pep: IDeviceSetting | null;
	pepMax: IDeviceSetting | null;
	pepMin: IDeviceSetting | null;
	pressure: IDeviceSetting | null;
	pressureMax: IDeviceSetting | null;
	pressureMin: IDeviceSetting | null;
	pressureSet: IDeviceSetting | null;
	spO2InAir: IDeviceSetting | null;
	spO2InO2: IDeviceSetting | null;
	ti: IDeviceSetting | null;
	triggerPosition: IDeviceSetting | null;
	triggerPositionExpiratory: IDeviceSetting | null;
	nightFlow: IDeviceSetting | null;
	nightFlowLeft: IDeviceSetting | null;
	dayFlow: IDeviceSetting | null;
	dayFlowLeft: IDeviceSetting | null;
	effortFlow: IDeviceSetting | null;
	effortFlowLeft: IDeviceSetting | null;
	o2Flow: IDeviceSetting | null;
	o2FlowLeft: IDeviceSetting | null;
	duration: IDeviceSetting | null;
	instructions: IDeviceSetting | null;
	fiO2: IDeviceSetting | null;
	effortInstructions: IDeviceSetting | null;
}

export interface IConsumable {
	deliveryDate: string;
	model: string;
	showMaskDeliveryDate: boolean;
	treatment: string;
}

export interface IDeviceSetting {
	value: string;
	unit: string;
}

export interface IEquipmentsByPatientItem {
	devices: IDeviceItemResponse[] | null | undefined;
	consumables: IConsumable[] | null | undefined;
	hasHumidifier: boolean;
}

export class GetEquipmentsByPatientCommand {
	patientId!: string;
	medicalUserId!: string;

	constructor(init?: Partial<GetEquipmentsByPatientCommand>) {
		Object.assign(this, init);
	}

	generateUrlQuery(): string {
		return `medicalUserId=${this.medicalUserId}&patientId=${this.patientId}`;
	}
}
