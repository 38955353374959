<div class="modal position-fixed d-block bg-modal-light" tabindex="-1" role="dialog">
	<div class="modal-dialog preferences position-relative" role="document">
		<h3 class="text-bold fs-4 my-2 ms-3 ms-lg-4 py-2 text-start text-dark">{{ dialogData.title }}</h3>
		<div class="modal-content rounded shadow position-relative border-0">
			<div class="modal-body fs-5 p-3 p-lg-4 position-relative">
				<div style="white-space: pre-line">
					<form form [formGroup]="form" class="form p-0" (ngSubmit)="submit($event)" mat-dialog-content>
						<div class="form-global m-0">
							<span class="mb-5 fs-5 p-0 text-gray text-start text-light" mat-dialog-content>
								{{ dialogData.message }}
							</span>
							<div
								class="button-actions p-0 border-0 d-flex justify-content-between flex-row-reverse"
								mat-dialog-actions
								[align]="'center'">
								<button
									class="btn btn-primary text-white bg-color-primary"
									[mat-dialog-close]="false"
									type="submit"
									id="confirmButton">
									{{ dialogData.confirmText }}
								</button>
								<button
									class="btn btn-primary"
									[mat-dialog-close]="true"
									id="cancelButton"
									type="submit">
									{{ dialogData.cancelText }}
								</button>
							</div>
						</div>
					</form>
				</div>
				<div *ngIf="dialogData.isCloseButton" class="close-window position-absolute z-index-4">
					<button
						type="button"
						title="Fermer la modale"
						mat-dialog-close
						class="unstyled shadow bg-white btn color-primary p-1">
						<i class="icon-closed color-primary fs-26"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
