import { BrowsePaging, FromToDetermination } from "src/app/@shared/types";
import { Mixin } from "ts-mixer";

export class BrowseObservationByPatientCommand extends Mixin(BrowsePaging, FromToDetermination) {
	constructor(init?: Partial<BrowseObservationByPatientCommand>) {
		super();
		Object.assign(this, init);
	}

	override generateUrlQuery(): string {
		const baseFragmentsUrl: string = super.generateUrlQuery();
		return `from=${this.formatDate(this.from)}&to=${this.formatDate(this.to)}&${baseFragmentsUrl}`;
	}
}
