export class DeviceAllowedSettingsByType {
	treatment!: string;
	displayName!: string;
	id!: string;
}

export class DeviceAllowedSettings {
	static deviceAllowedSettingsWithDisplayName: DeviceAllowedSettingsByType[] = [
		{ id: "pressureMin", displayName: "Pression min", treatment: "ppc" },
		{ id: "pressureMax", displayName: "Pression max", treatment: "ppc" },
		{ id: "pressureSet", displayName: "Pression fixe", treatment: "ppc" },
		{ id: "aiMax", displayName: "Aide inspiratoire max", treatment: "ppc" },
		{ id: "aiMin", displayName: "Aide inspiratoire min", treatment: "ppc" },
		{ id: "fr", displayName: "Fréquence respiratoire", treatment: "ppc" },
		{ id: "nightFlow", displayName: "Débit la nuit trouvé au domicile", treatment: "o2" },
		{ id: "dayFlow", displayName: "Débit jour trouvé au domicile", treatment: "o2" },
		{ id: "effortFlow", displayName: "Débit à l'effort trouvé au domicile", treatment: "o2" },
		{ id: "o2Flow", displayName: "Débit O2 trouvé au domicile", treatment: "o2" },
		{ id: "duration", displayName: "Durée", treatment: "o2" },
		{ id: "instructions", displayName: "Consignes", treatment: "o2" },
		{ id: "o2FlowLeft", displayName: "Débit O2 relevé", treatment: "o2" },
		{ id: "dayFlowLeft", displayName: "Débit jour relevé", treatment: "o2" },
		{ id: "nightFlowLeft", displayName: "Débit nuit relevé", treatment: "o2" },
		{ id: "effortFlowLeft", displayName: "Débit effort relevé", treatment: "o2" },
		{ id: "fiO2", displayName: "FIO2", treatment: "o2" },
		{ id: "effortInstructions", displayName: "Consignes effort", treatment: "o2" },
		{ id: "PDM_SC_SATURATION_AC_O2", displayName: "Saturation sous O2", treatment: "o2" },
		{ id: "PDM_SC_SATURAT_EN_AIR", displayName: "Saturation air ambiant", treatment: "o2" },
		{ id: "pep", displayName: "Pression expiratoire", treatment: "vni" },
		{ id: "ipap", displayName: "Pression inspiratoire", treatment: "vni" },
		{ id: "mainModeVni", displayName: "Mode principal", treatment: "vni" },
		{ id: "fr", displayName: "Fréquence respiratoire", treatment: "vni" },
		{ id: "ai", displayName: "Aide inspiratoire", treatment: "vni" },
		{ id: "ipapMin", displayName: "Temps insufflation min", treatment: "vni" },
		{ id: "ipapMax", displayName: "Temps insufflation max", treatment: "vni" },
		{ id: "aiMax", displayName: "Aide inspiratoire max", treatment: "vni" },
		{ id: "aiMin", displayName: "Aide inspiratoire min", treatment: "vni" },
		{ id: "gradiant", displayName: "Pente de l'aide inspiratoire", treatment: "vni" },
		{ id: "triggerPosition", displayName: "Trigger Position", treatment: "vni" },
		{ id: "triggerPositionExpiratory", displayName: "Trigger expiratoire Position", treatment: "vni" },
	];
}

export class DeviceSettingsIntervention {
	static readonly pdmPressionExpiratoire: string = "PDM_PRESSION_EXPIRATION";
	static readonly pdmPressionMin: string = "PDM_PRESSION_MIN";
	static readonly pdmPressionMax: string = "PDM_PRESSION_MAX";
	static readonly pdmPressionSaos: string = "PDM_PRESSION_SAOS";
	static readonly pdmScIahResiduel: string = "PDM_SC_IAH_RESIDUEL";
	static readonly pdmScObservanceRelevee: string = "PDM_SC_OBSERVANCE_RELEVEE";
	static readonly pdmAiMax: string = "PDM_AI_MAX";
	static readonly pdmAiMin: string = "PDM_AI_MIN";
	static readonly pdmFr: string = "PDM_FR";
	static readonly pdmPepPeEpap: string = "PDM_PEP_PE_EPAP";
	static readonly pdmPipPiIpap: string = "PDM_PIP_PI_IPAP";
	static readonly pdmModePrincipalVentil: string = "PDM_MODE_PRINCIPAL_VENTIL";
	static readonly pdmAi: string = "PDM_AI";
	static readonly pdmTiMinIpapMin: string = "PDM_TI_MIN_IPAP_MIN";
	static readonly pdmTiMaxIpapMax: string = "PDM_TI_MAX_IPAP_MAX";
	static readonly pdmPente: string = "PDM_PENTE";
	static readonly pdmTriggerPosition: string = "PDM_TRIGGER_POSITION";
	static readonly pdmTriggerExpiPosition: string = "PDM_TRIGGER_EXPI_POSITION";
	static readonly pdmPepMaxi: string = "PDM_PEP_MAXI";
	static readonly pdmDuree: string = "PDM_DUREE";
	static readonly pdmScObservanceO2: string = "PDM_SC_OBSERVANCE_O2";
	static readonly pdmConsignes: string = "PDM_CONSIGNES";
	static readonly pdmDebitO2Laisse: string = "PDM_DEBIT_O2_LAISSE";
	static readonly pdmDebitJourLaisse: string = "PDM_DEBIT_JOUR_LAISSE";
	static readonly pdmDebitNuitLaisse: string = "PDM_DEBIT_NUIT_LAISSE";
	static readonly pdmDebitEffortLaisse: string = "PDM_DEBIT_EFFORT_LAISSE";
	static readonly pdmFio2: string = "PDM_FIO2";
	static readonly pdmConsignesEffort: string = "PDM_CONSIGNES_EFFORT";
	static readonly pdmScSaturationAcO2: string = "PDM_SC_SATURATION_AC_O2";
	static readonly pdmScSaturatEnAir: string = "PDM_SC_SATURAT_EN_AIR";

	static readonly labels: Record<string, string> = {
		PDM_PRESSION_MIN: "Pression min",
		PDM_PRESSION_MAX: "Pression max",
		PDM_PRESSION_SAOS: "Pression fixe",
		PDM_PRESSION_EXPIRATION: "Pression expiratoire",
		PDM_SC_IAH_RESIDUEL: "IAH résiduel",
		PDM_SC_OBSERVANCE_RELEVEE: "Observance",
		PDM_AI_MAX: "Aide inspiratoire max",
		PDM_AI_MIN: "Aide inspiratoire min",
		PDM_FR: "Fréquence respiratoire",
		PDM_PEP_PE_EPAP: "Pression expiratoire",
		PDM_PIP_PI_IPAP: "Pression inspiratoire",
		PDM_MODE_PRINCIPAL_VENTIL: "Mode principal",
		PDM_AI: "Aide inspiratoire",
		PDM_TI_MIN_IPAP_MIN: "Temps insufflation min / Pression inspiratoire min",
		PDM_TI_MAX_IPAP_MAX: "Temps insufflation max / Pression inspiratoire max",
		PDM_PENTE: "Pente de l'aide inspiratoire",
		PDM_TRIGGER_POSITION: "Trigger Position",
		PDM_TRIGGER_EXPI_POSITION: "Trigger expiratoire Position",
		PDM_PEP_MAXI: "Pression expiratoire max",
		PDM_DEBIT_NUIT: "Débit la nuit trouvé au domicile",
		PDM_DEBIT_JOUR: "Débit jour trouvé au domicile",
		PDM_DEBIT_EFFORT: "Débit à l'effort trouvé au domicile",
		PDM_DUREE: "Durée",
		PDM_SC_OBSERVANCE_O2: "Observance O2",
		PDM_DEBIT_O2: "Débit O2 trouvé au domicile",
		PDM_CONSIGNES: "Consignes",
		PDM_DEBIT_O2_LAISSE: "Débit O2 relevé",
		PDM_DEBIT_JOUR_LAISSE: "Débit jour relevé",
		PDM_DEBIT_NUIT_LAISSE: "Débit nuit relevé",
		PDM_DEBIT_EFFORT_LAISSE: "Débit effort relevé",
		PDM_FIO2: "FIO2",
		PDM_CONSIGNES_EFFORT: "Consignes effort",
		PDM_SC_SATURATION_AC_O2: "SPO2 sous O2",
		PDM_SC_SATURAT_EN_AIR: "SPO2 en air ambiant",
	};
}

export class DeviceSettingsSourceTypeCodes {
	public static MEDICAL_CONSTANT = "medical_constant";
	public static DEVICE_SETTINGS = "device_settings";
}
