import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class CommunicationComService {
	private _dashboardChangeSubject = new Subject<boolean>();
	onDashboardChangeSubject$: Observable<boolean> = this._dashboardChangeSubject.asObservable();

	private _patientsFavoritesChangeSubject = new Subject<boolean>();
	onChangeSubjectPatientsFavorites$: Observable<boolean> = this._patientsFavoritesChangeSubject.asObservable();

	private _checkedCardSelectionSubject = new Subject<string>();
	onCheckedCardSelection$: Observable<string> = this._checkedCardSelectionSubject.asObservable();

	private _modalContactOpenSubject = new Subject<any>();
	onModalContactOpen$: Observable<any> = this._modalContactOpenSubject.asObservable();

	private _modalSecretaryContactOpenSubject = new Subject<any>();
	onModalSecretaryContactOpen$: Observable<any> = this._modalSecretaryContactOpenSubject.asObservable();

	private _modalContactStateChangedSubject = new Subject<boolean>();
	onModalContactStateChanged$: Observable<boolean> = this._modalContactStateChangedSubject.asObservable();

	private _prescriberIdsSelectionChangedSubject = new Subject<string>();
	onPrescriberIdsSelectionChanged$: Observable<string> = this._prescriberIdsSelectionChangedSubject.asObservable();

	private _focusOnSearchBarSubject = new Subject<boolean>();
	onSearchBarFocusSubjectChange$: Observable<boolean> = this._focusOnSearchBarSubject.asObservable();

	private _modalFullScreenSubject = new Subject<boolean>();
	onModalFullScreenStateChanged$: Observable<boolean> = this._modalFullScreenSubject.asObservable();

	private _expandPatientFileEvent = new Subject<string>();
	onPatientFileExpandChange$: Observable<string> = this._expandPatientFileEvent.asObservable();

	constructor() {}

	changeDashboardConfiguration(isChanged: boolean): void {
		this._dashboardChangeSubject.next(isChanged);
	}

	changePatientsFavorites(isChanged: boolean): void {
		this._patientsFavoritesChangeSubject.next(isChanged);
	}

	changeModalContactState(isOpen: boolean): void {
		this._modalContactStateChangedSubject.next(isOpen);
	}

	changePrescriberIdsSelection(medicalUserId: string): void {
		this._prescriberIdsSelectionChangedSubject.next(medicalUserId);
	}

	openModalContact(data: any): void {
		this._modalContactOpenSubject.next(data);
	}

	openModalSecretaryContact(data: any): void {
		this._modalSecretaryContactOpenSubject.next(data);
	}

	selectMainItem(id: string) {
		this._checkedCardSelectionSubject.next(id);
	}

	focusOnSearchBar(isChanged: boolean): void {
		this._focusOnSearchBarSubject.next(isChanged);
	}

	openModalOnFullScreen(isFullScreen: boolean): void {
		this._modalFullScreenSubject.next(isFullScreen);
	}

	expandPatientFileEvent(id: string): void {
		this._expandPatientFileEvent.next(id);
	}
}
