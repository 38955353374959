export class MessageHistoryConstant {
	static readonly toTreat: string = "to-treat";
	static readonly read: string = "read";
	static readonly taken: string = "taken";
	static readonly treated: string = "treated";

	static readonly messageHistoryActionRecord: Record<string, string> = {
		[MessageHistoryConstant.toTreat]: "À traiter",
		[MessageHistoryConstant.read]: "Lu",
		[MessageHistoryConstant.taken]: "Pris en charge",
		[MessageHistoryConstant.treated]: "Traité",
	};
}
