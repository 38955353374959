import { Component, Input } from "@angular/core";
import { TableCellDateRange } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-date-range",
	templateUrl: "table-cell-date-range.component.html",
})
export class TableCellDateRangeComponent {
	@Input() item?: TableCellDateRange;
}
