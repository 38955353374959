<div
	class="footer d-none d-sm-flex footer mx-auto px-4 justify-content-start justify-content-xl-center position-relative z-index-3">
	<div class="me-4 text-color-primary">© 2024 SantéCie</div>
	<ul class="d-flex list-unstyled flex-column flex-xl-row">
		<li class="border-end border-2 border-md-none" *ngIf="legalDocumentService.legalNotice$ | async as legalNotice">
			<a
				role="dialog"
				(keyup.enter)="legalDocumentService.openLegalDocumentModal(legalNotice)"
				class="text-color-primary cursor-pointer btn-footer px-2 py-1 mx-2"
				(click)="legalDocumentService.openLegalDocumentModal(legalNotice)">
				Mentions Légales
			</a>
		</li>
		<li
			class="border-end border-2 border-md-none"
			*ngIf="legalDocumentService.privacyPolicy$ | async as privacyPolicy">
			<a
				role="dialog"
				(keyup.enter)="legalDocumentService.openLegalDocumentModal(privacyPolicy)"
				class="text-color-primary cursor-pointer btn-footer px-2 py-1 mx-2"
				(click)="legalDocumentService.openLegalDocumentModal(privacyPolicy)">
				Politique de confidentialité
			</a>
		</li>
		<li
			*ngIf="
				legalDocumentService.userTermsAndConditionsAgreementStatus$
					| async as userTermsAndConditionsAgreementStatus
			">
			<a
				role="dialog"
				(keyup.enter)="
					legalDocumentService.openLegalDocumentModal(
						userTermsAndConditionsAgreementStatus.currentLegalDocument
					)
				"
				class="text-color-primary cursor-pointer btn-footer px-2 py-1 mx-2"
				(click)="
					legalDocumentService.openLegalDocumentModal(
						userTermsAndConditionsAgreementStatus.currentLegalDocument
					)
				">
				CGU
			</a>
		</li>
	</ul>
</div>
