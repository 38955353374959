<div
	class="modal modal-signin position-fixed d-block bg-modal-search pb-5"
	tabindex="-1"
	role="dialog"
	id="modalSignin">
	<div class="close-window position-absolute end-0 mt-2 me-2">
		<button (click)="close()" mat-dialog-close class="unstyled btn close">
			<i class="icon-closed position-absolute top-50 start-50 translate-middle"></i>
		</button>
	</div>
	<div class="position-relative" role="document">
		<h3 class="text-bold fs-4 my-2 ms-3 ms-lg-4 py-2 text-start text-dark">Chercher un patient</h3>

		<form
			[formGroup]="searchPatientsForm"
			(ngSubmit)="onSubmit()"
			class="d-sm-inline-block form-inline mr-auto ml-md-3 my-2 px-2 mw-100 navbar-search">
			<input type="hidden" formControlName="familyName" />
			<input type="hidden" formControlName="givenName" />
			<input type="hidden" formControlName="patientId" />
			<input type="hidden" formControlName="prescriptionId" />
			<div class="input-group pt-2 z-index-100">
				<input
					matInput
					[matAutocomplete]="auto"
					placeholder="Chercher un patient par son nom, prénom ou date de naissance"
					formControlName="patientDisplayName"
					type="text"
					class="form-control rounded-pill bg-white border-0 small py-3"
					aria-label="Search"
					aria-describedby="basic-addon2" />
				<div class="input-group-search position-absolute end-0">
					<button class="btn pt-3" type="submit" title="search">
						<i class="icon-search fs-22"></i>
					</button>
				</div>
				<mat-autocomplete
					#auto="matAutocomplete"
					[displayWith]="displayPatient"
					(optionSelected)="onPatientSelected($event.option.value)"
					class="custom-autocomplete">
					<mat-option *ngFor="let filteredPatient of filteredPatients$ | async" [value]="filteredPatient">
						<div class="searchbar text-light py-1">
							<div class="row justify-content-between">
								<div class="col-1 text-color-primary align-self-center px-1">
									<i
										class="fs-1 me-2 d-block"
										[ngClass]="{
											'icon-patient': filteredPatient.sex === sexes.male,
											'icon-patiente': filteredPatient.sex === sexes.female,
											'icon-patient-unknown': filteredPatient.sex === null,
										}">
									</i>
								</div>
								<div class="col-11 align-self-center lh-18 ps-4">
									{{ filteredPatient.givenName | titlecase }}&nbsp;
									<span class="text-uppercase text-bold">{{ filteredPatient.familyName }}&nbsp;</span>
									({{ filteredPatient.patientId }})
									<div class="fs-12p">
										{{ filteredPatient.age }} ans ({{ filteredPatient.birthDate | titlecase }}) |
										{{ filteredPatient.postalCode | titlecase }}
										{{ filteredPatient.city | titlecase }} |
										<span
											*ngFor="
												let treatmentCode of filteredPatient.activeTreatmentCodesForPatient
											">
											{{ treatmentCode | label: "treatmentCode" | titlecase }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</div>
			<ng-container *ngIf="onPreviewModeChange$ | async as isPreviewMode">
				<p class="text-lg-end text-start adv-search pt-2"><a href="#">Recherche avancée</a></p>
			</ng-container>
		</form>
	</div>
</div>
