export const environment = {
	production: true,
	amCharts5LicenseKey: "##{amCharts5LicenseKey}##",
	appCompanyName: "##{appCompanyName}##",
	appCompanyUrl: "##{appCompanyUrl}##",
	appDisplayName: "##{appDisplayName}##",
	appDomain: "##{appDomain}##",
	appName: "##{appName}##",
	clientId: "##{clientId}##",
	clientRoot: "##{clientRoot}##",
	clientSecret: "##{clientSecret}##",
	environmentName: "##{environmentName}##",
	hostApi: "##{hostApi}##",
	hostAuth: "##{hostAuth}##",
	hostHub: "##{hostHub}##",
	isDesignIntegration: false,
	isFeaturePreviewOn: "##{isFeaturePreviewOn}##",
	pandalabProject: "##{pandalabProject}##",
	secureRoutes: "##{secureRoutes}##",
	userSessionNbMinutes: "##{userSessionNbMinutes}##",
};
