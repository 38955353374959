import moment from "moment";
export {};

Date.prototype.toIso8601 = function (this: Date): string {
	const dateToIsoString = this.toISOString();
	const dateToIso8601 = dateToIsoString.substring(0, dateToIsoString.length - 1) + "0000";

	return dateToIso8601;
};

Date.prototype.toEndOfDayUTC = function (this: Date): Date {
	return moment(new Date(this)).endOf("day").utc().toDate();
};

Date.prototype.toStartOfDayUTC = function (this: Date): Date {
	return moment(new Date(this)).startOf("day").utc().toDate();
};

Date.prototype.toLocalTimezone = function (this: Date): Date {
	return moment(new Date(this)).local().toDate();
};

Date.prototype.isDateOutOfRange = function (this: Date): boolean {
	const date: Date = new Date(this);
	const currentDate = new Date().getFullYear();

	return date < new Date(currentDate - 150, 0, 1) || date > new Date(currentDate + 150, 11, 31);
};
