import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService, AuthService } from "src/app/services";
import { AuthorityHttpParam } from "src/app/interceptors";

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
	private _apiRoot!: string;
	private _authApiRoot!: string;

	constructor(
		appConfigService: AppConfigService,
		private _authService: AuthService
	) {
		const config = appConfigService.appConfig;
		this._apiRoot = config.hostApi;
		this._authApiRoot = config.hostAuth;
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!/^(http|https):/i.test(request.url)) {
			request = request.clone({
				url: this.getApiRootByParams(request.params) + request.url,
				headers: this.getHeaders(request),
			});
		}
		return next.handle(request);
	}

	private getApiRootByParams(params: HttpParams): string {
		if (params instanceof AuthorityHttpParam && params.isAuthorityRequest) {
			return this._authApiRoot;
		}

		return this._apiRoot;
	}

	private getHeaders(request: HttpRequest<any>): HttpHeaders {
		const userPrescriberIds = this._authService.user?.scopePrescriberIds;

		if (userPrescriberIds) {
			return request.headers.set("Scope-Prescribers", userPrescriberIds.join(";"));
		}

		return request.headers;
	}
}
