export class MedicalUsersLabels {
	static readonly mr: string = "Monsieur";
	static readonly ms: string = "Madame";
	static readonly doctor: string = "Docteur";
	static readonly professor: string = "Professeur";
}

export class MedicalUsers {
	static readonly mr: string = "M.";
	static readonly ms: string = "Mme.";
	static readonly doctor: string = "Dr.";
	static readonly professor: string = "Pr.";

	static readonly labelMappings: Record<string, string> = {
		[MedicalUsers.mr]: MedicalUsersLabels.mr,
		[MedicalUsers.ms]: MedicalUsersLabels.ms,
		[MedicalUsers.doctor]: MedicalUsersLabels.doctor,
		[MedicalUsers.professor]: MedicalUsersLabels.professor,
	};
}

export class AmelinkPlatformAccessCodes {
	static readonly default: string = "default";
	static readonly alpha: string = "alpha";
	static readonly beta: string = "beta";
	static readonly unauthorized: string = "unauthorized";
}
