<div
	*ngIf="item"
	class="d-flex align-items-center justify-content-center align-items-center"
	style="position: relative">
	<div
		[ngClass]="{
			'obs-black':
				item.observanceLevelCode === observanceLevelCodes.noTransmission ||
				item.observanceLevelCode === observanceLevelCodes.notMonitored ||
				item.observanceStatusCode === observanceStatusCodes.noTransmission ||
				item.observanceStatusCode === observanceStatusCodes.notMonitored ||
				item.observanceStatusCode === observanceStatusCodes.remoteMonitoringRefusal,
			'obs-red': item.observanceLevelCode === observanceLevelCodes.ge001h_lt2h,
			'obs-orange': item.observanceLevelCode === observanceLevelCodes.ge2h_lt4h,
			'obs-green': item.observanceLevelCode === observanceLevelCodes.ge4h,
			'obs-purple': item.observanceLevelCode === observanceLevelCodes.lt001h,
		}">
		<div>
			<ng-container [ngSwitch]="item.observanceStatusCode">
				<span *ngSwitchCase="observanceStatusCodes.initialPeriod" class="text-nowrap ps-4">
					Période initiale
				</span>

				<span *ngSwitchCase="observanceStatusCodes.notMonitored" class="text-nowrap ps-4"> Non monitoré </span>

				<span *ngSwitchCase="observanceStatusCodes.remoteMonitoringRefusal" class="text-nowrap ps-4">
					Refus de télésuivi
				</span>

				<span *ngSwitchCase="observanceStatusCodes.noTransmission" class="text-nowrap ps-4">
					Pas de transmission
				</span>

				<span *ngSwitchCase="observanceStatusCodes.lt001h" class="text-nowrap"> 0h </span>

				<span *ngSwitchDefault>
					{{ !item.duration ? "-" : (item.duration | durationFormat: "min" : "hhmm") }}
				</span>
			</ng-container>
		</div>
	</div>
</div>
