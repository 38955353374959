import { Component, Input, OnInit } from "@angular/core";
import { TableCellReadMore } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-read-more-text",
	templateUrl: "./table-cell-read-more-text.component.html",
	styleUrls: ["./table-cell-read-more-text.component.css"],
})
export class TableCellReadMoreTextComponent implements OnInit {
	@Input() item!: TableCellReadMore;

	expand = false;
	anchor = "Lire la suite ...";

	get isLongText(): boolean {
		return this.item.value.split(/\r\n|\r|\n/).length > 2;
	}

	constructor() {}

	ngOnInit(): void {
		this.expand = this.item.defaultExpand ? this.item.defaultExpand : false;

		if (this.item.defaultExpand && this.item.onExpandReadMore && this.item.id) {
			this.anchor = "Replier";
			this.item.onExpandReadMore(this.item.id);
		}
	}

	toggle() {
		this.expand = !this.expand;
		this.anchor = this.expand ? "Replier" : "Lire la suite ...";

		if (this.expand && this.item.onExpandReadMore && this.item.id) {
			this.item.onExpandReadMore(this.item.id);
		}
	}
}
