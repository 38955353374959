<app-table-loader [isFullLoader]="true"></app-table-loader>

<ng-container *ngIf="tableView">
	<!-- Table -->
	<table class="table table-striped align-middle">
		<col *ngFor="let column of tableView.displayColumns" span="1" [style.width]="column.width" />
		<thead class="thead-primary">
			<tr>
				<th *ngFor="let column of tableView.displayColumns" class="text-center">
					<div
						*ngIf="column.headerLabel"
						[ngClass]="{
							'd-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100':
								!column.headerWithoutShadow,
						}">
						<div
							class="justify-content-between"
							[ngClass]="{
								'd-flex': !column.headerWithoutShadow,
							}">
							<button
								*ngIf="column.sortColumnName"
								type="button"
								class="btn pin-circle me-2 position-relative align-self-start z-index-1"
								title="sort">
								<i class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
							</button>

							<span class="text-medium fs-7 align-self-center lh-17">
								{{ column.headerLabel }}
							</span>
							<div *ngIf="column.headerSubLabel" class="d-flex justify-content-center">
								<span class="text-nowrap text-center">
									{{ column.headerSubLabel }}
								</span>
							</div>
							<div>&nbsp;</div>
						</div>
					</div>
				</th>
			</tr>
			<tr class="alert">
				<th *ngFor="let column of tableView.displayColumns" class="text-center">&nbsp;</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let row of cells">
				<td *ngFor="let column of tableView.displayColumns" class="position-relative">
					<div
						style="
							background-color: #e3e3e3;
							background: linear-gradient(
								90deg,
								rgba(227, 227, 227, 1) 0%,
								rgba(227, 227, 227, 1) 35%,
								rgba(230, 230, 230, 1) 100%
							);
							border-radius: 0.5em;
						">
						&nbsp;
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</ng-container>
