<div
	*ngIf="item"
	class="d-flex align-items-center {{
		item.alignCenter === undefined || item.alignCenter === true ? 'justify-content-center' : ''
	}}">
	<div
		*ngIf="item.signingStatus"
		class="w-25 p-1 me-4"
		style="align-items: center; display: flex; flex-flow: column"
		[ngClass]="{
			'prescription-orange':
				item.signingStatus === prescriptionSigningStatus.toSignEarly ||
				item.signingStatus === prescriptionSigningStatus.toSignFuture,
			'prescription-red': item.signingStatus === prescriptionSigningStatus.toSignPast,
			'prescription-green':
				item.signingStatus === prescriptionSigningStatus.upToDate ||
				item.signingStatus === prescriptionSigningStatus.inProgress,
		}">
		<span
			class="pt-1 text-bold text-center"
			[ngClass]="{
				'text-orange':
					item.signingStatus === prescriptionSigningStatus.toSignEarly ||
					item.signingStatus === prescriptionSigningStatus.toSignFuture,
				'text-red': item.signingStatus === prescriptionSigningStatus.toSignPast,
				'text-green':
					item.signingStatus === prescriptionSigningStatus.upToDate ||
					item.signingStatus === prescriptionSigningStatus.inProgress,
			}"
			>{{ item.signingStatusLabel }}</span
		>
	</div>
	<div
		[ngClass]="item.dateRangeTextCssClass"
		class="text-nowrap text-bold"
		style="align-items: center; display: flex; flex-flow: column">
		<span>Du {{ item.dateRangeStartDate | date: "dd/MM/yyyy" }}</span>
		<span *ngIf="item.dateRangeEndDate"> au {{ item.dateRangeEndDate | date: "dd/MM/yyyy" }} </span>
		<span *ngIf="item.dateRangeSubValue">
			{{ item.dateRangeSubValue }}
		</span>
	</div>
</div>
