<div *ngIf="!isTermsAndConditionsOpen" class="modal position-fixed d-block bg-modal-search" tabindex="-1" role="dialog">
	<div class="modal-dialog preferences position-relative" role="document">
		<h3 class="text-bold fs-4 my-2 ms-3 ms-lg-4 py-2 text-start text-dark">Première connexion</h3>
		<div class="modal-content rounded shadow position-relative border-0">
			<div class="modal-body p-3 p-lg-4 position-relative z-index-10">
				<p class="fs-5 text-light">
					Veuillez accepter, les présentes Conditions Générales d’Utilisation qui établissent les règles
					applicables dans le cadre de l’utilisation de l’Extranet Médecin mis à votre disposition.
				</p>
				<form>
					<div class="p-4 ps-0 fs-5-5" style="margin-left: -11px">
						<mat-checkbox
							[(ngModel)]="areTermsAndConditionsAccepted"
							[ngModelOptions]="{ standalone: true }">
							Accepter les
							<button
								type="button"
								class="btn m-0 p-0 text-underline"
								(click)="toggleTermsAndConditions()">
								Conditions Générales d'Utilisation
							</button>
						</mat-checkbox>
					</div>
					<div class="d-flex px-3 pt-4 justify-content-center">
						<div>
							<button
								type="button"
								[disabled]="!areTermsAndConditionsAccepted || isLoading"
								(click)="acceptTermsAndConditions()"
								class="btn btn-primary btn-hover bg-color-primary text-white text-uppercase fs-5">
								Valider
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="position-absolute rounded overflow-hidden end-0 bottom-0">
				<img src="assets/img/bg-partage-confirmation.png" class="d-none d-md-block" alt="CGU" />
			</div>
		</div>
	</div>
</div>

<div
	*ngIf="isTermsAndConditionsOpen"
	[ngClass]="{ fadeInRightLong: isOpenedWithFadeIn, fadeOutRightLong: isClosedWithFadeIn }"
	class="modal modal-cgu position-fixed d-block bg-modal-search"
	tabindex="-1"
	role="dialog">
	<div class="modal-dialog preferences" role="document">
		<h3 class="text-bold fs-4 mb-0 ms-3 ms-lg-4 py-3 text-start text-dark">{{ title }}</h3>
		<div
			class="modal-content rounded shadow position-relative border-0"
			[ngClass]="isOnlyForReading ? 'py-3' : 'pt-3'">
			<div class="modal-body p-3 p-lg-4 modal-body-scroll position-relative cgu-content z-index-2 fs-5">
				<markdown #markdownDiv [data]="description" (ready)="onMarkdownLoad()"></markdown>
			</div>

			<form
				*ngIf="!isOnlyForReading"
				class="d-flex flex-column pb-5 bg-white rounded z-index-2 align-items-center justify-content-center position-absolute bottom-0 end-0">
				<div class="p-4">
					<mat-checkbox
						class="fs-5-5"
						[(ngModel)]="areTermsAndConditionsAccepted"
						[ngModelOptions]="{ standalone: true }">
						Accepter les Conditions Générales d'Utilisation
					</mat-checkbox>
				</div>
				<div>
					<button
						type="button"
						(click)="acceptTermsAndConditions()"
						[disabled]="!areTermsAndConditionsAccepted || isLoading"
						class="btn btn-primary btn-hover bg-color-primary text-white text-uppercase">
						Valider
					</button>
				</div>
			</form>

			<div
				class="position-absolute rounded overflow-hidden end-0 bottom-0"
				[ngClass]="isOnlyForReading ? 'z-index-1' : 'z-index-3'">
				<img
					src="assets/img/bg-partage-confirmation.png"
					class="d-none d-md-block"
					[style.opacity]="isOnlyForReading ? '0.4' : '1'"
					alt="CGU" />
			</div>
			<div class="close-window position-absolute z-index-4">
				<button
					type="button"
					title="Fermer la modale"
					(click)="close()"
					class="unstyled shadow bg-white btn p-1">
					<i class="icon-closed color-primary fs-26"></i>
				</button>
			</div>
		</div>
	</div>
</div>
