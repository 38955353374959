import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppConfigService } from "src/app/services/app-config.service";
import { catchError, Observable, of, Subscription } from "rxjs";
import { SearchPatientsItem } from "src/app/models/analyses/search-patient-result";
import { AuthService } from "src/app/services/auth.service";
import { Logger } from "src/app/services/logger.service";
import { AppConfig } from "src/app/types/app-config";
import { CommunicationComService } from "src/app/services/communication.com.service";
import { HealthCenterSelectorComponent } from "../../health-center-selector/health-center-selector.component";
import { AnnouncementsService } from "src/app/services/announcements.service";
import { AnnouncementItem } from "src/app/models/announcements/announcements.interface";
import { NotificationService } from "src/app/services/notification.service";

const log = new Logger("ModalAnnouncementComponent");

@Component({
	selector: "app-modal-announcement",
	templateUrl: "./modal-announcement.component.html",
})
export class ModalAnnouncementComponent implements OnInit, OnDestroy {
	@ViewChild(HealthCenterSelectorComponent)
	healthCenterSelector!: HealthCenterSelectorComponent;

	isSpecificType = true;
	isAdmin = false;
	isHealthCenterSelectionActive = false;
	appConfig!: AppConfig;
	requestSubmitted = false;
	//Dropdown list entities
	filteredPatients$!: Observable<SearchPatientsItem[]>;
	selectedPatient!: SearchPatientsItem;
	isPatientsSearchLoading = true;
	isPatientsSearchOnError = false;

	currentMessageIdx = 0;
	currentAnnouncement!: AnnouncementItem;
	announcementsLength = Object.keys(this.data).length;

	private _announcementReadingStateSubscription!: Subscription;

	constructor(
		_appConfigService: AppConfigService,
		private _announcementsService: AnnouncementsService,
		private _authService: AuthService,
		private _communicationComService: CommunicationComService,
		private _diag: MatDialogRef<ModalAnnouncementComponent>,
		@Inject(MAT_DIALOG_DATA) public data: AnnouncementItem[],
		private _notificationService: NotificationService
	) {
		this.appConfig = _appConfigService.appConfig;
		this.isAdmin =
			this._authService.user.isAdmin ||
			this._authService.user.isAdministrative ||
			this._authService.user.isCustomerRelationDepartment ||
			this._authService.user.isMedicalTechnicalAdvisor ||
			this._authService.user.isRegionalOrDevelopmentManager;
	}
	ngOnDestroy(): void {
		this._announcementReadingStateSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this.goToNextAnnouncement();
	}

	goToNextAnnouncement() {
		if (this.currentMessageIdx < this.announcementsLength) {
			this.currentAnnouncement = this.data[this.currentMessageIdx];
			this.currentMessageIdx++;
		} else {
			this.close();
		}
	}

	validateMessageReading() {
		this._announcementReadingStateSubscription?.unsubscribe();
		this._announcementReadingStateSubscription = this._announcementsService
			.setAnnouncementReadingState(this.currentAnnouncement.id, true)
			.pipe(
				catchError(error => {
					log.debug(error);
					this._notificationService.showErrorMessage("Erreur lors de la mise à jour du statut");
					return of(undefined);
				})
			)
			.subscribe(() => null);

		this.goToNextAnnouncement();
	}

	close(): void {
		this._diag.close();
		this._communicationComService.changeModalContactState(false);
	}
}
