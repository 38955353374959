import { ApplicationRef, Injectable } from "@angular/core";
import { Subscription, concat, delay, first, interval, of } from "rxjs";
import { Logger } from "./logger.service";
import { SwUpdate } from "@angular/service-worker";

const log = new Logger("AppVersionService");

@Injectable({
	providedIn: "root",
})
export class AppVersionService {
	private _everySixHoursOnceAppIsStableSubscription?: Subscription;

	constructor(
		private _appRef: ApplicationRef,
		private _updates: SwUpdate
	) {
		if (!this._updates.isEnabled) {
			log.info("Service worker not registered.");
			return;
		}

		this._updates.versionUpdates.subscribe(evt => {
			switch (evt.type) {
				case "VERSION_DETECTED":
					log.debug(`Downloading new app version: ${evt.version.hash}`);
					break;
				case "VERSION_READY":
					log.info(`Current app version: ${evt.currentVersion.hash}`);
					log.info(`New app version ready for use: ${evt.latestVersion.hash}`);

					// Reload the page to update to the latest version.
					document.location.reload();
					break;
				case "VERSION_INSTALLATION_FAILED":
					log.info(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
					break;
				case "NO_NEW_VERSION_DETECTED":
					log.info(`No new version detected`);
					break;
			}
		});

		this._updates.unrecoverable.subscribe(event => {
			log.info(
				`An error occurred that we cannot recover! '${event.reason}': the page will be reloaded in 10 seconds`
			);

			of([])
				.pipe(delay(10000))
				.subscribe(_ => {
					document.location.reload();
				});
		});
	}

	registerCheckForUpdates(): void {
		if (!this._updates.isEnabled) return;

		// Allow the app to stabilize first, before starting
		// polling for updates with `interval()`.
		const appIsStable$ = this._appRef.isStable.pipe(first(isStable => isStable === true));
		const everySixHours$ = interval(6 * 60 * 60 * 1000); // 6h
		const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

		this._everySixHoursOnceAppIsStableSubscription?.unsubscribe();
		this._everySixHoursOnceAppIsStableSubscription = everySixHoursOnceAppIsStable$.subscribe(async () => {
			try {
				const updateFound = await this._updates.checkForUpdate();
				log.info(updateFound ? "A new version is available." : "Already on the latest version.");

				if (updateFound) document.location.reload();
			} catch (err) {
				log.error("Failed to check for updates:", err);
			}
		});
	}
}
