import { BrowsePaging } from "src/app/@shared/types/browse-paging";

export class BrowsePrescriptionsByPrescriberCommand extends BrowsePaging {
	medicalUserId!: string;

	constructor(init?: Partial<BrowsePrescriptionsByPrescriberCommand>) {
		super();
		Object.assign(this, init);
	}
}
