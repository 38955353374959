/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
export enum LogLevel {
	Off = 0,
	Error,
	Warning,
	Info,
	Debug,
}

/**
 * Log output handler function.
 */
export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

export class Logger {
	/**
	 * Current logging level.
	 * Set it to LogLevel.Off to disable logs completely.
	 */
	static level = LogLevel.Debug;

	/**
	 * Additional log outputs.
	 */
	static outputs: LogOutput[] = [];

	/**
	 * Enables production mode.
	 * Sets logging level to LogLevel.Warning.
	 */
	static enableProductionMode(): void {
		// Tmp
		// Logger.level = LogLevel.Warning;
		Logger.level = LogLevel.Info;
	}

	constructor(private source?: string) {}

	/**
	 * Logs messages or objects  with the debug level.
	 * Works the same as console.log().
	 */
	debug(...objects: any[]): void {
		this.log(console.log, LogLevel.Debug, objects);
	}

	/**
	 * Logs messages or objects  with the info level.
	 * Works the same as console.log().
	 */
	info(...objects: any[]): void {
		this.log(console.info, LogLevel.Info, objects);
	}

	/**
	 * Logs messages or objects  with the warning level.
	 * Works the same as console.log().
	 */
	warn(...objects: any[]): void {
		this.log(console.warn, LogLevel.Warning, objects);
	}

	/**
	 * Logs messages or objects  with the error level.
	 * Works the same as console.log().
	 */
	error(...objects: any[]): void {
		this.log(console.error, LogLevel.Error, objects);
	}

	private log(func: (...args: any[]) => void, level: LogLevel, objects: any[]): void {
		if (level <= Logger.level) {
			const dateTimeNow = this.getDisplayDate();
			const logLevel = this.getDisplayLogLevel(level);
			const log = this.source
				? ["[" + dateTimeNow + "] [" + logLevel + "] [" + this.source + "]"].concat(objects)
				: ["[" + dateTimeNow + "] [" + logLevel + "]"].concat(objects);

			func.apply(console, log);
			Logger.outputs.forEach(output => output.apply(output, [this.source, level, ...objects]));
		}
	}

	private getDisplayLogLevel(level: LogLevel): string {
		switch (level) {
			case LogLevel.Debug:
				return "DBG";
			case LogLevel.Error:
				return "ERR";
			case LogLevel.Info:
				return "INF";
			case LogLevel.Warning:
				return "WRN";
			default:
				return "";
		}
	}

	private getDisplayDate(): string {
		const d = new Date();

		return (
			d.getFullYear() +
			"-" +
			("0" + (d.getMonth() + 1)).slice(-2) +
			"-" +
			("0" + d.getDate()).slice(-2) +
			"T" +
			("0" + d.getHours()).slice(-2) +
			":" +
			("0" + d.getMinutes()).slice(-2) +
			":" +
			("0" + d.getSeconds()).slice(-2) +
			"." +
			("0" + d.getMilliseconds()).slice(-3)
		);
	}
}
