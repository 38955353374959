export class MessageStatus {
	static readonly needTreatment: string = "need-treatment";
	static readonly inProgress: string = "in-progress";
	static readonly treated: string = "treated";
}

export class MessageStatusErrorMessage {
	static readonly message = {
		changingStatus: {
			error: "Erreur lors du changement de statut du message.",
			ok: "Changement effectué avec succès",
		},
		error: "Erreur lors du changement de statut du message.",
	};
}

export class UserLockoutStatusErrorMessage {
	static readonly message = {
		cancelled: "Modification du statut du compte annulée.",
		error: "Erreur lors de la modification du statut du compte.",
		ok: "Changement du statut effectué avec succès",
	};
}

export class UserUpdatePasswordMessage {
	static readonly message = {
		cancelled: "Modification du mot de passe annulée",
		error: "Erreur lors de la modification du mot de passe",
		errorCurrentPassword: "Mot de passe actuel erroné",
		ok: "Changement du mot de passe effectué avec succès",
	};
}

export class HealthCenterActiveStatusErrorMessage {
	static readonly message = {
		cancelled: "Modification du statut de l'établissement annulée.",
		error: "Erreur lors de la modification du statut de l'établissement.",
		ok: "Changement du statut de l'établissement effectué avec succès",
	};
}

export class HealthCenterMediViewLocalAdminStatusErrorMessage {
	static readonly message = {
		cancelled: "Modification du statut du prescripteur annulée.",
		error: "Erreur lors de la modification du statut du prescripteur.",
		ok: "Changement du statut du prescripteur effectué avec succès",
		userUnavailable: "Le prescripteur n'a pas de compte utilisateur.",
	};
}
