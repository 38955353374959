import { Component, Input } from "@angular/core";
import { TableCellObservancePeriodsStatus } from "src/app/@shared/types/table/table-view";
import { ObservancePeriodTypes } from "src/app/constants";

@Component({
	selector: "app-table-cell-observance-periods-status",
	templateUrl: "table-cell-observance-periods-status.component.html",
})
export class TableCellObservancePeriodsStatusComponent {
	@Input() item?: TableCellObservancePeriodsStatus;

	getMessageStatusClass(periodType: ObservancePeriodTypes): string {
		switch (periodType) {
			case ObservancePeriodTypes.Nb112Hours:
				return "bg-success success";
			case ObservancePeriodTypes.Nb56Hours:
				return "bg-warning warning";
			case ObservancePeriodTypes.NbLess56Hours:
				return "bg-red red";
		}
	}
}
