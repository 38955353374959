import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { ActivityAreas, Companies, RestrictAccessMessages, UserModes } from "src/app/constants";
import { AppConfigService, AuthService, SignalRService } from "src/app/services";
import { Observable, Subscription } from "rxjs";
import { AppConfig, AuthorizationResult, User } from "src/app/types";
import { Features } from "src/app/constants";
import { environment } from "src/environments";

@Component({
	selector: "app-base",
	template: "",
})
export class NavigationBaseComponent implements OnInit, OnDestroy {
	private readonly authService = inject(AuthService);
	private readonly appConfigService = inject(AppConfigService);
	private readonly signalRService = inject(SignalRService);
	private readonly router = inject(Router);

	onPreviewModeChange$: Observable<boolean> = this.appConfigService.onPreviewModeChange$;

	appConfig: AppConfig = this.appConfigService.appConfig;
	appName: string = this.appConfig.appName;
	isDesignIntegration: boolean = environment.isDesignIntegration;

	isAuthenticated = false;
	activityArea: string | null = null;
	isActivityRespiratory = false;
	isActivityScyova = false;

	canShowDashboard = false;
	urlDashboard: string | null = null;
	canShowPatients = false;
	urlPatients: string | null = null;
	canShowPreferences = false;
	urlPreferences: string | null = null;
	canShowPrescriptions = false;
	urlPrescriptions: string | null = null;
	canShowMessaging = false;
	canDisplayMessagingLink = false;
	urlMessaging: string | null = null;
	canShowPatientCareForm = false;
	canDisplayPatientCareFormLink = false;
	urlPatientCareForm: string | null = null;
	canShowSwitchArea = false;
	urlSwitchArea: string | null = null;

	adminMtaAdministrativeMessage = RestrictAccessMessages.adminMtaAdministrative;
	userModes = UserModes;

	// Feature permissions
	hasAnnouncementsFeaturePermission = false;
	hasHealthCentersFeaturePermission = false;
	hasImpersonationFeaturePermission = false;
	hasMedicalTechnicalAdvisorMessagingFeaturePermission = false;
	hasPatientCareFormFeaturePermission = false;
	hasPatientsFeaturePermission = false;
	hasPrescriberMessagingFeaturePermission = false;
	hasPrescriptionsFeaturePermission = false;
	hasUsersFeaturePermission = false;

	// Scopes
	isImpersonated = false;
	isScopePrescriber = false;
	isScopeMediviewLocalAdmin = false;
	isScopeMediviewLocalAdminElivie = false;
	isScopeMediviewLocalAdminAsdia = false;
	isScopeMedicalTechnicalAdvisor = false;
	isScopeAdministrative = false;
	isScopeAdmin = false;
	isScopeSuperAdmin = false;
	isScopeAdminSuperAdmin = false;

	givenName = "";
	familyName = "";
	impersonatedMedicalUserId = "";
	userMode = "";

	private _nbHackAttempt = 0;
	private _authorizationResultSubscription?: Subscription;
	private _modalContactStateChangedSubscription?: Subscription;
	private _modalFullScreenStateChangedSubscription?: Subscription;

	ngOnDestroy(): void {
		this._authorizationResultSubscription?.unsubscribe();
		this._modalContactStateChangedSubscription?.unsubscribe();
		this._modalFullScreenStateChangedSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this._authorizationResultSubscription = this.authService.authorizationResult$.subscribe(authorizationResult => {
			this.isAuthenticated = authorizationResult.isAuthenticated;
			this.setUserInformations(authorizationResult);
			this.setComponentDisplayRules(authorizationResult.user);
		});
	}

	async logout(): Promise<void> {
		await this.authService.logout(false);
	}

	hackPreview(): void {
		if (!this.appConfig.isFeaturePreviewOn) return;

		this._nbHackAttempt++;
		if (this._nbHackAttempt % 10 !== 0) return;
		this.appConfigService.changePreviewMode();
	}

	resetImpersonation(): void {
		this.authService.resetImpersonation();
		this.signalRService.runRegistration(this.authService.user?.currentMedicalUserId);
		this.router.navigate(["/admin"]);
	}

	private getUrlDashboard(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopeMedicalUser) return null;

		if (!user.activityArea) return "/activity-area-selector";

		switch (user.activityArea) {
			case ActivityAreas.scyova:
				return null;
			case ActivityAreas.respiratory:
				return "/prescriber/dashboard";
			default:
				return null;
		}
	}

	private getUrlPatients(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopeMedicalUser) return null;
		if (!user.activityArea) return null;

		switch (user.activityArea) {
			case ActivityAreas.scyova:
				return "/scyova/patients";
			case ActivityAreas.respiratory:
				return "/prescriber/patients";
			default:
				return null;
		}
	}

	private getUrlPreferences(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopeMedicalUser) return null;
		if (!user.activityArea) return null;

		switch (user.activityArea) {
			case ActivityAreas.scyova:
				return "/scyova/preferences";
			case ActivityAreas.respiratory:
				return "/prescriber/preferences";
			default:
				return null;
		}
	}

	private getUrlPrescriptions(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopeMedicalUser) return null;
		if (!user.activityArea) return null;

		switch (user.activityArea) {
			case ActivityAreas.respiratory:
				return "/prescriber/prescriptions";
			case ActivityAreas.scyova:
				return null;
			default:
				return null;
		}
	}

	private getUrlMessaging(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopeMedicalUser) return null;
		if (!user.activityArea) return null;

		switch (user.activityArea) {
			case ActivityAreas.respiratory:
				return "/prescriber/messaging";
			case ActivityAreas.scyova:
				return "/scyova/messaging";
			default:
				return null;
		}
	}

	private getUrlPatientCareForm(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopeMedicalUser) return null;
		if (!user.activityArea) return null;

		switch (user.activityArea) {
			case ActivityAreas.respiratory:
				return "/prescriber/patient-care-form";
			case ActivityAreas.scyova:
				return "/scyova/membership-request";
			default:
				return null;
		}
	}

	private getUrlSwitchArea(user: User): string | null {
		if (!user.isScopeMediviewLocalAdmin && !user.isScopePrescriber) return null;
		if (!user.currentHasMultipleActivityArea) return null;

		return "/activity-area-selector";
	}

	private setComponentDisplayRules(user: User): void {
		if (!user) return;

		this.activityArea = user.activityArea;
		this.isActivityRespiratory = user.isActivityRespiratory;
		this.isActivityScyova = user.isActivityScyova;

		this.isImpersonated = user.isImpersonated;

		this.urlDashboard = this.getUrlDashboard(user);
		this.canShowDashboard = !!this.urlDashboard;

		this.urlPatients = this.getUrlPatients(user);
		this.canShowPatients = !!this.urlPatients;

		this.urlPreferences = this.getUrlPreferences(user);
		this.canShowPreferences = !!this.urlPreferences;

		this.urlPrescriptions = this.getUrlPrescriptions(user);
		this.canShowPrescriptions = !!this.urlPrescriptions;

		this.urlMessaging = this.getUrlMessaging(user);
		this.canShowMessaging = !!this.urlMessaging;
		this.canDisplayMessagingLink = user.hasFeaturePermission(Features.PrescriberMessaging);

		this.urlPatientCareForm = this.getUrlPatientCareForm(user);
		this.canShowPatientCareForm = !!this.urlPatientCareForm;
		this.canDisplayPatientCareFormLink = user.hasFeaturePermission(Features.PatientCareForm);

		this.urlSwitchArea = this.getUrlSwitchArea(user);
		this.canShowSwitchArea =
			this.appConfig.appCompanyName.toLowerCase() === Companies.elivie.toLowerCase() && !!this.urlSwitchArea;
	}

	private setUserInformations(authorizationResult: AuthorizationResult): void {
		if (!authorizationResult.user) return;

		this.isScopeSuperAdmin = authorizationResult.user.isScopeSuperAdmin;
		this.isScopeAdmin = authorizationResult.user.isScopeAdmin || authorizationResult.user.isScopeSuperAdmin;
		this.isScopeAdministrative = authorizationResult.user.isScopeAdministrative;
		this.isScopeMedicalTechnicalAdvisor = authorizationResult.user.isScopeMedicalTechnicalAdvisor;
		this.isScopePrescriber = authorizationResult.user.isScopePrescriber;
		this.isScopeMediviewLocalAdmin = authorizationResult.user.isScopeMediviewLocalAdmin;
		this.isScopeMediviewLocalAdminElivie = authorizationResult.user.isScopeMediviewLocalAdminElivie;
		this.isScopeMediviewLocalAdminAsdia = authorizationResult.user.isScopeMediviewLocalAdminAsdia;
		this.userMode = authorizationResult.user.userMode;

		this.hasAnnouncementsFeaturePermission = authorizationResult.user.hasFeaturePermission(Features.Announcements);
		this.hasHealthCentersFeaturePermission = authorizationResult.user.hasFeaturePermission(Features.HealthCenters);
		this.hasImpersonationFeaturePermission = authorizationResult.user.hasFeaturePermission(Features.Impersonation);
		this.hasMedicalTechnicalAdvisorMessagingFeaturePermission = authorizationResult.user.hasFeaturePermission(
			Features.MedicalTechnicalAdvisorMessaging
		);
		this.hasPatientCareFormFeaturePermission = authorizationResult.user.hasFeaturePermission(
			Features.PatientCareForm
		);
		this.hasPatientsFeaturePermission = authorizationResult.user.hasFeaturePermission(Features.Patients);
		this.hasPrescriberMessagingFeaturePermission = authorizationResult.user.hasFeaturePermission(
			Features.PrescriberMessaging
		);
		this.hasPrescriptionsFeaturePermission = authorizationResult.user.hasFeaturePermission(Features.Prescriptions);
		this.hasUsersFeaturePermission = authorizationResult.user.hasFeaturePermission(Features.Users);

		this.givenName = authorizationResult.user.givenName;
		this.familyName = authorizationResult.user.familyName;

		if (authorizationResult.user.isImpersonated) {
			this.impersonatedMedicalUserId = authorizationResult.user.currentMedicalUserId;
		}
	}
}
