import { Component, OnInit } from "@angular/core";
import { AppConfigService } from "src/app/services/app-config.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { AppConfig } from "src/app/types/app-config";
import { Logger } from "src/app/services/logger.service";

const log = new Logger("ForbiddenComponent");

@Component({
	selector: "app-forbidden",
	templateUrl: "forbidden.component.html",
})
export class ForbiddenComponent implements OnInit {
	isAuthenticated = false;
	forbiddenMessage!: string;
	displayAdminRedirect = false;
	displayDashboardRedirect = false;
	isDesignIntegration: boolean = environment.isDesignIntegration;
	appConfig!: AppConfig;

	constructor(
		_appConfigService: AppConfigService,
		private _authService: AuthService,
		private _router: Router
	) {
		this.appConfig = _appConfigService.appConfig;
	}

	ngOnInit(): void {
		if (this._authService.user.isImpersonated) {
			this.displayDashboardRedirect = true;
			this.forbiddenMessage =
				"Le prescripteur sujet à l'impersonnation n'a pas l'autorisation d'accéder à cette page";
		} else if (
			this._authService.user.isAdmin ||
			this._authService.user.isAdministrative ||
			this._authService.user.isCustomerRelationDepartment ||
			this._authService.user.isMedicalTechnicalAdvisor ||
			this._authService.user.isMediviewLocalAdmin ||
			this._authService.user.isRegionalOrDevelopmentManager
		) {
			this.displayAdminRedirect = true;
			this.forbiddenMessage =
				"Afin d'accéder à cette page, il vous faut d'abord vous 'impersonner' en tant que prescripteur";
		} else {
			this.forbiddenMessage = "Vous n'avez pas l'autorisation d'accéder à cette page";
		}
		log.info(this.forbiddenMessage);
	}

	redirectToAdmin(): void {
		log.debug(`Navigate to /admin`);
		this._router.navigate(["/admin"]);
	}

	redirectToDashboard(): void {
		log.debug(`Navigate to /prescriber/dashboard`);
		this._router.navigate(["/prescriber/dashboard"]);
	}
}
