<!-- contenu global du composant -->
<div id="content-wrapper" class="d-flex flex-column">
	<div id="content">
		<!-- navigation du composant -->
		<app-header
			firstLabel="Bienvenue,"
			secondLabel="{{ user.currentTitle }} {{ user.currentGivenName | titlecase }} {{
				user.currentFamilyName | uppercase
			}}"
			secondLabelAbbr="{{ user.givenName | titlecase }} {{ user.familyName | uppercase }} log as a prescriber"
			[showSecondLabelAbbr]="user.isImpersonated"
			[showSearchPatientsForm]="false"
			[showActions]="false"
			[showContacts]="false"
			specificPhoneNumber="09 70 80 89 38"></app-header>
		<!-- contenu du composant -->
		<div class="container-fluid">
			<div class="row position-relative">
				@if (medicalUserCard$ | asyncStatus | async; as medicalUserCard) {
					<div
						class="col-12 col-xl-6 mb-3 mb-xl-0 order-1 order-xl-1 position-relative z-index-3 mt-4 mt-sm-0">
						<ng-template [ngIf]="medicalUserCard.value">
							@if (medicalUserCard.value.hasMultipleActivityAreas) {
								<div class="row gx-3 gx-xl-4 mb-5">
									@if (showScyova) {
										<div class="col-6 col-sm-4 pb-3 pb-sm-0">
											<button
												type="button"
												(click)="goToScyovaActivityArea()"
												class="card border border-1 card-scyova card-indicateur pt-2 pb-4 h-100 border-left-primary bg-primary bg-hover cs-pointer">
												<div class="card-body py-lg-0 p-md-2">
													<div
														class="d-flex flex-column justify-content-around h-100 bd-highlight">
														<div
															class="p-2 align-self-center flex-grow-1 d-flex align-items-center fs-picto">
															<i class="icon-parkour-scyova"></i>
															<img
																src="assets/img/dashboard/parkour-scyova-rgb.svg"
																class="scyova-rgb"
																alt="" />
														</div>
														<div class="px-2 align-self-center bd-highlight">
															<div class="mt-2 mb-4 fs-20">
																<span
																	class="d-inline-block text-center d-sm-block me-2 text-ultra-light text-darkblue">
																	Programme d'apprentissage
																</span>
															</div>
														</div>
													</div>
												</div>
											</button>
										</div>
									}
									<div class="col-6 col-sm-4 pb-3 pb-sm-0">
										<button
											type="button"
											(click)="goToRespiratoryActivityArea()"
											class="card border border-1 card-scyova card-indicateur pt-2 pb-4 h-100 border-left-primary bg-blue bg-hover cs-pointer">
											<div class="card-body py-lg-0 p-md-2 mx-auto">
												<div
													class="d-flex flex-column justify-content-around h-100 bd-highlight">
													<div
														class="p-2 align-self-center flex-grow-1 d-flex align-items-center fs-picto opacity-25">
														<i class="icon-patients-appairage"></i>
													</div>
													<div class="px-2 align-self-center bd-highlight">
														<div class="mt-2 mb-4 fs-20">
															<span
																class="d-inline-block text-center d-sm-block me-2 text-ultra-light">
																Activité
															</span>
															<span class="text-bold text-uppercase fs-4">
																Respiratoire
															</span>
														</div>
													</div>
												</div>
											</div>
										</button>
									</div>
								</div>
							} @else {
								<div class="bg-white position-relative rounded shadow p-3 overflow-hidden">
									<div class="w-60 w-xs-100 position-relative z-index-2">
										<p>
											À ce jour vous n’avez plus ou pas encore de patients chez
											<span class="text-capitalize">{{ appConfig.appCompanyName }}</span
											>.
										</p>
										<p class="mb-0">
											Pour nous contacter, vous pouvez utiliser la messagerie dédiée à cet effet
											ou téléphoner à votre conseiller médico-technique ou votre agence
											<span class="text-capitalize">{{ appConfig.appCompanyName }}</span
											>.
										</p>
									</div>
									<div class="position-absolute end-0 bottom-0 z-index-1 text-end d-block d-sm-none">
										<img
											src="assets/img/dashboard/bg-whiteboard.png"
											class="img-fluid w-70 position-relative bg-no-list"
											alt="Vous n'avez pas de message" />
									</div>
								</div>
							}
						</ng-template>

						<ng-template [ngIf]="medicalUserCard.loading"> ... </ng-template>
						<ng-template [ngIf]="medicalUserCard.error">
							Une erreur est survenue lors du chargement du composant
						</ng-template>
					</div>
				}
			</div>
		</div>
	</div>
	<div class="mh-125 position-absolute bottom-0 w-100">
		<div class="position-absolute bottom-0 z-index-1 text-start d-none d-sm-block" style="left: -230px">
			<img src="assets/img/bg-left-patient-care-form.png" class="img-fluid h-em-15" alt="" />
		</div>

		<div class="position-absolute bottom-0 z-index-1 d-none d-sm-block" style="right: -30px">
			<img src="assets/img/bg-right-patient-care-form.svg" class="img-fluid mh-290" alt="" />
		</div>
	</div>
</div>
