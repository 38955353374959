export class Scopes {
	static readonly user: string = "mediview-user";
	static readonly prescriber: string = "prescriber";
	static readonly mediviewLocalAdminAsdia: string = "mediview-local-admin-asdia";
	static readonly mediviewLocalAdminElivie: string = "mediview-local-admin-elivie";
	static readonly medicalTechnicalAdvisor: string = "medical-technical-advisor";
	static readonly medicalUser: string = "medical-user";
	static readonly administrative: string = "administrative";
	static readonly admin: string = "admin";
	static readonly superAdmin: string = "super-admin";
	static readonly customerRelationDepartment: string = "customerRelationDepartment";
	static readonly secretary: string = "secretary";

	static readonly all: string[] = [
		Scopes.admin,
		Scopes.superAdmin,
		Scopes.administrative,
		Scopes.customerRelationDepartment,
		Scopes.prescriber,
		Scopes.mediviewLocalAdminAsdia,
		Scopes.mediviewLocalAdminElivie,
		Scopes.medicalTechnicalAdvisor,
		Scopes.medicalUser,
		Scopes.user,
		Scopes.secretary,
	];
}
