import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { appConfig } from "./app/app.config";
import { environment } from "./environments/environment";
import { AppComponent } from "./app";

import "src/app/extensions/array-extensions";
import "src/app/extensions/number-extensions";
import "src/app/extensions/string-extensions";
import "src/app/extensions/date-extensions";
import "moment/locale/fr";

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
