import { Component, Input } from "@angular/core";
import { TableCellCodeState } from "src/app/@shared/types";

@Component({
	selector: "app-table-cell-code-state",
	templateUrl: "table-cell-code-state.component.html",
})
export class TableCellCodeStateComponent {
	@Input() item?: TableCellCodeState;
}
