import { Component, inject } from "@angular/core";
import { AppConfigService } from "src/app/services/app-config.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { AppConfig } from "src/app/types/app-config";
import { environment } from "src/environments/environment";
import { Logger } from "src/app/services/logger.service";
import { SharedModule } from "src/app/@shared";

const log = new Logger("UnauthorizedComponent");

@Component({
	imports: [SharedModule],
	selector: "app-unauthorized",
	standalone: true,
	styleUrls: ["./unauthorized.component.scss"],
	templateUrl: "unauthorized.component.html",
})
export class UnauthorizedComponent {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly appConfigService = inject(AppConfigService);
	private readonly authService = inject(AuthService);
	private readonly router = inject(Router);

	isIdentityServerOnError$: Observable<boolean> = this.authService.isIdentityServerOnError$;
	isDesignIntegration: boolean = environment.isDesignIntegration;
	appConfig: AppConfig = this.appConfigService.appConfig;

	returnUrl$: Observable<string | null> = this.activatedRoute.queryParamMap.pipe(
		map((params: ParamMap) => params.get("returnUrl")),
		map((returnUrl: string | null) => {
			if (!returnUrl) return null;
			log.warn(`Force login with return url: ${returnUrl}`);

			this.authService.tryForceLogin(returnUrl).finally(() => {
				log.debug(`Force login done`);
			});

			return returnUrl;
		})
	);

	redirectToDashboard(): void {
		this.router.navigate(["/prescriber/dashboard"]);
	}
}
