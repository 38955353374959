import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class TableMatPaginatorIntl extends MatPaginatorIntl {
	override firstPageLabel = "Première page";
	override itemsPerPageLabel = "Élements par page";
	override lastPageLabel = "Dernière page";
	override nextPageLabel = "Page suivante";
	override previousPageLabel = "Page précédente";

	override getRangeLabel = (page: number, pageSize: number, length: number): string => {
		if (length === 0 || pageSize === 0) {
			return `0 sur ${length}`;
		}

		length = Math.max(length, 0);

		const startIndex = page * pageSize;
		return `${startIndex + 1} sur ${length}`;
	};
}
