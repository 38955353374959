import { Features } from "../constants/features.constant";
import { Permissions } from "../constants/permissions.constant";
import { User } from "./user";

export class AuthorizationResult {
	user!: User;
	isAuthenticated = false;
	isIdentityServerConfigured = false;
	isIdentityServerOnError = false;

	constructor(
		isIdentityServerConfigured: boolean,
		isIdentityServerOnError: boolean,
		isAuthenticated: boolean,
		user?: User
	) {
		this.isIdentityServerConfigured = isIdentityServerConfigured;
		this.isIdentityServerOnError = isIdentityServerOnError;
		this.isAuthenticated = isAuthenticated;

		if (user) this.user = user;
	}

	hasFeaturePermission(feature: Features, permission: Permissions): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated || !this.user)
			return false;

		return this.user.hasFeaturePermission(feature, permission);
	}

	hasRoles(roles: string[]): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated || !this.user)
			return false;

		return this.user.hasRoles(roles);
	}

	hasScopes(scopes: string[]): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated || !this.user)
			return false;

		return this.user.hasScopes(scopes);
	}

	hasActivityAreas(activityAreas: string[]): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated || !this.user)
			return false;

		return this.user.hasActivityAreas(activityAreas);
	}

	canAccessActivityAreaContent(): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated || !this.user)
			return false;

		return this.user.isActivityAreaSelected();
	}

	canAccessAuthorizedContent(): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated) return false;

		return true;
	}
}
