import { Pager } from "src/app/@shared/types/pager.interface";

// Commands
export interface CreateUserMediviewCommand {
	agencyCodes: string[];
	email: string;
	familyName: string;
	givenName: string;
	medicalTechnicalAdvisorId: string;
	phoneNumber: string;
	roles: string[];
	title: string;
	sendAccountActivationNotification: boolean;
	userName: string;
}

export interface UpdateUserMediviewCommand {
	agencyCodes: string[];
	amelinkPlatformAccessCode?: string;
	email: string;
	familyName: string;
	givenName: string;
	hasSignatureBypassRules?: boolean;
	medicalTechnicalAdvisorId?: string;
	medicalUserId?: string;
	roles: string[];
	title: string;
	userName: string;
}

export interface UpdatePasswordUserMediviewCommand {
	currentPassword: string;
	newPassword: string;
}

export interface AdminResetUsersCommand {
	userName: string;
}

export interface SwitchUserStatusCommand {
	userId: string;
	userMustBeLocked: boolean;
}

// Responses
export interface UpdateMediviewUserPasswordResponse {
	userName: string;
	updatePasswordSuccess: boolean;
}

export interface SendAccountActivationNotificationResponse {
	token: string;
}

export interface SearchSecretaryUserResponse {
	items: SearchSecretaryUserResponseItem[];
}

export interface SearchSecretaryUserResponseItem {
	familyName: string;
	givenName: string;
	medicalUserId: string;
	agencies: string;
	title: string;
}

// Others
export interface RoleSelection {
	checked: boolean;
	color: string;
	name: string;
	selected: boolean;
	type: RoleTypes;
	value: string;
}

export enum RoleTypes {
	External,
	Internal,
}

export interface Title {
	color: string;
	name: string;
	value: string;
}

export interface UserData {
	id: string;
	email: string;
	familyName: string;
	givenName: string;
	amelinkAccessCode: string;
	isLocked: boolean;
	lastLoginTime: string;
	phoneNumber: string;
	roles: string[];
	title: string;
	userName: string;
}

export interface UserDataClaims {
	agencyCodes: string[];
	amelinkPlatformAccessCode: string;
	hasSignatureBypassRules: boolean;
	medicalTechnicalAdvisorId: string;
	medicalUserId: string;
}

export type PagerUser = Pager<UserData>;
