<ng-container *ngIf="columnName">
	<div class="filters-added w-100 pb-xl-2 d-flex">
		<ng-container *ngFor="let filter of filters.items; let i = index">
			<ng-container *ngIf="filter.columnName === columnName">
				<button
					type="button"
					(click)="removeFilter(i)"
					class="rounded-pill bg-white mt-2 mt-xl-0 p-1 ps-2 me-2 d-flex">
					<span *ngIf="filterType !== filterTableColumnTypes.date" class="text-gray me-2 text-truncate"
						>{{ filter.filteredColumn.label }}
					</span>
					<span *ngIf="filterType === filterTableColumnTypes.date" class="text-gray me-2 text-truncate"
						>{{ filter.filteredColumn.label | date: "dd/MM/yyyy" }}
					</span>
					<i class="icon-closed text-gray fs-5-closed"></i>
				</button>
			</ng-container>
		</ng-container>
	</div>
</ng-container>
