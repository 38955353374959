import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { CdkTableModule } from "@angular/cdk/table";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MarkdownModule } from "ngx-markdown";

import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatExpansionModule } from "@angular/material/expansion";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { HealthCenterSelectorComponent } from "./components/health-center-selector/health-center-selector.component";
import { ModalConfirmationDialogComponent } from "./components/modals/modal-confirmation-dialog/modal-confirmation-dialog.component";
import { ModalMessageSimpleComponent } from "./components/modals/modal-message-simple/modal-message-simple.component";
import { ModalMessageComponent } from "./components/modals/modal-message/modal-message.component";
import { ModalMessageSecretaryComponent } from "./components/modals/modal-message-secretary/modal-message-secretary.component";
import { ModalSearchComponent } from "./components/modals/modal-search/modal-search.component";
import { ModalTermsAndConditionsComponent } from "./components/modals/modal-terms-and-conditions/modal-terms-and-conditions.component";
import { NavigationMobileComponent } from "./components/navigation-mobile/navigation-mobile.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { ReadMoreComponent } from "./components/read-more/read-more.component";
import { SecuredFileLinkComponent } from "./components/secured-file-link/secured-file-link";
import { SingleFileUploaderComponent } from "./components/single-file-uploader/single-file-uploader.component";
import { TableMobileComponent } from "./components/table-mobile/table-mobile.component";
import { TableCellActionsListComponent } from "./components/table/table-cells/common/table-cell-actions-list/table-cell-actions-list.component";
import { TableCellDateRangeComponent } from "./components/table/table-cells/common/table-cell-date-range/table-cell-date-range.component";
import { TableCellDateComponent } from "./components/table/table-cells/common/table-cell-date/table-cell-date.component";
import { TableCellDocumentComponent } from "./components/table/table-cells/common/table-cell-document/table-cell-document.component";
import { TableCellDurationComponent } from "./components/table/table-cells/common/table-cell-duration/table-cell-duration.component";
import { TableCellMultipleLinesStringComponent } from "./components/table/table-cells/common/table-cell-multiple-lines-string/table-cell-multiple-lines-string.component";
import { TableCellPersonComponent } from "./components/table/table-cells/common/table-cell-person/table-cell-person.component";
import { TableCellReadMoreTextComponent } from "./components/table/table-cells/common/table-cell-read-more-text/table-cell-read-more-text.component";
import { TableCellSelectComponent } from "./components/table/table-cells/common/table-cell-select/table-cell-select.component";
import { TableCellStatusComponent } from "./components/table/table-cells/common/table-cell-status/table-cell-status.component";
import { TableCellHealthCenterMembersComponent } from "./components/table/table-cells/specific/table-cell-health-center-members/table-cell-health-center-members.component";
import { TableCellMessageStatusComponent } from "./components/table/table-cells/specific/table-cell-message-status/table-cell-message-status.component";
import { TableCellObservancePeriodsStatusComponent } from "./components/table/table-cells/specific/table-cell-observance-periods-status/table-cell-observance-periods-status.component";
import { TableCellPatientInterventionTypeComponent } from "./components/table/table-cells/specific/table-cell-patient-intervention-type/table-cell-patient-intervention-type.component";
import { TableCellPrescriptionDateRangeAndStatusComponent } from "./components/table/table-cells/specific/table-cell-prescription-date-range-and-status/table-cell-prescription-date-range-and-status.component";
import { TableCellStringComponent } from "./components/table/table-cells/common/table-cell-string/table-cell-string.component";
import { TableCellTagComponent } from "./components/table/table-cells/common/table-cell-tag/table-cell-tag.component";
import { TableFilterComponent } from "./components/table/table-filter/table-filter.component";
import { TableHeaderDisplayFilterComponent } from "./components/table/table-header-display-filter/table-header-display-filter.component";
import { TableHeaderFilterComponent } from "./components/table/table-header-filter/table-header-filter.component";
import { TableHeaderComponent } from "./components/table/table-header/table-header.component";
import { TableLoaderComponent } from "./components/table/table-loader/table-loader.component";
import { TableMatPaginatorIntl } from "./components/table/table-paginator/table-standard-paginator";
import { TableSkeltonComponent } from "./components/table/table-skeleton/table-skeleton.component";
import { TableStandardComponent } from "./components/table/table-standard/table-standard.component";
import { TableViewComponent } from "./components/table/table-view/table-view.component";
import { CheckAllAccessDirective } from "./directives/check-all-access.directive";
import { CheckPermissionsDirective } from "./directives/check-permission.directive";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { StylePaginatorDirective } from "./directives/style-paginator.directive";
import { AsyncStatusPipe } from "./pipes/async-status.pipe";
import { BooleanPipe } from "./pipes/boolean.pipe";
import { ChunkArrayByColumnPipe } from "./pipes/chunk-array-by-column.pipe";
import { DurationFormatPipe } from "./pipes/duration-format.pipe";
import { JoinPipe } from "./pipes/join.pipe";
import { LabelArrayPipe } from "./pipes/label-array.pipe";
import { LabelPipe } from "./pipes/label.pipe";
import { LunarDateStringPipe } from "./pipes/lunar-date-string.pipe";
import { PhonePipe } from "./pipes/phone.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { TableCellCodeStateComponent } from "./components/table/table-cells/common/table-cell-code-state/table-cell-code-state.component";
import { DownloadUrlPipe } from "./pipes/download-url.pipe";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { PrescriberMessageTableComponent } from "./components/prescriber-messaging/message-table/prescriber-message-table.component";
import { PrescriberMessagingNoMessageComponent } from "./components/prescriber-messaging/no-message/prescriber-messaging-no-message.component";
import { PrescriberMessageCardComponent } from "./components/prescriber-messaging/prescriber-message-card/prescriber-message-card.component";
import { MedicalUserSelectorComponent } from "./components/medical-user-selector/medical-user-selector.component";
import { MultiFileUploaderComponent, OrderByPipe } from ".";
import { DragAndDropDirective } from "./directives/drag-and-drop.directive";
import { FileUploaderComponent } from "./components/multi-file-uploader/components/file-uploader-item/file-uploader-item.component";
import { PrescriberSelectorComponent } from "./components/prescriber-selector/prescriber-selector.component";

@NgModule({
	imports: [
		CdkTableModule,
		CommonModule,
		ClipboardModule,
		FormsModule,
		MarkdownModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTooltipModule,
		ReactiveFormsModule,
		RouterModule,
		NgxMaskDirective,
		NgxMaskPipe,
	],
	declarations: [
		FileUploaderComponent,
		FooterComponent,
		HeaderComponent,
		HealthCenterSelectorComponent,
		MedicalUserSelectorComponent,
		ModalConfirmationDialogComponent,
		ModalMessageComponent,
		ModalMessageSecretaryComponent,
		ModalMessageSimpleComponent,
		ModalSearchComponent,
		ModalTermsAndConditionsComponent,
		MultiFileUploaderComponent,
		NavigationComponent,
		NavigationMobileComponent,
		PrescriberSelectorComponent,
		PrescriberMessageCardComponent,
		PrescriberMessageTableComponent,
		PrescriberMessagingNoMessageComponent,
		ReadMoreComponent,
		SingleFileUploaderComponent,
		SecuredFileLinkComponent,
		TableCellActionsListComponent,
		TableCellCodeStateComponent,
		TableCellDateComponent,
		TableCellDateRangeComponent,
		TableCellDocumentComponent,
		TableCellDurationComponent,
		TableCellHealthCenterMembersComponent,
		TableCellMessageStatusComponent,
		TableCellObservancePeriodsStatusComponent,
		TableCellPersonComponent,
		TableCellPrescriptionDateRangeAndStatusComponent,
		TableCellReadMoreTextComponent,
		TableCellMultipleLinesStringComponent,
		TableCellPatientInterventionTypeComponent,
		TableCellSelectComponent,
		TableCellStatusComponent,
		TableCellStringComponent,
		TableCellTagComponent,
		TableFilterComponent,
		TableHeaderComponent,
		TableHeaderDisplayFilterComponent,
		TableHeaderFilterComponent,
		TableLoaderComponent,
		TableSkeltonComponent,
		TableStandardComponent,
		TableViewComponent,
		TableMobileComponent,

		AsyncStatusPipe,
		BooleanPipe,
		ChunkArrayByColumnPipe,
		DownloadUrlPipe,
		DurationFormatPipe,
		JoinPipe,
		LabelArrayPipe,
		LabelPipe,
		LunarDateStringPipe,
		OrderByPipe,
		PhonePipe,
		TruncatePipe,

		CheckAllAccessDirective,
		CheckPermissionsDirective,
		ClickOutsideDirective,
		DragAndDropDirective,
		StylePaginatorDirective,
	],
	exports: [
		FileUploaderComponent,
		FooterComponent,
		HeaderComponent,
		HealthCenterSelectorComponent,
		MedicalUserSelectorComponent,
		ModalMessageComponent,
		ModalMessageSecretaryComponent,
		ModalSearchComponent,
		ModalTermsAndConditionsComponent,
		PrescriberSelectorComponent,
		MultiFileUploaderComponent,
		PrescriberMessageCardComponent,
		PrescriberMessageTableComponent,
		PrescriberMessagingNoMessageComponent,
		NavigationComponent,
		ReadMoreComponent,
		SecuredFileLinkComponent,
		SingleFileUploaderComponent,
		TableCellActionsListComponent,
		TableCellCodeStateComponent,
		TableCellDateComponent,
		TableCellDateRangeComponent,
		TableCellDurationComponent,
		TableCellMultipleLinesStringComponent,
		TableCellPersonComponent,
		TableCellPrescriptionDateRangeAndStatusComponent,
		TableCellSelectComponent,
		TableCellStringComponent,
		TableFilterComponent,
		TableHeaderComponent,
		TableHeaderDisplayFilterComponent,
		TableHeaderFilterComponent,
		TableHeaderFilterComponent,
		TableLoaderComponent,
		TableSkeltonComponent,
		TableStandardComponent,
		TableViewComponent,
		TableMobileComponent,

		CommonModule,
		ReactiveFormsModule,
		CdkTableModule,
		ClipboardModule,
		FormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatExpansionModule,
		MatTooltipModule,

		AsyncStatusPipe,
		BooleanPipe,
		ChunkArrayByColumnPipe,
		DownloadUrlPipe,
		DurationFormatPipe,
		JoinPipe,
		LabelArrayPipe,
		LabelPipe,
		LunarDateStringPipe,
		OrderByPipe,
		PhonePipe,
		TruncatePipe,

		CheckAllAccessDirective,
		CheckPermissionsDirective,
		ClickOutsideDirective,
		DragAndDropDirective,
		StylePaginatorDirective,
	],
	providers: [
		DurationFormatPipe,
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: ["DD/MM/YYYY"],
				},
				display: {
					dateInput: "DD/MM/YYYY",
					monthYearLabel: "MMM YYYY",
					dateA11yLabel: "DD/MM/YYYY",
					monthYearA11yLabel: "MMMM YYYY",
				},
			},
		},
		{ provide: MatPaginatorIntl, useClass: TableMatPaginatorIntl },
		provideNgxMask(),
	],
})
export class SharedModule {}
