import { ActivityAreas } from "../constants";
import { FeaturePermission, Features } from "../constants/features.constant";
import { Permissions } from "../constants/permissions.constant";
import { Roles } from "../constants/roles.constant";
import { Scopes } from "../constants/scopes.constant";
import { UserModes } from "../constants/user-modes.constant";
import { Impersonation } from "./impersonation";

export class User {
	private _impersonation!: Impersonation;
	private _prescriberIds: string[] = [];
	private _currentActivityArea: string | null = null;

	id!: string;
	scopes: string[] = [];
	agencies!: string;
	familyName!: string;
	givenName!: string;
	hasMultipleActivityArea: boolean | null = null;
	hasNoActivityArea: boolean | null = null;
	isAuthenticated = false;
	medicalTechnicalAdvisorId!: string;
	medicalUserId!: string;
	roles!: string[];
	username!: string;
	title!: string;

	get featurePermissions(): FeaturePermission[] {
		return Roles.permissions
			.filter(r => this.hasRole(r.role))
			.concat()
			.flatMap(u => u.featurePermissions);
	}

	get isImpersonated(): boolean {
		return this._impersonation.isImpersonated;
	}

	get currentMedicalUserId(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.medicalUserId;

		return this.medicalUserId;
	}

	get currentAgencies(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.agencies;

		return this.agencies;
	}

	get currentGivenName(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.givenName;

		return this.givenName;
	}

	get currentFamilyName(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.familyName;

		return this.familyName;
	}

	get currentTitle(): string {
		if (this._impersonation.isImpersonated)
			return this._impersonation.scopes.includes(Scopes.prescriber) ? "Dr." : "";

		return this.title;
	}

	get currentHasMultipleActivityArea(): boolean | null {
		if (this._impersonation.isImpersonated) return this._impersonation.hasMultipleActivityArea;

		return this.hasMultipleActivityArea;
	}

	get currentHasNoActivityArea(): boolean | null {
		if (this._impersonation.isImpersonated) return this._impersonation.hasNoActivityArea;

		return this.hasNoActivityArea;
	}

	get currentScopes(): string[] {
		if (this._impersonation.isImpersonated) return this._impersonation.scopes;

		return this.scopes;
	}

	get isAdmin(): boolean {
		return this.hasRole(Roles.admin);
	}

	get isAdministrative(): boolean {
		return this.hasRole(Roles.administrative);
	}

	get isCustomerRelationDepartment(): boolean {
		return this.hasRole(Roles.customerRelationDepartement);
	}

	get isPrescriber(): boolean {
		return this.hasRole(Roles.prescriber);
	}

	get isSecretary(): boolean {
		return this.hasRole(Roles.secretary);
	}

	get isMediviewLocalAdmin(): boolean {
		return this.isMediviewLocalAdminAsdia || this.isMediviewLocalAdminElivie;
	}

	get isMediviewLocalAdminAsdia(): boolean {
		return this.hasRole(Roles.mediviewLocalAdminAsdia);
	}

	get isMediviewLocalAdminElivie(): boolean {
		return this.hasRole(Roles.mediviewLocalAdminElivie);
	}

	get isMedicalTechnicalAdvisor(): boolean {
		return this.hasRole(Roles.medicalTechnicalAdvisor);
	}

	get isRegionalOrDevelopmentManager(): boolean {
		return this.hasRole(Roles.regionalOrDevelopmentManager);
	}

	get isSuperAdmin(): boolean {
		return this.hasRole(Roles.superAdmin);
	}

	get isMessageController(): boolean {
		return this.hasRole(Roles.messageController);
	}

	get isScopeAdministrative(): boolean {
		return this.currentScopes.includes(Scopes.administrative);
	}

	get isScopeAdmin(): boolean {
		return this.currentScopes.includes(Scopes.admin);
	}

	get isScopeCustomerRelationDepartment(): boolean {
		return this.currentScopes.includes(Scopes.customerRelationDepartment);
	}

	get isScopeSuperAdmin(): boolean {
		return this.currentScopes.includes(Scopes.superAdmin);
	}

	get isScopePrescriber(): boolean {
		return this.currentScopes.includes(Scopes.prescriber);
	}

	get isScopeSecretary(): boolean {
		return this.currentScopes.includes(Scopes.secretary);
	}

	get isScopeMediviewLocalAdmin(): boolean {
		return this.isScopeMediviewLocalAdminElivie || this.isScopeMediviewLocalAdminAsdia;
	}

	get isScopeMediviewLocalAdminElivie(): boolean {
		return this.currentScopes.includes(Scopes.mediviewLocalAdminElivie);
	}

	get isScopeMediviewLocalAdminAsdia(): boolean {
		return this.currentScopes.includes(Scopes.mediviewLocalAdminAsdia);
	}

	get isScopeMedicalTechnicalAdvisor(): boolean {
		return this.currentScopes.includes(Scopes.medicalTechnicalAdvisor);
	}

	get isScopeMedicalUser(): boolean {
		return this.currentScopes.includes(Scopes.medicalUser);
	}

	get scopePrescriberIds(): string[] {
		return this._prescriberIds;
	}

	get isActivityRespiratory(): boolean {
		return this._currentActivityArea === ActivityAreas.respiratory;
	}

	get isActivityScyova(): boolean {
		return this._currentActivityArea === ActivityAreas.scyova;
	}

	get activityArea(): string | null {
		return this._currentActivityArea;
	}

	get userMode(): string {
		if (!this.currentScopes) return UserModes.unknown;
		if (this.currentScopes.includes(Scopes.admin)) return UserModes.admin;
		if (this.currentScopes.includes(Scopes.superAdmin)) return UserModes.admin;
		if (this.currentScopes.includes(Scopes.prescriber)) return UserModes.prescriber;
		if (this.currentScopes.includes(Scopes.mediviewLocalAdminAsdia)) return UserModes.mediviewLocalAdminAsdia;
		if (this.currentScopes.includes(Scopes.mediviewLocalAdminElivie)) return UserModes.mediviewLocalAdminElivie;
		if (this.currentScopes.includes(Scopes.medicalTechnicalAdvisor)) return UserModes.medicalTechnicalAdvisor;
		return UserModes.unknown;
	}

	constructor(init?: Partial<User>) {
		Object.assign(this, init);
		this._impersonation = Impersonation.retrieveFromCache();
		this.loadDefaultScopes();
	}

	private getSpecificImpersonationScopes(specificRoles: string[], agencies: string): string[] {
		const impersonationScopes = [Scopes.medicalUser];

		if (agencies) impersonationScopes.push(Scopes.secretary);

		if (!agencies) impersonationScopes.push(Scopes.prescriber);

		if (specificRoles && specificRoles.includes(Roles.mediviewLocalAdminAsdia))
			impersonationScopes.push(Scopes.mediviewLocalAdminAsdia);

		if (specificRoles && specificRoles.includes(Roles.mediviewLocalAdminElivie))
			impersonationScopes.push(Scopes.mediviewLocalAdminElivie);

		return impersonationScopes;
	}

	assignScopePrescriberIds(prescriberIds: string[]): void {
		this._prescriberIds = prescriberIds;
	}

	assignActivityArea(activityArea: string): void {
		this._currentActivityArea = activityArea;
	}

	impersonateMedicalUser(
		medicalUserId: string,
		agencies: string,
		familyName: string,
		givenName: string,
		specificRoles: string[],
		hasMultipleActivityArea: boolean,
		hasNoActivityArea: boolean
	): void {
		if (!Scopes.all.includes(Scopes.prescriber) || !this.roles) return;

		this.scopes = this.getSpecificImpersonationScopes(specificRoles, agencies);

		const impersonation = new Impersonation({
			isImpersonated: true,
			agencies: agencies,
			familyName: familyName,
			givenName: givenName,
			hasMultipleActivityArea: hasMultipleActivityArea,
			hasNoActivityArea: hasNoActivityArea,
			medicalUserId: medicalUserId,
			scopes: this.scopes,
		});
		impersonation.saveInsideCache();

		this._impersonation = impersonation;
	}

	resetActivityArea(): void {
		this._currentActivityArea = null;
	}

	loadDefaultScopes(resetImpersonation = false): void {
		if (resetImpersonation) {
			this._impersonation = Impersonation.resetImpersonationInsideCache();
			this.resetPrescriberScopes([
				Scopes.prescriber,
				Scopes.mediviewLocalAdminAsdia,
				Scopes.mediviewLocalAdminElivie,
			]);
		}

		if (!this.roles) return;

		if (this.roles.includes(Roles.superAdmin)) this.scopes.push(Scopes.superAdmin);

		if (this.roles.includes(Roles.mediviewLocalAdminAsdia)) this.scopes.push(Scopes.mediviewLocalAdminAsdia);

		if (this.roles.includes(Roles.mediviewLocalAdminElivie)) this.scopes.push(Scopes.mediviewLocalAdminElivie);

		if (this.roles.includes(Roles.admin)) this.scopes.push(Scopes.admin);

		if (this.roles.includes(Roles.administrative)) this.scopes.push(Scopes.administrative);

		if (this.roles.includes(Roles.customerRelationDepartement)) this.scopes.push(Scopes.customerRelationDepartment);

		if (this.roles.includes(Roles.medicalTechnicalAdvisor)) this.scopes.push(Scopes.medicalTechnicalAdvisor);

		if (this.roles.includes(Roles.prescriber)) {
			this.scopes.push(Scopes.prescriber);
			this.scopes.push(Scopes.medicalUser);
		}

		if (this.roles.includes(Roles.secretary)) {
			this.scopes.push(Scopes.secretary);
			this.scopes.push(Scopes.medicalUser);
		}

		if (this.roles.includes(Roles.user)) this.scopes.push(Scopes.user);
	}

	hasFeaturePermission(feature: Features, permission = Permissions.ReadOnly): boolean {
		if (this.featurePermissions.some(f => f.feature === Features.AllAccess)) return true;

		const featurePermissions = this.featurePermissions.filter(f => f.feature === feature).map(f => f.permission);

		if (!featurePermissions) return false;

		switch (permission) {
			case Permissions.ReadOnly:
				return featurePermissions.some(
					p => p === Permissions.Admin || p === Permissions.ReadWrite || p === Permissions.ReadOnly
				);
			case Permissions.ReadWrite:
				return featurePermissions.some(p => p === Permissions.Admin || p === Permissions.ReadWrite);
			default:
				return featurePermissions.some(p => p === permission);
		}
	}

	hasActivityAreas(activityAreas: string[]): boolean {
		for (const activityArea of activityAreas) {
			if (this._currentActivityArea === activityArea) return true;
		}

		return false;
	}

	isActivityAreaSelected(): boolean {
		return !!this._currentActivityArea;
	}

	hasRole(role: string): boolean {
		return this.hasRoles([role]);
	}

	hasRoles(roles: string[]): boolean {
		if (!this.roles) return false;

		for (const role of roles) {
			if (this.roles.includes(role)) return true;
		}

		return false;
	}

	hasScopes(scopes: string[]): boolean {
		if (!this.currentScopes) return false;

		for (const scope of scopes) {
			if (this.currentScopes.includes(scope)) return true;
		}

		return false;
	}

	isScope(scope: string): boolean {
		return this.currentScopes.includes(scope);
	}

	private resetPrescriberScopes(elementsToRemove: string[]): void {
		elementsToRemove.forEach(element => {
			this.resetScope(element);
		});
	}

	private resetScope(element: string): void {
		this.scopes = this.scopes.filter(el => el !== element);
	}
}
