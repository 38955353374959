<div
	*ngIf="sortColumnName && headerLabel"
	class="d-flex flex-column justify-content-start bg-white rounded p-2 shadow position-relative h-100">
	<div class="d-flex justify-content-between">
		<button
			*ngIf="sortColumnName"
			type="button"
			matTooltip="{{ sortToolTip }}"
			matTooltipPosition="right"
			matTooltipShowDelay="500"
			class="btn pin-circle me-2 position-relative align-self-start z-index-1"
			[ngClass]="{ asc: sort && sort.sortDirection === 'asc' && sort.sortColumn === sortColumnName }"
			(click)="sortTable()"
			[disabled]="filterOnly">
			<i class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
		</button>

		<span class="text-medium fs-7 align-self-center lh-17" [ngClass]="{ 'flew-grow-1 flex-fill': !sortColumnName }">
			{{ headerLabel }}
		</span>
		<div *ngIf="headerSubLabel" class="d-flex justify-content-center">
			<span class="text-nowrap text-center">
				{{ headerSubLabel }}
			</span>
		</div>

		<app-table-header-filter
			[columnName]="columnName"
			[filters]="filters"
			[filterChoiceList]="filterChoiceList"
			[filterColumnName]="filterColumnName"
			[filterToolTip]="filterToolTip"
			[filterType]="filterType"
			(filterChange)="filterTable()"
			class="align-self-start"></app-table-header-filter>
	</div>
</div>

<div
	*ngIf="!sortColumnName && headerLabel"
	[ngClass]="{
		'd-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100': !headerWithoutShadow,
	}">
	<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
		{{ headerLabel }}
	</span>
	<div *ngIf="headerSubLabel" class="d-flex justify-content-center">
		<span class="text-nowrap text-center">
			{{ headerSubLabel }}
		</span>
	</div>
</div>
