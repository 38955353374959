import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map, Observable, of, startWith, Subject, switchMap, tap } from "rxjs";
import {
	FilterTableColumnTypes,
	TableCellDate,
	TableCellDocuments,
	TableCellMessageStatus,
	TableCellReadMore,
	TableCellString,
	TableCellTypes,
	TableCommunicationService,
	TableFilterListItemDetail,
	TableFilterUrlParamList,
	TableItem,
	TableRowList,
	TableView,
} from "src/app/@shared";
import { ActivityAreas, CacheKeysPrefix, ComparisonOperators, MessageStatus, TableCacheKeys } from "src/app/constants";
import { PagerMessage, MessageItem, BrowseMessagesCommand } from "src/app/models";
import {
	AppConfigService,
	AuthService,
	StorageService,
	ExchangeService,
	FilesService,
	UtilsService,
} from "src/app/services";
import { Logger } from "src/app/services/logger.service";

const log = new Logger("PrescriberPrescriptionTableComponent");

export interface TableCellMessage extends TableItem {
	createdOn: TableCellDate;
	title: TableCellString;
	body: TableCellReadMore;
	attachments: TableCellDocuments;
	status: TableCellMessageStatus;
}

export class TableCellMessageNames {
	static readonly createdOn: string = "createdOn";
	static readonly medicalTechnicalAdvisorId: string = "medicalTechnicalAdvisorId";
	static readonly patientId: string = "patientId";
	static readonly prescriberId: string = "prescriberId";
	static readonly title: string = "title";
	static readonly body: string = "body";
	static readonly attachments: string = "attachments";
	static readonly hasAttachment: string = "hasAttachment";
	static readonly status: string = "status";
}

@Component({
	selector: "app-prescriber-message-table",
	templateUrl: "./prescriber-message-table.component.html",
})
export class PrescriberMessageTableComponent {
	@Input() medicalUserId!: string;

	extraParamsFromUrl: any;
	protected isLoadingResults = true;
	protected isOnError = false;

	tableView: TableView;

	// if a change is made on the default TableView the version number should incremented
	// this allow the cache to be refreshed
	private _defaultTableView: TableView = new TableView({
		version: 1,
		filters: { items: [] },
		pageIndex: 0,
		pageSize: 20,
		sort: {
			sortColumn: "createdOn",
			sortDirection: "desc",
		},
		columns: {
			createdOn: {
				columnName: TableCellMessageNames.createdOn,
				cellType: TableCellTypes.date,
				filterColumnName: TableCellMessageNames.createdOn,
				filterDefaultOperator: ComparisonOperators.between,
				filterTableColumnType: FilterTableColumnTypes.date,
				filterToolTip: "Filtrer sur la date de création",
				headerLabel: "Date",
				isVisible: true,
				order: 1,
				sortColumnName: TableCellMessageNames.createdOn,
				sortToolTip: "Trier sur la date de création",
				weight: 12,
			},
			title: {
				columnName: TableCellMessageNames.title,
				cellType: TableCellTypes.string,
				filterColumnName: TableCellMessageNames.title,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.stringContains,
				filterToolTip: "Filtrer sur l'objet du message",
				headerLabel: "Objet du message",
				isVisible: true,
				order: 4,
				sortColumnName: TableCellMessageNames.title,
				sortToolTip: "Trier sur l'objet du message",
				weight: 15,
			},
			body: {
				columnName: TableCellMessageNames.body,
				cellType: TableCellTypes.readMore,
				filterColumnName: TableCellMessageNames.body,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.stringContains,
				filterToolTip: "Filtrer sur le contenu du message",
				headerLabel: "Contenu du message",
				isVisible: true,
				order: 5,
				sortColumnName: TableCellMessageNames.body,
				sortToolTip: "Trier sur le contenu du message",
				weight: 30,
			},
			attachments: {
				columnName: TableCellMessageNames.attachments,
				cellType: TableCellTypes.documents,
				filterColumnName: TableCellMessageNames.hasAttachment,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.in,
				filterChoiceList: {
					items: [
						{ label: "Oui", value: true, isSelected: false },
						{ label: "Non", value: false, isSelected: false },
					],
				},
				filterToolTip: "Filtrer sur les documents",
				headerLabel: "Documents",
				isVisible: true,
				order: 6,
				sortColumnName: TableCellMessageNames.hasAttachment,
				sortToolTip: "Trier sur les documents",
				weight: 12,
			},
			status: {
				columnName: TableCellMessageNames.status,
				cellType: TableCellTypes.messageStatus,
				filterColumnName: TableCellMessageNames.status,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.in,
				filterChoiceList: {
					items: [
						{ label: "A traiter", value: MessageStatus.needTreatment, isSelected: false },
						{ label: "Pris en charge", value: MessageStatus.inProgress, isSelected: false },
						{ label: "Traité", value: MessageStatus.treated, isSelected: false },
					],
				},
				filterToolTip: "Filtrer sur le statut du message",
				headerLabel: "Status",
				isVisible: true,
				order: 7,
				sortColumnName: TableCellMessageNames.status,
				sortToolTip: "Trier sur le statut du message",
				weight: 10,
			},
		},
	});

	get cacheKey(): string {
		return `${TableCacheKeys.tablePrescriberMessages}__${this._authService.user.currentMedicalUserId}__${this._authService.user.activityArea}`;
	}
	get cacheKeyMobile(): string {
		return `${TableCacheKeys.tablePrescriberMessagesMobile}__${this._authService.user.currentMedicalUserId}__${this._authService.user.activityArea}`;
	}

	protected onPreviewModeChange$: Observable<boolean> = this._appConfigService.onPreviewModeChange$;

	private _tableLoadSubject = new Subject<boolean>();
	protected rowList$: Observable<TableRowList> = this._tableLoadSubject.asObservable().pipe(
		startWith({}),
		tap(_ => {
			this.isLoadingResults = true;
			this.isOnError = false;
		}),
		switchMap(() =>
			this.getItems$().pipe(
				catchError((err: any) => {
					log.error(err);
					this.isOnError = true;
					this.isLoadingResults = false;
					return of(null);
				})
			)
		),
		map(pager => this.mapToTableRowList(pager)),
		tap(_ => {
			this.isLoadingResults = false;
			this._storageService.saveCache(this.cacheKey, this.tableView);
		})
	);

	constructor(
		private _appConfigService: AppConfigService,
		private _authService: AuthService,
		private _exchangeService: ExchangeService,
		private _filesService: FilesService,
		private _router: Router,
		private _storageService: StorageService,
		private _tableCommunicationService: TableCommunicationService,
		private _utilsService: UtilsService
	) {
		this.tableView =
			this._storageService.retrieveCache(TableView, CacheKeysPrefix + this.cacheKey) ?? this._defaultTableView;

		if (this.tableView.version !== this._defaultTableView.version) this.tableView = this._defaultTableView;

		this.registerRouteFilter();

		_tableCommunicationService.onChangeFiltersSubject$.subscribe(isMobile => {
			if (isMobile) {
				const messageMobileCache = _storageService.retrieveCacheValue(this.cacheKeyMobile);

				if (messageMobileCache) {
					this.tableView.filters = _utilsService.cloneDeep(messageMobileCache.filters);
					this.tableView.filters.items.forEach((item: any) => {
						if (item.columnName) {
							item.columnName = item.columnName.replace("Mobile", "");
						}
					});
					this.reloadTable(true);
				}
			}
		});
	}

	tableViewHasBrowseFilteredColumns(filteredColums: TableFilterListItemDetail[]): boolean {
		let hasFilter = true;

		if (this.tableView.filters.items.length !== filteredColums.length) return false;

		filteredColums.forEach(column => {
			if (
				this.tableView.filters.items.findIndex(
					e => e.filteredColumn.name === column.name && e.filteredColumn.value === column.value
				) === -1
			)
				hasFilter = false;
		});

		return hasFilter;
	}

	reloadTable(_: any): void {
		this._tableLoadSubject.next(true);
	}

	private filterFromUrl(filterUrl: TableFilterUrlParamList): void {
		this.tableView.resetFilters();

		filterUrl.items.forEach(item => {
			let label: string | undefined;

			if (item.name === TableCellMessageNames.title) {
				const tableFilter = TableView.createStringContainsTableFilterListItemDetail(
					item.value,
					TableCellMessageNames.title,
					FilterTableColumnTypes.stringContains
				);
				this.tableView.addFilter(TableCellMessageNames.title, tableFilter);

				if (item.extraParams) this.extraParamsFromUrl = item.extraParams;
				return;
			}

			this.tableView.addCustomFilter(item.name, item.value, label, item.operator);
		});
	}

	private registerRouteFilter(): void {
		const currentNavigation = this._router.getCurrentNavigation();
		if (!currentNavigation || !currentNavigation.extras.state || currentNavigation.trigger === "popstate") return;

		const filterParams = currentNavigation.extras.state["filter"] as TableFilterUrlParamList;

		if (!filterParams || filterParams.items.length === 0) return;

		this.filterFromUrl(filterParams);
	}

	private mapToTableRowList(pager: PagerMessage | null): TableRowList {
		if (!pager) return TableRowList.defaultValue;

		return new TableRowList({
			hasItems: pager.totalItems !== 0,
			items: pager.items.map(i => this.mapToCellMessage(i)),
			nbTotalItems: pager.totalItems,
			nbTotalFilteredItems: pager.totalFilteredItems,
		});
	}

	private mapToCellMessage(item: MessageItem): TableCellMessage {
		return {
			id: item.id,
			isSelected: false,
			createdOn: {
				date: item.createdOn,
				format: "dd/MM/yyyy",
				hideSubValue: true,
				subValue: "",
			},
			title: {
				forceCasing: false,
				value: item.title,
			},
			body: {
				defaultExpand: this.extraParamsFromUrl?.value ? this.extraParamsFromUrl?.value : false,
				value: item.body,
			},
			attachments: {
				value: this._filesService.convertToTableItemDocuments(item.attachments),
			},
			status: {
				forceCasing: true,
				value: item.status,
				textCssClass: "text-center",
			},
		};
	}

	private createCommand(): BrowseMessagesCommand {
		const treatmentBusinessCode = this._authService.user.activityArea ? this._authService.user.activityArea : "";
		const command = new BrowseMessagesCommand({
			medicalUserId: this._authService.user.currentMedicalUserId,
			treatmentBusinessCode: treatmentBusinessCode === ActivityAreas.scyova ? "parkinson" : treatmentBusinessCode,
		});

		this.tableView.compute();
		command.load(this.tableView);

		return command;
	}

	private getItems$(): Observable<PagerMessage> {
		const command = this.createCommand();

		return this._exchangeService.browseMessages(command);
	}
}
