<span class="text-nowrap w-100 my-2" *ngIf="item">
	<a
		[class.disabled]="item.isLinkVisible"
		[routerLink]="item.link"
		class="d-flex align-self-center text-decoration-none cs-pointer"
		title="{{ item.linkTooltip }}">
		<span
			[style.visibility]="!item.treatmentShortLabel ? 'hidden' : ''"
			class="initiales pin-circle white me-3 text-medium align-self- z-index-1"
			>{{ item.treatmentShortLabel | uppercase }}
		</span>
		<div class="flex-grow-1 d-flex flex-column">
			<span class="text-nowrap">
				{{ item.value | titlecase }}
			</span>
		</div>
	</a>
</span>
