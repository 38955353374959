import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TableItemDocument } from "../@shared/types/table-item-document";
import { MessageAttachmentItem } from "../models/exchange/pager-message.interface";
import { FileAccessToken } from "../models/files/file-access-token";
import { SigningFileUrl } from "../models/files/signing-file-url";
import { SigningFilesUrlRequest } from "../models/files/signing-files-url-request";
import { UploadFile } from "../models/files/upload-file.interface";

@Injectable({
	providedIn: "root",
})
export class FilesService {
	constructor(private _httpClient: HttpClient) {}

	getSigningFileEndpoint(signingFiles: SigningFilesUrlRequest): Observable<SigningFileUrl> {
		return this._httpClient.post<SigningFileUrl>(`/files/signing-url`, signingFiles);
	}

	getEphemeralFileAccessTokenId(fileId: string, medicalUserId: string): Observable<FileAccessToken> {
		if (medicalUserId)
			return this._httpClient.get<FileAccessToken>(
				`/files/${fileId}/access-tokens/medical-users/${medicalUserId}`
			);

		return this._httpClient.get<FileAccessToken>(`/files/${fileId}/access-tokens/medical-users`);
	}

	uploadFile(
		file: File,
		fileCategoryCode: string,
		medicalUserId: string,
		prescriberId?: string,
		patientId?: string
	): Observable<HttpEvent<UploadFile>> {
		const formData = new FormData();

		formData.append("file", file);
		formData.append("fileCategoryCode", fileCategoryCode);
		formData.append("medicalUserId", medicalUserId);

		if (prescriberId) formData.append("prescriberId", prescriberId);

		if (patientId) formData.append("patientId", patientId);

		return this._httpClient.post<UploadFile>(`/files/upload`, formData, {
			reportProgress: true,
			observe: "events",
		});
	}

	convertToTableItemDocuments(items: MessageAttachmentItem[]): TableItemDocument[] {
		if (!items) return [];

		return items.map(i => {
			return {
				fileDate: i.uploadedAt,
				fileId: i.fileId,
				fileName: i.fileName,
				fileTypeCode: i.fileTypeCode,
			} as TableItemDocument;
		});
	}
}
