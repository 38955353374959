import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { catchError, Observable, of, Subject, Subscription, switchMap, tap } from "rxjs";
import { Logger } from "src/app/services/logger.service";
import { SubjectEvents } from "src/app/constants/subject-events.constant";
import { MedicalUsersService } from "src/app/services/medical-users.service";
import {
	RegisterMedicalUserDashboardConfiguration,
	RegisterMedicalUserDashboardConfigurationItem,
} from "src/app/models/medical-user/register-medical-user-dashboard-configuration";
import { AuthService } from "src/app/services/auth.service";
import { CommunicationComService } from "src/app/services/communication.com.service";
import {
	RegisterMedicalUserDashboardConfigurationCommand,
	RegisterMedicalUserDashboardConfigurationSubCommand,
} from "src/app/models/medical-user/register-medical-user-dashboard-configuration-request";
import { AppConfigService } from "src/app/services";

const log = new Logger("ModalPatientIndicatorsComponent");

@Component({
	selector: "app-modal-patient-indicators",
	templateUrl: "./modal-patient-indicators.component.html",
})
export class ModalPatientIndicatorsComponent implements OnInit, OnDestroy {
	@Output() readonly closed = new EventEmitter();

	isComponentLoading = true;
	isComponentOnError = false;
	allSelected = false;

	currentMedicalUserDashboardConfiguration: RegisterMedicalUserDashboardConfigurationItem[] = [];

	private _dashboardConfigurationSubscription?: Subscription;

	// TODO: To delete and use dashboard configuration api call from prescriber dashboard page
	private _onChangeSubject$: Subject<string> = new Subject<string>();
	getMedicalUserDashboardConfigurations$: Observable<RegisterMedicalUserDashboardConfiguration | undefined> =
		this._onChangeSubject$.asObservable().pipe(
			tap(_ => {
				this.isComponentLoading = true;
				this.isComponentOnError = false;
			}),
			switchMap(() =>
				this._medicalUsersService.getDashboardConfiguration(this._authService.user.currentMedicalUserId).pipe(
					catchError((err: any) => {
						this.isComponentOnError = true;
						log.error(err);
						return of(undefined);
					})
				)
			),
			tap(registerMedicalUserDashboardConfiguration => {
				if (registerMedicalUserDashboardConfiguration) {
					this.currentMedicalUserDashboardConfiguration =
						registerMedicalUserDashboardConfiguration?.items ?? [];
				}

				this.calculateAllSelected();

				this.isComponentLoading = false;
			})
		);

	companyName: string;

	constructor(
		_appConfigService: AppConfigService,
		private _authService: AuthService,
		private _communicationComService: CommunicationComService,
		private _diag: MatDialogRef<ModalPatientIndicatorsComponent>,
		private _medicalUsersService: MedicalUsersService
	) {
		this.companyName = _appConfigService.appConfig.appCompanyName;
	}

	ngOnInit(): void {
		setTimeout(() => this._onChangeSubject$.next(SubjectEvents.onInit), 0);
	}

	ngOnDestroy(): void {
		this._dashboardConfigurationSubscription?.unsubscribe();
	}

	onSelectedIndicatorChange(codeIndicator: string): boolean {
		const currentIndicator = this.currentMedicalUserDashboardConfiguration.find(
			indicator => indicator.code === codeIndicator
		);
		if (currentIndicator) {
			currentIndicator.isSelected = !currentIndicator.isSelected;
			this.calculateAllSelected();
			return currentIndicator.isSelected;
		}

		return false;
	}

	private calculateAllSelected(): void {
		this.allSelected = !this.currentMedicalUserDashboardConfiguration.find(c => !c.isSelected);
	}

	submitConfiguration(): void {
		const registerMedicalUserDashboardConfigurationCommand: RegisterMedicalUserDashboardConfigurationCommand = {
			indicators: this.currentMedicalUserDashboardConfiguration.map(config => {
				return {
					code: config.code,
					isSelected: config.isSelected,
					order: config.order,
				} as RegisterMedicalUserDashboardConfigurationSubCommand;
			}),
		};

		this._dashboardConfigurationSubscription?.unsubscribe();
		this._dashboardConfigurationSubscription = this._medicalUsersService
			.setDashboardConfiguration(
				this._authService.user.currentMedicalUserId,
				registerMedicalUserDashboardConfigurationCommand
			)
			.subscribe((response: RegisterMedicalUserDashboardConfiguration | undefined) => {
				log.debug("response : ", response);
				this._communicationComService.changeDashboardConfiguration(true);
				this.close();
			});
	}

	onSelectedAllIndicatorChange(): boolean {
		this.allSelected = !this.allSelected;
		this.currentMedicalUserDashboardConfiguration.forEach(
			currentIndicator => (currentIndicator.isSelected = this.allSelected)
		);
		return this.allSelected;
	}

	close(): void {
		this._diag.close();
	}
}
