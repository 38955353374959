<div class="modal modal-signin position-fixed d-block bg-modal-search indicators" tabindex="-1" role="dialog">
	<div class="modal-dialog preferences position-relative ms-lg-auto" role="document">
		<h3 class="text-bold fs-4 my-2 ms-3 ms-lg-4 py-2 text-start text-dark">
			Ajouter des données sur votre patientèle
		</h3>
		<div class="modal-content rounded shadow position-relative border-0">
			<div class="modal-body fs-5 p-3 p-lg-4 position-relative">
				<p class="text-medium">Personnaliser votre interface</p>
				<p class="">
					{{ companyName | titlecase }} met à votre disposition des données sur différents aspects de votre
					patientèle. Ici vous pouvez choisir de les afficher ou pas sur votre tableau de bord
				</p>
				<ng-container
					*ngIf="{
						getMedicalUserDashboardConfigurations: getMedicalUserDashboardConfigurations$ | async,
					} as observables">
					<table class="table table-partage mb-0" *ngIf="observables.getMedicalUserDashboardConfigurations">
						<thead>
							<tr>
								<th scope="col">
									<div class="d-flex justify-content-between fs-5">
										<span class="text-medium"> Tout cocher </span>
										<div class="align-self-center form-chec form-switch double me-3 mb-2">
											<input
												class="form-check-input"
												type="checkbox"
												role="switch"
												id="flexSwitchCheckDefault8"
												[checked]="allSelected"
												(change)="onSelectedAllIndicatorChange()" />
											<label class="form-check-label" for="flexSwitchCheckDefault8"></label>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let registerMedicalUserDashboardConfiguration of observables
										.getMedicalUserDashboardConfigurations.items;
									let index = index
								">
								<td class="p-0">
									<div
										class="d-flex pt-2 px-3 shared bd-highlight border"
										[ngClass]="
											index === 0
												? 'rounded-top border-bottom-0'
												: index ===
													  observables.getMedicalUserDashboardConfigurations.items.length - 1
													? 'rounded-bottom'
													: 'border-bottom-0'
										"
										[class.bg-odd-switch]="!registerMedicalUserDashboardConfiguration.isSelected">
										<div class="pe-2 d-flex justify-content-center bd-highlight mw-3">
											<container-element
												[ngSwitch]="registerMedicalUserDashboardConfiguration.code"
												class="margin-bottom-m4">
												<!-- the same view can be shown in more than one case -->
												<p
													class="mb-0"
													*ngSwitchCase="'current-hospitalized-patients-indicator'">
													<i
														class="fs-1 icon-patients-hospitalises text-color-primary"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														"></i>
												</p>
												<p
													class="mb-0"
													*ngSwitchCase="'current-unreachable-patients-indicator'">
													<i
														class="fs-1 icon-patients-injoignables text-color-primary"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														"></i>
												</p>
												<p class="mb-0" *ngSwitchCase="'observance-distribution-indicator'">
													<i
														class="fs-1 icon-patients-observance text-color-primary"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														"></i>
												</p>
												<p class="mb-0" *ngSwitchCase="'patients-interactions-indicator'">
													<i
														class="fs-1 icon-patients-appairage text-color-primary"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														"></i>
												</p>
												<p class="mb-0" *ngSwitchCase="'treatments-distribution-indicator'">
													<i
														class="fs-1 icon-repartition-traitement text-color-primary"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														"></i>
												</p>
												<p *ngSwitchDefault>...</p>
											</container-element>
										</div>
										<div class="p-0 align-self-center flex-grow-1 bd-highlight">
											<div class="content lh-18 pe-0 ps-1">
												<container-element
													[ngSwitch]="registerMedicalUserDashboardConfiguration.code">
													<!-- the same view can be shown in more than one case -->
													<p
														*ngSwitchCase="'current-hospitalized-patients-indicator'"
														class="mb-0 fs-5-5"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														">
														Nombre de patients
														<span class="text-regular"> hospitalisés actuellement. </span>
													</p>
													<p
														*ngSwitchCase="'current-unreachable-patients-indicator'"
														class="mb-0 fs-5-5"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														">
														Nombre de patients
														<span class="text-regular"> injoignables actuellement. </span>
													</p>
													<p
														*ngSwitchCase="'observance-distribution-indicator'"
														class="mb-0 fs-5-5"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														">
														Différents niveaux d'observance
														<span class="text-regular"> sur la patientèle globale. </span>
													</p>
													<p
														*ngSwitchCase="'patients-interactions-indicator'"
														class="mb-0 fs-5-5"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														">
														Interventions patients réalisées
														<span class="text-regular"> les 7 derniers jours. </span>
													</p>
													<p
														*ngSwitchCase="'treatments-distribution-indicator'"
														class="mb-0 fs-5-5"
														[ngClass]="
															registerMedicalUserDashboardConfiguration.isSelected
																? ''
																: 'text-light-gray'
														">
														Répartition
														<span class="text-regular"> des traitements. </span>
													</p>
													<p *ngSwitchDefault>...</p>
												</container-element>
											</div>
										</div>
										<div class="align-self-center form-chec form-switch">
											<input
												class="form-check-input"
												type="checkbox"
												(change)="
													onSelectedIndicatorChange(
														registerMedicalUserDashboardConfiguration.code
													)
												"
												[checked]="registerMedicalUserDashboardConfiguration.isSelected"
												role="switch"
												id="flexSwitchCheckDefault9" />
											<label class="form-check-label" for="flexSwitchCheckDefault9"></label>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
			</div>
			<div class="close-window position-absolute z-index-4">
				<button
					type="button"
					title="Fermer la modale"
					mat-dialog-close
					class="unstyled shadow color-primary bg-white btn p-1">
					<i class="icon-closed color-primary fs-26"></i>
				</button>
			</div>
			<form class="d-flex pt-4 justify-content-between fs-5 position-absolute btn-indicateurs">
				<div class="align-self-start">
					<button type="button" class="btn btn-primary text-uppercase" (click)="close()">Retour</button>
				</div>
				<div>
					<button
						type="button"
						class="btn btn-primary btn-hover bg-color-primary text-white text-uppercase"
						(click)="submitConfiguration()">
						Je confirme mes choix
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
