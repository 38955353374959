import { HttpParams, HttpParamsOptions } from "@angular/common/http";

export class AuthorityHttpParam extends HttpParams {
	constructor(
		public isAuthorityRequest: boolean,
		options?: HttpParamsOptions
	) {
		super(options);
	}
}
