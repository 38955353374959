<div *ngIf="item" class="d-flex flex-column">
	<div>
		<span #textbox [class.show-less]="expand === false" style="white-space: pre-line">
			{{ item.value }}
		</span>
		<span class="btn-read-more p-1" (click)="toggle()" *ngIf="isLongText" (keyup.enter)="toggle()" role="dialog">
			<i [ngClass]="{ 'icon-pagination-down': !expand, 'icon-pagination-up': expand }"></i>
			{{ anchor }}
		</span>
	</div>
</div>
