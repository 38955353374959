import { formatDate } from "@angular/common";
import { Params } from "@angular/router";

import { TableView } from "./table/table-view";

export class BrowsePaging {
	currentPage = 1;
	filteredColumns: BrowseFilteredColumn[] = [];
	includedColumns: BrowseIncludedColumn[] = [];
	pageSize = 1;
	sortColumns: BrowseSortColumn[] = [];

	constructor(init?: Partial<BrowsePaging>) {
		Object.assign(this, init);

		if (!this.filteredColumns) this.filteredColumns = [];
	}

	load(tableView: TableView): void {
		this.includedColumns = [];
		this.filteredColumns = tableView.filteredColumns.map(e => {
			return {
				name: e.name,
				operation: e.operator as string,
				value: e.value,
			};
		});

		this.currentPage = tableView.pageIndex + 1;
		this.pageSize = tableView.pageSize;

		this.sortColumns = [
			{
				direction: tableView.sort.sortDirection,
				name: tableView.sort.sortColumn,
			},
		];
	}

	generateUrlQuery(): string {
		let fragmentsUrl = `pageSize=${this.pageSize}&currentPage=${this.currentPage}`;

		this.sortColumns.forEach((sortColumn, index) => {
			fragmentsUrl += `&sortColumns[${index}].name=${sortColumn.name}&sortColumns[${index}].direction=${sortColumn.direction}`;
		});

		this.filteredColumns.forEach((filteredColumn, index) => {
			fragmentsUrl += `&filteredColumns[${index}].name=${filteredColumn.name}&filteredColumns[${index}].operation=${filteredColumn.operation}&filteredColumns[${index}].value=${filteredColumn.value}`;
		});

		return fragmentsUrl;
	}

	addFiltersFromParams(params: Params): void {
		if (!params) return;

		if (!params["filteredColumns.name"] || !params["filteredColumns.operation"] || !params["filteredColumns.value"])
			return;

		if (
			Array.isArray(params["filteredColumns.name"]) &&
			Array.isArray(params["filteredColumns.operation"]) &&
			Array.isArray(params["filteredColumns.value"])
		) {
			params["filteredColumns.name"].forEach((name, index) => {
				const value = params["filteredColumns.value"][index];
				const operation = params["filteredColumns.operation"][index];
				if (
					!this.filteredColumns.find(f => f.name === name && f.operation === operation && f.value === value)
				) {
					this.filteredColumns.push({
						name,
						operation,
						value,
					});
				}
			});
			return;
		}

		const duplicatedFilter = this.filteredColumns.find(
			f =>
				f.name === params["filteredColumns.name"] &&
				f.operation === params["filteredColumns.operation"] &&
				f.value === params["filteredColumns.value"]
		);
		if (duplicatedFilter) return;

		this.filteredColumns.push({
			name: params["filteredColumns.name"],
			operation: params["filteredColumns.operation"],
			value: params["filteredColumns.value"],
		});
	}

	protected formatDate(date: Date): string {
		return formatDate(date, "yyyy-MM-dd", "fr");
	}
}

export interface BrowseFilteredColumn {
	name: string;
	operation: string;
	value: any;
}

export interface BrowseSortColumn {
	direction: string;
	name: string;
}

export interface BrowseIncludedColumn {
	name: string;
}
