import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { URLEncoder } from "src/app/types";
import {
	BrowseScyovaPatientsCommand,
	PagerScyovaPatientAnalysis,
	ScyovaDocumentsSet,
	ScyovaMembershipRequestDocumentsSet,
	ScyovaPatientCard,
	ScyovaTreatmentProgression,
} from "src/app/models/scyova";
import { SearchPatientsResult } from "src/app/models";

@Injectable({
	providedIn: "root",
})
export class ScyovaMedicalUsersService {
	private readonly httpClient = inject(HttpClient);

	browsePatients(
		medicalUserId: string,
		command: BrowseScyovaPatientsCommand
	): Observable<PagerScyovaPatientAnalysis> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
			fromString: command.generateUrlQuery(),
		});

		return this.httpClient.get<PagerScyovaPatientAnalysis>(
			`/scyova/medical-users/${medicalUserId}/patients/browse`,
			{
				params: params,
			}
		);
	}

	searchPatientsByTerms(
		medicalUserId: string,
		terms: string,
		forcedPrescriberIds: string[] = []
	): Observable<SearchPatientsResult> {
		let params = new HttpParams().set("terms", terms);

		for (const id of forcedPrescriberIds) {
			params = params.append("forcedPrescriberIds", id);
		}

		return this.httpClient.get<SearchPatientsResult>(`/scyova/medical-users/${medicalUserId}/patients/search`, {
			params: params,
		});
	}

	getScyovaPatientCard(medicalUserId: string, patientId: string): Observable<ScyovaPatientCard> {
		return this.httpClient.get<ScyovaPatientCard>(`/scyova/medical-users/${medicalUserId}/patients/${patientId}`);
	}

	getScyovaTreatmentProgression(medicalUserId: string, patientId: string): Observable<ScyovaTreatmentProgression> {
		return this.httpClient.get<ScyovaTreatmentProgression>(
			`/scyova/medical-users/${medicalUserId}/patients/${patientId}/treatment-progression`
		);
	}

	getScyovaDocumentsSet(medicalUserId: string, patientId: string): Observable<ScyovaDocumentsSet> {
		return this.httpClient.get<ScyovaDocumentsSet>(
			`/scyova/medical-users/${medicalUserId}/patients/${patientId}/documents`
		);
	}

	getScyovaMembershipRequestDocumentsSet(medicalUserId: string): Observable<ScyovaMembershipRequestDocumentsSet> {
		return this.httpClient.get<ScyovaMembershipRequestDocumentsSet>(
			`/scyova/medical-users/${medicalUserId}/documents/membership-request`
		);
	}
}
