<div
	*ngIf="item"
	class="d-flex align-items-center justify-content-center align-items-center"
	[class.text-center]="item.isTextCenter">
	<span
		[ngClass]="
			item.hideInitials ? 'd-flex align-items-center justify-content-center' : 'flex-grow-1 d-flex flex-column'
		">
		<span *ngIf="item.withIcon" class="pin-circle-patient-file">
			<i [ngClass]="item.iconCssClass" class="fs-3">&nbsp;</i>
		</span>
		<a
			[routerLink]="item.isLinkVisible ? item.link : null"
			class="d-flex text-decoration-none cs-pointer patient-name"
			title="{{ item.linkTooltip }}"
			[class.disabled]="!item.isLinkVisible">
			<span class="initiales pin-circle white me-4 text-medium align-self- z-index-1" *ngIf="!item.hideInitials"
				>{{ item.shortName | uppercase }}
			</span>
			<div class="d-flex flex-column mw-12vw">
				<div class="d-flex flex-wrap" *ngIf="!item.isOnlyFullName; else isOnlyFullName">
					<div class="text-bold float-start pt-1px">{{ item.familyName | uppercase }}&nbsp;</div>
					<span class="inline-flex pt-1px">{{ item.givenName | titlecase }}</span>
				</div>
				<ng-template #isOnlyFullName>
					<div class="text-bold float-start pt-1px">{{ item.familyName }}&nbsp;</div>
				</ng-template>
				<span class="flex-grow-1" *ngIf="!item.hidePersonId">
					{{ item.patientId }}
				</span>
				<span class="flex-grow-1 patient-cell__complementary" *ngIf="item.complementaryText01">
					<span [ngClass]="item.complementaryCssClass02">
						{{ item.complementaryText02 }}
					</span>
					<span [ngClass]="item.complementaryCssClass01">
						{{ item.complementaryText01 }}
					</span>
				</span>
			</div>
		</a>
	</span>
</div>
