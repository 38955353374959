import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthLogoutEvent } from "src/app/constants/auth-service.constant";
import { AnnouncementsService } from "src/app/services/announcements.service";
import { AuthService } from "src/app/services/auth.service";
import { Logger } from "src/app/services/logger.service";
import { Impersonation } from "src/app/types/impersonation";

const log = new Logger("SigninCallbackComponent");

@Component({
	selector: "app-signin-callback",
	templateUrl: "signin-callback.component.html",
})
export class SigninCallbackComponent implements OnInit {
	private rootUrl = "/";

	constructor(
		private _authService: AuthService,
		private _announcementsService: AnnouncementsService,
		private _router: Router
	) {}

	ngOnInit(): void {
		Impersonation.resetImpersonationInsideCache();

		let uri = this._authService.stateUrl ?? this.rootUrl;

		const redirectUrls = ["/signin-callback"];

		if (this.shouldRedirect(uri, redirectUrls) && this._authService.user?.roles) {
			if (this._authService.user.isAdmin) uri = "/admin";
			if (this._authService.user.isAdministrative) uri = "/admin";
			if (this._authService.user.isCustomerRelationDepartment) uri = "/admin";
			if (this._authService.user.isMedicalTechnicalAdvisor) uri = "/admin";
			if (this._authService.user.isRegionalOrDevelopmentManager) uri = "/admin";
			if (this._authService.user.isPrescriber) uri = "/prescriber/patients-selection";
			if (this._authService.user.isSecretary) uri = "/prescriber/patients-selection";
		}

		this._announcementsService.saveIsFromSigninCallback(true);

		const queryParams: Record<string, string> = {};
		if (!this.shouldRedirect(uri, redirectUrls)) {
			if (uri.includes("?")) {
				const parts = uri.split("?");

				uri = parts[0];
				const params = parts[1];

				const searchParams = new URLSearchParams(params);
				searchParams.forEach((value, key, _) => {
					queryParams[key] = value;
				});
			} else if (uri === AuthLogoutEvent.tokenRevokedLogoutEvent) {
				uri = this.rootUrl;
			}
		}

		this._authService.ensureUserConnection(true).finally(() => {
			log.debug(`Navigate to ${uri}`);
			this._router.navigate([uri], { queryParams }).then(() => {});
		});
	}

	private shouldRedirect(uri: string, redirectUrls: string[]): boolean {
		return uri === this.rootUrl || redirectUrls.some(r => uri.includes(r));
	}
}
