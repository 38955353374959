import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModalShowMessageType } from "src/app/@shared/constants/message-modal.constant";
import { MessageType } from "src/app/constants/message-type.constant";

@Component({
	selector: "app-modal-message-simple.component",
	templateUrl: "./modal-message-simple.component.html",
})
export class ModalMessageSimpleComponent {
	@Output() readonly closed = new EventEmitter();

	dataToDisplay: any;
	modalMessageType!: string;
	modalTitle: any;

	modalShowMessageType = ModalShowMessageType;
	messageType = MessageType;

	constructor(
		private _diag: MatDialogRef<ModalMessageSimpleComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { modalTitle: string; dataToDisplay: any; modalMessageType: string }
	) {
		this.dataToDisplay = this.data.dataToDisplay;
		this.modalMessageType = this.data.modalMessageType;
		this.modalTitle = this.data.modalTitle;
	}

	close(): void {
		this._diag.close();
	}
}
