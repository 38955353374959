<!-- contenu global du composant -->
<div id="content-wrapper" class="d-flex flex-column v2">
	<div id="content">
		<!-- navigation du composant -->

		<!-- ########### COMMENTAIRE DESTINÉ >>> DEVS ############ -->
		<!-- La classe 'navbar-fixed' switch au moment où l'utilisateur scroll vers le bas (smartphone), et le moment où l'utilisateur arrive en haut de page (enlever cette classe). -->
		<nav
			class="navbar navbar-fixe navbar-expand navbar-light topbar mb-4 mb-sm-1 mb-sm-0 static-top pb-0 pb-lg-2 position-relative">
			<div class="row ms-1 align-items-center align-items-md-end align-items-lg-start">
				<div class="col-6 col-xs-6 col-lg-3 order-lg-1 px-0 my-2">
					<span class="fs-4 mb-1 title-header">Fiche patient</span>
					<span class="fs-4 nom-patient">Huguette BERTHE</span>
				</div>
				<div class="col-6 col-xs-6 col-lg-4 order-lg-3 ps-0 my-2 pe-3">
					<div class="d-flex flex-column pe-5 my-2 d-none d-sm-block">
						<div class="d-flex justify-content-end">
							<div class="align-self-center">
								<span class="icon-logo-elivie fs-4 pe-3"
									><span class="path1"></span><span class="path2"></span><span class="path3"></span
									><span class="path4"></span><span class="path5"></span><span class="path6"></span
									><span class="path7"></span><span class="path8"></span
								></span>
							</div>
							<div class="">
								<a href="" class="btn-top-nav fs-2">
									<i class="icon-chart"></i>
									<span class="info-b bg-primary p-2 shadow text-light fadeInBottom"
										>Rapport d'observance</span
									>
								</a>
							</div>
						</div>
						<div class="d-flex justify-content-end">
							<div class="d-none d-lg-inline-block">
								<span class="text-capitalize border-end me-1 pe-1">BORDEAUX</span>
								<span class="text-capitalize text-bolder d-none d-lg-inline-block">05 17 17 70 03</span>
							</div>
						</div>
					</div>
					<div class="d-flex justify-content-end d-sm-none">
						<button (click)="openCloseXSMenu(false)" class="pin-circle btn pin-circle-xl shadow-xs">
							<i class="icon-icon-plus fs-6 position-absolute"></i>
						</button>
					</div>

					<!-- <div class="row align-items-center align-items-end mx-0">
						<div class="col-sm-8 col-xs-6 d-none d-sm-inline-block px-0">
							<ul class="list-entete-r1 px-0 my-0 mx-auto">
								<li class="nav-item dropdown no-arrow text-end">
									<span class="icon-logo-elivie fs-3"
										><span class="path1"></span><span class="path2"></span
										><span class="path3"></span><span class="path4"></span
										><span class="path5"></span><span class="path6"></span
										><span class="path7"></span><span class="path8"></span
									></span>
								</li>
								<li>
									<div class="d-sm-inline-block d-inline-block position-relative">
										<a href="" class="">
											<i class="icon-chart"></i>
											<span
												class="info-b info-b-down bg-primary p-2 shadow text-light fadeInRight text-start"
												>Rapport d'observance</span
											>
										</a>
									</div>
								</li>
								<li class="text-end">
									<span class="text-capitalize border-end me-1 pe-1 d-none d-lg-inline-block"
										>BORDEAUX</span
									>
									<span class="text-capitalize text-bolder d-none d-lg-inline-block"
										>05 17 17 70 03</span
									>
								</li>
							</ul>
						</div>
						<div class="col-sm-4 col-xs-6 px-0">
							<ul class="list-entete-r2 px-0 my-0 mx-auto text-start fs-2 text-end row align-items-end">
								<li class="d-none d-sm-block nav-item col-6">
									<div class="d-sm-inline-block d-inline-block position-relative">
										<a href="" class="">
											<i class="icon-chart"></i>
											<span
												class="info-b info-b-down bg-primary p-2 shadow text-light fadeInRight text-start"
												>Rapport d'observance</span
											>
										</a>
									</div>
								</li>
								<li
									class="nav-item nav-item-contact d-inline-block ps-2 pt-2 col-8 col-sm-6 position-relative"
								>
									<a href="" class="pin-circle pin-circle-xl shadow-xs">
										<i class="icon-contact"></i>
										<span class="info-b bg-primary p-2 shadow text-light fadeInRight"
											>Contacter Elivie</span
										>
									</a>
								</li>
								<li
									class="nav-item nav-item-contact d-inline-block ps-2 col-4 position-relative d-sm-none"
								>
									<a href="" class="pin-circle pin-circle-xl shadow-xs">
										<i class="icon-search"></i>
										<span class="info-b bg-primary p-2 shadow text-light fadeInRight"
											>Rechercher</span
										>
									</a>
								</li>
							</ul>
						</div>
					</div>  -->
				</div>
				<div class="col-lg-5 order-lg-2 px-0 my-0 d-none d-sm-block pe-sm-3 pe-lg-0">
					<form
						class="d-sm-inline-block form-inline mr-auto ml-md-3 my-2 mw-100 navbar-search pe-sm-5 pe-lg-0">
						<div class="input-group">
							<input
								type="text"
								class="form-control rounded-pill bg-white border-0 small"
								placeholder="Chercher un patient, une prescription..."
								aria-label="Search"
								aria-describedby="basic-addon2" />
							<div class="input-group-search">
								<button class="btn" type="button">
									<i class="icon-search"></i>
								</button>
							</div>
						</div>
						<p class="text-sm-end text-start adv-search pt-2"><a href="#">Recherche avancée</a></p>
					</form>
				</div>
			</div>
			<!-- menu XS (smartphone) -->
			<div
				class="menu-xs fadeInRight rounded-pill bg-white position-absolute d-flex"
				[class.active]="isMenuXSOpen">
				<div class="flex-fill d-flex margin-top">
					<button (click)="openCloseXSMenu(false)" class="btn p-0">
						<i class="icon-icon-plus fs-5 m-0 fermer"></i>
						<span class="fs-12p">Fermer</span>
					</button>
				</div>
				<ul class="flex-fill d-flex m-0 p-0 justify-content-end mt-1 me-1">
					<li class="nav-item nav-item-contact px-4 pt-2 position-relative">
						<button class="init pin-circle pin-circle-xl shadow-xs">
							<i class="icon-contact"></i>
							<span class="info-b bg-primary p-2 shadow text-light">Contacter Elivie</span>
						</button>
					</li>
					<li class="nav-item nav-item-contact px-4 pt-2 position-relative">
						<button
							(click)="openCloseNoteMenu(false)"
							href=""
							class="init pin-circle pin-circle-xl shadow-xs">
							<i class="icon-modify"></i>
							<span class="info-b bg-primary p-2 shadow text-light">Editer</span>
						</button>
					</li>
					<li class="nav-item nav-item-contact ps-3 pt-2 position-relative">
						<button class="init pin-circle pin-circle-xl shadow-xs">
							<i class="icon-search"></i>
							<span class="info-b bg-primary p-2 shadow text-light">Rechercher</span>
						</button>
					</li>
				</ul>
			</div>
			<!-- menu right (tablet & desktop) -->
			<div class="menu-right rounded bg-white shadow py-2 px-3 position-absolute d-none d-sm-block">
				<ul class="components p-0 m-0">
					<li class="position-relative border-bottom">
						<a class="btn-bulle d-flex align-self-center my-2 text-decoration-none">
							<i class="icon-contact fs-4"></i>
							<span class="info-b bg-primary p-2 shadow text-light fadeInRight text-nowrap">
								Contacter Elivie
							</span>
						</a>
					</li>
					<li class="position-relative">
						<button
							(click)="openCloseNoteMenu(false)"
							class="btn p-0 btn-bulle d-flex align-self-center my-2 text-decoration-none">
							<i class="icon-modify fs-4"></i>
							<span class="info-b bg-primary p-2 shadow text-light fadeInRight text-nowrap"
								>Créer une note</span
							>
						</button>
					</li>
				</ul>
			</div>
			<!-- Bloc ajout de note -->
			<div
				class="ajout-note rounded shadow bg-white position-absolute fadeInRight"
				[class.active]="isMenuNoteOpen">
				<div class="d-flex align-items-center border-bottom pb-2">
					<i class="icon-modify fs-5 mb-2 me-2"></i>
					<span class="fs-5 text-medium flew-grow-1 flex-fill"> Mes notes pour ce patient </span>
					<button class="btn btn-popover pin-circle position-relative" (click)="openCloseNoteMenu(false)">
						<i class="icon-icon-plus position-absolute top-50 start-50 rotate-45 translate-middle"></i>
					</button>
				</div>
				<div class="d-flex flex-column border-bottom py-2 pb-4">
					<span class="fs-6 mb-1 text-medium flew-grow-1 flex-fill"> Nouvelle note </span>
					<div class="form-floating">
						<textarea
							class="form-control"
							placeholder="Laisser un commentaire ici"
							id="floatingTextarea2"
							style="height: 200px"></textarea>
						<label for="floatingTextarea2">À ce jour le patient...</label>
					</div>
					<div class="row g-0 btn-filters position-relative margin-top-m10">
						<button class="col-6 btn text-uppercase btn-default text-medium">annuler</button>
						<button class="col-6 btn text-uppercase text-white text-medium">enregistrer</button>
					</div>
				</div>
				<div class="pt-2">
					<span class="fs-6 mb-1 text-medium flew-grow-1 flex-fill"> Anciennes notes </span>
					<div class="table-responsive-lg">
						<table class="table table-striped mb-0 align-middle">
							<thead>
								<th class="text-center">
									<span class="d-block text-regular">Date</span>
								</th>
								<th class="text-center">
									<span class="d-block text-regular">Ma note</span>
								</th>
								<th class="text-center w-8">
									<span class="d-block text-regular fs-7">Actions</span>
								</th>
							</thead>
							<tbody>
								<!-- 1er élément -->
								<tr class="alert" role="alert">
									<td class="align-top">22/02/2022</td>
									<td class="align-top">
										dmflqjdb jhd gqjhgdqj dqhgdj qhgdj qhgsdjq gs dmflqjdb jhd gqjhgdqj dqhgdj qhgdj
										qhgsdjq gs dmflqjdb jhd gqjhgdqj dqhgdj qhgdj qhgsdjq gs
									</td>
									<td class="text-center mw-3">
										<div class="d-flex flex-column align-items-center justify-content-between">
											<button class="btn pin-circle my-1">
												<i
													class="icon-modify position-absolute top-50 start-50 translate-middle"></i>
											</button>
											<button class="btn pin-circle my-1">
												<i
													class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</div>
									</td>
								</tr>
								<!-- 2e élément -->
								<tr class="alert" role="alert">
									<td class="align-top">22/02/2022</td>
									<td class="align-top">dmflqjdb jhd gqjhgdqj dqhgdj qhgdj qhgsdjq gs</td>
									<td class="text-center mw-3">
										<div class="d-flex flex-column align-items-center justify-content-between">
											<button class="btn pin-circle my-1">
												<i
													class="icon-modify position-absolute top-50 start-50 translate-middle"></i>
											</button>
											<button class="btn pin-circle my-1">
												<i
													class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</div>
									</td>
								</tr>
								<!-- 3e élément -->
								<tr class="alert" role="alert">
									<td class="align-top">22/02/2022</td>
									<td class="align-top">
										dmflqjdb jhd gqjhgdqj dqhgdj qhgdj qhgsdjq gs dmflqjdb jhd gq gs dmflqjdb jhd
										gqjhgdqj dqhgdj qhgdj qhgsdjq gs
									</td>
									<td class="text-center mw-3">
										<div class="d-flex flex-column align-items-center justify-content-between">
											<button class="btn pin-circle my-1">
												<i
													class="icon-modify position-absolute top-50 start-50 translate-middle"></i>
											</button>
											<button class="btn pin-circle my-1">
												<i
													class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</nav>
		<!-- contenu du composant -->
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="row">
						<div class="col-12 hori-verti pt-5 position-relative">
							<div class="rounded-pill timeline">
								timeline
								<div class="timeline-container rounded-pill bg-color-primary position-relative">
									<div
										class="rounded-pill w-70 bg-green progress-time"
										role="progressbar"
										aria-valuenow="75"
										aria-valuemin="0"
										aria-valuemax="100"></div>
								</div>
							</div>
							<div class="timeline-items col-12">
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-1 d-block"
											name="timetip-tab2"
											id="timetip-tab2" />
										<label for="timetip-tab2" class="circle"> </label>
										<div class="timetip">
											<span class="debut text-light">Début du programme</span>
											<i class="icon-date-picker icon text-green icon fs-5"></i>
											<span class="text-start fs-12p lh-12 mb-2"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Compte rendu</span
													>
												</div>
												<div class="d-flex align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-table-2 position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Grille d'autonomisation</span
													>
												</div>
											</div>
										</div>
										<div class="time-show">
											<span class="debut text-light">Début du programme</span>
											<input
												type="checkbox"
												class="timetip-1 d-block"
												name="timetip-tab2"
												id="timetip-tab2" />
											<label
												for="timetip-tab2"
												class="btn-time btn pin-circle pin-circle-xl green">
												<i
													class="icon-date-picker icon fs-5 position-absolute top-50 start-50 translate-middle"></i>
											</label>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J0</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab3"
											id="timetip-tab3" />
										<label for="timetip-tab3" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2 text-light"
												>Documents<br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Compte rendu</span
													>
												</div>
												<div class="d-flex align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-table-2 position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Grille d'autonomisation</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J1</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab4"
											id="timetip-tab4" />
										<label for="timetip-tab4" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2 text-light"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Compte rendu</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J2</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab5"
											id="timetip-tab5" />
										<label for="timetip-tab5" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2 text-light"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Compte rendu</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J3</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab6"
											id="timetip-tab6" />
										<label for="timetip-tab6" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2 text-light"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Compte rendu</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J4</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab7"
											id="timetip-tab7" />
										<label for="timetip-tab7" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2 text-light"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type text-light"
														>Compte rendu</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J5</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab8"
											id="timetip-tab8" />
										<label for="timetip-tab8" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type">Compte rendu</span>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J6</h1>
										<span class="text-light"
											>Prévu le :<br />
											26 avril 2023</span
										>
										<div class="linear"></div>
										<span class="text-light"
											>Réalisé le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item active current">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab9"
											id="timetip-tab9" />
										<label for="timetip-tab9" class="circle"> </label>
										<div class="timetip">
											<span class="text-start fs-12p lh-12 mb-2"
												>Documents <br />
												accessibles</span
											>
											<div class="d-flex flex-column">
												<div class="d-flex mb-2 align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type">Compte rendu</span>
												</div>
												<div class="d-flex align-self-start">
													<button class="btn pin-circle">
														<i
															class="icon-table-2 position-absolute top-50 start-50 translate-middle"></i>
													</button>
													<span class="ms-2 align-self-center file-type"
														>Grille d'autonomisation</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J7</h1>
										<p class="text-light">Description of J7</p>
									</div>
								</div>
								<div class="timeline-item">
									<div class="circle-timetip">
										<span class="circle"> </span>
									</div>
									<div class="timeline-content">
										<h1>J8</h1>
										<span class="text-light">
											Prévu le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item">
									<div class="circle-timetip">
										<span class="circle"> </span>
									</div>
									<div class="timeline-content">
										<h1>J9</h1>
										<span class="text-light">
											Prévu le :<br />
											26 avril 2023
										</span>
									</div>
								</div>
								<div class="timeline-item">
									<div class="circle-timetip">
										<input
											type="checkbox"
											class="timetip-2 d-block"
											name="timetip-tab12"
											id="timetip-tab12" />
										<label for="timetip-tab12" class="circle"> </label>

										<div class="time-last">
											<label for="timetip" class="btn-time btn pin-circle pin-circle-xl gray">
												<i
													class="icon-date-picker icon fs-5 position-absolute top-50 start-50 translate-middle"></i>
											</label>
										</div>
									</div>
									<div class="timeline-content">
										<h1>J180</h1>
										<span class="text-light">
											27 octobre <br />
											2023
										</span>
									</div>
								</div>
							</div>
							<div class="position-absolute top-0 z-index-1 d-xl-none" style="left: -225px">
								<img
									src="assets/img/bg-left-patient-care-form.png"
									class="img-fluid w-40"
									alt="patient-care-form" />
							</div>
							<div class="position-absolute bottom-0 z-index-1 d-xl-none" style="right: -30px">
								<img
									src="assets/img/bg-right-patient-care-form.svg"
									class="img-fluid w-100"
									alt="patient-care-form" />
							</div>
						</div>
					</div>

					<div class="row mb-1">
						<div class="col-12 col-lg-3">
							<span class="fs-5 text-medium d-block mb-3 mb-sm-1 ps-3 ps-lg-0"
								>Informations générales</span
							>
						</div>
						<div class="col-12 col-lg-9">
							<span class="d-none d-xl-block fs-5 text-medium d-block mb-3 mb-sm-1 ps-3 ps-lg-0"
								>Synthèse des 3 derniers mois</span
							>
						</div>
					</div>
					<div class="row scyova">
						<div class="col-12 col-xl-6 mb-3 position-relative">
							<div class="d-flex flex-row bloc-patient fs-wide-screen h-100">
								<!-- INFO GENERALE PATIENT -->
								<div
									class="card card-evenement bg-white border-left-primary py-0 rounded-start overflow-none z-index-110">
									<div class="card-body p-O pb-2">
										<div class="d-flex h-100">
											<div class="py-2 px-3 align-self-center text-green d-none d-lg-block">
												<!-- CHANGER ICI LE NOM DE CLASSE DU PICTO HOMME/FEMME par : "icon-patient" ou "icon-patiente" -->
												<i class="icon-patient-unknown fs-1"></i>
												<div class="rounded-pill d-flex flex-column text-center">
													<span class="mb-0 date text-white mt-1">Age</span>
													<span class="mb-0 text-regular text-white">67 ans</span>
												</div>
											</div>
											<div class="py-2 ps-3 ps-lg-0 align-self-stretch flex-grow-1">
												<p class="fs-6 mb-1 text-green">
													<span class="text-uppercase text-bold">Huguettes Berthes</span>
												</p>
												<div class="d-flex justify-content-between pe-5 pe-xl-0">
													<span class="date">Née le : 06 juin 1966</span>
													<span class="date d-lg-none">Age : 67 ans</span>
												</div>
												<div class="d-flex justify-content-start text-gray text-regular mt-3">
													<i class="icon-xs-screen me-1"></i
													><span class="tel">06 75 64 23 41</span>
												</div>
												<div class="carte-vitale d-flex justify-content-start mt-3 text-gray">
													<i class="icon-patients me-1"></i>
													<div class="lh-1 flex-grow-1">
														<span class="text-light d-block">Carte vitale</span>
													</div>
												</div>
												<div class="d-flex text-gray justify-content-start mt-xxxl-1">
													<span class="text-regular">2540675588157 80</span>
												</div>
											</div>
											<div class="p-2 align-self-start text-end">
												<button class="btn pin-circle green">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
									</div>

									<!-- END HOSPITALISATION -->
								</div>

								<!-- ALERTE INFO  -->
								<div
									class="card card-indicateur bg-success py-3 rounded-end cs-pointer flex-fill shadow z-index-9">
									<div class="card-body py-lg-0 p-0">
										<div class="d-flex flex-column">
											<div class="align-self-center w-100 flex-fill d-flex">
												<div class="border-bottom flex-fill h-10p"></div>
												<div class="text-center text-regular w-50 mx-1">
													<p class="lh-1">Ventilation à pression continue PPC</p>
												</div>
												<div class="border-bottom flex-fill h-10p"></div>
											</div>

											<!-- PrescriptionStatus.toRenew == TRUE -->
											<div class="p-2 align-self-center text-center py-3">
												<button class="btn btn-success d-none d-lg-inline-block">
													renouveler la prescription
												</button>
												<button
													class="btn btn-success d-inline-block d-lg-none text-uppercase text-regular fs-18">
													renouveler la prescription
												</button>
											</div>
											<!-- END PrescriptionStatus.toRenew == TRUE -->

											<div class="px-2 align-self-center flex-grow-1">
												<div class="text-center">
													<p>
														Date de fin de prescription :
														<span class="text-medium">09/11/2021</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div
									class="tag-info d-none pt-2 pt-sm-0 z-index-106 position-absolute card border red border-2 fade show shadow bs-popover-end"
									[class.d-xl-block]="isTagOpen">
									<div class="popover-body p-2 text-gray text-wrap">
										<p>Votre patient a été hospitalisé.</p>
										<p class="mb-0">Date d'admission :</p>
										<span class="text-medium mb-2">23/06/2022</span>
										<p class="mb-0">Date de sortie prévue :</p>
										<span class="text-medium">16/09/2022</span>
									</div>
									<div class="position-absolute w-100 text-end bottom-0 overflow-hidden">
										<img
											src="../../../assets/img/bg-hospitalisation.png"
											class="img-fluid"
											alt="info hospitalisation" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-xl-6 mb-3 position-relative bloc-docs">
							<div
								class="d-flex flex-row bg-white rounded p-3 position-relative shadow fs-wide-screen h-100">
								<div class="d-flex justify-content-between flex-column border-end w-50">
									<div
										class="d-flex justify-content-between align-items-start column-gap-3 pe-3 mb-5 mb-xl-3">
										<div class="d-flex column-gap-3 down-max-size">
											<i class="icon-check-file fs-3 text-green"></i>
											<div class="d-flex flex-column row-gap-2">
												<span class="text-bold fs-5">inscription du patient</span>
												<span class="fs-5-5">Formulaire de consentement signé</span>
											</div>
										</div>
										<button class="btn pin-circle align-self-start">
											<i
												class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
									<div class="d-flex justify-content-between align-items-start column-gap-3 pe-3">
										<div class="d-flex column-gap-3 down-max-size">
											<i class="icon-check-file fs-3 text-green"></i>
											<div class="d-flex flex-column row-gap-2">
												<span class="text-bold fs-5">Réunion de coordination</span>
												<span class="fs-5-5">Compte rendu</span>
											</div>
										</div>
										<button class="btn pin-circle align-self-start">
											<i
												class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
								</div>
								<div class="d-flex justify-content-between flex-column w-50">
									<div
										class="d-flex justify-content-between align-items-start column-gap-3 ps-3 mb-5 mb-xl-0">
										<div class="d-flex column-gap-3 down-max-size">
											<i class="icon-check-file fs-3 text-green"></i>
											<div class="d-flex flex-column row-gap-2">
												<span class="text-bold fs-5">Formation IDEL</span>
												<span class="fs-5-5">Fiche d'instruction IDEL</span>
											</div>
										</div>
										<button class="btn pin-circle align-self-start">
											<i
												class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
									<div class="d-flex justify-content-between align-items-start column-gap-3 ps-3">
										<div class="d-flex column-gap-3 down-max-size disabled">
											<i class="icon-closed fs-3 text-light-gray"></i>
											<div class="d-flex flex-column row-gap-2">
												<span class="text-bold fs-5 text-light-gray">Formation IDEH</span>
												<span class="fs-5 text-light-gray">Fiche d'instruction IDEH</span>
											</div>
										</div>
										<button class="btn pin-circle disabled align-self-start">
											<i
												class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="order-1 col-12 col-xl-3 mb-3 position-relative">
							<div
								class="d-flex flex-column flex-sm-row flex-xl-column bloc-patient fs-wide-screen h-100">
								<!-- INFO GENERALE PATIENT -->
								<div
									class="card card-evenement bg-white border-left-primary py-0 rounded-start overflow-none z-index-110">
									<div class="card-body p-O pb-2">
										<div class="d-flex h-100">
											<div class="py-2 px-3 align-self-center text-green d-none d-lg-block">
												<!-- CHANGER ICI LE NOM DE CLASSE DU PICTO HOMME/FEMME par : "icon-patient" ou "icon-patiente" -->
												<i class="icon-patient-unknown fs-1"></i>
												<div class="rounded-pill d-flex flex-column text-center">
													<span class="mb-0 date text-white mt-1">Age</span>
													<span class="mb-0 text-regular text-white">67 ans</span>
												</div>
											</div>
											<div class="py-2 ps-3 ps-lg-0 align-self-stretch flex-grow-1">
												<p class="fs-6 mb-1 text-green">
													<span class="text-uppercase text-bold">Huguettes Berthes</span>
												</p>
												<div class="d-flex justify-content-between pe-5 pe-xl-0">
													<span class="date">Née le : 06 juin 1966</span>
													<span class="date d-lg-none">Age : 67 ans</span>
												</div>
												<div class="d-flex justify-content-start text-gray text-regular mt-3">
													<i class="icon-xs-screen me-1"></i
													><span class="tel">06 75 64 23 41</span>
												</div>
												<div class="carte-vitale d-flex justify-content-start mt-3 text-gray">
													<i class="icon-patients me-1"></i>
													<div class="lh-1 flex-grow-1">
														<span class="text-light d-block">Carte vitale</span>
													</div>
												</div>
												<div class="d-flex text-gray justify-content-start mt-xxxl-1">
													<span class="text-regular">2540675588157 80</span>
												</div>
											</div>
											<div class="p-2 align-self-start text-end">
												<button class="btn pin-circle green">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
									</div>
									<!-- TAG HOSPITALISATION -->
									<div
										class="tag-info d-none pt-2 pt-sm-0 z-index-106 position-absolute card border red border-2 fade show shadow bs-popover-end"
										[class.d-block]="isTagOpen">
										<div class="popover-body p-2 text-gray text-wrap">
											<p>Votre patient a été hospitalisé.</p>
											<p class="mb-0">Date d'admission :</p>
											<span class="text-medium mb-2">23/06/2022</span>
											<p class="mb-0">Date de sortie prévue :</p>
											<span class="text-medium">16/09/2022</span>
										</div>
										<div class="position-absolute w-100 text-end bottom-0 overflow-hidden">
											<img
												src="../../../assets/img/bg-hospitalisation.png"
												class="img-fluid"
												alt="info hospitalisation" />
										</div>
									</div>
									<div
										class="indic-popover d-flex z-index-107 start-50 translate-middle"
										(mouseenter)="isTagOpen = true"
										(mouseleave)="isTagOpen = false">
										<button
											class="btn btn-popover pin-circle red position-relative"
											(hover)="btnTag(false)">
											<i
												class="icon-patient-hopital position-absolute top-50 start-50 translate-middle"></i>
										</button>
										<div>
											<div
												class="rounded-pill border red border-1 pe-2 ps-4 d-flex text-red justify-content-between bg-white"
												style="padding-top: 1px; margin-top: 3px">
												<span class="fs-12p ps-2 text-uppercase text-nowrap"
													>Patient hospitalisé</span
												>
											</div>
										</div>
									</div>
									<div class="position-absolute info-hospitalisation">
										<i class="icon-patients-hospitalises fs-info text-green opacity-80"></i>
									</div>
									<!-- END HOSPITALISATION -->
								</div>

								<!-- ALERTE INFO  -->
								<div
									class="card card-indicateur bg-success py-3 rounded-end cs-pointer flex-fill shadow z-index-9">
									<i class="icon-ordonance"></i>
									<div class="card-body py-lg-0 p-0">
										<div class="d-flex flex-column">
											<div class="align-self-center w-100 flex-fill d-flex">
												<div class="border-bottom flex-fill h-10p"></div>
												<div class="text-center text-regular w-50 mx-1">
													<p class="lh-1">Ventilation à pression continue PPC</p>
												</div>
												<div class="border-bottom flex-fill h-10p"></div>
											</div>

											<!-- PrescriptionStatus.toRenew == TRUE -->
											<div class="p-2 align-self-center text-center py-3">
												<button class="btn btn-success d-none d-lg-inline-block">
													renouveler la prescription
												</button>
												<button
													class="btn btn-success d-inline-block d-lg-none text-uppercase text-regular fs-18">
													renouveler la prescription
												</button>
											</div>
											<!-- END PrescriptionStatus.toRenew == TRUE -->

											<!-- DEBUT Prescription en cours -->
											<!-- <div class="p-2 align-self-center text-center py-1">
												<i class="icon-prescription-en-cours fs-2"></i>
												<span class="text-medium d-block">
													Prescription en cours
												</span>
											</div> -->
											<!-- FIN Prescription en cours -->

											<!-- DEBUT Prescription à jour -->
											<!-- <div class="p-2 align-self-center text-center py-1">
												<i class="icon-prescription-a-jour fs-2"></i>
												<span class="text-medium d-block"> Prescription à jour </span>
											</div> -->
											<!-- FIN Prescription à jour -->

											<!-- DEBUT Prescription en cours de validation -->
											<!-- <div class="p-2 align-self-center text-center py-1">
												<i class="icon-prescription-en-cours fs-2"></i>
												<span class="text-medium d-block">
													Prescription en cours de validation
												</span>
											</div> -->
											<!-- FIn Prescription en cours de validation -->
											<div class="px-2 align-self-center flex-grow-1">
												<div class="text-center">
													<p>
														Date de fin de prescription :
														<span class="text-medium">09/11/2021</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div
									class="tag-info d-none pt-2 pt-sm-0 z-index-106 position-absolute card border red border-2 fade show shadow bs-popover-end"
									[class.d-xl-block]="isTagOpen">
									<div class="popover-body p-2 text-gray text-wrap">
										<p>Votre patient a été hospitalisé.</p>
										<p class="mb-0">Date d'admission :</p>
										<span class="text-medium mb-2">23/06/2022</span>
										<p class="mb-0">Date de sortie prévue :</p>
										<span class="text-medium">16/09/2022</span>
									</div>
									<div class="position-absolute w-100 text-end bottom-0 overflow-hidden">
										<img
											src="../../../assets/img/bg-hospitalisation.png"
											class="img-fluid"
											alt="info hospitalisation" />
									</div>
								</div>
							</div>
							<!--  BLOC SKELETON -->
							<!-- <div class="h-100 w100 skeleton-box position-absolute top-0 left-0">
								<div
									class="d-flex flex-column flex-sm-row flex-xl-column bloc-patient fs-wide-screen h-100"
								>
									<div
										class="card card-evenement bg-white border-left-primary py-2 rounded-start mh-155"
									>
										<div class="card-body skeleton-box p-O">
											<div class="d-flex h-100">
												<div
													class="px-3 align-self-center d-flex flex-column justify-content-between"
												>
													<div class="skeleton-box rounded-circle p-5 bg-odd"></div>
													<span class="skeleton-box w-100 rounded-pill bg-odd p-3"></span>
												</div>
												<div
													class="pe-lg-3 align-self-stretch flex-grow-1 d-flex flex-column justify-content-between"
												>
													<div>
														<span
															class="skeleton-box w-100 rounded-pill bg-odd p-2 mt-0"
														></span>
														<span
															class="skeleton-box w-70 rounded-pill bg-odd p-1 mt-0"
														></span>
													</div>
													<span class="skeleton-box w-100 rounded-pill bg-odd p-2"></span>
												</div>
												<div class="px-2 align-self-start text-end">
													<div class="skeleton-box rounded-circle p-3 bg-odd"></div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="card card-indicateur bg-odd py-3 rounded-end cs-pointer flex-fill shadow mh-125"
									>
										<div
											class="card-body skeleton-box py-lg-0 p-0 d-flex flex-column justify-content-between align-items-center px-5"
										>
											<span class="w-80 rounded-pill bg-white p-2 mt-0"></span>
											<span class="w-100 rounded-pill bg-white p-3 mt-0"></span>
											<span class="w-80 rounded-pill bg-white p-2 mt-0"></span>
										</div>
									</div>
								</div>
							</div> -->
							<!--  END BLOC SKELETON -->
						</div>
						<div class="order-2 d-block d-xl-none col-12">
							<span class="fs-5 text-medium mt-3 ps-3 ps-lg-0"> Synthèse des 3 derniers mois </span>
						</div>

						<div
							class="order-3 order-xl-2 col-12 col-xl-6 mb-3 py-3 pb-5 pb-sm-3 py-xl-0 position-relative">
							<div class="row h-100">
								<!-- Début du bloc carousel -->
								<div class="carousel-syn slide indicator" id="myCarousel">
									<!-- bouttons navigations -->
									<div class="carousel-indicators d-sm-none">
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="0"
											class="rounded-pill"
											aria-label="Slide 1"
											aria-current="true"></button>
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="1"
											aria-label="Slide 2"
											class="active rounded-pill"></button>
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="2"
											aria-label="Slide 3"
											class="rounded-pill"></button>
									</div>
									<!-- Affichage contenu   -->
									<div class="carousel-syn-inner d-flex h-100">
										<!-- 1er élément (actif par défaut) -->
										<div
											class="carousel-syn-item h-100 order-1 order-lg-1 flex-fill position-relative">
											<div class="col-12 h-100">
												<div
													class="card card-info bg-white rounded p-3 position-relative shadow h-100">
													<div
														class="flex-fill pb-lg-2 position-relative d-flex flex-column justify-content-between h-100">
														<div class="d-flex flex-column">
															<div class="mt-2 text-center">
																<div class="d-flex flex-column justify-content-center">
																	<span class="text-medium fs-info py-3 text-nowrap">
																		4h
																	</span>
																	<span class="text-ultra-light unite fs-3 my-1 mx-0">
																		29 min
																	</span>
																</div>
															</div>
														</div>
														<div class="d-block">
															<div class="d-flex flex-column text-center">
																<div class="my-3">
																	<i
																		class="icon-utilisation-moyenne fs-info opacity-80"></i>
																</div>
																<div class="text-ultra-light">Observance moyenne</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<!--  BLOC SKELETON LOADING DATA -->
											<!-- <div
												class="bg-white h-100 w-100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column justify-content-between"
											>
												<div class="px-4 pt-2 d-flex flex-column align-items-center">
													<div class="w-100 rounded-pill bg-color-primary opacity-30 p-3 mb-2"></div>
													<div class="w-80 rounded-pill bg-color-primary opacity-30 p-3"></div>
												</div>
												<div class="px-4 py-2 d-flex flex-column align-items-center">
													<div class="rounded bg-odd-color-primary square"></div>
												</div>
												<div class="px-4 pb-2 d-flex flex-column align-items-center">
													<div class="w-100 rounded-pill bg-color-primary opacity-30 p-2"></div>
												</div>
											</div> -->
										</div>
										<!-- 2e élément -->
										<div
											class="carousel-syn-item active h-100 order-2 order-lg-2 flex-fill position-relative">
											<div class="col-12 h-100">
												<div
													class="tag-info z-index-106 position-absolute card border red border-2 bg-white fade show shadow bs-popover-end"
													role="tooltip"
													id="popover523901">
													<div class="popover-body text-wrap fs-12p">
														Right popover withd azd lorem ippsum dolr sit amettransectectuer
														quam minima credula postero
													</div>
												</div>
												<div
													class="card border red border-2 z-index-105 overflow-none card-info bg-white rounded p-3 position-relative shadow h-100">
													<div class="indic-popover d-flex start-50 translate-middle">
														<button
															class="btn btn-popover pin-circle red position-relative">
															<i
																class="icon-popover position-absolute top-50 start-50 translate-middle"></i>
														</button>
														<div
															class="rounded-pill border red border-1 pt-1 pe-2 ps-4 d-flex text-red justify-content-between bg-white">
															<span class="fs-12p ps-2 text-uppercase"
																>à lire important
															</span>
														</div>
													</div>

													<div
														class="flex-fill pb-lg-2 position-relative d-flex flex-column justify-content-between h-100">
														<div class="d-flex flex-column">
															<div class="mt-2 text-center">
																<span class="text-medium fs-info py-3 text-nowrap">
																	4,77
																</span>
																<div
																	class="d-flex justify-content-center position-relative">
																	<div class="d-flex justify-content-center mt-1">
																		<!-- <div class="align-self-center">
																			<span
																				class="text-ultra-light text-end me-2 mt-1 lh-1"
																			>
																				Ajouter<br />
																				IAH initial
																			</span>
																		</div>
																		<div class="align-self-center">
																			<input
																				type="checkbox"
																				name="iah-popin"
																				id="iah-popin"
																				class="iah-popin d-block"
																			/>
																			<label
																				for="iah-popin"
																				class="btn pin-circle"
																			>
																				<i
																					class="icon-modify position-absolute top-50 start-50 translate-middle"
																				></i>
																			</label>
																			<div
																				class="iah-popin-content text-start w-80 shadow bg-white border border-2 color-primary rounded-pill"
																			>
																				<div class="d-flex">
																					<input
																						type="text"
																						class="form-control py-2 px-3 rounded-pill rounded-start bg-white border-0 small"
																						placeholder="Ex : 9,4"
																						aria-label="IAH"
																					/>
																					<button
																						class="btn rounded-pill rounded-end position-relative bg-"
																					>
																						<i
																							class="icon-save position-absolute top-50 start-50 translate-middle"
																						></i>
																					</button>
																				</div>
																			</div>
																		</div> -->
																		<div class="align-self-center">
																			<span
																				class="text-ultra-light text-uppercase">
																				IAH initial
																			</span>
																		</div>
																		<div class="align-self-center">
																			<span
																				class="text-ultra-light unite fs-3 mx-0 lh-1">
																				9,4
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="d-block">
															<div class="d-flex flex-column text-center">
																<div class="my-3">
																	<i class="icon-iah-moyen fs-info opacity-80"></i>
																</div>
																<div class="text-ultra-light">
																	IAH r&eacute;siduel moyen
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<!--  BLOC SKELETON LOADING DATA -->
											<!-- <div class="bg-white h-100 w-100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column justify-content-between">
													<div class="px-4 pt-2 d-flex flex-column align-items-center">
														<div class="w-100 rounded-pill bg-color-primary opacity-30 p-3 mb-2"></div>
														<div class="w-80 rounded-pill bg-color-primary opacity-30 p-3"></div>
													</div>
													<div class="px-4 py-2 d-flex flex-column align-items-center">
														<div class="rounded bg-odd-color-primary square"></div>
													</div>
													<div class="px-4 pb-2 d-flex flex-column align-items-center">
														<div class="w-100 rounded-pill bg-color-primary opacity-30 p-2"></div>
													</div>
												</div> -->
											<!--  END BLOC SKELETON LOADING DATA -->
										</div>
										<!-- 3e élément -->
										<!-- <div class="carousel-syn-item h-100 order-3 order-lg-3 flex-fill position-relative ">
											<div class="col-12">
												<div
													class="card card-info bg-white rounded p-3 position-relative shadow h-100"
												>
													<div
														class="flex-fill pb-lg-2 position-relative d-flex flex-column justify-content-between h-100"
													>
														<div class="d-flex flex-column">
															<div class="mt-2 text-center">
																<div class="d-flex flex-column justify-content-center">
																	<span class="text-medium fs-info py-3 text-nowrap">
																		12,46
																	</span>
																	<span class="text-ultra-light unite fs-3 my-1 mx-0">
																		l/s
																	</span>
																</div>
															</div>
														</div>
														<div class="d-block">
															<div class="d-flex flex-column text-center">
																<div class="my-3">
																	<i
																		class="icon-mediant-fuite fs-info opacity-80"
																	></i>
																</div>
																<div class="text-ultra-light">
																	Taux de fuite m&eacute;dian
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div> -->

										<!-- 4e élément -->
										<div
											class="carousel-syn-item h-100 order-3 order-lg-3 flex-fill position-relative">
											<!-- <div class="col-12 h-100">
												<div
													class="card card-info bg-white rounded p-3 position-relative shadow h-100"
												>
													<div
														class="flex-fill pb-lg-2 position-relative d-flex flex-column justify-content-between h-100"
													>
														<div class="d-flex flex-column">
															<div class="mt-2 text-center">
																<div class="d-flex flex-column justify-content-center">
																	<span class="text-medium fs-info py-3 text-nowrap">
																		12,46
																	</span>
																	<span class="text-ultra-light unite fs-3 my-1 mx-0">
																		l/s
																	</span>
																</div>
															</div>
														</div>
														<div class="d-block">
															<div class="d-flex flex-column text-center">
																<div class="my-3">
																	<i
																		class="icon-mediant-fuite fs-info opacity-80"
																	></i>
																</div>
																<div class="text-ultra-light">
																	Taux de fuite m&eacute;dian
																</div>
															</div>
														</div>
													</div>
												</div>
											</div> -->

											<div class="col-12 h-100">
												<!-- <div
													class="tag-info  z-index-106 position-absolute card border red border-2 bg-white fade show shadow bs-popover-end"
													role="tooltip"
													id="popover523901"
												>
													<div class="popover-body text-wrap fs-12p">
														Right popover withd azd lorem ippsum dolr sit
														amettransectectuer quam minima credula postero
													</div>
												</div> -->
												<div
													class="card overflow-none card-info bg-white rounded p-3 position-relative shadow h-100">
													<!-- <div class="indic-popover d-flex start-50 translate-middle">
														<button class="btn btn-popover pin-circle red position-relative">
															<i
																class="icon-popover position-absolute top-50 start-50 translate-middle"
															></i>

															
														</button>
														<div class="rounded-pill border red border-1 pt-1 pe-2 ps-4 d-flex text-red justify-content-between bg-white">
															<span class="fs-12p ps-2 text-uppercase">à lire important </span>
														</div>
													</div> -->
													<div
														class="flex-fill pb-lg-2 position-relative d-flex flex-column justify-content-between h-100">
														<div class="d-flex flex-column">
															<div class="mt-2 text-center">
																<div class="d-flex flex-column justify-content-center">
																	<span class="text-medium fs-info py-3 text-nowrap">
																		12,46
																	</span>
																	<span class="text-ultra-light unite fs-3 my-1 mx-0">
																		l/s
																	</span>
																</div>
															</div>
														</div>
														<div class="d-block">
															<div class="d-flex flex-column text-center">
																<div class="my-3">
																	<i
																		class="icon-mediant-fuite fs-info opacity-80"></i>
																</div>
																<div class="text-ultra-light">
																	Taux de fuite m&eacute;dian
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
											class="carousel-syn-item h-100 order-4 order-lg-4 flex-fill position-relative">
											<div class="col-12 h-100">
												<div
													class="card card-info bg-white rounded p-3 position-relative shadow h-100">
													<div
														class="flex-fill pb-lg-2 position-relative d-flex flex-column justify-content-between">
														<div class="d-flex flex-column">
															<div class="mt-2 text-center">
																<div class="d-flex justify-content-center">
																	<span class="text-bold fs-info py-3 text-nowrap">
																		2,06
																	</span>
																	<span
																		class="text-ultra-light align-self-center unite fs-3 pt-2 ms-1">
																		l
																	</span>
																</div>
															</div>
														</div>
														<div class="d-block">
															<div class="d-flex flex-column text-center">
																<div class="text-ultra-light lh-12">
																	Volume <br />courant
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!--  DONNEES INSUFFISANTES -->
							<!-- <div class="card-no-data-1 shadow bg-white rounded h-100 d-flex flex-column justify-content-center">
								<div class="text-center">
									<i class="icon-no-data"></i>
									<p class="text-medium">Données insuffisantes</p>
									<p>
										Votre patient a <span class="text-regular">refusé le télésuivi</span> et Elivie
										n’a pas accès aux données d’observance.
									</p>
								</div>
							</div>   -->
							<!--  FIN DONNEES INSUFFISANTES -->

							<!--  ERREUR TECHNIQUE -->
							<!-- <div class="card-no-data-1 bg-white rounded h-100 d-flex flex-column justify-content-center">
								<div class="text-center">
									<i class="icon-problem text-red"></i>
									<p class="text-medium text-red">Erreur technique !</p>
									<p class="text-gray">
										Nos experts font le maximum pour vous apporter une solution rapidement.
									</p>
								</div>
							</div> -->
							<!--  FIN ERREUR TECHNIQUE -->
						</div>
						<div class="order-4 order-xl-3 col-12 col-xl-3 col-md-12 mb-3 position-relative">
							<div
								class="d-flex flex-column bg-white rounded px-3 pt-1 pb-3 pb-xl-0 position-relative shadow fs-wide-screen h-100">
								<div class="mt-2">
									<span class="text-medium d-block">Les indicateurs</span>
									<p>
										<span class="me-1">Données délivrées par :</span>
										<span class="text-uppercase">airsens 10 autoset</span>
									</p>
								</div>
								<div class="flex-fill d-flex border-bottom border-none mb-2 text-sm-center data-indic">
									<div class="d-flex flex-column w-100 m-auto">
										<div class="d-flex flex-column flex-sm-row">
											<div
												class="flex-fill d-flex flex-xl-column justify-content-between justify-content-sm-center my-1 my-sm-0">
												<div class="fs-6 mb-0 mb-xl-2 align-self-middle">Fuite P95 ++</div>
												<div class="d-xl-none border-bottom flex-grow-1 mx-1"></div>
												<div class="fs-5 mb-0 mb-xl-2 align-self-middle">
													<span class="text-medium">5,51</span> l/min
												</div>
											</div>
											<div
												class="flex-fill d-flex flex-xl-column justify-content-between justify-content-sm-center my-1 my-sm-0">
												<div class="fs-6 mb-0 mb-xl-2 align-self-middle">Pression médiane</div>
												<div class="d-xl-none border-bottom flex-grow-1 mx-1"></div>
												<div class="fs-5 mb-0 mb-xl-2 align-self-middle">
													<span class="text-medium">7,62</span> cm/H20
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="flex-fill d-flex border-none mb-2 text-sm-center data-indic">
									<div class="d-flex flex-column w-100 m-auto">
										<div class="d-flex flex-column flex-sm-row">
											<div
												class="flex-fill d-flex flex-xl-column justify-content-between justify-content-sm-center my-1 my-sm-0">
												<div class="fs-6 mb-0 mb-xl-2 align-self-middle">
													Fuite P95 percentile
												</div>
												<div class="d-xl-none border-bottom flex-grow-1 mx-1"></div>
												<div class="fs-5 mb-0 mb-xl-2 align-self-middle">
													<span class="text-medium">5,51</span> l/min
												</div>
											</div>
											<div
												class="flex-fill d-flex flex-xl-column justify-content-between justify-content-sm-center my-1 my-sm-0">
												<div class="fs-6 mb-0 mb-xl-2 align-self-middle">Pression maximale</div>
												<div class="d-xl-none border-bottom flex-grow-1 mx-1"></div>
												<div class="fs-5 mb-0 mb-xl-2 align-self-middle">
													<span class="text-medium">11,23</span> cm/H20
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- DONNEES INSUFFISANTES -->
							<!-- <div class="card-no-data-2 bg-white rounded h-100">
								<i class="icon-no-data position-absolute top-50 translate-middle-y"></i>
							</div>  -->
							<!--  FIN DONNEES INSUFFISANTES -->

							<!--  ERREUR TECHNIQUE -->
							<!-- <div class="card-no-data-2 bg-white rounded h-100">
								<i class="icon-problem text-red position-absolute top-50 translate-middle-y"></i>
							</div> -->
							<!-- FIN  ERREUR TECHNIQUE -->
							<!-- SKELETON > 576px -->
							<!-- <div class="d-none d-sm-block">
								<div
									class="bg-white h-100 w100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column justify-content-between"
								>
									<div>
										<div class="w-45 rounded-pill bg-odd p-3 mb-2"></div>
										<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
									</div>
									<div class="d-flex justify-content-between">
										<div class="flex-fill px-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-1"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
											<div class="w-100 rounded-pill bg-odd p-2"></div>
										</div>
										<div class="flex-fill px-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-1"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
											<div class="w-100 rounded-pill bg-odd p-2"></div>
										</div>
									</div>
									<div class="border-bottom opacity-80"></div>
									<div class="d-flex justify-content-between">
										<div class="flex-fill px-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-1"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
											<div class="w-100 rounded-pill bg-odd p-2"></div>
										</div>
										<div class="flex-fill px-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-1"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
											<div class="w-100 rounded-pill bg-odd p-2"></div>
										</div>
									</div>
								</div>
							</div> -->

							<!-- FIN SKELETON > 576px -->
							<!-- SKELETON < 576px -->
							<!-- <div class="d-block d-sm-none">
								<div
									class="bg-white h-100 w100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column justify-content-between"
								>
									<div>
										<div class="w-45 rounded-pill bg-odd p-3 mb-3"></div>
										<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
									</div>
									<div class="d-flex justify-content-between">
										<div class="flex-fill">
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2"></div>
										</div>
									</div>
								</div>
							</div> -->
							<!-- FIN SKELETON < 576px -->
						</div>
					</div>
					<div class="row">
						<!-- Affichage TAbleau COMMENTAIRES desktop > 992px -->
						<div class="col-12 d-none d-lg-block my-4 fs-wide-screen position-relative">
							<div class="row">
								<div class="col-12">
									<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0">
										Les derniers &eacute;v&egrave;nements
									</span>
								</div>

								<div class="col position-relative">
									<div class="table-responsive bg-white shadow rounded p-3 overflow-hidden">
										<div class="d-flex p-0">
											<table class="table mb-0 align-middle align-middle">
												<tbody>
													<tr>
														<td class="w-10 ps-4 pe-2">
															<span class="text-medium flex-fill">Date</span>
														</td>
														<td class="position-relative w-25">
															<span class="text-medium flex-fill"
																>Nom de l'agent Elivie
															</span>
														</td>
														<td class="d-none d-sm-block">
															<span class="text-medium flex-fill"> Commentaire </span>
														</td>
														<td class="text-end">
															<button class="btn p-0 fs-7">
																<i class="icon-rapport me-1"></i>
																<span class="text-regular">
																	<u>Voir tous les commentaires</u>
																</span>
															</button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<!-- 1ere item -->
										<div class="table-accordeon bg-white">
											<table class="table table-hover align-middle mb-0">
												<tbody>
													<!-- 1er élément -->
													<tr>
														<td
															colspan="6"
															class="position-relative table-cell-padding-none">
															<div class="rounded py-1">
																<table class="table mb-0 align-middle align-middle">
																	<tbody>
																		<tr class="" role="alert">
																			<td class="w-10 ps-4 pe-2">22/08/21</td>
																			<td class="w-25 position-relative">
																				Raphaël Le Mirabeau
																			</td>
																			<td class="w-65 d-none d-sm-block">
																				Lorem ipsum sit dolir amet
																				transetecturer amur
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<!-- 2e item -->
										<div class="table-accordeon bg-odd rounded">
											<table class="table table-hover align-middle mb-0">
												<tbody>
													<!-- 1er élément -->
													<tr>
														<td
															colspan="6"
															class="position-relative table-cell-padding-none">
															<div class="rounded py-1">
																<table class="table mb-0 align-middle align-middle">
																	<tbody>
																		<tr class="" role="alert">
																			<td class="w-10 ps-4 pe-2">22/08/21</td>
																			<td class="w-25 position-relative">
																				Suzanne De Chabot
																			</td>
																			<td class="w-90 pe-5 d-none d-sm-block">
																				Lorem ipsum sit dolir amet
																				transetecturer amur dolor sit credula
																				postero carpe diem...
																			</td>
																		</tr>
																	</tbody>
																</table>
																<input
																	type="checkbox"
																	class="accordeon-1 d-block"
																	name="accordeon-tab2"
																	id="accordeon-tab2" />
																<label
																	for="accordeon-tab2"
																	class="d-block btn pin-circle"
																	><i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i
																></label>
																<div class="accordeon mt-15">
																	<table class="table align-middle mb-0">
																		<tbody>
																			<!-- 1er élément -->
																			<tr class="" role="">
																				<td class="w-10 ps-4 pe-2"></td>
																				<td class="w-25 position-relative"></td>
																				<td>
																					What is Lorem Ipsum? Lorem Ipsum is
																					simply dummy text of the printing
																					and typesetting industry. Lorem
																					Ipsum has been the industry's
																					standard dummy text ever since the
																					1500s, when an unknown printer took
																					a galley of type and scrambled it to
																					make a type specimen book. It has
																					survived not only five centuries,
																					but also the leap into electronic
																					typesetting, remaining essentially
																					unchanged. It was popularised in the
																					1960s with the release of Letraset
																					sheets containing Lorem Ipsum
																					passages, and more recently with
																					desktop publishing software like
																					Aldus PageMaker including versions
																					of Lorem Ipsum. Why do we use it? It
																					is a long established fact that a
																					reader will be distracted by the
																					readable content of a page when
																					looking at its layout. The point of
																					using Lorem Ipsum is that it has a
																					more-or-less normal distribution of
																					letters, as opposed to using
																					'Content here, content here', making
																					it look like readable English. Many
																					desktop publishing packages and web
																					page editors now use Lorem Ipsum as
																					their default model text, and a
																					search for 'lorem ipsum' will
																					uncover many web sites still in
																					their infancy. Various versions have
																					evolved over the years, sometimes by
																					accident, sometimes on purpose
																					(injected humour and the like).
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<!-- BLOC COMMENTAIRE SKELETON -->
							<!-- <div class="bg-white h-100 w100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column">
								<div class="table-responsive">
									<div class="d-flex justify-content-end mb-2">
										<div class="w-20 p-2 skeleton-box rounded-pill bg-odd "></div>
									</div>
									<div class="d-flex align-items-center justify-content-between bg-white rounded p-2 mb-2 skeleton-box">
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-odd p-2  me-3 align-self-center"></div>
											<div class="w-20 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="w-70 rounded-pill bg-odd p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="rounded-circle p-3 bg-odd me-2"></div>
										</div>
									</div>
									<div class="d-flex align-items-center bg-odd rounded p-2 mb-2 skeleton-box">
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-white p-2  me-3 align-self-center"></div>
											<div class="w-20 rounded-pill bg-white p-2 me-3 align-self-center"></div>
											<div class="w-70 rounded-pill bg-white p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="rounded-circle p-3 bg-white me-2"></div>
										</div>
									</div>
								</div>
							</div>	 -->
							<!-- END BLOC COMMENTAIRE SKELETON -->
						</div>
						<!-- FIN - Affichage TAbleau COMMENTAIRES desktop > 1200px -->
						<!-- Affichage TAbleau COMMENTAIRES mobile et tablet < 1200px -->
						<div class="col-12 d-block d-lg-none mt-4">
							<span class="fs-5 text-medium d-block mb-3 mb-sm-1 ps-3 ps-lg-0"
								>Les derniers &eacute;v&egrave;nements</span
							>
						</div>
						<div class="col-12 d-block d-lg-none">
							<div class="d-com d-com-note d-flex flex-column bg-white rounded shadow position-relative">
								<div class="py-2 px-3">
									<div class="d-flex align-items-center justify-content-between">
										<div class=" ">
											<span class="d-block text-regular">20 février 2021</span>
											<span class="d-block text-light">Intervention à domicile</span>
										</div>
										<div class="">
											<i class="icon-intervention-2 fs-4"></i>
										</div>
									</div>
								</div>
								<div class="py-2 px-3 border-top">
									<div class="d-flex">
										<div class="flex-fill">
											<span class="d-block">
												Et licet quocumque oculos flexeris feminas adfatim multas spectare
												cirratas, quibus, si nupsissent, per aetatem ter iam nixus poterat
												suppetere liberorum, ad usque taedium pedibus pavimenta tergentes
												iactari volucriter gyris, dum exprimunt innumera simulacra, quae finxere
												fabulae theatrales.
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								class="d-com d-com-note d-flex flex-column bg-white rounded shadow position-relative my-3">
								<div class="py-2 px-3">
									<div class="d-flex align-items-center justify-content-between">
										<div class=" ">
											<span class="d-block text-medium">Commentaire du 20 février 2021</span>
										</div>
									</div>
								</div>
								<div class="py-2 px-3 border-top">
									<div class="d-flex">
										<div class="flex-fill">
											<span class="d-block">
												Et licet quocumque oculos flexeris feminas adfatim multas spectare
												cirratas, quibus, si nupsissent, per aetatem ter iam nixus poterat
												suppetere liberorum, ad usque taedium pedibus pavimenta tergentes
												iactari volucriter gyris, dum exprimunt innumera simulacra, quae finxere
												fabulae theatrales.
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="bg-white rounded p-3 mb-sm-2 skeleton-box shadow w-100">
								<div class="d-flex mb-4">
									<div class="flex-fill">
										<div class="w-35 rounded-pill bg-odd p-2 mb-2"></div>
										<div class="w-55 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill text-end">
										<div class="skeleton-box rounded-circle p-3 bg-odd"></div>
									</div>
								</div>
								<div class="d-flex flex-column">
									<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
									<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
									<div class="w-100 rounded-pill bg-odd p-2"></div>
								</div>
							</div>
							<div class="bg-white rounded p-3 mb-sm-2 skeleton-box shadow w-100">
								<div class="d-flex mb-4">
									<div class="flex-fill">
										<div class="w-30 rounded-pill bg-odd p-2"></div>
									</div>
								</div>
								<div class="d-flex flex-column">
									<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
									<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
									<div class="w-100 rounded-pill bg-odd p-2"></div>
								</div>
							</div>
						</div>
						<!-- FIN - Affichage TAbleau COMMENTAIRES mobile < 1200px -->
					</div>
					<div class="row mb-1 mt-4">
						<div class="col-12 col-lg-3">
							<span class="fs-5 text-medium d-block mb-3 mb-sm-1 ps-3 ps-lg-0"> Données patient </span>
						</div>
						<div class="col-12 col-lg-9">
							<span class="d-none d-xl-block fs-5 text-medium d-block mb-3 mb-sm-1">
								Matériels utilisés
							</span>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-xl-3 mb-3 mb-lg-0 position-relative">
							<!-- COURBE DE POIDS  -->
							<div class="bg-white rounded px-3 pt-2 position-relative shadow h-100 overflow-hidden">
								<!-- <span class="fs-6 text-medium pt-2 d-block d-lg-none">Constantes</span>  -->

								<div class="d-flex flex-column align-items-stretch position-relative z-index-99">
									<div class="py-2">
										<p class="fs-7 mb-1 lh-1 text-regular d-none d-lg-block">Constantes</p>
										<div class="row g-0">
											<div class="col-6 col-sm-3 col-xl-5">
												<span class="flex-fill text-nowrap">
													Dernier poids :
													<span class="text-regular">89Kg</span>
												</span>
											</div>
											<div
												class="col-6 col-sm-3 col-md-7 col-xl-4 text-end text-sm-start text-xl-center">
												<span class="flex-fill text-nowrap ps-xl-2">
													Taille :
													<span class="text-regular">161cm</span>
												</span>
											</div>
											<div class="col-6 col-sm-3 text-sm-start">
												<span class="flex-fill text-nowrap">
													IMC :
													<span class="text-regular">41</span>
												</span>
											</div>
											<div class="col-6 col-sm-3 col-md-7 text-end text-sm-start">
												<span class="flex-fill">
													IAH initial :
													<span class="text-regular">9,44</span>
												</span>
											</div>
										</div>
									</div>
									<div class="flex-grow-1 px-2 pt-0 pb-2 text-center no-data">
										<img
											alt="fake image"
											id="smoothedLineChartDiv"
											src="https://fakeimg.pl/350x90/"
											class="img-fluid" />
									</div>
								</div>
								<!-- 				------------------------------
									SI INFORMATIONS MANQUANTES ALORS ON AFFICHE CI-DESSOUS LE VISUEL 
													------------------------------
								-->
								<div
									class="position-absolute start-0 bottom-0 z-index-100 text-center alert-data w-100">
									<img
										src="../../../assets/img/no-data.png"
										class="w-70 img-fluid"
										alt="données indisponible" />
									<div class="border-bottom bg-color-primary p-2 w-100">
										<span class="text-white fs-12p text-regular"> Il manque des données ! </span>
									</div>
								</div>
							</div>
							<!-- BLOC SKELETON -->
							<!-- <div
								class="bg-white h-100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 w100"
							>
								<div class="d-flex h-100 justify-content-between">
									<div class="flex-fill align-self-stretch d-flex flex-column">
										<div class="w-20 rounded-pill bg-odd p-2 mb-2"></div>
										<div class="w-100 rounded-pill bg-odd p-2 mb-4"></div>
										<div class="w-100 h-100 rounded bg-odd-color-primary p-2"></div>
									</div>
								</div>
							</div>   -->
							<!-- END SKELETON -->
						</div>
						<div class="col-12 d-xl-none">
							<span class="fs-5 text-medium d-block mb-2 ps-3 ps-lg-0 pt-lg-3"> Matériels utilisés </span>
							<div class="d-flex justify-content-between pb-2 d-xl-none">
								<div class="align-self-center">
									<span class="text-light fs-6 ps-3 ps-lg-0">
										Retrouver tous les matériels et consommables
									</span>
								</div>
								<div class="align-self-center">
									<button class="btn pin-circle me-2 order-3">
										<i
											aria-hidden="true"
											class="icon-materiels position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</div>
						</div>
						<div class="col-12 col-xl-9 position-relative mh-255">
							<div
								class="bg-white rounded px-3 pt-2 shadow position-relative h-100 bloc-equipements no-icon">
								<!-- 	######## CLASS SPECIFICQUE #######
											------------------------
									CLASSE SPECIFIQUE 'no-icon' POUR AFFICHER LE PICTO SI ET SEULEMENT SI TOUTES LES IMAGES D'EQUIPEMENT ou DE CONSOMMABLE SONT AFFICHES 
								-->
								<i
									class="icon icon-equipements fs-equipement position-absolute start-50 opacity-80 d-none d-sm-block"></i>

								<!-- Début affichage bloc matériels  -->
								<div class="position-relative">
									<div class="d-flex flex-column">
										<div class="pt-2 row">
											<div class="col-6 title mb-0 fs-6 text-medium">
												<span class="d-none d-sm-block"> Équipement </span>
											</div>
											<div class="col-6 mb-0 fs-6 text-medium">
												<span class="d-none d-sm-block ms-4 ps-3"> Consommables </span>
											</div>

											<div class="d-none d-xl-block position-absolute text-end end-0">
												<button class="btn p-0 fs-7">
													<i class="icon-materiels me-1"></i>

													<span class="text-regular"><u>Voir tous les matériels</u></span>
												</button>
											</div>
										</div>
										<!-- <div>
											Date de première installation :
											<span class="text-regular me-1">18/03/2016</span>
											<span class="fs-12p lh-1 text-extra-light"
												>(4 ans, 8 mois et 3 semaines)</span
											>
										</div> -->
									</div>

									<div class="d-flex flex-column flex-xl-row justify-content-between mt-2 pb-2">
										<div class="align-self-stretch flex-fill">
											<div class="d-flex flex-column flex-sm-row">
												<!-- #################          SMARTPHONE & TABLET affichage EQUIPEMENTS en 1 colonne < 992px -->
												<!-- Début du bloc carousel -->
												<div
													class="flex-fill carousel-equip pointer-event slide indicator"
													id="myCarouselEquip">
													<!-- bouttons navigations -->
													<div class="carousel-equip-indicators d-sm-none">
														<button
															type="button"
															data-bs-target="#myCarouselEquip"
															data-bs-slide-to="0"
															class="position-absolute prev active btn pin-circle pin-circle-xl"
															aria-label="Prev Slide"
															aria-current="true">
															<i
																class="icon-pagination-left position-absolute top-50 translate-middle"></i>
														</button>
														<button
															type="button"
															data-bs-target="#myCarouselEquip"
															data-bs-slide-to="1"
															aria-label="Next Slide"
															class="next position-absolute btn pin-circle pin-circle-xl">
															<i
																class="icon-pagination-right position-absolute top-50 translate-middle"></i>
														</button>
													</div>
													<!-- Affichage contenu   -->
													<div
														class="carousel-inner d-flex flex-column flex-sm-row justify-content-between">
														<!-- 1er élément (actif par défaut) -->
														<div
															class="flex-fill align-self-stretch carousel-equip-item active pt-2 pt-sm-0 fadeInRight">
															<p class="mb-2 text-center text-sm-start">
																Resmed
																<span class="text-regular"> AIRSENSE 10 AUTOSET </span>
															</p>
															<div class="d-flex h-items justify-content-between">
																<div class="pe-2 my-2 align-self-end">
																	<img
																		src="../../../assets/img/devices/RESMED_AIRSENSE10.jpg"
																		alt="no-data"
																		class="wpx-220" />
																</div>
																<div class="pe-lg-5 align-self-end w-100">
																	<p
																		class="text-extra-light fs-12p mb-0 text-center text-sm-start">
																		Valeur en cm/H<sub>2</sub>O
																	</p>
																	<div
																		class="d-flex justify-content-center justify-content-sm-start">
																		<div class="me-1">
																			<span class="me-1">Pression Min:</span>
																			<span class="text-medium valeur">6</span>
																		</div>
																		<div
																			class="fs-3 text-ultra-light letter-spacing">
																			--
																		</div>
																		<!-- Il est possible d'utiliser le séparateur comme ci-dessous en commentaire soit avec des caractères comme ' | ' ou ' _ ' ou ' & ' ou : etc -->
																		<!-- <div class="fs-3">|</div>
																		<div class="fs-3">|</div> -->
																		<div class="ms-1">
																			<span class="me-1"> Max: </span>
																			<span class="text-medium valeur">10</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<!-- 2e élément -->
														<div
															class="flex-fill align-self-stretch carousel-equip-item pt-2 pt-sm-0 fadeInRight">
															<p class="mb-2 text-center text-sm-start">
																Masque :
																<span class="text-regular text-uppercase">
																	Airfit 20L
																</span>
															</p>
															<div class="d-flex h-items justify-content-between">
																<div class="pe-2 my-2 align-self-end">
																	<img
																		src="../../../assets/img/oxygen.png"
																		alt="no-data"
																		class="mh-125" />
																</div>
																<div class="pe-lg-5 align-self-end">
																	<p
																		class="text-extra-light fs-12p mb-0 text-center text-sm-start">
																		Mode :
																	</p>
																	<span class="d-block d-lg-inline-block me-2">
																		Auto-Ventilation par pilotage à distance
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<!-- Ce BLOC ci-dessous est affiché uniquement en version TABLET -->
										<!-- <div class="align-self-stretch pt-3 d-none d-sm-block d-lg-none">
											<span class="text-regular py-2 py-sm-0">Consommables</span>
											<div class="d-flex flex-column flex-sm-row bloc-lg-consommables">
												<div class="flex-fill">
													<div
														class="d-flex flex-row flex-sm-column justify-content-between h-100"
													>
														<div class="pe-2 my-2 align-self-center align-self-sm-start">
															<img
																src="../../../assets/img/mask_default.png"
																alt="no-data"
															/>
														</div>
														<div class="pe-lg-5 align-self-end align-self-sm-start">
															<span class="d-block d-lg-inline-block me-2"
																>Masque :
																<span class="text-medium valeur">Masque Airfit</span>
															</span>
														</div>
													</div>
												</div>
												<div class="flex-fill pt-2 pt-sm-0">
													<div
														class="d-flex flex-row flex-sm-column justify-content-between h-100"
													>
														<div class="pe-2 my-2 align-self-center align-self-sm-start">
															<img src="../../../assets/img/oxygen.png" alt="no-data" />
														</div>
														<div class="align-self-end align-self-sm-start">
															<span class="d-block d-lg-inline-block me-2">
																Masque :
																<span class="text-medium valeur">Dreamweaver</span>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div> -->
									</div>
								</div>
							</div>
							<!--  BLOC PROBLEME TECHNIQUE -->
							<!-- <div class="card-no-data-3 bg-white rounded h-100 d-flex flex-column justify-content-center">
								<i class="icon-problem text-red position-absolute translate-middle top-50"></i>
								<div class="text-center ">
									
									<p class="text-medium text-red">Erreur technique ! </p>
									<p>
										Nos experts font le maximum pour vous apporter une solution rapidement.
									</p>
								</div>
							</div>    -->
							<!--  FIN PROBLEME TECHNIQUE-->
							<!-- BLOC SKELETON >  568px -->
							<!-- <div class="d-none d-sm-block">
								<div
									class="bg-white h-100 w100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column"
								>
									<div class="d-flex justify-content-between mb-3">
										<div class="flex-fill d-flex flex-column">
											<div class="w-15 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-80 rounded bg-odd p-2"></div>
										</div>
										<div class="flex-fill d-flex flex-column justify-content-start">
											<div class="w-30 align-self-end rounded-pill bg-odd p-2"></div>
										</div>
									</div>
									<div
										class="flex-fill align-self-stretch d-flex flex-column flex-sm-row justify-content-between"
									>
										<div class="flex-fill w-30 align-self-stretch d-flex flex-column me-sm-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 h-100 rounded bg-odd p-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mt-3"></div>
										</div>
										<div class="flex-fill w-30 align-self-stretch d-flex flex-column me-sm-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 h-100 rounded bg-odd p-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mt-3"></div>
										</div>
										<div class="flex-fill w-15 align-self-stretch d-flex flex-column me-sm-4">
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 h-100 rounded bg-odd p-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mt-3"></div>
										</div>
										<div class="flex-fill w-15 align-self-stretch d-flex flex-column">
											<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											<div class="w-100 h-100 rounded bg-odd p-2"></div>
											<div class="w-100 rounded-pill bg-odd p-2 mt-3"></div>
										</div>
									</div>
								</div>
							</div> -->
							<!-- END SKELETON -->
							<!-- BLOC SKELETON <  568px -->
							<!-- <div
								class="bg-white h-100 w100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-block d-sm-none"
							>
								<div class="d-flex flex-column flex-sm-row justify-content-between h-100">
									<div class="flex-fill w-100 align-self-stretch d-flex flex-column">
										<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
										<div class="w-50 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
										<div class="w-40 align-self-center h-100 rounded bg-odd p-2"></div>
										<div class="w-50 align-self-center rounded-pill bg-odd p-2 mt-3"></div>
										<div class="w-30 align-self-center rounded-pill bg-odd p-2 mt-1"></div>
									</div>
								</div>
							</div> -->
							<!-- END SKELETON -->
						</div>
					</div>

					<!-- Affichage bloc SYNTHESE et matériel utilisé "Syn-mat" sur 2 colonnes -->

					<span class="fs-5 text-medium d-block mb-2 mt-3">Historique</span>
					<!-- Affichage données observance version smartphone et tablet < 992px -->
					<div class="d-block mb-3 d-lg-none">
						<div class="d-flex">
							<div class="text-start align-self-center">
								<span class="text-light">Retrouver les données d'observance du patient</span>
							</div>
							<!-- <div class="flex-fill text-end d-block d-sm-none mb-2">
								<button class="btn pin-circle pin-circle-xl shadow">
									<i class="icon-modify position-absolute top-50 start-50 translate-middle"></i>
								</button>
							</div> -->
						</div>
					</div>

					<!-- Affichage bloc HISTORIQUE -->
					<div class="row">
						<div class="col-12 position-relative mb-4 mh-470">
							<div class="bg-white rounded p-3 mb-sm-2">
								<!-- Affichage boutons actions version desktop > 992px -->
								<div class="d-none d-lg-block">
									<div class="d-flex">
										<div class="flex-grow-1 align-self-center">
											<!-- Affichage période version desktop > 992px -->
											<div class="d-none d-lg-block periode">
												<div class="d-flex flex-column text-ultra-light position-relative">
													<div class="input-group position-relative mb-2">
														<input
															class="custom-radio"
															id="troismois"
															name="radio"
															type="radio" />
														<label for="troismois" class="custom-radio">
															3 derniers mois</label
														>
													</div>
													<div class="input-group position-relative mb-2">
														<input
															class="custom-radio"
															id="sixmois"
															name="radio"
															type="radio" />
														<label for="sixmois" class="custom-radio"
															>6 derniers mois</label
														>
													</div>
													<div class="input-group position-relative">
														<input
															class="custom-radio"
															id="douzemois"
															name="radio"
															type="radio" />
														<label for="douzemois" class="custom-radio"
															>12 derniers mois</label
														>
													</div>
													<div
														class="position-absolute bloc-synthese w-78 border color-primary rounded-1 bg-white me-3">
														<div class="position-relative pt-3">
															<div
																class="position-absolute start-0 top-0 bg-white px-1 mx-3 top-minus-10">
																<span class="text-regular">
																	Synthèse sur la période choisie
																</span>
															</div>
															<table class="table mb-0">
																<thead>
																	<tr class="align-top text-center">
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>Utilisation <br />Moyenne</span
																			>
																		</th>
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>IAH Moyen</span
																			>
																		</th>
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>Taux de fuite <br />médiant</span
																			>
																		</th>
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>Fuite P95</span
																			>
																		</th>
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>Pression <br />médiane</span
																			>
																		</th>
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>Pression P95</span
																			>
																		</th>
																		<th scope="col">
																			<span class="text-regular lh-1"
																				>Pression<br />
																				Maximale</span
																			>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="align-bottom text-center">
																		<td>
																			<span class="text-medium me-2">4h</span>
																			<span class="text-ultra-light">22min</span>
																		</td>
																		<td>
																			<span class="text-medium me-2">5,8</span>
																		</td>
																		<td>
																			<span class="text-medium me-2">0,23</span>
																			<span class="text-ultra-light">l/s</span>
																		</td>
																		<td>
																			<span class="text-medium me-2">5,51</span>
																			<span class="text-ultra-light">l/min</span>
																		</td>
																		<td>
																			<span class="text-medium me-2">12,24</span>
																			<span class="text-ultra-light"
																				>cm/H<sub>2</sub>O</span
																			>
																		</td>
																		<td>
																			<span class="text-medium me-2">7,62</span>
																			<span class="text-ultra-light"
																				>cm/H<sub>2</sub>O</span
																			>
																		</td>
																		<td>
																			<span class="text-medium me-2">11,53</span>
																			<span class="text-ultra-light"
																				>cm/H<sub>2</sub>O</span
																			>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="text-end align-self-center">
											<ul class="list-unstyled d-inline-block position-relative">
												<li class="d-inline-block mx-2">
													<input
														type="checkbox"
														name="option-popin"
														id="option-popin"
														class="option-popin d-block" />
													<label for="option-popin" class="btn btn-options pin-circle">
														<i
															class="icon-admin position-absolute top-50 start-50 translate-middle"></i>
													</label>
													<div
														class="option-popin-content text-start w-30 shadow bg-white rounded">
														<div class="p-3">
															<span class="fs-5 text-medium w-100">
																Options d'affichage
															</span>
															<span class="lh-1">
																Choisissez des données supplémentaires à afficher sur
																l'historique d'obersvance de votre patient
															</span>
															<div class="mt-2">
																<span class="text-regular">Type</span>
																<div class="row g-0">
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="obs"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="obs"
																				class="custom-radio position-relative"
																				>Baisses d'observance</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group mb-2">
																			<input
																				class="custom-radio"
																				id="iah"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="iah"
																				class="custom-radio position-relative"
																				>Alertes IAH</label
																			>
																		</div>
																	</div>
																	<div class="col-12">
																		<div class="input-group mb-2">
																			<input
																				class="custom-radio"
																				id="tech"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="tech"
																				class="custom-radio position-relative"
																				>Intervention du technicien
																				Elivie</label
																			>
																		</div>
																	</div>
																</div>
																<span class="text-regular"
																	>Courbes supplémentaires</span
																>
																<div class="row g-0">
																	<div class="col-6">Pressions</div>
																	<div class="col-6">Indices</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative"
																				>Pression médiane</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="d-flex flex-row">
																			<div>
																				<input
																					class="custom-radio"
																					id="iah"
																					name="checkbox"
																					type="checkbox" />
																			</div>
																			<div>
																				<label
																					for="iah"
																					class="custom-radio position-relative">
																					IAH (Indice
																					d'Apnée-Hypopnées)</label
																				>
																			</div>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pm"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pm"
																				class="custom-radio position-relative"
																				>Pression moyenne</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="apne"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="apne"
																				class="custom-radio position-relative"
																				>IA ( Indice d'Apnée)</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pres"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pres"
																				class="custom-radio position-relative"
																				>Pression P90</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="hyp"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="hyp"
																				class="custom-radio position-relative"
																				>IH (Indice d'Hypopnée)</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pression"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pression"
																				class="custom-radio position-relative"
																				>Pression P95</label
																			>
																		</div>
																	</div>
																	<div class="col-6">Fuites</div>
																	<div class="col-6">Volumes courants</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="fui"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="fui"
																				class="custom-radio position-relative"
																				>Fuite médiane</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="vol"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="vol"
																				class="custom-radio position-relative">
																				Volume courant médian
																			</label>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="fuite"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="fuite"
																				class="custom-radio position-relative"
																				>Fuite P95</label
																			>
																		</div>
																	</div>
																	<div class="col-6">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="volu"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="volu"
																				class="custom-radio position-relative"
																				>Volumne courant P95</label
																			>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="row g-0 btn-filters">
															<button
																class="col-6 btn text-uppercase btn-default text-medium">
																effacer
															</button>
															<button
																class="col-6 btn text-uppercase text-white text-medium">
																filter
															</button>
														</div>
													</div>

													<div
														class="option-popin rounded-pill border color-primary border-2 pt-1 pe-1 ps-3 d-flex text-color-primary justify-content-between bulle-options bg-white">
														<span class="me-3">2 options</span>
														<button class="btn p-0 lh-1">
															<i class="icon-closed fs-5-closed"></i>
														</button>
													</div>
												</li>
												<li class="d-inline-block mx-2">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
												</li>
												<li class="d-inline-block mx-2">
													<button class="btn pin-circle">
														<i
															class="icon-xtend position-absolute top-50 start-50 translate-middle"></i>
													</button>
												</li>
											</ul>
											<div class="text-end ms-2 mt-2">
												<div class="d-flex">
													<div class="align-self-center form-switch-view">
														<div class="d-flex position-relative">
															<input
																(click)="openCloseGraph()"
																class="order-2 form-check-input"
																type="checkbox"
																role="switch"
																id="flexSwitchCheckView" />
															<div class="mode-graph me-1 order-1">
																<i class="icon-table-2"></i>
																<label
																	class="form-check-label mx-1"
																	for="flexSwitchCheckView"
																	>Version Graphique</label
																>
															</div>
															<div class="mode-detailed me-1 order-3">
																<i class="icon-table"></i>
																<label
																	class="form-check-label mx-1"
																	for="flexSwitchCheckView"
																	>Version détaillée</label
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="d-flex flex-column flex-sm-row">
									<div class="">
										<span class="text-medium d-block mb-1 mb-sm-0">Affichage</span>
										<span class="text-regular mb-3 mb-sm-0">Période</span>
									</div>
									<div class="flex-fill align-self-sm-center px-sm-5">
										<!-- Affichage période version smartphone et tablet < 992px -->
										<nav
											class="d-block d-lg-none d-flex justify-content-between form-date-increment">
											<div class="text-light order-2 flex-grow-1">
												<div class="d-flex align-items-center">
													<div class="strat flex-fill"></div>
													<div class="px-2">3 derniers mois</div>
													<div class="strat flex-fill"></div>
												</div>
											</div>
											<button class="btn pin-circle inactive order-1" aria-label="Previous">
												<i
													aria-hidden="true"
													class="icon-icon-moins position-absolute top-50 start-50 translate-middle fs-6"></i>
											</button>
											<button class="btn pin-circle shadow me-2 order-3" aria-label="next">
												<i
													aria-hidden="true"
													class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</nav>
									</div>
									<!-- Affichage Bouton "Ajouter une note" version Tablet < 992px -->
									<div class="d-none d-sm-block d-lg-none text-end align-self-center">
										<button class="btn pin-circle me-2 order-3">
											<i
												aria-hidden="true"
												class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
								</div>
								<!-- #########   Affichage observances vue détaillée ou graphique desktop > 992px -->
								<div class="d-flex mt-4 d-none d-lg-block" [class.active]="!isMenuOpen">
									<div class="flex-fill align-self-center align-content-center">
										<!-- #########   début tableau HISTORIQUE version détaillée desktop > 992px -->
										<div class="tab-historique item-switch fs-wide-screen">
											<table
												class="table table-striped table-hover align-middle mb-0 text-center">
												<thead>
													<th class="align-top">Date</th>
													<th class="align-top">Durée</th>
													<th class="align-top">
														Indice
														<div class="d-flex p-1 justify-content-center fs-12p lh-1">
															<span class="text-light flex-fill">IAH</span>
															<span class="text-light flex-fill">IAO</span>
															<span class="text-light flex-fill">IAC</span>
															<span class="text-light flex-fill">IH</span>
															<span class="text-light flex-fill">IA</span>
														</div>
													</th>
													<th>
														Fuites
														<div class="d-flex p-1 justify-content-center fs-12p lh-1">
															<span class="text-light flex-fill"
																>Taux de fuite médian</span
															>
															<span class="text-light flex-fill">P95</span>
														</div>
													</th>
													<th>
														Pression
														<div class="d-flex p-1 justify-content-center fs-12p lh-1">
															<span class="text-light flex-fill">Médiane</span>
															<span class="text-light flex-fill">P95</span>
															<span class="text-light flex-fill">Max</span>
														</div>
													</th>
												</thead>
												<tbody>
													<!-- 1er élément -->
													<tr class="" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar w-75 text-light"
																	role="progressbar"
																	aria-valuenow="75"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	4h42min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<!-- 2e élément -->
													<tr class="" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar w-75 text-light"
																	role="progressbar"
																	aria-valuenow="75"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	4h42min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<!-- 3er élément -->
													<tr class="highlight orange before-orange" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar orange w-75 text-light"
																	role="progressbar"
																	aria-valuenow="65"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	3h12min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="highlight orange" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar orange w-75 text-light"
																	role="progressbar"
																	aria-valuenow="65"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	3h12min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="highlight orange" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress notif">
																<div
																	class="progress-bar orange text-orange w-25 text-light"
																	role="progressbar"
																	aria-valuenow="25"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	<span
																		class="position-absolute top-50 start-50 translate-middle"
																		>2h18min</span
																	>
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="highlight red" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress notif">
																<div
																	class="progress-bar red text-red w-15 text-light"
																	role="progressbar"
																	aria-valuenow="15"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	<span
																		class="position-absolute top-50 start-50 translate-middle"
																		>1h22min</span
																	>
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="highlight orange" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar orange w-66 text-light"
																	role="progressbar"
																	aria-valuenow="66"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	2h34min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="highlight orange" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar orange w-54 text-light"
																	role="progressbar"
																	aria-valuenow="54"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	2h12min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="highlight orange after-orange" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar orange w-44 text-light"
																	role="progressbar"
																	aria-valuenow="44"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	1h52min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar w-65 text-light"
																	role="progressbar"
																	aria-valuenow="65"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	4h42min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
													<tr class="" role="">
														<td>22/08/21</td>
														<td class="border-left position-relative">
															<div class="progress">
																<div
																	class="progress-bar w-75 text-light"
																	role="progressbar"
																	aria-valuenow="75"
																	aria-valuemin="0"
																	aria-valuemax="100">
																	4h42min
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">1,14</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,12
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,36
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,51
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,62
																</div>
															</div>
														</td>
														<td class="event text-wrap position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">0,50 l/s</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	0,61 l/s
																</div>
															</div>
														</td>
														<td class="position-relative border-left">
															<div class="d-flex justify-content-center">
																<div class="flex-fill">9,14 cm/H<sub>2</sub>O</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	4,61 cm/H<sub>2</sub>O
																</div>
																<div
																	class="flex-fill position-relative border-tiny-left">
																	19,61 cm/H<sub>2</sub>O
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
											<nav
												class="d-flex d-pagination justify-content-center align-items-center my-3 position-relative"
												aria-label="Page navigation">
												<button class="btn pin-circle me-2" aria-label="Previous">
													<i
														aria-hidden="true"
														class="icon-pagination-left position-absolute top-50 start-50 translate-middle"></i>
												</button>
												<ul class="pagination list-unstyled align-items-center">
													<li class="page-item"><a class="page-link" href="#">1</a></li>
													<li class="page-item"><a class="page-link" href="#">2</a></li>
													<li class="page-item"><a class="page-link" href="#">3</a></li>
													<li class="page-item"><a class="page-link" href="#">...</a></li>
													<li class="page-item"><a class="page-link" href="#">10</a></li>
													<li class="page-item"><a class="page-link" href="#">...</a></li>
													<li class="page-item"><a class="page-link" href="#">18</a></li>
													<li class="page-item"><a class="page-link" href="#">19</a></li>
													<li class="page-item"><a class="page-link" href="#">20</a></li>
												</ul>
												<button class="btn pin-circle ms-2" aria-label="Next">
													<i
														aria-hidden="true"
														class="icon-pagination-right position-absolute top-50 start-50 translate-middle"></i>
												</button>
												<div class="position-absolute select">
													<select
														class="form-select2 w-100"
														aria-label="Default select example">
														<option selected>Journée d'observance par page</option>
														<option value="1">One</option>
														<option value="2">Two</option>
														<option value="3">Three</option>
													</select>
													<span class="nb-items position-absolute fs-12p">12</span>
												</div>
											</nav>
										</div>
										<!-- #########   début version GRAPHIQUE desktop > 992px -->
										<div class="tab-graph item-switch">
											<div class="position-relative" style="padding-top: 100px">
												<div
													class="d-flex position-absolute flex-column w-100"
													style="height: 150px">
													<span class="flex-fill">moins de 8h</span>
													<div class="line m-0"></div>
													<span class="flex-fill">moins de 6h</span>
													<div class="line m-0"></div>
													<span class="flex-fill">moins de 4h</span>
													<div class="line m-0"></div>
													<span class="flex-fill">moins de 2h</span>
													<div class="line m-0"></div>
													<span class="flex-fill">non transmis</span>
													<div class="line m-0"></div>
												</div>
												<div
													class="d-flex flex-wrap align-items-end justify-content-between"
													style="
														height: 150px;
														margin: 0px auto;
														max-width: 90%;
														overflow: hidden;
													">
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-6 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-4 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-2 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-22 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-11 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-82 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-8 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-12 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-13 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-22 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-7 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-2 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-11 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-22 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-7 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-12 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-88 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
													<span class="bg-green rounded wpx-9 h-81 mx-1"></span>
													<span class="bg-red rounded wpx-9 h-12 mx-1"></span>
													<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
												</div>
											</div>

											<nav class="d-flex justify-content-between nav-graphique-date">
												<button class="btn pin-circle me-2" aria-label="Previous">
													<i
														aria-hidden="true"
														class="icon-pagination-left position-absolute top-50 start-50 translate-middle"></i>
												</button>
												<div class="flex-grow-1">
													<ol
														class="list-unstyled d-flex justify-content-between align-items-center text-center">
														<li class="flex-fill text-start">
															<span class="text-extra-light">Janvier</span> <br />
															<span class="text-regular date">2021</span>
														</li>
														<li class="flex-fill">
															<span class="text-extra-light">Février</span> <br />
															<span class="text-regular date">2021</span>
														</li>

														<li class="flex-fill text-end">
															<span class="text-extra-light">Mars</span> <br />
															<span class="text-regular date">2021</span>
														</li>
													</ol>
												</div>
												<button class="btn pin-circle ms-2" aria-label="Next">
													<i
														aria-hidden="true"
														class="icon-pagination-right position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</nav>
										</div>
									</div>
								</div>
							</div>
							<!-- #########   début tableau HISTORIQUE version SMARTPHONE ET TABLET < 992px -->

							<div class="tab-historique d-block d-lg-none">
								<!-- 1er item -->
								<p class="fs-6 text-medium d-none d-sm-block">Avril 2021</p>
								<div
									class="pe-auto cs-pointer d-flex flex-column flex-sm-row bg-white rounded shadow position-relative my-3">
									<div class="px-3 py-2">
										<div class="d-flex flex-column h-100 justify-content-between">
											<span
												class="d-block text-medium align-content-center mb-2 d-none d-sm-block">
												Utilisation moyenne
											</span>
											<span
												class="d-block text-medium align-content-center mb-2 d-block d-sm-none">
												Avril 2021
											</span>

											<div class="progress usage position-relative mb-sm-2">
												<i class="icon-utilisation-moyenne position-absolute text-green"></i>
												<div
													class="progress-bar w-81 text-light"
													role="progressbar"
													aria-valuenow="81"
													aria-valuemin="0"
													aria-valuemax="100">
													6h12min
												</div>
											</div>
										</div>
									</div>
									<div class="px-3 py-2 flex-grow-1">
										<span class="d-block text-medium align-content-center">Indices moyens</span>
										<div class="d-flex flex-column flex-sm-row">
											<div class="me-1"><span class="text-light me-1">IAH : </span>1,14</div>
											<div class="position-relative border-tiny-left">
												<span class="text-light mx-sm-1 me-1">Taux de fuite médian : </span>0,50
												l/s
											</div>
										</div>
										<div class="">
											<span class="text-light me-1">Pression médiane :</span>4,19
											cm/H<sub>2</sub>O
										</div>
									</div>
									<button class="icon-bg-button px-2 position-relative">
										<span class="text-white">+ de détails</span>
										<i class="icon-table-2 position-absolute"></i>
									</button>
								</div>
								<!-- 2nd item -->
								<p class="fs-6 text-medium d-none d-sm-block">Mars 2021</p>
								<div
									class="pe-auto cs-pointer d-flex flex-column flex-sm-row bg-white rounded shadow position-relative my-3">
									<div class="px-3 py-2">
										<div class="d-flex flex-column h-100 justify-content-between">
											<span
												class="d-block text-medium align-content-center mb-2 d-none d-sm-block">
												Utilisation moyenne
											</span>
											<span
												class="d-block text-medium align-content-center mb-2 d-block d-sm-none">
												Mars 2021
											</span>

											<div class="progress usage position-relative mb-sm-2">
												<i class="icon-utilisation-moyenne position-absolute text-orange"></i>
												<div
													class="progress-bar orange w-61 text-light"
													role="progressbar"
													aria-valuenow="61"
													aria-valuemin="0"
													aria-valuemax="100">
													4h42min
												</div>
											</div>
										</div>
									</div>
									<div class="px-3 py-2 flex-grow-1">
										<span class="d-block text-medium align-content-center">Indices moyens</span>
										<div class="d-flex flex-column flex-sm-row">
											<div class="me-1"><span class="text-light me-1">IAH : </span>1,14</div>
											<div class="position-relative border-tiny-left">
												<span class="text-light mx-sm-1 me-1">Taux de fuite médian : </span>0,50
												l/s
											</div>
										</div>
										<div class="">
											<span class="text-light me-1">Pression médiane :</span>4,19
											cm/H<sub>2</sub>O
										</div>
									</div>
									<button class="icon-bg-button px-2 position-relative">
										<span class="text-white">+ de détails</span>
										<i class="icon-table-2 position-absolute"></i>
									</button>
								</div>
								<!-- 3rd item -->
								<p class="fs-6 text-medium d-none d-sm-block">Février 2021</p>
								<div
									class="pe-auto cs-pointer d-flex flex-column flex-sm-row bg-white rounded shadow position-relative my-3">
									<div class="px-3 py-2">
										<div class="d-flex flex-column h-100 justify-content-between">
											<span
												class="d-block text-medium align-content-center mb-2 d-none d-sm-block">
												Utilisation moyenne
											</span>
											<span
												class="d-block text-medium align-content-center mb-2 d-block d-sm-none">
												Février 2021
											</span>
											<div class="progress notif usage position-relative mb-sm-2">
												<i class="icon-utilisation-moyenne position-absolute text-red"></i>
												<div
													class="progress-bar red w-22 text-light text-red"
													role="progressbar"
													aria-valuenow="22"
													aria-valuemin="0"
													aria-valuemax="100">
													<span class="position-absolute top-50 start-50 translate-middle"
														>42min</span
													>
												</div>
											</div>
										</div>
									</div>
									<div class="px-3 py-2 flex-grow-1">
										<span class="d-block text-medium align-content-center">Indices moyens</span>
										<div class="d-flex justify-content-between">
											<span class="text-medium text-red me-1">Alerte IAH : 9,86</span>
											<i class="icon-notif text-red me-2 fs-6"></i>
										</div>
										<div class="">
											<span class="text-light me-1">Taux de fuite médian : </span>0,50 l/s
										</div>
										<div class="">
											<span class="text-light me-1">Pression médiane :</span>4,19
											cm/H<sub>2</sub>O
										</div>
									</div>
									<button class="icon-bg-button px-2 position-relative">
										<span class="text-white">+ de détails</span>
										<i class="icon-table-2 position-absolute"></i>
									</button>
								</div>
							</div>

							<!-- DEBUT SKELETON HISTORIQUE -->
							<!-- <div class="bg-white rounded p-3 mb-sm-2 skeleton-box d-block d-lg-none mb-3">
								<div class="d-flex flex-column h-100 justify-content-between mb-3">
									<div class="w-30 rounded-pill bg-odd p-2 mb-2"></div>
									<div class="progress usage position-relative mb-sm-2">
										<i class="icon-utilisation-moyenne position-absolute opacity-30"></i>
										<div
											role="progressbar"
											aria-valuenow="81"
											aria-valuemin="0"
											aria-valuemax="100"
											class="progress-bar w-81 text-light bg-odd-darker"
										>
											XhXXmin
										</div>
									</div>
								</div>
								<div class="d-flex flex-column w-100">
									<div class="w-30 rounded-pill bg-odd p-2 mb-1"></div>
									<div class="w-40 rounded-pill bg-odd p-2 mb-1"></div>
									<div class="w-55 rounded-pill bg-odd p-2 mb-1"></div>
									<div class="w-65 rounded-pill bg-odd p-2 mb-1"></div>
								</div>
							</div>
							<div class="bg-white rounded p-3 mb-sm-2 skeleton-box d-block d-lg-none mb-3">
								<div class="d-flex flex-column h-100 justify-content-between mb-3">
									<div class="w-30 rounded-pill bg-odd p-2 mb-2"></div>
									<div class="progress usage position-relative mb-sm-2">
										<i class="icon-utilisation-moyenne position-absolute opacity-30"></i>
										<div
											role="progressbar"
											aria-valuenow="41"
											aria-valuemin="0"
											aria-valuemax="100"
											class="progress-bar w-41 text-light bg-odd-darker"
										>
											XhXXmin
										</div>
									</div>
								</div>
								<div class="d-flex flex-column w-100">
									<div class="w-30 rounded-pill bg-odd p-2 mb-1"></div>
									<div class="w-40 rounded-pill bg-odd p-2 mb-1"></div>
									<div class="w-55 rounded-pill bg-odd p-2 mb-1"></div>
									<div class="w-65 rounded-pill bg-odd p-2 mb-1"></div>
								</div>
							</div> -->
							<!-- FIN SKELETON HISTORIQUE -->
							<!--  DONNEES INSUFFISANTES -->
							<!-- <div class="card-no-data-3 bg-white rounded h-100 d-flex flex-column justify-content-center">
								<i class="icon-no-data position-absolute translate-middle top-50"></i>
								<div class="text-center ">
									<i class="icon-no-data fs-small"></i>
									<p class="text-medium ">Données insuffisantes </p>
									<p>
										Votre patient a <span class="text-regular">refusé le télésuivi</span> et Elivie n’a pas accès aux données d’observance.
									</p>
								</div>
							</div>  -->
							<!--  FIN DONNEES INSUFFISANTES -->

							<!--  BLOC SKELETON -->
							<!-- <div class="bg-white rounded p-3 mb-sm-2 skeleton-box position-absolute left-0 top-0 d-none d-lg-block">
								<div class="d-flex justify-content-between mb-5">
									<div class="flex-fill d-flex flex-column">
										<div class="w-30 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-60 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-45 rounded-pill bg-odd p-2 mb-3"></div>
									</div>
									<div class="flex-fill d-flex flex-column">
										<div class="flex-fill align-self-end d-flex mb-3">
											<div class="rounded-circle bg-odd p-3 align-self-center skeleton-box me-2"></div>
											<div class="rounded-circle bg-odd p-3 align-self-center skeleton-box me-2"></div>
											<div class="rounded-circle bg-odd p-3 align-self-center skeleton-box me-2"></div>
										</div>
										<div class="w-30 align-self-end rounded-pill bg-odd p-2 mb-3"></div>
									</div>
								</div>
								<div class="d-flex pt-3">
									<div class="d-flex flex-column bg-white justify-content-between mb-2 skeleton-box w-5">
										<div class="w-100 rounded-pill bg-odd p-2 align-self-center"></div>
										<div class="w-100 rounded-pill bg-odd p-2 align-self-center"></div>
										<div class="w-100 rounded-pill bg-odd p-2 align-self-center"></div>
										<div class="w-100 rounded-pill bg-odd p-2 align-self-center"></div>
										<div class="w-100 rounded-pill bg-odd p-2 align-self-center"></div>
									</div>
									<div class="d-flex flex-wrap align-items-end justify-content-between opacity-25 skeleton-box-graph">
										<span class="bg-green rounded wpx-9 h-5 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-5 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-5 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-7 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-9 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-12 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-6 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-4 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-67 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-55 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-67 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-71 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-2 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-22 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-11 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-82 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-8 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-12 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-13 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-22 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-7 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-2 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-11 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-22 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-95 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-85 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-87 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-91 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-27 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-7 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-12 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-47 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-32 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-88 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-75 mx-1"></span>
										<span class="bg-green rounded wpx-9 h-81 mx-1"></span>
										<span class="bg-red rounded wpx-9 h-12 mx-1"></span>
										<span class="bg-orange rounded wpx-9 h-17 mx-1"></span>
									</div>
								</div>
								<div class="d-flex justify-content-between nav-graphique-date ps-5 ms-5">
									<div class="rounded-circle bg-odd p-3 align-self-center skeleton-box me-2"></div>
									<div class="w-5 rounded-pill bg-odd p-2 align-self-center me-2"></div>
									<div class="w-20 timeline align-self-center position-relative"></div>
									<div class="w-5 rounded-pill bg-odd p-2 align-self-center me-2"></div>
									<div class="w-20 timeline align-self-center position-relative"></div>
									<div class="w-5 rounded-pill bg-odd p-2 align-self-center me-2"></div>
									<div class="w-20 timeline align-self-center position-relative"></div>
									<div class="w-5 rounded-pill bg-odd p-2 align-self-center me-2"></div>
									<div class="rounded-circle bg-odd p-3 align-self-center skeleton-box"></div>
								</div>
							</div> -->
							<!--  FIN BLOC SKELETON -->
						</div>
					</div>
					<!-- Affichage bloc dernières analyses -->
					<div class="row mb-1">
						<div class="col-12">
							<span class="fs-5 text-medium d-block mb-1">Les dernières analyses</span>
						</div>
						<div class="col-12">
							<div class="row">
								<div class="col-12 mb-3 col-md-6 position-relative">
									<!-- <div
										class="d-flex flex-column bg-white rounded px-3 pt-1 position-relative overflow-hidden shadow h-100"
									>
										<i
											class="icon icon-analyses fs-analyses position-absolute bottom-0 opacity-80"
										></i>
										<div class="my-2">
											<span class="text-medium">Capnographie</span>
										</div>
										<div class="flex-fill mb-2 text-center">
											<div class="d-flex flex-column h-100">
												<div class="d-flex h-100">
													<div class="flex-fill align-self-center">
														<p class="fs-12p mb-0 text-extra-light">Date de l'analyse</p>
														<p class="fs-6 mb-1 text-regular">22/10/2022</p>
														<p class="fs-12p mb-0 text-extra-light">Durée de l'analyse</p>
														<p class="fs-6 mb-0 text-regular">8H36</p>
													</div>
													<div class="flex-fill align-self-center">
														<p class="fs-6">PC02</p>
														<p class="fs-5 mb-0">
															<span class="text-medium">38,6</span> mmHg
														</p>
													</div>
													<div class="flex-fill align-self-center">
														<p class="fs-6">SP02</p>
														<p class="fs-5 mb-0">
															<span class="text-medium">96%</span> 2.0PI
														</p>
													</div>
													<div class="flex-fill align-self-center">
														<p class="fs-6">Fréquence du pouls</p>
														<p class="fs-5 mb-0"><span class="text-medium">84</span> bpm</p>
													</div>
												</div>
											</div>
										</div>
									</div> -->
									<!-- BLOC DONNEES INSUFFISANTES -->
									<!-- <div class="card-no-data-3 bg-white rounded h-100 d-flex flex-column justify-content-center">
										<i class="icon-no-data position-absolute translate-middle top-50"></i>
										<div class="text-center">
											<p class="text-medium">Données insuffisantes</p>
											<p>
												Votre patient a <span class="text-regular">refusé le télésuivi</span> et
												Elivie n’a pas accès aux données d’observance.
											</p>
										</div>
									</div>  -->
									<!-- FIN BLOC DONNEES INSUFFISANTES -->
									<!--  BLOC SKELETON -->
									<div class="bg-white shadow w-100 h-100 rounded p-3 mb-sm-2 skeleton-box">
										<div class="d-flex h-100 justify-content-between">
											<div class="flex-fill align-self-end d-flex flex-column">
												<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											</div>
											<div class="flex-fill align-self-end d-flex flex-column px-4">
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
											</div>
											<div class="flex-fill align-self-end d-flex flex-column px-4">
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
											</div>
											<div class="flex-fill align-self-end d-flex flex-column px-4">
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
											</div>
										</div>
									</div>
									<!-- END -->
								</div>
								<div class="col-12 mb-3 col-md-3 position-relative">
									<!-- <div
										class="d-flex flex-column bg-white rounded px-3 pt-1 position-relative shadow h-100"
									>
										<div class="mt-2">
											<span class="text-medium">Capnographie</span>
										</div>
										<div class="flex-fill mb-2 text-center">
											<div class="d-flex flex-column h-100">
												<div class="d-flex h-100">
													<div class="flex-fill align-self-center">
														<p class="fs-12p mb-0 text-extra-light">Date de l'analyse</p>
														<p class="fs-6 mb-1 text-regular">22/10/2022</p>
														<p class="fs-12p mb-0 text-extra-light">Durée de l'analyse</p>
														<p class="fs-6 mb-0 text-regular">8H36</p>
													</div>
													<div class="flex-fill align-self-center">
														<p class="fs-6">Fuite<br />P95</p>
														<p class="fs-5 mb-0">
															<span class="text-medium">5,51</span> l/min
														</p>
													</div>
												</div>
											</div>
										</div>
									</div> -->
									<!-- DONNEES INSUFFISANTES -->
									<!-- <div class="card-no-data-2 bg-white rounded h-100">
										<i class="icon-no-data position-absolute top-50 translate-middle-y"></i>
									</div>  -->
									<!--  FIN DONNEES INSUFFISANTES -->
									<!--  BLOC LOADING DATA -->
									<div class="bg-white shadow w-100 h-100 rounded p-3 mb-sm-2 skeleton-box">
										<div class="d-flex h-100 justify-content-between">
											<div class="flex-fill align-self-end d-flex flex-column">
												<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											</div>
											<div class="flex-fill align-self-end d-flex flex-column ps-4">
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
											</div>
										</div>
									</div>
									<!-- END BLOC LOADING DATA -->
								</div>
								<div class="col-12 mb-3 col-md-3 position-relative">
									<!-- <div
										class="d-flex flex-column bg-white rounded px-3 pt-1 position-relative shadow h-100"
									>
										<div class="mt-2">
											<span class="text-medium">Capnographie</span>
										</div>
										<div class="flex-fill mb-2 text-center">
											<div class="d-flex flex-column h-100">
												<div class="d-flex h-100">
													<div class="flex-fill align-self-center">
														<p class="fs-12p mb-0 text-extra-light">Date de l'analyse</p>
														<p class="fs-6 mb-1 text-regular">22/10/2022</p>
														<p class="fs-12p mb-0 text-extra-light">Durée de l'analyse</p>
														<p class="fs-6 mb-0 text-regular">8H36</p>
													</div>
													<div class="flex-fill align-self-center">
														<p class="fs-6">Fuite<br />P95</p>
														<p class="fs-5 mb-0">
															<span class="text-medium">5,51</span> l/min
														</p>
													</div>
												</div>
											</div>
										</div>
									</div> -->
									<!-- DONNEES INSUFFISANTES -->
									<!-- <div class="card-no-data-2 bg-white rounded h-100">
										<i class="icon-no-data position-absolute top-50 translate-middle-y"></i>
									</div>  -->
									<!--  FIN DONNEES INSUFFISANTES -->
									<!--  BLOC LOADING DATA -->
									<div class="bg-white shadow w-100 h-100 rounded p-3 mb-sm-2 skeleton-box">
										<div class="d-flex h-100 justify-content-between">
											<div class="flex-fill align-self-end d-flex flex-column">
												<div class="w-100 rounded-pill bg-odd p-2 mb-3"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
											</div>
											<div class="flex-fill align-self-end d-flex flex-column ps-4">
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-4"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
												<div class="w-70 align-self-center rounded-pill bg-odd p-2 mb-2"></div>
											</div>
										</div>
									</div>
									<!-- END BLOC LOADING DATA -->
								</div>
							</div>
						</div>
					</div>
					<!-- Affichage bloc évènements -->
					<div class="row pt-3">
						<div class="col-12">
							<span class="fs-5 text-medium d-block mb-1">Evènements</span>
							<div class="d-flex flex-column flex-lg-row mb-3">
								<div class="me-3 fs-6 mb-1">Liste des dates importantes du patient</div>
								<div class="">
									<div class="d-flex">
										<span class="d-inline-block me-3">Filtrer par :</span>
										<div class="flex-grow-1 flex-fill select d-inline-block me-3">
											<select class="form-select2 w-100" aria-label="Default select example">
												<option selected>Choisir une option</option>
												<option value="1">One</option>
												<option value="2">Two</option>
												<option value="3">Three</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Affichage TAbleau EVENEMENTS desktop > 992px -->
						<div class="col-12 d-none d-lg-block position-relative">
							<div class="row">
								<div class="col position-relative">
									<div class="d-flex p-2">
										<span class="text-medium flex-fill ms-5">Date</span>
										<span class="text-medium flex-fill"
											>Intitulés et informations détaillées sur l'évènement</span
										>
									</div>
									<div class="table-responsive bg-white shadow rounded p-4 fs-wide-screen">
										<!-- 1ere item -->
										<div class="d-flex align-items-center bg-white rounded p-2 mb-2">
											<div class="date">22/02/2021</div>
											<div class="content flex-grow-1 position-relative">
												<i class="icon-intervention-2"></i>
												Intervention à domicile
											</div>
											<div class="action">
												rapport d'intervention à consulter
												<button class="btn pin-circle">
													<i
														class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
										<!-- 2e item -->
										<div class="d-flex align-items-center bg-odd rounded p-2 mb-2">
											<div class="date">22/02/2021</div>
											<div class="content flex-grow-1 position-relative">
												<i class="icon-rapport"></i>
												Votre note : début de la note du prescripteur : lorem ipsum dolor sit
												alem dolor eum acrem
											</div>
											<div class="action">
												<button class="btn pin-circle">
													<i
														class="icon-see position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
										<!-- ACCORDEON -->
										<div class="table-accordeon">
											<table class="table table-striped table-hover align-middle">
												<tbody>
													<!-- 1er élément -->
													<tr>
														<td
															colspan="6"
															class="position-relative table-cell-padding-none">
															<div
																class="rounded bg-white border color-primary color-primary">
																<table class="table mb-0 align-middle align-middle">
																	<tbody>
																		<tr class="" role="alert">
																			<td class="py-10px">22/08/2021</td>
																			<td class="position-relative">
																				<i
																					class="icon-intervention-2 color-primary"></i>
																				Intervention à domicile
																			</td>
																		</tr>
																	</tbody>
																</table>
																<input
																	type="checkbox"
																	class="accordeon-1 d-block"
																	name="accordeon-tab11"
																	id="accordeon-tab11" />
																<label
																	for="accordeon-tab11"
																	class="d-block btn pin-circle color-primary"
																	><i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i
																></label>
																<div class="accordeon border-top color-primary">
																	<table
																		class="table table-striped align-middle mb-0">
																		<tbody>
																			<!-- 1er élément -->
																			<tr class="" role="">
																				<td
																					rowspan="3"
																					class="bg-white rowspan align-top pt-2 pe-0">
																					Technicien(e) Elivie
																					<span>Solange</span>
																					<p>CASPERIANI</p>
																				</td>
																				<td>Intervention à domicile</td>
																				<td
																					class="text-wrap position-relative text-end">
																					<span>Taille : 1.3 Mo </span>
																					<button class="btn pin-circle ms-4">
																						<i
																							class="icon-ordonance position-absolute top-50 start-50 translate-middle fs-6"></i>
																					</button>
																				</td>
																			</tr>
																			<!-- 2e élément -->
																			<tr class="" role="">
																				<td class="w-60">
																					Baisse d'observance | 7e jour
																					d'affilé enregistré
																				</td>
																				<td
																					class="text-wrap position-relative text-end">
																					<span>Taille : 1.3 Mo </span>
																					<button class="btn pin-circle ms-4">
																						<i
																							class="icon-ordonance position-absolute top-50 start-50 translate-middle fs-6"></i>
																					</button>
																				</td>
																			</tr>
																			<!-- 3er élément -->
																			<tr class="" role="">
																				<td>
																					Baisse d'observance | 7e jour
																					d'affilé enregistré
																				</td>
																				<td
																					class="event text-wrap position-relative text-end">
																					<span>Taille : 1.3 Mo </span>
																					<button class="btn pin-circle ms-4">
																						<i
																							class="icon-ordonance position-absolute top-50 start-50 translate-middle fs-6"></i>
																					</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<!-- ACCORDEON -->
										<div class="table-accordeon">
											<table class="table table-striped table-hover align-middle">
												<tbody>
													<!-- 1er élément -->
													<tr>
														<td
															colspan="6"
															class="position-relative table-cell-padding-none">
															<div class="rounded bg-white border orange">
																<table class="table mb-0 align-middle align-middle">
																	<tbody>
																		<tr class="" role="alert">
																			<td>Du 18 au 22/08/21</td>
																			<td class="position-relative">
																				<i
																					class="icon-level-down text-orange"></i>
																				Baisse d'observance | 7e jour d'affilé
																				enregistré
																			</td>
																		</tr>
																	</tbody>
																</table>
																<input
																	type="checkbox"
																	class="accordeon-1 d-block"
																	name="accordeon-tab1"
																	id="accordeon-tab1" />
																<label
																	for="accordeon-tab1"
																	class="d-block btn pin-circle orange"
																	><i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i
																></label>
																<div class="accordeon">
																	<table
																		class="table table-striped align-middle mb-0">
																		<tbody>
																			<!-- 1er élément -->
																			<tr class="" role="">
																				<td>22/08/21</td>
																				<td>
																					Baisse d'observance | 7e jour
																					d'affilé enregistré
																				</td>
																				<td
																					class="position-relative border-light-left">
																					<div
																						class="d-flex justify-content-center">
																						<div>observance :</div>
																						<div
																							class="obs-green position-relative"></div>
																						<div>
																							<span class="wpx-95"
																								>Plus de 4h</span
																							>
																							<i
																								class="icon-level-up ms-2"></i>
																						</div>
																					</div>
																				</td>
																				<td
																					class="event text-wrap position-relative border-light-left text-center">
																					Pression 4,19 cm/H<sub>2</sub>O
																				</td>
																				<td
																					class="position-relative border-light-left text-center">
																					IAH : 2,4
																				</td>
																				<td
																					class="text-center position-relative border-light-left">
																					Taux de fuite médian : 0,37 l/s
																				</td>
																			</tr>
																			<!-- 2e élément -->
																			<tr class="" role="">
																				<td>22/08/21</td>
																				<td>
																					Baisse d'observance | 7e jour
																					d'affilé enregistré
																				</td>
																				<td
																					class="position-relative border-light-left">
																					<div
																						class="d-flex justify-content-center">
																						<div>observance&nbsp;:</div>
																						<div
																							class="obs-orange position-relative d-flex justify-content-between align-items-center">
																							<span class="wpx-95"
																								>Moins de 4h</span
																							>
																							<i
																								class="icon-stable ms-2"></i>
																						</div>
																					</div>
																				</td>
																				<td
																					class="event text-wrap position-relative border-light-left text-center">
																					Pression 4,19 cm/H<sub>2</sub>O
																				</td>
																				<td
																					class="position-relative border-light-left text-center">
																					IAH : 2,4
																				</td>
																				<td
																					class="text-center position-relative border-light-left">
																					Taux de fuite médian : 0,37 l/s
																				</td>
																			</tr>
																			<!-- 3er élément -->
																			<tr class="" role="">
																				<td>22/08/21</td>
																				<td>
																					Baisse d'observance | 7e jour
																					d'affilé enregistré
																				</td>
																				<td
																					class="position-relative border-light-left">
																					<div
																						class="d-flex justify-content-center align-items-center">
																						<div>observance&nbsp;:</div>

																						<div
																							class="obs-red position-relative d-flex justify-content-between align-items-center">
																							<span class="wpx-95"
																								>moins de 2h30</span
																							>
																							<i
																								class="icon-level-down ms-2 text-red"></i>
																						</div>
																					</div>
																				</td>
																				<td
																					class="event text-wrap position-relative border-light-left text-center">
																					Pression 4,19 cm/H<sub>2</sub>O
																				</td>
																				<td
																					class="position-relative border-light-left text-center">
																					IAH : 2,4
																				</td>
																				<td
																					class="text-center position-relative border-light-left">
																					Taux de fuite médian : 0,37 l/s
																				</td>
																			</tr>
																			<tr class="" role="">
																				<td>22/08/21</td>
																				<td>
																					Baisse d'observance | 7e jour
																					d'affilé enregistré
																				</td>
																				<td
																					class="position-relative border-light-left">
																					<div
																						class="d-flex justify-content-center align-items-center">
																						<div>observance&nbsp;:</div>

																						<div
																							class="obs-red position-relative d-flex justify-content-between align-items-center">
																							<span class="wpx-95"
																								>moins de 2h30</span
																							>
																							<i
																								class="icon-level-down ms-2 text-red"></i>
																						</div>
																					</div>
																				</td>
																				<td
																					class="event text-wrap position-relative border-light-left text-center">
																					Pression 4,19 cm/H<sub>2</sub>O
																				</td>
																				<td
																					class="position-relative border-light-left text-center">
																					IAH : 2,4
																				</td>
																				<td
																					class="text-center position-relative border-light-left">
																					Taux de fuite médian : 0,37 l/s
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<!-- 3e item -->
										<div class="d-flex align-items-center bg-odd rounded p-2 mb-2">
											<div class="date">22/02/2021</div>
											<div class="content flex-grow-1 position-relative">
												<i class="icon-level-down text-orange"></i>
												Baisse d'observance | 7e jour d'affilé enregistré
											</div>
											<div class="action">
												rapport d'intervention à consulter
												<button class="btn pin-circle">
													<i
														class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
										<!-- 4e item -->
										<div class="d-flex align-items-center bg-white rounded p-2 mb-2">
											<div class="date">22/02/2021</div>
											<div class="content flex-grow-1 position-relative">
												<i class="icon-intervention-2"></i>
												Intervention à domicile
											</div>
											<div class="action">
												rapport d'intervention à consulter
												<button class="btn pin-circle">
													<i
														class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
										<!-- 5e item -->
										<div class="rounded bg-white border red mb-2">
											<table class="table table-striped mb-0">
												<tbody>
													<tr>
														<td class="position-relative table-cell-padding-none">
															<table class="table align-middle mb-0">
																<tbody>
																	<!-- 1er élément -->
																	<tr class="" role="">
																		<td class="date">22/08/21</td>
																		<td class="position-relative">
																			<i class="icon-notif"></i>
																			Baisse d'observance | 4e jour d'affilé
																			enregistré
																		</td>
																		<td class="position-relative border-light-left">
																			<div
																				class="d-flex justify-content-center align-items-center">
																				<div>observance&nbsp;:</div>
																				<div
																					class="obs-red position-relative d-flex justify-content-between align-items-center">
																					<span class="wpx-95"
																						>Plus de 4h</span
																					>
																					<i class="icon-level-up ms-2"></i>
																				</div>
																			</div>
																		</td>
																		<td
																			class="event text-wrap position-relative border-light-left text-center">
																			Pression 4,19 cm/H<sub>2</sub>O
																		</td>
																		<td
																			class="position-relative border-light-left text-center">
																			IAH : 2,4
																		</td>
																		<td
																			class="text-center position-relative border-light-left">
																			Taux de fuite médian : 0,37 l/s
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<!-- 6e item -->
										<div class="d-flex align-items-center rounded p-2 mb-2">
											<div class="date">18/02/2021</div>
											<div class="content flex-grow-1 position-relative">
												<i class="icon-message"></i>
												Votre message envoyé à Elivie : début du message : lorem ipsum dolor sit
												alem dolor eum acrem
											</div>
											<div class="action">
												<button class="btn pin-circle">
													<i
														class="icon-see position-absolute top-50 start-50 translate-middle"></i>
												</button>
											</div>
										</div>
										<div class="d-flex justify-content-center">
											<button class="btn btn-primary text-uppercase">
												Voir plus d'évènements
											</button>
										</div>
									</div>
								</div>
							</div>
							<!--  BLOC SKELETON -->
							<!-- <div
								class="bg-white h-100 w100 rounded p-3 mb-sm-2 skeleton-box position-absolute top-0 left-0 d-flex flex-column"
							>
								<div class="table-responsive">
									<div class="flex-fill d-flex mb-4">
										<div
											class="w-25 p-2 skeleton-box rounded-pill bg-odd me-5 align-self-center"
										></div>
										<div
											class="w-5 p-2 skeleton-box rounded-pill bg-odd me-3 align-self-center"
										></div>
										<div class="w-10 p-2 skeleton-box rounded-pill bg-odd align-self-center"></div>
									</div>
									<div
										class="d-flex align-items-center justify-content-between bg-white rounded p-2 mb-2 skeleton-box"
									>
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-odd me-3"></div>
											<div class="w-50 rounded-pill bg-odd p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="w-40 w-20 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-odd me-2"></div>
										</div>
									</div>
									<div class="d-flex align-items-center bg-odd rounded p-2 mb-2 skeleton-box">
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-white p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-white me-3"></div>
											<div class="w-30 rounded-pill bg-white p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="w-60 rounded-pill bg-white p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-white me-2"></div>
										</div>
									</div>
									<div
										class="d-flex align-items-center justify-content-between bg-white rounded p-2 mb-2 skeleton-box"
									>
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-odd me-3"></div>
											<div class="w-50 rounded-pill bg-odd p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="w-40 w-20 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-odd me-2"></div>
										</div>
									</div>
									<div class="d-flex align-items-center bg-odd rounded p-2 mb-2 skeleton-box">
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-white p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-white me-3"></div>
											<div class="w-30 rounded-pill bg-white p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="w-60 rounded-pill bg-white p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-white me-2"></div>
										</div>
									</div>
									<div
										class="d-flex align-items-center justify-content-between bg-white rounded p-2 mb-2 skeleton-box"
									>
										<div class="flex-fill d-flex">
											<div class="w-15 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-odd me-3"></div>
											<div class="w-50 rounded-pill bg-odd p-2 align-self-center"></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div class="w-40 w-20 rounded-pill bg-odd p-2 me-3 align-self-center"></div>
											<div class="rounded-circle p-3 bg-odd me-2"></div>
										</div>
									</div>
									<div class="d-flex align-items-center bg-odd rounded p-2 mb-2">
										<div class="flex-fill d-flex">
											<div
												class="w-15 skeleton-box rounded-pill bg-white p-2 me-3 align-self-center"
											></div>
											<div class="skeleton-box-circle rounded-circle p-3 bg-white me-3"></div>
											<div
												class="w-30 skeleton-box rounded-pill bg-white p-2 align-self-center"
											></div>
										</div>
										<div class="flex-fill d-flex justify-content-end">
											<div
												class="w-60 skeleton-box rounded-pill bg-white p-2 me-3 align-self-center"
											></div>
											<div class="skeleton-box-circle rounded-circle p-3 bg-white me-2"></div>
										</div>
									</div>
									<div class="d-flex p-2 mb-2 mt-5">
										<div class="flex-fill d-flex justify-content-center">
											<div class="w-20 skeleton-box rounded-pill bg-odd p-3"></div>
										</div>
									</div>
								</div>
							</div>  -->
							<!--  FIN BLOC SKELETON -->
						</div>
						<!-- #################          SMARTPHONE & TABLET affichage EVENEMENTS en 1 colonne < 992px -->
						<!-- Début du bloc carousel -->
						<div class="carousel slide flex-carousel mt-2 mb-4 d-block d-sm-none" id="myCarousel">
							<!-- bouttons navigations -->
							<div class="carousel-indicators">
								<button
									type="button"
									data-bs-target="#myCarousel"
									data-bs-slide-to="0"
									class="active rounded-pill"
									aria-label="Slide 1"
									aria-current="true"></button>
								<button
									type="button"
									data-bs-target="#myCarousel"
									data-bs-slide-to="1"
									aria-label="Slide 2"
									class="rounded-pill"></button>
							</div>
							<!-- Affichage contenu   -->
							<div class="carousel-inner">
								<!-- 1er élément (actif par défaut) -->
								<div class="carousel-item active">
									<div
										class="d-event d-event-inter d-flex flex-column bg-white rounded shadow position-relative">
										<div class="px-3 py-2">
											<div class="d-flex align-items-center justify-content-between">
												<div class=" ">
													<span class="d-block text-medium">22 février 2021</span>
													<span class="d-block text-regular">Intervention à domicile</span>
												</div>
												<div class="">
													<button class="btn pin-circle">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
													</button>
												</div>
											</div>
										</div>
										<div class="px-3 py-2 border-top">
											<div class="d-flex">
												<div class="flex-fill">
													<span class="d-block mb-2">
														<span class="text-regular me-1">Technicien(ne) : </span>Soline
														PICARD
													</span>
													<span class="d-block">
														<span class="text-regular me-1">Sujet d'intervention : </span>
														Réglage de pression sur la pompe
													</span>
												</div>
											</div>
										</div>

										<i class="icon-intervention-2" aria-hidden="true"></i>
									</div>
								</div>
								<!-- 2e élément -->
								<div class="carousel-item">
									<div
										class="d-event d-event-obs orange d-flex flex-column bg-white rounded shadow position-relative">
										<div class="px-3 py-2 position-relative">
											<div class="d-flex align-items-center justify-content-between">
												<div class="date">
													<span class="d-block text-medium">Du 14 au 22 février 2021</span>
													<span class="d-block text-regular">Baisse d'observance</span>
												</div>
												<div class="flex-fill ps-5">
													<div class="progress">
														<div
															class="progress-bar orange w-75 text-light"
															role="progressbar"
															aria-valuenow="75"
															aria-valuemin="0"
															aria-valuemax="100">
															4h42min
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="px-3 py-2 border-top">
											<div class="d-flex flex-column flex-sm-row">
												<div class="flex-fill">
													<span class="d-block text-regular me-1">Indices moyens : </span>
													<span class="d-block">IAH : 1,14</span>
													<span class="d-block">Taux de fuite médian : 0,50 l/s</span>
												</div>
												<div class="flex-fill align-self-sm-end">
													<span class="">Pression médiane : 4,19 cm/H<sub>2</sub>O</span>
												</div>
											</div>
										</div>

										<i class="icon-level-down text-orange" aria-hidden="true"></i>
									</div>
								</div>
							</div>
						</div>
						<!-- Début sans bloc carousel -->
						<div class="col-12 d-block d-lg-none position-relative">
							<div
								class="d-event d-event-inter d-flex flex-column bg-white rounded shadow position-relative">
								<div class="px-3 py-2">
									<div class="d-flex align-items-center justify-content-between">
										<div class=" ">
											<span class="d-block text-medium">22 février 2021</span>
											<span class="d-block text-regular">Intervention à domicile</span>
										</div>
										<div class="">
											<button class="btn pin-circle">
												<i
													class="icon-ordonance position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</div>
									</div>
								</div>
								<div class="px-3 py-2 border-top">
									<div class="d-flex">
										<div class="flex-fill">
											<span class="d-block mb-2"
												><span class="text-regular me-1">Technicien(ne) : </span>Soline
												PICARD</span
											>
											<span class="d-block"
												><span class="text-regular me-1">Sujet d'intervention : </span>Réglage
												de pression sur la pompe</span
											>
										</div>
									</div>
								</div>

								<i class="icon-intervention-2" aria-hidden="true"></i>
							</div>
							<div
								class="d-event d-event-obs orange d-flex flex-column bg-white rounded shadow position-relative">
								<div class="px-3 py-2 position-relative">
									<div class="d-flex align-items-center justify-content-between">
										<div class="date">
											<span class="d-block text-medium">Du 14 au 22 février 2021</span>
											<span class="d-block text-regular">Baisse d'observance</span>
										</div>
										<div class="flex-fill ps-5">
											<div class="progress">
												<div
													class="progress-bar orange w-75 text-light"
													role="progressbar"
													aria-valuenow="75"
													aria-valuemin="0"
													aria-valuemax="100">
													4h42min
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="px-3 py-2 pt-5 pt-sm-2 border-top">
									<div class="d-flex flex-column flex-sm-row">
										<div class="flex-fill">
											<span class="d-block text-regular me-1">Indices moyens : </span>
											<span class="d-block">IAH : 1,14</span>
											<span class="d-block">Taux de fuite médian : 0,50 l/s</span>
										</div>
										<div class="flex-fill align-self-sm-end">
											<span class="">Pression médiane : 4,19 cm/H<sub>2</sub>O</span>
										</div>
									</div>
								</div>

								<i class="icon-level-down text-orange" aria-hidden="true"></i>
							</div>
							<div
								class="d-event d-event-note d-flex flex-column bg-white rounded shadow position-relative">
								<div class="px-3 py-2">
									<div class="d-flex align-items-center justify-content-between">
										<div class=" ">
											<span class="d-block text-medium">20 février 2021</span>
											<span class="d-block text-regular">Votre note personnelle</span>
										</div>
										<div class="">
											<button class="btn pin-circle">
												<i
													class="icon-see position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</div>
									</div>
								</div>
								<div class="px-3 py-2 border-top">
									<div class="d-flex">
										<div class="flex-fill">
											<span class="d-block">
												Et licet quocumque oculos flexeris feminas adfatim multas spectare
												cirratas, quibus, si nupsissent, per aetatem ter iam nixus poterat
												suppetere liberorum, ad usque taedium pedibus pavimenta tergentes
												iactari volucriter gyris, dum exprimunt innumera simulacra, quae finxere
												fabulae theatrales.
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								class="d-event d-event-alert red d-flex flex-column bg-white rounded shadow position-relative">
								<div class="px-3 py-2 position-relative">
									<div class="d-flex align-items-center justify-content-between">
										<div class="date">
											<span class="d-block text-medium text-red">16 janvier 2021</span>
											<span class="d-block text-regular">Alerte IAH</span>
										</div>
										<div class="flex-fill ps-5">
											<div class="progress text-red text-regular">
												<div
													class="progress-bar red text-red w-25 text-light"
													role="progressbar"
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100"></div>
												<span class="ms-2 fs-6">18min</span>
											</div>
										</div>
									</div>
								</div>
								<div class="px-3 py-2 border-top pt-5 pt-sm-2">
									<div class="d-flex flex-column flex-sm-row">
										<div class="flex-fill">
											<span class="d-block text-regular me-1">Indices : </span>
											<span class="d-block text-medium text-red">Alerte IAH : 8,94</span>
											<span class="d-block"
												>Taux de fuite médian :
												<span class="text-medium text-red">0,82 l/s</span></span
											>
										</div>
										<div class="flex-fill align-self-sm-end">
											<span class=""
												>Pression médiane :
												<span class="text-medium text-red">9,19 cm/H<sub>2</sub>O</span></span
											>
										</div>
									</div>
								</div>

								<i class="icon-notif text-red" aria-hidden="true"></i>
							</div>
							<div
								class="d-event d-event-msg d-flex flex-column bg-white rounded shadow position-relative">
								<div class="px-3 py-2">
									<div class="d-flex align-items-center justify-content-between">
										<div class=" ">
											<span class="d-block text-medium">13 février 2021</span>
											<span class="d-block text-regular">Message envoyé à Elivie</span>
										</div>
										<div class="">
											<button class="btn pin-circle">
												<i
													class="icon-see position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</div>
									</div>
								</div>
								<div class="px-3 py-2 border-top">
									<div class="d-flex">
										<div class="flex-fill">
											<p class="">
												<span class="text-regular me-1">Sujet : </span> Réglages de pression sur
												la pompe
											</p>
											<span class="d-block">
												Et licet quocumque oculos flexeris feminas adfatim multas spectare
												cirratas, quibus, si nupsissent, per aetatem ter iam nixus poterat
												suppetere liberorum, ad usque taedium pedibus pavimenta tergentes
												iactari volucriter gyris, dum exprimunt innumera simulacra, quae finxere
												fabulae theatrales.
											</span>
										</div>
									</div>
								</div>
								<i class="icon-message" aria-hidden="true"></i>
							</div>
							<!-- <div class="bg-white rounded p-3 mb-sm-2 skeleton-box shadow w-100">
								<div class="d-flex mb-4">
									<div class="flex-fill">
										<div class="w-30 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-50 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill text-end">
										<div class="skeleton-box rounded-circle p-3 bg-odd"></div>
									</div>
								</div>
								<div class="d-flex">
									<div class="flex-fill">
										<div class="w-65 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-90 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill align-self-end">
										<div class="w-55 rounded-pill bg-odd p-2"></div>
									</div>
								</div>
							</div>
							<div class="bg-white rounded p-3 mb-sm-2 skeleton-box shadow w-100">
								<div class="d-flex mb-4">
									<div class="flex-fill">
										<div class="w-30 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-50 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill text-end">
										<div class="skeleton-box rounded-circle p-3 bg-odd"></div>
									</div>
								</div>
								<div class="d-flex">
									<div class="flex-fill">
										<div class="w-35 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
										<div class="w-100 rounded-pill bg-odd p-2"></div>
									</div>
								</div>
							</div>
							<div class="bg-white rounded p-3 mb-sm-2 skeleton-box shadow w-100">
								<div class="d-flex mb-4">
									<div class="flex-fill">
										<div class="w-30 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-50 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill text-end">
										<div class="skeleton-box rounded-circle p-3 bg-odd"></div>
									</div>
								</div>
								<div class="d-flex">
									<div class="flex-fill">
										<div class="w-65 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-90 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill align-self-end">
										<div class="w-55 rounded-pill bg-odd p-2"></div>
									</div>
								</div>
							</div>
							<div class="bg-white rounded p-3 mb-sm-2 skeleton-box shadow w-100">
								<div class="d-flex mb-4">
									<div class="flex-fill">
										<div class="w-30 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-50 rounded-pill bg-odd p-2"></div>
									</div>
									<div class="flex-fill text-end">
										<div class="skeleton-box rounded-circle p-3 bg-odd"></div>
									</div>
								</div>
								<div class="d-flex">
									<div class="flex-fill">
										<div class="w-35 rounded-pill bg-odd p-2 mb-3"></div>
										<div class="w-100 rounded-pill bg-odd p-2 mb-2"></div>
										<div class="w-100 rounded-pill bg-odd p-2"></div>
									</div>
								</div>
							</div> -->
						</div>
					</div>
					<!-- DEBUT LISTING PATIENT > 1200px -->
					<div class="row py-3 d-none d-xl-block">
						<div class="col-12 mt-5">
							<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0">
								Notifications à traiter
							</span>
						</div>
						<div class="col-12">
							<div class="d-flex listing-patient w-100 mb-3">
								<div class="flex-fill pe-2 align-self-end">
									<span>Prescriptions</span>
									<input id="renouveler" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="renouveler"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative">
										<div class="d-flex justify-content-between">
											<span class="text-medium">A renouveler</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												6
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill pe-2 align-self-end">
									<input id="echeance" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="echeance"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Avec échéance</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												23
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill pe-2 align-self-end">
									<span>Patients</span>
									<input id="alerte-iah" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="alerte-iah"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Alerte IAH</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												8
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill pe-2 align-self-end">
									<input id="obs-down" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="obs-down"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Baisse d'observance</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												14
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill align-self-end">
									<input id="favoris" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="favoris"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Favoris</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												9
											</span>
										</div>
									</label>
								</div>
							</div>
						</div>
						<div class="col-12 mt-1">
							<span class="d-block mb-3 mb-sm-2 ps-3 ps-lg-0">
								Liste de vos patients classés par ordre Alphapbétique
							</span>
						</div>
						<!-- #################          LISTING PATIENT > 1200px -->
						<div class="col-12">
							<div class="row">
								<div class="listing-patient table-responsive-lg d-none d-lg-block fs-wide-screen">
									<div class="bg-white table-rounded pb-3 px-3 pt-1">
										<div class="pt-2 ps-3 ms-1 d-flex">
											<!-- <div class="align-self-center me-5">
												<label class="checkbox-wrap checkbox-primary custom-checkbox d-flex">
													<input type="checkbox" />
													<span class="ms-2">Sélectionner tous les patients</span>
												</label>
											</div> -->

											<button class="btn btn-primary disabled me-2">
												Editer
												<i class="icon-editer fs-12p ms-2"></i>
											</button>
											<button class="btn btn-primary disabled">
												Supprimer
												<i class="icon-delete fs-12p ms-2"></i>
											</button>
										</div>
										<table class="table table-striped align-middle">
											<thead class="thead-primary">
												<tr>
													<th>
														<label
															class="checkbox-wrap checkbox-primary custom-checkbox ms-3 mt-3">
															<input type="checkbox" />
														</label>
													</th>
													<th class="text-left w-15">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center asc active">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span
																class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
																Nom | Prénom
															</span>
															<div class="align-self-center">
																<input
																	type="checkbox"
																	name="option-popin-nom22"
																	id="option-popin-nom22"
																	class="option-popin d-block" />
																<label
																	for="option-popin-nom22"
																	class="btn btn-options pin-circle ms-2">
																	<i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
																</label>
																<div
																	class="option-popin-content text-start shadow bg-white rounded-bottom">
																	<div class="p-3">
																		<form class="mr-auto ml-md-3 my-2 mw-100">
																			<div class="input-group">
																				<input
																					type="text"
																					class="form-control rounded-pill bg-white border-1 small py-1 px-3"
																					placeholder="Ex: Jean"
																					aria-label="Search"
																					aria-describedby="basic-addon2" />
																			</div>
																		</form>
																	</div>
																	<div class="row g-0 btn-filters">
																		<button
																			class="col-6 btn text-uppercase btn-default text-medium">
																			effacer
																		</button>
																		<button
																			class="col-6 btn text-uppercase text-white text-medium">
																			filter
																		</button>
																	</div>
																</div>
															</div>
														</div>
														<div class="filters-added w-100 pt-2 d-flex">
															<button class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
																<span class="text-gray me-2 text-truncate"
																	>Dai...
																</span>
																<i class="icon-closed text-gray fs-5-closed"></i>
															</button>
															<button class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
																<span class="text-gray me-2 text-truncate">G... </span>
																<i class="icon-closed text-gray fs-5-closed"></i>
															</button>
														</div>
													</th>
													<th class="text-left">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center desc">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span
																class="flew-grow-1 flex-fill text-medium fs-7 align-self-center lh-17"
																>Traitement du patient</span
															>
															<div class="align-self-center">
																<input
																	type="checkbox"
																	name="option-popin-patient"
																	id="option-popin-patient"
																	class="option-popin d-block" />
																<label
																	for="option-popin-patient"
																	class="btn btn-options pin-circle ms-2">
																	<i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
																</label>
																<div
																	class="option-popin-content text-start shadow bg-white rounded-bottom">
																	<div class="p-3">
																		<form class="mr-auto ml-md-3 my-2 mw-100">
																			<div class="input-group">
																				<input
																					type="text"
																					class="form-control rounded-pill bg-white border-1 small py-1 px-3"
																					placeholder="Ex: Ventilatopn"
																					aria-label="Search"
																					aria-describedby="basic-addon2" />
																			</div>
																		</form>
																	</div>
																	<div class="row g-0 btn-filters">
																		<button
																			class="col-6 btn text-uppercase btn-default text-medium">
																			effacer
																		</button>
																		<button
																			class="col-6 btn text-uppercase text-white text-medium">
																			filter
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</th>
													<th class="text-left w-15">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center asc active">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span
																class="text-medium fs-7 align-self-center lh-17 flew-grow-1 flex-fill"
																>IAH Moyen</span
															>
															<div class="align-self-center">
																<input
																	type="checkbox"
																	name="option-popin-iah"
																	id="option-popin-iah"
																	class="option-popin d-block" />
																<label
																	for="option-popin-iah"
																	class="btn btn-options pin-circle ms-2">
																	<i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
																</label>
																<div
																	class="option-popin-content text-start shadow bg-white rounded-bottom">
																	<div class="p-3">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Plus de 6
																			</label>
																		</div>
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Entre 4 et 6
																			</label>
																		</div>
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Moins de 4
																			</label>
																		</div>
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Non transmis
																			</label>
																		</div>
																	</div>
																	<div class="row g-0 btn-filters">
																		<button
																			class="col-6 btn text-uppercase btn-default text-medium">
																			effacer
																		</button>
																		<button
																			class="col-6 btn text-uppercase text-white text-medium">
																			filter
																		</button>
																	</div>
																</div>
															</div>
														</div>
														<div class="filters-added w-100 pt-2 d-flex">
															<button class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
																<span class="text-gray me-2 text-truncate"
																	>Plus de 10</span
																>
																<i class="icon-closed text-gray fs-5-closed"></i>
															</button>
															<button class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
																<span class="text-gray me-2 text-truncate"
																	>Entre 5 et 10
																</span>
																<i class="icon-closed text-gray fs-5-closed"></i>
															</button>
														</div>
													</th>
													<th class="text-left">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center desc">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span class="text-medium fs-7 align-self-center lh-17"
																>Niveau d'observance</span
															>
															<div class="align-self-center">
																<input
																	type="checkbox"
																	name="option-popin-obs"
																	id="option-popin-obs"
																	class="option-popin d-block" />
																<label
																	for="option-popin-obs"
																	class="btn btn-options pin-circle ms-2">
																	<i
																		class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
																</label>
																<div
																	class="option-popin-content text-start w-30 shadow bg-white rounded-bottom">
																	<div class="p-3">
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Plus de 4h
																			</label>
																		</div>
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Entre 2h et 4h
																			</label>
																		</div>
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Moins de 2h
																			</label>
																		</div>
																		<div class="input-group">
																			<input
																				class="custom-radio"
																				id="pmed"
																				name="checkbox"
																				type="checkbox" />
																			<label
																				for="pmed"
																				class="custom-radio position-relative">
																				Non transmis
																			</label>
																		</div>
																	</div>
																	<div class="row g-0 btn-filters">
																		<button
																			class="col-6 btn text-uppercase btn-default text-medium">
																			effacer
																		</button>
																		<button
																			class="col-6 btn text-uppercase text-white text-medium">
																			filter
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</th>
													<th class="text-left">
														<div
															class="d-flex bg-white rounded p-2 shadow position-relative h-100">
															<button class="btn pin-circle me-2 align-self-center">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span class="text-medium fs-7 align-self-center lh-17"
																>Fin de prescription</span
															>
														</div>
													</th>
													<th class="text-center">
														<span class="d-block text-medium fs-7">Age</span>
														<div class="d-flex justify-content-between">
															<span class="text-nowrap text-center">
																Date de naissance
															</span>
														</div>
													</th>
													<th class="text-center w-8">
														<span class="d-block text-medium fs-7">Actions</span>
														<div class="d-flex justify-content-between">
															<span class="text-nowrap text-center"> Renouveler </span>
															<span class="text-nowrap text-center"> Favoris </span>
														</div>
													</th>
												</tr>
												<tr></tr>
											</thead>
											<tbody>
												<!-- 1er élément -->
												<tr class="alert" role="alert">
													<td class="rounded-start">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td>
														<div class="d-flex">
															<div
																class="initiales pin-circle white me-3 text-medium align-self-center">
																ES
															</div>
															<div class="flex-grow-1 align-self-center">
																Esther <span class="text-bold">Murphy</span>
															</div>
														</div>
													</td>
													<td class="text-center">
														Ventilation à pression positive continue PPC
													</td>
													<td class="event text-wrap text-center">4,12</td>
													<td class="position-relative">
														<div class="d-flex align-items-center ms-1 ps-2">
															<i class="icon-check-file fs-4 text-green"></i>
															<div class="d-flex">
																<span class="text-capitalize">Plus de 4h</span>
																<div class="align-self-center">
																	<i class="icon-level-up ms-2"></i>
																</div>
															</div>
														</div>
													</td>
													<td class="position-relative">
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="">23 mars 2022</span>
															<span class="text-light fs-12p">
																Dans moins de 1 mois
															</span>
														</div>
													</td>
													<td>
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="text-regular">66 ans</span>
															<span class="text-light fs-12p"> 02/12/1956 </span>
														</div>
													</td>
													<td class="text-center">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
												<!-- 1er élément -->
												<tr class="alert" role="alert">
													<td class="rounded-start">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td>
														<div class="d-flex">
															<div
																class="initiales pin-circle white me-3 text-medium align-self-center">
																ES
															</div>
															<div class="flex-grow-1 align-self-center">
																Esther <span class="text-bold">Murphy</span>
															</div>
														</div>
													</td>
													<td class="text-center">
														Ventilation à pression positive continue PPC
													</td>
													<td class="event text-wrap text-center">4,12</td>
													<td class="position-relative">
														<div class="obs-blue d-flex">
															<span class="text-capitalize">Plus de 4h</span>
															<div class="align-self-center">
																<i class="icon-level-up ms-2"></i>
															</div>
														</div>
													</td>
													<td class="position-relative">
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="">23 mars 2022</span>
															<span class="text-light fs-12p">
																Dans moins de 1 mois
															</span>
														</div>
													</td>
													<td>
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="text-regular">66 ans</span>
															<span class="text-light fs-12p"> 02/12/1956 </span>
														</div>
													</td>
													<td class="text-center">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
												<!-- 1er élément -->
												<tr class="alert" role="alert">
													<td class="rounded-start">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td>
														<div class="d-flex">
															<div
																class="initiales pin-circle white me-3 text-medium align-self-center">
																ES
															</div>
															<div class="flex-grow-1 align-self-center">
																Esther <span class="text-bold">Murphy</span>
															</div>
														</div>
													</td>
													<td class="text-center">
														Ventilation à pression positive continue PPC
													</td>
													<td class="event text-wrap text-center">4,12</td>
													<td class="position-relative">
														<div class="obs-green d-flex">
															<span class="text-capitalize">Plus de 4h</span>
															<div class="align-self-center">
																<i class="icon-level-up ms-2"></i>
															</div>
														</div>
													</td>
													<td class="position-relative">
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="">23 mars 2022</span>
															<span class="text-light fs-12p">
																Dans moins de 1 mois
															</span>
														</div>
													</td>
													<td>
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="text-regular">66 ans</span>
															<span class="text-light fs-12p"> 02/12/1956 </span>
														</div>
													</td>
													<td class="text-center">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
												<!-- 2er élément -->
												<tr class="alert" role="alert">
													<td class="rounded-start">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td>
														<div class="d-flex">
															<span
																class="initiales pin-circle white me-3 text-medium align-self-center"
																>FL
															</span>
															<div class="flex-grow-1 align-self-center">
																Farine <span class="text-bold">Letcher</span>
															</div>
														</div>
													</td>
													<td class="text-center w-25">
														Autre traitement pour le patient sur deux lignes ou plusieurs
														lignes, cela peut être conséquent et l'affichage change
													</td>
													<td class="event text-wrap text-center">
														<span class="text-orange text-regular">6,57</span>
													</td>
													<td class="position-relative">
														<div class="obs-orange d-flex">
															<span class="text-capitalize">Entre 2h et 4h</span>
															<div class="align-self-center">
																<i class="icon-level-down ms-2"></i>
															</div>
														</div>
													</td>
													<td class="position-relative">
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="">30 Juillet 2022</span>
														</div>
													</td>
													<td>
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="text-regular">52 ans</span>
															<span class="text-light fs-12p"> 10/09/1968 </span>
														</div>
													</td>
													<td class="text-center">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
												<!-- 3er élément -->
												<tr class="alert" role="alert">
													<td class="rounded-start">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td>
														<div class="d-flex">
															<span
																class="initiales pin-circle white me-3 text-medium align-self-center"
																>WA
															</span>
															<div class="flex-grow-1 align-self-center">
																Walid <span class="text-bold">Alister</span>
															</div>
														</div>
													</td>
													<td class="text-center">Autre traitement pour le patient</td>
													<td class="event text-wrap text-center">
														<span class="text-red text-regular">7,23</span>
													</td>
													<td class="position-relative">
														<div class="obs-red d-flex">
															<span class="text-capitalize">Moins de 2h</span>
															<div class="align-self-center">
																<i class="icon-stable ms-2"></i>
															</div>
														</div>
													</td>
													<td class="position-relative">
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="">23 mars 2022</span>
															<span class="text-light fs-12p">
																Dans moins de 1 mois
															</span>
														</div>
													</td>
													<td>
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="text-regular">66 ans</span>
															<span class="text-light fs-12p"> 02/12/1956 </span>
														</div>
													</td>
													<td class="text-center">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
												<!-- 4er élément -->
												<tr class="alert" role="alert">
													<td class="rounded-start">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td>
														<div class="d-flex">
															<span
																class="initiales pin-circle white me-3 text-medium align-self-center"
																>AW
															</span>
															<div class="flex-grow-1 align-self-center">
																Alisther <span class="text-bold">Westside</span>
															</div>
														</div>
													</td>
													<td class="text-center">
														Ventilation à pression positive continue PPC
													</td>
													<td class="event text-wrap text-center">non transmis</td>
													<td class="position-relative">
														<div class="obs-none d-flex">
															<span class="text-capitalize">Non transmis</span>
														</div>
													</td>
													<td class="position-relative">
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="">11 septembre 2022</span>
															<span class="text-light fs-12p">
																Dans moins de 3 mois
															</span>
														</div>
													</td>
													<td>
														<div
															class="d-flex flex-column justify-content-between text-center">
															<span class="text-regular">66 ans</span>
															<span class="text-light fs-12p"> 02/12/1956 </span>
														</div>
													</td>
													<td class="text-center">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN LISTING PATIENT -->

					<!-- DEBUT LISTING PRESCRIPTIONS < 1200px -->
					<div class="row d-block d-xl-none pb-5 my-5 position-relative">
						<div class="col-12">
							<!-- <span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0">
									Notifications à traiter
								</span> -->
							<div class="d-flex justify-content-between form-date-increment pe-4">
								<span class="fs-5 mb-0 align-self-center text-medium d-block px-3">
									Notifications à traiter
								</span>
								<div class="flex-grow-1 align-self-center d-block d-sm-none">
									<div class="w-100 border-bottom"></div>
								</div>
								<!-- <button class="btn pin-circle shadow me-2 order-3" aria-label="next">
										<i aria-hidden="true" class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
									</button> -->
							</div>
						</div>
						<div class="col-12">
							<div class="d-flex flex-column flex-sm-row listing-patient w-100 mb-3">
								<div class="flex-fill pe-sm-2 mb-2 mb-sm-0 align-self-end w-100">
									<span class="ps-3">Prescriptions</span>
									<input id="renouveler2" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="renouveler2"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
										<div class="d-flex justify-content-between">
											<span class="text-medium">À signer</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												6
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill mb-2 mb-sm-0 align-self-end w-100">
									<input id="echeance2" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="echeance2"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Bientôt échues</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												23
											</span>
										</div>
									</label>
								</div>
							</div>
							<input
								type="checkbox"
								name="option-popin-notifs"
								id="option-popin-notifs"
								class="popin-filtres accordeon d-block d-sm-none" />
							<label
								for="option-popin-notifs"
								class="btn btn-options pin-circle me-2 d-block d-sm-none"
								aria-label="next">
								<i
									aria-hidden="true"
									class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
							</label>
							<div
								class="d-flex flex-column flex-sm-row listing-patient w-100 mb-3 bloc-listing-patient bloc-listing-patient-sm">
								<div class="flex-fill pe-sm-2 mb-2 mb-sm-0 align-self-end w-100">
									<span class="ps-3">Patients</span>
									<input id="alerte-iah2" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="alerte-iah2"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Alerte IAH</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												8
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill pe-sm-2 mb-2 mb-sm-0 align-self-end w-100">
									<input id="obs-down" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="obs-down"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Baisse d'observance</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												14
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill align-self-end w-100">
									<input id="favoris" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="favoris"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
										<div class="d-flex justify-content-between">
											<span class="text-medium">Favoris</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
												9
											</span>
										</div>
									</label>
								</div>
							</div>
							<!-- <div class="d-flex flex-column flex-sm-row listing-patient w-100 mb-3">
								<div class="flex-fill pe-sm-2 mb-2 mb-sm-0 align-self-end w-100">
									<span class="ps-3">Patients</span>
									<input id="alerte-iah2" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="alerte-iah2"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3"
									>
										<div class="d-flex justify-content-between">
											<span class="text-medium">Alerte IAH</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20"
											>
												8
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill pe-sm-2 mb-2 mb-sm-0 align-self-end w-100">
									<input id="obs-down" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="obs-down"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3"
									>
										<div class="d-flex justify-content-between">
											<span class="text-medium">Baisse d'observance</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20"
											>
												14
											</span>
										</div>
									</label>
								</div>
								<div class="flex-fill align-self-end w-100">
									<input id="favoris" name="" type="checkbox" class="filter-checkbox" />
									<label
										for="favoris"
										class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3"
									>
										<div class="d-flex justify-content-between">
											<span class="text-medium">Favoris</span>
											<span
												class="initiales pin-circle white text-medium align-self-center fs-6 lh-20"
											>
												9
											</span>
										</div>
									</label>
								</div>
							</div> -->
						</div>
						<!-- FILTRES -->
						<div class="col-12 position-relative d-block d-xl-none">
							<div class="d-flex justify-content-between form-date-increment pe-4">
								<span class="fs-5 mb-0 align-self-center text-medium d-block px-3"> Filtres </span>
								<div class="flex-grow-1 align-self-center">
									<div class="w-100 border-bottom"></div>
								</div>
								<!-- <button class="btn pin-circle shadow me-2 order-3" aria-label="next">
									<i aria-hidden="true" class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
								</button> -->
							</div>
							<input
								type="checkbox"
								name="option-popin-filtres"
								id="option-popin-filtres"
								class="popin-filtres accordeon d-block" />
							<label for="option-popin-filtres" class="btn btn-options pin-circle me-2" aria-label="next">
								<i
									aria-hidden="true"
									class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
							</label>
							<div class="row listing-patient bloc-listing-patient">
								<div class="col-12 col-sm-6 mt-2">
									<div
										class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
										<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
											IAH Moyen
										</span>
										<div class="align-self-center">
											<input
												type="checkbox"
												name="option-popin-nom2"
												id="option-popin-nom2"
												class="option-popin d-block" />
											<label for="option-popin-nom2" class="btn btn-options pin-circle ms-2">
												<i
													class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
											</label>
											<div class="option-popin-content text-start shadow bg-white rounded-bottom">
												<div class="px-3 py-2">
													<div class="input-group mb-2">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Supérieur à 10
														</label>
													</div>
													<div class="input-group mb-2">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Entre 5 et 10
														</label>
													</div>
													<div class="input-group mb-2">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Moins de 5
														</label>
													</div>
												</div>
												<div class="row g-0 btn-filters">
													<button class="col-6 btn text-uppercase btn-default text-medium">
														effacer
													</button>
													<button class="col-6 btn text-uppercase text-white text-medium">
														filter
													</button>
												</div>
											</div>
										</div>
									</div>
									<div class="filters-added w-100 pt-2 d-flex">
										<button class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
											<span class="text-gray me-2 text-truncate">Plus de 10</span>
											<i class="icon-closed text-gray fs-5-closed"></i>
										</button>
										<button class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
											<span class="text-gray me-2 text-truncate">Entre 5 et 10 </span>
											<i class="icon-closed text-gray fs-5-closed"></i>
										</button>
									</div>
								</div>
								<div class="col-12 col-sm-6 mt-2">
									<div
										class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
										<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
											Niveau d'observance
										</span>
										<div class="align-self-center">
											<input
												type="checkbox"
												name="option-popin-obs2"
												id="option-popin-obs2"
												class="option-popin d-block" />
											<label for="option-popin-obs2" class="btn btn-options pin-circle ms-2">
												<i
													class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
											</label>
											<div class="option-popin-content text-start shadow bg-white rounded-bottom">
												<div class="p-3">
													<div class="input-group mb-2">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Plus de 6
														</label>
													</div>
													<div class="input-group mb-2">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Entre 4 et 6
														</label>
													</div>
													<div class="input-group mb-2">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Moins de 4
														</label>
													</div>
													<div class="input-group">
														<input
															class="custom-radio"
															id="pmed"
															name="checkbox"
															type="checkbox" />
														<label for="pmed" class="custom-radio position-relative">
															Non transmis
														</label>
													</div>
												</div>
												<div class="row g-0 btn-filters">
													<button class="col-6 btn text-uppercase btn-default text-medium">
														effacer
													</button>
													<button class="col-6 btn text-uppercase text-white text-medium">
														filter
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-6 mt-2">
									<div
										class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
										<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
											Traitement du patient
										</span>
										<div class="align-self-center">
											<input
												type="checkbox"
												name="option-popin-trait"
												id="option-popin-trait"
												class="option-popin d-block" />
											<label for="option-popin-trait" class="btn btn-options pin-circle ms-2">
												<i
													class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
											</label>
											<div class="option-popin-content text-start shadow bg-white rounded-bottom">
												<div class="p-3">
													<form class="mr-auto ml-md-3 my-2 mw-100">
														<div class="input-group">
															<input
																type="text"
																class="form-control rounded-pill bg-white border-1 small py-1 px-3"
																placeholder="Ex: Ventilation continue positive..."
																aria-label="Search"
																aria-describedby="basic-addon2" />
														</div>
													</form>
												</div>
												<div class="row g-0 btn-filters">
													<button class="col-6 btn text-uppercase btn-default text-medium">
														effacer
													</button>
													<button class="col-6 btn text-uppercase text-white text-medium">
														filter
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-6 mt-2">
									<div
										class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
										<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
											Nom | Prénom
										</span>
										<div class="align-self-center">
											<input
												type="checkbox"
												name="option-popin-trait2"
												id="option-popin-trait2"
												class="option-popin d-block" />
											<label for="option-popin-trait2" class="btn btn-options pin-circle ms-2">
												<i
													class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
											</label>
											<div class="option-popin-content text-start shadow bg-white rounded-bottom">
												<div class="p-3">
													<form class="mr-auto ml-md-3 my-2 mw-100">
														<div class="input-group">
															<input
																type="text"
																class="form-control rounded-pill bg-white border-1 small py-1 px-3"
																placeholder="Ex: Jean"
																aria-label="Search"
																aria-describedby="basic-addon2" />
														</div>
													</form>
												</div>
												<div class="row g-0 btn-filters">
													<button class="col-6 btn text-uppercase btn-default text-medium">
														effacer
													</button>
													<button class="col-6 btn text-uppercase text-white text-medium">
														filter
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- CONTENUS -->
						<div class="col-12 mt-4 d-block d-xl-none card-prescriptions">
							<div class="px-2 py-2 shadow bg-white rounded">
								<span class="d-none d-sm-block"
									>Liste de vos patients classés par ordre alphabétiques</span
								>
								<!-- 1st item -->
								<div class="position-relative bg-odd mb-2 rounded">
									<div class="d-flex justify-content-between ps-5 pe-2 py-2">
										<span class="align-self-center fs-6 w-30"
											>Esther <span class="text-medium">Murphy</span></span
										>
										<div class="d-none d-sm-block d-flex lh-14 w-50">
											<span class="w-100 text-truncate">Date de fin de prescription</span>
											<span class="text-medium me-1">28 mars 2022</span>
											<span class="text-light fs-12p">(dans moins de 1 mois)</span>
										</div>
										<div class="d-none d-sm-block w-20">
											<div
												class="me-3 d-flex h-100 flex-column align-items-center justify-content-center">
												<div class="lh-12">
													<i class="rounded-circle p-2 bg-green"></i>
												</div>
												<span class="lh-13 text-center">À jour</span>
											</div>
										</div>
										<button class="align-self-center btn pin-circle order-3">
											<i
												aria-hidden="true"
												class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
									<input
										type="checkbox"
										class="accordeon-1 d-block"
										name="accordeon-tab113"
										id="accordeon-tab113" />
									<label
										for="accordeon-tab113"
										class="d-block btn btn-md-patient pin-circle color-primary">
										<i
											class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
									</label>
									<div class="border border-light bg-white rounded-bottom d-sm-none">
										<div class="w-100 d-flex">
											<div class="align-self-center px-2">
												<i class="icon-date-picker fs-1 text-ultra-light-gray"></i>
											</div>
											<div class="py-2 w-60">
												<span class="d-block w-100">Date de fin de prescription</span>
												<span class="text-medium me-2">23 Mars 2022</span>
												<span class="text-light fs-12p">(Dans moins de 1 mois)</span>
											</div>
											<div class="flex-fill w-20 border-light">
												<div
													class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
													<div>
														<i class="rounded-circle p-2 bg-green"></i>
													</div>
													<span class="text-center">À jour</span>
												</div>
											</div>
										</div>
									</div>
									<div class="accordeon border border-light bg-white rounded-bottom">
										<div class="">
											<div class="w-100 p-2 mb-3 d-none d-sm-block">
												<span class="text-medium d-block">Traitement du patient</span>
												<span class="">Ventilation à pression psitive continue PPC</span>
											</div>

											<div class="d-flex flex-column flex-sm-row mb-2">
												<div
													class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
													<span class="text-regular d-block">Traitement du patient</span>
													<span class="">Ventilation à pression psitive continue PPC</span>
												</div>
												<div
													class="flex-fill border-b border-light position-relative p-2 my-2 my-sm-0 order-3 order-sm-1">
													<div class="mb-2 d-flex flex-column">
														<span class="text-regular">Niveau d'observance</span>
														<span class="text-light fs-12p d-sm-none"
															>Depuis les 3 derniers mois</span
														>
													</div>
													<div class="obs-green d-flex">
														<span class="text-capitalize">Plus de 4 heures</span>
														<div class="align-self-center">
															<i class="icon-level-up ms-2"></i>
														</div>
													</div>
												</div>
												<div
													class="flex-fill border-start p-2 my-2 my-sm-0 d-flex flex-row justify-content-between align-items-sm-center flex-sm-column order-4 order-sm-2">
													<div class="mb-sm-2 d-flex flex-column">
														<span class="text-regular">IAH Moyen</span>
														<span class="text-light fs-12p d-sm-none"
															>Depuis les 3 derniers mois</span
														>
													</div>
													<span class="text-capitalize align-self-center">4.12</span>
												</div>
												<div
													class="flex-fill border-start border-b border-light p-2 my-2 my-sm-0 d-flex flex-column align-items-sm-center order-1 order-sm-3">
													<div class="d-flex justify-sm-content-center">
														<span class="text-regular mb-sm-2">Age</span>
													</div>
													<div class="d-flex justify-sm-content-center">
														<span class="text-capitalize text-medium me-2">66 ans</span>
														<div class="border-start ps-2 align-self-center">
															Née le 11/11/62
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 2nd item -->
								<div class="position-relative bg-odd-darker mb-2 rounded">
									<div class="d-flex justify-content-between ps-5 pe-2 py-2">
										<span class="align-self-center fs-6 w-30"
											>Jean <span class="text-medium">Moore</span></span
										>
										<div class="d-none d-sm-block flex-fill lh-14 w-50">
											<div class="d-flex flex-column h-100 justify-content-center">
												<div class="text-truncate">Date de fin de prescription</div>
												<div class="">
													<span class="text-medium me-1">12 juillet 2022</span>
												</div>
											</div>
										</div>
										<div class="d-none d-sm-block w-20">
											<div
												class="me-3 d-flex flex-column align-items-center justify-content-center">
												<div class="lh-12">
													<i class="rounded-circle p-2 bg-red"></i>
												</div>
												<span class="lh-13 text-red text-medium">À signer</span>
											</div>
										</div>
										<button class="align-self-center btn pin-circle order-3">
											<i
												aria-hidden="true"
												class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
									<input
										type="checkbox"
										class="accordeon-1 d-block"
										name="accordeon-tab111"
										id="accordeon-tab111" />
									<label
										for="accordeon-tab111"
										class="d-block btn btn-md-patient pin-circle color-primary">
										<i
											class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
									</label>
									<div class="border border-light bg-white rounded-bottom d-sm-none">
										<div class="w-100 d-flex">
											<div class="align-self-center px-2">
												<i class="icon-date-picker fs-1 text-ultra-light-gray"></i>
											</div>
											<div class="py-2 w-60">
												<span class="d-block w-100">Date de fin de prescription</span>
												<span class="text-medium me-2">23 Mars 2022</span>
												<span class="text-light fs-12p">(Dans moins de 1 mois)</span>
											</div>
											<div class="flex-fill w-20 border-light">
												<div
													class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
													<div>
														<i class="rounded-circle p-2 bg-red"></i>
													</div>
													<span class="text-regular text-center">À signer</span>
												</div>
											</div>
										</div>
									</div>
									<div class="accordeon border border-light bg-white rounded-bottom">
										<div class="">
											<div class="w-100 p-2 mb-3 d-none d-sm-block">
												<span class="text-medium d-block">Traitement du patient</span>
												<span class="">Ventilation à pression psitive continue PPC</span>
											</div>

											<div class="d-flex flex-column flex-sm-row mb-2">
												<div
													class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
													<span class="text-regular d-block">Traitement du patient</span>
													<span class="">Ventilation à pression psitive continue PPC</span>
												</div>
												<div
													class="flex-fill border-b border-light position-relative p-2 my-2 my-sm-0 order-3 order-sm-1">
													<div class="mb-2 d-flex flex-column">
														<span class="text-regular">Niveau d'observance</span>
														<span class="text-light fs-12p d-sm-none"
															>Depuis les 3 derniers mois</span
														>
													</div>
													<div class="obs-orange d-flex">
														<span class="text-capitalize">Entre 2h et 4 heures</span>
														<div class="align-self-center">
															<i class="icon-stable ms-2"></i>
														</div>
													</div>
												</div>
												<div
													class="flex-fill border-start p-2 my-2 my-sm-0 d-flex flex-row justify-content-between align-items-sm-center flex-sm-column order-4 order-sm-2">
													<div class="mb-sm-2 d-flex flex-column">
														<span class="text-regular">IAH Moyen</span>
														<span class="text-light fs-12p d-sm-none"
															>Depuis les 3 derniers mois</span
														>
													</div>
													<span
														class="text-capitalize align-self-center text-orange text-medium"
														>2.57</span
													>
												</div>
												<div
													class="flex-fill border-start border-b border-light p-2 my-2 my-sm-0 d-flex flex-column align-items-sm-center order-1 order-sm-3">
													<div class="d-flex justify-sm-content-center">
														<span class="text-regular mb-sm-2">Age</span>
													</div>
													<div class="d-flex justify-sm-content-center">
														<span class="text-capitalize text-medium me-2">66 ans</span>
														<div class="border-start ps-2 align-self-center">
															Née le 11/11/62
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 3rd item -->
								<div class="position-relative bg-odd mb-2 rounded">
									<div class="d-flex justify-content-between ps-5 pe-2 py-2">
										<span class="align-self-center fs-6 w-30"
											>Sophienne <span class="text-medium">Aloujenne de Campanna</span></span
										>
										<div class="d-none d-sm-block flex-fill lh-14 w-50">
											<div class="d-flex flex-column h-100 justify-content-center">
												<div class="text-truncate">Date de fin de prescription</div>
												<div class="">
													<span class="text-medium me-1">28 septembre 2022</span>
												</div>
											</div>
										</div>
										<div class="d-none d-sm-block w-20">
											<div
												class="me-3 d-flex h-100 flex-column align-items-center justify-content-center">
												<div class="lh-12">
													<i class="rounded-circle p-2 bg-orange"></i>
												</div>
												<span class="lh-13 text-orange text-center text-regular"
													>Bientôt échue</span
												>
											</div>
										</div>
										<button class="align-self-center btn pin-circle order-3">
											<i
												aria-hidden="true"
												class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</div>
									<input
										type="checkbox"
										class="accordeon-1 d-block"
										name="accordeon-tab112"
										id="accordeon-tab112" />
									<label
										for="accordeon-tab112"
										class="d-block btn btn-md-patient pin-circle color-primary">
										<i
											class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
									</label>
									<div class="border border-light bg-white rounded-bottom d-sm-none">
										<div class="w-100 d-flex">
											<div class="align-self-center px-2">
												<i class="icon-date-picker fs-1 text-ultra-light-gray"></i>
											</div>
											<div class="py-2 w-60">
												<span class="d-block w-100">Date de fin de prescription</span>
												<span class="text-medium me-2">23 Mars 2022</span>
												<span class="text-light fs-12p">(Dans moins de 1 mois)</span>
											</div>
											<div class="flex-fill w-20 border-light">
												<div
													class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
													<div>
														<i class="rounded-circle p-2 bg-orange"></i>
													</div>
													<span class="text-regular text-center">Bientôt échue</span>
												</div>
											</div>
										</div>
									</div>
									<div class="accordeon border border-light bg-white rounded-bottom">
										<div class="">
											<div class="w-100 p-2 mb-3 d-none d-sm-block">
												<span class="text-medium d-block">Traitement du patient</span>
												<span class="">Ventilation à pression psitive continue PPC</span>
											</div>

											<div class="d-flex flex-column flex-sm-row mb-2">
												<div
													class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
													<span class="text-regular d-block">Traitement du patient</span>
													<span class="">Ventilation à pression psitive continue PPC</span>
												</div>
												<div
													class="flex-fill border-b border-light position-relative p-2 my-2 my-sm-0 order-3 order-sm-1">
													<div class="mb-2 d-flex flex-column">
														<span class="text-regular">Niveau d'observance</span>
														<span class="text-light fs-12p d-sm-none"
															>Depuis les 3 derniers mois</span
														>
													</div>
													<div class="obs-red d-flex">
														<span class="text-capitalize">Moins de 2 heures</span>
														<div class="align-self-center">
															<i class="icon-level-down ms-2"></i>
														</div>
													</div>
												</div>
												<div
													class="flex-fill border-start p-2 my-2 my-sm-0 d-flex flex-row justify-content-between align-items-sm-center flex-sm-column order-4 order-sm-2">
													<div class="mb-sm-2 d-flex flex-column">
														<span class="text-regular">IAH Moyen</span>
														<span class="text-light fs-12p d-sm-none"
															>Depuis les 3 derniers mois</span
														>
													</div>
													<span class="text-capitalize align-self-center text-medium text-red"
														>1.12</span
													>
												</div>
												<div
													class="flex-fill border-start border-b border-light p-2 my-2 my-sm-0 d-flex flex-column align-items-sm-center order-1 order-sm-3">
													<div class="d-flex justify-sm-content-center">
														<span class="text-regular mb-sm-2">Age</span>
													</div>
													<div class="d-flex justify-sm-content-center">
														<span class="text-capitalize text-medium me-2">66 ans</span>
														<div class="border-start ps-2 align-self-center">
															Née le 11/11/62
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN LISTING PRESCRIPTIONS -->

					<!-- DEBUT LISTE EQUIPEMENTS WP-658 -->
					<span class="fs-5 text-medium d-block mb-2 mt-3">Matériels utilisés</span>
					<div class="mb-5 d-flex flex-wrap">
						<!-- TRAITEMENT PPC -->
						<!-- <div class="d-flex mb-3 position-relative"> -->
						<div class="carousel-equip mb-3 me-0 me-xl-3 w-md-100">
							<!-- bouttons navigations -->
							<div class="carousel-equip-detail d-sm-none">
								<button
									type="button"
									data-bs-target="#myCarouselEquip"
									data-bs-slide-to="0"
									class="position-absolute prev active btn pin-circle pin-circle-xl"
									aria-label="Prev Slide"
									aria-current="true">
									<i class="icon-pagination-left position-absolute top-50 translate-middle"></i>
								</button>
								<button
									type="button"
									data-bs-target="#myCarouselEquip"
									data-bs-slide-to="1"
									aria-label="Next Slide"
									class="next position-absolute btn pin-circle pin-circle-xl">
									<i class="icon-pagination-right position-absolute top-50 translate-middle"></i>
								</button>
							</div>
							<!-- Equipements pour PPC -->
							<div class="carousel-syn h-100 pb-3 pb-sm-0">
								<div class="p-3 fadeInLeft rounded bg-white shadow h-100">
									<p class="text-medium mb-2">Equipements pour PPC</p>
									<div class="d-flex flex-column flex-md-row">
										<div class="carousel-equip-item active flex-fill">
											<div class="w-100 w-md-100 align-self-stretch">
												<p class="mb-3 text-center text-sm-start">
													Resmed
													<span class="text-regular">AIRSENSE10 AUTOSET</span>
												</p>
												<div class="d-flex flex-column flex-sm-row h-items">
													<div class="pe-2 my-2 align-self-center align-self-sm-start">
														<img
															class="device"
															alt="AIRSENSE10 AUTOSET"
															src="assets/img/devices/RESMED_AIRSENSE10.jpg" />
													</div>
													<div class="px-3 align-self-end w-100 text-center text-sm-start">
														<p class="mb-1">Réglages de l'appareil :</p>
														<p class="text-regular mb-0">Mode&nbsp; Asv</p>
														<ul
															class="ps-4 mb-2 d-flex mh-70 flex-column flex-column-reverse flex-wrap">
															<li class="text-green">
																<span class="text-gray">Pression Max :&nbsp;</span>
																<span class="text-gray text-regular">5</span>
															</li>
															<li class="text-green">
																<span class="text-gray">Pression Min :&nbsp;</span>
																<span class="text-gray text-regular">4</span>
															</li>
															<li class="text-green">
																<span class="text-gray">Pression Max :&nbsp;</span>
																<span class="text-gray text-regular">5</span>
															</li>
															<li class="text-green">
																<span class="text-gray">Pression Min :&nbsp;</span>
																<span class="text-gray text-regular">4</span>
															</li>
														</ul>
														<div
															class="d-flex justify-content-center justify-content-sm-start">
															<div class="me-1">
																<span class="me-1">Pression Min:</span>
																<span class="text-medium valeur">6</span>
															</div>
															<div class="flex-fill border-bottom h-10p"></div>
															<div class="ms-1">
																<span class="me-1"> Max: </span>
																<span class="text-medium valeur">10</span>
															</div>
														</div>
														<p
															class="text-extra-light fs-12p lh-12 mb-0 text-center text-sm-start">
															Valeur en cm/H
														</p>
													</div>
												</div>
											</div>
										</div>
										<!-- <div class="carousel-equip-item flex-fill">
												<div class="w-100 w-md-100 align-self-stretch">
													<p class="mb-3 text-center text-sm-start">
														Philips
														<span _ngcontent-fwf-c211="" class="text-regular">SSD PR1</span>
													</p>
													<div class="d-flex flex-column flex-sm-row h-items">
														<div class="pe-2 my-2 align-self-center align-self-sm-start">
															<img
																class="device"
																alt="AIRSENSE10 AUTOSET"
																src="assets/img/devices/PHILIPS-SSD_PR1.jpg"
															/>
														</div>
														<div
															class="px-3 align-self-end w-100 text-center text-sm-start"
														>
															<p class="mb-1">Réglages de l'appareil :</p>
															<p class="text-regular mb-0">Mode&nbsp; Asv</p>
															<ul
																class="ps-4 mb-2 d-flex flex-column flex-column-reverse flex-wrap"
															>
																<li class="text-green">
																	<span class="text-gray">Pression Max :&nbsp;</span>
																	<span class="text-gray text-regular">5</span>
																</li>
																<li class="text-green">
																	<span class="text-gray">Pression Min :&nbsp;</span>
																	<span class="text-gray text-regular">4</span>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div> -->
									</div>
								</div>
								<div class="carousel-indicators equip d-sm-none">
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="0"
										class="rounded-pill"
										aria-label="Slide 1"
										aria-current="true"></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="1"
										aria-label="Slide 2"
										class="active rounded-pill"></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="2"
										aria-label="Slide 3"
										class="rounded-pill"></button>
								</div>
							</div>
						</div>
						<!-- Consommables pour PPC -->
						<div class="mb-3 mt-xxl-0 position-relative">
							<div class="p-3 fadeInLeft rounded bg-white shadow h-100">
								<span class="text-medium mb-2">Consommables pour PPC</span>
								<div class="d-flex flex-column text-start">
									<div>Masque : <span class="text-regular text-uppercase"> AirFIT F20L </span></div>
									<div class="align-self-start">
										<img
											class="my-1 mx-auto"
											src="assets/img/masks/ALMS_RESPIREO-SOFTCHILD.jpg"
											alt="ALMS_RESPIREO-SOFTCHILD" />
									</div>
								</div>
							</div>
						</div>
						<div class="position-absolute end-0 bottom-15 z-index-1 text-end d-none d-sm-block d-xxl-none">
							<img src="assets/img/devices/bg-materiels.png" alt="matériels" class="wpx-250" />
						</div>
						<!-- </div> -->
						<!-- TRAITEMENT O2 -->
						<!-- <div class="d-flex mb-3 position-relative"> -->
						<div class="carousel-equip flex-fill mb-3 me-0 me-xl-3 w-md-100">
							<!-- bouttons navigations -->
							<div class="carousel-equip-detail d-sm-none">
								<button
									type="button"
									data-bs-target="#myCarouselEquip"
									data-bs-slide-to="0"
									class="position-absolute prev active btn pin-circle pin-circle-xl"
									aria-label="Prev Slide"
									aria-current="true">
									<i class="icon-pagination-left position-absolute top-50 translate-middle"></i>
								</button>
								<button
									type="button"
									data-bs-target="#myCarouselEquip"
									data-bs-slide-to="1"
									aria-label="Next Slide"
									class="next position-absolute btn pin-circle pin-circle-xl">
									<i class="icon-pagination-right position-absolute top-50 translate-middle"></i>
								</button>
							</div>
							<div class="carousel-syn h-100 pb-3 pb-sm-0">
								<div class="p-3 fadeInLeft rounded bg-white shadow h-100">
									<!-- <div class="row"> -->
									<!-- <div class="col-12"> -->
									<p class="text-medium mb-2">Equipements pour O<sub>2</sub></p>
									<!-- </div> -->
									<div class="d-flex flex-column flex-md-row">
										<div class="carousel-equip-item flex-fill active">
											<div class="w-100 w-md-100 align-self-stretch">
												<p class="mb-3 text-center text-sm-start">
													Philips
													<span class="text-regular">SSD EVERFLO</span>
												</p>
												<div class="d-flex flex-column flex-sm-row h-items">
													<div class="pe-2 my-2 align-self-center align-self-sm-start">
														<img
															class="device"
															alt="AIRSENSE10 AUTOSET"
															src="assets/img/devices/PHILIPS-SSD_EVERFLO.jpg" />
													</div>
													<div class="px-3 align-self-end w-100 text-center text-sm-start">
														<p class="mb-1">Réglages de l'appareil :</p>
														<p class="text-regular mb-0">Mode&nbsp; Asv</p>
														<ul
															class="ps-4 mb-2 d-flex flex-column flex-column-reverse flex-wrap">
															<li class="text-green">
																<span class="text-gray">Pression Max :&nbsp;</span>
																<span class="text-gray text-regular">5</span>
															</li>
															<li class="text-green">
																<span class="text-gray">Pression Min :&nbsp;</span>
																<span class="text-gray text-regular">4</span>
															</li>
														</ul>
														<div
															class="d-flex justify-content-center justify-content-sm-start">
															<div class="me-1">
																<span class="me-1">Pression Min:</span>
																<span class="text-medium valeur">6</span>
															</div>
															<div class="flex-fill border-bottom h-10p"></div>
															<div class="ms-1">
																<span class="me-1"> Max: </span>
																<span class="text-medium valeur">10</span>
															</div>
														</div>
														<p
															class="text-extra-light fs-12p lh-12 mb-0 text-center text-sm-start">
															Valeur en cm/H
														</p>
													</div>
												</div>
											</div>
										</div>
										<div class="carousel-equip-item flex-fill">
											<div class="w-100 w-md-100 align-self-stretch">
												<p class="mb-3 text-center text-sm-start">
													Philips
													<span _ngcontent-fwf-c211="" class="text-regular"
														>SSD_TRILOGY-EVO</span
													>
												</p>
												<div class="d-flex flex-column flex-sm-row h-items">
													<div class="pe-2 my-2 align-self-center align-self-sm-start">
														<img
															class="device"
															alt="AIRSENSE10 AUTOSET"
															src="assets/img/devices/PHILIPS-SSD_TRILOGY-EVO.jpg" />
													</div>
													<div class="px-3 align-self-end w-100 text-center text-sm-start">
														<p class="mb-1">Réglages de l'appareil :</p>
														<p class="text-regular mb-0">Mode&nbsp; Asv</p>
														<ul
															class="ps-4 mb-2 d-flex flex-column flex-column-reverse flex-wrap">
															<li class="text-green">
																<span class="text-gray">Pression Max :&nbsp;</span>
																<span class="text-gray text-regular">5</span>
															</li>
															<li class="text-green">
																<span class="text-gray">Pression Min :&nbsp;</span>
																<span class="text-gray text-regular">4</span>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- </div> -->
								</div>
								<div class="carousel-indicators equip d-sm-none">
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="0"
										class="rounded-pill"
										aria-label="Slide 1"
										aria-current="true"></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="1"
										aria-label="Slide 2"
										class="active rounded-pill"></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="2"
										aria-label="Slide 3"
										class="rounded-pill"></button>
								</div>
							</div>
						</div>
						<div class="mb-3 mt-xxl-0 position-relative h-100">
							<div class="p-3 fadeInLeft rounded bg-white shadow">
								<span class="text-medium mb-2">Consommables pour O<sub>2</sub></span>
								<div class="d-flex">
									<div class="d-flex flex-column justify-content-between text-start">
										<div>Masque à oxygène</div>
										<div class="align-self-start">
											<img
												alt="ALMS_RESPIREO-SOFTBABY"
												class="my-1 mx-auto"
												src="assets/img/masks/ALMS_RESPIREO-SOFTBABY.jpg" />
										</div>
									</div>
									<div class="d-flex flex-column justify-content-between text-start">
										<div>Bouteille oxygène 1L</div>
										<div class="align-self-start">
											<img
												class="my-1 mx-auto"
												alt="bouteille-oxygene"
												src="assets/img/masks/bouteille-oxygene.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- </div> -->
						<!-- TRAITEMENT Aerosol -->
						<!-- <div class="d-flex justify-content-between"> -->
						<div class="carousel-syn h-100 d-flex w-sm-100">
							<div class="p-3 fadeInLeft rounded bg-white shadow h-100 w-sm-100">
								<div class="col-12 carousel-equip-item active">
									<span class="text-medium mb-2">Equipements pour Aérosol</span>
									<div class="w-100 w-md-100 align-self-stretch">
										<div class="text-center text-sm-start">
											Pari
											<span class="text-regular text-uppercase"
												>aérosol pneumatique pari boy</span
											>
										</div>
										<div class="d-flex flex-column flex-sm-row h-items">
											<div class="pe-2 my-2 align-self-center align-self-sm-start">
												<img
													class="device"
													alt="AIRSENSE10 AUTOSET"
													src="assets/img/devices/aerosol.png" />
											</div>
											<div
												class="px-3 align-self-end w-100 text-center text-sm-start d-none d-lg-block">
												<p class="mb-1">Réglages de l'appareil :</p>
												<p class="text-regular mb-0">Mode&nbsp; Asv</p>
												<ul class="ps-4 mb-2 d-flex flex-column flex-column-reverse flex-wrap">
													<li class="text-green">
														<span class="text-gray">Pression Max :&nbsp;</span>
														<span class="text-gray text-regular">5</span>
													</li>
													<li class="text-green">
														<span class="text-gray">Pression Min :&nbsp;</span>
														<span class="text-gray text-regular">4</span>
													</li>
													<li class="text-green">
														<span class="text-gray">Pression Moy :&nbsp;</span>
														<span class="text-gray text-regular">4</span>
													</li>
												</ul>
												<p class="text-extra-light fs-12p lh-12 mb-0 text-center text-sm-start">
													Valeur en cm/H
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="align-self-end d-none d-sm-block">
								<img
									src="assets/img/masks/bg-consommables.svg"
									class="w-90 opacity-75"
									alt="consommables" />
							</div>
						</div>
						<div class="align-self-center d-none d-xxl-block">
							<i class="icon-white-logo-elivie fs-info-diverses opacity-75"></i>
						</div>
						<div class="text-end align-self-end d-none d-xxl-block">
							<img src="assets/img/devices/bg-materiels.svg" class="w-90 opacity-75" alt="materiels" />
						</div>
						<!-- </div> -->
					</div>
					<div>
						<!-- OLD DESIGN -->
						<span class="fs-5 text-medium d-block mb-2 mt-3">Consommables</span>
						<div class="row">
							<div class="col-12 carousel-equip">
								<!-- bouttons navigations -->
								<div class="carousel-equip-detail d-sm-none">
									<button
										type="button"
										data-bs-target="#myCarouselEquip"
										data-bs-slide-to="0"
										class="position-absolute prev active btn pin-circle pin-circle-xl"
										aria-label="Prev Slide"
										aria-current="true">
										<i class="icon-pagination-left position-absolute top-50 translate-middle"></i>
									</button>
									<button
										type="button"
										data-bs-target="#myCarouselEquip"
										data-bs-slide-to="1"
										aria-label="Next Slide"
										class="next position-absolute btn pin-circle pin-circle-xl">
										<i class="icon-pagination-right position-absolute top-50 translate-middle"></i>
									</button>
								</div>
								<div class="row carousel-syn position-relative">
									<div class="col-xl-2 col-sm-4 col-12 carousel-equip-item mb-3 active">
										<div class="bg-white mh-285 rounded shadow py-2">
											<div class="d-flex flex-column text-center">
												<span>Masque :</span>
												<span class="text-regular text-uppercase"> AirFIT F20L </span>
												<img
													alt="ALMS_RESPIREO-SOFTCHILD"
													class="my-1 mx-auto"
													src="assets/img/masks/ALMS_RESPIREO-SOFTCHILD.jpg" />
												<span class="fs-12p">Date de livraison :</span>
												<span class="text-regular">12/07/22 </span>
												<span class="fs-12p">(1 mois et 23 jours)</span>
											</div>
										</div>
									</div>
									<div class="col-xl-2 col-sm-4 col-12 carousel-equip-item mb-3">
										<div class="bg-white mh-285 rounded shadow py-2">
											<div class="d-flex flex-column text-center">
												<span>Masque :</span>
												<span class="text-regular text-uppercase"> Dreamweaver </span>
												<img
													alt="Lowenstein_CARA"
													class="my-1 mx-auto"
													src="assets/img/masks/Lowenstein_CARA-nasal.jpg" />
												<span class="fs-12p">Date de livraison :</span>
												<span class="text-regular">19/08/22 </span>
												<span class="fs-12p">(3 mois et 23 jours)</span>
											</div>
										</div>
									</div>
									<div class="col-xl-2 col-sm-4 col-12 carousel-equip-item mb-3">
										<div class="bg-white mh-285 rounded shadow py-2">
											<div class="d-flex flex-column text-center">
												<span>Bouteille de gaz</span>
												<span class="text-regular text-uppercase"> Capacité 20L </span>
												<img class="my-2 mx-auto" src="assets/img/oxygen.png" alt="oxygen" />
												<span class="fs-12p">Date de livraison :</span>
												<span class="text-regular">03/04/22 </span>
												<span class="fs-12p">(2 mois et 1 jours)</span>
											</div>
										</div>
									</div>
									<div class="col-xl-2 col-sm-4 col-12 carousel-equip-item mb-3">
										<div class="bg-white mh-285 rounded shadow py-2">
											<div class="d-flex flex-column text-center">
												<span>Masque : </span>
												<span class="text-regular text-uppercase"> Airfit F20L </span>
												<img
													class="my-2 mx-auto"
													src="assets/img/masks/mask_default.png"
													alt="mask_default" />
												<span class="fs-12p">Date de livraison :</span>
												<span class="text-regular">03/11/22 </span>
												<span class="fs-12p">(4 mois et 11 jours)</span>
											</div>
										</div>
									</div>
									<div class="col-xl-3 col-sm-5 d-none d-md-block position-relative">
										<div class="position-absolute bottom-0" style="left: -10px">
											<img
												src="assets/img/devices/img-consommables.png"
												class="img-fluid"
												alt="consommables" />
										</div>
									</div>
									<div class="carousel-indicators equip d-sm-none">
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="0"
											class="rounded-pill"
											aria-label="Slide 1"
											aria-current="true"></button>
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="1"
											aria-label="Slide 2"
											class="active rounded-pill"></button>
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="2"
											aria-label="Slide 3"
											class="rounded-pill"></button>
									</div>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-center mt-5">
							<button class="btn btn-primary text-uppercase">Retour fiche patient</button>
						</div>
						<!-- END OLD DESIGN -->
					</div>

					<!-- FIN LISTE EUQUIPEMENTS WP-658 -->

					<!-- DEBUT - PAS DE MESSAGERIE > 1200px -->
					<div class="row py-3 d-none d-xl-block">
						<div class="col-12 mt-5">
							<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0">
								Pas encore de messages envoyés à Elivie ?
							</span>
						</div>

						<div class="col-12">
							<div class="row">
								<div class="d-none d-lg-block fs-wide-screen">
									<div class="bg-white position-relative rounded shadow p-3 overflow-hidden">
										<div class="w-60 position-relative z-index-2">
											<p>À ce jour vous n’avez pas encore de messages à Elivie.</p>
											<p class="mb-0">
												Si vous souhaitez nous contacter n’hésitez pas à utiliser l’espace dédié
												à cet effet ou à téléphoner à votre agent de terrain privilégié :
											</p>
											<div class="d-flex justify-content-between">
												<div class="d-flex align-items-center text-color-primary">
													<i class="icon-xs-screen fs-3 me-2"></i>
													<div class="d-flex flex-column">
														<span>Y. BONGARS</span>
														<span class="text-medium lh-12">06 17 75 68 76 45</span>
													</div>
												</div>
												<div class="d-flex">
													<button class="btn btn-circle">
														<span class="pin-circle btn pin-circle-xl">
															<i
																class="icon-message fs-4 position-absolute top-50 start-50 translate-middle"></i>
														</span>
														<span
															class="rounded-pill border color-primary border-2 py-1 px-3 ps-5 margin-left-m40 text-uppercase text-medium">
															Contacter Elivie
														</span>
													</button>
												</div>
											</div>
											<p>
												Dans cette page, vous retrouverez l’ensemble des messages qui vous nous
												enverrez. Tout sera détaillé, l’objet, la date et le contenu de l’envoi.
												.
											</p>
										</div>
										<div
											class="position-absolute end-0 bottom-0 z-index-1 text-end d-none d-sm-block">
											<img
												src="assets/img/bg-no-messages.png"
												class="img-fluid w-70"
												alt="no-messages" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN - PAS DE MESSAGERIE -->

					<!-- DEBUT MESSAGERIE > 1200px -->
					<div class="row position-relative py-3 d-none d-xl-block">
						<!-- Bloc nouvelles fonctionnalités -->
						<div class="w-100 h-100">
							<div class="d-flex justify-content-center text-center">
								<span class="rounded pill ps-1 pt-1 pe-1 bg-color-primary">
									<i class="icon-mouse text-white fs-4"></i>
								</span>
							</div>
							<input type="checkbox" class="mouse-hover" name="m-hover" id="m-hover" />
							<label for="m-hover" class="btn text-center d-block mx-auto"
								>Découvrez les fonctionnalités de la messagerie Elivie</label
							>
							<div
								class="position-absolute z-index-99 mh-470 w-100 h-100 mouse-gradient opacity-75"></div>
						</div>
						<!-- FIN Bloc nouvelles fonctionnalités -->
						<div class="col-12 mt-5">
							<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0"> Vos messages </span>
						</div>

						<div class="col-12 mt-1">
							<span class="d-block mb-3 mb-sm-2 ps-3 ps-lg-0 text-medium"> Liste de vos messages </span>
						</div>

						<div class="col-12">
							<div class="row">
								<div class="listing-patient table-responsive-lg d-none d-lg-block fs-wide-screen">
									<div class="bg-white table-rounded pb-3 px-3 pt-1">
										<table class="table table-striped align-middle">
											<thead class="thead-primary">
												<tr>
													<th colspan="2" class="text-left w-15">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center asc active">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span
																class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
																Date
															</span>
														</div>
													</th>
													<th class="text-left w-20">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center desc">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span
																class="text-medium fs-7 align-self-center lh-17 flew-grow-1 flex-fill"
																>Objet du message</span
															>
														</div>
													</th>
													<th class="text-left">
														<div
															class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100">
															<button
																class="btn pin-circle me-2 position-relative align-self-center asc active">
																<i
																	class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<span
																class="flew-grow-1 flex-fill text-medium fs-7 align-self-center lh-17"
																>Contenu du message</span
															>
														</div>
													</th>
													<th class="text-center w-15">
														<span class="d-block text-medium fs-7">Statut</span>
														<div class="d-flex justify-content-center">
															<span class="text-nowrap text-center"> Messages </span>
														</div>
													</th>
													<th class="text-center w-8">
														<span class="d-block text-medium fs-7">Actions</span>
														<div class="d-flex justify-content-between">
															<span class="text-nowrap text-center"> Renvoyer </span>
															<span class="text-nowrap text-center"> Supprimer </span>
														</div>
													</th>
												</tr>
												<tr></tr>
											</thead>
											<tbody>
												<!-- 1er élément -->
												<tr class="alert" role="alert">
													<td class="w-5 rounded-start align-top pt-4">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td class="align-top pt-4">
														<div class="d-flex">
															<div class="flex-grow-1 align-self-center text-medium">
																12/02/22
															</div>
														</div>
													</td>
													<td class="text-start align-top pt-4">Problème sur prescription</td>

													<td class="position-relative align-top pt-4">
														<div class="d-flex justify-content-between text-start">
															<div class="flex-grow-1">
																<span class="text-truncate w-85"
																	>Lorem ipsime dolor sit amet transectectuer carpe
																	quam minima credula postero......</span
																>
															</div>
														</div>
														<input
															type="checkbox"
															class="accordeon-1 d-block"
															name="accordeon-tab22"
															id="accordeon-tab22" />
														<label
															for="accordeon-tab22"
															class="d-block text text-light text-color-primary text-decoration-underline"
															>Lire la suite</label
														>
														<div class="accordeon">
															<table class="table align-middle mb-0">
																<tbody>
																	<!-- 1er élément -->
																	<tr class="" role="">
																		<td class="p-0">
																			What is Lorem Ipsum? Lorem Ipsum is simply
																			dummy text of the printing and typesetting
																			industry. Lorem Ipsum has been the
																			industry's standard dummy text ever since
																			the 1500s, when an unknown printer took a
																			galley of type and scrambled it to make a
																			type specimen book. It has survived not only
																			five centuries, but also the leap into
																			electronic typesetting, remaining
																			essentially unchanged. It was popularised in
																			the 1960s with the release of Letraset
																			sheets containing Lorem Ipsum passages, and
																			more recently with desktop publishing
																			software like Aldus PageMaker including
																			versions of Lorem Ipsum. Why do we use it?
																			It is a long established fact that a reader
																			will be distracted by the readable content
																			of a page when looking at its layout. The
																			point of using Lorem Ipsum is that it has a
																			more-or-less normal distribution of letters,
																			as opposed to using 'Content here, content
																			here', making it look like readable English.
																			Many desktop publishing packages and web
																			page editors now use Lorem Ipsum as their
																			default model text, and a search for 'lorem
																			ipsum' will uncover many web sites still in
																			their infancy. Various versions have evolved
																			over the years, sometimes by accident,
																			sometimes on purpose (injected humour and
																			the like).
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
													<td class="align-top">
														<div
															class="ms-3 h-100 pt-2 d-flex flex-column align-items-center justify-content-center">
															<div>
																<i class="rounded-circle p-2 bg-green"></i>
															</div>
															<span class="text-center">Reçu</span>
														</div>
													</td>
													<td class="text-center align-top pt-4">
														<div class="d-flex justify-content-between px-2">
															<button class="btn disabled pin-circle">
																<i
																	class="icon-forward position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>
												<!-- 2er élément -->
												<tr class="alert" role="alert">
													<td class="w-5 rounded-start align-top pt-4">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td class="align-top pt-4">
														<div class="d-flex">
															<div class="flex-grow-1 align-self-center text-medium">
																12/02/22
															</div>
														</div>
													</td>
													<td class="text-start align-top pt-4">Problème sur prescription</td>

													<td class="position-relative align-top pt-4">
														<div class="d-flex justify-content-between text-start">
															<div class="flex-grow-1">
																<span class="text-truncate w-85"
																	>Lorem ipsime dolor sit amet transectectuer carpe
																	quam minima credula postero......</span
																>
															</div>
														</div>
														<input
															type="checkbox"
															class="accordeon-1 d-block"
															name="accordeon-tab122"
															id="accordeon-tab122" />
														<label
															for="accordeon-tab122"
															class="d-block text text-light text-color-primary text-decoration-underline">
															Lire la suite
														</label>
														<div class="accordeon">
															<table class="table align-middle mb-0">
																<tbody>
																	<!-- 1er élément -->
																	<tr class="" role="">
																		<td class="p-0">
																			What is Lorem Ipsum? Lorem Ipsum is simply
																			dummy text of the printing and typesetting
																			industry. Lorem Ipsum has been the
																			industry's standard dummy text ever since
																			the 1500s, when an unknown printer took a
																			galley of type and scrambled it to make a
																			type specimen book. It has survived not only
																			five centuries, but also the leap into
																			electronic typesetting, remaining
																			essentially unchanged. It was popularised in
																			the 1960s with the release of Letraset
																			sheets containing Lorem Ipsum passages, and
																			more recently with desktop publishing
																			software like Aldus PageMaker including
																			versions of Lorem Ipsum. Why do we use it?
																			It is a long established fact that a reader
																			will be distracted by the readable content
																			of a page when looking at its layout. The
																			point of using Lorem Ipsum is that it has a
																			more-or-less normal distribution of letters,
																			as opposed to using 'Content here, content
																			here', making it look like readable English.
																			Many desktop publishing packages and web
																			page editors now use Lorem Ipsum as their
																			default model text, and a search for 'lorem
																			ipsum' will uncover many web sites still in
																			their infancy. Various versions have evolved
																			over the years, sometimes by accident,
																			sometimes on purpose (injected humour and
																			the like).
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
													<td class="align-top">
														<div
															class="ms-3 h-100 pt-2 d-flex flex-column align-items-center justify-content-center">
															<div>
																<i class="rounded-circle p-2 bg-orange"></i>
															</div>
															<span class="text-center">En cours...</span>
														</div>
													</td>
													<td class="text-center align-top pt-4">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-forward position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>

												<!-- 3er élément -->
												<tr class="alert" role="alert">
													<td class="w-5 rounded-start align-top pt-4">
														<label class="checkbox-wrap checkbox-primary custom-checkbox">
															<input type="checkbox" />
														</label>
													</td>
													<td class="align-top pt-4">
														<div class="d-flex">
															<div class="flex-grow-1 align-self-center text-medium">
																12/02/22
															</div>
														</div>
													</td>
													<td class="text-start align-top pt-4">Problème sur prescription</td>

													<td class="position-relative align-top pt-4">
														<div class="d-flex justify-content-between text-start">
															<div class="flex-grow-1">
																<span class="text-truncate w-85"
																	>Lorem ipsime dolor sit amet transectectuer carpe
																	quam minima credula postero......</span
																>
															</div>
														</div>
														<input
															type="checkbox"
															class="accordeon-1 d-block"
															name="accordeon-tab322"
															id="accordeon-tab322" />
														<label
															for="accordeon-tab322"
															class="d-block text text-light text-color-primary text-decoration-underline"
															>Lire la suite</label
														>
														<div class="accordeon">
															<table class="table align-middle mb-0">
																<tbody>
																	<!-- 1er élément -->
																	<tr class="" role="">
																		<td class="p-0">
																			What is Lorem Ipsum? Lorem Ipsum is simply
																			dummy text of the printing and typesetting
																			industry. Lorem Ipsum has been the
																			industry's standard dummy text ever since
																			the 1500s, when an unknown printer took a
																			galley of type and scrambled it to make a
																			type specimen book. It has survived not only
																			five centuries, but also the leap into
																			electronic typesetting, remaining
																			essentially unchanged. It was popularised in
																			the 1960s with the release of Letraset
																			sheets containing Lorem Ipsum passages, and
																			more recently with desktop publishing
																			software like Aldus PageMaker including
																			versions of Lorem Ipsum. Why do we use it?
																			It is a long established fact that a reader
																			will be distracted by the readable content
																			of a page when looking at its layout. The
																			point of using Lorem Ipsum is that it has a
																			more-or-less normal distribution of letters,
																			as opposed to using 'Content here, content
																			here', making it look like readable English.
																			Many desktop publishing packages and web
																			page editors now use Lorem Ipsum as their
																			default model text, and a search for 'lorem
																			ipsum' will uncover many web sites still in
																			their infancy. Various versions have evolved
																			over the years, sometimes by accident,
																			sometimes on purpose (injected humour and
																			the like).
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</td>
													<td class="align-top">
														<div
															class="ms-3 h-100 pt-2 d-flex flex-column align-items-center justify-content-center">
															<div>
																<i class="rounded-circle p-2 bg-red"></i>
															</div>
															<span class="text-center text-medium">Non transmis</span>
														</div>
													</td>
													<td class="text-center align-top pt-4">
														<div class="d-flex justify-content-between px-2">
															<button class="btn pin-circle">
																<i
																	class="icon-forward position-absolute top-50 start-50 translate-middle"></i>
															</button>
															<button class="btn pin-circle">
																<i
																	class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
															</button>
														</div>
													</td>
												</tr>

												<!-- 4er élément -->
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN MESSAGERIE -->

					<div
						class="d-none d-sm-block d-lg-none btn-anchor shadow rounded-pill border-0 py-1 ps-3 d-flex text-color-primary justify-content-between bg-white">
						<span class="me-3 text-medium">Haut de page</span>

						<button type="button" (click)="goToHeadOfPage()" class="btn pin-circle shadow">
							<i class="icon-pagination-up position-absolute top-50 start-50 translate-middle"></i>
						</button>
					</div>
					<!-- WP-578 -->
					<div class="col-12 mt-5 pb-5">
						<div class="row">
							<div class="col">
								<div class="rounded bg-white shadow mb-5 p-3 pe-5 position-relative">
									<p>
										&Aacute; ce jour, vous exercez dans
										<span class="text-medium">5 établissements</span> diff&eacute;rents.
									</p>
									<span class="w-80">
										Merci de choisir quelle liste de patients vous souhaitez parcourir aujourd'hui.
										Vous pouvez retrouver vos patientèles dans la rubrique Préférences
										<i class="icon-preferences"></i>
									</span>
									<div class="position-absolute top-50 end-0 translate-middle opacity-25">
										<span class="icon-logo-elivie fs-3"
											><span class="path1"></span><span class="path2"></span
											><span class="path3"></span><span class="path4"></span
											><span class="path5"></span><span class="path6"></span
											><span class="path7"></span><span class="path8"></span
										></span>
									</div>
								</div>
							</div>
						</div>
						<div class="row list-patientele">
							<div class="col-xs-12 position-relative col-sm-4 col-xxl-2">
								<input
									type="checkbox"
									class="select-patientele"
									name="accordeon-pat1"
									id="accordeon-pat1" />
								<label for="accordeon-pat1" class="card cs-pointer bg-white mh-175 mh-md-213">
									<span class="card-body py-lg-0 p-md-2">
										<span class="d-flex flex-column justify-content-between mh-165 bd-highlight">
											<span class="p-2 flex-grow-1 d-flex align-self-center fs-info opacity-25">
												<i class="icon-patients-hospitalises align-self-center"></i>
											</span>
											<span class="px-2 align-self-center bd-highlight">
												<span class="mt-2 mb-1 fs-20 d-flex flex-sm-column">
													<span class="text-capitalize text-center me-2 text-ultra-light">
														hôpital
													</span>
													<span class="text-medium text-center text-nowrap">subtitle</span>
												</span>
											</span>
										</span>
									</span>
								</label>

								<div class="accordeon col-3 position-relative card overflow-none bg-white shadow p-3">
									Veuillez choisir quelle(s) patientèle(s) observer :
									<div class="d-flex flex-column mt-3">
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-1"
												name="checkbox"
												type="checkbox" />
											<label for="check-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-2"
												name="checkbox"
												type="checkbox" />
											<label for="check-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-3"
												name="checkbox"
												type="checkbox" />
											<label for="check-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-4"
												name="checkbox"
												type="checkbox" />
											<label for="check-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-5"
												name="checkbox"
												type="checkbox" />
											<label for="check-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-1"
												name="checkbox"
												type="checkbox" />
											<label for="check-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-2"
												name="checkbox"
												type="checkbox" />
											<label for="check-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-3"
												name="checkbox"
												type="checkbox" />
											<label for="check-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-4"
												name="checkbox"
												type="checkbox" />
											<label for="check-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-5"
												name="checkbox"
												type="checkbox" />
											<label for="check-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-1"
												name="checkbox"
												type="checkbox" />
											<label for="check-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-2"
												name="checkbox"
												type="checkbox" />
											<label for="check-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-3"
												name="checkbox"
												type="checkbox" />
											<label for="check-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-4"
												name="checkbox"
												type="checkbox" />
											<label for="check-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-5"
												name="checkbox"
												type="checkbox" />
											<label for="check-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-1"
												name="checkbox"
												type="checkbox" />
											<label for="check-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-2"
												name="checkbox"
												type="checkbox" />
											<label for="check-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-3"
												name="checkbox"
												type="checkbox" />
											<label for="check-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-4"
												name="checkbox"
												type="checkbox" />
											<label for="check-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-5"
												name="checkbox"
												type="checkbox" />
											<label for="check-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-1"
												name="checkbox"
												type="checkbox" />
											<label for="check-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-2"
												name="checkbox"
												type="checkbox" />
											<label for="check-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-3"
												name="checkbox"
												type="checkbox" />
											<label for="check-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-4"
												name="checkbox"
												type="checkbox" />
											<label for="check-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="check-5"
												name="checkbox"
												type="checkbox" />
											<label for="check-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
									</div>
									<button
										type="submit"
										class="btn btn-primary btn-patientele rounded-pill text-uppercase py-2 px-4">
										<span class="text-medium">Valider la sélection</span>
									</button>
								</div>
								<span
									class="nb-patientele z-index-101 pin-circle white text-medium align-self-center fs-6 lh-20 w-20">
									6
								</span>
							</div>
							<div class="col-xs-12 position-relative col-sm-4 col-xxl-2">
								<input
									type="checkbox"
									class="select-patientele"
									name="accordeon-pat2"
									id="accordeon-pat2" />
								<label for="accordeon-pat2" class="card cs-pointer bg-white mh-175 mh-md-213">
									<span class="card-body py-lg-0 p-md-2">
										<span class="d-flex flex-column justify-content-between mh-165 bd-highlight">
											<span class="p-2 flex-grow-1 d-flex align-self-center fs-info opacity-25">
												<i class="icon-clinique align-self-center"></i>
											</span>
											<span class="px-2 align-self-center bd-highlight">
												<span class="mt-2 mb-1 fs-20 d-flex flex-sm-column">
													<span class="text-capitalize text-center me-2 text-ultra-light">
														Clinique
													</span>
													<span class="text-medium text-center"
														>Part-Dieu - Dr. Duchemin / Gandet / molllet</span
													>
												</span>
											</span>
										</span>
									</span>
								</label>

								<div class="accordeon position-relative card overflow-none bg-white shadow p-3">
									Veuillez choisir quelle(s) patientèle(s) observer :
									<div class="d-flex flex-column mt-3">
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="ccheck-1"
												name="checkbox"
												type="checkbox" />
											<label for="ccheck-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="ccheck-2"
												name="checkbox"
												type="checkbox" />
											<label for="ccheck-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="ccheck-3"
												name="checkbox"
												type="checkbox" />
											<label for="ccheck-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="ccheck-4"
												name="checkbox"
												type="checkbox" />
											<label for="ccheck-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="ccheck-5"
												name="checkbox"
												type="checkbox" />
											<label for="ccheck-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
									</div>
									<button
										type="submit"
										class="btn btn-primary btn-patientele rounded-pill text-uppercase py-2 px-4">
										<span class="text-medium">Valider la sélection</span>
									</button>
								</div>
							</div>
							<div class="col-xs-12 position-relative col-sm-4 col-xxl-2">
								<input
									type="checkbox"
									class="select-patientele"
									name="accordeon-pat3"
									id="accordeon-pat3" />
								<label for="accordeon-pat3" class="card cs-pointer bg-white mh-175 mh-md-213">
									<span class="card-body py-lg-0 p-md-2">
										<span class="d-flex flex-column justify-content-between mh-165 bd-highlight">
											<span class="p-2 flex-grow-1 d-flex align-self-center fs-info opacity-25">
												<i class="icon-cabinet align-self-center"></i>
											</span>
											<span class="px-2 align-self-center bd-highlight">
												<span class="mt-2 mb-1 fs-20 d-flex flex-sm-column">
													<span class="text-capitalize text-center me-2 text-ultra-light">
														Cabinet
													</span>
													<span class="text-medium text-center"
														>Lyon - Cabinet de Dr. Bettencourt / Rondait / Bolloré /
														Archamps - Cours Vuiton</span
													>
												</span>
											</span>
										</span>
									</span>
								</label>

								<div class="accordeon position-relative card overflow-none bg-white shadow p-3">
									Veuillez choisir quelle(s) patientèle(s) observer :
									<div class="d-flex flex-column mt-3">
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-1"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-2"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-3"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-4"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-5"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
									</div>
									<button
										type="submit"
										class="btn btn-primary btn-patientele rounded-pill text-uppercase py-2 px-4">
										<span class="text-medium">Valider la sélection</span>
									</button>
								</div>
							</div>
							<div class="col-xs-12 position-relative col-sm-4 col-xxl-2">
								<input
									type="checkbox"
									class="select-patientele"
									name="accordeon-pat4"
									id="accordeon-pat4" />
								<label for="accordeon-pat4" class="card cs-pointer bg-white mh-175 mh-md-213">
									<span class="card-body py-lg-0 p-md-2">
										<span class="d-flex flex-column justify-content-between mh-165 bd-highlight">
											<span class="p-2 flex-grow-1 d-flex align-self-center fs-info opacity-25">
												<i class="icon-cabinet align-self-center"></i>
											</span>
											<span class="px-2 align-self-center bd-highlight">
												<span class="mt-2 mb-1 fs-20 d-flex flex-sm-column">
													<span class="text-capitalize text-center me-2 text-ultra-light">
														Cabinet
													</span>
													<span class="text-medium text-center text-nowrap"
														>Lyon - Hôtel de Ville</span
													>
												</span>
											</span>
										</span>
									</span>
								</label>

								<div class="accordeon position-relative card overflow-none bg-white shadow p-3">
									Veuillez choisir quelle(s) patientèle(s) observer :
									<div class="d-flex flex-column mt-3">
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-11"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-11" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-22"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-22" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-33"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-33" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-44"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-44" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-55"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-55" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
									</div>
									<button
										type="submit"
										class="btn btn-primary btn-patientele rounded-pill text-uppercase py-2 px-4">
										<span class="text-medium">Valider la sélection</span>
									</button>
								</div>
							</div>
							<div class="col-xs-12 position-relative col-sm-4 col-xxl-2">
								<input
									type="checkbox"
									class="select-patientele"
									name="accordeon-pat5"
									id="accordeon-pat5" />
								<label for="accordeon-pat5" class="card cs-pointer bg-white mh-175 mh-md-213">
									<span class="card-body py-lg-0 p-md-2">
										<span class="d-flex flex-column justify-content-between mh-165 bd-highlight">
											<span class="p-2 flex-grow-1 d-flex align-self-center fs-info opacity-25">
												<i class="icon-cabinet align-self-center"></i>
											</span>
											<span class="px-2 align-self-center bd-highlight">
												<span class="mt-2 mb-1 fs-20 d-flex flex-sm-column">
													<span class="text-capitalize text-center me-2 text-ultra-light">
														Cabinet
													</span>
													<span class="text-medium text-center text-nowrap"
														>Lyon - Cours Vuiton</span
													>
												</span>
											</span>
										</span>
									</span>
								</label>

								<div class="accordeon position-relative card overflow-none bg-white shadow p-3">
									Veuillez choisir quelle(s) patientèle(s) observer :
									<div class="d-flex flex-column mt-3">
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-1"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-1" class="custom-checkbox position-relative">
												Votre patientèle
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-2"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-2" class="custom-checkbox position-relative">
												Dr Aribary Josette
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-3"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-3" class="custom-checkbox position-relative">
												Dr Bernard Patrick
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-4"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-4" class="custom-checkbox position-relative">
												Dr Cerisola Denis
											</label>
										</div>
										<div class="d-flex py-1">
											<input
												class="custom-checkbox"
												id="cccheck-5"
												name="checkbox"
												type="checkbox" />
											<label for="cccheck-5" class="custom-checkbox position-relative">
												Dr Duchemin Philippe
											</label>
										</div>
									</div>
									<button
										type="submit"
										class="btn btn-primary btn-patientele rounded-pill text-uppercase py-2 px-4">
										<span class="text-medium">Valider la sélection</span>
									</button>
								</div>
							</div>
						</div>
						<div class="d-flex position-relative z-index-102 justify-content-center">
							<button
								class="btn btn-primary bg-color-primary text-white fs-5 rounded-pill text-uppercase py-3 px-5">
								<span class="text-medium">Voir les patients</span>
							</button>
						</div>
					</div>
					<!-- ## END ## WP-578 -->
					<!-- ## END ## WP-581 -->
					<div class="col-12 mt-5">
						<div class="row mb-3">
							<div class="col-12">
								<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0"
									>Préférences de votre compte
								</span>
							</div>
							<div class="col-xs-12 col-lg-6">
								<div class="rounded bg-white shadow z-index-101">
									<div class="p-3">
										<form>
											<title>Changer mon mot de passe</title>
											<p class="fs-6 text-medium">Changer mon mot de passe</p>
											<div class="d-flex">
												<div class="flex-fill form-group fs-16 mb-2 label-xs">
													<label for="inputPassword1" class="mb-1">Mot de passe actuel</label>
													<div class="position-relative">
														<input
															id="inputPassword1"
															formControlName="userDisplayName"
															type="password"
															class="form-control rounded-pill bg-white border-2 small"
															aria-label="Password" />
														<div
															class="input-group-show position-absolute end-0 top-50 translate-middle">
															<button class="btn p-0" type="button" title="search">
																<i class="icon-see-unsee fs-26 mt-1"></i>
															</button>
														</div>
													</div>
												</div>
												<div class="flex-fill ps-3 form-group fs-16 mb-2 label-xs">
													<label for="inputPassword2" class="mb-1"
														>Nouveau mot de passe *</label
													>
													<div class="position-relative">
														<input
															id="inputPassword2"
															formControlName="userDisplayName"
															type="password"
															class="form-control rounded-pill bg-white border-2 small"
															aria-label="Password" />
														<div
															class="input-group-show position-absolute end-0 top-50 translate-middle">
															<button class="btn p-0" type="button" title="search">
																<i class="icon-see-unsee fs-26 mt-1"></i>
															</button>
														</div>
													</div>
												</div>
												<div class="ms-3 ps-3 wpx-30 position-relative">
													<button class="btn p-0 position-absolute top-50 translate-middle">
														<img
															alt="switch-on-disabled"
															src="../../../assets/img/switch-on-disabled.svg"
															class="wpx-30 mt-2" />
													</button>
												</div>
											</div>
											<p class="fs-12p lh-12">
												* Votre mot de passe doit contenir 8 caractères minimum avec un chiffre
												et une majuscule.
											</p>
											<div class="d-flex justify-content-end">
												<button class="btn btn-primary text-uppercase">
													Je change mon mot de passe
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="col-xs-12 col-lg-6 mt-3 mt-lg-0">
								<div class="rounded bg-white shadow h-100 z-index-101">
									<div class="p-3 h-100 position-relative">
										<p class="fs-6 text-medium">Demande de changement d'identifiant</p>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
											faucibus justo eget purus vulputate porta. Praesent dui tortor, tempus ac
											erat iaculis, molestie pulvinar ex. Nullam sed dapibus lacus, in commodo
											turpis. Praesent dui tortor, tempus ac erat iaculis, molestie pulvinar ex.
											Nullam sed dapibus lacus, in commodo turpis.
										</p>
										<div class="bottom-0 end-0 position-absolute">
											<button
												class="btn btn-primary bg-color-primary text-link text-uppercase mb-3 me-3">
												Je fais une demande
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<span class="fs-5 text-medium d-block mt-3 mb-3 mb-sm-2 ps-3 ps-lg-0"
									>Partage de votre patientèle
								</span>
							</div>
							<div class="col-xs-12 col-sm-6 col-xl-3">
								<div class="rounded bg-white shadow mh-175 position-relative z-index-101">
									<div class="px-3 pt-3">
										<p class="fs-6 text-medium text-capitalize">établissement</p>
										<div class="d-flex mb-4">
											<i class="icon-patients-hospitalises fs-info color-primary me-2"></i>
											<div class="align-self-end lh-14">
												<span class="text-capitalize w-100">hôpital</span>
												<span class="text-medium">Lyon Sud - HCL</span>
											</div>
										</div>
										<p class="fs-6 text-medium text-capitalize">partages</p>
										<div
											class="d-flex flex-column justify-content-around h-100 bd-highlight partage">
											<div class="d-flex shared bd-highlight border-bottom">
												<div class="p-2 align-self-center bd-highlight select-patient">
													<i class="icon-docteur-femme fs-3"></i>
												</div>
												<div class="p-0 align-self-center flex-grow-1 bd-highlight">
													<div class="content lh-18 pe-0 ps-1">
														<span class="text-ultra-light">Docteur</span>
														<p class="mb-0 fs-6">
															<span class="text-bold"
																>Huguettes
																<span class="text-uppercase">Berthes</span></span
															>
														</p>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														checked
														role="switch"
														id="flexSwitchCheckDefault" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault"></label>
												</div>
											</div>
											<div class="d-flex unshared bd-highlight border-bottom">
												<div class="p-2 align-self-center bd-highlight select-patient">
													<i class="icon-docteur-homme fs-3"></i>
												</div>
												<div class="p-0 align-self-center flex-grow-1 bd-highlight">
													<div class="content lh-18 pe-0 ps-1">
														<span class="text-ultra-light">Docteur</span>
														<p class="mb-0 fs-6">
															<span class="text-bold"
																>Victor <span class="text-uppercase">DAZARI</span></span
															>
														</p>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault2" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault2"></label>
												</div>
											</div>
											<div class="d-flex unshared bd-highlight border-bottom">
												<div class="p-2 align-self-center bd-highlight select-patient">
													<i class="icon-docteur-femme fs-3"></i>
												</div>
												<div class="p-0 align-self-center flex-grow-1 bd-highlight">
													<div class="content lh-18 pe-0 ps-1">
														<span class="text-ultra-light">Docteur</span>
														<p class="mb-0 fs-6">
															<span class="text-bold"
																>Sabrina <span class="text-uppercase">NATAF</span></span
															>
														</p>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault3" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault3"></label>
												</div>
											</div>
										</div>
									</div>
									<div class="py-3 bg-odd rounded-bottom border-top d-flex justify-content-center">
										<button class="btn btn-primary text-uppercase">Gestion de partage</button>
									</div>
								</div>
								<div class="card bg-white shadow my-3 mh-55">card-1</div>
								<div class="card bg-white shadow my-3 mh-125">card-1</div>
							</div>
							<div class="col-xs-12 col-sm-6 col-xl-3">
								<div class="rounded bg-white shadow mh-175 position-relative z-index-101">
									<div class="px-3 pt-3">
										<p class="fs-6 text-medium text-capitalize">établissement</p>
										<div class="d-flex mb-4">
											<i class="icon-clinique fs-info color-primary me-2"></i>
											<div class="align-self-end lh-14">
												<span class="text-capitalize w-100">Clinique</span>
												<span class="text-medium">Part DieuL</span>
											</div>
										</div>
										<p class="fs-6 text-medium text-capitalize">partages</p>
										<div
											class="d-flex flex-column justify-content-around h-100 bd-highlight partage">
											<div class="d-flex unshared bd-highlight border-bottom">
												<div class="p-2 align-self-center bd-highlight select-patient">
													<i class="icon-docteur-femme fs-3"></i>
												</div>
												<div class="p-0 align-self-center flex-grow-1 bd-highlight">
													<div class="content lh-18 pe-0 ps-1">
														<span class="text-ultra-light">Docteur</span>
														<p class="mb-0 fs-6">
															<span class="text-bold"
																>Violette
																<span class="text-uppercase">Agranda</span></span
															>
														</p>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault4" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault4"></label>
												</div>
											</div>
										</div>
									</div>
									<div class="py-3 bg-odd rounded-bottom border-top d-flex justify-content-center">
										<button class="btn btn-primary text-uppercase">Gestion de partage</button>
									</div>
								</div>
								<div class="card bg-white shadow my-3 mh-155">card-2</div>
							</div>
							<div class="col-xs-12 col-sm-6 col-xl-3">
								<div class="rounded bg-white shadow mh-175 position-relative z-index-101">
									<div class="px-3 pt-3">
										<p class="fs-6 text-medium text-capitalize">établissement</p>
										<div class="d-flex mb-4">
											<i class="icon-cabinet fs-info color-primary me-2"></i>
											<div class="align-self-end lh-14">
												<span class="text-capitalize w-100">Cabinet</span>
												<span class="text-medium">Le parc</span>
											</div>
										</div>
										<p class="fs-6 text-medium text-capitalize">partages</p>
										<div
											class="d-flex flex-column justify-content-around h-100 bd-highlight partage">
											<div class="d-flex unshared bd-highlight border-bottom">
												<div class="p-2 align-self-center bd-highlight select-patient">
													<i class="icon-docteur-homme fs-3"></i>
												</div>
												<div class="p-0 align-self-center flex-grow-1 bd-highlight">
													<div class="content lh-18 pe-0 ps-1">
														<span class="text-ultra-light">Docteur</span>
														<p class="mb-0 fs-6">
															<span class="text-bold"
																>Denis
																<span class="text-uppercase"> Cerisola</span></span
															>
														</p>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault7" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault7"></label>
												</div>
											</div>
										</div>
									</div>
									<div class="py-3 bg-odd rounded-bottom border-top d-flex justify-content-center">
										<button class="btn btn-primary text-uppercase">Gestion de partage</button>
									</div>
								</div>
								<div class="card bg-white shadow mh-125 my-3">card-3</div>
							</div>
							<div class="col-xs-12 col-sm-6 col-xl-3">
								<button class="card btn bg-white shadow p-3">
									<div class="d-flex w-100 justify-content-center flex-column">
										<span
											class="text-uppercase text-medium color-primary fs-6 align-self-center mb-3">
											changer de patientèle</span
										>
										<div class="align-self-center d-flex">
											<div class="d-flex flex-column">
												<i class="icon-patients-hospitalises fs-info opacity-25"></i>
												<span class="text-ultra-light">Hôpital</span>
											</div>
											<i class="icon-selection align-self-center mx-2"></i>
											<div class="d-flex flex-column">
												<i class="icon-clinique fs-info opacity-25"></i>
												<span class="text-ultra-light">Clinique</span>
											</div>
											<i class="icon-selection align-self-center mx-2"></i>
											<div class="d-flex flex-column">
												<i class="icon-cabinet fs-info opacity-25"></i>
												<span class="text-ultra-light">Cabinet</span>
											</div>
										</div>
									</div>
								</button>
								<div class="rounded z-index-3 position-relative bg-odd border border-2 p-3 my-3">
									<div class="position-relative mh-121">
										<div class="text-light-gray">
											<span class="text-medium text-uppercase text-center w-100"
												>Ajouter un nouvel établissement</span
											>
											<span class="text-gray"
												>Un nouveau lieu d'activité à ajouter pour votre patientèle</span
											>
										</div>
										<button
											class="btn btn-primary text-uppercase position-absolute w-90 bottom-0 start-50 translate-middle-x">
											Ajouter un établissement
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col-12 z-index-3">
								<span class="fs-5 text-medium d-block mb-3 mb-sm-2 ps-3 ps-lg-0"
									>Gestion des cookies
								</span>
							</div>
							<div class="col-xs-12 col-lg-6 z-index-3">
								<div class="rounded bg-white shadow p-3">
									<p>
										Pour détecter des problèmes de conception sur notre site, dialoguer avec vous et
										construire notre relation client, nous utilisons 3 solutions éprouvées et
										respectueuses de vos données
									</p>
									<form>
										<div class="d-flex justify-content-between">
											<span class="text-medium">Tout cocher </span>
											<div class="align-self-center form-chec form-switch">
												<input
													class="form-check-input"
													type="checkbox"
													checked
													role="switch"
													id="flexSwitchCheckDefault" />
												<label class="form-check-label" for="flexSwitchCheckDefault"></label>
											</div>
										</div>
										<div class="border mt-2 border-1 rounded">
											<div class="d-flex justify-content-between border-bottom p-2">
												<div class="align-self-center flex-fill d-flex">
													<img alt="hotjar-logo" src="../../../assets/img/hotjar-logo.svg" />
													<div class="d-flex flex-column ps-1">
														<span class="text-capitalize text-medium">Hotjar</span>
														<span class="">Enregistrement du parcours de navigation</span>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														checked
														role="switch"
														id="flexSwitchCheckDefault" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault"></label>
												</div>
											</div>
											<div class="d-flex justify-content-between border-bottom p-2">
												<div class="align-self-center flex-fill d-flex">
													<img alt="ga-logo" src="../../../assets/img/ga-logo.svg" />
													<div class="d-flex flex-column ps-1">
														<span class="text-capitalize text-medium"
															>Google Analytics</span
														>
														<span class=""
															>Enregistrement de l'audience du site internet</span
														>
													</div>
												</div>
												<div class="align-self-center form-chec form-switch">
													<input
														class="form-check-input"
														type="checkbox"
														checked
														role="switch"
														id="flexSwitchCheckDefault" />
													<label
														class="form-check-label"
														for="flexSwitchCheckDefault"></label>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<!-- ## END ## WP-580 -->
					<div class="col-12 my-5 position-relative z-index-101">
						<p class="fs-4 text-center mb-5">Nouvelle adhésion au programme d’apprentissage</p>
						<div class="mx-auto wpx-373">
							<div
								class="rounded bg-white shadow d-flex flex-column p-3-2 row-gap-3 justify-content-center">
								<span class="fs-5-6">Télécharger</span>
								<span class="fs-5"
									>Vous pouvez également télécharger le formulaire vierge pour le remplir, en 3
									exemplaires.</span
								>
								<button class="btn btn-primary bg-color-primary text-link text-uppercase">
									<i class="icon-download me-2"></i>
									Télécharger
								</button>
							</div>
						</div>
					</div>
					<!-- WP-578 -->
					<div
						class="position-absolute start-0 bottom-0 z-index-1 text-end d-none d-sm-block"
						style="left: -690px !important">
						<img
							src="assets/img/dashboard/illus-dde-adhesion.svg"
							alt="dde-adhesion"
							class="img-fluid w-70" />
					</div>
					<div
						class="position-absolute end-0 bottom-0 z-index-1 text-end d-none d-sm-block"
						style="right: -85px !important">
						<img
							src="assets/img/dashboard/illus-dde-adhesion-2.svg"
							alt="hotjar-logo"
							class="img-fluid w-70" />
					</div>
					<!-- ## END ## WP-578 -->
				</div>
			</div>
		</div>

		<!-- <div class="anchor-btn position-fixed rounded-pill shadow d-flex align-items-center px-2 py-1">
            <div class="position-relative pe-5">
                <span>Haut de page</span>
                <div class="btn-haut-page position-absolute end-0">
                    <button 
                        (click)="goToHeadOfPage()"
                        class="btn btn-popover pin-circle position-relative"
                    >
                        <i class="icon-pagination-up position-absolute top-50 start-50 translate-middle"></i>
                    </button>
                </div>
            </div>
        </div> -->
	</div>
</div>
