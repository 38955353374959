import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Data } from "@angular/router";
import { AppConfigService } from "./app-config.service";

@Injectable({
	providedIn: "root",
})
export class SeoService {
	private _appDisplayName!: string;

	constructor(
		appConfigService: AppConfigService,
		private _title: Title,
		private _meta: Meta
	) {
		this._appDisplayName = appConfigService.appConfig.appDisplayName;
	}

	updateFromData(eventData: Data, params: string[]): void {
		if (eventData["title"]) this.updateTitle(eventData["title"], params);
		if (eventData["description"]) this.updateDescription(eventData["description"]);
	}

	updateTitle(title: string, params: string[] = []): void {
		const formattedTitle = this.formatString(title, params);
		this._title.setTitle(`${formattedTitle} | ${this._appDisplayName}`);
	}

	updateDescription(desc: string): void {
		this._meta.updateTag({ name: "description", content: desc });
	}

	private formatString(value: string, args: string[]): string {
		if (args.length === 0) return value;

		return value.replace(/{(\d+)}/g, function (match, number) {
			return typeof args[number] != "undefined" ? args[number] : match;
		});
	}
}
