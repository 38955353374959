<div class="row position-relative py-3 d-none d-xl-block listing-prescriber-message">
	<ng-container *ngIf="rowList$ | async as rowList; else loading">
		<div class="col-12" *ngIf="rowList.hasItems && !isOnError; else messageListEmpty">
			<div class="d-none d-xl-block">
				<div>
					<app-table-standard
						[isLoadingResults]="isLoadingResults"
						[isOnError]="isOnError"
						[items]="rowList.items"
						[nbTotalFilteredItems]="rowList.nbTotalFilteredItems"
						[nbTotalItems]="rowList.nbTotalItems"
						noRowLabel="Aucun message trouvé"
						paginationLabel="Sélectionner la page des messages"
						[pageSizeOptions]="[20, 50, 100, 200, 500]"
						[tableView]="tableView"
						(tableChange)="reloadTable($event)">
					</app-table-standard>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #messageListEmpty>
		<div class="col-12">
			<app-prescriber-messaging-no-message> </app-prescriber-messaging-no-message>
		</div>
	</ng-template>
</div>

<ng-template #loading>
	<div class="col-12 listing-patients">
		<div class="d-none d-xl-block">
			<div>
				<div class="table-responsive-lg listing-patient">
					<div class="bg-white table-rounded pb-3 px-3 pt-1 position-relative">
						<app-table-skeleton [tableView]="tableView"></app-table-skeleton>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<div class="row d-block d-xl-none pb-5 position-relative">
	<div class="col-12 fs-1 my-4">
		<div class="text-light lh-1">Vos</div>
		<div class="text-medium">Messages</div>
	</div>
	<div class="col-12">
		<app-prescriber-message-card></app-prescriber-message-card>
	</div>
</div>

<!-- Composant à créer à part >> DEBUT LISTING PRESCRIPTIONS < 1200px -->
<ng-container *ngIf="onPreviewModeChange$ | async as isPreviewMode">
	<div class="row d-block d-xl-none pb-5 position-relative">
		<div class="col-12 listing-patients">
			<div class="d-flex justify-content-between form-date-increment pe-4">
				<span class="fs-5 mb-3 mb-sm-2 align-self-center text-medium d-block px-3">
					Notifications à traiter
				</span>
			</div>
		</div>
		<div class="col-12">
			<div class="d-flex flex-column flex-sm-row listing-patient w-100 mb-3">
				<div class="flex-fill pe-sm-2 mb-2 mb-sm-0 align-self-end w-100">
					<span class="ps-3 pb-2 pb-sm-1">Prescriptions</span>
					<input id="renouveler2" name="" type="checkbox" class="filter-checkbox" />
					<label
						for="renouveler2"
						class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
						<div class="d-flex justify-content-between">
							<span class="text-medium">À signer</span>
							<span class="initiales pin-circle white text-medium align-self-center fs-6 lh-20"> 6 </span>
						</div>
					</label>
				</div>
				<div class="flex-fill mb-2 mb-sm-0 align-self-end w-100">
					<input id="echeance2" name="" type="checkbox" class="filter-checkbox" />
					<label
						for="echeance2"
						class="btn btn-default btn-activate-filtre w-100 bg-white rounded shadow position-relative px-3">
						<div class="d-flex justify-content-between">
							<span class="text-medium">Bientôt échues</span>
							<span class="initiales pin-circle white text-medium align-self-center fs-6 lh-20">
								23
							</span>
						</div>
					</label>
				</div>
			</div>
		</div>
		<!-- FILTRES -->
		<div class="col-12 position-relative d-block d-xl-none">
			<div class="d-flex justify-content-between form-date-increment pe-4">
				<span class="fs-5 mb-0 align-self-center text-medium d-block px-3"> Filtres </span>
				<div class="flex-grow-1 align-self-center">
					<div class="w-100 border-bottom"></div>
				</div>
			</div>
			<input
				type="checkbox"
				name="option-popin-filtres"
				id="option-popin-filtres"
				class="popin-filtres accordeon d-block" />
			<label for="option-popin-filtres" class="btn btn-options pin-circle me-2" aria-label="next">
				<i aria-hidden="true" class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
			</label>
			<div class="row mt-2 listing-patient bloc-listing-patient">
				<div class="col-12 col-sm-6 mt-2">
					<div class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
						<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
							Nom | Prénom
						</span>
						<div class="align-self-center">
							<input
								type="checkbox"
								name="option-popin-trait2"
								id="option-popin-trait2"
								class="option-popin d-block" />
							<label for="option-popin-trait2" class="btn btn-options pin-circle ms-2">
								<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
							</label>
							<div class="option-popin-content text-start shadow bg-white rounded-bottom">
								<div class="p-3">
									<form class="mr-auto ml-md-3 my-2 mw-100">
										<div class="input-group">
											<input
												type="text"
												class="form-control rounded-pill bg-white border-1 small py-1 px-3"
												placeholder="Ex: Jean"
												aria-label="Search"
												aria-describedby="basic-addon2" />
										</div>
									</form>
								</div>
								<div class="row g-0 btn-filters">
									<button class="col-6 btn text-uppercase btn-default text-medium">effacer</button>
									<button class="col-6 btn text-uppercase text-white text-medium">filter</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-6 mt-2">
					<div class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
						<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
							Traitement du patient
						</span>
						<div class="align-self-center">
							<input
								type="checkbox"
								name="option-popin-trait"
								id="option-popin-trait"
								class="option-popin d-block" />
							<label for="option-popin-trait" class="btn btn-options pin-circle ms-2">
								<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
							</label>
							<div class="option-popin-content text-start shadow bg-white rounded-bottom">
								<div class="p-3">
									<form class="mr-auto ml-md-3 my-2 mw-100">
										<div class="input-group">
											<input
												type="text"
												class="form-control rounded-pill bg-white border-1 small py-1 px-3"
												placeholder="Ex: Ventilation continue positive..."
												aria-label="Search"
												aria-describedby="basic-addon2" />
										</div>
									</form>
								</div>
								<div class="row g-0 btn-filters">
									<button class="col-6 btn text-uppercase btn-default text-medium">effacer</button>
									<button class="col-6 btn text-uppercase text-white text-medium">filter</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-6 mt-2">
					<div class="d-flex justify-content-between bg-white rounded p-2 px-3 shadow position-relative">
						<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
							Type de prescription
						</span>
						<div class="align-self-center">
							<input
								type="checkbox"
								name="option-popin-obs2"
								id="option-popin-obs2"
								class="option-popin d-block" />
							<label for="option-popin-obs2" class="btn btn-options pin-circle ms-2">
								<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
							</label>
							<div class="option-popin-content text-start shadow bg-white rounded-bottom">
								<div class="p-3">
									<div class="input-group mb-2">
										<input class="custom-checkbox" id="pmed" name="checkbox" type="checkbox" />
										<label for="pmed" class="custom-radio position-relative"> A jour </label>
									</div>
									<div class="input-group mb-2">
										<input class="custom-checkbox" id="pmed" name="checkbox" type="checkbox" />
										<label for="pmed" class="custom-radio position-relative"> A signer </label>
									</div>
									<div class="input-group mb-2">
										<input class="custom-checkbox" id="pmed" name="checkbox" type="checkbox" />
										<label for="pmed" class="custom-radio position-relative"> Bientôt échue </label>
									</div>
								</div>
								<div class="row g-0 btn-filters">
									<button class="col-6 btn text-uppercase btn-default text-medium">effacer</button>
									<button class="col-6 btn text-uppercase text-white text-medium">filter</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- CONTENUS -->
		<div class="col-12 mt-4 d-block d-sm-none">
			<span class="pb-2 ps-3">Liste de vos patients classés par ordre alphabétiques</span>
		</div>
		<div class="col-12 mt-1 mt-sm-4 d-block d-xl-none card-prescriptions">
			<div class="p-3 mt-1 shadow bg-white rounded">
				<span class="d-none d-sm-block pb-2">Liste de vos patients classés par ordre alphabétiques</span>
				<!-- 1st item -->
				<div class="position-relative bg-odd mb-2 rounded">
					<div class="d-flex justify-content-between ps-5 pe-2 py-2">
						<span class="align-self-center fs-6 w-30">Esther <span class="text-medium">Murphy</span></span>
						<div class="d-none d-sm-block d-flex lh-14 w-50">
							<span class="w-100 text-truncate">Date de fin de prescription</span>
							<span class="text-medium me-1">28 mars 2022</span>
							<span class="text-light fs-12p">(dans moins de 1 mois)</span>
						</div>
						<div class="d-none d-sm-block w-20">
							<div class="me-3 d-flex h-100 flex-column align-items-center justify-content-center">
								<div class="lh-12">
									<i class="rounded-circle p-2 bg-green"></i>
								</div>
								<span class="lh-13 text-center">À jour</span>
							</div>
						</div>
						<button class="align-self-center btn pin-circle order-3">
							<i
								aria-hidden="true"
								class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
						</button>
					</div>
					<input type="checkbox" class="accordeon-1 d-block" name="accordeon-tab113" id="accordeon-tab113" />
					<label for="accordeon-tab113" class="d-block btn btn-md-patient pin-circle">
						<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
					</label>
					<!-- CONTENU SMARTPHONE -->
					<div class="border border-light bg-white rounded-bottom d-sm-none">
						<div class="w-100 d-flex">
							<div class="align-self-center px-2">
								<i class="icon-date-picker fs-1 text-ultra-light-gray"></i>
							</div>
							<div class="py-2 w-60">
								<span class="d-block w-100">Date de fin de prescription</span>
								<span class="text-medium me-2">23 Mars 2022</span>
								<span class="text-light fs-12p">(Dans moins de 1 mois)</span>
							</div>
							<div class="flex-fill w-20 border-light">
								<div
									class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
									<div>
										<i class="rounded-circle p-2 bg-green"></i>
									</div>
									<span class="text-center">À jour</span>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN CONTENU SMARTPHONE -->
					<div class="accordeon border border-light bg-white rounded-bottom">
						<div class="">
							<div class="d-flex flex-column flex-sm-row my-sm-2 mt-2">
								<!-- CONTENU SMARTPHONE -->
								<div
									class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
									<span class="text-regular d-block">Traitement du patient</span>
									<span class="">Ventilation à pression psitive continue PPC</span>
								</div>
								<div class="flex-fill border-light d-flex flex-column order-2 p-2 d-sm-none">
									<span class="text-medium d-block">Type de prescription</span>
									<span class="">Ordonnance</span>
								</div>
								<!-- FIN CONTENU SMARTPHONE -->
								<div class="flex-fill w-50 border-light p-2 my-2 my-sm-0 d-none d-sm-block">
									<span class="text-regular d-block">Traitement du patient</span>
									<span class="">Ventilation à pression psitive continue PPC</span>
								</div>
								<div class="flex-fill w-50 border-start p-2 my-2 my-sm-0 d-none d-sm-block">
									<span class="text-medium d-block">Type de prescription</span>
									<span class="">Ordonnance</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 2nd item -->
				<div class="position-relative bg-odd-darker mb-2 rounded">
					<div class="d-flex justify-content-between ps-5 pe-2 py-2">
						<span class="align-self-center fs-6 w-30">Jean <span class="text-medium">Moore</span></span>
						<div class="d-none d-sm-block flex-fill lh-14 w-50">
							<div class="d-flex flex-column h-100 justify-content-center">
								<div class="text-truncate">Date de fin de prescription</div>
								<div class="">
									<span class="text-medium me-1">12 juillet 2022</span>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block w-20">
							<div class="me-3 d-flex flex-column align-items-center justify-content-center">
								<div class="lh-12">
									<i class="rounded-circle p-2 bg-red"></i>
								</div>
								<span class="lh-13 text-medium">À signer</span>
							</div>
						</div>
						<button class="align-self-center btn pin-circle order-3">
							<i
								aria-hidden="true"
								class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
						</button>
					</div>
					<input type="checkbox" class="accordeon-1 d-block" name="accordeon-tab111" id="accordeon-tab111" />
					<label for="accordeon-tab111" class="d-block btn btn-md-patient pin-circle blue">
						<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
					</label>
					<div class="border border-light bg-white rounded-bottom d-sm-none">
						<div class="w-100 d-flex">
							<div class="align-self-center px-2">
								<i class="icon-date-picker fs-1 text-ultra-light-gray"></i>
							</div>
							<div class="py-2 w-60">
								<span class="d-block w-100">Date de fin de prescription</span>
								<span class="text-medium me-2">23 Mars 2022</span>
								<span class="text-light fs-12p">(Dans moins de 1 mois)</span>
							</div>
							<div class="flex-fill w-20 border-light">
								<div
									class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
									<div>
										<i class="rounded-circle p-2 bg-red"></i>
									</div>
									<span class="text-regular text-center">À signer</span>
								</div>
							</div>
						</div>
					</div>
					<div class="accordeon border border-light bg-white rounded-bottom">
						<div class="">
							<div class="d-flex flex-column flex-sm-row my-sm-2 mt-2">
								<!-- CONTENU SMARTPHONE -->
								<div
									class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
									<span class="text-regular d-block">Traitement du patient</span>
									<span class="">Ventilation à pression psitive continue PPC</span>
								</div>
								<div class="flex-fill border-light d-flex flex-column order-2 p-2 d-sm-none">
									<span class="text-medium d-block">Type de prescription</span>
									<span class="">Ordonnance</span>
								</div>
								<!-- FIN CONTENU SMARTPHONE -->
								<div class="flex-fill w-50 border-light p-2 my-2 my-sm-0 d-none d-sm-block">
									<span class="text-regular d-block">Traitement du patient</span>
									<span class="">Ventilation à pression psitive continue PPC</span>
								</div>
								<div class="flex-fill w-50 border-start p-2 my-2 my-sm-0 d-none d-sm-block">
									<span class="text-medium d-block">Type de prescription</span>
									<span class="">Ordonnance</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 3rd item -->
				<div class="position-relative bg-odd mb-2 rounded">
					<div class="d-flex justify-content-between ps-5 pe-2 py-2">
						<span class="align-self-center fs-6 w-30"
							>Sophienne <span class="text-medium">Aloujenne de Campanna</span></span
						>
						<div class="d-none d-sm-block flex-fill lh-14 w-50">
							<div class="d-flex flex-column h-100 justify-content-center">
								<div class="text-truncate">Date de fin de prescription</div>
								<div class="">
									<span class="text-medium me-1">28 septembre 2022</span>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block w-20">
							<div class="me-3 d-flex h-100 flex-column align-items-center justify-content-center">
								<div class="lh-12">
									<i class="rounded-circle p-2 bg-orange"></i>
								</div>
								<span class="lh-13 text-center text-regular">Bientôt échue</span>
							</div>
						</div>
						<button class="align-self-center btn pin-circle order-3">
							<i
								aria-hidden="true"
								class="icon-editer position-absolute top-50 start-50 translate-middle"></i>
						</button>
					</div>
					<input type="checkbox" class="accordeon-1 d-block" name="accordeon-tab112" id="accordeon-tab112" />
					<label for="accordeon-tab112" class="d-block btn btn-md-patient pin-circle blue">
						<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
					</label>
					<div class="border border-light bg-white rounded-bottom d-sm-none">
						<div class="w-100 d-flex">
							<div class="align-self-center px-2">
								<i class="icon-date-picker fs-1 text-ultra-light-gray"></i>
							</div>
							<div class="py-2 w-60">
								<span class="d-block w-100">Date de fin de prescription</span>
								<span class="text-medium me-2">23 Mars 2022</span>
								<span class="text-light fs-12p">(Dans moins de 1 mois)</span>
							</div>
							<div class="flex-fill w-20 border-light">
								<div
									class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
									<div>
										<i class="rounded-circle p-2 bg-orange"></i>
									</div>
									<span class="text-regular text-center">Bientôt échue</span>
								</div>
							</div>
						</div>
					</div>
					<div class="accordeon border border-light bg-white rounded-bottom">
						<div class="">
							<div class="d-flex flex-column flex-sm-row my-sm-2 mt-2">
								<!-- CONTENU SMARTPHONE -->
								<div
									class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
									<span class="text-regular d-block">Traitement du patient</span>
									<span class="">Ventilation à pression psitive continue PPC</span>
								</div>
								<div class="flex-fill border-light d-flex flex-column order-2 p-2 d-sm-none">
									<span class="text-medium d-block">Type de prescription</span>
									<span class="">Ordonnance</span>
								</div>
								<!-- FIN CONTENU SMARTPHONE -->
								<div class="flex-fill w-50 border-light p-2 my-2 my-sm-0 d-none d-sm-block">
									<span class="text-regular d-block">Traitement du patient</span>
									<span class="">Ventilation à pression psitive continue PPC</span>
								</div>
								<div class="flex-fill w-50 border-start p-2 my-2 my-sm-0 d-none d-sm-block">
									<span class="text-medium d-block">Type de prescription</span>
									<span class="">Ordonnance</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
