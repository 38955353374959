import { Component, Input } from "@angular/core";
import { TableCellMessageStatus } from "src/app/@shared/types/table/table-view";
import { MessageStatus } from "src/app/constants";

@Component({
	selector: "app-table-cell-message-status",
	templateUrl: "table-cell-message-status.component.html",
})
export class TableCellMessageStatusComponent {
	@Input() item?: TableCellMessageStatus;

	getMessageDisplayStatus(status: string): string {
		switch (status) {
			case MessageStatus.needTreatment:
				return "À traiter";
			case MessageStatus.inProgress:
				return "Pris en charge";
			case MessageStatus.treated:
				return "Traité";
			default:
				return status;
		}
	}

	getMessageStatusClass(status: string): string {
		switch (status) {
			case MessageStatus.needTreatment:
				return "prescription-red";
			case MessageStatus.inProgress:
				return "prescription-orange";
			case MessageStatus.treated:
				return "prescription-green";
			default:
				return status;
		}
	}
}
