<!-- contenu global du composant -->
<div id="content-wrapper" class="d-flex flex-column">
	<div id="content">
		<!-- navigation du composant -->
		<nav class="navbar navbar-expand navbar-light topbar mb-1 mb-sm-0 static-top pb-0 pb-lg-2">
			<div class="row ms-1">
				<div class="col-6 col-xs-6 col-lg-3 order-lg-1 px-0 my-2">
					<span class="fs-4 mb-1 d-block">Bienvenue,</span>
					<span class="fs-4 text-bold">Dr Sylvain Mathieu</span>
					<div class="d-flex position-absolute nb-etablissement">
						<div class="btn pin-circle pt-1 text-medium fs-14">2</div>
						<span class="align-slef-center pt-1 mx-1">Patientèles : </span>
						<button class="btn p-0 text-decoration-underline align-slef-center">2 établissements</button>
					</div>
				</div>
				<div class="col-6 col-xs-6 col-lg-4 order-lg-3 px-0 my-2">
					<div class="row align-items-center align-items-end mx-0">
						<div class="col-xs-6 d-none d-sm-inline-block px-0 col-sm-10">
							<ul class="list-entete-r1 ps-0 pe-2 mt-0 mx-auto">
								<li class="nav-item dropdown no-arrow text-end mb-1 d-flex justify-content-end">
									<div class="align-self-center pb-1">
										<a
											rel="noopener"
											target="_blank"
											class="fs-4 icon-logo-elivie"
											href="https://www.elivie.fr/"
											title="Aller sur le site d'elivie">
											<span class="path1"></span><span class="path2"></span>
											<span class="path3"></span><span class="path4"></span>
											<span class="path5"></span><span class="path6"></span>
											<span class="path7"></span><span class="path8"></span>
										</a>
									</div>
									<div class="ms-1 align-self-center text-gray border-start">
										<span class="ms-2 text-capitalize text-medium fs-5">05 17 17 70 03</span>
									</div>
								</li>
								<li class="text-end fs-18 ng-star-inserted">
									<span
										class="text-capitalize text-gray border-end me-1 pe-1 d-none d-lg-inline-block">
										A.NAXXXXXN
									</span>
									<span class="text-capitalize d-none d-lg-inline-block">05 17 17 70 03</span>
									<div class="select">
										<select
											class="form-select2 fs-10p text-gray border-bottom"
											aria-label="Default select example">
											<option selected>Voir ses interlocuteurs</option>
											<option value="1">Yves BONGARS | 06 17 17 70 03</option>
											<option value="2">Anne Nault | 06 17 17 70 03</option>
											<option value="3">Océane RIGOLLET | 06 17 17 70 03</option>
										</select>
									</div>
								</li>
							</ul>
						</div>
						<div class="col-sm-4 col-xs-6 px-0 d-sm-none ng-star-inserted">
							<ul
								class="list-entete-r2 px-0 my-0 mx-auto text-start fs-2 text-end d-flex align-items-end">
								<li class="d-none d-sm-inline-block nav-item col-6 ng-star-inserted">
									<div class="d-inline-block position-relative">
										<a href=""
											><i class="icon-chart"></i
											><span
												class="info-b info-b-down bg-primary p-2 shadow text-light fadeInRight text-start"
												>Rapport d'observance</span
											></a
										>
									</div>
								</li>
								<li class="nav-item nav-item-contact d-inline-block ps-2 col position-relative">
									<a href="" class="pin-circle pin-circle-xl shadow z-index-1 cursor-pointer">
										<i class="icon-contact"></i
										><span class="info-b bg-primary p-2 shadow text-light fadeInRight text-nowrap"
											>Contacter <span class="text-capitalize">elivie</span></span
										></a
									>
								</li>
								<li class="nav-item nav-item-contact d-inline-block ps-2 col position-relative">
									<a href="" class="pin-circle pin-circle-xl shadow z-index-1 cursor-pointer">
										<i class="icon-burger-xs-menu fs-4"></i>
									</a>
								</li>
							</ul>
						</div>
						<div class="col-sm-2 col-xs-6 px-0 ng-star-inserted">
							<div
								class="menu-right rounded bg-white shadow py-2 px-3 position-fixed d-none d-sm-block z-index-112">
								<ul class="components p-0 m-0">
									<li class="position-relative">
										<a
											class="btn-bulle cursor-pointer d-flex align-self-center my-2 text-decoration-none cursor-pointer"
											><i class="icon-contact fs-4"></i
											><span
												class="info-b bg-primary p-2 shadow text-light fadeInRight text-nowrap">
												Contacter <span class="text-capitalize">elivie</span></span
											></a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-5 order-lg-2 px-0 my-2">
					<form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 mw-100 navbar-search">
						<div class="input-group z-index-4">
							<input
								type="text"
								class="form-control rounded-pill bg-white border-2 small shadow fs-16"
								placeholder="Chercher un patient, une prescription..."
								aria-label="Search"
								aria-describedby="basic-addon2" />
							<div class="input-group-search z-index-99">
								<button class="btn btn-hover rounded-end rounded-5 border-2" type="button">
									<i class="icon-search fs-4"></i>
								</button>
							</div>
						</div>
						<p class="text-sm-end text-start adv-search pt-2"><a href="#">Recherche avancée</a></p>
					</form>
				</div>
			</div>
		</nav>
		<!-- contenu du composant -->
		<div class="container-fluid">
			<div class="row position-relative">
				<!-- #################             BEGIN NEW DESIGN DASHBOARD				 ################# -->

				<div class="col-12 col-xl-6 mb-3 mb-xl-0 order-1 order-xl-1 position-relative z-index-3 mt-4 mt-sm-0">
					<!-- Les prescriptions -->
					<div class="row gx-3 gx-xl-4 mb-5">
						<div class="col-12">
							<span class="fs-5 text-medium d-block mb-2">Les prescriptions</span>
						</div>
						<div class="col-6 col-sm-4 pb-3 pb-sm-0">
							<div
								class="card border border-1 card-indicateur pt-2 pb-4 h-100 border-left-primary bg-primary bg-hover cs-pointer bg-trait">
								<div class="card-body py-lg-0 p-md-2">
									<div class="d-flex flex-column justify-content-around h-100 bd-highlight">
										<div class="p-2 align-self-center fs-info opacity-25">
											<i class="icon-new-patients"></i>
										</div>
										<div
											class="position-relative bg-color-primary px-2 align-self-center bd-highlight">
											<div class="mt-2 mb-1 fs-20">
												<p class="d-block text-center me-2 text-ultra-light">Faire une</p>
												<span class="d-block text-bold text-center"
													>demande de prise en charge</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 position-relative d-sm-none">
							<img
								alt="dashboard"
								src="assets/img/dashboard/bg-mobile-whiteboard.svg"
								class="position-absolute start-0 bottom-0" />
						</div>
						<div class="col-6 col-sm-4 pb-3 pb-sm-0">
							<div
								class="card border border-1 card-indicateur pt-2 pb-4 h-100 border-left-primary bg-cyan bg-hover cs-pointer">
								<div class="card-body py-lg-0 p-md-2">
									<div class="d-flex flex-column justify-content-around h-100 bd-highlight">
										<div class="p-2 align-self-center bd-highlight">
											<div class="h4 nb-pres mt-md-3 fs-info ng-star-inserted">0</div>
										</div>
										<div class="p-2 align-self-center fs-info opacity-25">
											<i ng-reflect-ng-class="icon-prescription" class="icon-prescription"></i>
										</div>
										<div class="px-2 align-self-center flex-grow-1 bd-highlight">
											<div class="mt-2 mb-1 fs-20">
												<p class="d-inline-block text-center d-sm-block me-2 text-ultra-light">
													Initiales
												</p>
												<span class="d-block text-bold text-center text-nowrap ng-star-inserted"
													>à signer</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-sm-4 pb-3 pb-sm-0">
							<div
								class="card border border-1 card-indicateur pt-2 pb-4 h-100 border-left-primary bg-success bg-hover cs-pointer">
								<div class="card-body py-lg-0 p-md-2">
									<div class="d-flex flex-column justify-content-around h-100 bd-highlight">
										<div class="p-2 align-self-center bd-highlight">
											<div class="h4 nb-pres mt-md-3 fs-info">0</div>
										</div>
										<div class="p-2 align-self-center fs-info opacity-25">
											<i class="icon-alerte-iah"></i>
										</div>
										<div class="px-2 align-self-center flex-grow-1 bd-highlight">
											<div class="mt-2 mb-1 fs-20">
												<p class="d-inline-block text-center d-sm-block me-2 text-ultra-light">
													Renouvellements
												</p>
												<span class="d-block text-bold text-center text-nowrap ng-star-inserted"
													>à signer</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-sm-4 pb-3 pb-sm-0">
							<div
								class="card border border-1 card-scyova card-indicateur pt-2 pb-4 h-100 border-left-primary bg-primary bg-hover cs-pointer">
								<div class="card-body py-lg-0 p-md-2">
									<div class="d-flex flex-column justify-content-around h-100 bd-highlight">
										<div
											class="p-2 align-self-center flex-grow-1 d-flex align-items-center fs-picto">
											<i class="icon-parkour-scyova"></i>
											<img
												alt="scyova"
												src="assets/img/dashboard/parkour-scyova-rgb.svg"
												class="scyova-rgb"
												alt="" />
										</div>
										<div class="px-2 align-self-center bd-highlight">
											<div class="mt-2 mb-4 fs-20">
												<span
													class="d-inline-block text-center d-sm-block me-2 text-ultra-light text-darkblue">
													Programme d'apprentissage
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-sm-4 pb-3 pb-sm-0">
							<div
								class="card border border-1 card-scyova card-indicateur pt-2 pb-4 h-100 border-left-primary bg-blue bg-hover cs-pointer">
								<div class="card-body py-lg-0 p-md-2">
									<div class="d-flex flex-column justify-content-around h-100 bd-highlight">
										<div
											class="p-2 align-self-center flex-grow-1 d-flex align-items-center fs-picto opacity-25">
											<i class="icon-patients-appairage"></i>
										</div>
										<div class="px-2 align-self-center bd-highlight">
											<div class="mt-2 mb-4 fs-20">
												<span
													class="d-inline-block text-center d-sm-block me-2 text-ultra-light">
													Activité
												</span>
												<span class="text-bold text-uppercase fs-4">Respiratoire</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-xl-6 indicator-list order-2 order-xl-2 position-relative z-index-3">
					<!-- Indicateurs patientèles -->
					<div class="col-12">
						<span class="fs-5 text-medium d-block mb-2">Les indicateurs de votre patientèle</span>
					</div>
					<div class="indicator-list-item position-relative mb-3">
						<div class="row m-0 row-cols-5 z-index-3">
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-observance fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-decedes fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">5</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients</span>
									<span class="fs-16 d-sm-block text-regular">décédés</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-hospitalises fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">11</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients </span>
									<span class="fs-16 d-sm-block text-regular">hospitalisés</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-transmission fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-appairage fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-transmission fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-appairage fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-transmission fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div
								class="col-12 col-sm-3 col-xl-item col-item d-flex flex-sm-column align-items-center justify-content-between px-0 mb-3">
								<i
									class="icon-patients-appairage fs-info opacity-50 mt-2 my-sm-2 mx-2 mx-sm-0 order-3 order-sm-1"></i>
								<span class="fs-3 text-bold my-2 mx-2 mx-sm-0 me-sm-0 order-1 order-sm-2">21</span>
								<div class="text-start flex-fill text-sm-center mb-2 order-2 order-sm-3">
									<span class="fs-16 d-sm-block me-1 me-sm-0">Patients en </span>
									<span class="fs-16 d-sm-block text-regular">observance à 0</span>
								</div>
							</div>
							<div class="col-12 col-sm-3 col-xl-item mb-3 px-0 px-sm-2">
								<button
									class="btn btn-primary rounded d-flex flex-row-reverse flex-sm-column align-items-center justify-content-between justify-content-sm-center text-transform-none w-100 h-100"
									(click)="openDialog()">
									<i class="icon-baisse-observance fs-info opacity-25 align-self-center"></i>
									<div
										class="text-light mt-sm-2 d-flex flex-sm-column justify-content-center flex-grow-1 flex-sm-grow-0">
										Ajouter une <span class="text-regular"> donnée patientèle</span>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-xl-6 mb-3 mb-xl-0 order-4 order-xl-3 position-relative z-index-3">
					<!-- Patients favoris -->
					<div class="row">
						<div class="col-12">
							<p class="fs-5 text-medium d-block mb-1">Patients</p>
							<span class="mb-2">Vos favoris</span>
						</div>
						<!-- 1ere colonne -->
						<div class="col-sm-6 mb-4">
							<div
								class="card card-evenement bg-white border border-1 bg-hover border-left-primary h-100 py-0 shadow position-relative">
								<div class="card-body p-O">
									<div class="d-flex bd-highlight">
										<div class="p-2 align-self-top bd-highlight">
											<i class="icon-patiente circle fs-1"></i>
											<div
												class="position-relative rounded border border-1 color-primary d-flex flex-column text-center">
												<span class="mb-0 fs-12p small-vline"> 51 ans</span>
											</div>
										</div>
										<div class="p-0 flex-grow-1 bd-highlight">
											<div
												class="content h-100 d-flex flex-column justify-content-between pe-0 ps-0">
												<div class="mt-2">
													<p class="mb-0 fs-6">
														Huguettes <span class="text-uppercase text-bold">Berthes</span>
													</p>
													<span class="date my-O">Le 22 septembre 2021</span>
												</div>
												<div class="mb-2 d-flex flex-column">
													<div>
														<span class="message d-inline-block me-1"
															>Renouvellement :</span
														>
														<span class="text-regular text-gray">03/02/24</span>
													</div>
													<div class="text-wrap">
														<span class="message d-inline-block me-1"
															>Prochaine intervention :</span
														>
														<span class="text-gray">13/03/24</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer text-gray text-center d-inline-block">
									<div class="d-flex align-items-center justify-content-between mx-2">
										<div class="d-flex rounded-pill bg-white border border-2 green">
											<i class="icon-synthese text-green fs-5 mt-1 ms-2 me-1 mb-0"></i>
											<p class="d-flex m-0">
												<span class="text-gray">IAH : </span>
												<span class="text-regular text-gray ms-1 me-2">1,3</span>
											</p>
										</div>
										<div class="d-flex rounded-pill bg-white border border-2 green">
											<i class="icon-dashboard text-green fs-5 mt-1 mx-2 mb-1"></i>
											<p class="d-flex m-0">
												<span class="text-gray">Observance : </span>
												<span class="text-regular text-gray ms-1 me-2">4h29</span>
											</p>
										</div>
									</div>
								</div>
								<div class="position-absolute end-0 top-0">
									<button class="btn pin-circle mt-2 me-2">
										<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</div>
						</div>
						<!-- 2e colonne -->
						<div class="col-sm-6 mb-4">
							<div
								class="card card-evenement bg-white border border-1 bg-hover border-left-primary h-100 py-0 shadow position-relative">
								<div class="card-body p-O">
									<div class="d-flex bd-highlight">
										<div class="p-2 align-self-top bd-highlight">
											<i class="icon-patient circle fs-1"></i>
											<div
												class="position-relative rounded border border-1 color-primary d-flex flex-column text-center">
												<span class="mb-0 fs-12p small-vline"> 33 ans</span>
											</div>
										</div>
										<div class="p-0 flex-grow-1 bd-highlight">
											<div
												class="content h-100 d-flex flex-column justify-content-between pe-0 ps-0">
												<div class="mt-2">
													<p class="mb-0 fs-6">
														Marc <span class="text-uppercase text-bold">Odenthal</span>
													</p>
													<span class="date my-O">Le 11 septembre 2021</span>
												</div>
												<div class="mb-2 d-flex flex-column">
													<div>
														<span class="message d-inline-block me-1"
															>Renouvellement :</span
														>
														<span class="text-regular text-gray">13/03/24</span>
													</div>
													<div class="text-wrap">
														<span class="message d-inline-block me-1"
															>Prochaine intervention :</span
														>
														<span class="text-gray">26/04/24</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer text-gray text-center d-inline-block">
									<div class="d-flex align-items-center justify-content-between mx-2">
										<div class="d-flex rounded-pill bg-white border border-2 red">
											<i class="icon-synthese text-red fs-5 mt-1 ms-2 me-1 mb-0"></i>
											<p class="d-flex m-0">
												<span class="text-gray">IAH : </span>
												<span class="text-regular text-gray ms-1 me-2">2,03</span>
											</p>
										</div>
										<div class="d-flex rounded-pill bg-white border border-2 orange">
											<i class="icon-dashboard text-orange fs-5 mt-1 mx-2 mb-1"></i>
											<p class="d-flex m-0">
												<span class="text-gray">Observance : </span>
												<span class="text-regular text-gray ms-1 me-2">3h47</span>
											</p>
										</div>
									</div>
								</div>
								<div class="position-absolute end-0 top-0">
									<button class="btn pin-circle mt-2 me-2">
										<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</div>
						</div>
						<!-- 3e colonne  -->
						<div class="col-sm-6 mb-4">
							<div
								class="card card-evenement border border-2 border-left-primary h-100 py-0 shadow position-relative">
								<div class="card-body bg-odd p-O">
									<div class="d-flex bd-highlight">
										<div class="p-2 align-self-top bd-highlight">
											<i class="icon-patient gray circle bg-none fs-1"></i>
											<div
												class="position-relative rounded border border-1 d-flex flex-column text-center">
												<span class="mb-0 fs-12p small-vline"> 33 ans</span>
											</div>
										</div>
										<div class="p-0 flex-grow-1 bd-highlight">
											<div
												class="content h-100 d-flex flex-column justify-content-between pe-0 ps-0">
												<div class="mt-2">
													<p class="mb-0 fs-6 text-light-gray">
														Simon <span class="text-uppercase text-bold">Sacarra</span>
													</p>
													<span class="date text-light-gray my-O">Le 06 juin 2026</span>
												</div>
												<div class="mb-2 d-flex flex-column">
													<div>
														<span class="message text-light-gray d-inline-block me-1"
															>Renouvellement :</span
														>
														<span class="text-regular text-light-gray">13/03/25</span>
													</div>
													<div class="text-wrap">
														<span class="message text-light-gray d-inline-block me-1"
															>Prochaine intervention :</span
														>
														<span class="text-light-gray">26/04/25</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer border-top text-gray text-center d-inline-block">
									<div class="d-flex align-items-center justify-content-between mx-2">
										<div class="d-flex rounded-pill border border-2 bg-gray-light">
											<i class="icon-synthese text-white fs-5 mt-1 ms-2 me-1 mb-0"></i>
											<p class="d-flex m-0">
												<span class="text-white">IAH : </span>
												<span class="text-regular text-white ms-1 me-2">2,08</span>
											</p>
										</div>
										<div class="d-flex rounded-pill border border-2 bg-gray-light">
											<i class="icon-dashboard text-white fs-5 mt-1 mx-2 mb-1"></i>
											<p class="d-flex m-0">
												<span class="text-white">Observance : </span>
												<span class="text-regular text-white ms-1 me-2">3h47</span>
											</p>
										</div>
									</div>
								</div>
								<div class="position-absolute end-0 top-0">
									<button class="btn pin-circle mt-2 me-2">
										<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</div>
						</div>
						<!-- 2e colonne -->
						<div class="col-sm-6 mb-4">
							<div
								class="card card-evenement bg-white border border-1 bg-hover border-left-primary h-100 py-0 shadow position-relative">
								<div class="card-body p-O">
									<div class="d-flex bd-highlight">
										<div class="p-2 align-self-top bd-highlight">
											<i class="icon-patient circle fs-1"></i>
											<div
												class="position-relative rounded border border-1 color-primary d-flex flex-column text-center">
												<span class="mb-0 fs-12p small-vline"> 33 ans</span>
											</div>
										</div>
										<div class="p-0 flex-grow-1 bd-highlight">
											<div
												class="content h-100 d-flex flex-column justify-content-between pe-0 ps-0">
												<div class="mt-2">
													<p class="mb-0 fs-6">
														Marc <span class="text-uppercase text-bold">Odenthal</span>
													</p>
													<span class="date my-O">Le 11 septembre 2021</span>
												</div>
												<div class="mb-2 d-flex flex-column">
													<div>
														<span class="message d-inline-block me-1"
															>Renouvellement :</span
														>
														<span class="text-regular text-gray">13/03/24</span>
													</div>
													<div class="text-wrap">
														<span class="message d-inline-block me-1"
															>Prochaine intervention :</span
														>
														<span class="text-gray">26/04/24</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer text-gray text-center d-inline-block">
									<div class="d-flex align-items-center justify-content-between mx-2">
										<div class="d-flex rounded-pill bg-white border border-2 red">
											<i class="icon-synthese text-red fs-5 mt-1 ms-2 me-1 mb-0"></i>
											<p class="d-flex m-0">
												<span class="text-gray">IAH : </span>
												<span class="text-regular text-gray ms-1 me-2">2,03</span>
											</p>
										</div>
										<div class="d-flex rounded-pill bg-white border border-2 orange">
											<i class="icon-dashboard text-orange fs-5 mt-1 mx-2 mb-1"></i>
											<p class="d-flex m-0">
												<span class="text-gray">Observance : </span>
												<span class="text-regular text-gray ms-1 me-2">3h47</span>
											</p>
										</div>
									</div>
								</div>
								<div class="position-absolute end-0 top-0">
									<button class="btn pin-circle mt-2 me-2">
										<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="col-sm-6 mb-4">
							<button
								class="btn btn-primary rounded d-flex flex-row-reverse flex-sm-column align-items-center justify-content-between justify-content-sm-center text-transform-none h-100 w-xs-100"
								(click)="openDialog()">
								<i class="icon-patient-favori fs-info opacity-25 align-self-center"></i>
								<div
									class="text-light mt-sm-2 d-flex flex-sm-column justify-content-center flex-grow-1 flex-sm-grow-0">
									<span class="me-1">Mettre un </span>
									<span class="text-regular"> patient en favori</span>
								</div>
							</button>
						</div>
					</div>
				</div>

				<div class="col-12 col-xl-6 mb-3 mb-xl-0 order-3 order-xl-4 position-relative z-index-3">
					<!-- Interventions -->
					<div class="row">
						<div class="col">
							<div class="col-12 bg-white rounded shadow position-relative p-3">
								<p class="text-regular">Interventions techniciens Elivie</p>
								<span>Liste des interventions chez le patient (sous les 7 derniers jours)</span>
								<div class="d-none d-sm-block">
									<div class="bg-white table-rounded">
										<table class="table table-striped align-middle">
											<thead class="thead-primary">
												<tr>
													<th class="ps-3">
														<span class="text-bold fs-7 lh-17">Nom du patient</span>
													</th>
													<th class="text-center">
														<span class="text-bold fs-7 lh-17">Date</span>
													</th>
													<th>
														<span class="text-bold fs-7 lh-17">Type d'intervention</span>
													</th>
												</tr>
											</thead>
											<tbody>
												<!-- 1er élément -->
												<tr class="alert" role="alert">
													<td class="p-3">Esther Murphy</td>
													<td class="text-center">22/08/21</td>
													<td class="position-relative">
														<div class="d-flex">
															<span
																class="pin-circle white ps-1 me-4 text-medium align-self-center">
																PPC
															</span>
															<div class="flex-grow-1 align-self-center">
																intitulé e-de l'intervention
															</div>
														</div>
													</td>
												</tr>
												<!-- 2e élément -->
												<tr class="alert" role="alert">
													<td class="p-3">Adrien Lombart</td>
													<td class="text-center">22/08/21</td>
													<td class="position-relative">
														<div class="d-flex">
															<span
																class="pin-circle white ps-1 me-4 text-medium align-self-center">
																VNI
															</span>
															<div class="flex-grow-1 align-self-center">
																Visites conjointes
															</div>
														</div>
													</td>
												</tr>
												<!-- 3e élément -->
												<tr class="alert" role="alert">
													<td class="p-3">Manu Franc</td>
													<td class="text-center">22/08/21</td>
													<td class="position-relative">
														<div class="d-flex">
															<span
																class="pin-circle white ps-2 me-4 text-medium align-self-center">
																O<sub>2</sub>
															</span>
															<div class="flex-grow-1 align-self-center">
																Visites matériovigilantes Philipps
															</div>
														</div>
													</td>
												</tr>
												<!-- 1er élément -->
												<tr class="alert" role="alert">
													<td class="p-3">Esther Murphy</td>
													<td class="text-center">22/08/21</td>
													<td class="position-relative">
														<div class="d-flex">
															<span
																class="pin-circle white ps-1 me-4 text-medium align-self-center">
																PPC
															</span>
															<div class="flex-grow-1 align-self-center">
																intitulé e-de l'intervention
															</div>
														</div>
													</td>
												</tr>
												<!-- 2e élément -->
												<tr class="alert" role="alert">
													<td class="p-3">Adrien Lombart</td>
													<td class="text-center">22/08/21</td>
													<td class="position-relative">
														<div class="d-flex">
															<span
																class="pin-circle white ps-1 me-4 text-medium align-self-center">
																VNI
															</span>
															<div class="flex-grow-1 align-self-center">
																Visites conjointes
															</div>
														</div>
													</td>
												</tr>
												<!-- 3e élément -->
												<tr class="alert" role="alert">
													<td class="p-3">Manu Franc</td>
													<td class="text-center">22/08/21</td>
													<td class="position-relative">
														<div class="d-flex">
															<span
																class="pin-circle white ps-2 me-4 text-medium align-self-center">
																O<sub>2</sub>
															</span>
															<div class="flex-grow-1 align-self-center">
																Visites matériovigilantes Philipps
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										<nav
											class="d-flex d-pagination justify-content-center align-items-center my-3 position-relative"
											aria-label="Page navigation">
											<button class="btn pin-circle me-2" aria-label="Previous">
												<i
													aria-hidden="true"
													class="icon-pagination-left position-absolute top-50 start-50 translate-middle"></i>
											</button>
											<ul class="pagination list-unstyled align-items-center">
												<li class="page-item"><a class="page-link" href="#">1</a></li>
												<li class="page-item"><a class="page-link" href="#">2</a></li>
												<li class="page-item"><a class="page-link" href="#">3</a></li>
												<li class="page-item"><a class="page-link" href="#">...</a></li>
												<li class="page-item"><a class="page-link" href="#">10</a></li>
												<li class="page-item"><a class="page-link" href="#">...</a></li>
												<li class="page-item"><a class="page-link" href="#">18</a></li>
												<li class="page-item"><a class="page-link" href="#">19</a></li>
												<li class="page-item"><a class="page-link" href="#">20</a></li>
											</ul>
											<button class="btn pin-circle ms-2" aria-label="Next">
												<i
													aria-hidden="true"
													class="icon-pagination-right position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 mt-3 order-5 order-xl-5 position-relative z-index-3">
					<!-- Patients favoris -->
					<div class="row">
						<!-- 1ere colonne -->
						<div class="col-sm-6 col-xl-3 mb-3 mb-xl-0">
							<button
								class="btn btn-primary rounded position-relative d-flex flex-row-reverse flex-sm-column align-items-center justify-content-between justify-content-sm-center text-transform-none h-100 w-100"
								(click)="openDialog()">
								<i class="icon-patient-favori fs-info opacity-25 align-self-center"></i>
								<div
									class="text-light mt-sm-2 d-flex flex-sm-column justify-content-center flex-grow-1 flex-sm-grow-0">
									<div>
										<span class="me-1">Mettre un </span>
										<span class="text-regular"> patient en favori</span>
									</div>

									<span class="mt-3 text-gray"
										>Afin de suivre au plus prés l’évolution de certains de vos patients, nous vous
										invitons à les mettre en favoris</span
									>
								</div>
								<div class="position-absolute end-0 top-0">
									<button class="btn pin-circle mt-2 me-2">
										<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</button>
						</div>
						<!-- 3e colonne  -->
						<div class="col-sm-6 col-xl-3 mb-3 mb-xl-0">
							<div
								class="card card-evenement border border-2 border-left-primary h-100 py-0 shadow position-relative">
								<div class="card-body bg-odd p-O">
									<div class="d-flex bd-highlight">
										<div class="p-2 align-self-top bd-highlight">
											<i class="icon-patient gray circle bg-none fs-1"></i>
											<div
												class="position-relative rounded border border-1 d-flex flex-column text-center">
												<span class="mb-0 fs-12p small-vline"> 33 ans</span>
											</div>
										</div>
										<div class="p-0 flex-grow-1 bd-highlight">
											<div
												class="content h-100 d-flex flex-column justify-content-between pe-0 ps-0">
												<div class="mt-2">
													<p class="mb-0 fs-6 text-light-gray">
														Simon <span class="text-uppercase text-bold">Sacarra</span>
													</p>
													<span class="date text-light-gray my-O">Le 06 juin 2026</span>
												</div>
												<div class="mb-2 d-flex flex-column">
													<div>
														<span class="message text-light-gray d-inline-block me-1"
															>Renouvellement :</span
														>
														<span class="text-regular text-light-gray">13/03/25</span>
													</div>
													<div class="text-wrap">
														<span class="message text-light-gray d-inline-block me-1"
															>Prochaine intervention :</span
														>
														<span class="text-light-gray">26/04/25</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer border-top text-gray text-center d-inline-block">
									<div class="d-flex align-items-center justify-content-between mx-2">
										<div class="d-flex rounded-pill border border-2 bg-gray-light">
											<i class="icon-synthese text-white fs-5 mt-1 ms-2 me-1 mb-0"></i>
											<p class="d-flex m-0">
												<span class="text-white">IAH : </span>
												<span class="text-regular text-white ms-1 me-2">2,08</span>
											</p>
										</div>
										<div class="d-flex rounded-pill border border-2 bg-gray-light">
											<i class="icon-dashboard text-white fs-5 mt-1 mx-2 mb-1"></i>
											<p class="d-flex m-0">
												<span class="text-white">Observance : </span>
												<span class="text-regular text-white ms-1 me-2">3h47</span>
											</p>
										</div>
									</div>
								</div>
								<div class="position-absolute end-0 top-0">
									<button class="btn disabled pin-circle mt-2 me-2">
										<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-xl-6 mb-3 mb-xl-0">
							<div class="card card-evenement h-100 py-0 shadow position-relative">
								<div class="bg-color-primary text-center p-3">
									<div class="position-relative">
										<hr
											class="border white border-tiny w-100 position-absolute opacity-100 m-0 top-50 start-50 translate-middle" />
										<span
											class="text-white bg-color-primary px-2 position-relative fs-6 text-regular z-index-2"
											>Informations complémentaires</span
										>
									</div>
								</div>
								<div class="card-body py-2 px-3 bg-white">
									<div class="d-flex flex-column align-items-start">
										<div class="mb-2 d-flex align-items-center">
											<div class="d-flex rounded-pill p-1 bg-green">
												<i class="icon-synthese text-white fs-5 mt-1 ms-2 me-1 mb-0"></i>
												<p class="d-flex m-0 infos">
													<span class="text-white text-extra-light">IAH : </span>
													<span class="text-regular text-white fs-6 ms-1 me-2">2,08</span>
												</p>
											</div>
											<span class="ms-3 text-extra-light">IAH inital : 56,4</span>
										</div>
										<div class="mb-2 d-flex justify-content-between w-100">
											<div class="d-flex rounded-pill p-1 bg-green">
												<i class="icon-dashboard text-white fs-5 mt-1 mx-2 mb-1"></i>
												<p class="d-flex m-0 infos">
													<span class="text-white text-extra-light">Observance : </span>
													<span class="text-regular text-white fs-6 ms-1 me-2">3h47</span>
												</p>
											</div>
											<div
												class="border color-primary border-2 px-2 rounded-pill d-flex align-items-center">
												<span class="me-2 text-gray">Soin ALO</span>
												<i class="icon-check-circle"></i>
											</div>
										</div>
									</div>
									<div class="w-100 w-md-100 align-self-stretch patient-care">
										<span class="fs-6 mb-3"
											>Nombre d'heures d'observance sur la dernière
											<span class="text-regular">période de 28 jours : 5h12</span></span
										>
										<span class="fs-6">Nombre de périodes </span>
										<div class="d-flex mb-3">
											<div class="d-flex align-items-center me-3">
												<span class="text-medium me-2">+ de 112h :</span>
												<span
													class="rounded-circle text-center text-white bg-green pt-1 text-medium">
													59
												</span>
											</div>
											<div class="d-flex align-items-center me-3">
												<span class="text-medium me-2">+ de 56h :</span>
												<span
													class="rounded-circle text-center text-white bg-green pt-1 text-medium">
													26
												</span>
											</div>
											<div class="d-flex align-items-center me-3">
												<span class="text-medium me-2">- de 56h :</span>
												<span
													class="rounded-circle text-center text-white bg-orange pt-1 text-medium">
													52
												</span>
											</div>
										</div>
										<ul class="m-0 ps-3 liste-form">
											<li class="text-color-primary m-0">
												<span class="text-gray fs-6 text-regular">Appareil utilisé</span>
											</li>
										</ul>
										<span class="mb-1 ms-1 text-center text-sm-start ps-3">
											Resmed
											<span class="text-regular">AIRSENSE10 AUTOSET</span>
										</span>
										<div class="d-flex flex-column flex-sm-row h-items">
											<div class="align-self-center align-self-sm-start">
												<img
													class="device"
													alt="AIRSENSE10 AUTOSET"
													src="assets/img/devices/RESMED_AIRSENSE10.jpg" />
											</div>
											<div class="px-3 align-self-center w-100 text-center text-sm-start">
												<span class="mb-1">Réglages de l'appareil :</span>
												<span class="text-regular mb-0">Mode&nbsp; Asv</span>

												<ul
													class="ps-4 mb-2 d-flex mh-60 mah-60 flex-column flex-column-reverse flex-wrap">
													<li class="text-green">
														<span class="text-gray">Pression Max :&nbsp;</span>
														<span class="text-gray text-regular">5</span>
													</li>
													<li class="text-green">
														<span class="text-gray">Pression Max :&nbsp;</span>
														<span class="text-gray text-regular">5</span>
													</li>
													<li class="text-green">
														<span class="text-gray">Pression Min :&nbsp;</span>
														<span class="text-gray text-regular">4</span>
													</li>
													<li class="text-green">
														<span class="text-gray">Pression Max :&nbsp;</span>
														<span class="text-gray text-regular">5</span>
													</li>
													<li class="text-green">
														<span class="text-gray">Pression Min :&nbsp;</span>
														<span class="text-gray text-regular">4</span>
													</li>
												</ul>
											</div>
											<div class="align-self-end text-end pb-3 mb-1">
												<div>Pression : <span class="text-regular">9</span></div>
												<span class="text-nowrap text-extra-light fs-12p lh-12 mb-2 pb-1">
													Valeur en cm/H
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="position-absolute start-0 bottom-0 w-20 z-index-1">
					<img alt="dashboard" src="assets/img/dashboard/bg-dashboard-left.svg" />
				</div>
				<div class="position-absolute end-0 bottom-0 w-30 z-index-2">
					<img alt="dashboard" src="assets/img/dashboard/bg-dashboard-right.svg" />
				</div>

				<!-- #################             FIN NEW DESIGN DASHBOARD				 ################# -->

				<!-- #################              OLD DESIGN BEGINS -->
				<!-- <div class="col-12 mt-5 order-6 order-xl-6 ">
					<div class="col-lg-6 d-none d-xl-block">
						<span class="text-light">Prescriptions</span>
					</div>
					<div class="col-lg-6 d-none d-xl-block">
						<span class="text-light">Patients</span>
					</div>
					<div class="col-12 d-none d-lg-block">
						<div class="row">
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-indicateur border-left-primary bg-primary py-2">
									<i class="icon-info-background"></i>
									<div class="card-body py-lg-0 p-md-2">
										<div class="d-flex bd-highlight">
											<div class="px-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mt-md-3">6</div>
											</div>
											<div class="px-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">
														Prescriptions
													</p>
													<span class="d-inline-block d-sm-block text-bold">à signer</span>
												</div>
											</div>
											<div class="p-2 align-self-center bd-highlight text-end">
												<button class="btn btn-primary d-none d-lg-inline-block">Voir</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-indicateur bg-white border-left-success shadow py-2">
									<i class="icon-info-background"></i>
	
									<div class="card-body py-lg-0 p-md-2">
										<div class="d-flex bd-highlight">
											<div class="px-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mt-md-3">23</div>
											</div>
											<div class="px-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">
														Prescriptions
													</p>
													<span class="d-inline-block d-sm-block text-bold">avec échéance</span>
												</div>
											</div>
											<div class="px-2 align-self-center bd-highlight text-end">
												<button class="btn btn-primary d-none d-lg-inline-block">Voir</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-indicateur border-left-primary bg-success py-2">
									<i class="icon-alert-background"></i>
									<div class="card-body py-lg-0 p-md-2">
										<div class="d-flex bd-highlight">
											<div class="px-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mt-md-3">6</div>
											</div>
											<div class="px-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">Patients</p>
													<span class="d-inline-block d-sm-block text-bold">en alerte IAH</span>
												</div>
											</div>
											<div class="px-2 align-self-center bd-highlight text-end">
												<button class="btn btn-success d-none d-lg-inline-block">Voir</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-indicateur bg-white green border-left-success shadow py-2">
									<i class="icon-alert-background"></i>
									<div class="card-body py-lg-0 p-md-2">
										<div class="d-flex bd-highlight">
											<div class="px-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mt-md-3">23</div>
											</div>
											<div class="px-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">
														Patients en <span class="text-medium">baisse</span>
													</p>
													<span class="d-inline-block d-sm-block text-bold">d'observance</span>
												</div>
											</div>
											<div class="px-2 align-self-center bd-highlight text-end">
												<button class="btn btn-success d-none d-lg-inline-block">Voir</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
					<div class="col-12 d-none d-sm-block d-lg-none">
				
						<div class="row g-2">
							<div class="col-12">
								<span class="text-light">Prescriptions</span>
							</div>
				
							<div class="col-xl-3 col-sm-6 mb-4">
								<button class="card card-indicateur border-left-primary bg-primary py-1">
									<i class="icon-info-background"></i>
									<div class="card-body p-0 p-lg-2">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mb-O mt-2">26</div>
											</div>
											<div class="p-2 align-self-center flex-grow-1 bd-highlight">
												<div class="fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">
														Prescriptions
													</p>
													<span class="d-inline-block d-sm-block text-bold">à signer</span>
												</div>
											</div>
										</div>
									</div>
								</button>
							</div>
				
							<div class="col-xl-3 col-sm-6 mb-4">
								<button class="card card-indicateur bg-white border-left-success shadow py-1">
									<i class="icon-info-background"></i>
									<div class="card-body p-0 p-lg-2">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mb-O mt-2">23</div>
											</div>
											<div class="p-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">
														Prescriptions
													</p>
													<span class="d-inline-block d-sm-block text-bold">avec échéance</span>
												</div>
											</div>
										</div>
									</div>
								</button>
							</div>
						</div>
				
						<div class="row g-2">
							<div class="col-12">
								<span class="text-light">Patients</span>
							</div>
						
							<div class="col-xl-3 col-sm-6 mb-4">
								<button class="card card-indicateur border-left-primary bg-success py-1">
									<i class="icon-alert-background"></i>
									<div class="card-body p-0 p-lg-2">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mb-O mt-2">6</div>
											</div>
											<div class="p-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">Patients</p>
													<span class="d-inline-block d-sm-block text-bold">en Alert IAH</span>
												</div>
											</div>
										</div>
									</div>
								</button>
							</div>
						
							<div class="col-xl-3 col-sm-6 mb-4">
								<button class="card card-indicateur bg-white green border-left-success shadow py-1">
									<i class="icon-alert-background"></i>
									<div class="card-body p-0 p-lg-2">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<div class="h4 nb-pres mb-O mt-2">23</div>
											</div>
											<div class="p-2 align-self-center flex-grow-1 bd-highlight">
												<div class="mb-1 fs-18">
													<p class="d-inline-block d-sm-block me-2 text-ultra-light">
														Patients en
													</p>
													<span class="d-inline-block d-sm-block text-bold"
														>baisse d'observance</span
													>
												</div>
											</div>
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>
					
					<div class="col-12 d-block d-sm-none d-md-none">
						<div class="row g-2">
					
							<div class="d-flex flex-column">
								<div><span class="text-light">Prescriptions</span></div>
					
								<div class="carousel slide indicator mt-2 mb-4" id="myCarousel">
					
									<div class="carousel-indicators">
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="0"
											class="active rounded-pill"
											aria-label="Slide 1"
											aria-current="true"
										></button>
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="1"
											aria-label="Slide 2"
											class="rounded-pill"
										></button>
									</div>
						
									<div class="carousel-inner">
						
										<div class="carousel-item active">
											<div class="card card-indicateur border-left-primary bg-primary py-1">
												<i class="icon-info-background"></i>
												<div class="card-body p-0 p-md-2">
													<div class="d-flex bd-highlight">
														<div class="p-2 align-self-center bd-highlight">
															<div class="h4 nb-pres mb-O mt-2">26</div>
														</div>
														<div class="p-2 align-self-center flex-grow-1 bd-highlight">
															<div class="">
																<p class="d-inline-block d-sm-block me-2">Prescriptions</p>
																<span class="d-inline-block d-sm-block font-bolder"
																	>à signer</span
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									
										<div class="carousel-item">
											<div class="card card-indicateur bg-white border-left-success shadow py-1">
												<i class="icon-info-background"></i>
												<div class="card-body p-0 p-md-2">
													<div class="d-flex bd-highlight">
														<div class="p-2 align-self-center bd-highlight">
															<div class="h4 nb-pres mb-O mt-2">23</div>
														</div>
														<div class="p-2 align-self-center flex-grow-1 bd-highlight">
															<div class="">
																<p class="d-inline-block d-sm-block me-2">Prescriptions</p>
																<span class="d-inline-block d-sm-block font-bolder"
																	>avec échéance</span
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row g-2">
							
							<div class="d-flex flex-column">
								<div><span class="text-light">Patients</span></div>
							
								<div class="carousel slide indicator mt-2 mb-4" id="myCarousel">
							
									<div class="carousel-indicators green">
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="0"
											class="active rounded-pill"
											aria-label="Slide 1"
											aria-current="true"
										></button>
										<button
											type="button"
											data-bs-target="#myCarousel"
											data-bs-slide-to="1"
											aria-label="Slide 2"
											class="rounded-pill"
										></button>
									</div>
								
									<div class="carousel-inner">
								
										<div class="carousel-item active">
											<div class="card card-indicateur border-left-primary bg-success py-1">
												<i class="icon-alert-background"></i>
												<div class="card-body p-0 p-md-2">
													<div class="d-flex bd-highlight">
														<div class="p-2 align-self-center bd-highlight">
															<div class="h4 nb-pres mb-O mt-2">6</div>
														</div>
														<div class="p-2 align-self-center flex-grow-1 bd-highlight">
															<div class="">
																<p class="d-inline-block d-sm-block me-2">Patients</p>
																<span class="d-inline-block d-sm-block font-bolder"
																	>en Alert IAH</span
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
								
										<div class="carousel-item">
											<div
												class="card card-indicateur bg-white green border-left-success shadow py-1"
											>
												<i class="icon-alert-background"></i>
	
												<div class="card-body p-0 p-md-2">
													<div class="d-flex bd-highlight">
														<div class="p-2 align-self-center bd-highlight">
															<div class="h4 nb-pres mb-O mt-2">23</div>
														</div>
														<div class="p-2 align-self-center flex-grow-1 bd-highlight">
															<div class="">
																<p class="d-inline-block d-sm-block me-2">Patients en</p>
																<span class="d-inline-block d-sm-block font-bolder"
																	>baisse d'observance</span
																>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12">
						<span class="fs-5 text-medium d-block mb-1">Évènements</span>
						<span class="text-light">Depuis votre dernière connexion...</span>
					</div>
					
					<div class="col-12 d-none d-lg-block">
						<div class="row">
					
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-evenement bg-white border-left-primary h-100 py-0 shadow">
									<div class="card-body p-O">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<i class="icon-patiente fs-1"></i>
											</div>
											<div class="p-0 align-self-center flex-grow-1 bd-highlight">
												<div class="content pe-0 ps-0">
													<p class="mb-0 fs-6">
														Huguettes <span class="text-uppercase text-bold">Berthes</span>
													</p>
													<span class="date my-O">Le 22 septembre 2021</span>
													<span class="message">Intitulé du dernier message</span>
												</div>
											</div>
											<div class="p-2 align-self-start bd-highlight text-end">
												<button class="btn pin-circle">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"
													></i>
												</button>
											</div>
										</div>
									</div>
									<div class="card-footer text-center d-none d-lg-inline-block">
										<button class="btn btn-primary">Voir les changements</button>
									</div>
								</div>
							</div>
					
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-evenement bg-white border-left-primary h-100 py-0 shadow">
									<div class="card-body p-O">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<i class="icon-patient fs-1"></i>
											</div>
											<div class="p-0 align-self-center flex-grow-1 bd-highlight">
												<div class="content pe-0 ps-0">
													<p class="mb-0 fs-6">
														Jean <span class="text-uppercase text-bold">Landin</span>
													</p>
													<span class="date my-O">Le 16 septembre 2021</span>
													<span class="message">Intitulé du dernier message</span>
												</div>
											</div>
											<div class="p-2 align-self-start bd-highlight text-end">
												<button class="btn pin-circle">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"
													></i>
												</button>
											</div>
										</div>
									</div>
									<div class="card-footer text-center d-none d-lg-inline-block">
										<button class="btn btn-primary">Voir les changements</button>
									</div>
								</div>
							</div>
					
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-evenement bg-white border-left-primary h-100 py-0 shadow">
									<div class="card-body p-O">
										<div class="d-flex bd-highlight">
											<div class="p-2 align-self-center bd-highlight">
												<i class="icon-patient fs-1"></i>
											</div>
											<div class="p-0 align-self-center flex-grow-1 bd-highlight">
												<div class="content pe-0 ps-0">
													<p class="mb-0 fs-6">
														Jean <span class="text-uppercase text-bold">Landin</span>
													</p>
													<span class="date my-O">Le 16 septembre 2021</span>
													<span class="message">Intitulé du dernier message</span>
												</div>
											</div>
											<div class="p-2 align-self-start bd-highlight text-end">
												<button class="btn pin-circle">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"
													></i>
												</button>
											</div>
										</div>
									</div>
									<div class="card-footer text-center d-none d-lg-inline-block">
										<span class="text-uppercase text-regular"> pas de changements </span>
									</div>
								</div>
							</div>
					
							<div class="col-xl-3 col-md-6 mb-4">
								<div class="card card-to-add border border-left-success h-100 py-2">
									<div class="card-body text-center opacity-50">
										<p class="text-uppercase text-regular my-1">Mettre un patient en favori</p>
										<span class="mb-2">
											Afin de suivre au plus prés l’évolution de certains de vos patients, nous vous
											invitons à les mettre en favoris
										</span>
										<span class="text-uppercase me-2 mt-2 text-regular">Utiliser cette icône </span>
										<i class="icon-pin"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12 d-none d-sm-block d-lg-none">
						<div class="row event g-2">
					
							<div class="col-xl-3 col-sm-6 mb-4">
								<div class="card card-evenement bg-white border-left-primary h-100 py-0 shadow">
									<div class="card-body p-O">
										<div class="d-flex bd-highlight">
											<div class="pt-2 px-2 align-self-center bd-highlight">
												<i class="icon-patiente fs-1"></i>
											</div>
											<div class="p-0 align-self-center flex-grow-1 bd-highlight">
												<div class="content pe-0 ps-0">
													<p class="mb-0">
														Huguette <span class="text-uppercase text-bold">Berthes</span>
													</p>
													<span class="date my-O">Le 16 septembre 2021</span>
												</div>
											</div>
											<div class="p-2 align-self-start bd-highlight text-end">
												<button class="btn pin-circle">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"
													></i>
												</button>
											</div>
										</div>
										<div class="pt-0 pb-1 px-3 message">
											<span class="text-bold d-block">X nouveaux évènements</span>
											<span class="d-block">Veuillez les consulter</span>
										</div>
									</div>
								</div>
							</div>
					
							<div class="col-xl-3 col-sm-6 mb-4">
								<div class="card card-evenement bg-white border-left-primary h-100 py-0 shadow">
									<div class="card-body p-O">
										<div class="d-flex bd-highlight">
											<div class="pt-2 px-2 align-self-center bd-highlight">
												<i class="icon-patient fs-1"></i>
											</div>
											<div class="p-0 align-self-center flex-grow-1 bd-highlight">
												<div class="content pe-0 ps-0">
													<p class="mb-0">
														Jean <span class="text-uppercase text-bold">Landin</span>
													</p>
													<span class="date my-O">Le 16 septembre 2021</span>
												</div>
											</div>
											<div class="p-2 align-self-start bd-highlight text-end">
												<button class="btn pin-circle">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"
													></i>
												</button>
											</div>
										</div>
										<div class="pt-0 pb-1 px-3 message">
											<span class="text-bold d-block">X nouveaux évènements</span>
											<span class="d-block">Veuillez les consulter</span>
										</div>
									</div>
								</div>
							</div>
	
							<div class="col-xl-3 col-sm-6 mb-4">
								<div class="card card-evenement bg-white border-left-primary h-100 py-0 shadow">
									<div class="card-body p-O">
										<div class="d-flex bd-highlight">
											<div class="pt-2 px-2 align-self-center bd-highlight">
												<i class="icon-patient fs-1"></i>
											</div>
											<div class="p-0 align-self-center flex-grow-1 bd-highlight">
												<div class="content pe-0 ps-0">
													<p class="mb-0">
														Jean <span class="text-uppercase text-bold">Landin</span>
													</p>
													<span class="date my-O">Le 16 septembre 2021</span>
												</div>
											</div>
											<div class="p-2 align-self-start bd-highlight text-end">
												<button class="btn pin-circle">
													<i
														class="icon-pin position-absolute top-50 start-50 translate-middle"
													></i>
												</button>
											</div>
										</div>
										<div class="pt-0 pb-1 px-3 message">
											<span class="text-bold d-block">X nouveaux évènements</span>
											<span class="d-block">Veuillez les consulter</span>
										</div>
									</div>
								</div>
							</div>
	
							<div class="col-xl-3 col-sm-6 mb-4">
								<div class="card card-to-add border border-left-success h-100 py-2">
									<div class="card-body text-center opacity-50">
										<p class="text-uppercase text-regular my-1">Mettre un patient en favori</p>
										<span class="mb-2"
											>Afin de suivre au plus prés l’évolution de certains de vos patients, nous vous
											invitons à les mettre en favoris</span
										>
										<span class="text-uppercase me-2 mt-2 text-regular">Utiliser cette icône </span>
										<i class="icon-pin"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12 d-block d-sm-none d-md-none">
						<div class="d-flex flex-column">
					
							<div class="carousel slide event mt-2 mb-4" id="myCarousel">
					
								<div class="carousel-indicators">
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="0"
										class="active rounded-pill"
										aria-label="Slide 1"
										aria-current="true"
									></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="1"
										aria-label="Slide 2"
										class="rounded-pill"
									></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="2"
										aria-label="Slide 3"
										class="rounded-pill"
									></button>
									<button
										type="button"
										data-bs-target="#myCarousel"
										data-bs-slide-to="3"
										aria-label="Slide 4"
										class="rounded-pill"
									></button>
								</div>
								<div class="carousel-inner">
					
									<div class="carousel-item active">
										<div class="card card-evenement bg-white border-left-primary h-100 py-0">
											<div class="card-body p-O">
												<div class="d-flex bd-highlight">
													<div class="pt-2 px-2 align-self-center bd-highlight">
														<i class="icon-patiente fs-1"></i>
													</div>
													<div class="p-0 align-self-center flex-grow-1 bd-highlight">
														<div class="content pe-0 ps-0">
															<p class="mb-0">
																Huguette
																<span class="text-uppercase text-bold">Berthes</span>
															</p>
															<span class="date my-O">Le 16 septembre 2021</span>
														</div>
													</div>
													<div class="p-2 align-self-start bd-highlight text-end">
														<button class="btn pin-circle">
															<i
																class="icon-pin position-absolute top-50 start-50 translate-middle"
															></i>
														</button>
													</div>
												</div>
												<div class="pt-0 pb-1 px-3 message">
													<span class="text-bold d-block">X nouveaux évènements</span>
													<span class="d-block">Veuillez les consulter</span>
												</div>
											</div>
										</div>
									</div>
					
									<div class="carousel-item">
										<div class="card card-evenement bg-white border-left-primary h-100 py-0">
											<div class="card-body p-O">
												<div class="d-flex bd-highlight">
													<div class="pt-2 px-2 align-self-center bd-highlight">
														<i class="icon-patient fs-1"></i>
													</div>
													<div class="p-0 align-self-center flex-grow-1 bd-highlight">
														<div class="content pe-0 ps-0">
															<p class="mb-0">
																Jean <span class="text-uppercase text-bold">Landin</span>
															</p>
															<span class="date my-O">Le 16 septembre 2021</span>
														</div>
													</div>
													<div class="p-2 align-self-start bd-highlight text-end">
														<button class="btn pin-circle">
															<i
																class="icon-pin position-absolute top-50 start-50 translate-middle"
															></i>
														</button>
													</div>
												</div>
												<div class="pt-0 pb-1 px-3 message">
													<span class="text-bold d-block">X nouveaux évènements</span>
													<span class="d-block">Veuillez les consulter</span>
												</div>
											</div>
										</div>
									</div>
					
									<div class="carousel-item">
										<div class="card card-evenement bg-white border-left-primary h-100 py-0">
											<div class="card-body p-O">
												<div class="d-flex bd-highlight">
													<div class="pt-2 px-2 align-self-center bd-highlight">
														<i class="icon-patient fs-1"></i>
													</div>
													<div class="p-0 align-self-center flex-grow-1 bd-highlight">
														<div class="content pe-0 ps-0">
															<p class="mb-0">
																Jean <span class="text-uppercase text-bold">Landin</span>
															</p>
															<span class="date my-O">Le 16 septembre 2021</span>
														</div>
													</div>
													<div class="p-2 align-self-start bd-highlight text-end">
														<button class="btn pin-circle">
															<i
																class="icon-pin position-absolute top-50 start-50 translate-middle"
															></i>
														</button>
													</div>
												</div>
												<div class="pt-0 pb-1 px-3 message">
													<span class="text-bold d-block">X nouveaux évènements</span>
													<span class="d-block">Veuillez les consulter</span>
												</div>
											</div>
										</div>
									</div>
					
									<div class="carousel-item">
										<div class="card card-to-add border border-left-success h-100 py-2">
											<div class="card-body text-center opacity-50">
												<p class="text-uppercase text-regular my-1">Mettre un patient en favori</p>
												<span class="mb-2"
													>Afin de suivre au plus prés l’évolution de certains de vos patients,
													nous vous invitons à les mettre en favoris</span
												>
												<span class="text-uppercase me-2 mt-2 text-regular"
													>Utiliser cette icône
												</span>
												<i class="icon-pin"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12 d-none d-lg-block">
						<span class="text-light d-block">Derniers évènements avec les agents Elivie</span>
						<div class="input-group mt-1 ps-4 mb-3 d-flex justify-content-start align-items-center">
							<div class="checkbox-wrap checkbox-primary custom-checkbox">
								<input
									class="form-check-input mt-0"
									type="checkbox"
									value=""
									aria-label="Checkbox for following text input"
								/>
							</div>
							<span class="d-inline-block mx-2">Sélectionner tous les évènements</span>
							<button class="btn pin-circle me-2 inactive">
								<i class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
							</button>
							<button class="btn pin-circle inactive">
								<i class="icon-delete position-absolute top-50 start-50 translate-middle"></i>
							</button>
						</div>
					</div>
					<div class="col-12 d-none d-lg-block">
						<div class="row">
							<div class="table-responsive-lg d-none d-lg-block">
								<div class="bg-white table-rounded pb-3 px-3 pt-1">
									<table class="table table-striped align-middle">
										<thead class="thead-primary">
											<tr>
												<th>&nbsp;</th>
												<th class="text-center">Nom du patient</th>
												<th class="text-center">Date</th>
												<th class="text-center">Évènement</th>
												<th class="text-center">Intervention et rapport</th>
												<th class="text-center">Niveau d'observance</th>
												<th class="text-center">Actions</th>
											</tr>
										</thead>
										<tbody>
										
											<tr class="alert" role="alert">
												<td class="rounded-start">
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td>
													<div class="d-flex">
														<span
															class="pin-circle white ps-2 me-4 text-medium align-self-center"
															>ES
														</span>
														<div class="flex-grow-1 align-self-center">
															Esther <span class="text-bold">Murphy</span>
														</div>
													</div>
												</td>
												<td>22/08/21</td>
												<td
													class="cdk-cell border-tiny-left position-relative cdk-column-eventName ng-tns-c183-0 ng-star-inserted"
												>
													<div
														class="d-flex justify-content-start align-items-center ng-tns-c183-0 isGroupedHeaderColumn ng-star-inserted"
														ng-reflect-ng-class="[object Object]"
														ng-reflect-ng-switch="upper"
													>
														<span
															style="max-height: 38px; max-width: 350px"
															class="text-truncate ng-tns-c183-0 ng-star-inserted"
															title="Intitulé du dernier évènement pour le patient qui peut être long avec plusieurs centaines de caractères et du lorem ipsum dolor sit mamet ou amet ne pas confondre avec le prohete ou le prophete meme si latinum vlatko chitouf comme ad ou riad sattouf 
														Intitulé du dernier évènement pour le patient qui peut être long avec plusieurs centaines de caractères et du lorem ipsum dolor sit mamet ou amet ne pas confondre avec le prohete ou le prophete meme si latinum vlatko chitouf comme ad ou riad sattouf"
														>
															Intitulé du dernier évènement pour le patient qui peut être long
															avec plusieurs centaines de caractères et du lorem ipsum dolor
															sit mamet ou amet ne pas confondre avec le prohete ou le
															prophete meme si latinum vlatko chitouf comme ad ou riad sattouf
															Intitulé du dernier évènement pour le patient qui peut être long
															avec plusieurs centaines de caractères et du lorem ipsum dolor
															sit mamet ou amet ne pas confondre avec le prohete ou le
															prophete meme si latinum vlatko chitouf comme ad ou riad sattouf
														</span>
													</div>
												</td>
												<td class="position-relative">
													intitulé e-de l'intervention
													<button class="btn pin-circle btn-ordonance">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
												<td class="position-relative">
													<div class="obs-green">
														<span class="">Plus de 4h</span>
														<i class="icon-level-up ms-2"></i>
													</div>
												</td>
												<td class="text-center">
													<button class="btn pin-circle me-2">
														<i
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													<button class="btn pin-circle">
														<i
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
										
											<tr class="alert" role="alert">
												<td>
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" checked="" />
													</label>
												</td>
	
												<td>
													<div class="d-flex">
														<span
															class="pin-circle white ps-2 me-4 text-medium align-self-center"
															>ES
														</span>
														<div class="flex-grow-1 align-self-center">
															Esther <span class="text-bold">Murphy</span>
														</div>
													</div>
												</td>
												<td>22/08/21</td>
												<td class="event text-wrap">
													Intitulé du dernier évènement pour le patient
												</td>
												<td class="position-relative">
													<span class="">intitulé e-de l'inter</span>
													<button class="btn pin-circle btn-ordonance">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
												<td class="position-relative">
													<div class="obs-orange">
														<span class="text-capitalize">Entre 2h et 4h</span>
														<i class="icon-level-down ms-2"></i>
													</div>
												</td>
												<td class="text-center">
													<button _ngcontent-ajq-c23="" class="btn pin-circle me-2">
														<i
															_ngcontent-ajq-c23=""
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													<button _ngcontent-ajq-c23="" class="btn pin-circle">
														<i
															_ngcontent-ajq-c23=""
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
										
											<tr class="alert" role="alert">
												<td>
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td>
													<div class="d-flex">
														<span
															class="pin-circle white ps-2 me-4 text-medium align-self-center"
															>ES
														</span>
														<div class="flex-grow-1 align-self-center">
															Esther <span class="text-bold">Murphy</span>
														</div>
													</div>
												</td>
												<td>22/08/21</td>
												<td class="event text-wrap">
													Intitulé du dernier évènement pour le patient
												</td>
												<td class="position-relative">
													intitulé e-de l'intervention
													<button class="btn pin-circle btn-ordonance">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
												<td class="position-relative">
													<div class="obs-red align-middle">
														<span class="">Moins de 2h</span>
														<i class="icon-stable ms-2"></i>
													</div>
												</td>
												<td class="text-center">
													<button _ngcontent-ajq-c23="" class="btn pin-circle me-2">
														<i
															_ngcontent-ajq-c23=""
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													<button _ngcontent-ajq-c23="" class="btn pin-circle">
														<i
															_ngcontent-ajq-c23=""
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
										
											<tr class="alert" role="alert">
												<td>
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td>
													<div class="d-flex">
														<span
															class="pin-circle white ps-2 me-4 text-medium align-self-center"
															>ES
														</span>
														<div class="flex-grow-1 align-self-center">
															Esther <span class="text-bold">Murphy</span>
														</div>
													</div>
												</td>
												<td>22/08/21</td>
												<td class="event text-wrap">
													Intitulé du dernier évènement pour le patient
												</td>
												<td class="position-relative">
													intitulé e-de l'intervention
													<button class="btn pin-circle btn-ordonance">
														<i
															class="icon-ordonance position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
												<td class="position-relative">
													<div class="obs-none">
														<span class="">Non transmis</span>
														<i class="icon-stable ms-2"></i>
													</div>
												</td>
												<td class="text-center">
													<button _ngcontent-ajq-c23="" class="btn pin-circle me-2">
														<i
															_ngcontent-ajq-c23=""
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													<button _ngcontent-ajq-c23="" class="btn pin-circle">
														<i
															_ngcontent-ajq-c23=""
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 d-none d-lg-block">
						<div class="row">
							<div class="table-responsive-lg d-none d-lg-block">
								<div class="bg-white table-rounded pb-3 px-3 pt-1">
									<table class="table table-striped align-middle">
										<thead class="thead-primary">
											<tr>
												<th colspan="4">
													<label
														class="checkbox-wrap checkbox-primary custom-checkbox ms-2 text-ultra-light"
													>
														<input type="checkbox" />
													</label>
													<span class="d-inline-block px-3 text-ultra-light fs-smaller"
														>Sélectionner toutes les prescrptions</span
													>
													<button class="btn pin-circle me-2 d-inline-block" disabled>
														<i
															class="icon-editer position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</th>
											</tr>
											<tr>
												<th>&nbsp;</th>
												<th class="text-center">
													<span class="text-medium fs-7">Fin de prescription</span>
												</th>
												<th class="text-start">
													<span class="text-medium fs-7">Intitul&eacute; de la prescription</span>
												</th>
												<th class="text-center"><span class="text-medium fs-7">Actions</span></th>
											</tr>
										</thead>
										<tbody>
											
											<tr class="alert" role="alert">
												<td class="rounded-start">
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td class="position-relative">
													<div class="d-flex flex-column justify-content-between text-center">
														<span class="">23 mars 2022</span>
														<span class="text-light fs-12p"> Dans moins de 1 mois </span>
													</div>
												</td>
												<td>Ventilation à pression positive continue PPC</td>
												<td class="text-center">
													<button class="btn pin-circle me-2">
														<i
															class="icon-editer position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
						
											<tr class="alert" role="alert">
												<td class="rounded-start">
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td class="position-relative">
													<div class="d-flex flex-column justify-content-between text-center">
														<span class="">23 mars 2022</span>
														<span class="text-light fs-12p"> Dans moins de 1 mois </span>
													</div>
												</td>
												<td cl>Ventilation à pression positive continue PPC</td>
												<td class="text-center">
													<button class="btn pin-circle me-2">
														<i
															class="icon-editer position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
					
											<tr class="alert" role="alert">
												<td class="rounded-start">
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td class="position-relative">
													<div class="d-flex flex-column justify-content-between text-center">
														<span class="">23 mars 2022</span>
														<span class="text-light fs-12p"> Dans moins de 1 mois </span>
													</div>
												</td>
												<td>Ventilation à pression positive continue PPC</td>
												<td class="text-center">
													<button class="btn pin-circle me-2">
														<i
															class="icon-editer position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
							
											<tr class="alert" role="alert">
												<td class="rounded-start">
													<label class="checkbox-wrap checkbox-primary custom-checkbox">
														<input type="checkbox" />
													</label>
												</td>
												<td class="position-relative">
													<div class="d-flex flex-column justify-content-between text-center">
														<span class="">23 mars 2022</span>
														<span class="text-light fs-12p"> Dans moins de 1 mois </span>
													</div>
												</td>
												<td>Ventilation à pression positive continue PPC</td>
												<td class="text-center">
													<button class="btn pin-circle me-2">
														<i
															class="icon-editer position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				
					<div class="col-12 d-sm-block d-lg-none">
						<span class="text-light d-block mb-2">Derniers évènements avec les agents Elivie</span>
					</div>
					<div class="col-12 d-sm-block d-lg-none">
						<div class="table-responsive bg-white table-rounded d-block d-lg-none">
							<table class="table table-md table-striped">
								<tbody>
									
									<tr>
										<td>
											<div class="d-flex justify-content-between pb-3">
												<div class="fs">
													<button class="btn pin-circle me-1">
														<i
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													Esther <span class="text-bold">Murphy</span>
												</div>
												<div>
													<button class="btn pin-circle">
														<i
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</div>
											</div>
											<div class="row">
												<div class="col-sm-6">
													<div class="d-flex flex-row-reverse flex-sm-row justify-content-start">
														<div class="align-self-end">
															<button class="btn pin-circle mb-1">
																<i
																	class="icon-ordonance position-absolute top-50 start-50 translate-middle"
																></i>
															</button>
														</div>
														<div class="intitule flex-grow-1 ps-2">
															<span class="d-block date">22/08/21 </span>
															intitulé e-de l'intervention sur 2 lignes pour tester
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="position-relative">
														<span class="text-medium d-block mb-2">Niveau d'observance</span>
														<div class="obs-green">
															<span>Plus de 4h</span><i class="icon-level-up ms-2"></i>
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
				
									<tr>
										<td>
											<div class="d-flex justify-content-between pb-3">
												<div class="fs">
													<button class="btn pin-circle me-1">
														<i
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													Flipper <span class="text-bold">Gareth</span>
												</div>
												<div>
													<button class="btn pin-circle">
														<i
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</div>
											</div>
											<div class="row">
												<div class="col-sm-6">
													<div class="d-flex flex-row-reverse flex-sm-row justify-content-start">
														<div class="align-self-end">
															<button class="btn pin-circle mb-1">
																<i
																	class="icon-ordonance position-absolute top-50 start-50 translate-middle"
																></i>
															</button>
														</div>
														<div class="intitule flex-grow-1 ps-2">
															<span class="d-block date">24/10/21 </span>
															pas d'intervention
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="position-relative">
														<span class="text-medium d-block mb-2">Niveau d'observance</span>
														<div class="obs-black">
															<span>Moins de 2h</span><i class="icon-level-down ms-2"></i>
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
				
									<tr>
										<td>
											<div class="d-flex justify-content-between pb-3">
												<div class="fs">
													<button class="btn pin-circle me-1">
														<i
															class="icon-pin position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
													Kenneth <span class="text-bold">Mc Dowell</span>
												</div>
												<div>
													<button class="btn pin-circle">
														<i
															class="icon-delete position-absolute top-50 start-50 translate-middle"
														></i>
													</button>
												</div>
											</div>
											<div class="row">
												<div class="col-sm-6">
													<div class="d-flex flex-row-reverse flex-sm-row justify-content-start">
														<div class="align-self-end">
															<button class="btn pin-circle mb-1">
																<i
																	class="icon-ordonance position-absolute top-50 start-50 translate-middle"
																></i>
															</button>
														</div>
														<div class="intitule flex-grow-1 ps-2">
															<span class="d-block date">12/11/21 </span>
															intitulé e-de l'intervention sur 2 lignes pour tester
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="position-relative">
														<span class="text-medium d-block mb-2">Niveau d'observance</span>
														<div class="obs-orange">
															<span>Entre 2h et 4h</span><i class="icon-stable ms-2"></i>
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				
				</div> -->
			</div>
		</div>
	</div>
</div>

<div
	class="modal modal-signin position-fixed d-none d-block bg-modal py-5"
	tabindex="-1"
	role="dialog"
	id="modalSignin">
	<div class="modal-dialog" role="document">
		<div class="text-center my-4">
			<i class="icon-white-logo-elivie"></i>
		</div>
		<h3 class="fw-bold mb-0">Contacter Elivie</h3>
		<div class="modal-content rounded-5 shadow">
			<div class="modal-header p-5 pb-4 border-bottom-0">
				<!-- <h5 class="modal-title">Modal title</h5> -->

				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>

			<div class="modal-body p-5 pt-0">
				<form class="">
					<div class="row">
						<div class="md-6">
							<div class="form-group color-primary mb-2 label-xs">
								<label for="inputEmail1">Patient</label> (requis)
								<input
									type="email"
									class="form-control rounded-pill"
									id="inputEmail1"
									placeholder="exemple@gmail.com" />
							</div>
						</div>
						<div class="md-6">
							<div class="form-group color-primary mb-2 label-xs">
								<label for="inputEmail1">N° patient</label> (requis)
								<input
									type="email"
									class="form-control rounded-pill"
									id="inputEmail1"
									placeholder="exemple@gmail.com" />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<textarea class="form-control rounded-pill" placeholder="bonjour..."> </textarea>
						</div>
					</div>
					<div class="form-group text-center">
						<button type="submit" class="btn btn-primary rounded-pill text-uppercase fs-5">Envoyer</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
