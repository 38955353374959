export class ComparisonOperators {
	static readonly equal: string = "eq";
	static readonly notEqual: string = "ne";
	static readonly greaterThan: string = "gt";
	static readonly greaterThanOrEqual: string = "gte";
	static readonly lessThan: string = "lt";
	static readonly lessThanOrEqual: string = "lte";
	static readonly contains: string = "contains";
	static readonly searchTerms: string = "searchTerms";
	static readonly in: string = "in";
	static readonly between: string = "between";
}
