export class AnnouncementCurrentStatus {
	static readonly coming: string = "A venir";
	static readonly finished: string = "Terminée";
	static readonly running: string = "En cours";
}

export class AnnouncementStatus {
	static readonly active: string = "active";
	static readonly archived: string = "archived";
	static readonly draft: string = "draft";
}

export class AnnouncementStatusMappings {
	static readonly statusMappings: Record<string, string> = {
		[AnnouncementStatus.active]: "Active",
		[AnnouncementStatus.archived]: "Archivée",
		[AnnouncementStatus.draft]: "Brouillon",
	};
}
