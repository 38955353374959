import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorComponent } from "./components/error";
import { FicheIntegrationHtmlComponent } from "./components/fiche-integration-html/fiche-integration-html.component";
import { ForbiddenComponent } from "./components/forbidden";
import { HomeComponent } from "./components/home";
import { NotFoundComponent } from "./components/not-found";
import { PrescriberIntegrationHtmlComponent } from "./components/prescriber-integration-html/prescriber-integration-html.component";
import { SigninCallbackComponent } from "./components/signin-callback";
import { SignoutCallbackComponent } from "./components/signout-callback";
import { UnauthorizedComponent } from "./components/unauthorized";
import { authInGuard, scopesInGuard } from "./guards";
import { ActivityAreaSelectorComponent } from "./components/activity-area-selector";
import { Scopes } from "./constants";

export const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		canActivate: [authInGuard],
		component: HomeComponent,
		data: {
			title: "Accueil",
			description: "Page d'accueil",
		},
	},
	{
		path: "admin",
		loadChildren: () => import("./components/admin/admin.module").then(m => m.AdminModule),
	},
	{
		path: "activity-area-selector",
		component: ActivityAreaSelectorComponent,
		canActivate: [scopesInGuard],
		data: {
			scopes: [Scopes.medicalUser],
			byPassPrescriberSelection: true,
			title: "Sélection de l'activité",
			description: "Sélection de l'activité",
		},
	},
	{
		path: "error",
		component: ErrorComponent,
		data: {
			title: "500 Internal Server Error - Une erreur est survenue",
			description: "500 Internal Server Error - Une erreur est survenue",
		},
	},
	{
		path: "forbidden",
		component: ForbiddenComponent,
		canActivate: [authInGuard],
		data: {
			title: "403 Forbidden - Vous n'avez pas l'autorisation d'accéder à cette page",
			description: "403 Forbidden - Vous n'avez pas l'autorisation d'accéder à cette page",
		},
	},
	{ path: "home", redirectTo: "" },
	{
		path: "not-found",
		component: NotFoundComponent,
		data: {
			title: "404 Not Found - La page demandée n'existe pas",
			description: "404 Not Found - La page demandée n'existe pas",
		},
	},
	{
		path: "medical-technical-advisor",
		loadChildren: () =>
			import("./components/medical-technical-advisor/medical-technical-advisor.module").then(
				m => m.MedicalTechnicalAdvisorModule
			),
	},
	{
		path: "prescriber",
		loadChildren: () => import("./components/prescriber/prescriber.module").then(m => m.PrescriberModule),
	},
	{
		path: "prescriber-integration-html",
		component: PrescriberIntegrationHtmlComponent,
		data: {
			title: "Tableau de bord (*)",
			description: "Tableau de bord (*)",
		},
	},
	{
		path: "fiche-integration-html",
		component: FicheIntegrationHtmlComponent,
		data: {
			title: "Fiche patient (*)",
			description: "Fiche patient (*)",
		},
	},
	{
		path: "scyova",
		loadChildren: () => import("./components/scyova/scyova.module").then(m => m.ScyovaModule),
	},
	{
		path: "signin-callback",
		component: SigninCallbackComponent,
		canActivate: [authInGuard],
		data: {
			title: "Vous êtes connecté(e)",
			description: "Vous êtes connecté(e)",
		},
	},
	{
		path: "signout-callback",
		component: SignoutCallbackComponent,
		data: {
			title: "Vous avez été déconnecté(e)",
			description: "Vous avez été déconnecté(e)",
		},
	},
	{
		path: "unauthorized",
		component: UnauthorizedComponent,
		data: {
			title: "401 Unauthorized - Vous devez être authentifié(e) pour accéder à cette page",
			description: "401 Unauthorized - Vous devez être authentifié(e) pour accéder à cette page",
		},
	},
	{ path: "**", redirectTo: "not-found" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
