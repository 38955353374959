import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SortDirection } from "@angular/material/sort";
import { TableFilterChoiceList, TableFiltersList, TableSortItem } from "src/app/@shared/types/table/table-view";
import { FilterTableColumnTypes } from "src/app/@shared/constants/filter-table-column-types.constant";
import { TableCommunicationService } from "src/app/@shared/services/table.communication.service";

@Component({
	selector: "app-table-header",
	templateUrl: "table-header.component.html",
})
export class TableHeaderComponent {
	filterTableColumnTypes = FilterTableColumnTypes;

	@Input() columnName?: string;
	@Input() headerLabel?: string;
	@Input() headerSubLabel?: string;
	@Input() headerWithoutShadow?: boolean;
	@Input() sort?: TableSortItem;
	@Input() sortColumnName?: string;
	@Input() sortToolTip?: string;

	@Input() filters!: TableFiltersList;
	@Input() filterChoiceList?: TableFilterChoiceList;
	@Input() filterColumnName?: string;
	@Input() filterOnly?: boolean;
	@Input() filterToolTip?: string;
	@Input() filterType?: FilterTableColumnTypes;

	@Input() isMobile!: boolean;

	@Output() readonly filterChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() readonly sortChange: EventEmitter<SortDirection> = new EventEmitter<SortDirection>();

	constructor(private _tableCommunicationService: TableCommunicationService) {}

	sortTable(): void {
		if (!this.sortColumnName || !this.sort) return;

		if (this.sortColumnName !== this.sort.sortColumn) {
			this.sort.sortColumn = this.sortColumnName;
			this.sort.sortDirection = "asc";
		} else {
			this.sort.sortDirection = this.sort.sortDirection === "asc" ? "desc" : "asc";
		}

		this.sortChange.emit(this.sort.sortDirection);
	}

	filterTable(): void {
		this.filterChange.emit(true);
		this._tableCommunicationService.changeFilters(this.isMobile);
	}
}
