export class ExternalReportPeriods {
	static readonly custom: string = "custom";
	static readonly oneMonth: string = "one-month";
	static readonly oneWeek: string = "one-week";
	static readonly oneYear: string = "one-year";
	static readonly sixMonths: string = "six-months";
	static readonly threeMonths: string = "three-months";
	static readonly threeWeeks: string = "three-weeks";
	static readonly twoWeeks: string = "two-weeks";

	static readonly defaultReportValue: string = ExternalReportPeriods.threeMonths;
	static readonly defaultDetailedReportValue: string = ExternalReportPeriods.oneWeek;

	static readonly maxReportValue: string = ExternalReportPeriods.oneYear;
	static readonly maxDetailedReportValue: string = ExternalReportPeriods.oneMonth;

	static readonly allAvailable: string[] = [
		ExternalReportPeriods.oneWeek,
		ExternalReportPeriods.twoWeeks,
		ExternalReportPeriods.threeWeeks,
		ExternalReportPeriods.oneMonth,
		ExternalReportPeriods.threeMonths,
		ExternalReportPeriods.sixMonths,
		ExternalReportPeriods.oneYear,
		ExternalReportPeriods.custom,
	];

	static readonly allAvailableDefaultReport: string[] = [
		ExternalReportPeriods.oneMonth,
		ExternalReportPeriods.threeMonths,
		ExternalReportPeriods.sixMonths,
		ExternalReportPeriods.oneYear,
		ExternalReportPeriods.custom,
	];

	static readonly allAvailableDetailedReport: string[] = [
		ExternalReportPeriods.oneWeek,
		ExternalReportPeriods.twoWeeks,
		ExternalReportPeriods.threeWeeks,
		ExternalReportPeriods.oneMonth,
		ExternalReportPeriods.custom,
	];

	static readonly labelMappingRecord: Record<string, string> = {
		[ExternalReportPeriods.oneWeek]: "1 semaine",
		[ExternalReportPeriods.twoWeeks]: "2 semaines",
		[ExternalReportPeriods.threeWeeks]: "3 semaines",
		[ExternalReportPeriods.oneMonth]: "1 mois",
		[ExternalReportPeriods.threeMonths]: "3 mois",
		[ExternalReportPeriods.sixMonths]: "6 mois",
		[ExternalReportPeriods.oneYear]: "1 an",
		[ExternalReportPeriods.custom]: "Personnalisée",
	};

	static readonly valueMappingRecord: Record<string, number> = {
		[ExternalReportPeriods.oneWeek]: 7,
		[ExternalReportPeriods.twoWeeks]: 14,
		[ExternalReportPeriods.threeWeeks]: 21,
		[ExternalReportPeriods.oneMonth]: 29,
		[ExternalReportPeriods.threeMonths]: 90,
		[ExternalReportPeriods.sixMonths]: 180,
		[ExternalReportPeriods.oneYear]: 364,
		[ExternalReportPeriods.custom]: 0,
	};
}
