<div *ngIf="item && rowItem" class="text-nowrap justify-content-left align-items-left" style="display: flex">
	<label class="checkbox-wrap checkbox-primary custom-checkbox">
		<input
			type="checkbox"
			(click)="$event.stopPropagation()"
			(change)="toggleSelectRow()"
			[checked]="rowItem.isSelected"
			[disabled]="!item.isActive" />
	</label>
</div>
