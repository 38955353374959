<!-- navigation du composant général -->
<nav
	class="navbar navbar-expand navbar-light topbar mb-1 mb-sm-0 static-top pb-2 pb-sm-0 pb-lg-2"
	[ngClass]="{ 'menu-xs': isMenuXSOpen }">
	<input
		type="hidden"
		attr.app-name="{{ appConfig.appName }}"
		attr.app-company="{{ appConfig.appCompanyName }}"
		attr.app-build-version="{{ appConfig.version.build }}"
		attr.app-version="{{ appConfig.version.version }}"
		attr.app-version-name="{{ appConfig.version.name }}"
		attr.current-medical-user-id="{{ currentMedicalUserId }}" />
	<div class="row ms-1">
		<div class="col-9 col-sm-6 col-xl-3 order-xl-1 px-0 my-lg-2">
			<div
				class="d-flex flex-wrap lh-36"
				[ngClass]="{ 'flex-column': !alignLabels, 'flex-row gap-2': alignLabels }">
				<span class="fs-2-5 text-light text-light-din">{{ firstLabel }}</span>
				<span class="fs-2-5 text-bold header-second-label text-truncate">
					{{ secondLabel }}
					@if (showSecondLabelAbbr) {
						<abbr [title]="secondLabelAbbr">*</abbr>
					}
				</span>
				@if (
					patientOrganization &&
					!isPatientInquiryInProgress &&
					appConfig.appCompanyName.toLowerCase() !== patientOrganization.toLowerCase() &&
					appConfig.appCompanyName.toLowerCase() !== companies.asdia.toLowerCase()
				) {
					<span class="fs-5 text-light text-light-din d-none d-xl-block">
						Patient pris en charge par {{ patientOrganization }}
					</span>
				}
				@if (showMedicalUserPatientsSelection && currentNbSelectedPrescriberIds > 0) {
					@if (prescriberCardsSelection$ | async; as prescriberCardsSelection) {
						<div class="d-flex">
							@if (hasMoreThanOnePatientBase) {
								@if (currentNbSelectedPrescriberIds > 1) {
									<div class="btn pin-circle pt-1 text-medium text-regular-din fs-7">
										{{ currentNbSelectedPrescriberIds }}
									</div>
								}
								<span
									class="text-light align-slef-center pt-1 fs-6"
									[ngClass]="currentNbSelectedPrescriberIds > 1 ? 'mx-1' : 'me-1'">
									@if (currentNbSelectedPrescriberIds) {
										<ng-container [ngPlural]="currentNbSelectedPrescriberIds">
											<ng-template ngPluralCase="=1">Patientèle : </ng-template>
											<ng-template ngPluralCase="other">Patientèles : </ng-template>
										</ng-container>
									}
								</span>
								<button
									(click)="openHealthCenterSelection(prescriberCardsSelectionLoaded)"
									class="btn p-0 text-regular text-regular-roboto text-decoration-underline fs-6 align-slef-center">
									<ng-container [ngPlural]="prescriberCardsSelection.nbTotalWorkplaces">
										<ng-template ngPluralCase="=1">1 établissement</ng-template>
										<ng-template ngPluralCase="other"
											>{{
												prescriberCardsSelection.nbTotalWorkplaces
											}}
											établissements</ng-template
										>
									</ng-container>
								</button>
							}
						</div>
					}
				}

				<!-- SKELETON -->
				<!-- <div class="mh-40 position-relative">
					<div
						class="h-100 w-100  skeleton-box d-flex justify-content-between"
					>
						<div class="rounded-circle p-3 bg-white me-1"></div>
						<div class="flex-fill d-flex align-items-center">
							<div class="w-100 rounded-pill bg-white p-3 pt-2"></div>
						</div>
					</div>
				</div> -->
				<!-- END SKELETON -->
			</div>
		</div>
		<div
			class="col-3 col-sm-6 col-xl-3 order-xl-3 px-0 my-3 my-sm-2 d-flex align-items-sm-center align-items-xl-end">
			<div class="d-flex w-100 mx-0 position-relative">
				<div
					class="flew-grow-1 w-100 d-none d-sm-inline-block px-0 pe-5"
					[ngClass]="{
						'col-sm-10': showActions,
						'col-sm-8': !showActions,
					}">
					@if (isScopeSecretary) {
						@if (secretaryContacts$ | async; as secretaryContacts) {
							<ul class="list-entete-r1 ps-0 mb-2 pe-4 mt-0 mx-auto">
								<li
									class="nav-item dropdown no-arrow text-end mb-1 d-flex flex-column flex-xxxl-row justify-content-end">
									<div class="align-self-end align-self-xxxl-center">
										<a
											href="{{ appConfig.appCompanyUrl }}"
											title="Aller sur le site d'{{ appConfig.appCompanyName }}"
											rel="noopener"
											target="_blank"
											class="d-block logo-{{ appConfig.appCompanyName }} logo-header z-index-1 ">
											@if (appConfig.appCompanyName === companies.asdia) {
												<img src="assets/img/logo-{{ appConfig.appCompanyName }}.svg" alt="" />
											}
											@if (
												appConfig.appCompanyName === companies.hospidom ||
												appConfig.appCompanyName === companies.elivie
											) {
												<img
													src="assets/img/logo-nobaseline-{{ appConfig.appCompanyName }}.svg"
													alt="" />
											}
										</a>
									</div>
									@if (showContacts && hasMoreThanOneContactAgency) {
										<div class="select">
											<select
												class="form-select2 fs-5-5 text-gray border-bottom"
												aria-label="Default select example">
												<option selected disabled>Voir ses interlocuteurs</option>
												@for (agency of secretaryContacts.agencies; track agency.code) {
													<option>{{ agency.city }} | {{ agency.phoneNumber }}</option>
												}
											</select>
										</div>
									}
									@if (showContacts && !hasMoreThanOneContactAgency) {
										<div class="ms-1 mt-1 align-self-end text-gray border-xxxl-start">
											<span class="ms-2 pt-1 text-capitalize text-medium fs-5-5 fs-xxl-24">{{
												secretaryContacts.agencies[0].phoneNumber
											}}</span>
										</div>
									}
								</li>
							</ul>
						}
					}
					@if (isScopePrescriber) {
						@if (prescriberContacts$ | async; as prescriberContacts) {
							<ul class="list-entete-r1 ps-0 mb-2 pe-4 mt-0 mx-auto">
								<li
									class="nav-item dropdown no-arrow text-end mb-1 d-flex flex-column flex-xxxl-row justify-content-end">
									<div class="align-self-end align-self-xxxl-center">
										<a
											href="{{ appConfig.appCompanyUrl }}"
											title="Aller sur le site d'{{ appConfig.appCompanyName }}"
											rel="noopener"
											target="_blank"
											class="d-block logo-{{ appConfig.appCompanyName }} logo-header z-index-1 ">
											@if (appConfig.appCompanyName === companies.asdia) {
												<img src="assets/img/logo-{{ appConfig.appCompanyName }}.svg" alt="" />
											}
											@if (
												appConfig.appCompanyName === companies.hospidom ||
												appConfig.appCompanyName === companies.elivie
											) {
												<img
													src="assets/img/logo-nobaseline-{{ appConfig.appCompanyName }}.svg"
													alt="" />
											}
										</a>
									</div>
									@if (showContacts && hasMoreThanOneContactAgency && hasMoreThanOneContactCmt) {
										<div class="select">
											<select
												class="form-select2 fs-5-5 text-gray border-bottom"
												aria-label="Default select example">
												<option selected disabled>Voir ses interlocuteurs</option>
												<optgroup label="Agences">
													@for (
														agencyItem of prescriberContacts.agencyItems;
														track agencyItem.code
													) {
														<option>
															{{ agencyItem.city }} | {{ agencyItem.phoneNumber }}
														</option>
													}
												</optgroup>
												<optgroup label="Conseillers Médico-Techniques">
													@for (
														medicalTechnicalAdvisorItem of prescriberContacts.medicalTechnicalAdvisorItems;
														track medicalTechnicalAdvisorItem.id
													) {
														<option>
															{{ medicalTechnicalAdvisorItem.displayName }} |
															{{ medicalTechnicalAdvisorItem.phoneNumber | phone: "FR" }}
														</option>
													}
												</optgroup>
											</select>
										</div>
									}
									@if (showContacts && hasMoreThanOneContactAgency && !hasMoreThanOneContactCmt) {
										<div class="select">
											<select
												class="form-select2 fs-5-5 text-gray border-bottom"
												aria-label="Default select example">
												<option selected disabled>Voir ses interlocuteurs</option>
												@for (
													agencyItem of prescriberContacts.agencyItems;
													track agencyItem.code
												) {
													<option>
														{{ agencyItem.city }} | {{ agencyItem.phoneNumber }}
													</option>
												}
											</select>
										</div>
									}
									@if (
										showContacts &&
										prescriberContacts.agencyItems.length !== 0 &&
										!hasMoreThanOneContactAgency
									) {
										<div class="ms-1 mt-1 align-self-end text-gray border-xxxl-start">
											<span class="ms-2 pt-1 text-capitalize text-medium fs-5-5 fs-xxl-24">{{
												prescriberContacts.agencyItems[0].phoneNumber
											}}</span>
										</div>
									}
									@if (specificPhoneNumber) {
										<div class="ms-1 mt-1 align-self-end text-gray border-xxxl-start">
											<span class="ms-2 pt-1 text-capitalize text-medium fs-5-5 fs-xxl-24">{{
												specificPhoneNumber | phone: "FR"
											}}</span>
										</div>
									}
								</li>
								@if (showContacts) {
									<li class="text-end fs-18 d-flex flex-column flex-xxxl-row justify-content-end">
										@if (
											prescriberContacts.medicalTechnicalAdvisorItems.length !== 0 &&
											!hasMoreThanOneContactCmt
										) {
											<span
												class="text-capitalize text-gray border-xxxl-end me-xxxl-1 pe-xxxl-1 d-none d-sm-inline-block fs-5-5 fs-xl-5">
												{{ prescriberContacts.medicalTechnicalAdvisorItems[0].displayName }}
											</span>
											<span class="text-capitalize d-none d-sm-inline-block fs-5-5 fs-xl-5">{{
												prescriberContacts.medicalTechnicalAdvisorItems[0].phoneNumber
													| phone: "FR"
											}}</span>
										}
										@if (hasMoreThanOneContactCmt && !hasMoreThanOneContactAgency) {
											<div class="select">
												<select
													class="form-select2 fs-5-5 text-gray border-bottom"
													aria-label="Default select example">
													<option selected disabled>Voir ses interlocuteurs</option>
													@for (
														medicalTechnicalAdvisorItem of prescriberContacts.medicalTechnicalAdvisorItems;
														track medicalTechnicalAdvisorItem.id
													) {
														<option>
															{{ medicalTechnicalAdvisorItem.displayName }} |
															{{ medicalTechnicalAdvisorItem.phoneNumber | phone: "FR" }}
														</option>
													}
												</select>
											</div>
										}
									</li>
								}
							</ul>
						}
					}
				</div>
				@if (showActions) {
					<div class="col-sm-4 col-xs-6 px-0 d-sm-none">
						<ul
							class="list-entete-r2 pe-3 my-0 mx-auto text-start fs-2 text-end d-flex flex-column menu-burger position-fixed">
							<li class="nav-item mh-60 nav-item-contact d-inline-block col position-relative">
								<a
									role="dialog"
									(keyup.enter)="openCloseXSMenu(false)"
									[ngStyle]="{
										'pointer-events': 'auto',
									}"
									class="pin-circle xs-menu pin-circle-xl z-index-1 cursor-pointer"
									(click)="openCloseXSMenu(false)">
									<i class="icon-burger-xs-menu fs-6"></i>
								</a>
							</li>
							<!-- [WP-1542] : Menu burger - Sticky 
								###
	
								Si ce bouton n'est pas utilisé, doit alors être supprimé
	
								###
								<li
								[matTooltipDisabled]="isPrescriber || isSuperAdmin"
								matTooltip="{{ this.adminMtaAdministrativeMessage }}"
								class="nav-item mh-60 nav-item-contact d-inline-block col position-relative"
							>
								<a
									[ngClass]="{ disabled: !(isPrescriber || isSuperAdmin) }"
									[ngStyle]="{
										'pointer-events': !(isPrescriber || isSuperAdmin) ? 'none' : 'auto'
									}"
									class="pin-circle pin-circle-xl z-index-1 cursor-pointer"
									(click)="openMessageDialog()"
								>
									<i class="icon-contact fs-4"></i>
									<span class="info-b bg-primary p-2 shadow text-light fadeInRight text-nowrap">
										Contacter
										<span class="text-capitalize">{{ appConfig.appCompanyName }}</span>
									</span>
								</a>
							</li> -->
						</ul>
					</div>
				}
				@if (showActions) {
					@if (prescriberCardsSelection$ | async; as prescriberCardsSelection) {
						@if (prescriberCardsSelection.nbTotalWorkplaces !== 0) {
							<div class="px-0">
								<div
									class="menu-right rounded bg-white shadow position-fixed d-none d-sm-block z-index-112">
									<ul class="components p-0 m-0 position-relative mh-24 wpx-31">
										<li
											[matTooltipDisabled]="isPrescriber || isSuperAdmin || isScopeSecretary"
											matTooltip="{{ this.adminMtaAdministrativeMessage }}"
											class="position-relative">
											<a
												role="dialog"
												(click)="openMessageDialog()"
												(keyup.enter)="openMessageDialog()"
												[ngClass]="{
													disabled: !(isPrescriber || isSuperAdmin || isScopeSecretary),
												}"
												[ngStyle]="{
													'pointer-events': !(
														isPrescriber ||
														isSuperAdmin ||
														isScopeSecretary
													)
														? 'none'
														: 'auto',
												}"
												class="btn-bulle cursor-pointer d-flex align-self-center text-decoration-none position-absolute">
												<i class="icon-message fs-4"> </i>
												<span
													class="info-b bg-primary p-2 shadow text-light fadeInRight text-nowrap">
													Contacter
													<span class="text-capitalize">{{ appConfig.appCompanyName }}</span>
												</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						}
					}
				}
			</div>
		</div>
		<div class="searchbar col-12 col-xl-6 order-xl-2 px-0 mt-sm-3 mb-2 mt-lg-2">
			@if (showSearchPatientsForm) {
				<form
					[formGroup]="searchPatientsForm"
					(ngSubmit)="onSubmit()"
					class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 mw-100 navbar-search px-lg-6">
					<input type="hidden" formControlName="familyName" />
					<input type="hidden" formControlName="givenName" />
					<input type="hidden" formControlName="patientId" />
					<input type="hidden" formControlName="prescriptionId" />
					<div class="input-group z-index-100" [class.z-index-115]="isBlurOpen">
						<input
							matInput
							[matAutocomplete]="auto"
							(focus)="onFocusBlur(false)"
							placeholder="Chercher un patient par son nom, prénom ou date de naissance"
							formControlName="patientDisplayName"
							type="text"
							class="form-control rounded-pill bg-white border-2 small shadow fs-5"
							aria-label="Search"
							aria-describedby="basic-addon2"
							#inputSearch />
						<div class="input-group-search z-index-99">
							<button
								type="button"
								title="Chercher un patient par son nom, prénom ou date de naissance"
								class="btn btn-hover rounded-end rounded-5 border-2"
								type="button">
								<i class="icon-search fs-4"></i>
							</button>
						</div>
						<mat-autocomplete
							#auto="matAutocomplete"
							[displayWith]="displayPatient"
							(optionSelected)="onPatientSelected($event.option.value)"
							class="custom-autocomplete searchPatient">
							@for (filteredPatient of filteredPatients$ | async; track filteredPatient.patientId) {
								<mat-option [value]="filteredPatient">
									<div class="searchbar text-light">
										<div class="d-flex my-3 mx-2 w-100 justify-content-between">
											<div class="text-primary">
												<i
													class="fs-1 me-2 d-block"
													[ngClass]="{
														'icon-patient': filteredPatient.sex === sexes.male,
														'icon-patiente': filteredPatient.sex === sexes.female,
														'icon-patient-unknown': filteredPatient.sex === null,
													}">
												</i>
											</div>
											<div class="flex-grow-1 fs-5 align-self-center lh-20">
												{{ filteredPatient.givenName | titlecase }}&nbsp;
												<span class="text-uppercase text-bold text-bold-roboto"
													>{{ filteredPatient.familyName }}&nbsp;</span
												>
												<div class="fs-14 d-flex">
													<div>
														@if (filteredPatient.birthDate) {
															{{ filteredPatient.age }} ans ({{
																filteredPatient.birthDate | titlecase
															}})
														}
														@if (filteredPatient.lunarBirthDate) {
															{{
																filteredPatient.lunarBirthDate
																	| lunarDateString
																	| titlecase
															}}
														}
														| {{ filteredPatient.postalCode | titlecase }}
														{{ filteredPatient.city | titlecase }} |
													</div>
													<div class="d-flex">
														@for (
															treatmentCode of filteredPatient.activeTreatmentCodesForPatient;
															track treatmentCode
														) {
															<span
																>&nbsp;
																{{ treatmentCode | label: "treatmentCode" | titlecase }}
																@if (!$last) {
																	<span>&nbsp;+&nbsp;</span>
																}
															</span>
														}
														@if (filteredPatient.isPinned) {
															<span class="position-absolute end-0 pe-3">
																<span class="text-primary text-bold">{{
																	filteredPatient.sex === "female"
																		? "Patiente favorite"
																		: "Patient favori"
																}}</span>
															</span>
														}
													</div>
												</div>
											</div>
											@if (!filteredPatient.isPinned) {
												<span class="pe-3 align-self-center">
													<button
														title="Ajouter aux favoris"
														class="btn pin-circle me-2"
														(click)="addToFavorites(filteredPatient, $event)">
														<i
															class="icon-pin position-absolute top-50 start-50 translate-middle"></i>
													</button>
												</span>
											}
										</div>
									</div>
								</mat-option>
							}
						</mat-autocomplete>
					</div>
				</form>
			}
		</div>
	</div>

	<!-- Bloc menu Smartphone -->
	<app-navigation-mobile [patientId]="patientId" (closeMenu)="openCloseXSMenu(true)" [isMenuXSOpen]="isMenuXSOpen" />
</nav>

<div
	class="app-blur"
	(click)="onFocusBlur(false)"
	[class.d-none]="!isBlurOpen"
	[class.over]="isBlurOpen"
	(keyup.enter)="onFocusBlur(false)"
	role="dialog"></div>
