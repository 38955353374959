import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	GetPatientObservationsStatsRequest,
	PatientObservationsStats,
} from "../models/observance/patient-observations-stats";
import { PatientObservationsDetail } from "../models/observance/patient-observations-detail";
import { BrowseObservationByPatientCommand } from "../models/observance/browse-observation-by-patient";
import { PagerObservationPatient } from "../models/observance/pager-observation-patient.interface";
import { ObservationAveragePatient } from "../models/observance/browse-observation-average-by-patient";

@Injectable({
	providedIn: "root",
})
export class ObservanceService {
	constructor(private _httpClient: HttpClient) {}

	getObservationsStatsByPatient(
		medicalUserId: string,
		patientId: string,
		command: GetPatientObservationsStatsRequest
	): Observable<PatientObservationsStats> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PatientObservationsStats>(
			`/observance/medical-users/${medicalUserId}/patients/${patientId}/stats`,
			{
				params: params,
			}
		);
	}

	searchObservationsByPatient(
		medicalUserId: string,
		patientId: string,
		command: BrowseObservationByPatientCommand
	): Observable<PagerObservationPatient> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerObservationPatient>(
			`/observance/medical-users/${medicalUserId}/patients/${patientId}/search`,
			{
				params: params,
			}
		);
	}

	searchMonthlyAverageObservationsByPatient(
		medicalUserId: string,
		patientId: string,
		command: BrowseObservationByPatientCommand
	): Observable<ObservationAveragePatient> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<ObservationAveragePatient>(
			`/observance/medical-users/${medicalUserId}/patients/${patientId}/search/avg`,
			{
				params: params,
			}
		);
	}

	searchObservationsDetailsByPatient(
		medicalUserId: string,
		patientId: string,
		command: BrowseObservationByPatientCommand
	): Observable<PatientObservationsDetail> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PatientObservationsDetail>(
			`/observance/medical-users/${medicalUserId}/patients/${patientId}/search/detailed`,
			{
				params: params,
			}
		);
	}
}
