export class UserModes {
	static readonly admin: string = "admin";
	static readonly medicalTechnicalAdvisor: string = "medical-technical-advisor";
	static readonly mediviewLocalAdminAsdia: string = "mediview-local-admin-asdia";
	static readonly mediviewLocalAdminElivie: string = "mediview-local-admin-elivie";
	static readonly messageController: string = "message-controller";
	static readonly prescriber: string = "prescriber";
	static readonly superAdmin: string = "super-admin";
	static readonly unknown: string = "unknown";
	static readonly unauthenticated: string = "unauthenticated";
}
