import { Component, Input, Output, EventEmitter, inject } from "@angular/core";
import { CommunicationComService } from "src/app/services";
import { UserAgreementLegalDocument, UserAgreementStatus } from "src/app/models";
import { NavigationBaseComponent, LegalDocumentService } from "src/app/@shared";
import { Observable } from "rxjs";

@Component({
	selector: "app-navigation-mobile",
	templateUrl: "./navigation-mobile.component.html",
})
export class NavigationMobileComponent extends NavigationBaseComponent {
	private readonly communicationComService = inject(CommunicationComService);
	private readonly legalDocumentService = inject(LegalDocumentService);

	@Input() patientId = "";
	@Input() isMenuXSOpen = false;
	@Output() closeMenu = new EventEmitter<void>();

	legalNotice$: Observable<UserAgreementLegalDocument | undefined> = this.legalDocumentService.legalNotice$;
	privacyPolicy$: Observable<UserAgreementLegalDocument | undefined> = this.legalDocumentService.privacyPolicy$;
	userTermsAndConditionsAgreementStatus$: Observable<UserAgreementStatus | undefined> =
		this.legalDocumentService.userTermsAndConditionsAgreementStatus$;

	closeBurgerMenu() {
		this.closeMenu.emit();
	}

	openMessageDialog(): void {
		const data: any = {
			patientId: this.patientId,
		};
		this.closeBurgerMenu();
		this.communicationComService.openModalContact(data);
	}

	openLegalDocumentModal(legalDocument: UserAgreementLegalDocument): void {
		this.closeBurgerMenu();
		this.legalDocumentService.openLegalDocumentModal(legalDocument);
	}
}
