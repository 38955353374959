import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "durationFormat",
	pure: false,
})
export class DurationFormatPipe implements PipeTransform {
	transform(value: any, sourceType: any, format: any): any {
		if (value === null) return "";
		if (value === 0) return value;

		if (sourceType === "min" && format === "hh") return this.minutesToHours(value);

		if (sourceType === "min" && format === "mm") return this.minutesToMinutes(value);

		if (sourceType === "s" && format === "hh") return this.secondsToHours(value);
		let seconds: any;
		let minutes: any;
		let hours: any;
		let days: any;

		if (sourceType === "h" && format === "hhmm") {
			hours = Math.floor(value);
			minutes = Math.floor((value % 1) * 60);
			return this.format(format, seconds, minutes, hours, days);
		}

		if (sourceType === "min" && format === "hhmm") {
			seconds = Math.floor((value * 60) % 60);
			minutes = Math.floor(value % 60);
			hours = Math.floor(value / 60);
			return this.format(format, seconds, minutes, hours, days);
		}

		if (sourceType === "ms" && format === "hhmmss") {
			seconds = Math.floor((value / 1000) % 60);
			minutes = Math.floor((value / (1000 * 60)) % 60);
			hours = Math.floor(value / (1000 * 60 * 60));
			return this.format(format, seconds, minutes, hours, days);
		}

		if (sourceType === "s" && format === "hhmmss") {
			seconds = Math.floor(value % 60);
			minutes = Math.floor((value / 60) % 60);
			hours = Math.floor(value / 60 / 60);
			return this.format(format, seconds, minutes, hours, days);
		}
		if (sourceType === "s" && format === "hhmm") {
			minutes = Math.floor((value / 60) % 60);
			hours = Math.floor(value / 60 / 60);
			return this.format(format, seconds, minutes, hours, days);
		}

		if (sourceType === "ms" && (format === "ddhhmmss" || format === "ddhhmmssLong")) {
			seconds = Math.floor((value / 1000) % 60);
			minutes = Math.floor((value / (1000 * 60)) % 60);
			hours = Math.floor((value / (1000 * 60 * 60)) % 24);
			days = Math.floor(value / (1000 * 60 * 60 * 24));
			return this.format(format, seconds, minutes, hours, days);
		}

		if (sourceType === "s" && (format === "ddhhmmss" || format === "ddhhmmssLong")) {
			seconds = Math.floor(value % 60);
			minutes = Math.floor((value / 60) % 60);
			hours = Math.floor((value / 60 / 60) % 24);
			days = Math.floor(value / 60 / 60 / 24);
			return this.format(format, seconds, minutes, hours, days);
		}

		return value;
	}

	private format(format: string, seconds: number, minutes: number, hours: number, days: number) {
		let resultFormatted = "";

		const localDays = days != null ? (days < 10 ? "0" + days : days.toString()) : "";
		const localHoursWithoutZero = hours != null ? hours.toString() : "";
		const localHours = hours != null ? (hours < 10 ? "0" + hours : hours.toString()) : "";
		const localMinutes = minutes != null ? (minutes < 10 ? "0" + minutes : minutes.toString()) : "";
		const localSeconds = seconds != null ? (seconds < 10 ? "0" + seconds : seconds.toString()) : "";

		switch (format) {
			case "hhmm":
				if (hours > 0) {
					resultFormatted = resultFormatted + `${localHoursWithoutZero}h`;
				} else {
					resultFormatted = `0h`;
				}

				if (minutes > 0) {
					resultFormatted = resultFormatted + ` ${localMinutes}min`;
				} else {
					resultFormatted = resultFormatted + ` 00min`;
				}

				break;
			case "hhmmss":
				if (hours > 0) {
					resultFormatted = resultFormatted + `${localHoursWithoutZero}`;
				} else {
					resultFormatted = `00`;
				}

				if (minutes > 0) {
					resultFormatted = resultFormatted + `:${localMinutes}`;
				} else {
					resultFormatted = resultFormatted + `:00`;
				}

				if (seconds > 0) {
					resultFormatted = resultFormatted + `:${localSeconds}`;
				} else {
					resultFormatted = resultFormatted + `:00`;
				}

				break;
			case "ddhhmmss":
				if (days > 0) {
					resultFormatted = resultFormatted + `${localDays}d, `;
				}

				if (hours > 0) {
					resultFormatted = resultFormatted + `${localHours}h, `;
				}

				if (minutes > 0) {
					resultFormatted = resultFormatted + `${localMinutes}m, `;
				}

				if (seconds > 0) {
					resultFormatted = resultFormatted + `${localSeconds}s`;
				}

				break;
			case "ddhhmmssLong":
				if (days > 0) {
					resultFormatted = resultFormatted + `${localDays} days, `;
				}

				if (hours > 0) {
					resultFormatted = resultFormatted + `${localHours} hours, `;
				}

				if (minutes > 0) {
					resultFormatted = resultFormatted + `${localMinutes} minutes, `;
				}

				if (seconds > 0) {
					resultFormatted = resultFormatted + `${localSeconds} seconds`;
				}

				break;
		}

		return resultFormatted;
	}

	private minutesToHours(value: number): string {
		return `${Math.floor(value / 60)}`;
	}

	private secondsToHours(value: number): string {
		return `${Math.floor(value / 3600)}`;
	}

	private minutesToMinutes(value: number): string {
		const minutes = value % 60;

		if (minutes < 10) return `0${minutes}`;

		return `${minutes}`;
	}
}
