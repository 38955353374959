export class UserModalConfirmation {
	static readonly titleUserLockoutOn: string = "Désactivation de l'utilisateur";
	static readonly titleUserLockoutOff: string = "Réactivation de l'utilisateur";
	static readonly titleUserResetPassword: string = "Réinitialisation du mot de passe";
	static readonly titleUserAccountCreation: string = "Création d'un nouveau compte";

	static readonly messageUserLockoutOn: string =
		"La désactivation entraînera le blocage du compte, l'utilisateur ne pourra plus se connecter à l'application";
	static readonly messageUserLockoutOff: string =
		"Souhaitez-vous réactiver le compte et permettre à l'utilisateur de se connecter à nouveau?";
	static readonly messageUserResetPassword: string = "Souhaitez-vous réinitialiser le mot de passe de l'utilisateur?";
	static readonly messageUserAccountCreation: string = "Souhaitez-vous envoyer le mail de création de compte?";

	static readonly confirmText: string = "Confirmer";
	static readonly cancelText: string = "Annuler";
}

export class HealthCenterModalConfirmation {
	static readonly titleHealthCenterLockoutOn: string = "Désactivation de l'établissement";
	static readonly titleHealthCenterLockoutOff: string = "Réactivation de l'établissement";
	static readonly messageHealthCenterLockoutOn: string =
		"La désactivation de l'établissement entraînera également le blocage des partages des membres rattachés à cet établissement.";
	static readonly messageHealthCenterLockoutOff: string =
		"Souhaitez-vous réactiver l'établissement et rétablir le partage des membres rattachés à cet établissement?";
	static readonly confirmText: string = "Confirmer";
	static readonly cancelText: string = "Annuler";
}

export class HealthCenterPrescriberModalConfirmation {
	static readonly titleHealthCenterMediViewLocalAdminRoleOn: string = "Ajout du rôle admin d'établissement";
	static readonly titleHealthCenterMediViewLocalAdminRoleOff: string = "Suppression du rôle admin d'établissement";
	static readonly messageHealthCenterMediViewLocalAdminRoleOn: string =
		"L'ajout du rôle d'administrateur d'établissement permettra au médecin d'accéder aux patientèles de tous les médecins de l'établissement, ainsi que partager ces patientèles à tout membre de l'établissement.";
	static readonly messageHealthCenterMediViewLocalAdminRoleOff: string =
		"La suppression du rôle d'administrateur d'établissement retirera l'accès aux autres patientèles de l'établissement mais le médecin conservera l'accès à ses patientèles et aux patientèles qui lui sont partagées." +
		"\n" +
		" Les partages déjà effectués via ce rôle d'administrateur resteront actifs.";
	static readonly cancelText: string = "Annuler";
}
