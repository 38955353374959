<ng-container
	*ngIf="
		filterType === filterTableColumnTypes.in ||
		filterType === filterTableColumnTypes.contains ||
		filterType === filterTableColumnTypes.stringContains ||
		filterType === filterTableColumnTypes.comparison ||
		filterType === filterTableColumnTypes.date ||
		filterType === filterTableColumnTypes.dateRange ||
		filterType === filterTableColumnTypes.searchTerms
	">
	<div
		appClickOutside
		(clickOutside)="closeFilter()"
		matTooltip="{{ filterToolTip }}"
		matTooltipPosition="left"
		matTooltipShowDelay="500">
		<input
			title="{{ filterToolTip }}"
			type="checkbox"
			name="table-{{ columnName }}"
			id="table-{{ columnName }}"
			class="option-popin d-block"
			[checked]="searchChecked"
			(change)="changeChecked($event.target)" />
		<label for="table-{{ columnName }}" class="btn btn-options pin-circle ms-2 z-index-1">
			<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
		</label>
		<div
			class="option-popin-content text-start shadow bg-white rounded-bottom"
			(keyup.enter)="performSearch()"
			role="dialog">
			<!-- stringContains && searchTerms -->
			<ng-container
				*ngIf="
					filterType === filterTableColumnTypes.stringContains ||
					filterType === filterTableColumnTypes.searchTerms
				">
				<div class="p-3">
					<form class="mr-auto ml-md-3 my-2 mw-100">
						<div class="input-group">
							<input
								[(ngModel)]="filterSearchTerms"
								matinput
								name="searchTerms"
								type="text"
								class="form-control rounded-pill bg-white border-1 small py-1 px-3"
								aria-label="Search"
								aria-describedby="basic-addon2" />
						</div>
					</form>
				</div>
			</ng-container>

			<!-- date -->
			<ng-container *ngIf="filterType === filterTableColumnTypes.date">
				<div class="p-3">
					<form class="mr-auto ml-md-3 my-2 mw-100">
						<div class="input-group">
							<input
								[(ngModel)]="filterDate"
								matinput
								name="searchDate"
								type="date"
								class="form-control rounded-pill bg-white border-1 small py-1 px-3"
								aria-label="dd/MM/yyyy" />
						</div>
					</form>
				</div>
			</ng-container>

			<!-- in && contains -->
			<ng-container
				*ngIf="filterType === filterTableColumnTypes.in || filterType === filterTableColumnTypes.contains">
				<div class="px-3 py-2" *ngIf="filterChoiceList">
					<form class="mr-auto ml-md-3 my-2 mw-100">
						<div *ngFor="let item of filterChoiceList.items; let i = index" class="input-group mb-2">
							<input
								type="checkbox"
								class="custom-checkbox"
								id="checkbox-{{ columnName | lowercase }}-{{ i }}"
								name="checkbox-{{ columnName | lowercase }}-{{ i }}"
								[ngModel]="item.isSelected"
								(ngModelChange)="item.isSelected = $event" />
							<label
								for="checkbox-{{ columnName | lowercase }}-{{ i }}"
								class="custom-radio position-relative"
								>{{ item.label }}</label
							>
						</div>
					</form>
				</div>
			</ng-container>

			<!-- comparison -->
			<ng-container *ngIf="filterType === filterTableColumnTypes.comparison">
				<div class="px-3 py-2" *ngIf="filterChoiceList">
					<form class="mr-auto ml-md-3 my-2 mw-100">
						<div *ngFor="let item of filterChoiceList.items; let i = index" class="input-group mb-2">
							<input
								type="checkbox"
								class="custom-checkbox"
								id="comparison-{{ columnName | lowercase }}-{{ i }}"
								name="comparison-{{ columnName | lowercase }}-{{ i }}"
								[ngModel]="item.isSelected"
								(ngModelChange)="resetSearch(true); item.isSelected = $event" />
							<label
								for="comparison-{{ columnName | lowercase }}-{{ i }}"
								class="custom-radio position-relative"
								>{{ item.label }}</label
							>
						</div>
					</form>
				</div>
			</ng-container>

			<div class="row g-0 btn-filters">
				<button
					type="button"
					(click)="resetSearch(false)"
					class="col-6 btn text-uppercase btn-default text-medium">
					effacer
				</button>
				<button type="button" (click)="performSearch()" class="col-6 btn text-uppercase text-white text-medium">
					filtrer
				</button>
			</div>
		</div>
	</div>
</ng-container>
