import { Renderer2, Injectable } from "@angular/core";
import { ExternalScripts } from "../constants/external-scripts.constant";

@Injectable({
	providedIn: "root",
})
export class ScriptsService {
	private scripts: any = {};

	constructor() {
		ExternalScripts.availableExternalScripts.map((script: any) => {
			this.scripts[script.name] = {
				loaded: false,
				src: script.src,
			};
		});
	}

	/**
	 * Append the JS tag to the head.
	 * @param renderer The Angular Renderer
	 * @param src The path to the script
	 * @returns A Promise with the script element
	 */
	loadScript(renderer: Renderer2, name: string) {
		return new Promise((resolve, _) => {
			if (this.scripts[name].loaded) {
				return resolve({ script: name, loaded: true, status: "Already Loaded" });
			}

			//load script
			const script = renderer.createElement("script");
			script.type = "text/javascript";
			script.src = this.scripts[name].src;

			if (script.readyState) {
				//IE
				script.onreadystatechange = () => {
					if (script.readyState === "loaded" || script.readyState === "complete") {
						script.onreadystatechange = null;
						this.scripts[name].loaded = true;
						resolve({ script: name, loaded: true, status: "Loaded" });
					}
				};
			} else {
				//Others
				script.onload = () => {
					this.scripts[name].loaded = true;
					resolve({ script: name, loaded: true, status: "Loaded" });
				};
			}

			script.onerror = (_: any) => resolve({ script: name, loaded: false, status: "Loaded" });
			document.getElementsByTagName("head")[0].appendChild(script);
		});
	}
}
