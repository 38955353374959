<ng-container *ngIf="prescriberSelectionData$ | async as prescriberSelectionData">
	<div class="row" [formGroup]="formGroup" [hidden]="!isActive">
		<div class="md-6 row mb-3">
			@for (healthCenter of prescriberSelectionData.healthCenters | orderBy: "name"; track healthCenter.id) {
				@if (
					(isScyova && healthCenter.hasScyovaAccess) || (isRespiratory && healthCenter.hasRespiratoryAccess)
				) {
					<div
						class="col-12 color-primary d-flex mb-xl-2 mb-sm-0 fs-5-5 text-ultra-light"
						[ngClass]="forceOneColumn ? 'col-sm-12' : 'col-sm-6'">
						<input
							title="Choix de l’établissement"
							type="radio"
							name="prescriberSelection"
							[id]="healthCenter.id"
							[value]="healthCenter.id"
							formControlName="healthCenterId"
							class="custom-radio mb-2"
							(change)="onHealthCenterSelectionChanged($event, healthCenter.id)" />
						<label class="text-capitalize custom-radio lh-22" [for]="healthCenter.id">{{
							healthCenter.name
						}}</label>
					</div>
				}
			}
			@for (
				prescriberCard of prescriberSelectionData.prescriberCards | orderBy: "address";
				track prescriberCard.id
			) {
				@if (
					(isScyova && prescriberCard.hasScyovaAccess) ||
					(isRespiratory && prescriberCard.hasRespiratoryAccess)
				) {
					<div
						class="col-12 col-xl-12 color-primary d-flex mb-xl-2 mb-sm-0"
						[ngClass]="forceOneColumn ? 'col-sm-12' : 'col-sm-6'">
						<input
							type="radio"
							name="prescriberSelection"
							id="prescriber-{{ prescriberCard.id }}"
							value="{{ prescriberCard.id }}"
							formControlName="prescriberId"
							class="custom-radio"
							[checked]="prescriberCard.id === prescriberSelectionData.messagePreferencePrescriberId"
							(change)="onPrescriberSelectionChanged($event, prescriberCard.id)" />
						<label class="text-capitalize" for="prescriber-{{ prescriberCard.id }}">{{
							prescriberCard.address
						}}</label>
					</div>
				}
			}
		</div>
	</div>
</ng-container>
