import { Component, Input } from "@angular/core";
import { TableCellPrescriptionDateRangeAndStatus } from "src/app/@shared/types/table/table-view";
import { PrescriptionSigningStatus } from "src/app/constants/prescriptions.constant";

@Component({
	selector: "app-table-cell-prescription-date-range-and-status",
	templateUrl: "table-cell-prescription-date-range-and-status.component.html",
})
export class TableCellPrescriptionDateRangeAndStatusComponent {
	@Input() item?: TableCellPrescriptionDateRangeAndStatus;

	prescriptionSigningStatus = PrescriptionSigningStatus;
}
