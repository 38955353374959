import { Component, EventEmitter, Inject, OnDestroy, Output, Renderer2, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MarkdownComponent } from "ngx-markdown";

export interface DialogTermsAndConditionsData {
	description: string;
	legalDocumentId: string;
	title: string;
	withFadeIn: boolean;
}
@Component({
	selector: "app-modal-terms-and-conditions",
	templateUrl: "./modal-terms-and-conditions.component.html",
})
export class ModalTermsAndConditionsComponent implements OnDestroy {
	@Output() readonly submitAgreement = new EventEmitter<string>();

	areTermsAndConditionsAccepted = false;
	description: string;
	isTermsAndConditionsOpen = false;
	isLoading = false;
	isOnError = false;
	legalDocumentId: string;
	isOnlyForReading = false;
	title: string;
	withFadeIn = false;

	get isOpenedWithFadeIn(): boolean {
		return this.withFadeIn && this.isTermsAndConditionsOpen;
	}
	get isClosedWithFadeIn(): boolean {
		return this.withFadeIn && !this.isTermsAndConditionsOpen;
	}

	private listenMarkdownObj!: () => void | null;
	@ViewChild("markdownDiv", { static: false })
	private markdownDiv!: MarkdownComponent;

	constructor(
		private _dialogRef: MatDialogRef<ModalTermsAndConditionsComponent>,
		@Inject(MAT_DIALOG_DATA)
		data: DialogTermsAndConditionsData,
		private renderer: Renderer2
	) {
		this.description = data.description;
		this.legalDocumentId = data.legalDocumentId;
		this.withFadeIn = data.withFadeIn;
		this.title = data.title;
	}

	ngOnDestroy(): void {
		if (this.listenMarkdownObj) {
			this.listenMarkdownObj();
		}
	}

	acceptTermsAndConditions(): void {
		this.isLoading = true;
		this.submitAgreement.emit(this.legalDocumentId);
	}

	close(): void {
		if (this.isOnlyForReading) {
			this._dialogRef.close();
			return;
		}

		this.toggleTermsAndConditions();
	}

	toggleTermsAndConditions(): void {
		this.isTermsAndConditionsOpen = !this.isTermsAndConditionsOpen;
	}

	onMarkdownLoad() {
		setTimeout(() => {
			if (!this.markdownDiv) return;

			this.markdownDiv.disableSanitizer = false;
			this.listenMarkdownObj = this.renderer.listen(
				this.markdownDiv.element.nativeElement,
				"click",
				(e: Event) => {
					if (!(e.target && (e.target as any).tagName === "A")) return;

					const el = e.target as HTMLElement;
					const linkURL = el.getAttribute && el.getAttribute("href");
					if (!linkURL?.startsWith("#")) return;

					e.preventDefault();
					const classAnchor = linkURL.replace("#", "");
					const targetElement = document.querySelector(`.${classAnchor}`);
					if (targetElement) targetElement.scrollIntoView();
				}
			);
		}, 100);
	}
}
