import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BrowseMessagesCommand, PagerMessage, CreateMessageCommand, MessageItem, MessageHistory } from "../models";

@Injectable({
	providedIn: "root",
})
export class ExchangeService {
	isModalMessageOpen = false;

	constructor(private _httpClient: HttpClient) {}

	browseMessages(command: BrowseMessagesCommand): Observable<PagerMessage> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerMessage>(`/exchange/messages/search`, {
			params: params,
		});
	}

	sendMessage(message: CreateMessageCommand): Observable<CreateMessageCommand> {
		return this._httpClient.post<CreateMessageCommand>(`/exchange/messages`, message);
	}

	setMessageStatus(messageId: string, targetStatusCode: string): Observable<MessageItem> {
		const params = new HttpParams({
			fromString: `&targetStatusCode=${targetStatusCode}`,
		});
		return this._httpClient.get<MessageItem>(`/exchange/messages/${messageId}/set-status`, {
			params: params,
		});
	}

	notifyReadMessage(messageId: string): Observable<MessageItem> {
		return this._httpClient.get<MessageItem>(`/exchange/messages/${messageId}/read`);
	}

	getMessageHistory(messageId: string): Observable<MessageHistory> {
		return this._httpClient.get<MessageHistory>(`/exchange/messages/${messageId}/history`);
	}
}
