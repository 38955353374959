import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { PrescriberAvailableFilters } from "../models/analyses/prescriber-available-filters";
import { RegisterMedicalUserDashboardConfiguration } from "../models/medical-user/register-medical-user-dashboard-configuration";
import { RegisterMedicalUserDashboardConfigurationCommand } from "../models/medical-user/register-medical-user-dashboard-configuration-request";
import { BrowseEventsRequest } from "../models/patients/browse-patient-event-by-prescriber";
import { PagerEventPrescriber, PagerEventPatient } from "../models/patients/pager-patient-analyze.interface";
import { LastPatientEvents } from "../models/prescribers/last-patient-events";
import {
	PrescriberIndicatorCardsRequest,
	PrescriberIndicatorCardsResponse,
} from "../models/prescribers/prescriber-indicator-cards-response";
import {
	PrescriberIndicatorGraphsResponse,
	PrescriberIndicatorGraphsRequest,
} from "../models/prescribers/prescriber-indicator-graphs-response";
import {
	MedicalUserMessagePreference,
	RegisterMedicalUserMessagePreferenceCommand,
} from "../models/medical-user/medical-user-message-preference";
import {
	PrescriberPatientsDelegation,
	ShareMedicalUserPatientsCommand,
	ShareMedicalUserPatientsResponse,
} from "../models/prescribers/prescriber-patients-delegation";
import { PrescriberPatientsSelection } from "../models/prescribers/prescriber-patients-selection";
import { PatientFavoriteDetails } from "../models/patients-favorites/patients-favorites-details";
import { RegisterPatientFavoriteRequest } from "../models/patients-favorites/register-patients-favorites-request";
import { RegisterPatientFavoriteResponse } from "../models/patients-favorites/register-patients-favorites-response";
import { PandaLabSigningUrlRequest } from "../models/files/pandalab-signing-url.request";
import { PandaLabSigningUrlResponse } from "../models/files/pandalab-signing-url.response";
import { PrescriberAnnouncementsList } from "../models/announcements/announcements.interface";
import { PrescriptionSummariesRequest } from "../models/medical-user/prescription-summaries.request";
import { PrescriptionSummariesResponse } from "../models/medical-user/prescription-summaries.response";
import {
	PrescriptionIndicatorsRequest,
	PrescriptionIndicatorsResponse,
} from "../models/analyses/prescription-indicators-by-prescriber";
import { GetContactsByAgencyRequest, GetContactsByAgencyResponse, PatientTags } from "../models";
import { LastPatientObservationRecord } from "../models/prescribers/last-patient-observation-record";
import { PatientIndicatorsRequest } from "../models/analyses/patient-indicators-by-prescriber";

@Injectable({
	providedIn: "root",
})
export class MedicalUsersService {
	constructor(private _httpClient: HttpClient) {}

	getDashboardConfiguration(medicalUserId: string): Observable<RegisterMedicalUserDashboardConfiguration> {
		return this._httpClient.get<RegisterMedicalUserDashboardConfiguration>(
			`/medical-users/${medicalUserId}/dashboard/configuration`
		);
	}

	setDashboardConfiguration(
		medicalUserId: string,
		command: RegisterMedicalUserDashboardConfigurationCommand
	): Observable<RegisterMedicalUserDashboardConfiguration> {
		return this._httpClient.post<RegisterMedicalUserDashboardConfiguration>(
			`/medical-users/${medicalUserId}/dashboard/configuration`,
			command
		);
	}

	getPatientsFavorites(medicalUserId: string): Observable<PatientFavoriteDetails> {
		return this._httpClient.get<PatientFavoriteDetails>(`/medical-users/${medicalUserId}/dashboard/favorites`);
	}

	setMedicalUserPatientFavorite(
		medicalUserId: string,
		request: RegisterPatientFavoriteRequest
	): Observable<RegisterPatientFavoriteResponse> {
		return this._httpClient.post<RegisterPatientFavoriteResponse>(
			`/medical-users/${medicalUserId}/dashboard/favorites`,
			request
		);
	}

	getCardTypeIndicatorsByMedicalUser(
		medicalUserId: string,
		request: PrescriberIndicatorCardsRequest
	): Observable<PrescriberIndicatorCardsResponse> {
		return this._httpClient.get<PrescriberIndicatorCardsResponse>(
			`/medical-users/${medicalUserId}/indicators/card-type`,
			{
				params: request as any,
			}
		);
	}

	getGraphTypeIndicatorsByMedicalUser(
		medicalUserId: string,
		request: PrescriberIndicatorGraphsRequest
	): Observable<PrescriberIndicatorGraphsResponse> {
		return this._httpClient.get<PrescriberIndicatorGraphsResponse>(
			`/medical-users/${medicalUserId}/indicators/graph-type`,
			{
				params: request as any,
			}
		);
	}

	getPrescriptionIndicatorsByPrescribers(
		medicalUserId: string,
		request: PrescriptionIndicatorsRequest
	): Observable<PrescriptionIndicatorsResponse> {
		return this._httpClient.post<PrescriptionIndicatorsResponse>(
			`/medical-users/${medicalUserId}/prescriptions/indicators`,
			request
		);
	}

	getPatientIndicatorsByPrescribers(
		medicalUserId: string,
		request: PatientIndicatorsRequest
	): Observable<PrescriptionIndicatorsResponse> {
		return this._httpClient.post<PrescriptionIndicatorsResponse>(
			`/medical-users/${medicalUserId}/patients/indicators`,
			request
		);
	}

	browseEventsByMedicalUser(medicalUserId: string, command: BrowseEventsRequest): Observable<PagerEventPrescriber> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerEventPrescriber>(`/medical-users/${medicalUserId}/events/search`, {
			params: params,
		});
	}

	browseEventsByPatient(
		medicalUserId: string,
		patientId: string,
		command: BrowseEventsRequest
	): Observable<PagerEventPatient> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerEventPatient>(`/medical-users/${medicalUserId}/${patientId}/events`, {
			params: params,
		});
	}

	getLastEventsByPatient(medicalUserId: string, patientId: string, limit = 2): Observable<LastPatientEvents> {
		const params = new HttpParams().set("limit", limit);
		return this._httpClient.get<LastPatientEvents>(`/medical-users/${medicalUserId}/${patientId}/last-events`, {
			params: params,
		});
	}

	getLastObservationRecordByPatient(
		medicalUserId: string,
		patientId: string
	): Observable<LastPatientObservationRecord> {
		return this._httpClient.get<LastPatientObservationRecord>(
			`/medical-users/${medicalUserId}/${patientId}/last-observation-record`
		);
	}

	getPrescriberAvailableFilters(
		activityArea: string | null,
		medicalUserId: string,
		requestedFrom: string
	): Observable<PrescriberAvailableFilters> {
		const params = new HttpParams().set("requestedFrom", requestedFrom);
		return this._httpClient.get<PrescriberAvailableFilters>(
			`/${activityArea}/medical-users/${medicalUserId}/available-filters`,
			{
				params: params,
			}
		);
	}

	getPrescriberPatientsDelegation(medicalUserId: string): Observable<PrescriberPatientsDelegation> {
		return this._httpClient.get<PrescriberPatientsDelegation>(
			`/medical-users/${medicalUserId}/patients/delegations`
		);
	}

	async shareMedicalUserPatients(
		medicalUserId: string,
		command: ShareMedicalUserPatientsCommand
	): Promise<ShareMedicalUserPatientsResponse> {
		const response$ = this._httpClient.post<ShareMedicalUserPatientsResponse>(
			`/medical-users/${medicalUserId}/patients/share`,
			command
		);

		return await firstValueFrom(response$);
	}

	// TODO : Change the way to get this data, to load one time and to get from all application parts
	getPrescriberCards(medicalUserId: string, activityArea?: string | null): Observable<PrescriberPatientsSelection> {
		const params = activityArea ? new HttpParams().set("activityArea", activityArea) : {};
		return this._httpClient.get<PrescriberPatientsSelection>(`/medical-users/${medicalUserId}/prescriber-cards`, {
			params: params,
		});
	}

	getMessagePreference(medicalUserId: string): Observable<MedicalUserMessagePreference> {
		return this._httpClient.get<MedicalUserMessagePreference>(`/medical-users/${medicalUserId}/message-preference`);
	}

	setMessagePreference(
		medicalUserId: string,
		command: RegisterMedicalUserMessagePreferenceCommand
	): Observable<MedicalUserMessagePreference> {
		return this._httpClient.post<RegisterMedicalUserMessagePreferenceCommand>(
			`/medical-users/${medicalUserId}/message-preference`,
			command
		);
	}

	getPandalabSigningUrl(
		medicalUserId: string,
		request: PandaLabSigningUrlRequest
	): Observable<PandaLabSigningUrlResponse> {
		return this._httpClient.post<PandaLabSigningUrlResponse>(
			`/medical-users/${medicalUserId}/pandalab/signing-url`,
			request
		);
	}

	getMedicalUserAnnouncements(
		activityArea: string | null,
		medicalUserId: string
	): Observable<PrescriberAnnouncementsList> {
		return this._httpClient.get<PrescriberAnnouncementsList>(
			`/${activityArea}/medical-users/${medicalUserId}/announcements`
		);
	}

	getPrescriptionSummaries(
		medicalUserId: string,
		request: PrescriptionSummariesRequest
	): Observable<PrescriptionSummariesResponse> {
		return this._httpClient.post<PrescriptionSummariesResponse>(
			`/medical-users/${medicalUserId}/prescriptions/summaries`,
			request
		);
	}

	getPatientTagsByPatient(medicalUserId: string, patientId: string): Observable<PatientTags> {
		return this._httpClient.get<PatientTags>(`/medical-users/${medicalUserId}/patients/${patientId}/tags`);
	}

	getContactsByAgencies(
		medicalUserId: string,
		request: GetContactsByAgencyRequest
	): Observable<GetContactsByAgencyResponse> {
		return this._httpClient.post<GetContactsByAgencyResponse>(
			`/medical-users/${medicalUserId}/contacts-agencies`,
			request
		);
	}
}
