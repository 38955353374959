import { Component, Input, OnDestroy } from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from "@angular/forms";
import { FilesService } from "src/app/services/files.service";
import { FilesCategoryCodes } from "src/app/constants/files.constants";
import { finalize, Subscription } from "rxjs";
import { HttpErrorResponse, HttpEventType, HttpResponse, HttpStatusCode } from "@angular/common/http";

@Component({
	selector: "app-single-file-uploader",
	templateUrl: "single-file-uploader.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SingleFileUploaderComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: SingleFileUploaderComponent,
		},
	],
})
export class SingleFileUploaderComponent implements ControlValueAccessor, Validator, OnDestroy {
	@Input() inputName!: string;
	@Input() label?: string;
	@Input() value?: string;
	@Input() maxSize?: number;
	@Input() maxFilenameLength?: number;
	@Input() medicalUserId!: string;
	@Input() availableMimeTypes?: string[];
	@Input() availableExtensionTypes?: string[];
	@Input() isRequired = false;

	isOnError = false;
	errorMessage: string | null = null;
	disabled = false;
	filename: string | null = null;
	fileMimeType: string | null = null;
	filenameLength: number | null = null;
	fileSize: number | null = null;
	touched = false;

	uploadProgressPercentage: number | null = null;
	uploadSubscription?: Subscription;

	constructor(private _filesService: FilesService) {}

	ngOnDestroy(): void {
		this.uploadSubscription?.unsubscribe();
	}

	onChange = (_: string | null) => {};

	onTouched = () => {};

	onValidationChange = () => {};

	writeValue(obj: string): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onValidationChange = fn;
	}

	setDisabledState?(_isDisabled: boolean): void {
		this.disabled = _isDisabled;
	}

	markAsTouched(): void {
		if (this.touched) return;

		this.onTouched();
		this.touched = true;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		const fileValue: string = control.value;

		if (!this.isRequired && !fileValue) return null;

		if (!fileValue) return { required: { fileValue } };

		if (!this.hasCorrectSize()) return { fileMinSize: { requiredSize: this.maxSize, actualSize: this.fileSize } };

		if (!this.hasCorrectFilenameLength())
			return {
				filenameLength: {
					requiredFilenameLength: this.maxFilenameLength,
					actualFilenameLength: this.filenameLength,
				},
			};

		if (!this.hasCorrectMimeType()) {
			return {
				fileMimeType: { allowedMimeTypes: this.availableMimeTypes, actualMimeType: this.fileMimeType },
			};
		}

		return null;
	}

	onFileChange($event: any): void {
		this.markAsTouched();

		if (this.disabled) return;

		if (!$event.target || !$event.target.files || $event.target.files.length === 0) return;

		const file: File = $event.target.files[0];

		this.isOnError = false;
		this.errorMessage = null;
		this.fileMimeType = file.type;
		this.filename = file.name;
		this.filenameLength = file.name.length;
		this.fileSize = file.size;

		if (this.maxSize && !this.hasCorrectSize()) {
			this.isOnError = true;
			this.errorMessage = `La limite de taille autorisée est de ${this.maxSize / (1024 * 1024)}Mo`;
			return;
		}
		if (!this.hasCorrectMimeType()) {
			this.isOnError = true;
			this.errorMessage = `L'extension du fichier n'est pas supportée`;
			return;
		}
		if (!this.hasCorrectFilenameLength()) {
			this.isOnError = true;
			this.errorMessage = `Le nom du fichier ne doit pas dépasser ${this.maxFilenameLength} caractères`;
			return;
		}

		const upload$ = this._filesService
			.uploadFile(file, FilesCategoryCodes.cpapPrescriptionPolysomnography, this.medicalUserId)
			.pipe(finalize(() => this.reset()));

		this.uploadSubscription?.unsubscribe();
		this.uploadSubscription = upload$.subscribe({
			next: event => {
				if (event.type === HttpEventType.UploadProgress && event.total) {
					this.uploadProgressPercentage = Math.round(100 * (event.loaded / event.total));
				}

				if (event.type === HttpEventType.Response && event instanceof HttpResponse) {
					if (event.body) {
						this.onChange(event.body.documentUploadId);
					}
				}
			},
			error: (error: HttpErrorResponse) => {
				if (error.status !== HttpStatusCode.UnprocessableEntity) {
					this.isOnError = true;
				}
			},
		});
	}

	removeUpload($event: Event): void {
		$event.stopPropagation();
		this.fileMimeType = null;
		this.filename = null;
		this.fileSize = null;
		this.isOnError = false;
		this.errorMessage = null;
		this.onChange(null);
	}

	cancelUpload(): void {
		this.uploadSubscription?.unsubscribe();
		this.reset();
	}

	private reset(): void {
		this.uploadProgressPercentage = null;
		this.uploadSubscription?.unsubscribe();
	}

	private hasCorrectSize(): boolean {
		if (this.maxSize && this.fileSize && this.fileSize > this.maxSize) return false;

		return true;
	}

	private hasCorrectMimeType(): boolean {
		if (this.availableMimeTypes && this.fileMimeType && !this.availableMimeTypes.includes(this.fileMimeType))
			return false;

		return true;
	}

	private hasCorrectFilenameLength(): boolean {
		if (this.maxFilenameLength && this.filenameLength && this.filenameLength > this.maxFilenameLength) return false;

		return true;
	}
}
