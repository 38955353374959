import { Component, Input } from "@angular/core";
import { TableView } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-skeleton",
	templateUrl: "table-skeleton.component.html",
})
export class TableSkeltonComponent {
	@Input() tableView?: TableView;

	cells = [0, 1, 2];
}
