import { Pager } from "../pager.interface";
import { Observable, Subject } from "rxjs";
import { SortDirection } from "@angular/material/sort";
import { ComparisonOperators, SubjectEvents } from "src/app/constants";

export type TableRowItem = Record<string, TableRowColumnItem>;

export interface TableRowColumnItem {
	value: any;
	subValue?: any;
	textColor?: string;
}

export type PagerTableRowItem = Pager<TableRowItem>;

export class TableSettings {
	columns!: TableColumn;
	isLastButtonActions = false;
	showSelectAllButton = false;
	showSelectAllCheckBox = false;
	pagerLabel = "Éléments par page";

	private _settingsChangesSubject: Subject<string> = new Subject<string>();
	settingsChanges$: Observable<string> = this._settingsChangesSubject.asObservable();

	private _firstColumnName!: string;
	private _displayedColumns: string[] = [];
	get displayedColumns(): string[] {
		return this._displayedColumns;
	}

	private _displayedMultiColumns: string[] = [];
	get displayedMultiColumns(): string[] {
		return this._displayedMultiColumns;
	}

	private _filteredColumns: string[] = [];
	get filteredColumns(): string[] {
		return this._filteredColumns;
	}

	multiColumns: TableMultiColumn = {};
	showFilters!: boolean;

	private _showMultiHeader = false;
	get showMultiHeader(): boolean {
		return this._showMultiHeader;
	}

	private _singleColumnNames: string[] = [];
	get singleColumnNames(): string[] {
		return this._singleColumnNames;
	}

	private _singleMultiSortableColumnNames: Record<string, string> = {};
	get singleMultiSortableColumnNames(): Record<string, string> {
		return this._singleMultiSortableColumnNames;
	}

	private _groupedHeaderColumnFirstColumnNames: string[] = [];
	get groupedHeaderColumnFirstColumnNames(): string[] {
		return this._groupedHeaderColumnFirstColumnNames;
	}

	constructor(init?: Partial<TableSettings>) {
		Object.assign(this, init);
		this.loadSettings();
	}

	loadSettings(): void {
		const columnNames = Object.keys(this.columns).filter(e => this.columns[e].disabled != true);
		const multiColumnNames = Object.keys(this.multiColumns);

		// Show the multi header if the array multiColumns is set
		this._showMultiHeader = multiColumnNames.length !== 0;

		// The single column --> with column names array length is 1
		this._singleColumnNames = multiColumnNames
			.filter(e => this.multiColumns[e].columnNames.length === 1)
			.map(e => this.multiColumns[e].columnNames[0]);

		// Loop on multiColumns array
		// - to fill the groupedColumnFirstColumnName array
		// - to fill the _singleMultiSortableColumnNames array
		for (const key in this.multiColumns) {
			if (this.multiColumns[key].columnNames.length !== 1) {
				this._groupedHeaderColumnFirstColumnNames.push(this.multiColumns[key].columnNames[0]);

				continue;
			}

			const columnName = this.multiColumns[key].columnNames[0];
			this._singleMultiSortableColumnNames[key] = columnName;
		}

		this._displayedColumns = columnNames;
		this._displayedMultiColumns = multiColumnNames.map(e => `${e}Multi`);
		this._filteredColumns = columnNames.map(e => `${e}Filter`);
		this._firstColumnName = columnNames[0];

		this._settingsChangesSubject.next(SubjectEvents.onRefresh);
	}

	isGroupedHeaderColumnFirstColumn(columnName: string): boolean {
		return this._groupedHeaderColumnFirstColumnNames.indexOf(columnName) !== -1;
	}

	isFirstColumn(columnName: string): boolean {
		return this._firstColumnName === columnName;
	}

	isGroupedHeaderColumn(columnName: string): boolean {
		return this._singleColumnNames.indexOf(columnName) === -1;
	}

	isGroupedHeaderColumnFirstColumnOnSpecificPosition(columnName: string, columnPosition: number): boolean {
		return this._groupedHeaderColumnFirstColumnNames.indexOf(columnName) === columnPosition;
	}

	getColumnWidth(columnName: string): string {
		const sumAllWeights: number = Object.keys(this.columns)
			.filter(e => !this.columns[e].disabled)
			.reduce((sum, e) => sum + this.columns[e].weight, 0);

		if (sumAllWeights <= 0) return "";

		const widthPercentage = (100 * this.columns[columnName].weight) / sumAllWeights;

		return (widthPercentage | 0) + "%";
	}

	applyExternalFilter(columnName: string, operator: ComparisonOperators | undefined, filterChoice: string): void {
		this.displayedColumns.forEach(column => {
			const targetColumn = this.columns[column];
			if (targetColumn.filterTableColumnName === columnName && targetColumn.filterTableColumnTypeChoices) {
				targetColumn.filterTableColumnTypeChoices?.forEach(choice => {
					const values = filterChoice.split(";");
					if (choice.operator === operator && values.includes(choice.value)) choice.checked = true;
				});
			}
		});
	}
}

export type TableColumn = Record<string, TableColumnItem>;

export interface TableColumnItem {
	alignLeft?: boolean;
	disabled?: boolean;
	displayName: string;
	filterOnly?: boolean;
	filterTableColumnType: string;
	filterTableColumnTypeChoices?: MultipleChoice[];
	filterTableColumnName?: string;
	formatTableColumnType: string;
	hidden?: boolean;
	sortTableColumnName?: string;
	sortTableColumnType: string;
	sortDirection?: SortDirection;
	subDisplayName?: string;
	weight: number;
}

export class MultipleChoice {
	checked!: boolean;
	name!: string;
	operator?: ComparisonOperators;
	value: any;

	constructor(name: string, value: any, checked = false, operator: ComparisonOperators | undefined = undefined) {
		this.checked = checked;
		this.name = name;
		this.operator = operator;
		this.value = value;
	}
}

export type TableMultiColumn = Record<string, TableMultiColumnItem>;

export interface TableMultiColumnItem {
	columnNames: string[];
	displayName: string;
}
