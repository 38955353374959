import { Component, Input } from "@angular/core";
import { TableCellDuration } from "src/app/@shared/types/table/table-view";
import { ObservanceLevelCodes, ObservanceStatusCodes } from "src/app/constants/observance-level.constant";

@Component({
	selector: "app-table-cell-duration",
	templateUrl: "table-cell-duration.component.html",
})
export class TableCellDurationComponent {
	observanceLevelCodes = ObservanceLevelCodes;
	observanceStatusCodes = ObservanceStatusCodes;

	@Input() item?: TableCellDuration;
}
