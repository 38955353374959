import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Treatments } from "src/app/constants";
import {
	ExportPatientsByPrescriberCommand,
	GetEquipmentsByPatientCommand,
	GetPatientPrescriptionsResult,
	IEquipmentsByPatientItem,
	PatientMedicalSummaryItem,
	PatientPairingDetailsItem,
	SearchAllPatientsResult,
} from "src/app/models";

@Injectable({
	providedIn: "root",
})
export class AnalysesService {
	constructor(private _httpClient: HttpClient) {}

	getDisplayTreatmentLabel(value: string): string {
		if (!value) return "";

		return value
			.split(";")
			.filter((c: string) => {
				return Treatments.PrescriptionCodeMappings.has(c);
			})
			.map((c: string) => {
				return Treatments.PrescriptionCodeMappings.get(c) ?? "";
			})
			.sort((c1: string, c2: string) => c1.localeCompare(c2))
			.join(" | ");
	}

	downloadPatientsByPrescribers(command: ExportPatientsByPrescriberCommand): Observable<Blob> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		const options = { params, responseType: "blob" as const };
		return this._httpClient.get(`/analyses/patients/export`, options);
	}

	getPatientMedicalSummaryByPatient(medicalUserId: string, patientId: string): Observable<PatientMedicalSummaryItem> {
		return this._httpClient.get<PatientMedicalSummaryItem>(
			`/analyses/medical-users/${medicalUserId}/patients/${patientId}/medical-summary`
		);
	}

	getPatientEquipmentsByPatient(patientId: string, medicalUserId: string): Observable<IEquipmentsByPatientItem> {
		const command = new GetEquipmentsByPatientCommand({
			medicalUserId: medicalUserId,
			patientId: patientId,
		});

		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<IEquipmentsByPatientItem>(`/analyses/equipments`, { params: params });
	}

	searchPatientsAdmin(terms: string): Observable<SearchAllPatientsResult> {
		const params = new HttpParams().set("terms", terms);
		return this._httpClient.get<SearchAllPatientsResult>(`/analyses/patients/search`, {
			params: params,
		});
	}

	getPatientPairingDetails(medicalUserId: string, patientId: string): Observable<PatientPairingDetailsItem> {
		return this._httpClient.get<PatientPairingDetailsItem>(
			`/analyses/medical-users/${medicalUserId}/patients/${patientId}/pairing`
		);
	}

	getPrescriptionsByPatient(medicalUserId: string, patientId: string): Observable<GetPatientPrescriptionsResult> {
		return this._httpClient.get<GetPatientPrescriptionsResult>(
			`/analyses/medical-users/${medicalUserId}/patients/${patientId}/prescriptions`
		);
	}
}
