import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
	selector: "[appClickOutside]",
})
export class ClickOutsideDirective {
	@Output() readonly clickOutside = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) {}

	@HostListener("document:click", ["$event.target"])
	onClick(target: EventTarget | null) {
		if (!this.elementRef.nativeElement.contains(target)) {
			this.clickOutside.emit();
		}
	}
}
