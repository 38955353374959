export class Sexes {
	static readonly female: string = "female";
	static readonly male: string = "male";
	static readonly neutral: string = "neutral";

	static readonly iconMappings: Record<string, string> = {
		[Sexes.female]: "icon-docteur-femme",
		[Sexes.male]: "icon-docteur-homme",
		[Sexes.neutral]: "icon-docteur-non-genre",
	};
}
