@if (item) {
	<div class="text-center">
		@if (item.value) {
			<span
				class="text-nowrap p-1 rounded-pill align-items-center text-white wpx-24 hpx-24"
				[ngClass]="getMessageStatusClass(item.valueType)">
				<span class="text-nowrap w-100 text-center">{{ item.value }}</span>
			</span>
			<span class="text-nowrap pt-1">&nbsp;/12</span>
		} @else {
			<span class="text-nowrap pt-1">-</span>
		}
	</div>
}
