import { filter, map, mergeMap, Subscription, tap } from "rxjs";
import { Component, OnDestroy, OnInit, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AppConfig } from "src/app/types";
import {
	AgreementsService,
	AppConfigService,
	AppVersionService,
	CommunicationComService,
	SeoService,
	UrlService,
} from "src/app/services";
import { Logger } from "src/app/services";
import { environment } from "src/environments";
import {
	DialogTermsAndConditionsData,
	ModalMessageComponent,
	ModalMessageSecretaryComponent,
	ModalTermsAndConditionsComponent,
	SharedModule,
} from "src/app/@shared";
import { UserAgreementLegalDocument } from "src/app/models";
import { ActivityAreas } from "./constants";

const log = new Logger("App");

@Component({
	imports: [RouterOutlet, SharedModule],
	selector: "app-root",
	standalone: true,
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
	private _appConfig!: AppConfig;
	private _activatedRouteSubscription?: Subscription;
	private _agreementSubscription?: Subscription;
	private _modalContactOpenSubscription?: Subscription;
	private _modalSecretaryContactOpenSubscription?: Subscription;
	private _modalUserAgreementLegalDocumentSubscription?: Subscription;
	private _modalUserAgreementLegalDocumentOnSubmitSubscription?: Subscription;
	private _previousUrl!: string;

	isScyovaActivityArea = false;
	isNavigationVisible = true;

	constructor(
		_appConfigService: AppConfigService,
		_renderer: Renderer2,
		private _activatedRoute: ActivatedRoute,
		private _agreementsService: AgreementsService,
		private _appVersionService: AppVersionService,
		private _communicationComService: CommunicationComService,
		private _dialog: MatDialog,
		private _router: Router,
		private _seoService: SeoService,
		private _urlService: UrlService,
		@Inject(DOCUMENT) private document: Document
	) {
		this._appConfig = _appConfigService.appConfig;

		_renderer.addClass(document.body, `theme-${this._appConfig.appCompanyName}`);
		this.loadStyle(`theme-${this._appConfig.appCompanyName}`);
	}

	ngOnInit(): void {
		if (environment.production) {
			Logger.enableProductionMode();
		}

		this.registerRoutesSubscription();
		this._appVersionService.registerCheckForUpdates();

		this._modalContactOpenSubscription = this._communicationComService.onModalContactOpen$.subscribe(
			(data: any) => {
				this._dialog.open(ModalMessageComponent, {
					data: data,
				});
			}
		);
		this._modalSecretaryContactOpenSubscription =
			this._communicationComService.onModalSecretaryContactOpen$.subscribe((data: any) => {
				this._dialog.open(ModalMessageSecretaryComponent, {
					data: data,
				});
			});
		log.info(
			` -- Current app: ${this._appConfig.version.build} (${this._appConfig.version.version}) | ${this._appConfig.version.name} | ${this._appConfig.appDisplayName} `
		);

		// hide the navigation component if an agreement is needed then open the dialog
		this._modalUserAgreementLegalDocumentSubscription =
			this._agreementsService.userTermsAndConditionsAgreementStatus$
				.pipe(
					filter(agreementStatus => agreementStatus.isAgreementAcceptanceRequired),
					map(agreementStatus => agreementStatus.currentLegalDocument)
				)
				.subscribe(legalDocument => this.openModalTermsAndConditions(legalDocument));

		this._agreementsService.ensureUserTermsAndConditionsAgreement();
	}

	ngOnDestroy(): void {
		this._activatedRouteSubscription?.unsubscribe();
		this._agreementSubscription?.unsubscribe();
		this._modalContactOpenSubscription?.unsubscribe();
		this._modalSecretaryContactOpenSubscription?.unsubscribe();
		this._modalUserAgreementLegalDocumentSubscription?.unsubscribe();
		this._modalUserAgreementLegalDocumentOnSubmitSubscription?.unsubscribe();
	}

	registerRoutesSubscription(): void {
		this._activatedRouteSubscription = this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(event => event as NavigationEnd),
				tap(navigationEndEvent => {
					this._urlService.setPreviousUrl(this._previousUrl);
					this._previousUrl = window.location.href;
					if (navigationEndEvent.url)
						this.isScyovaActivityArea = navigationEndEvent.url.toLowerCase().includes(ActivityAreas.scyova);
				}),
				map(() => this._activatedRoute),
				map(route => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter(route => route.outlet === "primary"),
				mergeMap(async route => route.snapshot)
			)
			.subscribe((activatedRoute: ActivatedRouteSnapshot) => {
				const eventData: Data = activatedRoute.data;

				if (!eventData) return;

				const params: string[] = eventData["params"]
					? eventData["params"].map((p: any) => activatedRoute.paramMap.get(p.name) || "")
					: [];

				this._seoService.updateFromData(eventData, params);
			});
	}

	loadStyle(styleName: string): void {
		const themeLink = this.document.getElementById("client-theme") as HTMLLinkElement;
		if (themeLink) {
			themeLink.href = styleName;
			return;
		}

		// the random string is used for dev mode only
		const hasVersion = !!this._appConfig.version.version && this._appConfig.version.version !== "";
		const cssUniqueHash = hasVersion
			? this._appConfig.version.version
			: Math.random()
					.toString(36)
					.substring(2, 2 + 10);

		const head = this.document.getElementsByTagName("head")[0];
		const style = this.document.createElement("link");
		style.id = "client-theme";
		style.rel = "stylesheet";
		style.type = "text/css";
		style.href = `${styleName}.css?v${cssUniqueHash}`;

		head.appendChild(style);
	}

	openModalTermsAndConditions(legalDocument: UserAgreementLegalDocument): void {
		this.isNavigationVisible = false;
		this._modalUserAgreementLegalDocumentOnSubmitSubscription?.unsubscribe();

		const data = {
			description: legalDocument.description,
			legalDocumentId: legalDocument.id,
			title: legalDocument.title,
		} as DialogTermsAndConditionsData;
		const dialogRef = this._dialog.open(ModalTermsAndConditionsComponent, { data });
		this._modalUserAgreementLegalDocumentOnSubmitSubscription =
			dialogRef.componentInstance.submitAgreement.subscribe(legalDocumentId => {
				this._agreementsService.acceptLegalDocument(legalDocumentId).subscribe(() => {
					dialogRef.componentInstance.isLoading = false;
					dialogRef.componentInstance.isOnError = false;
					dialogRef.close();
					this.isNavigationVisible = true;
				});
			});
	}
}
