export class DeviceProviders {
	static readonly bmc: string = "bmc";
	static readonly bmcApi: string = "bmc-api";
	static readonly lowenstein: string = "lowenstein";
	static readonly resmed: string = "resmed";
	static readonly respironics: string = "respironics";
	static readonly sefam: string = "sefam";

	static readonly multiProviders: string = "multi-providers";

	static readonly availableReportDeviceProviders: string[] = [DeviceProviders.resmed, DeviceProviders.respironics];

	static readonly labelMappingRecord: Record<string, string> = {
		[DeviceProviders.bmc]: "BMC",
		[DeviceProviders.resmed]: "Resmed",
		[DeviceProviders.respironics]: "Respironics",
	};
}
