import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "orderBy",
})
export class OrderByPipe implements PipeTransform {
	transform(value: any[], propertyName: string, order: "asc" | "desc" = "asc"): any[] {
		return value.sort((a, b) => {
			const a2 = order === "asc" ? a : b;
			const b2 = order === "asc" ? b : a;

			if (a2[propertyName] > b2[propertyName]) return 1;
			if (a2[propertyName] < b2[propertyName]) return -1;
			return 0;
		});
	}
}
