<div class="upload-item rounded-4 p-3" [class.error]="isOnError" [style.background]="isOnError ? '#EFD2DC' : null">
	<div class="upload-item__detail d-flex align-items-center">
		<i class="upload-item__detail__icon icon-ordonance"></i>
		<span class="upload-item__detail__name text-ultra-light align-self-center flex-grow-1 ps-2 pt-1">
			{{ fileName }}
		</span>
		<div class="d-flex justify-content-center align-items-center">
			<button
				type="button"
				color="accent"
				(click)="removeUpload($event)"
				title="Supprimer le fichier"
				class="upload-item__detail__action unstyled btn delete-file btn-hover p-2 rounded-circle">
				<i class="icon-delete text-gray fs-5-5"></i>
			</button>
		</div>
	</div>
	<div class="input-file__content">
		<div class="file-upload__progress align-self-stretch d-flex" *ngIf="uploadProgressPercentage">
			<mat-progress-bar
				class="progress-bar align-self-center flex-grow-1"
				mode="determinate"
				[value]="uploadProgressPercentage">
			</mat-progress-bar>
			<span class="fs-initial color-primary text-medium align-self-center ps-2 mt-2">
				{{ uploadProgressPercentage }}%
			</span>
		</div>
	</div>
</div>
