import { Component, Input } from "@angular/core";
import { DocumentsService } from "src/app/services/documents.service";

export enum SecuredFileLinkComponentMode {
	Document = "Document",
	InteractionDocument = "InteractionDocument",
}

@Component({
	selector: "app-secured-file-link",
	templateUrl: "./secured-file-link.html",
})
export class SecuredFileLinkComponent {
	@Input() btnCssClass!: string;
	@Input() documentId!: string;
	@Input() documentDate!: Date;
	@Input() documentName!: string;
	@Input() documentType!: string;
	@Input() eventDate!: Date;
	@Input() mode = SecuredFileLinkComponentMode.Document;
	@Input() patientFullName!: string;
	@Input() title = "Ouvrir le document";

	constructor(private _documentsService: DocumentsService) {}

	downloadFileById() {
		switch (this.mode) {
			case SecuredFileLinkComponentMode.Document:
				this._documentsService.downloadDocument(
					this.documentId,
					this.documentName,
					this.documentDate,
					this.eventDate
				);
				return;
			case SecuredFileLinkComponentMode.InteractionDocument:
				this._documentsService.downloadPatientInteractionDocument(
					this.documentId,
					this.documentName,
					this.documentDate,
					this.patientFullName,
					this.eventDate
				);
				return;
		}
	}
}
