import { Injectable } from "@angular/core";
import { environment } from "src/environments";

@Injectable({
	providedIn: "root",
})
export class AmelinkService {
	openAmelink(amelinkUrl: string, amelinkPrescriptionsGroupId: string): void {
		window.open(
			`${amelinkUrl}?shareamelink=${amelinkPrescriptionsGroupId}&env=${environment.environmentName}`,
			"_self"
		);
	}
}
