export {};

Number.prototype.hoursToShortTime = function (this: number): string {
	const hours = Math.floor(this);
	const minutes = Math.round((this - hours) * 60);

	const paddedHours = hours.toString().padStart(2, "0");
	const paddedMinutes = minutes.toString().padStart(2, "0");

	return `${paddedHours}:${paddedMinutes}`;
};

Number.prototype.isInTheFuture = function (this: number): boolean {
	const duration: number = this - new Date().getTime();
	return duration > 0;
};

Number.prototype.millisecondsToDisplayTime = function (this: number): string {
	const duration: number = this - new Date().getTime();
	const symbol: string = duration > 0 ? "" : "-";
	return symbol + new Date(Math.abs(duration)).toISOString().slice(11, 19);
};
