export class FilterTableColumnTypes {
	static readonly comparison: string = "comparison";
	static readonly contains: string = "contains";
	static readonly date: string = "date";
	static readonly dateRange: string = "dateRange";
	static readonly eq: string = "eq";
	static readonly in: string = "in";
	static readonly none: string = "none";
	static readonly searchTerms: string = "searchTerms";
	static readonly stringContains: string = "stringContains";
}
