import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { NotificationService } from "src/app/services";
import { Logger } from "src/app/services/logger.service";

const log = new Logger("GlobalErrorHandler");

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(
		private _zone: NgZone,
		private _injector: Injector
	) {}

	handleError(error: any): void {
		if (!error) return;

		if (error instanceof HttpErrorResponse) {
			// Server or connection error happened
			if (!navigator.onLine) {
				// Handle offline error
				// Add toast --> not caught error
				this.handleHttpError(error);
				return;
			} else {
				// Handle Http Error (error.status === 403, 404...)
				// Add toast --> not caught error
				this.handleHttpError(error);
				return;
			}
		} else {
			log.error(error);

			// Handle Client Error (Angular Error, ReferenceError...)
			const notificationService = this._injector.get(NotificationService);
			this._zone.run(() =>
				notificationService.showErrorMessage("Un problème est survenue lors du chargement d'un composant", " ")
			);
		}
	}

	private handleHttpError(error: HttpErrorResponse): void {
		switch (error.status) {
			case 0:
				// A client-side or network error occurred. Handle it accordingly.
				log.error("An error occurred:", error.error);
				break;
			case HttpStatusCode.NotFound:
				log.warn(`Backend returned code ${error.status}, body was: `, error.error);
				break;
			case HttpStatusCode.Unauthorized:
				log.warn(`Backend returned code ${error.status}, body was: `, error.error);
				break;
			default:
				// The backend returned an unsuccessful response code.
				// The response body may contain clues as to what went wrong.
				log.error(`Backend returned code ${error.status}, body was: `, error.error);
				break;
		}
	}
}
