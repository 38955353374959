import { BaseApiRequest } from "../@shared/base-api-request";

export class GenerateReportRequest extends BaseApiRequest {
	endDate!: Date;
	medicalUserId!: string;
	provider!: string;
	reportType!: string;
	startDate!: Date;

	constructor(init?: Partial<GenerateReportRequest>) {
		super();
		Object.assign(this, init);
	}
}
