export class TableCellTypes {
	static readonly actionsList: string = "actions-list";
	static readonly actionsMessageList: string = "actions-message-list";
	static readonly codeState: string = "codeState";
	static readonly date: string = "date";
	static readonly dateRange: string = "dateRange";
	static readonly documents: string = "documents";
	static readonly duration: string = "duration";
	static readonly healthCenterMembers: string = "health-center-members";
	static readonly messageStatus: string = "message-status";
	static readonly multipleLinesString: string = "multiple-lines-string";
	static readonly observancePeriodsStatus: string = "observance-periods-status";
	static readonly patientInterventionType: string = "patient-intervention-type";
	static readonly person: string = "person";
	static readonly prescriptionDateRangeStatus: string = "prescriptionDateRangeStatus";
	static readonly readMore: string = "read-more";
	static readonly select: string = "select";
	static readonly status: string = "status";
	static readonly string: string = "string";
	static readonly tag: string = "tag";
}
