import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FilesService } from "src/app/services/files.service";
import { finalize, Subscription } from "rxjs";
import { HttpErrorResponse, HttpEventType, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { FileItem, UploadedItem, UploadErrorItem } from "../../types";

@Component({
	selector: "app-file-uploader-item",
	templateUrl: "file-uploader-item.component.html",
})
export class FileUploaderComponent implements OnDestroy, OnInit {
	private readonly filesService = inject(FilesService);

	@Output() readonly errorDetected = new EventEmitter<UploadErrorItem>();
	@Output() readonly markAsDeleted = new EventEmitter<number>();
	@Output() readonly uploaded = new EventEmitter<UploadedItem>();

	@Input() availableExtensionTypes?: string[];
	@Input() availableMimeTypes?: string[];
	@Input() item!: FileItem;
	@Input() fileUploadCategoryCode!: string;
	@Input() maxFilenameLength?: number;
	@Input() maxSize?: number;
	@Input() medicalUserId!: string;
	@Input() prescriberId!: string;
	@Input() patientId?: string;

	disabled = false;
	fileName: string | null = null;
	fileMimeType: string | null = null;
	filenameLength: number | null = null;
	fileSize: number | null = null;

	hasServerError = false;
	hasWrongSize = true;
	hasWrongMimeType = false;
	hasWrongFilenameLength = false;
	isOnError = false;
	uploadProgressPercentage: number | null = null;
	uploadSubscription?: Subscription;

	ngOnInit(): void {
		this.uploadFile();
	}

	ngOnDestroy(): void {
		this.uploadSubscription?.unsubscribe();
	}

	removeUpload($event: Event): void {
		$event.stopPropagation();

		this.fileMimeType = null;
		this.fileName = null;
		this.filenameLength = null;
		this.fileSize = null;
		this.hasServerError = false;
		this.hasWrongSize = false;
		this.hasWrongMimeType = false;
		this.hasWrongFilenameLength = false;
		this.isOnError = false;

		this.markAsDeleted.emit(this.item.internalId);
	}

	private uploadFile(): void {
		this.isOnError = false;
		this.hasServerError = false;

		this.setValues();

		if (this.isOnError) {
			this.emitValidationError(false, false);
			return;
		}

		const upload$ = this.filesService
			.uploadFile(
				this.item.file,
				this.fileUploadCategoryCode,
				this.medicalUserId,
				this.prescriberId,
				this.patientId
			)
			.pipe(finalize(() => this.reset()));

		this.uploadSubscription?.unsubscribe();
		this.uploadSubscription = upload$.subscribe({
			next: event => {
				if (event.type === HttpEventType.UploadProgress && event.total) {
					this.uploadProgressPercentage = Math.round(100 * (event.loaded / event.total));
				}

				if (event.type === HttpEventType.Response && event instanceof HttpResponse) {
					if (event.body) {
						this.emitSuccess(event.body.documentUploadId);
					}
				}
			},
			error: (error: HttpErrorResponse) => {
				this.hasServerError = true;
				if (error.status == HttpStatusCode.UnprocessableEntity) {
					this.emitValidationError(true, true);
					return;
				}
				this.emitValidationError(true, false);
			},
		});
	}

	private setValues(): void {
		this.fileMimeType = this.item.file.type;
		this.fileName = this.item.file.name;
		this.filenameLength = this.item.file.name.length;
		this.fileSize = this.item.file.size;

		this.hasWrongSize = !this.checkCorrectSize();
		this.hasWrongMimeType = !this.checkCorrectMimeType();
		this.hasWrongFilenameLength = !this.checkCorrectFilenameLength();

		this.isOnError =
			this.hasServerError || this.hasWrongSize || this.hasWrongMimeType || this.hasWrongFilenameLength;
	}

	private emitValidationError(isServerError: boolean, isServerUnprocessableEntityError: boolean): void {
		this.isOnError = true;
		this.errorDetected.emit({
			fileMimeType: this.fileMimeType,
			fileName: this.fileName,
			fileNameLength: this.filenameLength,
			fileSize: this.fileSize,
			hasWrongFilenameLength: this.hasWrongFilenameLength,
			hasWrongMimeType: this.hasWrongMimeType,
			hasWrongSize: this.hasWrongSize,
			internalFileId: this.item.internalId,
			isServerError: isServerError,
			isServerUnprocessableEntityError: isServerUnprocessableEntityError,
		});
	}

	private emitSuccess(fileId: string): void {
		this.isOnError = false;
		this.uploaded.emit({
			fileId: fileId,
			fileName: this.fileName!,
			internalFileId: this.item.internalId,
		});
	}

	private reset(): void {
		this.uploadProgressPercentage = null;
		this.uploadSubscription?.unsubscribe();
	}

	private checkCorrectSize(): boolean {
		if (this.maxSize && this.fileSize && this.fileSize > this.maxSize) return false;

		return true;
	}

	private checkCorrectMimeType(): boolean {
		if (this.availableMimeTypes && this.fileMimeType && !this.availableMimeTypes.includes(this.fileMimeType))
			return false;

		return true;
	}

	private checkCorrectFilenameLength(): boolean {
		if (this.maxFilenameLength && this.filenameLength && this.filenameLength > this.maxFilenameLength) return false;

		return true;
	}
}
