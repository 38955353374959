import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "lunarDateString",
})
export class LunarDateStringPipe implements PipeTransform {
	transform(value: string): string {
		return this.toLunarDateString(value);
	}

	private toLunarDateString(value: string): string {
		const year = value.slice(0, 4);
		const month = value.slice(4, 6);
		const day = value.slice(6, 8);

		return `${day}/${month}/${year}`;
	}
}
