import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UserConnectionLog } from "src/app/models";
import { AppConfigService } from ".";
import { UserConnection } from "../types";

@Injectable({
	providedIn: "root",
})
export class AuditService {
	constructor(
		private _appConfigService: AppConfigService,
		private _httpClient: HttpClient
	) {}

	async logUserSession(eventCode: string, userConnection: UserConnection): Promise<void> {
		const request = {
			almostExpirationDate: userConnection.almostExpirationDate,
			appVersion: this._appConfigService.appConfig.version.build,
			connectionDate: userConnection.connectionDate,
			eventCode: eventCode,
			expirationDate: userConnection.expirationDate,
			itemId: userConnection.userConnectionId,
			language: window.navigator.language ?? "undefined",
			message: `User connection event: ${eventCode} with the id ${userConnection.userConnectionId}`,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? "undefined",
			userAgent: window.navigator.userAgent ?? "undefined",
		} as UserConnectionLog;

		await firstValueFrom(this._httpClient.post<never>(`/audit/log-session`, request));
	}

	logUserSessionAsync(eventCode: string, userConnection: UserConnection): void {
		this.logUserSession(eventCode, userConnection).finally(() => {});
	}
}
