import { Component, Input, OnInit } from "@angular/core";
import { TableCellHealthCenterMembers } from "src/app/@shared/types/table/table-view";
import { AuthService } from "src/app/services";

@Component({
	selector: "app-table-cell-health-center-members",
	templateUrl: "./table-cell-health-center-members.component.html",
})
export class TableCellHealthCenterMembersComponent implements OnInit {
	@Input() items!: TableCellHealthCenterMembers;
	@Input() row!: any;

	accordeonRowClickedIds: Record<string, string> = {};

	isAuthenticated = false;
	isScopeSuperAdmin = false;

	constructor(private _authService: AuthService) {}

	ngOnInit(): void {
		this._authService.authorizationResult$.subscribe(authorizationResult => {
			this.isAuthenticated = authorizationResult.isAuthenticated;
			this.isScopeSuperAdmin = authorizationResult.user.isScopeSuperAdmin;
		});
	}

	activeAccordeon(row: any): void {
		if (this.accordeonRowClickedIds[row] == row) {
			this.accordeonRowClickedIds[row] = "";
			return;
		}

		this.accordeonRowClickedIds[row] = row;
	}
}
