export class HealthInsuranceLabels {
	static readonly general: string = "général";
	static readonly specific: string = "particulier";
}

export class HealthInsurance {
	static readonly general: string = "general";
	static readonly specific: string = "specific";

	static readonly labelMappings: Record<string, string> = {
		[HealthInsurance.general]: HealthInsuranceLabels.general,
		[HealthInsurance.specific]: HealthInsuranceLabels.specific,
	};
}
