import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	first,
	map,
	Observable,
	of,
	shareReplay,
	startWith,
	Subscription,
	switchMap,
	tap,
} from "rxjs";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	inject,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import {
	NotificationService,
	CommunicationComService,
	MedicalUsersService,
	PrescribersService,
	Logger,
	AuthService,
	AppConfigService,
} from "src/app/services";
import { FavoriteConstantMessage, RestrictAccessMessages, Sexes, Companies, ActivityAreas } from "src/app/constants";
import {
	RegisterPatientFavoriteResponse,
	Contacts,
	PrescriberPatientsSelection,
	SearchPatientsItem,
	SearchPatientsResult,
	GetContactsByAgencyResponse,
	GetContactsByAgencyRequest,
} from "src/app/models";
import { SignalRService } from "src/app/services";
import { AppConfig, User } from "src/app/types";
import { ScyovaMedicalUsersService } from "src/app/services/scyova/scyova-medical-users.service";
import { RespiratoryMedicalUsersService } from "src/app/services/respiratory/respiratory-medical-users.service";

const log = new Logger("HeaderComponent");

@Component({
	selector: "app-header",
	templateUrl: "header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
	private readonly scyovaMedicalUsersService = inject(ScyovaMedicalUsersService);
	private readonly respiratoryMedicalUsersService = inject(RespiratoryMedicalUsersService);

	@Input() alignLabels = false;
	@Input() firstLabel = "";
	@Input() isPatientInquiryInProgress = false;
	@Input() patientId = "";
	@Input() patientOrganization = "";
	@Input() secondLabel = "";
	@Input() secondLabelAbbr = "";
	@Input() searchPatientActivityArea = ActivityAreas.respiratory;
	@Input() showActions = true;
	@Input() showContacts = true;
	@Input() showMedicalTechnicalAdvisorInfo = true;
	@Input() showMedicalUserPatientsSelection = false;
	@Input() showOrganizationMessage = false;
	@Input() showSearchPatientsForm = true;
	@Input() showSecondLabelAbbr = false;
	@Input() specificPhoneNumber?: string;
	@Input() thirdLabel?: string = "";

	@Output() readonly selectHealthCenters = new EventEmitter();

	@ViewChild("inputSearch")
	inputSearch!: ElementRef;

	isHiddenMenuOpen = false;
	isMenuNoteOpen = false;
	isMenuXSOpen = false;
	isMenuOpen = false;
	isBlurOpen = false;
	isDesignIntegration: boolean = environment.isDesignIntegration;
	sexes = Sexes;
	companies = Companies;
	adminMtaAdministrativeMessage = RestrictAccessMessages.adminMtaAdministrative;

	agencyCity = "bordeaux";
	agencyPhoneNumber = "0517177003";
	get currentMedicalUserId(): string {
		return this._authService.user?.currentMedicalUserId;
	}

	currentNbSelectedPrescriberIds = 0;

	appConfig!: AppConfig;
	isPatientsSearchLoading = true;
	isPatientsSearchOnError = false;

	isPrescriber = false;
	isScopePrescriber = false;
	isScopeSecretary = false;
	isSuperAdmin = false;

	searchPatientsForm = new FormGroup({
		birthDate: new FormControl("", Validators.required),
		familyName: new FormControl("", Validators.required),
		givenName: new FormControl("", Validators.required),
		lunarBirthDate: new FormControl("", Validators.required),
		patientDisplayName: new FormControl(""),
		patientId: new FormControl("", Validators.required),
		prescriptionId: new FormControl("", Validators.required),
	});
	filteredPatients$: Observable<SearchPatientsItem[]>;
	selectedPatient!: SearchPatientsItem;

	prescriberContacts$!: Observable<Contacts>;
	hasMoreThanOneContactAgency!: boolean;
	hasMoreThanOneContactCmt!: boolean;
	hasMoreThanOnePatientBase = false;

	secretaryContacts$!: Observable<GetContactsByAgencyResponse | undefined>;

	prescriberCardsSelection$!: Observable<PrescriberPatientsSelection | undefined>;
	prescriberCardsSelectionLoaded!: PrescriberPatientsSelection | undefined;
	onPreviewModeChange$: Observable<boolean> = this._appConfigService.onPreviewModeChange$;

	private _authorizationSubscription!: Subscription;
	private _searchBarFocusSubscription!: Subscription;
	private _patientFavoriteSubscription!: Subscription;

	constructor(
		private _appConfigService: AppConfigService,
		private _communicationComService: CommunicationComService,
		private _authService: AuthService,
		private _prescriberService: PrescribersService,
		private _medicalUsersService: MedicalUsersService,
		private _notificationService: NotificationService,
		private _router: Router,
		private _signalRService: SignalRService
	) {
		this.appConfig = _appConfigService.appConfig;

		this.filteredPatients$ = this.searchPatientsForm.controls["patientDisplayName"].valueChanges.pipe(
			startWith(""),
			debounceTime(400),
			distinctUntilChanged(),
			switchMap(value => this.filterSearchPatientsItems(value || ""))
		);
	}

	ngOnDestroy(): void {
		this._authorizationSubscription?.unsubscribe();
		this._searchBarFocusSubscription?.unsubscribe();
		this._patientFavoriteSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this._authorizationSubscription = this._authService.authorizationResult$
			.pipe(first())
			.subscribe(authorizationResult => {
				this.onLoad(authorizationResult.user);
			});
		this._searchBarFocusSubscription = this._communicationComService.onSearchBarFocusSubjectChange$.subscribe(_ => {
			this.inputSearch.nativeElement.focus();
		});
	}

	onSubmit(): void {
		if (this.searchPatientsForm.invalid) return;

		this.redirectToPatientPage();
	}

	private redirectToPatientPage(): Promise<boolean> {
		if (this.searchPatientActivityArea === ActivityAreas.scyova)
			return this._router.navigate([`/scyova/patient-file/${this.searchPatientsForm.value.patientId}`]);

		return this._router.navigate([`/prescriber/patient-file/${this.searchPatientsForm.value.patientId}`]);
	}

	onLoad(user: User): void {
		this.isPrescriber = user.isPrescriber;
		this.isScopePrescriber = user.isScopePrescriber;
		this.isScopeSecretary = user.isScopeSecretary;
		this.isSuperAdmin = user.isSuperAdmin;

		const getContactsByAgencyRequest: GetContactsByAgencyRequest = {
			agencies: user.currentAgencies?.split(";") ?? null,
		};

		if (this.currentMedicalUserId && this.showMedicalTechnicalAdvisorInfo) {
			this.prescriberContacts$ = this._prescriberService.getContacts(this.currentMedicalUserId).pipe(
				tap(contacts => {
					this.hasMoreThanOneContactAgency = contacts.agencyItems.length > 1;
					this.hasMoreThanOneContactCmt = contacts.medicalTechnicalAdvisorItems.length > 1;
				}),
				map(contacts => {
					return contacts as Contacts;
				})
			);

			this.secretaryContacts$ = this._medicalUsersService
				.getContactsByAgencies(this.currentMedicalUserId, getContactsByAgencyRequest)
				.pipe(
					tap(contacts => {
						this.hasMoreThanOneContactAgency = contacts.agencies.length > 1;
					})
				);
		}

		if (this.currentMedicalUserId) {
			this.prescriberCardsSelection$ = this._signalRService.patientShare$.pipe(
				switchMap(() =>
					this._medicalUsersService.getPrescriberCards(
						this.currentMedicalUserId,
						this._authService.user.activityArea
					)
				),
				tap((selection: PrescriberPatientsSelection | undefined) => {
					if (!selection) return;

					this.prescriberCardsSelectionLoaded = selection;

					this.hasMoreThanOnePatientBase = selection.availablePrescriberIds.length > 1;

					if (!this.checkCurrentPrescriberScopeSelection(selection.availablePrescriberIds)) return;

					log.debug(`Patients scope update detected`);
					const prescriberIds = this._authService.user.scopePrescriberIds.filter(id =>
						selection.availablePrescriberIds.find(availableId => availableId === id)
					);
					this.currentNbSelectedPrescriberIds = prescriberIds?.length;
					this._authService.changeUserPrescriberIdsScope(prescriberIds);

					this._notificationService.showValidationRequest(
						"Une mise à jour du partage de patientèle a été effectuée."
					);
				}),
				shareReplay(1)
			);
		}

		this.currentNbSelectedPrescriberIds = user.scopePrescriberIds?.length;
	}

	onPatientSelected(selected: SearchPatientsItem): void {
		this.selectedPatient = selected;
		this.searchPatientsForm.patchValue({
			birthDate: selected.birthDate,
			familyName: selected.familyName,
			givenName: selected.givenName,
			lunarBirthDate: selected.lunarBirthDate,
			patientId: selected.patientId,
			prescriptionId: selected.prescriptionId,
		});

		log.debug(
			`Patient selected: ${selected.givenName} ${selected.familyName} ${selected.birthDate} ${selected.lunarBirthDate} ${selected.patientId})`
		);

		this.redirectToPatientPage().then(() => {
			this.searchPatientsForm.reset();
		});
	}

	displayPatient(item: SearchPatientsItem): string {
		return item
			? `${item.givenName?.toTitleCase() ?? ""} ${item.familyName?.toUpperCase() ?? ""} ${item.birthDate} ${
					item.lunarBirthDate
				} (${item.patientId})`
			: "";
	}

	addToFavorites(filteredPatient: SearchPatientsItem, $event: any): void {
		$event.stopPropagation();

		this._patientFavoriteSubscription?.unsubscribe();
		this._patientFavoriteSubscription = this._medicalUsersService
			.setMedicalUserPatientFavorite(this._authService.user.currentMedicalUserId, {
				patientId: filteredPatient.patientId,
			})
			.pipe(
				catchError((err: any) => {
					this._notificationService.showErrorMessage(FavoriteConstantMessage.message.error);

					log.error(err);
					return of(undefined);
				})
			)
			.subscribe((registerPatientFavoriteResponse: RegisterPatientFavoriteResponse | undefined) => {
				if (!registerPatientFavoriteResponse) {
					this._notificationService.showErrorMessage(FavoriteConstantMessage.message.error);
					return;
				}
				if (
					registerPatientFavoriteResponse.result === FavoriteConstantMessage.message.favoriteResponseDeleted
				) {
					this._notificationService.showValidationRequest(FavoriteConstantMessage.message.favoriteRemove);
				} else if (
					registerPatientFavoriteResponse.result === FavoriteConstantMessage.message.favoriteResponseAdded
				) {
					filteredPatient.isPinned = true;
					this._notificationService.showValidationRequest(FavoriteConstantMessage.message.favoriteAdd);
					this._communicationComService.changePatientsFavorites(true);
				}
			});
	}

	openCloseXSMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isMenuXSOpen = false;
			return;
		}

		this.isMenuXSOpen = !this.isMenuXSOpen;
	}

	openCloseNoteMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isMenuNoteOpen = false;
			return;
		}

		this.isMenuNoteOpen = !this.isMenuNoteOpen;
	}

	openCloseHiddenMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isHiddenMenuOpen = false;
			return;
		}
		this.isHiddenMenuOpen = !this.isHiddenMenuOpen;
	}

	onFocusBlur(forceToClose: boolean): void {
		if (forceToClose) {
			this.isBlurOpen = false;
			return;
		}
		this.isBlurOpen = !this.isBlurOpen;
		if (!this.isBlurOpen) this.inputSearch.nativeElement.blur();
	}

	openMessageDialog(): void {
		const data: any = {
			patientId: this.patientId,
		};
		this.isMenuXSOpen = !this.isMenuXSOpen;

		if (this.isScopeSecretary) {
			this._communicationComService.openModalSecretaryContact(data);
			return;
		}

		this._communicationComService.openModalContact(data);
	}

	openHealthCenterSelection(prescriberCardsSelection: PrescriberPatientsSelection | undefined): void {
		this.selectHealthCenters.emit(prescriberCardsSelection);
	}

	private filterSearchPatientsItems(value: string | SearchPatientsItem): Observable<SearchPatientsItem[]> {
		if (typeof value !== "string") {
			return of([]);
		}

		if (!value || value.length < 3) return of([]);

		this.isPatientsSearchLoading = true;
		this.isPatientsSearchOnError = false;

		const filterValue = value.toLowerCase();

		return this.searchPatients$(filterValue).pipe(
			map(searchResult => searchResult.items),
			catchError(() => {
				this.isPatientsSearchLoading = false;
				this.isPatientsSearchOnError = true;
				log.debug(`An error occurred while searching patients with the following terms: ${filterValue}`);
				return of([]);
			})
		);
	}

	private searchPatients$(filterValue: string): Observable<SearchPatientsResult> {
		if (this.searchPatientActivityArea === ActivityAreas.scyova)
			return this.scyovaMedicalUsersService.searchPatientsByTerms(this.currentMedicalUserId, filterValue);

		return this.respiratoryMedicalUsersService.searchPatientsByTerms(this.currentMedicalUserId, filterValue);
	}

	private checkCurrentPrescriberScopeSelection(availablePrescriberIds: string[]): boolean {
		if (this.currentNbSelectedPrescriberIds > availablePrescriberIds.length) {
			log.debug(
				`Current scope prescriber Id is greater than available prescriber Ids : ${availablePrescriberIds}`
			);
			return true;
		}

		if (
			this._authService.user.scopePrescriberIds?.length > 0 &&
			!availablePrescriberIds.some(availablePrescriberId =>
				this._authService.user.scopePrescriberIds.includes(availablePrescriberId)
			)
		) {
			log.debug(
				`Difference between current scope prescriber id and available prescriber Ids :${this._authService.user.scopePrescriberIds} ${availablePrescriberIds}`
			);
			return true;
		}

		return false;
	}
}
