import { Injectable } from "@angular/core";
import { DeviceModels } from "../constants/devices.constant";
import { MaskModels } from "../constants/mask-constant";

@Injectable({
	providedIn: "root",
})
export class PatientEquipmentsService {
	constructor() {}

	private _aeroCodes = ["104", "105", "105", "106"];
	private _o2Codes = ["1101", "1102", "1103", "1104", "1105", "1106", "1107", "1108", "1110", "1113", "1119", "604"];

	getTreatmentBySubFamilyCode(code: string): string {
		if (this._aeroCodes.includes(code)) return "Aerosol";
		if (this._o2Codes.includes(code)) return "O2";
		return "PPC";
	}

	getDeviceImage(deviceName: string, treatment: string, isBottleDevice: boolean): string {
		const path = "assets/img/devices/";

		if (DeviceModels.recordedDevice[deviceName]) {
			return path + DeviceModels.recordedDevice[deviceName];
		}

		if (isBottleDevice) {
			return "assets/img/devices/default-device-gas.svg";
		}

		switch (treatment) {
			case "Aerosol":
				return "assets/img/devices/default-device-aerosol.svg";
			case "O2":
				return "assets/img/devices/default-device-oxygen.svg";
			default:
				return "assets/img/devices/default-device-ppc.svg";
		}
	}

	getMaskImage(maskName: string): string {
		const defaultImage = "assets/img/masks/default-mask.svg";
		const path = "assets/img/masks/";

		if (MaskModels.recordedMask[maskName]) return path + MaskModels.recordedMask[maskName];

		return defaultImage;
	}

	public cleanEquipmentName(name: string) {
		return name.removeHashtags();
	}
}
