/* eslint-disable @typescript-eslint/prefer-for-of */
import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";

@Directive({
	selector: "[appDragAndDrop]",
})
export class DragAndDropDirective {
	@Input()
	@HostBinding("class.active")
	isActive = false;
	@Output()
	isActiveChange = new EventEmitter<boolean>();

	@Output()
	filesChange = new EventEmitter<File[]>();

	@Input() preventBodyDrop = true;

	@HostListener("dragover", ["$event"])
	onDragOver($event: DragEvent): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.changeIsActive(true);
	}

	@HostListener("dragleave", ["$event"])
	onDragLeave($event: DragEvent): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.changeIsActive(false);
	}

	@HostListener("drop", ["$event"])
	onDrop($event: DragEvent): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.changeIsActive(false);

		const { dataTransfer } = $event;

		if (!dataTransfer) return;

		if (!dataTransfer.items) {
			const files = dataTransfer.files;
			dataTransfer.clearData();
			this.filesChange.emit(Array.from(files));

			return;
		}

		const files: File[] = [];

		for (let i = 0; i < dataTransfer.items.length; i++) {
			if (dataTransfer.items[i].kind !== "file") continue;
			const file = dataTransfer.items[i].getAsFile();

			if (!file) continue;

			files.push(file);
		}

		dataTransfer.items.clear();
		this.filesChange.emit(files);
	}

	@HostListener("body:dragover", ["$event"])
	onBodyDragOver(event: DragEvent) {
		if (!this.preventBodyDrop) return;

		event.preventDefault();
		event.stopPropagation();
	}

	@HostListener("body:drop", ["$event"])
	onBodyDrop(event: DragEvent) {
		if (!this.preventBodyDrop) return;

		event.preventDefault();
	}

	private changeIsActive(value: boolean): void {
		this.isActive = value;
		this.isActiveChange.emit(value);
	}
}
