import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HealthCenterSharings } from "../models/health-centers/health-center-sharings.interface";
import {
	AddHealthCenterMemberCommand,
	PagerHealthCenter,
	PagerHealthCenterMembers,
	SwitchHealthCenterMediViewLocalAdminStatusCommand,
	SwitchHealthCenterMediViewLocalAdminStatusResponse,
	SwitchHealthCenterStatusCommand,
} from "../models/health-centers/health-center.interface";
import { URLEncoder } from "../types/url-encoder";
import { BrowseHealthCenterRequest } from "../models";

@Injectable({
	providedIn: "root",
})
export class HealthCentersService {
	constructor(private _httpClient: HttpClient) {}

	addHealthCenterMember(
		healthCenterId: string,
		command: AddHealthCenterMemberCommand
	): Observable<AddHealthCenterMemberCommand> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
		});
		return this._httpClient.post<AddHealthCenterMemberCommand>(
			`/health-centers/${healthCenterId}/members`,
			command,
			{ params: params }
		);
	}

	removeHealthCenterMember(healthCenterId: string, medicalUserId: string) {
		const params = new HttpParams({
			encoder: new URLEncoder(),
		});
		return this._httpClient.delete<AddHealthCenterMemberCommand>(
			`/health-centers/${healthCenterId}/members/${medicalUserId}`,
			{ params: params }
		);
	}

	browseHealthCenters(command: BrowseHealthCenterRequest): Observable<PagerHealthCenter> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerHealthCenter>(`/health-centers/search`, {
			params: params,
		});
	}

	switchHealthCenterStatus(
		healthCenterId: string,
		command: SwitchHealthCenterStatusCommand
	): Observable<SwitchHealthCenterStatusCommand> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
		});
		return this._httpClient.patch<SwitchHealthCenterStatusCommand>(
			`/health-centers/${healthCenterId}/switch-status`,
			command,
			{ params: params }
		);
	}

	switchHealthCenterMediViewLocalAdminStatus(
		healthCenterId: string,
		command: SwitchHealthCenterMediViewLocalAdminStatusCommand
	): Observable<SwitchHealthCenterMediViewLocalAdminStatusResponse> {
		const params = new HttpParams({
			encoder: new URLEncoder(),
		});
		return this._httpClient.patch<SwitchHealthCenterMediViewLocalAdminStatusResponse>(
			`/health-centers/${healthCenterId}/switch-admin-status`,
			command,
			{ params: params }
		);
	}

	getHealthCenterMembers(
		healthCenterId: string,
		command: BrowseHealthCenterRequest
	): Observable<PagerHealthCenterMembers> {
		const params = new HttpParams({
			fromString: command.generateUrlQuery(),
		});
		return this._httpClient.get<PagerHealthCenterMembers>(`/health-centers/${healthCenterId}/members/search`, {
			params: params,
		});
	}

	getHealthCenterSharingsByMedicalUser(medicalUserId: string): Observable<HealthCenterSharings> {
		return this._httpClient.get<HealthCenterSharings>(`/health-centers/medical-users/${medicalUserId}/sharings`);
	}
}
