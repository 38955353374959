import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, tap } from "rxjs/operators";
import { Logger } from "src/app/services/logger.service";

const log = new Logger("LoggingInterceptor");

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		const started = Date.now();
		let ok: string;

		// extend server response observable with logging
		return next.handle(request).pipe(
			tap((event: any) => {
				if (event) {
					// Succeeds when there is a response; ignore other events
					ok = event instanceof HttpResponse ? "succeeded" : "";
				} else {
					// Operation failed; error is an HttpErrorResponse
					ok = "failed";
				}
			}),
			// Log when response observable either completes or errors
			finalize(() => {
				const elapsed = Date.now() - started;
				log.debug(`${request.method} "${request.urlWithParams}" ${ok} in ${elapsed} ms.`);
			})
		);
	}
}
