import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FilterTableColumnTypes } from "src/app/@shared/constants/filter-table-column-types.constant";
import {
	TableFilterChoiceList,
	TableFilterListItemDetail,
	TableFiltersList,
	TableView,
} from "src/app/@shared/types/table/table-view";
import { Subscription } from "rxjs";
import { TableCommunicationService } from "src/app/@shared/services/table.communication.service";
import { ComparisonOperators } from "src/app/constants";

@Component({
	selector: "app-table-header-filter",
	templateUrl: "table-header-filter.component.html",
})
export class TableHeaderFilterComponent implements OnInit, OnDestroy {
	filterTableColumnTypes = FilterTableColumnTypes;

	@Input() columnName?: string;
	@Input() filterChoiceList?: TableFilterChoiceList;
	@Input() filters!: TableFiltersList;
	@Input() filterColumnName?: string;
	@Input() filterToolTip?: string;
	@Input() filterType?: FilterTableColumnTypes;

	@Output() readonly filterChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	private _onResetFiltersSubscription!: Subscription;

	filterDate?: Date | null;
	filterSearchTerms?: string;
	searchChecked = false;

	constructor(private _tableCommunicationService: TableCommunicationService) {}

	ngOnInit(): void {
		this._onResetFiltersSubscription = this._tableCommunicationService.onResetFiltersSubject$.subscribe(_ => {
			this.resetSearch(true);
		});
		this.resetSearch(true);
		this.initFilters();
	}

	ngOnDestroy(): void {
		this._onResetFiltersSubscription.unsubscribe();
	}

	//Checkbox events
	changeChecked(_: any): void {
		//disable option-popin
		this.searchChecked = !this.searchChecked;
	}

	closeFilter(): void {
		this.searchChecked = false;
	}

	performSearch(): void {
		const filteredColumn = this.createTableFilterListItemDetail();

		if (!filteredColumn) {
			this.removeTableFilterListItems();
		} else {
			this.addOrReplaceTableFilterListItems(filteredColumn);
		}

		this.searchChecked = false;

		this.filterChange.emit(true);
	}

	resetSearch(preventNotifyChange: boolean): void {
		if (
			this.filterType === FilterTableColumnTypes.stringContains ||
			this.filterType === FilterTableColumnTypes.searchTerms
		) {
			this.filterSearchTerms = "";
		}

		if (this.filterType === FilterTableColumnTypes.date) {
			this.filterDate = null;
		}

		if (
			this.filterType === FilterTableColumnTypes.in ||
			this.filterType === FilterTableColumnTypes.contains ||
			this.filterType === FilterTableColumnTypes.comparison
		) {
			this.filterChoiceList?.items.forEach(item => {
				item.isSelected = false;
			});
		}

		if (preventNotifyChange) return;

		this.removeTableFilterListItems();
		this.filterChange.emit(true);
	}

	private initFilters(): void {
		if (!this.filters || !this.columnName) return;

		const filterIndex = this.filters.items.findIndex(i => i.columnName === this.columnName);
		if (filterIndex == -1) return;

		if (
			this.filterType === FilterTableColumnTypes.stringContains ||
			this.filterType === FilterTableColumnTypes.searchTerms
		) {
			this.filterSearchTerms = this.filters.items[filterIndex].filteredColumn.value;
		}

		if (this.filterType === FilterTableColumnTypes.date) {
			this.filterDate = this.filters.items[filterIndex].filteredColumn.value;
		}
		if (
			this.filterType === FilterTableColumnTypes.in ||
			this.filterType === FilterTableColumnTypes.contains ||
			this.filterType === FilterTableColumnTypes.comparison
		) {
			this.filterChoiceList?.items.forEach(item => {
				item.isSelected = false;

				const value: string = this.filters.items[filterIndex].filteredColumn.value;
				const operator: ComparisonOperators = this.filters.items[filterIndex].filteredColumn.operator;

				if (value.includes(";")) {
					const valueArray = value.split(";");
					const itemValue = item.value.includes(";") ? item.value.split(";") : item.value;

					if (Array.isArray(itemValue)) {
						if (
							valueArray.some(e => itemValue.includes(e)) &&
							(operator === item.operator || operator === ComparisonOperators.equal)
						) {
							item.isSelected = true;
						}
					} else {
						if (
							valueArray.includes(item.value) &&
							(operator === item.operator || operator === ComparisonOperators.equal)
						) {
							item.isSelected = true;
						}
					}
				} else if (value === item.value && operator === item.operator) {
					item.isSelected = true;
				}
			});
		}
	}

	private addOrReplaceTableFilterListItems(filteredColumn: TableFilterListItemDetail): void {
		if (!this.filters || !this.columnName) return;

		const filterIndex = this.filters.items.findIndex(i => i.columnName === this.columnName);

		if (filterIndex === -1) {
			this.filters.items = [
				...this.filters.items,
				{
					columnName: this.columnName,
					filteredColumn: filteredColumn,
				},
			];
		} else {
			this.filters.items[filterIndex].filteredColumn = filteredColumn;
		}
	}

	private removeTableFilterListItems(): void {
		if (!this.filters || !this.columnName) return;

		const filterIndex = this.filters.items.findIndex(i => i.columnName === this.columnName);
		if (filterIndex === -1) return;

		this.filters.items.splice(filterIndex, 1);
	}

	private createTableFilterListItemDetail(): TableFilterListItemDetail | null {
		if (!this.filterColumnName) return null;

		if (
			(this.filterType === FilterTableColumnTypes.stringContains ||
				this.filterType === FilterTableColumnTypes.searchTerms) &&
			this.filterSearchTerms
		) {
			return TableView.createStringContainsTableFilterListItemDetail(
				this.filterSearchTerms,
				this.filterColumnName,
				this.filterType
			);
		}

		if (this.filterType === FilterTableColumnTypes.date && this.filterDate) {
			return TableView.createEqualsDateTableFilterListItemDetail(this.filterDate, this.filterColumnName);
		}

		if (this.filterType === FilterTableColumnTypes.in && this.filterChoiceList) {
			return TableView.createInTableFilterListItemDetail(
				this.filterChoiceList.items,
				this.filterColumnName,
				this.filterType
			);
		}
		if (this.filterType === FilterTableColumnTypes.contains && this.filterChoiceList) {
			return TableView.createContainsTableFilterListItemDetail(
				this.filterChoiceList.items,
				this.filterColumnName,
				this.filterType
			);
		}
		if (this.filterType === FilterTableColumnTypes.comparison && this.filterChoiceList) {
			return TableView.createComparisonTableFilterListItemDetail(
				this.filterChoiceList.items,
				this.filterColumnName,
				this.filterType
			);
		}

		return null;
	}
}
