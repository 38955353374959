<div
	class="modal modal-signin position-fixed d-block bg-modal modal-announcement-dialog"
	tabindex="0"
	role="dialog"
	id="modalSignin">
	<div class="modal-dialog position-relative" role="document">
		<span class="icon-white-logo-{{ appConfig.appCompanyName }} fs-45 text-center mt-2 w-100 pb-3 mb-3">
			<span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
			><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
			><span class="path9"></span><span class="path10"></span><span class="path11"></span
			><span class="path12"></span><span class="path13"></span><span class="path14"></span
			><span class="path15"></span><span class="path16"></span><span class="path17"></span
			><span class="path18"></span><span class="path19"></span><span class="path20"></span
			><span class="path21"></span><span class="path22"></span><span class="path23"></span
			><span class="path24"></span><span class="path25"></span><span class="path26"></span
			><span class="path27"></span><span class="path28"></span><span class="path29"></span
			><span class="path30"></span><span class="path31"></span><span class="path32"></span>
		</span>

		<h3 class="text-bold fs-4 mb-2 ms-3 text-center text-white text-sm-start">Vos annonces</h3>
		<div class="modal-content rounded shadow position-relative overflow-hidden">
			<div class="modal-body p-3 p-lg-4 d-flex flex-column justify-content-between">
				<div>
					<div class="row">
						<div class="md-6 mb-6">
							<div class="form-group color-primary mb-2 fs-5 label-xs">
								<label for="message" class="text-medium mb-1">Titre</label>
								<p>
									{{ currentAnnouncement.title }}
								</p>
							</div>
						</div>
						<div class="md-6 mb-6">
							<div class="form-group color-primary mb-2 fs-5 label-xs">
								<label for="message" class="text-medium mb-1">Message</label>
								<p style="white-space: pre-line">
									{{ currentAnnouncement.message }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group text-center mt-5 fs-5">
					<button (click)="this.validateMessageReading()" class="btn btn-primary mb-3 mb-xl-0 me-3">
						<span class="text-medium">Ne plus me le rappeler</span>
					</button>
					<button (click)="this.goToNextAnnouncement()" class="btn btn-primary mb-3 mb-xl-0">
						<span class="text-medium"
							>{{ announcementsLength === currentMessageIdx ? "Fermer" : "Suivant" }}
							{{ currentMessageIdx }}/{{ announcementsLength }}</span
						>
					</button>
				</div>
			</div>
			<i class="icon-contact bg-contact position-absolute z-index-1"> </i>
		</div>
	</div>
</div>
