import { AbstractControl, ValidatorFn } from "@angular/forms";

export class FormValidator {
	/**
	 * Validate a phone number
	 * @returns {ValidatorFn}
	 */
	static phoneValidator(): ValidatorFn {
		return (control: AbstractControl) => {
			if (!control?.value) {
				return null;
			}

			const phonePattern = /^\d{10}$/;

			if (!phonePattern.test(control.value)) {
				return { phoneNumber: true };
			}

			return null;
		};
	}
}
