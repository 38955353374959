import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { filter, Observable, Subscription } from "rxjs";
import { ComparisonOperators } from "src/app/constants/comparison-operators.constant";
import { FilterTableColumnTypes } from "../../../constants/filter-table-column-types.constant";
import { TableFilterEvent } from "../../../events/table-filter.event";
import { BrowseFilteredColumn } from "../../../types/browse-paging";
import { MultipleChoice } from "../../../types/table/table";
import { NotificationService } from "src/app/services/notification.service";
import { FilterConstantMessage } from "src/app/constants/message-filter-constant";

@Component({
	selector: "app-table-filter",
	templateUrl: "table-filter.component.html",
})
export class TableFilterComponent implements OnInit, OnDestroy {
	@Input() columnName?: string;
	@Input() filterType?: FilterTableColumnTypes;
	@Input() filterChoice?: MultipleChoice[];
	@Input() filterColumn?: string;
	@Input() filterChanges$: Observable<TableFilterEvent> | undefined;

	@Output() readonly filter: EventEmitter<TableFilterEvent> = new EventEmitter<TableFilterEvent>();

	filterTableColumnTypes = FilterTableColumnTypes;

	private _filterChangesSubscription!: Subscription;

	searchChecked = false;
	searchForm = new FormGroup({
		search: new FormControl(""),
		date: new FormControl(""),
		checkArray: new FormArray([]),
		startDate: new FormControl(""),
		endDate: new FormControl(""),
	});

	constructor(private _notificationService: NotificationService) {}

	ngOnInit(): void {
		if (this.filterChanges$) {
			this._filterChangesSubscription = this.filterChanges$
				.pipe(filter(e => e.columnName === this.columnName))
				.subscribe(e => {
					if (e.isClean) this.resetForm();
				});
		}

		const checkArray = this.searchForm.get("checkArray") as FormArray;

		this.filterChoice?.forEach(choice => {
			checkArray.push(
				new FormGroup({
					name: new FormControl(choice.name),
					value: new FormControl(choice.value),
					checked: new FormControl(choice.checked),
					operator: new FormControl(choice.operator),
				})
			);
		});

		this.submitSearchForm();
	}

	ngOnDestroy(): void {
		if (this._filterChangesSubscription) this._filterChangesSubscription.unsubscribe();
	}

	checkArrayControls(): FormGroup[] {
		const checkArray = this.searchForm.get("checkArray") as FormArray;
		return checkArray.controls as FormGroup[];
	}

	//Checkbox events
	changeChecked(_: any): void {
		//disable option-popin
		this.searchChecked = !this.searchChecked;
	}

	changeComparisonChecked(group: FormGroup): void {
		const groups = this.searchForm?.value.checkArray;
		groups?.forEach((grp: any) => {
			if (grp.name !== group.value.name) grp.checked = false;
		});
	}

	//Form events
	submitSearchForm(): void {
		let resetForm = false;
		const searchValue = this.searchForm?.value?.search;
		const dateValue = this.searchForm?.value?.date;
		const checkArrayItems = this.searchForm?.value?.checkArray ?? [];
		const checkArrayValue = checkArrayItems.filter((c: MultipleChoice) => c.checked);
		const browsedFilters = new Array<BrowseFilteredColumn>();
		const startDateValue = this.searchForm?.value?.startDate;
		const endDateValue = this.searchForm?.value?.endDate;

		if (searchValue) {
			browsedFilters.push(this.createStringContainsBrowsedFilterColumn(searchValue));
			resetForm = true;
		}

		if (dateValue) {
			const date = new Date(dateValue).toStartOfDayUTC();
			const currentDate = new Date().getFullYear();

			if (date > new Date(currentDate - 150, 0, 1) && date < new Date(currentDate + 150, 11, 31)) {
				browsedFilters.push(this.createEqualsBrowsedFilterColumn(date.toIso8601()));
			} else {
				this._notificationService.showErrorMessage(FilterConstantMessage.message.error);
			}
		}

		if (startDateValue) {
			const startDate = new Date(startDateValue).toStartOfDayUTC().toIso8601();
			browsedFilters.push(
				this.createComparisonBrowsedFilterColumn(startDate, ComparisonOperators.greaterThanOrEqual)
			);
		}

		if (endDateValue) {
			const endDate = new Date(endDateValue).toEndOfDayUTC().toIso8601();
			browsedFilters.push(this.createComparisonBrowsedFilterColumn(endDate, ComparisonOperators.lessThan));
		}

		if (checkArrayValue && checkArrayValue.length > 0) {
			if (this.filterType === FilterTableColumnTypes.comparison) {
				checkArrayValue.forEach((cpr: MultipleChoice) => {
					browsedFilters.push(this.createComparisonBrowsedFilterColumn(cpr.value, cpr.operator));
				});
			} else if (this.filterType === FilterTableColumnTypes.contains) {
				browsedFilters.push(this.createContainsBrowsedFilterColumn(checkArrayValue));
			} else {
				browsedFilters.push(this.createInBrowsedFilterColumn(checkArrayValue));
			}
		}

		this.filter.emit({
			columnName: this.filterColumn,
			filteredColumns: browsedFilters,
			isClean: browsedFilters.length === 0,
		} as TableFilterEvent);

		if (resetForm) this.resetForm();

		this.searchChecked = false;
	}

	private createContainsBrowsedFilterColumn(values: any): BrowseFilteredColumn {
		return {
			name: this.filterColumn,
			operation: ComparisonOperators.contains,
			value: values.map((c: MultipleChoice) => c.value).join(";"),
		} as BrowseFilteredColumn;
	}

	private createInBrowsedFilterColumn(values: any): BrowseFilteredColumn {
		return {
			name: this.filterColumn,
			operation: ComparisonOperators.in,
			value: values.map((c: MultipleChoice) => c.value).join(";"),
		} as BrowseFilteredColumn;
	}

	private createStringContainsBrowsedFilterColumn(value: any): BrowseFilteredColumn {
		return {
			name: this.filterColumn,
			operation:
				this.filterType === FilterTableColumnTypes.searchTerms
					? ComparisonOperators.searchTerms
					: ComparisonOperators.contains,
			value: value,
		} as BrowseFilteredColumn;
	}

	private createEqualsBrowsedFilterColumn(value: any): BrowseFilteredColumn {
		return {
			name: this.filterColumn,
			operation: ComparisonOperators.equal,
			value: value,
		} as BrowseFilteredColumn;
	}

	private createComparisonBrowsedFilterColumn(value: any, operator?: ComparisonOperators): BrowseFilteredColumn {
		if (operator === ComparisonOperators.between) {
			return {
				name: this.filterColumn,
				operation: ComparisonOperators.between,
				value: value.join(";"),
			} as BrowseFilteredColumn;
		}
		return {
			name: this.filterColumn,
			operation: operator,
			value: value,
		} as BrowseFilteredColumn;
	}

	resetForm(): void {
		const checkArrayValues = this.searchForm?.value?.checkArray;
		if (checkArrayValues && checkArrayValues.length > 0) {
			checkArrayValues.forEach((checkArrayValue: MultipleChoice) => (checkArrayValue.checked = false));
		} else {
			this.searchForm.reset();
		}
	}

	closeFilter(): void {
		this.searchChecked = false;
	}
}
