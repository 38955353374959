import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TreatmentList } from "../models/treatments/treatments.interface";

@Injectable({
	providedIn: "root",
})
export class TreatmentsService {
	constructor(private _httpClient: HttpClient) {}

	getTreatmentsMappings(): Observable<TreatmentList> {
		return this._httpClient.get<TreatmentList>(`/treatments/get-treatments-types`);
	}
}
