import { ExternalScript } from "../types/external-script";

export class ExternalScripts {
	static readonly pandalabSdk: string = "pandalab-sdk";

	static readonly availableExternalScripts: ExternalScript[] = [
		{
			name: ExternalScripts.pandalabSdk,
			src: "https://integrations.pandalab.fr/load.min.js",
		},
	];
}
