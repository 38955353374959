import { OnInit, Input, TemplateRef, ViewContainerRef, Directive } from "@angular/core";
import { Features, Permissions } from "src/app/constants";
import { AuthService } from "src/app/services";

/***
 * Use this directive like so: 	*appCheckPermissions="permissions.XXXX; feature: features.XXXX"
 *
 * where
 *
 * permissions: Permissions
 * features: Features
 */
@Directive({
	selector: "[appCheckPermissions]",
})
export class CheckPermissionsDirective implements OnInit {
	@Input() appCheckPermissions?: Permissions;
	@Input() appCheckPermissionsFeature!: Features;

	constructor(
		private _authService: AuthService,
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef
	) {}

	ngOnInit() {
		if (this._authService.user?.hasFeaturePermission(this.appCheckPermissionsFeature, this.appCheckPermissions)) {
			this._viewContainer.createEmbeddedView(this._templateRef);
		} else {
			this._viewContainer.clear();
		}
	}
}
