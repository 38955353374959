export class MaskModelImages {
	// Air liquide
	static readonly almsPrimo: string = "ALMS_RESPIREO-PRIMO.jpg";
	static readonly almsSoftBaby: string = "ALMS_RESPIREO-SOFTBABY.jpg";
	static readonly almsSoftChild: string = "ALMS_RESPIREO-SOFTCHILD.jpg";
	// BMC
	static readonly bmcF5: string = "BMC_F5.jpg";
	static readonly bmcF5A: string = "BMC_F5A.jpg";
	static readonly bmcIvolve1A: string = "BMC_IVOLVE-VF1A.jpg";
	static readonly bmcIvolve1B: string = "BMC_IVOLVE-VF1B.jpg";
	static readonly bmcN5: string = "BMC_N5.jpg";
	static readonly bmcN5A: string = "BMC_N5A.jpg";
	static readonly bmcP2: string = "BMC_P2.jpg";
	// FISHER
	static readonly fisherPaykelBrevida = "fisher-paykel_Brevida.jpg";
	static readonly fisherPaykelEson = "fisher-paykel_Eson.jpg";
	static readonly fisherPaykelEvora = "fisher-paykel_EVORA.jpg";
	static readonly fisherPaykelForma = "fisher-paykel_FORMA.jpg";
	static readonly fisherPaykelHC405 = "fisher-paykel_HC405.jpg";
	static readonly fisherPaykelHC407 = "fisher-paykel_HC407.jpg";
	static readonly fisherPaykelHC431 = "fisher-paykel_HC431.jpg";
	static readonly fisherPaykelOpus = "fisher-paykel_OPUS.jpg";
	static readonly fisherPaykelOracle = "fisher-paykel_ORACLE.jpg";
	static readonly fisherPaykelPilairoq = "fisher-paykel_PILAIROQ.jpg";
	static readonly fisherPaykelSimplus = "fisher-paykel_SIMPLUS.jpg";
	static readonly fisherPaykelVitera = "fisher-paykel_Vitera.jpg";
	static readonly fisherPaykelZest = "fisher-paykel_ZEST.jpg";
	// L3 Medical
	static readonly l3mAnew: string = "L3M_ANEW.jpg";
	static readonly l3mIq: string = "L3M_IQ.jpg";
	static readonly l3mMinnim: string = "L3M_Minnim.jpg";
	static readonly l3mMojo: string = "L3M_MOJO.jpg";
	static readonly l3mSleepweaver: string = "L3M_Sleepweaver-advance.jpg";
	// Lowenstein Medical France
	static readonly lowensteinJoyceSilkGelNasal: string = "loewenstein_joyce-silkgel-Nasal.jpg";
	static readonly lowensteinJoyceSilkGel: string = "loewenstein_joyce-silkgel.jpg";
	static readonly lowensteinCaraFullFace: string = "Lowenstein_CARA-Full-Face.jpg";
	static readonly lowensteinCaraNasal: string = "Lowenstein_CARA-nasal.jpg";
	static readonly lowensteinJoyceGel: string = "Lowenstein_Joyce-gel.jpg";
	static readonly lowensteinJoyceOne: string = "Lowenstein_Joyce-One.jpg";
	static readonly lowensteinJoyceEasyN: string = "Lowenstein_JoyceEASY-N.jpg";
	static readonly lowensteinJoyceEasy: string = "Lowenstein_Joyce-EASY.jpg";
	static readonly lowensteinJoyceOneN: string = "Lowenstein_JoyceOne-N.jpg";
	static readonly lowensteinSilkGelN: string = "Löwenstein_SilkGel-N.jpg";
	// Philips
	static readonly philipsAmaraGel: string = "Philips_Amara-gel.jpg";
	static readonly philipsAmaraView: string = "Philips_Amara-View.jpg";
	static readonly philipsAmara: string = "Philips_Amara.jpg";
	static readonly philipsComfortFull: string = "Philips_comfort full.jpg";
	static readonly philipsConfortGel: string = "Philips_Confor-Gel.jpg";
	static readonly philipsConfortFacial: string = "philips_confort-gel-facial.jpg";
	static readonly philipsDreamwearFacial: string = "Philips_Dreamwear-facial.jpg";
	static readonly philipsDreamwearGel: string = "Philips_dreamwear-gel.jpg";
	static readonly philipsDreamwear: string = "Philips_Dreamwear.jpg";
	static readonly philipsDreamwisp: string = "Philips_dreamwisp.jpg";
	static readonly philipsEasyLife: string = "Philips_EASYLIFE.jpg";
	static readonly philipsFitLife: string = "Philips_fitlife.jpg";
	static readonly philipsNuance: string = "Philips_nuance-pro-gel.jpg";
	static readonly philipsOptilife: string = "Philips_Optilife.jpg";
	static readonly philipsPico: string = "Philips_Pico.jpg";
	static readonly philipsProfile: string = "Philips_PROFILE-LITE.jpg";
	static readonly philipsTrueBlue: string = "Philips_TrueBlue.jpg";
	static readonly philipsWispPedia: string = "Philips_WISP-pedia.jpg";
	static readonly philipsWisp: string = "Philips_WISP.jpg";
	//Resmed
	static readonly resmedAirfitF10: string = "Resmed_AirFit-F10.jpg";
	static readonly resmedAirfitF20: string = "Resmed_AirFit-F20.jpg";
	static readonly resmedAirfitF30: string = "Resmed_AirFit-F30.jpg";
	static readonly resmedAirfitN20: string = "Resmed_AirFit-N20.jpg";
	static readonly resmedAirfitN30: string = "Resmed_AirFit-N30.jpg";
	static readonly resmedAirfitP10: string = "Resmed_AIRFIT-P10.jpg";
	static readonly resmedMirageActiva: string = "Resmed_Mirage-Activa.jpg";
	static readonly resmedMirageFacialNiv: string = "RESMED_Mirage-facial-NIV.jpg";
	static readonly resmedMirageMicro: string = "Resmed_Mirage-Micro.jpg";
	static readonly resmedMirageQuattro: string = "Resmed_Mirage-Quattro.jpg";
	static readonly resmedMirageSoftGel: string = "Resmed_Mirage-Softgel.jpg";
	static readonly resmedMirageNasal: string = "Resmed_Mirage-ULTRA-mirage-Nasal-NIV.jpg";
	static readonly resmedMirageUltra: string = "Resmed_Mirage-ULTRA.jpg";
	static readonly resmedMirageFx: string = "Resmed_MirageFX-N.jpg";
	static readonly resmedSwiftNanoFx: string = "Resmed_NANO-Swift-FX.jpg";
	static readonly resmedPixi: string = "Resmed_PIXI.jpg";
	static readonly resmedQuattroAirNiv: string = "Resmed_Quattro-Air-NIV.jpg";
	static readonly resmedQuattroAir: string = "Resmed_Quattro-Air.jpg";
	static readonly resmedQuattroFxNiv: string = "Resmed_Quattro-FX-NIV.jpg";
	static readonly resmedQuattroFx: string = "Resmed_Quattro-FX.jpg";
	static readonly resmedSwift2: string = "resmed_Swift-2.jpg";
	static readonly resmedSwiftFxBella: string = "Resmed_Swift-FX-Bella.jpg";
	static readonly resmedSwiftFx: string = "Resmed_Swift-FX.jpg";
	static readonly resmedSwiftLt: string = "Resmed_Swift-LT.jpg";
	static readonly resmedSwiftNano: string = "Resmed_Swift-nano.jpg";
}
export class MaskModels {
	static readonly recordedMask: Record<string, string> = {
		"MASQUE RESPIREO PRIMO P": MaskModelImages.almsPrimo,
		"MASQUE RESPIREO PRIMO P NIV": MaskModelImages.almsPrimo,
		"#MASQUE RESPIREO PRIMO N INT S": MaskModelImages.almsPrimo,
		"MASQUE RESPIREO PRIMO F M": MaskModelImages.almsPrimo,
		"MASQUE RESPIREO SOFT BABY XS": MaskModelImages.almsSoftBaby,
		"MASQUE RESPIREO SOFT BABY S": MaskModelImages.almsSoftBaby,
		"MASQUE RESPIREO SOFT CHILD": MaskModelImages.almsSoftChild,
		"MASQUE F5 M": MaskModelImages.bmcF5,
		"MASQUE F5 L": MaskModelImages.bmcF5,
		"MASQUE F5 S": MaskModelImages.bmcF5,
		"MASQUE F5A M": MaskModelImages.bmcF5A,
		"MASQUE F5A L": MaskModelImages.bmcF5A,
		"MASQUE F5A S": MaskModelImages.bmcF5A,
		"MASQUE IVOLVE VF1A M": MaskModelImages.bmcIvolve1A,
		"MASQUE IVOLVE VF1A S": MaskModelImages.bmcIvolve1A,
		"MASQUE IVOLVE VF1A L": MaskModelImages.bmcIvolve1A,
		"#NPU MASQUE IVOLVE VF1B M": MaskModelImages.bmcIvolve1B,
		"#MASQUE IVOLVE VF1B L": MaskModelImages.bmcIvolve1B,
		"#MASQUE IVOLVE VF1B S": MaskModelImages.bmcIvolve1B,
		"MASQUE NASAL N5 M": MaskModelImages.bmcN5,
		"MASQUE NASAL N5 L": MaskModelImages.bmcN5,
		"MASQUE NASAL N5 S": MaskModelImages.bmcN5,
		"MASQUE NASAL N5 XL": MaskModelImages.bmcN5,
		"#NPU MASQUE NASAL N5A M": MaskModelImages.bmcN5A,
		"MASQUE NARINAIRE P2": MaskModelImages.bmcP2,
		"MASQUE BREVIDA XS-S + BULLE M/L": MaskModelImages.fisherPaykelBrevida,
		"MASQUE BREVIDA M-L + 1 BULLE M /L": MaskModelImages.fisherPaykelBrevida,
		"#MASQUE ESON M": MaskModelImages.fisherPaykelEson,
		"#MASQUE ESON L": MaskModelImages.fisherPaykelEson,
		"#MASQUE ESON S": MaskModelImages.fisherPaykelEson,
		"MASQUE ESON 2 M + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelEson,
		"MASQUE ESON 2 L + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelEson,
		"MASQUE ESON M + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelEson,
		"MASQUE ESON 2  S + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelEson,
		"MASQUE ESON L + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelEson,
		"MASQUE ESON S + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelEson,
		"#MASQUE ESON DOUBLE CORPS M/L": MaskModelImages.fisherPaykelEson,
		"MASQUE EVORA M": MaskModelImages.fisherPaykelEvora,
		"MASQUE EVORA S": MaskModelImages.fisherPaykelEvora,
		"MASQUE EVORA L": MaskModelImages.fisherPaykelEvora,
		"MASQUE FORMA L": MaskModelImages.fisherPaykelForma,
		"KIT FORMA L  (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelForma,
		"MASQUE FORMA M": MaskModelImages.fisherPaykelForma,
		"KIT FORMA M  (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelForma,
		"KIT FORMA S (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelForma,
		"KIT FORMA XL  (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelForma,
		"MASQUE FORMA S": MaskModelImages.fisherPaykelForma,
		"#MASQUE FORMA XL": MaskModelImages.fisherPaykelForma,
		"#MASQUE FORMA M/L NV": MaskModelImages.fisherPaykelForma,
		"#KIT HC405 L (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelHC405,
		"#MASQUE HC405 L": MaskModelImages.fisherPaykelHC405,
		"MASQUE HC405 L": MaskModelImages.fisherPaykelHC405,
		"#KIT HC405 S (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelHC405,
		"#MASQUE HC405 S": MaskModelImages.fisherPaykelHC405,
		"MASQUE HC406": MaskModelImages.fisherPaykelHC405,
		"#MASQUE HC405": MaskModelImages.fisherPaykelHC405,
		"KIT HC406 (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelHC405,
		"#KIT HC407 (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelHC407,
		"MASQUE HC407": MaskModelImages.fisherPaykelHC407,
		"MASQUE HC431U": MaskModelImages.fisherPaykelHC431,
		"#MASQUE HC431 NIV": MaskModelImages.fisherPaykelHC431,
		"MASQUE HC432 L": MaskModelImages.fisherPaykelHC431,
		"MASQUE HC432": MaskModelImages.fisherPaykelHC431,
		"MASQUE HC432 S": MaskModelImages.fisherPaykelHC431,
		"MASQUE HC432 M": MaskModelImages.fisherPaykelHC431,
		"#MASQUE OPUS": MaskModelImages.fisherPaykelOpus,
		"MASQUE ORACLE 2": MaskModelImages.fisherPaykelOracle,
		"MASQUE PILAIRO Q": MaskModelImages.fisherPaykelPilairoq,
		"#MASQUE SIMPLUS M": MaskModelImages.fisherPaykelSimplus,
		"#MASQUE SIMPLUS L": MaskModelImages.fisherPaykelSimplus,
		"#MASQUE SIMPLUS S": MaskModelImages.fisherPaykelSimplus,
		"MASQUE SIMPLUS M + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelSimplus,
		"MASQUE SIMPLUS L + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelSimplus,
		"#MASQUE SIMPLUS DOUBLE CORPS M-L": MaskModelImages.fisherPaykelSimplus,
		"MASQUE SIMPLUS S + BULLE (PACK ANNUEL)": MaskModelImages.fisherPaykelSimplus,
		"MASQUE VITERA M + CORPS ADDITIONNEL": MaskModelImages.fisherPaykelVitera,
		"MASQUE VITERA S + CORPS ADDITIONNEL": MaskModelImages.fisherPaykelVitera,
		"MASQUE VITERA L + CORPS ADDITIONNEL": MaskModelImages.fisherPaykelVitera,
		"MASQUE ZEST": MaskModelImages.fisherPaykelZest,
		"KIT ZEST (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelZest,
		"MASQUE ZEST PLUS": MaskModelImages.fisherPaykelZest,
		"#KIT ZEST PLUS (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelZest,
		"#MASQUE ZEST Q": MaskModelImages.fisherPaykelZest,
		"MASQUE ZEST PETITE": MaskModelImages.fisherPaykelZest,
		"KIT ZEST PETIT (MOUSSE/BULLE/HARNAIS)": MaskModelImages.fisherPaykelZest,
		"BULLE ZEST + MOUSSE": MaskModelImages.fisherPaykelZest,
		"MASQUE ZEST Q L": MaskModelImages.fisherPaykelZest,
		"MASQUE SLEEPWEAVER ANEW+ HARNAIS M": MaskModelImages.l3mAnew,
		"MASQUE SLEEPWEAVER ANEW+ HARNAIS L": MaskModelImages.l3mAnew,
		"MASQUE SLEEPWEAVER ANEW+ HARNAIS S": MaskModelImages.l3mAnew,
		"MASQUE SLEEPWEAVER ANEW SEUL S": MaskModelImages.l3mAnew,
		"#MASQUE IQ AVEC FUITE 3 POINTS TU": MaskModelImages.l3mIq,
		"MASQUE IQ 5 POINTS TU": MaskModelImages.l3mIq,
		"#MASQUE IQ SS FUITE 3 POINTS TU": MaskModelImages.l3mIq,
		"MASQUE MINIME 2 S/M": MaskModelImages.l3mMinnim,
		"MASQUE MINIME 2 M/L": MaskModelImages.l3mMinnim,
		"#MASQUE MINIME 2 S/M NIV": MaskModelImages.l3mMinnim,
		"MASQUE MINIME 2 M-L NIV": MaskModelImages.l3mMinnim,
		"MASQUE PEDIA MINI BUCCO NASAL CIRRI S": MaskModelImages.l3mMinnim,
		"#MASQUE MOJO M": MaskModelImages.l3mMojo,
		"#MASQUE MOJO L": MaskModelImages.l3mMojo,
		"MASQUE MOJO S": MaskModelImages.l3mMojo,
		"MASQUE SLEEPWEAVER ADVANCE BLEU + HARN.": MaskModelImages.l3mSleepweaver,
		"KIT MASQUE SLEEPWEAVER ELAN COMPLET": MaskModelImages.l3mSleepweaver,
		"MASQUE SLEEPWEAVER ADVANCE BEIGE + HARN.": MaskModelImages.l3mSleepweaver,
		"#MASQUE JOYCE SILK GEL M": MaskModelImages.lowensteinJoyceSilkGelNasal,
		"#MASQUE JOYCE SILK GEL L": MaskModelImages.lowensteinJoyceSilkGelNasal,
		"MASQUE JOYCE SILK GEL S": MaskModelImages.lowensteinJoyceSilkGelNasal,
		"MASQUE JOYCE FULL FACE SILK GEL M": MaskModelImages.lowensteinJoyceSilkGel,
		"MASQUE JOYCE FULL FACE SILK GEL L": MaskModelImages.lowensteinJoyceSilkGel,
		"MASQUE SILICONE CARA S-M": MaskModelImages.lowensteinCaraNasal,
		"MASQUE SILICONE CARA M-L": MaskModelImages.lowensteinCaraNasal,
		"MASQUE SILICONE CARA XS": MaskModelImages.lowensteinCaraNasal,
		"MASQUE CARA FULL FACE M": MaskModelImages.lowensteinCaraFullFace,
		"#MASQUE JOYCE GEL M": MaskModelImages.lowensteinJoyceGel,
		"#MASQUE JOYCE GEL L": MaskModelImages.lowensteinJoyceGel,
		"MASQUE JOYCE ONE M": MaskModelImages.lowensteinJoyceOneN,
		"MASQUE JOYCE EASY M": MaskModelImages.lowensteinJoyceEasyN,
		"MASQUE JOYCE EASY L": MaskModelImages.lowensteinJoyceEasyN,
		"MASQUE JOYCE EASY S": MaskModelImages.lowensteinJoyceEasyN,
		"#MASQUE JOYCE M": MaskModelImages.lowensteinJoyceEasyN,
		"MASQUE JOYCE L": MaskModelImages.lowensteinJoyceEasyN,
		"MASQUE JOYCE EASY FULL FACE M": MaskModelImages.lowensteinJoyceEasy,
		"MASQUE JOYCE EASY FULL FACE L": MaskModelImages.lowensteinJoyceEasy,
		"#MASQUE JOYCE FULL FACE L": MaskModelImages.lowensteinJoyceEasy,
		"#MASQUE JOYCE FULL FACE M": MaskModelImages.lowensteinJoyceEasy,
		"MASQUE JOYCE FULL FACE GEL M": MaskModelImages.lowensteinJoyceGel,
		"#MASQUE JOYCE S": MaskModelImages.lowensteinJoyceEasy,
		"MASQUE JOYCE FULL FACE XL": MaskModelImages.lowensteinJoyceEasy,
		"#MASQUE JOYCE XL": MaskModelImages.lowensteinJoyceEasy,
		"#MASQUE JOYCE FULL FACE GEL L": MaskModelImages.lowensteinJoyceGel,
		"MASQUE JOYCE FULL FACE PLUS L": MaskModelImages.lowensteinJoyceEasy,
		"#MASQUE JOYCE EASY FULL FACE S": MaskModelImages.lowensteinJoyceEasy,
		"#MASQUE COMFORT FULL 2 M": MaskModelImages.philipsComfortFull,
		"MASQUE COMFORTGEL BLUE M": MaskModelImages.philipsConfortGel,
		"MASQUE COMFORTGEL BLUE S": MaskModelImages.philipsConfortGel,
		"MASQUE COMFORTGEL BLUE L": MaskModelImages.philipsConfortGel,
		"#MASQUE COMFORTGEL BLUE P": MaskModelImages.philipsConfortGel,
		"MASQUE COMFORTGEL BLUE M NIV": MaskModelImages.philipsConfortGel,
		"#MASQUE COMFORTGEL BLUE FULL MD": MaskModelImages.philipsConfortFacial,
		"#MASQUE COMFORTGEL BLUE FULL LG": MaskModelImages.philipsConfortFacial,
		"MASQUE COMFORTGEL BLUE FULL SM": MaskModelImages.philipsConfortFacial,
		"MASQUE COMFORTGEL BLUE FULL XLG": MaskModelImages.philipsConfortFacial,
		"#MASQUE COMFORTGEL FULL S": MaskModelImages.philipsConfortFacial,
		"#MASQUE COMFORTGEL FULL L": MaskModelImages.philipsConfortFacial,
		"MASQUE DREAMWEAR NASO-BUCCAL M": MaskModelImages.philipsDreamwearFacial,
		"MASQUE DREAMWEAR NASO-BUCCAL L": MaskModelImages.philipsDreamwearFacial,
		"MASQUE DREAMWEAR NASO-BUCCAL S": MaskModelImages.philipsDreamwearFacial,
		"MASQUE DREAMWEAR NASO-BUCCAL MW": MaskModelImages.philipsDreamwearFacial,
		"MASQUE DREAMWEAR MW": MaskModelImages.philipsDreamwear,
		"#MASQUE DREAMWEAR FITPACK": MaskModelImages.philipsDreamwear,
		"MASQUE DREAMWEAR GEL FITPACK": MaskModelImages.philipsDreamwearGel,
		"MASQUE DREAMWISP M": MaskModelImages.philipsDreamwisp,
		"MASQUE DREAMWISP L": MaskModelImages.philipsDreamwisp,
		"MASQUE DREAMWISP S": MaskModelImages.philipsDreamwisp,
		"KIT COUSSIN EASYLIFE L": MaskModelImages.philipsEasyLife,
		"npu/MASQUE EASYLIFE P": MaskModelImages.philipsEasyLife,
		"KIT COUSSIN EASYLIFE M": MaskModelImages.philipsEasyLife,
		"#MASQUE FITLIFE XL": MaskModelImages.philipsEasyLife,
		"KIT COUSSIN EASYLIFE S": MaskModelImages.philipsEasyLife,
		"KIT COUSSIN EASYLIFE MSH": MaskModelImages.philipsEasyLife,
		"#MASQUE EASY LIFE FITPACK S-M": MaskModelImages.philipsEasyLife,
		"MASQUE EASYLIFE STD": MaskModelImages.philipsEasyLife,
		"#MASQUE FITLIFE L": MaskModelImages.philipsFitLife,
		"#MASQUE FITLIFE STD": MaskModelImages.philipsFitLife,
		"MASQUE NUANCE PRO GEL S-M-L": MaskModelImages.philipsNuance,
		"npu/MASQUE OPTILIFE (PACK STD)": MaskModelImages.philipsOptilife,
		"npu/MASQUE OPTILIFE": MaskModelImages.philipsOptilife,
		"#MASQUE OPTILIFE (PACK LARGE)  ML/LN": MaskModelImages.philipsOptilife,
		"MASQUE PICO L": MaskModelImages.philipsPico,
		"MASQUE PICO S-M": MaskModelImages.philipsPico,
		"MASQUE PICO XL": MaskModelImages.philipsPico,
		"#MASQUE PROFILE LITE L": MaskModelImages.philipsProfile,
		"#MASQUE PROFILE LITE S": MaskModelImages.philipsProfile,
		"npuMASQUE TRUEBLUE DISPOSITIF DE FUITE S": MaskModelImages.philipsTrueBlue,
		"MASQUE WISP PEDIA. FITPACK-MOTIF GIRAFE": MaskModelImages.philipsWispPedia,
		"MASQUE WISP FITPACK TISSU S-M-L": MaskModelImages.philipsWisp,
		"MASQUE WISP SILICONE P S-M-L": MaskModelImages.philipsWisp,
		"MASQUE WISP FITPACK P,SM,L NIV SILICONE": MaskModelImages.philipsWisp,
		"MASQUE WISP + BULLE P TISSU (KIT ANNUEL)": MaskModelImages.philipsWisp,
		"MASQUE AMARA VIEW M": MaskModelImages.philipsAmaraView,
		"MASQUE AMARA VIEW L": MaskModelImages.philipsAmaraView,
		"MASQUE AMARA VIEW S": MaskModelImages.philipsAmaraView,
		"MASQUE AMARA GEL M": MaskModelImages.philipsAmaraGel,
		"MASQUE AMARA GEL L": MaskModelImages.philipsAmaraGel,
		"MASQUE AMARA GEL S": MaskModelImages.philipsAmaraGel,
		"MASQUE AMARA M": MaskModelImages.philipsAmara,
		"MASQUE AMARA L": MaskModelImages.philipsAmara,
		"#MASQUE AMARA S": MaskModelImages.philipsAmara,
		"#KIT AMARA M + HARNAIS": MaskModelImages.philipsAmara,
		"MASQUE DREAMWEAR M": MaskModelImages.philipsDreamwear,
		"MASQUE DREAMWEAR S": MaskModelImages.philipsDreamwear,
		"MASQUE DREAMWEAR L": MaskModelImages.philipsDreamwear,
		"MASQUE AIRFIT F10 M": MaskModelImages.resmedAirfitF10,
		"MASQUE AIRFIT F10 L": MaskModelImages.resmedAirfitF10,
		"MASQUE AIRFIT F10 S": MaskModelImages.resmedAirfitF10,
		"#KIT AIRFIT F10 (BULLE+HARNAIS) M": MaskModelImages.resmedAirfitF10,
		"#KIT AIRFIT F10 (BULLE+HARNAIS) S": MaskModelImages.resmedAirfitF10,
		"MASQUE AIRFIT F10 XS": MaskModelImages.resmedAirfitF10,
		"#KIT AIRFIT F10 (BULLE+HARNAIS) XS": MaskModelImages.resmedAirfitF10,
		"MASQUE AIRFIT F10+BULLE M (ACHAT PLAT.)": MaskModelImages.resmedAirfitF10,
		"MASQUE AIRFIT F10+BULLE L (ACHAT PLAT.)": MaskModelImages.resmedAirfitF10,
		"MASQUE AIRFIT F20 M": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20 L": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20 S": MaskModelImages.resmedAirfitF20,
		"#KIT AIRFIT F20 (BULLE+HARNAIS) M": MaskModelImages.resmedAirfitF20,
		"#KIT AIRFIT F20 (BULLE+HARNAIS) L": MaskModelImages.resmedAirfitF20,
		"#KIT AIRFIT F20 (BULLE+HARNAIS) S": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20 FOR HER M": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20 FOR HER S": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20+BULLE L (ACHAT PLAT.)": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20+BULLE M (ACHAT PLAT.)": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F20+BULLE S (ACHAT PLAT.)": MaskModelImages.resmedAirfitF20,
		"MASQUE AIRFIT F30 M": MaskModelImages.resmedAirfitF30,
		"MASQUE AIRFIT F30 S": MaskModelImages.resmedAirfitF30,
		"MASQUE AIRFIT F30i M": MaskModelImages.resmedAirfitF30,
		"MASQUE AIRFIT F30i S": MaskModelImages.resmedAirfitF30,
		"MASQUE AIRFIT F30i W": MaskModelImages.resmedAirfitF30,
		"MASQUE AIRFIT N20 M": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N20 L": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N20 S": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N20 FOR HER": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N20 CLASSIC M": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N20 CLASSIC S": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N20 CLASSIC L": MaskModelImages.resmedAirfitN20,
		"MASQUE AIRFIT N30I QUIET M /STD": MaskModelImages.resmedAirfitN30,
		"MASQUE AIRFIT N30I QUIET S": MaskModelImages.resmedAirfitN30,
		"MASQUE AIRFIT N30": MaskModelImages.resmedAirfitN30,
		"MASQUE AIRFIT P30I STD": MaskModelImages.resmedAirfitN30,
		"MASQUE AIFIT P30I S": MaskModelImages.resmedAirfitN30,
		"MASQUE AIRFIT P10": MaskModelImages.resmedAirfitP10,
		"MASQUE AIRFIT P10 FOR HER": MaskModelImages.resmedAirfitP10,
		"#MASQUE MIRAGE ACTIVA LT L": MaskModelImages.resmedMirageActiva,
		"#MASQUE MIRAGE ACTIVA LT M": MaskModelImages.resmedMirageActiva,
		"#MASQUE MIRAGE ACTIVA LT LW": MaskModelImages.resmedMirageActiva,
		"#MASQUE MIRAGE ACTIVA STANDARD": MaskModelImages.resmedMirageActiva,
		"#KIT ACTIVA LT - M": MaskModelImages.resmedMirageActiva,
		"#KIT ACTIVA LT - L": MaskModelImages.resmedMirageActiva,
		"MASQUE NASAL MICRO M": MaskModelImages.resmedMirageMicro,
		"#KIT AIRFIT F10 (BULLE+HARNAIS) L": MaskModelImages.resmedMirageMicro,
		"MASQUE NASAL MICRO L": MaskModelImages.resmedMirageMicro,
		"MASQUE MIRAGE MICRO M/L": MaskModelImages.resmedMirageMicro,
		"MASQUE NASAL MICRO XL": MaskModelImages.resmedMirageMicro,
		"MASQUE NASAL MICRO S": MaskModelImages.resmedMirageMicro,
		"#MASQUE NASAL MICRO LW": MaskModelImages.resmedMirageMicro,
		"MASQUE MIRAGE MICRO LW/XL": MaskModelImages.resmedMirageMicro,
		"#KIT MIRAGE MICRO - M": MaskModelImages.resmedMirageMicro,
		"MASQUE QUATTRO M": MaskModelImages.resmedMirageQuattro,
		"#KIT QUATTRO M": MaskModelImages.resmedMirageQuattro,
		"MASQUE QUATTRO L": MaskModelImages.resmedMirageQuattro,
		"#KIT QUATTRO L": MaskModelImages.resmedMirageQuattro,
		"MASQUE QUATTRO S": MaskModelImages.resmedMirageQuattro,
		"#KIT QUATTRO S": MaskModelImages.resmedMirageQuattro,
		"MASQUE QUATTRO XS": MaskModelImages.resmedMirageQuattro,
		"#KIT QUATTRO XS": MaskModelImages.resmedMirageQuattro,
		"MASQUE SOFTGEL M": MaskModelImages.resmedMirageSoftGel,
		"#MASQUE SOFTGEL L": MaskModelImages.resmedMirageSoftGel,
		"MASQUE SOFTGEL S": MaskModelImages.resmedMirageSoftGel,
		"MASQUE MIRAGE FACIAL M NIV": MaskModelImages.resmedMirageFacialNiv,
		"MASQUE ULTRA MIRAGE NV STANDARD M": MaskModelImages.resmedMirageUltra,
		"MASQUE ULTRA MIRAGE NV STANDARD L": MaskModelImages.resmedMirageUltra,
		"MASQUE ULTRA MIRAGE LARGE SHALLOW": MaskModelImages.resmedMirageUltra,
		"MASQUE ULTRA MIRAGE NV STANDARD S": MaskModelImages.resmedMirageUltra,
		"npu/MASQUE ULTRA MIRAGE L": MaskModelImages.resmedMirageUltra,
		"npu/MASQUE ULTRA MIRAGE FULL FACE L": MaskModelImages.resmedMirageUltra,
		"MASQUE MIRAGE FX": MaskModelImages.resmedMirageFx,
		"MASQUE PEDIA. PIXI": MaskModelImages.resmedPixi,
		"MASQUE QUATTRO AIR NIV L": MaskModelImages.resmedQuattroAirNiv,
		"MASQUE QUATTRO AIR NIV XS": MaskModelImages.resmedQuattroAirNiv,
		"MASQUE QUATTRO AIR M": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTRO AIR L": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTRO AIR S": MaskModelImages.resmedQuattroAir,
		"#KIT QUATTRO AIR M": MaskModelImages.resmedQuattroAir,
		"#KIT QUATTRO AIR L": MaskModelImages.resmedQuattroAir,
		"#KIT QUATTRO AIR S": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTRO AIR XS": MaskModelImages.resmedQuattroAir,
		"#KIT QUATTRO AIR XS": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTRO AIR NIV M": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTRO AIR NIV S": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTROAIR+BULLE M (ACHAT PLAT.)": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTROAIR+BULLE S (ACHAT PLAT.)": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTROAIR+BULLE L (ACHAT PLAT.)": MaskModelImages.resmedQuattroAir,
		"MASQUE QUATTRO FX M": MaskModelImages.resmedQuattroFx,
		"#KIT QUATTRO FX M": MaskModelImages.resmedQuattroFx,
		"MASQUE QUATTRO FX L": MaskModelImages.resmedQuattroFx,
		"#KIT QUATTRO FX L": MaskModelImages.resmedQuattroFx,
		"MASQUE QUATTRO FX S": MaskModelImages.resmedQuattroFx,
		"#KIT QUATTRO FX S": MaskModelImages.resmedQuattroFx,
		"MASQUE QUATTRO FX NIV S": MaskModelImages.resmedQuattroFxNiv,
		"MASQUE QUATTRO FX NIV M": MaskModelImages.resmedQuattroFxNiv,
		"#MASQUE QUATTRO FX NIV L": MaskModelImages.resmedQuattroFxNiv,
		"npu/MASQUE SWIFT 2": MaskModelImages.resmedSwift2,
		"npu/MASQUE SWIFT FX BELLA": MaskModelImages.resmedSwiftFxBella,
		"npu/MASQUE SWIFT FX BELLA GRAY": MaskModelImages.resmedSwiftFxBella,
		"MASQUE SWIFT FX": MaskModelImages.resmedSwiftFx,
		"MASQUE SWIFT FX FOR HER": MaskModelImages.resmedSwiftFx,
		"npu/MASQUE SWIFT LT": MaskModelImages.resmedSwiftLt,
		"#MASQUE SWIFT FX NANO WIDE": MaskModelImages.resmedSwiftNanoFx,
		"MASQUE SWIFT FX NANO FOR HER": MaskModelImages.resmedSwiftNanoFx,
	};
}
