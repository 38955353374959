import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort, SortDirection } from "@angular/material/sort";
import { catchError, map, merge, Observable, of, Subject, Subscription } from "rxjs";
import { FilterTableColumnTypes } from "src/app/@shared/constants/filter-table-column-types.constant";
import { FormatTableColumnTypes } from "src/app/@shared/constants/format-table-column-types.constant";
import { SortTableColumnTypes } from "src/app/@shared/constants/sort-table-column-types.constant";
import { TypeColumnAction } from "src/app/@shared/constants/type-column-action.constant";
import { BrowseFilteredColumn, BrowsePaging } from "src/app/@shared/types/browse-paging";
import {
	MultipleChoice,
	PagerTableRowItem,
	TableColumnItem,
	TableRowItem,
	TableSettings,
} from "src/app/@shared/types/table/table";
import { StateRowTypes } from "src/app/@shared/constants/state-row-type";
import { TableFilterEvent } from "src/app/@shared/events/table-filter.event";
import { StorageService } from "src/app/services/storage.service";
import { MessageStatus } from "src/app/constants/message-status-constant";
import { SelectionModel } from "@angular/cdk/collections";
import { ObservanceStatusCodes } from "src/app/constants/observance-level.constant";
import moment from "moment";
import { PrescriptionSigningStatus } from "src/app/constants/prescriptions.constant";

@Component({
	selector: "app-table-view",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "table-view.component.html",
})
export class TableViewComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() tableSettings!: TableSettings;
	@Input() cacheFilterName = "";
	@Input() showPaginator = true;
	@Input() paginatorPageSize = 20;
	@Input() paginatorPageSizeOptions: number[] = [20, 50, 100];
	@Input() paginatorLabel = "";
	@Input() noRowsLabel = "";
	@Input() sortActiveColumn!: string;
	@Input() sortActiveDirection!: SortDirection;
	@Input() selectAllLabel!: string;
	@Input() buttonDisabled = false;
	@Input() buttonLabel!: string;
	@Input() changeParams$: Observable<void> = of();
	@Input() getItemsMethod!: (value: BrowsePaging) => Observable<PagerTableRowItem>;
	@Input() onExpandReadMore!: (messageId: string) => void;

	@Output() readonly isTableComponentOnError = new EventEmitter<boolean>();
	@Output() readonly selectedRows = new EventEmitter<TableRowItem[]>();
	@Output() readonly noDataChange = new EventEmitter<boolean>();

	@ViewChild(MatPaginator)
	private _paginator!: MatPaginator;

	@ViewChild(MatSort)
	private _sort!: MatSort;

	get paginatorPageIndex(): number {
		return this._paginator.pageIndex;
	}

	observanceLevel = ObservanceStatusCodes;
	defaultExpand = false;
	isTableFirstLoading = true;
	isTableLoading = true;
	isTableOnError = false;
	items: TableRowItem[] = [{}, {}, {}];
	nbFilteredItems = 0;
	displayAccordion = false;
	currentFilters: BrowseFilteredColumn[] = [];
	selector: SelectionModel<TableRowItem> = new SelectionModel<TableRowItem>(true, []);

	filterTableColumnTypes = FilterTableColumnTypes;
	formatTableColumnTypes = FormatTableColumnTypes;
	sortTableColumnTypes = SortTableColumnTypes;
	typeColumnAction = TypeColumnAction;

	prescriptionSigningStatus = PrescriptionSigningStatus;

	private _filtersChangeSubject: Subject<TableFilterEvent> = new Subject<TableFilterEvent>();
	filterChanges$: Observable<TableFilterEvent> = this._filtersChangeSubject.asObservable();

	private _initialLoadSubscription!: Subscription;
	private _tableChangesSubscription!: Subscription;
	private _sortSubscription!: Subscription;
	private _settingChangesSubscription!: Subscription;
	private _onPageInit = true;

	constructor(
		private _storageService: StorageService,
		private _ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		const cacheFilters = this._storageService.retrieveCacheValue(this.cacheFilterName);
		if (cacheFilters) this.currentFilters = cacheFilters;
		this.notifyChanges("id", true);

		this.loadData();
	}

	ngAfterViewInit(): void {
		this.manageTableChangesSubscription();
		if (this.currentFilters[0] && this.currentFilters[0].name === "id") {
			this.defaultExpand = true;
		}
		this._onPageInit = false;
	}

	activeAccordion(event: Event): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		this.displayAccordion = isChecked;
	}

	loadData() {
		this._initialLoadSubscription = this.loadDataSubscription();
	}

	loadDataSubscription(): Subscription {
		this.isTableLoading = true;
		this.isTableOnError = false;

		const browsePaging: BrowsePaging = this.createCommand();

		if (this.cacheFilterName && this.currentFilters.length > 0)
			this._storageService.saveCacheValue(this.cacheFilterName, this.currentFilters);

		return this.getItemsMethod(browsePaging)
			.pipe(
				catchError(() => {
					this.isTableFirstLoading = false;
					this.isTableOnError = true;
					this.isTableComponentOnError.emit(true);
					return of(null);
				})
			)
			.pipe(
				map((data: PagerTableRowItem | null) => {
					// Flip flag to show that loading has finished.
					this.isTableFirstLoading = false;
					this.isTableLoading = false;

					if (data === null) {
						this.noDataChange.emit(true);
						return [];
					}

					// Only refresh the result length if there is new data. In case of rate
					// limit errors, we do not want to reset the paginator to zero, as that
					// would prevent users from re-triggering requests.
					this.nbFilteredItems = data.totalFilteredItems;
					this.noDataChange.emit(data.totalItems === 0);
					return data.items;
				})
			)
			.subscribe((items: TableRowItem[]) => {
				this.items = items;
				this.selector.clear();
				this._ref.detectChanges();

				if (this.currentFilters[0] && this.currentFilters[0].name === "id") {
					this.defaultExpand = true;
				}
			});
	}

	manageTableChangesSubscription(): void {
		// If the user changes the sort order, reset back to the first page.
		this._sortSubscription = this._sort.sortChange.subscribe(() => (this._paginator.pageIndex = 0));
		this._paginator._intl.itemsPerPageLabel = this.tableSettings.pagerLabel;
		this._tableChangesSubscription = merge(
			this._sort.sortChange,
			this._paginator.page,
			this._filtersChangeSubject,
			this.changeParams$
		).subscribe(() => {
			return this.loadDataSubscription();
		});

		this._settingChangesSubscription = this.tableSettings.settingsChanges$.subscribe(_ => {
			this._ref.detectChanges();
		});
	}

	ngOnDestroy(): void {
		if (this._initialLoadSubscription) {
			this._initialLoadSubscription.unsubscribe();
		}

		if (this._tableChangesSubscription) {
			this._tableChangesSubscription.unsubscribe();
		}

		if (this._sortSubscription) {
			this._sortSubscription.unsubscribe();
		}

		if (this._settingChangesSubscription) {
			this._settingChangesSubscription.unsubscribe();
		}
	}

	getClassByStateBefore(row: any): string {
		if (row && row["stateBefore"]) {
			switch (row["stateBefore"].value) {
				case StateRowTypes.high:
					return " before-red";
				case StateRowTypes.warning:
					return " before-orange";
				default:
					return "";
			}
		} else {
			return "";
		}
	}

	getDisplayStatus(status: string): string {
		switch (status) {
			case PrescriptionSigningStatus.upToDate:
				return "À jour";
			case PrescriptionSigningStatus.inProgress:
				return "Validation en cours";
			case PrescriptionSigningStatus.toSignPast:
				return "À signer";
			case PrescriptionSigningStatus.toSignEarly:
			case PrescriptionSigningStatus.toSignFuture:
				return "À venir";
			case "unknown":
				return "Indéterminé";
			default:
				return status;
		}
	}

	getMessageDisplayStatus(status: string): string {
		switch (status) {
			case MessageStatus.needTreatment:
				return "À traiter";
			case MessageStatus.inProgress:
				return "Pris en charge";
			case MessageStatus.treated:
				return "Traité";
			default:
				return status;
		}
	}

	getMessageStatusClass(status: string): string {
		switch (status) {
			case MessageStatus.needTreatment:
				return "prescription-red";
			case MessageStatus.inProgress:
				return "prescription-orange";
			case MessageStatus.treated:
				return "prescription-green";
			default:
				return status;
		}
	}

	getClassByStateAfter(row: any): string {
		if (row && row["stateAfter"]) {
			switch (row["stateAfter"].value) {
				case StateRowTypes.high:
					return " after-red";
				case StateRowTypes.warning:
					return " after-orange";
				default:
					return "";
			}
		} else {
			return "";
		}
	}

	getClassByState(row: any): string {
		if (row && row["state"]) {
			switch (row["state"].value) {
				case StateRowTypes.high:
					return "highlight red" + this.getClassByStateBefore(row) + this.getClassByStateAfter(row);
				case StateRowTypes.warning:
					return "highlight orange" + this.getClassByStateBefore(row) + this.getClassByStateAfter(row);
				case StateRowTypes.empty:
					return row["duration"].value == 0
						? "highlight red" + this.getClassByStateBefore(row) + this.getClassByStateAfter(row)
						: "highlight empty" + this.getClassByStateBefore(row) + this.getClassByStateAfter(row);
				default:
					return "highlight" + this.getClassByStateBefore(row) + this.getClassByStateAfter(row);
			}
		} else {
			return "";
		}
	}

	getHighState(row: any): boolean {
		if (row && row["state"]) {
			switch (row["state"].value) {
				case StateRowTypes.high:
					return true;
				case StateRowTypes.warning:
					return false;
				case StateRowTypes.empty:
					return false;
				default:
					return false;
			}
		} else {
			return false;
		}
	}

	getPercentage(row: any): number {
		// durée en minute  --> x
		// max possible: 480 --> 100
		return Math.max(5, Math.min(Math.round((row["duration"].value * 100) / 480), 100));
	}

	callFunction(method: () => void): void {
		method();
	}

	getDurationProgressClass(row: any): string {
		const durationProgress = Math.max(5, Math.min(Math.round((row["duration"].value * 100) / 480), 100));
		const durationProgressDisplay: string =
			row["duration"] && row["duration"].value ? "w-" + (durationProgress > 100 ? 100 : durationProgress) : "w-5";

		if (row && row["state"]) {
			switch (row["state"].value) {
				case StateRowTypes.high:
					return durationProgressDisplay + " red";
				case StateRowTypes.empty:
					return durationProgressDisplay + " red";
				case StateRowTypes.warning:
					return durationProgressDisplay + " orange";
				case StateRowTypes.regular:
					return durationProgressDisplay + " green";
				default:
					return durationProgressDisplay;
			}
		} else {
			return "";
		}
	}

	onSortClicked(columnName: string, column: TableColumnItem): void {
		let direction = "" as SortDirection;
		this.cleanSortDirection(columnName);
		if (column.sortDirection) {
			direction = column.sortDirection == "asc" ? "desc" : "asc";
			column.sortDirection = direction;
		} else {
			direction = "asc";
			column.sortDirection = direction;
		}
		const sortColumn: string = column.sortTableColumnName ?? columnName;
		// Only notify change if a new sort is detected
		if (this._sort.active === sortColumn && this._sort.direction === direction) {
			return;
		}

		this._sort.active = sortColumn;
		this._sort.direction = direction;

		const sortState: Sort = { active: sortColumn, direction: direction };
		this._sort.sortChange.emit(sortState);
	}

	private cleanSortDirection(columnName: string): void {
		this.tableSettings.displayedColumns
			.filter(
				col =>
					col !== columnName &&
					(this.tableSettings.columns[col].sortDirection === "asc" ||
						this.tableSettings.columns[col].sortDirection === "desc")
			)
			.forEach(col => {
				this.tableSettings.columns[col].sortDirection = "";
			});
	}

	onFilterChange(_: string, event: TableFilterEvent): void {
		if (!event) return;

		if (event.filteredColumns?.length > 0)
			// Remove all filter with the event's column name
			this.currentFilters = this.getFiltersOnChange(event.columnName);

		// if the event is a clean --> do nothing more
		if (event.isClean) {
			this.currentFilters = this.getFiltersOnChange(event.columnName);
			this.notifyChanges(event.columnName, event.isClean);

			if (this.cacheFilterName) this._storageService.saveCacheValue(this.cacheFilterName, this.currentFilters);

			return;
		}

		// Add the event's filters to the table filters
		this.currentFilters = this.currentFilters.concat(event.filteredColumns);
		// Remove duplicated event filters. It happens on page load
		this.currentFilters = this.currentFilters.filter(
			(current, index, all) =>
				all.findIndex(
					current2 =>
						current2.name === current.name &&
						current2.operation === current.operation &&
						current2.value === current.value
				) === index
		);
		//reset PageIndex each new filter
		if (this._paginator) this._paginator.pageIndex = 0;
		this.notifyChanges(event.columnName, event.isClean);
	}

	private getFiltersOnChange(columnName: string) {
		if (!this._onPageInit) {
			return this.currentFilters.filter(f => f.name !== columnName);
		}
		return this.currentFilters;
	}

	private notifyChanges(columnName: string, isClean: boolean): void {
		this._filtersChangeSubject.next({
			columnName: columnName,
			filteredColumns: this.currentFilters,
			isClean: isClean,
		});
	}

	removeFilter(filter: BrowseFilteredColumn): void {
		const filterColumnIndex: number = this.currentFilters.findIndex(e => e.name === filter.name);

		if (filterColumnIndex !== -1) this.currentFilters.splice(filterColumnIndex, 1);
		this.notifyChanges(filter.name, true);

		if (!this.cacheFilterName) return;

		this._storageService.saveCacheValue(this.cacheFilterName, this.currentFilters);
	}

	private getDisplayValueForComparison(filterValue: string, choices: MultipleChoice[] | undefined): string {
		const multipleChoice = choices?.find((c: MultipleChoice) => {
			if (Array.isArray(c.value)) return c.value.join("; ") === filterValue;
			return c.value === filterValue;
		});

		return multipleChoice ? multipleChoice?.name : filterValue;
	}

	private getDisplayValueForInOrContains(filterValue: string, choices: MultipleChoice[] | undefined): string {
		const values = filterValue.split(";");
		const displayNameValues = choices
			?.filter((c: MultipleChoice) => {
				return values?.find((value: string) => c.value?.toString().includes(value));
			})
			.map((c: MultipleChoice) => c.name);
		return displayNameValues ? displayNameValues.join(", ") : filterValue;
	}

	private getDisplayValueForDateTime(filterValue: string): string {
		return moment.utc(filterValue).local().format("DD/MM/yyyy");
	}

	getDisplayNameValueFilter(filterValue: string, columnName: string): string {
		let displayNameValue;
		if (this.tableSettings.columns[columnName]) {
			const choices = this.tableSettings.columns[columnName].filterTableColumnTypeChoices;
			const column = this.tableSettings.columns[columnName];

			if (column.filterTableColumnName === FormatTableColumnTypes.isLocked) {
				return filterValue == "false" ? "Actif" : "Inactif";
			}

			switch (column.filterTableColumnType) {
				case FilterTableColumnTypes.comparison:
					displayNameValue = this.getDisplayValueForComparison(filterValue, choices);
					break;
				case FilterTableColumnTypes.in:
				case FilterTableColumnTypes.contains:
					displayNameValue = this.getDisplayValueForInOrContains(filterValue, choices);
					break;
				case this.filterTableColumnTypes.date:
				case this.filterTableColumnTypes.dateRange:
					displayNameValue = this.getDisplayValueForDateTime(filterValue);
					break;
				default:
					break;
			}
		}
		return displayNameValue ?? filterValue;
	}

	createCommand(): BrowsePaging {
		return new BrowsePaging({
			currentPage: this._paginator ? this._paginator.pageIndex + 1 : 1,
			filteredColumns: this.currentFilters,
			includedColumns: [],
			pageSize: this._paginator ? this._paginator.pageSize : this.paginatorPageSize,
			sortColumns: [
				{
					direction: this._sort ? this._sort.direction : this.sortActiveDirection,
					name: this._sort ? this._sort.active : this.sortActiveColumn,
				},
			],
		});
	}

	isAllSelected(): boolean {
		const numSelected = this.selector.selected.length;
		const numRows = this.items.filter((row: TableRowItem) => row["select"].value).length;
		return numSelected === numRows;
	}

	selectAll(): void {
		const isAllSelected = this.isAllSelected();
		if (isAllSelected) {
			this.selector.clear();
		} else {
			this.items.filter((row: TableRowItem) => row["select"].value).forEach(row => this.selector.select(row));
		}

		if (!this.tableSettings.showSelectAllButton) this.selectedRows.emit(this.selector.selected);
	}

	selectRow(row: TableRowItem): void {
		this.selector.toggle(row);
		if (!this.tableSettings.showSelectAllButton) this.selectedRows.emit(this.selector.selected);
	}

	riseSelectedRows(): void {
		this.selectedRows.emit(this.selector.selected);
	}
}
