import { Pipe, PipeTransform } from "@angular/core";
import { Observable, catchError, map, of, startWith } from "rxjs";
import { Logger } from "src/app/services/logger.service";

const log = new Logger("AsyncStatusPipe");
export interface ObsAsyncStatusResult<T> {
	loading: boolean;
	value?: T;
	error?: string;
}

@Pipe({
	name: "asyncStatus",
})
export class AsyncStatusPipe implements PipeTransform {
	private readonly defaultError = "Something went wrong";
	transform<T = unknown>(val: Observable<T>): Observable<ObsAsyncStatusResult<T>> {
		return val.pipe(
			map((value: T) => {
				return {
					loading: false,
					value: value,
				} as ObsAsyncStatusResult<T>;
			}),
			startWith({ loading: true } as ObsAsyncStatusResult<T>),
			catchError(error => {
				const errorText = this.getErrorMessage(error);
				return of({
					loading: false,
					error: errorText,
				} as ObsAsyncStatusResult<T>);
			})
		);
	}

	private getErrorMessage(error: unknown): string {
		log.info(error);
		if (typeof error === "string") return error;

		if (error instanceof Error) {
			return error.message;
		}

		return this.defaultError;
	}
}
