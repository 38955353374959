import { formatDate } from "@angular/common";
import { FromToDetermination } from "src/app/@shared/types/from-to-determination";

export interface PatientObservationsStats {
	avgAhi: number;
	avgAi: number;
	avgCai: number;
	avgDeviceUsage: number;
	avgHi: number;
	avgLeakP95Rate: number;
	avgLeakP95RateTitle: string;
	avgLargeLeakDuration: number;
	avgLargeLeakDurationTitle: string;
	avgLeakRate: number;
	avgLeakRateTitle: string;
	avgMaxPressure: number;
	avgMaxPressureTitle: string;
	avgOai: number;
	avgPressure: number;
	avgPressureTitle: string;
	avgPressureP90P95: number;
	avgPressureP90P95Title: string;
	avgTidalVolume50: number;
	avgTidalVolume50Title: string;
	avgTidalVolume95: number;
	avgTidalVolume95Title: string;
	firstInstallDate: Date;
	hasIndicators: boolean;
	hasMoreThanOneProvider: boolean;
	initialAhi: number;
	leakRateUnit: string;
	nbDayAfterInstall: number;
	nbFilteredPatientObservations: number;
	nbMonthAfterInstall: number;
	nbNoTransmissionPatientObservations: number;
	nbTl1PatientObservations: number;
	nbTl2PatientObservations: number;
	nbTl3PatientObservations: number;
	nbTotalPatientObservations: number;
	nbWeekAfterInstall: number;
	nbYearAfterInstall: number;
	pressureUnit: string;
	providerName: string;
}

export interface PatientObservationsLeakStats {
	leakTitle: string;
	leakValue: number;
	leakUnit: string;
}

export class GetPatientObservationsStatsRequest extends FromToDetermination {
	withEmptyObservation!: boolean;
	withNoTransmission!: boolean;

	constructor(init?: Partial<GetPatientObservationsStatsRequest>) {
		super();
		Object.assign(this, init);
	}

	generateUrlQuery(): string {
		return `startDate=${this.formatDate(this.from)}&endDate=${this.formatDate(this.to)}&withEmptyObservation=${
			this.withEmptyObservation
		}&withNoTransmission=${this.withNoTransmission}`;
	}

	protected formatDate(date: Date): string {
		return formatDate(date, "yyyy-MM-dd", "fr");
	}
}
