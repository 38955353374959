import { Component } from "@angular/core";
import { LegalDocumentService } from "src/app/@shared";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
	constructor(public legalDocumentService: LegalDocumentService) {}
}
