import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "src/app/types/app-config";
import { PrescriberMedicalTechnical } from "src/app/models/prescribers/prescriber-medical-technical";
import { PrescribersService } from "src/app/services/prescribers.service";
import { AuthService } from "src/app/services/auth.service";
import { AppConfigService } from "src/app/services/app-config.service";
import { CommunicationComService } from "src/app/services/communication.com.service";
import { RestrictAccessMessages } from "src/app/constants/roles.constant";

@Component({
	selector: "app-prescriber-messaging-no-message",
	templateUrl: "./prescriber-messaging-no-message.component.html",
})
export class PrescriberMessagingNoMessageComponent implements OnInit {
	appConfig!: AppConfig;

	get currentMedicalUserId(): string {
		return this._authService.user.currentMedicalUserId;
	}
	isAuthenticated = false;

	isPrescriber = false;
	isSecretary = false;
	isSuperAdmin = false;

	adminMtaAdministrativeMessage = RestrictAccessMessages.adminMtaAdministrative;

	prescriberMedicalTechnicalAdvisor$!: Observable<PrescriberMedicalTechnical>;

	constructor(
		_appConfigService: AppConfigService,
		private _communicationComService: CommunicationComService,
		private _prescriberService: PrescribersService,
		private _authService: AuthService
	) {
		this.appConfig = _appConfigService.appConfig;
		this.prescriberMedicalTechnicalAdvisor$ = this._prescriberService.getPrescriberMedicalTechnical(
			this.currentMedicalUserId
		);
	}
	ngOnInit(): void {
		this.isPrescriber = this._authService.user.isScopePrescriber;
		this.isSecretary = this._authService.user.isScopeSecretary;
		this.isSuperAdmin = this._authService.user.isSuperAdmin;
	}

	openMessageDialog(): void {
		const data: any = {
			noSettings: true,
		};
		this._communicationComService.openModalContact(data);
	}
}
