import { Component, Inject, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	Observable,
	of,
	startWith,
	Subscription,
	switchMap,
} from "rxjs";
import { FormValidator, HealthCenterSelectorComponent, SnackBarLabel, UserValidator } from "src/app/@shared";
import {
	ActivityAreas,
	FilesCategoryCodes,
	FilesExtensionTypesConstants,
	FilesMimeTypesConstants,
	FilesSizesConstants,
	HealthCenterTypes,
	MessageType,
	Roles,
} from "src/app/constants";
import { SearchPatientsItem, CreateMessageCommand, SearchPatientsResult } from "src/app/models";
import {
	ExchangeService,
	AuthService,
	CommunicationComService,
	FormsService,
	AppConfigService,
	NotificationService,
} from "src/app/services";
import { Logger } from "src/app/services/logger.service";
import { RespiratoryMedicalUsersService } from "src/app/services/respiratory/respiratory-medical-users.service";
import { ScyovaMedicalUsersService } from "src/app/services/scyova/scyova-medical-users.service";
import { AppConfig } from "src/app/types";

const log = new Logger("ModalMessageComponent");

@Component({
	selector: "app-modal-message",
	templateUrl: "./modal-message.component.html",
})
export class ModalMessageComponent implements OnInit, OnDestroy {
	private readonly authService = inject(AuthService);
	private readonly respiratoryMedicalUsersService = inject(RespiratoryMedicalUsersService);
	private readonly scyovaMedicalUsersService = inject(ScyovaMedicalUsersService);

	private _allowedUploadFileMessageTypes = [MessageType.specific, MessageType.general];
	private _filterSearchPatientsItemsSubscription?: Subscription;
	private _sendMessageSubscription!: Subscription;

	readonly availableDocumentMimeTypes: string[] = FilesMimeTypesConstants.availableDocumentMimeTypes;
	readonly availableDocumentExtensionTypes: string[] = FilesExtensionTypesConstants.availableDocumentExtensionTypes;
	readonly maxDocumentSize: number = FilesSizesConstants.maxDocumentSize;
	readonly maxDocumentFilenameLength: number = FilesSizesConstants.maxDocumentFilenameLength;
	readonly maxNbDocuments: number = 3;
	readonly fileUploadCategoryCode: string = FilesCategoryCodes.medicalUserMessage;

	documentFiles: string[] = [];

	@ViewChild(HealthCenterSelectorComponent)
	healthCenterSelector!: HealthCenterSelectorComponent;

	appConfig!: AppConfig;
	canHealthCenterRequest = this.authService.user.isActivityRespiratory;
	canDisplayStepFileUploader = true;
	fileMessageForm!: FormGroup;
	filteredPatients$!: Observable<SearchPatientsItem[]>;
	isAdmin = false;
	isHealthCenterSelectionActive = false;
	isPatientsSearchLoading = true;
	isPatientsSearchOnError = false;
	isScyova = false;
	isSpecificType = true;
	medicalUserId: string = this.authService.user.currentMedicalUserId;
	patientId?: string;
	prescriberId?: string;
	messageForm!: FormGroup;
	nbRemainingFiles = this.maxNbDocuments;
	searchPatientActivityArea: string | null;
	requestSubmitted = false;
	selectedPatient!: SearchPatientsItem;

	constructor(
		_appConfigService: AppConfigService,
		private _exchangeService: ExchangeService,
		private _communicationComService: CommunicationComService,
		private _diag: MatDialogRef<ModalMessageComponent>,
		private _fb: FormBuilder,
		private _formsService: FormsService,
		private _notificationsService: NotificationService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.appConfig = _appConfigService.appConfig;
		this.searchPatientActivityArea = this.authService.user.activityArea;
		this.isAdmin =
			this.authService.user.isAdmin ||
			this.authService.user.isAdministrative ||
			this.authService.user.isCustomerRelationDepartment ||
			this.authService.user.isMedicalTechnicalAdvisor ||
			this.authService.user.isRegionalOrDevelopmentManager;

		this.isScyova = this.authService.user.activityArea === ActivityAreas.scyova;
	}

	ngOnDestroy(): void {
		this._sendMessageSubscription?.unsubscribe();
		this._filterSearchPatientsItemsSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this.createForm();

		this.filteredPatients$ = this.messageForm.controls["patientDisplayName"].valueChanges.pipe(
			startWith(""),
			debounceTime(400),
			distinctUntilChanged(),
			switchMap(value => this.filterSearchPatientsItems(value || ""))
		);

		this.determineValidators();
		this._communicationComService.changeModalContactState(true);
	}

	private createForm(): void {
		this.messageForm = this._fb.group({
			familyName: [],
			givenName: [],
			prescriberId: ["", Validators.required],
			type: [MessageType.specific, Validators.required],
			patientDisplayName: [],
			patientId: ["", Validators.required],
			message: [],
			contactByEmail: [],
			contactByPhone: [],
			email: [{ value: this.authService.user.username, disabled: true }],
			phone: [{ value: "", disabled: true }],
		});
		this.fileMessageForm = this._fb.group({
			documentFiles: [[]],
		});
	}

	private determineValidators(): void {
		if (this.data && this.data.message && this.data.type === "newHealthCenter") {
			this.canDisplayStepFileUploader = false;
			this.messageForm.patchValue({
				message: this.data.message,
			});

			this.messageForm.patchValue({
				type: "healthCenter",
			});

			this.messageForm.controls["patientId"].clearValidators();
			this.messageForm.controls["patientId"].updateValueAndValidity();
			this.isSpecificType = false;
		}

		if (this.data && this.data.message && this.data.type === MessageType.general) {
			this.canDisplayStepFileUploader = false;
			this.messageForm.patchValue({
				message: this.data.message,
			});

			this.messageForm.patchValue({
				type: MessageType.general,
			});

			this.messageForm.controls["patientId"].clearValidators();
			this.messageForm.controls["patientId"].updateValueAndValidity();
			this.isSpecificType = false;
		}

		if (this.isScyova) {
			this.messageForm.patchValue({
				type: MessageType.contactScyova,
			});

			this.messageForm.setValidators(this.atLeastOneCheckboxChecked);

			this.messageForm.controls["contactByEmail"].valueChanges.subscribe(isChecked => {
				this.setScyovaValidators("email", isChecked);
			});

			this.messageForm.controls["contactByPhone"]?.valueChanges.subscribe(isChecked => {
				this.setScyovaValidators("phone", isChecked);
			});

			this.messageForm.controls["patientId"].clearValidators();
			this.messageForm.controls["patientId"].updateValueAndValidity();
		}

		this.messageForm.controls["message"].addValidators(Validators.required);
	}

	close(): void {
		this._diag.close();
		this._communicationComService.changeModalContactState(false);
	}

	changeType(event: any): void {
		this.canDisplayStepFileUploader = this._allowedUploadFileMessageTypes.indexOf(event.target.value) !== -1;
		this.messageForm.controls["message"].reset();

		if (event.target.value === MessageType.healthCenter) {
			this.messageForm.patchValue({
				message: HealthCenterTypes.formatMessage(HealthCenterTypes.messageDialogNewMember, ""),
			});
		}

		if (event.target.value === MessageType.specific) {
			this.isSpecificType = true;
			this.messageForm.controls["patientId"].addValidators(Validators.required);
		} else {
			this.isSpecificType = false;
			this.messageForm.controls["patientDisplayName"].reset();
			this.messageForm.controls["patientId"].clearValidators();
		}

		this.messageForm.controls["patientDisplayName"].updateValueAndValidity();
		this.messageForm.controls["patientId"].updateValueAndValidity();
	}

	onPrescriberIdChanged(e: any) {
		this.messageForm.patchValue({ prescriberId: e });
	}

	onPrescriberPhoneChanged(e: any) {
		this.messageForm.patchValue({ phone: e });
	}

	onIsHealthCenterSelectionActive(e: any) {
		this.isHealthCenterSelectionActive = e;
		this._formsService.setRequiredValidatorWithCondition(this.messageForm, "prescriberId", e);
		if (this.data && this.data.patientId)
			this._filterSearchPatientsItemsSubscription = this.filterSearchPatientsItems(this.data.patientId).subscribe(
				patientItem => this.onPatientSelected(patientItem[0])
			);
	}

	onPatientSelected(selected: SearchPatientsItem): void {
		this.selectedPatient = selected;
		this.messageForm.patchValue({
			patientId: selected.patientId,
			familyName: selected.familyName,
			givenName: selected.givenName,
			patientDisplayName: selected,
		});

		this.healthCenterSelector.selectHealthCenter(selected.prescriberId);

		log.debug(`Patient selected: ${selected.givenName} ${selected.familyName} (${selected.patientId})`);
	}

	displayPatient(item: SearchPatientsItem): string {
		if (this.isAdmin)
			return item
				? `${item.familyName?.toUpperCase() ?? ""} ${item.givenName?.toTitleCase() ?? ""} (${item.patientId})`
				: "";
		return item ? `${item.familyName?.toUpperCase() ?? ""} ${item.givenName?.toTitleCase() ?? ""}` : "";
	}

	private filterSearchPatientsItems(value: string | SearchPatientsItem): Observable<SearchPatientsItem[]> {
		if (typeof value !== "string") {
			return of([]);
		}
		//reset patientId field
		this.messageForm.patchValue({
			patientId: "",
		});

		if (!value || value.length < 3) return of([]);

		this.isPatientsSearchLoading = true;
		this.isPatientsSearchOnError = false;

		const filterValue = value.toLowerCase();
		const medicalUserId: string = this.authService.user.currentMedicalUserId;

		return this.searchPatients$(medicalUserId, filterValue).pipe(
			map(searchResult => searchResult.items),
			catchError(() => {
				this.isPatientsSearchLoading = false;
				this.isPatientsSearchOnError = true;

				log.debug(`An error occurred while searching patients with the following terms: ${filterValue}`);
				return of([]);
			})
		);
	}

	onRemainingNbFilesChange(nbRemainingFiles: number): void {
		this.nbRemainingFiles = nbRemainingFiles;
	}

	submitStepMessage(): void {
		if (!this.messageForm.valid) return;

		this.patientId = this.messageForm.value.patientId;
		this.prescriberId = this.messageForm.value.prescriberId;

		if (this.canDisplayStepFileUploader) return;

		this.sendMessage();
	}

	submitStepFileUploader(): void {
		if (!this.fileMessageForm.valid) return;

		this.sendMessage();
	}

	sendMessage(): void {
		this.requestSubmitted = true;

		const createMessageCommand: CreateMessageCommand = {
			familyName: this.messageForm.value.familyName,
			files: this.fileMessageForm.value.documentFiles ?? [],
			givenName: this.messageForm.value.givenName,
			prescriberId: this.messageForm.value.prescriberId,
			message: this.messageForm.value.message,
			patientId: this.messageForm.value.patientId,
			medicalUserId: this.authService.user.currentMedicalUserId,
			messageType: this.messageForm.value.type,
			treatmentBusinessCode: this.isScyova ? ActivityAreas.parkinson : ActivityAreas.respiratory,
			userRole: Roles.prescriber,
		};

		this._sendMessageSubscription = this._exchangeService
			.sendMessage(createMessageCommand)
			.pipe(
				catchError(() => {
					this._notificationsService.showErrorMessage(SnackBarLabel.errorMessage);
					this.close();
					return of(undefined);
				})
			)
			.subscribe(response => {
				if (response) {
					this._notificationsService.showValidationRequest(SnackBarLabel.successMessage);
					this.messageForm.reset();
				}
				this.close();
			});
	}

	private searchPatients$(medicalUserId: string, filterValue: string): Observable<SearchPatientsResult> {
		if (this.searchPatientActivityArea === ActivityAreas.scyova)
			return this.scyovaMedicalUsersService.searchPatientsByTerms(
				medicalUserId,
				filterValue,
				this.healthCenterSelector.availablePrescriberIds
			);

		return this.respiratoryMedicalUsersService.searchPatientsByTerms(
			medicalUserId,
			filterValue,
			this.healthCenterSelector.availablePrescriberIds
		);
	}

	private setScyovaValidators(inputControlName: string, isChecked: boolean): void {
		const inputControl = this.messageForm.get(inputControlName);
		if (inputControl) {
			const validators = [Validators.required];
			if (inputControlName === "email") {
				validators.push(UserValidator.emailValidator());
			}
			if (inputControlName === "phone") {
				validators.push(FormValidator.phoneValidator());
			}
			if (isChecked) {
				inputControl.setValidators(validators);
				inputControl.enable();
			} else {
				inputControl.clearValidators();
				inputControl.disable();
			}
			inputControl.updateValueAndValidity();
		}
	}

	private atLeastOneCheckboxChecked(form: AbstractControl): Record<string, boolean> | null {
		const contactByEmail = form.get("contactByEmail")?.value;
		const contactByPhone = form.get("contactByPhone")?.value;

		if (!contactByEmail && !contactByPhone) {
			return { atLeastOneRequired: true };
		}

		return null;
	}

	updateScyovaMessageForm(): void {
		let message = "";

		if (this.messageForm.value.contactByEmail)
			message += `Je souhaite être contacté par mail à l'adresse : ${this.messageForm.value.email}.\n`;

		if (this.messageForm.value.contactByPhone)
			message += `Je souhaite être contacté par téléphone au numéro : ${this.messageForm.value.phone}.\n`;

		this.messageForm.patchValue({
			message: message,
		});
	}
}
