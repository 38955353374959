import { Injectable } from "@angular/core";
import { LocalStorageService } from "ngx-webstorage";
import { Observable } from "rxjs";
import { CacheKeys, CacheKeysPrefix } from "src/app/constants";
import { TransformDate } from "src/app/decorators";
import { UtilsService } from "./utils.service";

@Injectable({
	providedIn: "root",
})
export class StorageService {
	constructor(
		private _localStorageService: LocalStorageService,
		private _utilsService: UtilsService
	) {}

	hasKey(key: string): boolean {
		return this._localStorageService.retrieve(key) !== null;
	}

	saveCacheValue(key: string, value: any): void {
		this._localStorageService.store(key, value);
	}

	saveCache<TItem>(key: string, value: TItem): void {
		this._localStorageService.store(key, value);
	}

	retrieveCacheValue(key: string): any {
		return this._localStorageService.retrieve(key);
	}

	retrieveCacheAsBoolean(key: string): boolean {
		const value = this._localStorageService.retrieve(key);
		if (!value) return false;
		return value;
	}

	@TransformDate
	retrieveCache<R, T extends new (...constructorArgs: any[]) => R>(constructor: T, key: string): R | null {
		const value = localStorage.getItem(key);
		if (!value) return null;

		return new constructor(JSON.parse(value));
	}

	removeCacheValue(key: string): void {
		this._localStorageService.clear(key);
	}

	clearCachesExceptKey(key: string): void {
		const exceptedKey = this._localStorageService.retrieve(key);
		this._localStorageService.clear();
		this._localStorageService.store(key, exceptedKey);
	}

	clearCachesExceptGroup(groups: string[]): void {
		let exceptedCacheItems: { key: string; value: string | null }[] = [];
		for (const group of groups) {
			exceptedCacheItems = [...exceptedCacheItems, ...this.getMatchingKeyPrefixCacheItems(group)];
		}
		this._localStorageService.clear();

		if (exceptedCacheItems.length == 0) return;

		exceptedCacheItems.map(item => this._localStorageService.store(item.key, item.value));
	}

	getObservableCacheItem(key: string): Observable<any> {
		return this._localStorageService.observe(key);
	}

	getCustomKeyWithSuffix(key: string, suffix: string): string {
		return `${key}__${suffix}`;
	}

	getMatchingKeyPrefixCacheItems(keyPrefix: string): {
		key: string;
		value: string | null;
	}[] {
		const keys = Object.keys(localStorage);
		const matchingKeys = keys.filter(key =>
			key.toLowerCase().startsWith(CacheKeysPrefix + keyPrefix.toLowerCase())
		);
		const matchingData = matchingKeys.map(key => {
			key = key.replace(CacheKeysPrefix, "");
			return { key, value: this.retrieveCacheValue(key) };
		});
		return matchingData;
	}

	getScopePrescriberIds(medicalUserId: string): string[] {
		const cacheKey = this.getCustomKeyWithSuffix(CacheKeys.scopePrescriberIds, medicalUserId);
		const valueAsString = this.retrieveCacheValue(cacheKey);

		if (!valueAsString) return [];

		const values: string[] = JSON.parse(valueAsString);
		return this._utilsService.removeDuplicate<string>(values);
	}

	getActivityArea(medicalUserId: string): string | null {
		const cacheKey = this.getCustomKeyWithSuffix(CacheKeys.activityArea, medicalUserId);
		const valueAsString = this.retrieveCacheValue(cacheKey);

		if (!valueAsString) return null;

		const value: string = JSON.parse(valueAsString);
		return value;
	}

	getActivityAreaSettings(medicalUserId: string): boolean | null {
		const cacheKey = this.getCustomKeyWithSuffix(CacheKeys.hasMultipleActivityArea, medicalUserId);
		const valueAsString = this.retrieveCacheValue(cacheKey);

		if (!valueAsString) return null;

		const value: boolean = JSON.parse(valueAsString);
		return value;
	}

	setScopePrescriberIds(medicalUserId: string, prescriberIds: string[]): void {
		const cacheKey = this.getCustomKeyWithSuffix(CacheKeys.scopePrescriberIds, medicalUserId);
		this.saveCacheValue(cacheKey, JSON.stringify(prescriberIds));
	}

	setActivityArea(medicalUserId: string, activityArea: string): void {
		const cacheKey = this.getCustomKeyWithSuffix(CacheKeys.activityArea, medicalUserId);
		this.saveCacheValue(cacheKey, JSON.stringify(activityArea));
	}

	setActivityAreaSettings(medicalUserId: string, hasMultipleActivityArea: boolean): void {
		const cacheKey = this.getCustomKeyWithSuffix(CacheKeys.hasMultipleActivityArea, medicalUserId);
		this.saveCacheValue(cacheKey, JSON.stringify(hasMultipleActivityArea));
	}
}
