import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { BrowsePaging, Pager } from "../../types";
import { TableView } from "../../types";
import { TableCommunicationService } from "../../services/table.communication.service";
import { Logger, StorageService, UtilsService } from "src/app/services";
import { CacheKeysPrefix, SubjectEvents } from "src/app/constants";
import { Observable, Subject, catchError, map, of, switchMap, tap } from "rxjs";

const log = new Logger("TableMobileComponent");

export interface BrowsePagingParametersMobile<T> {
	sortColumns?: [
		{
			direction: "desc" | "asc";
			name: string;
		},
	];
	customParams: T;
}

export type GetItemsParamsMobile = any | "command"[];

@Component({
	selector: "app-table-mobile",
	templateUrl: "./table-mobile.component.html",
})
export class TableMobileComponent<TItem, TCommand extends BrowsePaging, TResult extends Pager<TItem>>
	implements OnInit
{
	@Input() defaultTableView!: TableView;
	@Input() cacheKey!: string;
	@Input() cacheKeyMobile!: string;
	@Input() browsePagingParameters?: BrowsePagingParametersMobile<any>;
	@Input() browseCommandType!: new (init: Partial<TCommand>) => TCommand;
	@Input() getItems!: (...params: any) => Observable<TResult>;
	@Input() getItemsParams: GetItemsParamsMobile = ["command"];
	@Input() filterDescriptionText: string | null = null;
	@Input() tableBordered = true;
	@Output() initialized = new EventEmitter<boolean>();

	@ContentChild("row", { static: false }) rowTemplateRef!: TemplateRef<any>;

	tableViewMobile: TableView;
	noData = false;
	accordeonRowClickedIds: Record<number, number | null> = {};
	isLoadMoreButtonDisabled = true;
	endOfMessages = false;

	private itemPageIncrease = 5;
	private isComponentLoading = true;
	private isComponentOnError = false;

	private itemPage: { pageSize: number; currentPage: number } = {
		currentPage: 1,
		pageSize: this.itemPageIncrease,
	};

	constructor(
		private _tableCommunicationService: TableCommunicationService,
		private _storageService: StorageService,
		private _utilsService: UtilsService
	) {
		this.tableViewMobile = this.defaultTableView;
	}

	private computeQueryParams(command: TCommand) {
		const computed = [];
		for (const param of this.getItemsParams) {
			computed.push(param === "command" ? command : param);
		}
		return computed;
	}

	private _onChangeSubject$: Subject<string> = new Subject<string>();
	getItems$: Observable<TItem[] | undefined> = this._onChangeSubject$.asObservable().pipe(
		tap(eventName => {
			this.isLoadMoreButtonDisabled = true;
			this.isComponentOnError = false;

			if (eventName !== SubjectEvents.onInit) return;

			this.endOfMessages = false;
			this.itemPage.currentPage = 1;
			this.noData = false;
		}),
		switchMap(_ => {
			this.tableViewMobile.compute();

			let command = new this.browseCommandType({});
			command.load(this.tableViewMobile);

			const sortColumns = this.browsePagingParameters?.sortColumns ?? [{ direction: "desc", name: "createdOn" }];

			command = new this.browseCommandType({
				...command,
				...this.browsePagingParameters?.customParams,
				currentPage: this.itemPage.currentPage,
				filteredColumns: command.filteredColumns,
				includedColumns: [],
				pageSize: this.itemPageIncrease,
				sortColumns,
			});

			return this.getItems(...this.computeQueryParams(command)).pipe(
				map(response => {
					if (!response) return [];

					if (response.totalPages === response.currentPage) this.endOfMessages = true;

					if (response.items.length == 0) {
						this.noData = true;
						this.endOfMessages = true;
					}

					return response.items;
				}),
				catchError((err: any) => {
					this.isComponentOnError = true;
					log.error(err);
					return of([]);
				})
			);
		}),
		tap(_ => {
			this.isLoadMoreButtonDisabled = false;
			this.isComponentLoading = false;
			this._storageService.saveCache(this.cacheKeyMobile, this.tableViewMobile);
		})
	);

	ngOnInit(): void {
		this.tableViewMobile =
			this._storageService.retrieveCache(TableView, CacheKeysPrefix + this.cacheKeyMobile) ??
			this.defaultTableView;

		if (this.tableViewMobile.version !== this.defaultTableView.version)
			this.tableViewMobile = this.defaultTableView;

		this._tableCommunicationService.onChangeFiltersSubject$.subscribe((isMobile: boolean) => {
			if (!isMobile) {
				const itemCache = this._storageService.retrieveCacheValue(this.cacheKey);

				if (itemCache) {
					this.tableViewMobile.filters = this._utilsService.cloneDeep(itemCache.filters);
					this.tableViewMobile.filters.items.forEach((item: any) => {
						if (item.columnName) {
							const columnNameMobile = item.columnName + "Mobile";
							if (this.tableViewMobile.displayColumnNames.find(e => e === columnNameMobile)) {
								item.columnName = columnNameMobile;
							} else {
								this.tableViewMobile.removeFilter(item.columnName);
							}
						}
					});
					this.reloadTable(true);
				}
			}
		});

		this.tableViewMobile.compute();
		setTimeout(() => {
			this._onChangeSubject$.next(SubjectEvents.onInit);
			this.initialized.emit(true);
		}, 0);
	}

	trackById(_: number, item: any): number {
		return item.id;
	}

	reloadTable(_: boolean) {
		this.accordeonRowClickedIds = {};
		this._onChangeSubject$.next(SubjectEvents.onInit);
	}

	loadMoreMessages() {
		this.itemPageIncrease += 5;
		this.reloadTable(true);
	}

	activeAccordeon(row: number): void {
		const checkbox: HTMLInputElement | null = document.getElementById(`accordeon-tab-${row}`) as HTMLInputElement;

		if (this.accordeonRowClickedIds[row] == row) {
			this.accordeonRowClickedIds[row] = null;

			if (checkbox) checkbox.checked = false;
			return;
		}

		if (checkbox) checkbox.checked = true;
		this.accordeonRowClickedIds[row] = row;
	}
}
