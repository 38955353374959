<div
	class="modal modal-signin modal-message position-fixed d-block bg-modal-search"
	tabindex="-1"
	role="dialog"
	id="modalSignin">
	<div class="modal-dialog preferences position-relative mb-5 pb-5 mb-sm-0 pb-sm-0" role="document">
		<!-- <span class="icon-logo-{{ appConfig.appCompanyName }} fs-1 d-block text-center mt-2 w-100 pb-3 mb-3"
			><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
			><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
			><span class="path9"></span><span class="path10"></span><span class="path11"></span
			><span class="path12"></span><span class="path13"></span><span class="path14"></span
			><span class="path15"></span><span class="path16"></span><span class="path17"></span
			><span class="path18"></span><span class="path19"></span><span class="path20"></span
			><span class="path21"></span><span class="path22"></span><span class="path23"></span
			><span class="path24"></span><span class="path25"></span><span class="path26"></span
			><span class="path27"></span><span class="path28"></span><span class="path29"></span
			><span class="path30"></span><span class="path31"></span><span class="path32"></span>
		</span> -->
		<!-- WP-2138 : je conserve en commentaire le logo et le nom de la Société en cas d'exigence ultérieure -->
		<div class="d-flex w-full justify-content-between align-items-center my-0 ms-4 py-2">
			<h3 class="text-medium fs-22 text-start text-gray mb-0">
				Nous Contacter
				<!-- <span class="text-capitalize">{{ appConfig.appCompanyName }}</span> -->
			</h3>
			<span class="text-ultra-light me-4 fs-initial">Tous les champs sont requis</span>
		</div>
		<div class="modal-content border-0 rounded shadow position-relative">
			<div class="modal-body p-4">
				<mat-stepper linear #stepper>
					<mat-step [stepControl]="messageForm" label="Sujet">
						<form
							class="z-index-2 position-relative"
							[formGroup]="messageForm"
							(ngSubmit)="submitStepMessage()">
							@if (isScyova) {
								<div class="row">
									<p class="fs-5">Je souhaite être contacté</p>
									<div class="row mb-4 fs-5-6 gap-2 ms-3">
										<div
											class="col-12 color-primary d-flex mb-2 mb-sm-0 gap-2 flex-nowrap align-items-center">
											<div>
												<input
													class="custom-checkbox"
													type="checkbox"
													name="contactByEmail"
													formControlName="contactByEmail"
													id="contactByEmail"
													(change)="updateScyovaMessageForm()" />
											</div>
											<div class="col-4">
												<label for="contactByEmail" class="custom-checkbox">
													Par mail à l'adresse
												</label>
											</div>
											<div class="col-6">
												<input
													matInput
													class="form-control border-2 form-care w-auto"
													type="text"
													value="test.scyova@istep.fr"
													formControlName="email"
													(keyup)="updateScyovaMessageForm()" />
											</div>
										</div>
										<div
											class="col-12 color-primary d-flex mb-2 mb-sm-0 gap-2 flex-nowrap align-items-center">
											<div>
												<input
													class="custom-checkbox"
													type="checkbox"
													name="contactByPhone"
													formControlName="contactByPhone"
													id="contactByPhone"
													(change)="updateScyovaMessageForm()" />
											</div>
											<div class="col-4">
												<label for="contactByPhone" class="custom-checkbox">
													Par téléphone au numéro
												</label>
											</div>
											<div class="col-6">
												<input
													matInput
													class="form-control border-2 form-care w-auto"
													type="text"
													mask="00 00 00 00 00"
													formControlName="phone"
													(keyup)="updateScyovaMessageForm()" />
											</div>
										</div>
									</div>
									<p class="fs-5-6">
										par un collaborateur {{ appConfig.appCompanyName | uppercase }} dès le prochain
										jour ouvré.
									</p>
									<div class="md-6 row mb-4 fs-5-6">
										<p>
											En cas d'urgence, nous vous conseillons d'appeler directement le numéro
											Cristal dédié au programme d'apprentissage PARKOUR SCYOVA® :
											<span class="text-bold">09 70 80 89 38</span> (appel gratuit).
										</p>
									</div>
									<input type="hidden" formControlName="prescriberId" />
									<div class="md-6">
										<div
											class="form-group color-primary mb-4 fs-5-6"
											[hidden]="!isHealthCenterSelectionActive">
											<label for="message" class="text-medium mb-1">Lieu d'exercice</label>
											(requis)
											<p>À quel établissement votre demande doit-elle être rattachée ?</p>
											<app-health-center-selector
												(healthCenterSelectorActivation)="
													onIsHealthCenterSelectionActive($event)
												"
												(prescriberIdChange)="onPrescriberIdChanged($event)"
												(prescriberPhoneChange)="
													onPrescriberPhoneChanged($event)
												"></app-health-center-selector>
										</div>
									</div>
								</div>
							} @else {
								<p class="text-primary fs-5-closed text-medium m-0 pb-2">Sujet</p>
								<p class="fs-5-5 text-ultra-light">
									Vous souhaitez nous contacter, merci de choisir le sujet de votre demande
								</p>
								<div class="row">
									<div class="md-6 row mb-4 fs-5-5">
										<div class="col-12 col-md-6 color-primary d-flex mb-2 mb-sm-3">
											<input
												id="specific"
												class="custom-radio"
												type="radio"
												value="specific"
												name="type"
												formControlName="type"
												(change)="changeType($event)" />
											<label for="specific" class="custom-radio lh-22"
												>En lien avec un patient en particulier</label
											>
										</div>
										<div
											*ngIf="canHealthCenterRequest"
											class="col-12 col-md-6 color-primary d-flex">
											<input
												id="healthCenter"
												class="custom-radio"
												type="radio"
												value="healthCenter"
												name="type"
												formControlName="type"
												(change)="changeType($event)" />
											<label for="healthCenter" class="custom-radio"
												>En lien avec la gestion d'un établissement</label
											>
										</div>
										<div class="col-12 col-md-6 color-primary d-flex">
											<input
												id="general"
												class="custom-radio"
												type="radio"
												value="general"
												name="type"
												formControlName="type"
												(change)="changeType($event)" />
											<label for="general" class="custom-radio">Autre demande</label>
										</div>
									</div>
									<div *ngIf="isSpecificType" class="md-6 mb-4">
										<div class="form-group color-primary mb-2">
											<label for="patientId" class="text-medium mb-1 fs-5-closed"
												>NOM Prénom du patient</label
											>
											(requis)
											<input
												matInput
												[matAutocomplete]="auto"
												placeholder="Chercher un patient"
												formControlName="patientDisplayName"
												class="form-control border-2 rounded-pill text-ultra-light fs-5-closed"
												type="text"
												aria-label="Search"
												aria-describedby="basic-addon2" />
											<mat-autocomplete
												#auto="matAutocomplete"
												[displayWith]="displayPatient"
												(optionSelected)="onPatientSelected($event.option.value)"
												class="custom-autocomplete">
												<mat-option
													*ngFor="let filteredPatient of filteredPatients$ | async"
													[value]="filteredPatient"
													class="text-light">
													{{ filteredPatient.givenName | titlecase }}
													{{ filteredPatient.familyName | uppercase }}
													<ng-container *ngIf="isAdmin"
														>({{ filteredPatient.patientId }})</ng-container
													>
												</mat-option>
											</mat-autocomplete>
										</div>
									</div>
									<div class="md-6 mb-4">
										<div class="form-group color-primary mb-2">
											<label for="message" class="text-medium mb-1 fs-5-closed">Message</label>
											(requis)
											<textarea
												id="message"
												formControlName="message"
												class="form-control position-relative z-index-2 bg-none rounded border-2 fs-5-closed text-ultra-light mh-220"
												placeholder="Bonjour, ..."></textarea>
										</div>
									</div>
									<input type="hidden" formControlName="prescriberId" />
									<div class="md-6">
										<div
											class="form-group color-primary mb-4"
											[hidden]="!isHealthCenterSelectionActive">
											<label for="message" class="text-medium mb-1 fs-5-closed"
												>Lieu d'exercice</label
											>
											(requis)
											<p class="fs-5-5 text-ultra-light">
												À quel établissement votre demande doit-elle être rattachée ?
											</p>
											<app-health-center-selector
												(healthCenterSelectorActivation)="
													onIsHealthCenterSelectionActive($event)
												"
												(prescriberIdChange)="onPrescriberIdChanged($event)"
												(prescriberPhoneChange)="
													onPrescriberPhoneChanged($event)
												"></app-health-center-selector>
										</div>
									</div>
								</div>
							}
							<div class="form-group text-center mt-3">
								<button
									*ngIf="canDisplayStepFileUploader"
									matStepperNext
									type="submit"
									class="btn btn-primary btn-hover bg-color-primary text-white text-uppercase fs-5 py-3 px-5"
									[disabled]="!messageForm.valid">
									<span class="text-medium">Suivant</span>
								</button>
								<button
									*ngIf="!canDisplayStepFileUploader"
									type="submit"
									class="btn btn-primary btn-hover bg-color-primary text-white text-uppercase fs-5 py-3 px-5"
									[disabled]="!messageForm.valid">
									<span class="text-medium">Envoyer</span>
								</button>
							</div>
						</form>
					</mat-step>
					<mat-step [stepControl]="fileMessageForm" label="Pièces jointes">
						<div class="row">
							<form
								class="z-index-2 position-relative"
								[formGroup]="fileMessageForm"
								(ngSubmit)="submitStepFileUploader()">
								<div class="row justify-content-between align-items-center">
									<p class="text-medium mb-1 fs-5-closed col-6 mb-2">Pièces jointes</p>
									<p class="text-end fs-initial text-ultra-light col-6 m-0">Poids max. : 15Mo</p>
								</div>
								@switch (nbRemainingFiles) {
									@case (0) {
										<span class="fs-5-5 text-ultra-light mb-3">
											Vous avez attaché
											<span class="text-medium">le maximum de pièces jointes</span>
											à votre message.
										</span>
									}
									@case (1) {
										<span class="fs-5-5 text-ultra-light mb-3">
											Si vous le souhaitez, vous pouvez ajouter jusqu'à
											<span class="text-medium">1 pièce jointe</span>
											à votre message.
										</span>
									}
									@default {
										<span class="fs-5-5 text-ultra-light mb-3">
											Si vous le souhaitez, vous pouvez ajouter jusqu'à
											<span class="text-medium">{{ nbRemainingFiles }} pièces jointes</span>
											à votre message.
										</span>
									}
								}

								<div class="md-6 row mb-4 fs-5-5" *ngIf="prescriberId">
									<app-multi-file-uploader
										formControlName="documentFiles"
										id="documentFiles"
										(remainingNbFilesChange)="onRemainingNbFilesChange($event)"
										[availableExtensionTypes]="availableDocumentExtensionTypes"
										[availableMimeTypes]="availableDocumentMimeTypes"
										[fileUploadCategoryCode]="fileUploadCategoryCode"
										[maxFilenameLength]="maxDocumentFilenameLength"
										[maxSize]="maxDocumentSize"
										[medicalUserId]="medicalUserId"
										[prescriberId]="prescriberId"
										[patientId]="patientId"
										[nbMaxFiles]="maxNbDocuments"></app-multi-file-uploader>
									<div class="col-12">
										<div
											class="alert alert-danger"
											*ngIf="
												fileMessageForm.controls['documentFiles'].errors &&
												fileMessageForm.controls['documentFiles'].invalid &&
												(fileMessageForm.controls['documentFiles'].dirty ||
													fileMessageForm.controls['documentFiles'].touched)
											">
											@let errors = fileMessageForm.controls["documentFiles"].errors;
											<div class="text-medium text-gray fs-5-5" *ngIf="errors['fileMimeType']">
												Type de fichier autorisé: *.pdf, *.png, *.jpg, *.jpeg, *.doc, *.docx
											</div>
											<div class="text-medium text-gray fs-5-5" *ngIf="errors['filenameLength']">
												Taille maximale du nom du fichier 100 caractères
											</div>
											<div class="text-medium text-gray fs-5-5" *ngIf="errors['fileSize']">
												Taille maximale 15MO
											</div>
											<div class="text-medium text-gray fs-5-5" *ngIf="errors['serverError']">
												Une erreur est survenue lors de l'upload du fichier
											</div>
										</div>
									</div>
								</div>
								<div class="form-group d-flex flex justify-content-between text-center mt-3">
									<button
										type="button"
										class="btn btn-primary text-uppercase fs-5 py-3 px-5"
										matStepperPrevious>
										Retour
									</button>
									<button
										type="submit"
										class="btn btn-primary btn-hover bg-color-primary text-white text-uppercase fs-5 py-3 px-5"
										[disabled]="!fileMessageForm.valid || requestSubmitted">
										<span class="text-medium">Envoyer</span>
									</button>
								</div>
							</form>
						</div>
					</mat-step>
				</mat-stepper>
			</div>
			<div class="close-window position-absolute z-index-4">
				<button
					type="button"
					title="Fermer la modale"
					mat-dialog-close
					class="unstyled color-primary shadow bg-white btn p-1">
					<i class="icon-closed color-primary fs-26"></i>
				</button>
			</div>
			<div class="overflow-hidden position-absolute bottom-0 w-100 z-index-1 d-flex flex-row-reverse">
				<img
					src="../../../../../assets/img/bg-message.svg"
					class="img-fluid"
					style="width: 334px"
					alt="Données indisponibles" />
			</div>
		</div>
	</div>
</div>
