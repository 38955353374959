import { BrowsePaging } from "src/app/@shared/types/browse-paging";

export class BrowseHealthCenterRequest extends BrowsePaging {
	categories!: string[];

	constructor(init?: Partial<BrowseHealthCenterRequest>) {
		super();
		Object.assign(this, init);
	}
}
