import { BrowsePaging } from "src/app/@shared/types/browse-paging";

export class BrowseMessagesCommand extends BrowsePaging {
	medicalUserId!: string;
	medicalTechnicalAdvisorId!: string;
	treatmentBusinessCode!: string;

	constructor(init?: Partial<BrowseMessagesCommand>) {
		super();
		Object.assign(this, init);
	}

	override generateUrlQuery(): string {
		let query: string = super.generateUrlQuery();

		query += `&medicalUserId=${this.medicalUserId ? this.medicalUserId : ""}`;
		query += `&medicalTechnicalAdvisorId=${this.medicalTechnicalAdvisorId ? this.medicalTechnicalAdvisorId : ""}`;
		query += `&treatmentBusinessCode=${this.treatmentBusinessCode ? this.treatmentBusinessCode : ""}`;

		return query;
	}
}
