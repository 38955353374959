<button
	type="button"
	[title]="title"
	(click)="downloadFileById()"
	class="btn pin-circle text-decoration-none m-1 {{ btnCssClass }}"
	target="_blank">
	<i
		class=" {{
			documentType === 'Relevé DM complet' ? 'dm-complet icon-fiche-obs' : 'cr-final-respiratoire icon-ordonance'
		}}
			position-absolute top-50 start-50 translate-middle"></i>
</button>
