import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, startWith, switchMap, tap } from "rxjs";
import { SharedModule } from "../../@shared/shared.module";
import { Companies, SubjectEvents } from "../../constants";
import { PrescriberPatientsSelection } from "../../models";
import { AppConfigService, AuthService, MedicalUsersService } from "../../services";
import { AppConfig, User } from "../../types";

@Component({
	imports: [SharedModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-activity-area-selector",
	standalone: true,
	templateUrl: "activity-area-selector.component.html",
})
export class ActivityAreaSelectorComponent {
	private readonly router = inject(Router);
	private readonly authService = inject(AuthService);
	private readonly medicalUsersService = inject(MedicalUsersService);
	private readonly appConfigService = inject(AppConfigService);

	private _medicalUserCardSubject = new Subject<string>();

	user: User = this.authService.user;
	appConfig: AppConfig = this.appConfigService.appConfig;
	showScyova = this.appConfig.appCompanyName.toLowerCase() === Companies.elivie.toLowerCase();

	medicalUserCard$: Observable<PrescriberPatientsSelection> = this._medicalUserCardSubject.asObservable().pipe(
		startWith(SubjectEvents.onInit),
		switchMap(() => {
			return this.medicalUsersService.getPrescriberCards(this.authService.user.currentMedicalUserId);
		}),
		tap(e => this.tryRedirectToActivityArea(e))
	);

	tryRedirectToActivityArea(medicalUserCard: PrescriberPatientsSelection): void {
		const hasMultipleActivityAreas = medicalUserCard.hasMultipleActivityAreas;
		this.authService.changeActivityAreaSettings(hasMultipleActivityAreas);

		if (hasMultipleActivityAreas && medicalUserCard.hasRespiratoryActivityArea && !this.showScyova)
			return this.goToRespiratoryActivityArea();

		if (hasMultipleActivityAreas) return;

		if (medicalUserCard.hasRespiratoryActivityArea || this.user.isScopeSecretary)
			return this.goToRespiratoryActivityArea();

		if (medicalUserCard.hasScyovaActivityArea) return this.goToScyovaActivityArea();
	}

	goToRespiratoryActivityArea(): void {
		this.authService.changeUserActivityAreaToRespiratory();
		this.router.navigate(["/prescriber/dashboard"]);
	}

	goToScyovaActivityArea(): void {
		this.authService.changeUserActivityAreaToScyova();
		this.router.navigate(["/scyova/patients"]);
	}
}
