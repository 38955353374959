<div class="modal position-fixed d-block bg-modal-light" tabindex="-1" role="dialog">
	<div class="modal-dialog preferences position-relative" role="document">
		<h3 class="text-bold fs-4 my-2 ms-3 ms-lg-4 py-2 text-start text-dark">{{ modalTitle }}</h3>
		<div class="modal-content rounded shadow position-relative border-0">
			<div class="modal-body fs-5 p-3 p-lg-4 position-relative z-index-2 overflow-hidden">
				<div style="white-space: pre-line">
					<container-element [ngSwitch]="modalMessageType">
						<div *ngSwitchCase="'string'">
							{{ dataToDisplay }}
						</div>
						<div *ngSwitchCase="modalShowMessageType.messageMode">
							<div>
								<container-element [ngSwitch]="dataToDisplay.details.type">
									<span>
										<u *ngSwitchCase="messageType.general">Message général :</u>
										<u *ngSwitchCase="messageType.healthCenter"
											>Message à propos d’un établissement :</u
										>
										<u *ngSwitchCase="messageType.patientCareForm"
											>Message lié à une demande de prise en charge :</u
										>
										<u *ngSwitchCase="messageType.specific">Message à propos d’un patient :</u>
										<u *ngSwitchDefault>Message autre :</u>
									</span>
									<ul>
										<li *ngIf="dataToDisplay.details.prescriberName">
											Demande effectuée par le {{ dataToDisplay.details.prescriberName }}
										</li>
										<li *ngIf="dataToDisplay.details.agency">
											{{ dataToDisplay.details.agency }}
										</li>
										<li *ngIf="dataToDisplay.details.patientName">
											Patient : {{ dataToDisplay.details.patientName }}
										</li>
									</ul>
								</container-element>
							</div>
							<mat-divider></mat-divider>
							<br />
							<div>
								<span><u>Message du prescripteur :</u></span>
								<p>{{ dataToDisplay.content }}</p>
							</div>
						</div>
						<div *ngSwitchCase="'treatment-tag-array'" class="filters-added w-100 pb-2 d-flex flex-wrap">
							<ng-container class="" *ngFor="let treatmentTag of dataToDisplay">
								<span
									class="rounded-pill p-1 px-2 me-2 mb-2 d-flex text-white"
									style="padding-left: 10px; white-space: nowrap"
									[ngClass]="treatmentTag.color">
									{{ treatmentTag.name }}
								</span>
								<br />
							</ng-container>
						</div>
						<div *ngSwitchCase="'string-array'">
							<ng-container *ngFor="let item of dataToDisplay">
								{{ item }}
							</ng-container>
						</div>
					</container-element>
				</div>
				<div class="position-absolute rounded overflow-hidden end-0 bottom-0 z-index--10">
					<img
						src="assets/img/bg-partage-confirmation.png"
						alt="partage confirmation"
						class="d-none d-md-block opacity-50" />
				</div>
			</div>
			<div class="close-window position-absolute z-index-4">
				<button
					type="button"
					title="Fermer la modale"
					mat-dialog-close
					class="unstyled shadow bg-white color-primary btn p-1">
					<i class="icon-closed color-primary fs-26"></i>
				</button>
			</div>
		</div>
	</div>
</div>
