import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	constructor(private _matSnackBar: MatSnackBar) {}

	showErrorMessage(message: string, action?: string) {
		this._matSnackBar.open(message, action, {
			duration: 5000,
			panelClass: "snack-error",
		});
	}

	showValidationRequest(message: string, action?: string, duration = 5000) {
		this._matSnackBar.open(message, action, {
			duration: duration,
			panelClass: "snack-validation",
		});
	}
}
