<div *ngIf="item" class="position-relative">
	<div *ngIf="item.usePictogram" class="d-flex align-items-center ms-1 ps-2">
		<i class="fs-4" [class]="[item.cssPictogram, item.cssColor]"></i>
		<div class="d-flex">
			<span [class]="{ 'text-capitalize': item.forceCasing }">{{ item.label }}</span>
		</div>
	</div>
	<div *ngIf="!item.usePictogram" class="d-flex" [class]="[item.cssColor]">
		<span [class]="{ 'text-capitalize': item.forceCasing }">{{ item.label }}</span>
	</div>
</div>
