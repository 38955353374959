<ng-container *ngIf="prescriberSelection$ | async as prescriberSelection">
	<div class="row" [formGroup]="formGroup" [hidden]="!isActive">
		<div class="mb-3 d-flex justify-content-center">
			<select
				class="form-select form-control border-2 text-ultra-light fs-5-closed"
				title="Choix du prescripteurs"
				(change)="onPrescriberSelectionChanged()"
				[formControl]="prescriberControl"
				[hidden]="isFromPatientCareForm && prescriberSelection.uniquePrescribers.length <= 1">
				<option value="" disabled>Voir les prescripteurs</option>
				<option
					*ngFor="
						let prescriber of prescriberSelection.uniquePrescribers | orderBy: 'fullName';
						track: prescriber
					"
					[ngValue]="prescriber.fullName">
					{{ prescriber.fullName }}
				</option>
			</select>
		</div>
		@if (isFromPatientCareForm && isHealthCenterSelectionActive) {
			<div class="mb-3 d-flex justify-content-center">
				<select
					title="Choix de l'établissement"
					class="form-select form-control border-2 text-ultra-light fs-5-closed"
					(change)="onHealthCenterSelectionChanged($event)"
					[formControl]="healthCenterIdControl">
					<option value="" disabled>Voir les établissements</option>
					<option
						*ngFor="
							let healthCenter of getHealthCentersForSelectedPrescriber() | orderBy: 'healthCenterName';
							track: healthCenter
						"
						[ngValue]="healthCenter.healthCenterId">
						{{ healthCenter.healthCenterName | titlecase }}
					</option>
				</select>
			</div>
		}
	</div>
</ng-container>
