import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
	ConfirmationModalDialogInputData,
	ConfirmationModalDialogOutputData,
} from "src/app/@shared/types/confirmation-modal-interface";
import { TableViewComponent } from "../../table/table-view/table-view.component";

@Component({
	selector: "app-modal-confirmation-dialog",
	templateUrl: "./modal-confirmation-dialog.component.html",
	styleUrls: ["./modal-confirmation-dialog.component.scss"],
})
export class ModalConfirmationDialogComponent implements OnInit {
	form!: FormGroup;

	confirmationModalDialogOutputData: ConfirmationModalDialogOutputData = {
		confirmationStatus: false,
		modalContext: this.dialogData.modalContext,
	};

	@ViewChild(TableViewComponent) tableView!: TableViewComponent;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public dialogData: ConfirmationModalDialogInputData,
		private _dialogRef: MatDialogRef<ModalConfirmationDialogComponent>,
		private _fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.createForm();
	}

	createForm() {
		this.form = this._fb.group({
			title: [this.dialogData.title, [Validators.required]],
			message: [this.dialogData.message, [Validators.required]],
			confirmText: [this.dialogData.confirmText],
			cancelText: [this.dialogData.cancelText],
		});
	}

	submit(event: any) {
		switch (event.submitter.id) {
			case "confirmButton":
				this.confirmationModalDialogOutputData.confirmationStatus = true;
				this._dialogRef.close({ data: this.confirmationModalDialogOutputData });
				break;
			case "cancelButton":
				this.confirmationModalDialogOutputData.confirmationStatus = false;
				this._dialogRef.close({ data: this.confirmationModalDialogOutputData });
				break;
		}
	}
}
