import { Pipe, PipeTransform } from "@angular/core";
import { Treatments } from "src/app/constants/treatments.constant";

@Pipe({
	name: "labelArray",
})
export class LabelArrayPipe implements PipeTransform {
	constructor() {}

	transform(values: string[], type: string): string[] {
		return values.map((v: string) => this.getLabel(v, type));
	}

	private getLabel(value: any, type: string): string {
		if (!value || value === undefined || type === undefined) return "";

		switch (type) {
			case "treatmentCode":
				return this.getTreatmentLabelByCode(value);
			default:
				return "";
		}
	}

	private getTreatmentLabelByCode(code: any): string {
		const label = Treatments.PrescriptionCodeMappings.get(code);

		if (label === undefined) return "";

		return label;
	}
}
