import { HttpParams } from "@angular/common/http";
import { BaseApiRequest } from "../../@shared/base-api-request";

export class DetermineTreatmentsSigningPlatformsRequest extends BaseApiRequest {
	deviceOperatingSystemCode!: string;
	patient!: DetermineTreatmentsSigningPlatformsRequestPatient;

	constructor(init?: Partial<DetermineTreatmentsSigningPlatformsRequest>) {
		super();
		Object.assign(this, init);
	}

	override getHttpParams(): HttpParams {
		let params = new HttpParams();

		params = params.append("deviceOperatingSystemCode", this.deviceOperatingSystemCode);

		Object.entries(this.patient).forEach(([key, value]) => {
			if (value !== null && value !== undefined && value !== "") {
				params = params.append(`patient.${key}`, value as string);
			}
		});

		return params;
	}
}

export interface DetermineTreatmentsSigningPlatformsRequestPatient {
	birthDate: Date;
	familyName: string;
	givenName: string;
	healthPlanCode: string;
	socialSecurityNumber: string;
	advancedRequiredFields: boolean;
	sectorCode: string;
}

export class DeterminePrescriptionsSigningPlatformsRequest extends BaseApiRequest {
	deviceOperatingSystemCode!: string;
	documentPrescriptionIds!: string[];

	constructor(init?: Partial<DeterminePrescriptionsSigningPlatformsRequest>) {
		super();
		Object.assign(this, init);
	}

	override getHttpParams(): HttpParams {
		let params = new HttpParams();

		params = params.append("deviceOperatingSystemCode", this.deviceOperatingSystemCode);

		if (this.documentPrescriptionIds && this.documentPrescriptionIds.length > 0) {
			this.documentPrescriptionIds.forEach(id => {
				params = params.append("documentPrescriptionIds", id);
			});
		}

		return params;
	}
}

export class DetermineSigningPlatformResponse {
	amelinkPrescriptionsGroupId!: string;
	amelinkUrl!: string | null;
	canBrowserUseAmelink!: boolean;
	canMedicalUserUseAmelink!: boolean;
	hasAmelinkBypassRules!: boolean;
	isMultiPlatform!: boolean;
	patientCareFormTreatments!: SigningPlatformTreatment[];
	prescriptions!: SigningPlatformPrescription[];
	uniqueSigningPlatformCode!: string | null;

	constructor(init?: Partial<DetermineSigningPlatformResponse>) {
		Object.assign(this, init);
	}
}

export interface SigningPlatformTreatment {
	matchesNoRules: boolean;
	treatmentCode: string;
	signingPlatformCode: string;
}

export interface SigningPlatformPrescription {
	documentPrescriptionId: string;
	isAlreadySigned: boolean;
	isPartiallySigned: boolean;
	isMultiPlatform: boolean;
	matchesNoRules: boolean;
	prescriptionId: string;
	signingPlatformCodes: string[];
	uniqueSigningPlatformCode: string | null;
}
