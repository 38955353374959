import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subscription, catchError, map, of, switchMap, shareReplay } from "rxjs";
import { LegalDocumentTypeCodes, SubjectEvents } from "src/app/constants";
import { UserAgreementLegalDocument, UserAgreementStatus } from "src/app/models";
import { AgreementsService, CommunicationComService, Logger } from "src/app/services";
import { DialogTermsAndConditionsData, ModalTermsAndConditionsComponent } from "src/app/@shared";

const log = new Logger("LegalDocumentService");

@Injectable({
	providedIn: "root",
})
export class LegalDocumentService {
	private _modalTermsAndConditionsCloseSubscription?: Subscription;
	private _subjectUserTermsAndConditionsAgreementStatusUpdated = new BehaviorSubject<string>(SubjectEvents.onInit);

	userTermsAndConditionsAgreementStatus$: Observable<UserAgreementStatus | undefined> =
		this._subjectUserTermsAndConditionsAgreementStatusUpdated.asObservable().pipe(
			switchMap(() => this._agreementService.getUserTermsAndConditionsAgreementStatus()),
			catchError((err: any) => {
				log.error(err);
				return of(undefined);
			}),
			shareReplay(1)
		);

	legalNotice$: Observable<UserAgreementLegalDocument | undefined>;
	privacyPolicy$: Observable<UserAgreementLegalDocument | undefined>;

	constructor(
		private _agreementService: AgreementsService,
		private _dialog: MatDialog,
		private _communicationComService: CommunicationComService
	) {
		this.legalNotice$ = this._agreementService.getLegalDocument(LegalDocumentTypeCodes.legalNotice).pipe(
			map(value => value),
			catchError((err: any) => {
				log.error(err);
				return of(undefined);
			})
		);
		this.privacyPolicy$ = this._agreementService.getLegalDocument(LegalDocumentTypeCodes.privacyPolicy).pipe(
			map(value => value),
			catchError((err: any) => {
				log.error(err);
				return of(undefined);
			})
		);
	}

	openLegalDocumentModal(legalDocument: UserAgreementLegalDocument): void {
		this._communicationComService.openModalOnFullScreen(true);

		const data = {
			description: legalDocument.description,
			legalDocumentId: legalDocument.id,
			title: legalDocument.title,
		} as DialogTermsAndConditionsData;

		const dialogRef = this._dialog.open(ModalTermsAndConditionsComponent, { data, autoFocus: false });
		dialogRef.componentInstance.isTermsAndConditionsOpen = true;
		dialogRef.componentInstance.isOnlyForReading = true;

		this._modalTermsAndConditionsCloseSubscription?.unsubscribe();
		this._modalTermsAndConditionsCloseSubscription = dialogRef.afterClosed().subscribe(() => {
			this._communicationComService.openModalOnFullScreen(false);
		});
	}
}
