import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { catchError, map, Observable, of, Subject, switchMap, tap } from "rxjs";
import { SubjectEvents } from "src/app/constants";
import { HealthCenterSelectionItem, PrescriberPatientsSelection } from "src/app/models";
import { AuthService, Logger, MedicalUsersService } from "src/app/services";

const log = new Logger("PrescriberSelectionComponent");

export interface PrescriberItem {
	healthCenterId: string | null;
	healthCenterName: string | null;
	prescriberId: string;
	prescriberFullName: string;
}
interface PrescriberSelectorItem {
	messagePreferencePrescriberId: string;
	selection: PrescriberPatientsSelection;
	uniquePrescribers: UniquePrescriber[];
}
interface UniquePrescriber {
	fullName: string;
	prescriberIds: string[];
}
@Component({
	selector: "app-prescriber-selector",
	templateUrl: "./prescriber-selector.component.html",
})
export class PrescriberSelectorComponent implements OnInit {
	private readonly authService = inject(AuthService);
	private readonly fb = inject(FormBuilder);
	private readonly medicalUsersService = inject(MedicalUsersService);

	@Output() readonly healthCenterSelectorActivation = new EventEmitter<boolean>();
	@Output() readonly healthCenterIdChange = new EventEmitter<string | null>();
	@Output() readonly prescriberIdChange = new EventEmitter<string>();
	@Input() isFromPatientCareForm = false;
	@Input() isOneColumnDisplay = false;

	prescriberControl = new FormControl("", Validators.required);
	healthCenterIdControl = new FormControl("");
	formGroup = this.fb.group({
		prescriber: this.prescriberControl,
		healthCenterId: this.healthCenterIdControl,
	});

	allPrescribers: PrescriberItem[] = [];
	isActive = false;
	isComponentLoading = true;
	isComponentOnError = false;
	isHealthCenterSelectionActive = false;
	availablePrescriberIds: string[] = [];
	healthCenters: HealthCenterSelectionItem[] = [];

	private _onChangeSubject$: Subject<string> = new Subject<string>();
	prescriberSelection$: Observable<PrescriberSelectorItem | undefined> = this._onChangeSubject$.asObservable().pipe(
		tap(_ => {
			this.isComponentLoading = true;
			this.isComponentOnError = false;
		}),
		switchMap(() =>
			this.medicalUsersService.getPrescriberCards(this.authService.user.currentMedicalUserId).pipe(
				catchError((err: any) => {
					this.isComponentOnError = true;
					log.error(err);
					return of(undefined);
				})
			)
		),
		tap(e => {
			if (e) {
				this.isActive = e.nbTotalWorkplaces > 1 || e.availablePrescriberIds.length > 1;
				this.healthCenters = e.healthCenters;

				const prescriberId = this.authService.user.scopePrescriberIds[0];

				this.availablePrescriberIds = e.availablePrescriberIds;

				this.healthCenterSelectorActivation.emit(this.isActive);
				this.prescriberIdChange.emit(prescriberId);
			}
		}),
		map(e => {
			return {
				messagePreferencePrescriberId: e?.messagePreferencePrescriberId,
				selection: e,
				uniquePrescribers: this.getUniquePrescribers(e),
			} as PrescriberSelectorItem;
		}),
		tap(e => {
			if (e && e.uniquePrescribers.length !== 0) {
				const prescriberId = e.messagePreferencePrescriberId ?? this.authService.user.scopePrescriberIds[0];

				const itemIndex = this.allPrescribers.findIndex(p => p.prescriberId === prescriberId);

				if (itemIndex !== -1) {
					setTimeout(() => {
						if (this.isFromPatientCareForm) this.healthCenterIdControl.setValidators([Validators.required]);
						this.prescriberControl.patchValue(this.allPrescribers[itemIndex].prescriberFullName);
						this.healthCenterIdControl.patchValue(this.allPrescribers[itemIndex].healthCenterId);
						this.onPrescriberSelectionChanged();
					}, 0);
				}
			}
			this.isComponentLoading = false;
		})
	);

	ngOnInit(): void {
		setTimeout(() => this._onChangeSubject$.next(SubjectEvents.onInit), 0);
	}

	onPrescriberSelectionChanged(): void {
		const selectedPrescriber = this.prescriberControl.value;
		const availablePrescribers = this.allPrescribers.filter(p => p.prescriberFullName === selectedPrescriber);

		this.prescriberIdChange.emit(availablePrescribers[0].prescriberId);
		this.healthCenterIdChange.emit(availablePrescribers[0].healthCenterId);
		this.healthCenterIdControl.patchValue(availablePrescribers[0].healthCenterId);

		if (availablePrescribers.length === 1 || !this.isFromPatientCareForm) {
			this.isHealthCenterSelectionActive = false;
		}

		if (availablePrescribers.length > 1 && this.isFromPatientCareForm) {
			this.isHealthCenterSelectionActive = true;
		}
	}

	onHealthCenterSelectionChanged(_: any): void {
		const selectedHealthCenterId = this.formGroup.value.healthCenterId;
		const availablePrescribers = this.allPrescribers.filter(p => p.healthCenterId === selectedHealthCenterId);

		this.healthCenterIdChange.emit(availablePrescribers[0].healthCenterId);
		this.prescriberIdChange.emit(availablePrescribers[0].prescriberId);
	}

	selectPrescriber(prescriberId: string): void {
		const itemIndex = this.allPrescribers.findIndex(e => e.prescriberId === prescriberId);
		if (itemIndex === -1) return;

		this.prescriberControl.patchValue(this.allPrescribers[itemIndex].prescriberFullName);
		this.healthCenterIdControl.patchValue(this.allPrescribers[itemIndex].healthCenterId);
		this.onPrescriberSelectionChanged();
	}

	getUniquePrescribers(prescriberSelectionData: PrescriberPatientsSelection | undefined): UniquePrescriber[] {
		if (!prescriberSelectionData) {
			return [];
		}

		prescriberSelectionData.healthCenters.forEach((healthCenter: any) => {
			healthCenter.prescriberCards.forEach((prescriberCard: any) => {
				this.allPrescribers.push({
					healthCenterId: healthCenter.id,
					healthCenterName: healthCenter.name,
					prescriberId: prescriberCard.id,
					prescriberFullName: prescriberCard.fullName,
				});
			});
		});

		prescriberSelectionData.prescriberCards.forEach((prescriberCard: any) => {
			this.allPrescribers.push({
				healthCenterId: "",
				healthCenterName: prescriberCard.address,
				prescriberId: prescriberCard.id,
				prescriberFullName: prescriberCard.fullName,
			});
		});

		this.allPrescribers = Array.from(new Map(this.allPrescribers.map(item => [item.prescriberId, item])).values());

		return this.allPrescribers.reduce((result: UniquePrescriber[], currentValue: PrescriberItem) => {
			const itemIndex = result.findIndex(r => r.fullName === currentValue.prescriberFullName);
			if (itemIndex === -1) {
				result.push({
					fullName: currentValue.prescriberFullName,
					prescriberIds: [currentValue.prescriberId],
				});
			} else {
				result[itemIndex].prescriberIds.push(currentValue.prescriberId);
			}
			return result;
		}, [] as UniquePrescriber[]);
	}

	getHealthCentersForSelectedPrescriber(): PrescriberItem[] {
		const selectedPrescriber = this.formGroup.value.prescriber;
		const items = this.allPrescribers.filter(p => p.prescriberFullName === selectedPrescriber);
		return items;
	}
}
