import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class UtilsService {
	cloneDeep(value: any): any {
		return JSON.parse(JSON.stringify(value));
	}

	removeDuplicate<T>(values: T[]): T[] {
		return [...new Set(values)];
	}
}
