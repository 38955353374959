import { Pipe, PipeTransform } from "@angular/core";
import { CountryCode, ParseError, parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { Logger } from "src/app/services/logger.service";

const log = new Logger("PhonePipe");

@Pipe({
	name: "phone",
})
export class PhonePipe implements PipeTransform {
	constructor() {}

	transform(phoneValue: number | string, country: string): string {
		try {
			if (!phoneValue) return "";

			if (!isValidPhoneNumber(phoneValue + "", country as CountryCode)) return phoneValue.toString();

			return parsePhoneNumber(phoneValue + "", country as CountryCode).formatNational();
		} catch (error) {
			if (error instanceof ParseError) {
				log.error(error.message);
			} else {
				log.error(error);
			}

			return phoneValue.toString();
		}
	}
}
