import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

// Commands
import {
	CreatePatientCareCommand,
	CreatePatientCareResponse,
	GetDeviceModelsRequest,
	GetDeviceModelsResponse,
} from "../models/patient-care-form/patient-care.interface";

@Injectable({
	providedIn: "root",
})
export class PatientCareFormService {
	private _patientCareSummarySubject: ReplaySubject<CreatePatientCareCommand | null> =
		new ReplaySubject<CreatePatientCareCommand | null>(1);

	patientCareSummaryChange$: Observable<CreatePatientCareCommand | null> =
		this._patientCareSummarySubject.asObservable();

	constructor(private _httpClient: HttpClient) {}

	notifyNewPatientCare(command: CreatePatientCareCommand): void {
		this._patientCareSummarySubject.next(command);
	}

	resetPatientCareSummaryObject(): void {
		this._patientCareSummarySubject.next(null);
	}

	createPatientCare(patientCare: CreatePatientCareCommand): Observable<CreatePatientCareResponse> {
		return this._httpClient.post<CreatePatientCareResponse>(`/patient-care-form/`, patientCare);
	}

	getDeviceModels(request: GetDeviceModelsRequest): Observable<GetDeviceModelsResponse> {
		const options = {
			params: request.getHttpParams(),
		};
		return this._httpClient.get<GetDeviceModelsResponse>(`/patient-care-form/device-models`, options);
	}
}
