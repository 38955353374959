import { Component, Input } from "@angular/core";
import { TableCellPerson } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-person",
	templateUrl: "table-cell-person.component.html",
})
export class TableCellPersonComponent {
	@Input() item?: TableCellPerson;
}
