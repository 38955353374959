import { CacheKeys } from "../constants/cache-keys.constant";

export class Impersonation {
	isImpersonated = false;
	agencies = "";
	familyName = "";
	givenName = "";
	hasMultipleActivityArea: boolean | null = null;
	hasNoActivityArea: boolean | null = null;
	medicalUserId = "";
	scopes: string[] = [];

	constructor(init?: Partial<Impersonation>) {
		Object.assign(this, init);
	}

	saveInsideCache(): void {
		const jsonData = JSON.stringify(this);
		localStorage.setItem(CacheKeys.impersonation, jsonData);
	}

	static resetImpersonationInsideCache(): Impersonation {
		const impersonation = new Impersonation({
			isImpersonated: false,
			familyName: "",
			givenName: "",
			hasNoActivityArea: null,
			hasMultipleActivityArea: null,
			medicalUserId: "",
			scopes: [],
		});

		const jsonData = JSON.stringify(impersonation);
		localStorage.setItem(CacheKeys.impersonation, jsonData);

		return impersonation;
	}

	static retrieveFromCache(): Impersonation {
		const jsonData = localStorage.getItem(CacheKeys.impersonation);
		if (jsonData) return JSON.parse(jsonData);

		return Impersonation.resetImpersonationInsideCache();
	}
}
