import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GetRegionsResponse } from "../models/regions/region.interface";

@Injectable({
	providedIn: "root",
})
export class RegionsService {
	constructor(private _httpClient: HttpClient) {}

	getAllRegions(): Observable<GetRegionsResponse> {
		return this._httpClient.get<GetRegionsResponse>(`/regions`);
	}
}
