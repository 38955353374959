import { Component, Input } from "@angular/core";
import { TableCellDate } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-date",
	templateUrl: "table-cell-date.component.html",
})
export class TableCellDateComponent {
	@Input() item?: TableCellDate;
}
