import { Component, ViewChild } from "@angular/core";
import { FilterTableColumnTypes, TableCellTypes, TableItemDocument, TableView } from "src/app/@shared";
import { ComparisonOperators, MessageStatus, TableCacheKeys } from "src/app/constants";
import { BrowseMessagesCommand, MessageItem, PagerMessage } from "src/app/models";
import { AppConfigService, AuthService, ExchangeService, FilesService } from "src/app/services";
import { TableCellMessageNames } from "../message-table/prescriber-message-table.component";
import {
	BrowsePagingParametersMobile,
	TableMobileComponent,
} from "src/app/@shared/components/table-mobile/table-mobile.component";

@Component({
	selector: "app-prescriber-message-card",
	templateUrl: "./prescriber-message-card.component.html",
})
export class PrescriberMessageCardComponent {
	@ViewChild("tableMobile") tableMobile!: TableMobileComponent<MessageItem, BrowseMessagesCommand, PagerMessage>;
	browseMessagingParameters: BrowsePagingParametersMobile<{ medicalUserId: string; treatmentBusinessCode: string }>;
	browseCommandType: new (init: Partial<BrowseMessagesCommand>) => BrowseMessagesCommand = BrowseMessagesCommand;

	private apiRootUrl: string;

	public defaultTableViewMobile: TableView = new TableView({
		version: 1,
		filters: { items: [] },
		isMobile: true,
		pageIndex: 0,
		pageSize: 5,
		sort: {
			sortColumn: "createdOn",
			sortDirection: "desc",
		},
		columns: {
			createdOnMobile: {
				columnName: TableCellMessageNames.createdOn,
				cellType: TableCellTypes.date,
				filterColumnName: TableCellMessageNames.createdOn,
				filterDefaultOperator: ComparisonOperators.between,
				filterTableColumnType: FilterTableColumnTypes.date,
				filterToolTip: "Filtrer sur la date de création",
				headerLabel: "Date",
				isVisible: true,
				order: 1,
				sortColumnName: TableCellMessageNames.createdOn,
				sortToolTip: "Trier sur la date de création",
				weight: 12,
			},
			titleMobile: {
				columnName: TableCellMessageNames.title,
				cellType: TableCellTypes.string,
				filterColumnName: TableCellMessageNames.title,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.stringContains,
				filterToolTip: "Filtrer sur l'objet du message",
				headerLabel: "Objet du message",
				isVisible: true,
				order: 4,
				sortColumnName: TableCellMessageNames.title,
				sortToolTip: "Trier sur l'objet du message",
				weight: 15,
			},
			bodyMobile: {
				columnName: TableCellMessageNames.body,
				cellType: TableCellTypes.readMore,
				filterColumnName: TableCellMessageNames.body,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.stringContains,
				filterToolTip: "Filtrer sur le contenu du message",
				headerLabel: "Contenu du message",
				isVisible: true,
				order: 5,
				sortColumnName: TableCellMessageNames.body,
				sortToolTip: "Trier sur le contenu du message",
				weight: 30,
			},
			attachmentsMobile: {
				columnName: TableCellMessageNames.attachments,
				cellType: TableCellTypes.documents,
				filterColumnName: TableCellMessageNames.hasAttachment,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.in,
				filterChoiceList: {
					items: [
						{ label: "Oui", value: true, isSelected: false },
						{ label: "Non", value: false, isSelected: false },
					],
				},
				filterToolTip: "Filtrer sur les documents",
				headerLabel: "Documents",
				isVisible: true,
				order: 6,
				sortColumnName: TableCellMessageNames.hasAttachment,
				sortToolTip: "Trier sur les documents",
				weight: 12,
			},
			statusMobile: {
				columnName: TableCellMessageNames.status,
				cellType: TableCellTypes.messageStatus,
				filterColumnName: TableCellMessageNames.status,
				filterDefaultOperator: ComparisonOperators.contains,
				filterTableColumnType: FilterTableColumnTypes.in,
				filterChoiceList: {
					items: [
						{ label: "A traiter", value: MessageStatus.needTreatment, isSelected: false },
						{ label: "Pris en charge", value: MessageStatus.inProgress, isSelected: false },
						{ label: "Traité", value: MessageStatus.treated, isSelected: false },
					],
				},
				filterToolTip: "Filtrer sur le statut du message",
				headerLabel: "Status",
				isVisible: true,
				order: 7,
				sortColumnName: TableCellMessageNames.status,
				sortToolTip: "Trier sur le statut du message",
				weight: 10,
			},
		},
	});
	get cacheKey(): string {
		return `${TableCacheKeys.tablePrescriberMessages}__${this._authService.user.currentMedicalUserId}`;
	}
	get cacheKeyMobile(): string {
		return `${TableCacheKeys.tablePrescriberMessagesMobile}__${this._authService.user.currentMedicalUserId}`;
	}

	constructor(
		private _authService: AuthService,
		public exchangeService: ExchangeService,
		private _filesService: FilesService,
		private _appConfigService: AppConfigService
	) {
		this.apiRootUrl = this._appConfigService.appConfig.hostApi;
		this.browseMessagingParameters = {
			customParams: {
				medicalUserId: this._authService.user.currentMedicalUserId,
				treatmentBusinessCode: this._authService.user.activityArea ? this._authService.user.activityArea : "",
			},
		};
	}

	getColorStatus(status: string): string {
		switch (status) {
			case MessageStatus.treated:
				return "prescription-green";
			case MessageStatus.inProgress:
				return "prescription-orange";
			case MessageStatus.needTreatment:
				return "prescription-red";
			default:
				return "";
		}
	}

	getDisplayStatus(status: string): string {
		switch (status) {
			case MessageStatus.treated:
				return "Traité";
			case MessageStatus.inProgress:
				return "Pris en charge";
			case MessageStatus.needTreatment:
				return "À traiter";
			default:
				return status;
		}
	}

	getButtonStatus(status: string): string {
		switch (status) {
			case MessageStatus.treated:
				return "Traité";
			case MessageStatus.inProgress:
				return "Pris en charge";
			case MessageStatus.needTreatment:
				return "À traiter";
			default:
				return status;
		}
	}

	getAttachments(item: MessageItem): TableItemDocument[] {
		return this._filesService.convertToTableItemDocuments(item.attachments);
	}
}
