export class HealthCenters {
	static readonly healthCenterClientTypology: any = {
		autres: { value: "autres", name: "Autres" },
		centresHospitaliers: { value: "centres hospitaliers", name: "Centres Hospitaliers" },
		ehpad: { value: "ehpad", name: "EHPAD" },
		had: { value: "had", name: "HAD" },
		laboratoires: { value: "laboratoires", name: "Laboratoires" },
		medecins: { value: "médecins", name: "Médecins" },
		psad: { value: "psad", name: "PSAD" },
		mutuelles: { value: "mutuelles", name: "mutuelles" },
	};
}
