import { inject, Injectable } from "@angular/core";
import {
	CanActivateFn,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	createUrlTreeFromSnapshot,
	Params,
} from "@angular/router";
import { Observable, map, of, switchMap } from "rxjs";
import { Logger, PrescribersService, AuthService } from "../services";
import { AuthorizationResult } from "../types";
import { SearchPrescribersResult } from "../models";

const log = new Logger("Guards");

export const authInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	const permissionsService = inject(PermissionsService);
	return permissionsService.isAuthenticated(route, state);
};

export const featurePermissionInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	const permissionsService = inject(PermissionsService);
	return permissionsService.hasFeaturePermission(route, state);
};

export const impersonationForPatientGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | boolean | UrlTree => {
	const permissionsService = inject(PermissionsService);
	return permissionsService.isImpersonateForPatient(route, state);
};

/**
 * @deprecated Use featurePermissionInGuard instead
 */
export const rolesInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	const permissionsService = inject(PermissionsService);
	return permissionsService.hasInRoles(route, state);
};

export const scopesInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	const permissionsService = inject(PermissionsService);
	return permissionsService.hasInScope(route, state);
};

export const activityAreaSelectorInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	const permissionsService = inject(PermissionsService);
	return permissionsService.hasActivityAreaSelected(route, state);
};

@Injectable({ providedIn: "root" })
export class PermissionsService {
	private readonly prescribersService = inject(PrescribersService);
	private readonly authService = inject(AuthService);

	isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult) => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					log.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				return true;
			})
		);
	}

	isImpersonateForPatient(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult): boolean | UrlTree | ImpersonationLinkParameter => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					log.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				const medicalUserId = route.queryParams["muid"] as string | null;
				const patientId = route.params["id"] as string | null;

				if (medicalUserId && patientId && medicalUserId !== authorizationResult.user?.currentMedicalUserId)
					return new ImpersonationLinkParameter({ medicalUserId: medicalUserId, patientId: patientId });

				if (
					!authorizationResult.user.isPrescriber &&
					!authorizationResult.user.isSecretary &&
					!authorizationResult.user.isImpersonated
				) {
					log.warn(
						`Forbidden to access ${state.url} | not a prescriber or not impersonated --> redirection to forbidden page`
					);
					return this.redirectToForbiddenPage(route, state.url);
				}

				if (medicalUserId && medicalUserId === authorizationResult.user?.currentMedicalUserId)
					return this.createUrlTree(route, [state.url?.removeQueryParams()]);

				return true;
			}),
			switchMap(e1 => {
				if (!(e1 instanceof ImpersonationLinkParameter)) return of(e1);

				return this.prescribersService.searchPrescribersByPatient(e1.patientId).pipe(
					map((searchResult: SearchPrescribersResult) => {
						const prescriber = searchResult.items.find(e2 => e2.medicalUserId === e1.medicalUserId);
						if (!prescriber) {
							log.warn(
								`Forbidden to access to ${state.url} page | no match found between the medical user and the patient --> redirection to forbidden page`
							);
							return this.redirectToForbiddenPage(route, state.url);
						}

						this.authService.impersonateMedicalUser(
							prescriber.medicalUserId ?? "",
							"",
							prescriber.familyName ?? "",
							prescriber.givenName ?? "",
							false,
							false,
							[prescriber.specificRole ?? ""],
							prescriber.prescriberIds ?? null
						);

						this.authService.changeUserActivityAreaToRespiratory();

						const redirectUrl = state.url?.removeQueryParams();
						log.info(
							`Match found for the patient and the medical user to access with impersonation --> redirection to the page ${redirectUrl}`
						);
						return this.createUrlTree(route, [redirectUrl]);
					})
				);
			})
		);
	}

	hasFeaturePermission(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult): boolean | UrlTree => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					log.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				if (
					!route.data ||
					!authorizationResult.hasFeaturePermission(route.data["feature"], route.data["permission"])
				) {
					log.warn(
						`Forbidden to access ${state.url} | not enough permission for the feature --> redirection to forbidden page`
					);
					return this.redirectToForbiddenPage(route, state.url);
				}

				return true;
			})
		);
	}

	hasInRoles(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult): boolean | UrlTree => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					log.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				if (!route.data || !authorizationResult.hasRoles(route.data["roles"])) {
					log.warn(
						`Forbidden to access ${state.url} | current roles not allowed --> redirection to forbidden page`
					);
					return this.redirectToForbiddenPage(route, state.url);
				}

				return true;
			})
		);
	}

	hasInScope(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult): boolean | UrlTree => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					log.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				if (!route.data || !authorizationResult.hasScopes(route.data["scopes"])) {
					log.warn(
						`Forbidden to access ${state.url} | current scopes not allowed --> redirection to forbidden page`
					);
					return this.redirectToForbiddenPage(route, state.url);
				}

				if (
					!route.data["byPassPrescriberSelection"] &&
					!(authorizationResult.user.scopePrescriberIds?.length > 0)
				) {
					log.warn(`No patients selected to access ${state.url} --> redirection to patients selector page`);
					return this.createUrlTree(route, ["/prescriber/patients-selection"], { returnUrl: state.url });
				}

				return true;
			})
		);
	}

	hasActivityAreaSelected(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult): boolean | UrlTree => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					log.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				if (!authorizationResult.canAccessActivityAreaContent()) {
					log.warn(`No activity selected to access ${state.url} --> redirection to activity selector page`);
					return this.createUrlTree(route, ["/activity-area-selector"], { returnUrl: state.url });
				}

				if (!route.data || !authorizationResult.hasActivityAreas(route.data["activityAreas"])) {
					log.warn(
						`Forbidden to access ${state.url} | current activity area not allowed --> redirection to forbidden page`
					);
					return this.redirectToForbiddenPage(route, state.url);
				}

				return true;
			})
		);
	}

	private redirectToUnauthorizedPage(route: ActivatedRouteSnapshot, returnUrl: string): UrlTree {
		return this.createUrlTree(route, ["/unauthorized"], { returnUrl: returnUrl });
	}

	private redirectToForbiddenPage(route: ActivatedRouteSnapshot, returnUrl: string): UrlTree {
		return this.createUrlTree(route, ["/forbidden"], { returnUrl: returnUrl });
	}

	private createUrlTree(
		route: ActivatedRouteSnapshot,
		commands: string[],
		queryParams?: Params | null | undefined
	): UrlTree {
		return createUrlTreeFromSnapshot(route, commands, queryParams);
	}
}

class ImpersonationLinkParameter {
	medicalUserId!: string;
	patientId!: string;
	constructor(init?: Partial<ImpersonationLinkParameter>) {
		Object.assign(this, init);
	}
}
