import { Pipe, PipeTransform } from "@angular/core";
import { MedicalUsers } from "src/app/constants";
import { HealthCenterTypes } from "src/app/constants/health-center-types.constant";
import { Sexes } from "src/app/constants/sexes.constant";
import { Treatments } from "src/app/constants/treatments.constant";

@Pipe({
	name: "label",
})
export class LabelPipe implements PipeTransform {
	constructor() {}

	transform(value: string[] | any, type: string): string {
		return this.getLabel(value, type);
	}

	private getLabel(value: any, type: string): string {
		if (type === undefined) return "";

		switch (type) {
			case "healthCenterTypeIcon":
				return this.getHealthCenterTypeIcon(value);

			case "healthCenterTypeSingular":
				return this.getHealthCenterSingularLabel(value);

			case "prescriberSexIcon":
				return this.getPrescriberSexIcon(value);

			case "treatmentCode":
				return this.getTreatmentLabel(value);

			case "medicalUserTitle":
				return this.getMedicalUserTitle(value);

			default:
				return value;
		}
	}

	private getHealthCenterTypeIcon(typeCode: string): string {
		if (!typeCode) return HealthCenterTypes.iconMappingRecords[HealthCenterTypes.unknown];

		const value = HealthCenterTypes.iconMappingRecords[typeCode];

		if (!value) return HealthCenterTypes.iconMappingRecords[HealthCenterTypes.unknown];

		return value;
	}

	private getHealthCenterSingularLabel(typeCode: string): string {
		if (!typeCode) return HealthCenterTypes.singularLabelMappingRecords[HealthCenterTypes.unknown];

		const value = HealthCenterTypes.singularLabelMappingRecords[typeCode];

		if (!value) return HealthCenterTypes.singularLabelMappingRecords[HealthCenterTypes.unknown];

		return value;
	}

	private getPrescriberSexIcon(sexCode: string): string {
		if (!sexCode) return Sexes.iconMappings[Sexes.neutral];

		const value = Sexes.iconMappings[sexCode];

		if (!value) return Sexes.iconMappings[Sexes.neutral];

		return value;
	}

	private getTreatmentLabel(code: any): string {
		const label = Treatments.PrescriptionCodeMappings.get(code);

		if (!label) return "";

		return label;
	}

	private getMedicalUserTitle(medicalUserTitle: string): string {
		return MedicalUsers.labelMappings[medicalUserTitle] ?? "Inconnu";
	}
}
