export class ExternalReportTypes {
	static readonly compliance: string = "compliance";
	static readonly complianceTherapy: string = "compliance-therapy";
	static readonly detailed: string = "detailed";
	static readonly summary: string = "summary";
	static readonly therapy: string = "therapy";
	static readonly trend: string = "trend";

	static readonly defaultResmedValue: string = ExternalReportTypes.complianceTherapy;
	static readonly defaultRespironicsValue: string = ExternalReportTypes.summary;

	static readonly allAvailable: string[] = [
		ExternalReportTypes.therapy,
		ExternalReportTypes.compliance,
		ExternalReportTypes.complianceTherapy,
		ExternalReportTypes.detailed,
		ExternalReportTypes.summary,
		ExternalReportTypes.trend,
	];

	static readonly allAvailableResmed: string[] = [
		ExternalReportTypes.therapy,
		ExternalReportTypes.compliance,
		ExternalReportTypes.complianceTherapy,
		ExternalReportTypes.detailed,
	];

	static readonly allAvailableRespironics: string[] = [
		ExternalReportTypes.detailed,
		ExternalReportTypes.summary,
		ExternalReportTypes.trend,
	];

	static readonly labelMappingRecord: Record<string, string> = {
		[ExternalReportTypes.compliance]: "Rapport d'observance",
		[ExternalReportTypes.complianceTherapy]: "Rapport combiné",
		[ExternalReportTypes.detailed]: "Rapport détaillé",
		[ExternalReportTypes.summary]: "Rapport résumé",
		[ExternalReportTypes.therapy]: "Rapport thérapeutique",
		[ExternalReportTypes.trend]: "Rapport de tendance",
	};
}
