import { Params } from "@angular/router";
import { SortDirection } from "@angular/material/sort";
import { BrowseFilteredColumn } from "src/app/@shared/types/browse-paging";
import { TableItemDocument } from "../table-item-document";
import { FilterTableColumnTypes, TableCellTypes } from "../../constants";
import { ComparisonOperators, ObservancePeriodTypes } from "src/app/constants";

// TABLE COMMON

export class TableRowList {
	hasItems = false;
	items: TableItem[] = [];
	nbTotalFilteredItems = 0;
	nbTotalItems = 0;

	constructor(init?: Partial<TableRowList>) {
		Object.assign(this, init);
	}

	static defaultValue: TableRowList = new TableRowList({
		hasItems: false,
		items: [],
		nbTotalItems: 0,
		nbTotalFilteredItems: 0,
	});
}

export interface TableVisibleColumnListItem {
	columnName: string;
	cellType: TableCellTypes;
	filterChoiceList?: TableFilterChoiceList;
	filterColumnName?: string;
	filterOnly?: boolean;
	filterTableColumnType?: FilterTableColumnTypes;
	filterToolTip?: string;
	headerColumnName: string;
	headerFilterColumnName: string;
	headerLabel: string;
	headerSubLabel?: string;
	headerWithoutShadow?: boolean;
	sortColumnName?: string;
	sortToolTip?: string;
	width: string;
}

export interface TableColumnListItem {
	columnName: string;
	cellType: TableCellTypes;
	filterChoiceList?: TableFilterChoiceList;
	filterColumnName?: string;
	filterDefaultOperator?: ComparisonOperators;
	filterOnly?: boolean;
	filterTableColumnType?: FilterTableColumnTypes;
	filterToolTip?: string;
	headerLabel: string;
	headerSubLabel?: string;
	headerWithoutShadow?: boolean;
	isVisible: boolean;
	order: number;
	sortColumnName?: string;
	sortToolTip?: string;
	weight: number;
}

export type TableColumnList = Record<string, TableColumnListItem>;

export interface TableItem {
	isSelected: boolean;
	id: string;
}

export interface TableFilterChoiceList {
	items: TableFilterChoiceListItem[];
}

export interface TableFilterChoiceListItem {
	isSelected: boolean;
	label: string;
	operator?: ComparisonOperators;
	value: any;
}

export interface TableFilterListItemDetail {
	filterType: FilterTableColumnTypes;
	label?: string;
	name: string;
	operator: ComparisonOperators;
	value: any;
}

export interface TableFilterListItem {
	columnName: string;
	filteredColumn: TableFilterListItemDetail;
}

export interface TableSortItem {
	sortColumn: string;
	sortDirection: SortDirection;
}
export interface TableFiltersList {
	items: TableFilterListItem[];
}

//TABLE CELL INTERFACES

export interface TableCellActionsListItemLink {
	routerLink: string;
	params?: Params | null;
	targetBlank?: string;
}

export interface TableCellActionsListItemButton {
	action: () => void;
}

export interface TableCellActionsListItem {
	button?: TableCellActionsListItemButton;
	icon?: string;
	iconCssClass: string;
	isDisabled: boolean;
	link?: TableCellActionsListItemLink;
	title?: string;
	type?: string;
}

export interface TableFilterUrlParamListItem {
	extraParams?: {
		name: string;
		value: boolean;
	};
	name: string;
	operator?: string;
	value: any;
}

export interface TableFilterUrlParamList {
	items: TableFilterUrlParamListItem[];
}

export interface TableCellActionsList {
	items: TableCellActionsListItem[];
}

export interface TableCellObservancePeriodsStatus {
	valueType: ObservancePeriodTypes;
	value: number;
}

export interface TableCellMessageStatus {
	forceCasing: boolean;
	importantValue?: string;
	textCssClass?: string;
	value?: any;
}

export interface TableCellStatus {
	comparorType: string;
	textCssClass?: string;
	valueItems?: string[];
	value: any;
}

export interface TableCellString {
	importantValue?: string;
	forceCasing: boolean;
	textCssClass?: string;
	value: string;
}

export interface TableCellMultipleLinesString {
	firstLineValue?: string;
	firstLineValueAsDate?: Date;
	firstLineValueAsStringDate?: string;
	secondLineValue?: string;
	secondLineValueAsDate?: Date;
	invertBoldCase?: boolean;
}

export interface TableCellDate {
	date?: Date;
	format: string;
	hideSubValue: boolean;
	subValue: string;
	iconCssClass?: string;
	withIcon?: boolean;
}

export interface TableCellDateRange {
	endDate?: Date;
	hideSubValue: boolean;
	startDate: Date;
	subValue: string;
	textCssClass: string;
}

export interface TableCellDocuments {
	value: TableItemDocument[];
}

export interface TableCellDuration {
	duration: string | null;
	observanceLevelCode: string;
	observanceStatusCode: string;
}

export interface TableCellHealthCenterMembers {
	healthCenterMembers: TableCellHealthCenterMember[];
}

export interface TableCellHealthCenterMember {
	prescriberId: string;
	familyName: string;
	givenName: string;
}

export interface TableCellMessageStatus {
	forceCasing: boolean;
	importantValue?: string;
	textCssClass?: string;
}

export interface TableCellPatientInterventionType {
	hideInitials: boolean;
	isLinkVisible: boolean;
	link: string;
	linkTooltip: string;
	treatmentShortLabel: string;
	value: string;
}

export interface TableCellPerson {
	complementaryCssClass01?: string;
	complementaryCssClass02?: string;
	complementaryText01?: string;
	complementaryText02?: string;
	familyName: string;
	givenName: string;
	hideInitials: boolean;
	hidePersonId: boolean;
	isLinkVisible: boolean;
	isOnlyFullName?: boolean;
	isTextCenter?: boolean;
	link: string;
	linkTooltip: string;
	patientId: string;
	shortName: string;
	iconCssClass?: string;
	withIcon?: boolean;
}

export interface TableCellPrescriptionDateRangeAndStatus {
	alignCenter?: boolean;
	dateRangeEndDate?: Date;
	dateRangeHideSubValue: boolean;
	dateRangeStartDate: Date;
	dateRangeSubValue: string;
	dateRangeTextCssClass?: string;
	signingStatus: string;
	signingStatusLabel: string;
}

export interface TableCellReadMore {
	defaultExpand?: boolean;
	id?: string;
	onExpandReadMore?: (id: string) => void;
	title?: string;
	value: string;
}

export interface TableCellSelect {
	isActive: boolean;
}

export interface TableCellTags {
	value: string[];
}

export interface TableCellCodeState {
	cssColor: string | null;
	cssPictogram: string | null;
	forceCasing: boolean;
	usePictogram: boolean;
	label: string;
	value: string;
}
// MAIN CLASS : TABLE VIEW

export class TableView {
	columns!: TableColumnList;
	displayColumns!: TableVisibleColumnListItem[];
	displayColumnNames!: string[];
	filters!: TableFiltersList;
	filteredColumns!: TableFilterListItemDetail[];
	loaderColumnNames: string[] = ["loader"];
	headerColumnNames!: string[];
	headerFilterColumnNames!: string[];
	isMobile = false;
	nbVisibleColumns!: number;
	pageIndex!: number;
	pageSize!: number;
	selectAllButtonDisabled = false;
	showSelectAllButton = false;
	showSelectAllItems = false;
	isSelectAllItems = false;
	sort!: TableSortItem;
	version!: number;

	constructor(init?: Partial<TableView>) {
		Object.assign(this, init);
		this.compute();
	}

	compute(): void {
		this.displayColumns = this.getDisplayColumns();
		this.displayColumnNames = this.getDisplayColumnNames();
		this.filteredColumns = this.filters.items.map(i => i.filteredColumn);
		this.headerColumnNames = this.getHeaderColumnNames();
		this.headerFilterColumnNames = this.getHeaderFilterColumnNames();
		this.nbVisibleColumns = this.displayColumnNames.length;
	}

	addCustomFilter(
		filteredColumnName?: string,
		filteredColumnValue?: string,
		filteredColumnLabel?: string,
		operator?: string,
		isMobile = false
	): void {
		if (!filteredColumnName || !filteredColumnValue) return;

		this.removeFilter(filteredColumnName);

		const column = Object.values(this.columns).find(e => e.filterColumnName === filteredColumnName);

		if (!column || !column.filterTableColumnType || !column.filterDefaultOperator) return;

		const labels: string[] = [];
		const filteredColumnValues = filteredColumnValue.split(";");

		if (column.filterChoiceList) {
			column.filterChoiceList.items.forEach(e => {
				if (filteredColumnValues.findIndex(f => f === e.value) === -1 && filteredColumnValue !== e.value)
					return;

				e.isSelected = true;
				labels.push(e.label);
			});
		}

		const finalLabel = filteredColumnLabel ?? labels.join(" | ") ?? filteredColumnValue;

		this.filters.items = [
			...this.filters.items,
			{
				columnName: isMobile ? column.columnName + "Mobile" : column.columnName,
				filteredColumn: {
					filterType: column.filterTableColumnType,
					label: finalLabel,
					name: filteredColumnName,
					operator: operator ?? column.filterDefaultOperator,
					value: filteredColumnValue,
				},
			},
		];
		this.compute();
	}

	addFilter(columnName: string, filter: TableFilterListItemDetail | null): void {
		if (!filter) return;

		this.removeFilter(filter.name);
		this.filters.items = [
			...this.filters.items,
			{
				columnName: columnName,
				filteredColumn: filter,
			},
		];
		this.compute();
	}

	removeFilter(filteredColumnName: string): void {
		const index = this.filters.items.findIndex(e => e.filteredColumn.name === filteredColumnName);

		if (index === -1) return;

		this.filters.items.splice(index, 1);
		this.compute();
	}

	resetFilters(): void {
		this.filters.items = [];
		this.pageIndex = 0;
		this.compute();
	}

	private getDisplayColumnNames(): string[] {
		return this.getDisplayColumns().map(e => e.columnName);
	}

	private getHeaderColumnNames(): string[] {
		return this.getDisplayColumns().map(e => e.headerColumnName);
	}
	private getHeaderFilterColumnNames(): string[] {
		return this.getDisplayColumns().map(e => e.headerFilterColumnName);
	}

	private getDisplayColumns(): TableVisibleColumnListItem[] {
		return Object.keys(this.columns)
			.filter(e => this.columns[e].isVisible)
			.sort((e1, e2) => this.columns[e1].order - this.columns[e2].order)
			.map(e => {
				return {
					columnName: e,
					cellType: this.columns[e].cellType,
					filterChoiceList: this.columns[e].filterChoiceList,
					filterColumnName: this.columns[e].filterColumnName,
					filterOnly: this.columns[e].filterOnly,
					filterToolTip: this.columns[e].filterToolTip,
					filterTableColumnType: this.columns[e].filterTableColumnType,
					headerColumnName: `header_${e}`,
					headerFilterColumnName: `header_filter_${e}`,
					headerLabel: this.columns[e].headerLabel,
					headerSubLabel: this.columns[e].headerSubLabel,
					headerWithoutShadow: this.columns[e].headerWithoutShadow,
					sortColumnName: this.columns[e].sortColumnName,
					sortToolTip: this.columns[e].sortToolTip,
					width: this.getColumnWidth(e),
				} as TableVisibleColumnListItem;
			});
	}

	private getColumnWidth(columnName: string): string {
		const sumAllWeights: number = Object.keys(this.columns)
			.filter(e => this.columns[e].isVisible)
			.reduce((sum, e) => sum + this.columns[e].weight, 0);

		if (sumAllWeights <= 0) return "";

		const widthPercentage = (100 * this.columns[columnName].weight) / sumAllWeights;

		return (widthPercentage | 0) + "%";
	}

	static createBrowseFilteredColumn(item: TableFilterListItemDetail): BrowseFilteredColumn | null {
		if (!item) return null;

		return {
			name: item.name,
			operation: item.operator as string,
			value: item.value,
		};
	}

	static createContainsTableFilterListItemDetail(
		values: TableFilterChoiceListItem[],
		filterColumnName: string,
		filterType: FilterTableColumnTypes
	): TableFilterListItemDetail | null {
		const availableValues = values.filter(v => v.isSelected);

		if (availableValues.length === 0) return null;
		return {
			filterType: filterType,
			label: availableValues.map(c => c.label).join(" | "),
			name: filterColumnName,
			operator: ComparisonOperators.contains,
			value: availableValues.map(c => c.value).join(";"),
		};
	}

	static createInTableFilterListItemDetail(
		values: TableFilterChoiceListItem[],
		filterColumnName: string,
		filterType: FilterTableColumnTypes
	): TableFilterListItemDetail | null {
		const availableValues = values.filter(v => v.isSelected);

		if (availableValues.length === 0) return null;

		return {
			filterType: filterType,
			label: availableValues.map(c => c.label).join(" | "),
			name: filterColumnName,
			operator: ComparisonOperators.in,
			value: availableValues.map(c => c.value).join(";"),
		};
	}

	static createEqualsTableFilterListItemDetail(
		value: any,
		filterColumnName: string
	): TableFilterListItemDetail | null {
		if (!value) return null;

		return {
			filterType: FilterTableColumnTypes.eq,
			label: value,
			name: filterColumnName,
			operator: ComparisonOperators.equal,
			value: value,
		};
	}

	static createEqualsDateTableFilterListItemDetail(
		value: any,
		filterColumnName: string
	): TableFilterListItemDetail | null {
		if (!value) return null;

		const date = new Date(value);
		if (date.isDateOutOfRange()) return null;

		return {
			filterType: FilterTableColumnTypes.eq,
			label: value,
			name: filterColumnName,
			operator: ComparisonOperators.equal,
			value: value,
		};
	}

	static createStringContainsTableFilterListItemDetail(
		value: string,
		filterColumnName: string,
		filterType: FilterTableColumnTypes
	): TableFilterListItemDetail | null {
		if (!value) return null;

		return {
			filterType: filterType,
			label: value,
			name: filterColumnName,
			operator:
				filterType === FilterTableColumnTypes.searchTerms
					? ComparisonOperators.searchTerms
					: ComparisonOperators.contains,
			value: value,
		};
	}

	static createComparisonTableFilterListItemDetail(
		values: TableFilterChoiceListItem[],
		filterColumnName: string,
		filterType: FilterTableColumnTypes
	): TableFilterListItemDetail | null {
		const availableValues = values.filter(v => v.isSelected);
		if (availableValues.length === 0) return null;

		const operator = availableValues[0].operator ?? ComparisonOperators.equal;
		const value =
			operator === ComparisonOperators.between ? availableValues[0].value.join(";") : availableValues[0].value;
		const label = availableValues[0].label;

		return {
			filterType: filterType,
			label: label,
			name: filterColumnName,
			operator: operator,
			value: value,
		};
	}
}
