import { map } from "rxjs/operators";
import moment from "moment";

export function TransformDateForObservable(_: any, __: string, descriptor: any) {
	const originalMethod = descriptor.value;
	descriptor.value = function () {
		return originalMethod.apply(this).pipe(map(obj => Object.assign({}, obj, stringToDate(obj))));
	};
	return descriptor;
}

export function TransformDate(_: any, __: string, descriptor: any) {
	const originalMethod = descriptor.value;
	descriptor.value = function (...args: any[]) {
		const result = originalMethod.apply(this, args);

		if (!result) return result;

		return stringToDate(result);
	};
	return descriptor;
}

const isDate = (s: any) => moment(s, moment.ISO_8601, true).isValid();

function stringToDate(obj: any): any {
	Object.keys(obj).forEach(key => {
		if (typeof obj[key] === "object" && obj[key] !== null) {
			stringToDate(obj[key]);
		} else if (obj[key] && isDate(obj[key])) {
			obj[key] = moment(obj[key]).utc().toDate();
		}
	});
	return obj;
}
