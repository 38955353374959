import { FeaturePermission, FeaturePermissions } from "./features.constant";

export class RolePermission {
	role!: string;
	featurePermissions!: FeaturePermission[];

	constructor(init?: Partial<RolePermission>) {
		Object.assign(this, init);
	}
}

export class RoleLabels {
	static readonly admin: string = "Administrateur";
	static readonly administrative: string = "Administratif";
	static readonly customerRelationDepartement: string = "SRC";
	static readonly medicalTechnicalAdvisor: string = "CMT";
	static readonly mediviewLocalAdminAsdia: string = "Administrateur local Asdia";
	static readonly mediviewLocalAdminElivie: string = "Administrateur local Elivie";
	static readonly messageController: string = "Message controlleur";
	static readonly prescriber: string = "Prescripteur";
	static readonly regionalOrDevelopmentManager: string = "D.R. / R.DEV.";
	static readonly secretary: string = "Secrétaire / IPA";
	static readonly superAdmin: string = "Super admin";
}

export class Roles {
	static readonly admin: string = "admin";
	static readonly administrative: string = "administrative";
	static readonly customerRelationDepartement: string = "customer-relation-departement";
	static readonly mediviewLocalAdminAsdia: string = "mediview-local-admin-asdia";
	static readonly mediviewLocalAdminElivie: string = "mediview-local-admin-elivie";
	static readonly medicalTechnicalAdvisor: string = "medical-technical-advisor";
	static readonly messageController: string = "message-controller";
	static readonly prescriber: string = "prescriber";
	static readonly regionalOrDevelopmentManager: string = "regional-manager-development-manager";
	static readonly secretary: string = "secretary";
	static readonly superAdmin: string = "super-admin";
	static readonly user: string = "mediview-user";

	static readonly labelMappings: Record<string, string> = {
		[Roles.admin]: RoleLabels.admin,
		[Roles.administrative]: RoleLabels.administrative,
		[Roles.customerRelationDepartement]: RoleLabels.customerRelationDepartement,
		[Roles.medicalTechnicalAdvisor]: RoleLabels.medicalTechnicalAdvisor,
		[Roles.mediviewLocalAdminAsdia]: RoleLabels.mediviewLocalAdminAsdia,
		[Roles.mediviewLocalAdminElivie]: RoleLabels.mediviewLocalAdminElivie,
		[Roles.messageController]: RoleLabels.messageController,
		[Roles.prescriber]: RoleLabels.prescriber,
		[Roles.regionalOrDevelopmentManager]: RoleLabels.regionalOrDevelopmentManager,
		[Roles.secretary]: RoleLabels.secretary,
		[Roles.superAdmin]: RoleLabels.superAdmin,
	};

	static readonly compatibilities: Record<string, string[]> = {
		[Roles.superAdmin]: [Roles.admin],
		[Roles.admin]: [Roles.superAdmin],
		[Roles.medicalTechnicalAdvisor]: [Roles.messageController],
		[Roles.messageController]: [
			Roles.administrative,
			Roles.customerRelationDepartement,
			Roles.medicalTechnicalAdvisor,
			Roles.regionalOrDevelopmentManager,
		],
		[Roles.administrative]: [Roles.messageController],
		[Roles.customerRelationDepartement]: [Roles.messageController],
		[Roles.regionalOrDevelopmentManager]: [Roles.messageController],
	};

	static readonly permissions: RolePermission[] = [
		{
			role: Roles.superAdmin,
			featurePermissions: [FeaturePermissions.AllAccess],
		},
		{
			role: Roles.admin,
			featurePermissions: [
				FeaturePermissions.AnnouncementsReadOnly,
				FeaturePermissions.HealthCentersReadOnly,
				FeaturePermissions.ImpersonationReadOnly,
				FeaturePermissions.MedicalTechnicalAdvisorMessagingReadOnly,
				FeaturePermissions.PatientListingAdmin,
			],
		},
		{
			role: Roles.administrative,
			featurePermissions: [
				FeaturePermissions.ImpersonationReadOnly,
				FeaturePermissions.MedicalTechnicalAdvisorMessagingReadOnly,
				FeaturePermissions.PatientListingAdmin,
			],
		},
		{
			role: Roles.customerRelationDepartement,
			featurePermissions: [
				FeaturePermissions.ImpersonationReadOnly,
				FeaturePermissions.MedicalTechnicalAdvisorMessagingReadOnly,
				FeaturePermissions.PatientListingAdmin,
			],
		},
		{
			role: Roles.medicalTechnicalAdvisor,
			featurePermissions: [
				FeaturePermissions.ImpersonationReadOnly,
				FeaturePermissions.MedicalTechnicalAdvisorMessagingReadOnly,
				FeaturePermissions.PatientListingAdmin,
				FeaturePermissions.UsersReadOnly,
			],
		},
		{
			role: Roles.mediviewLocalAdminAsdia,
			featurePermissions: [],
		},
		{
			role: Roles.mediviewLocalAdminElivie,
			featurePermissions: [],
		},
		{
			role: Roles.messageController,
			featurePermissions: [FeaturePermissions.MessageControllerReadWrite],
		},
		{
			role: Roles.prescriber,
			featurePermissions: [
				FeaturePermissions.PatientCareFormReadOnly,
				FeaturePermissions.PrescriberMessagingReadOnly,
			],
		},
		{
			role: Roles.regionalOrDevelopmentManager,
			featurePermissions: [
				FeaturePermissions.ImpersonationReadOnly,
				FeaturePermissions.MedicalTechnicalAdvisorMessagingReadOnly,
				FeaturePermissions.PatientListingAdmin,
				FeaturePermissions.UsersReadOnly,
			],
		},
		{
			role: Roles.secretary,
			featurePermissions: [
				FeaturePermissions.PatientCareFormReadOnly,
				FeaturePermissions.PrescriberMessagingReadOnly,
			],
		},
	];

	public static isCompatible(role1: string, role2: string): boolean {
		if (!Roles.compatibilities[role1]) {
			return false;
		}

		return Roles.compatibilities[role1].includes(role2);
	}
}

export class RestrictAccessMessages {
	static readonly adminMtaAdministrative: string =
		"Cette page n'est pas accessible pour les rôles Administratif, CMT, D.R. / R.DEV. et SRC";
	static readonly impersonated: string = "Fonctionnalité indisponible en impersonation";
}
