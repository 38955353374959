<div class="listing-patient" [ngClass]="{ 'table-responsive-lg': tableSettings.isLastButtonActions }">
	<div class="bg-white table-rounded pb-3 px-3 pt-1 position-relative">
		<div
			*ngIf="isTableLoading"
			class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
			style="z-index: 999; backdrop-filter: blur(6px)">
			<div class="spinner-grow" style="width: 3vw; height: 3vw" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
		<div class="d-flex align-items-center ps-3 mt-2" *ngIf="tableSettings.showSelectAllCheckBox">
			<label class="checkbox-wrap checkbox-primary custom-checkbox ms-2 text-ultra-light">
				<input
					type="checkbox"
					(change)="$event ? selectAll() : null"
					[checked]="selector.hasValue() && isAllSelected()" />
			</label>
			<span class="d-inline-block px-3 text-ultra-light fs-smaller">{{ selectAllLabel }}</span>
			<button
				*ngIf="tableSettings.showSelectAllButton"
				class="btn btn-primary"
				[disabled]="!selector.hasValue() || buttonDisabled"
				(click)="riseSelectedRows()">
				{{ buttonLabel }}
			</button>
		</div>

		<table
			class="no"
			cdk-table
			matSort
			[matSortActive]="sortActiveColumn"
			matSortDisableClear
			[matSortDirection]="sortActiveDirection"
			[dataSource]="items"
			class="table table-striped align-middle"
			[ngClass]="{ 'table-hover': !tableSettings.isLastButtonActions }">
			<colgroup>
				<col
					*ngFor="let displayedColumn of tableSettings.displayedColumns"
					span="1"
					[style.width]="tableSettings.getColumnWidth(displayedColumn)" />
			</colgroup>

			<!-- No rows -->
			<tr class="mat-row" *matNoDataRow>
				<td
					*ngIf="!isTableFirstLoading"
					class="mat-cell"
					[attr.colspan]="tableSettings.displayedColumns.length">
					{{ noRowsLabel }}
				</td>
			</tr>

			<ng-container
				[cdkColumnDef]="displayedColumn"
				*ngFor="let displayedColumn of tableSettings.displayedColumns; let i = index">
				<!--Main headers -->
				<th cdk-header-cell *cdkHeaderCellDef class="text-center">
					<div
						*ngIf="
							!tableSettings.showMultiHeader ||
							tableSettings.singleColumnNames.indexOf(displayedColumn) === -1
						"
						[class.h-70p]="!tableSettings.columns[displayedColumn].subDisplayName">
						<div
							*ngIf="
								tableSettings.columns[displayedColumn].sortTableColumnType ===
								sortTableColumnTypes.order
							"
							class="d-flex flex-column justify-content-start bg-white rounded p-2 shadow position-relative h-100">
							<div class="d-flex justify-content-between">
								<button
									type="button"
									class="btn pin-circle me-2 position-relative align-self-start z-index-1"
									[ngClass]="{ asc: tableSettings.columns[displayedColumn].sortDirection === 'asc' }"
									(click)="onSortClicked(displayedColumn, tableSettings.columns[displayedColumn])">
									<i class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
								</button>

								<span
									class="text-medium fs-7 align-self-center lh-17"
									[ngClass]="{
										'laptop text-break':
											tableSettings.columns[displayedColumn].displayName === 'Responsable',
									}">
									{{ tableSettings.columns[displayedColumn].displayName }}
								</span>
								<app-table-filter
									[columnName]="tableSettings.columns[displayedColumn].sortTableColumnName"
									[filterType]="tableSettings.columns[displayedColumn].filterTableColumnType"
									[filterColumn]="tableSettings.columns[displayedColumn].filterTableColumnName"
									[filterChoice]="tableSettings.columns[displayedColumn].filterTableColumnTypeChoices"
									(filter)="onFilterChange(displayedColumn, $event)"
									[filterChanges$]="filterChanges$"
									class="align-self-start"></app-table-filter>
							</div>

							<div
								*ngIf="tableSettings.columns[displayedColumn].subDisplayName"
								class="d-flex justify-content-center ls-96">
								<span class="text-nowrap text-center">
									{{ tableSettings.columns[displayedColumn].subDisplayName }}
								</span>
							</div>
						</div>

						<div
							*ngIf="
								tableSettings.columns[displayedColumn].sortTableColumnType ===
									sortTableColumnTypes.none ||
								tableSettings.columns[displayedColumn].sortTableColumnType ===
									sortTableColumnTypes.noneWithShadow
							"
							style="height: 45px !important"
							[ngClass]="{
								'd-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100':
									tableSettings.columns[displayedColumn].sortTableColumnType ===
									sortTableColumnTypes.noneWithShadow,
							}">
							<span class="text-medium fs-7 align-self-center flew-grow-1 flex-fill lh-17">
								{{ tableSettings.columns[displayedColumn].displayName }}
							</span>
							<div
								*ngIf="tableSettings.columns[displayedColumn].subDisplayName"
								class="d-flex justify-content-center">
								<span class="text-nowrap text-center">
									{{ tableSettings.columns[displayedColumn].subDisplayName }}
								</span>
							</div>
						</div>

						<div
							*ngIf="
								tableSettings.columns[displayedColumn].sortTableColumnType ===
								sortTableColumnTypes.filterOnly
							"
							class="d-flex justify-content-between bg-white rounded p-2 shadow position-relative h-100"
							[ngClass]="{
								'laptop wpx-126': tableSettings.columns[displayedColumn].displayName === 'Documents',
							}">
							<button
								type="button"
								disabled
								class="btn pin-circle me-2 position-relative align-self-center z-index-1"
								[ngClass]="{ asc: tableSettings.columns[displayedColumn].sortDirection === 'asc' }"
								(click)="onSortClicked(displayedColumn, tableSettings.columns[displayedColumn])">
								<i class="icon-filtre position-absolute top-50 start-50 translate-middle"></i>
							</button>

							<span
								class="text-medium fs-7 align-self-center lh-17"
								[ngClass]="{
									'text-truncate': tableSettings.columns[displayedColumn].displayName === 'Documents',
								}">
								{{ tableSettings.columns[displayedColumn].displayName }}
							</span>
							<div
								*ngIf="tableSettings.columns[displayedColumn].subDisplayName"
								class="d-flex justify-content-between">
								<span class="text-nowrap text-center">
									{{ tableSettings.columns[displayedColumn].subDisplayName }}
								</span>
							</div>
							<app-table-filter
								[columnName]="tableSettings.columns[displayedColumn].sortTableColumnName"
								[filterType]="tableSettings.columns[displayedColumn].filterTableColumnType"
								[filterColumn]="tableSettings.columns[displayedColumn].filterTableColumnName"
								[filterChoice]="tableSettings.columns[displayedColumn].filterTableColumnTypeChoices"
								(filter)="onFilterChange(displayedColumn, $event)"
								[filterChanges$]="filterChanges$"></app-table-filter>
						</div>

						<div
							*ngIf="
								tableSettings.columns[displayedColumn].sortTableColumnType ===
								sortTableColumnTypes.select
							"></div>
					</div>
				</th>

				<!-- Main rows -->
				<td cdk-cell *cdkCellDef="let row; let rowNumber = index" class="position-relative">
					<!-- skeleton on first load -->
					<ng-container *ngIf="isTableFirstLoading">
						<div
							style="
								background-color: #e3e3e3;
								background: linear-gradient(
									90deg,
									rgba(227, 227, 227, 1) 0%,
									rgba(227, 227, 227, 1) 35%,
									rgba(230, 230, 230, 1) 100%
								);
								border-radius: 0.5em;
							">
							&nbsp;
						</div>
					</ng-container>
					<ng-container *ngIf="!isTableFirstLoading">
						<div
							class="d-flex align-items-center"
							[ngClass]="{
								isGroupedHeaderColumnFirstColumnOnSpecificPosition:
									tableSettings.isGroupedHeaderColumnFirstColumnOnSpecificPosition(
										displayedColumn,
										1
									),
								'justify-content-center align-items-center':
									!tableSettings.columns[displayedColumn].alignLeft,
								'justify-content-left align-items-left':
									tableSettings.columns[displayedColumn].alignLeft,
								'laptop wpx-140':
									tableSettings.columns[displayedColumn].displayName === 'Objet du message' ||
									tableSettings.columns[displayedColumn].displayName === 'Contenu du message',
							}"
							[ngSwitch]="tableSettings.columns[displayedColumn].formatTableColumnType">
							<span
								class="text-nowrap"
								style="align-items: center; display: flex; flex-flow: column"
								*ngSwitchCase="formatTableColumnTypes.date">
								<span>{{ row[displayedColumn].value | date: "dd/MM/yyyy" }}</span>
								<span *ngIf="row[displayedColumn].subValue">
									{{ row[displayedColumn].subValue }}
								</span>
							</span>

							<span
								class="text-nowrap"
								style="align-items: center; display: flex; flex-flow: column"
								*ngSwitchCase="formatTableColumnTypes.dateTime">
								<ng-container *ngIf="row[displayedColumn].value !== null; else noDateTimeValue">
									{{ row[displayedColumn].value | date: "dd/MM/yyyy H:mm:ss" }}
								</ng-container>

								<ng-template #noDateTimeValue> - </ng-template>
							</span>

							<span
								*ngSwitchCase="formatTableColumnTypes.dateRangeNullable"
								class="text-nowrap text-bold"
								[ngClass]="row[displayedColumn].textColor"
								[ngStyle]="{
									'flex-flow':
										row[displayedColumn].value.endDate === null ||
										row[displayedColumn].value.endDate === undefined
											? ''
											: 'column',
								}"
								style="align-items: center; display: flex; height: 40px">
								<ng-container
									*ngIf="
										row[displayedColumn].value.endDate === null ||
											row[displayedColumn].value.endDate === undefined;
										else dateRange
									">
									<span
										>À partir du
										{{ row[displayedColumn].value.startDate | date: "dd/MM/yyyy" }}</span
									>
									<span *ngIf="row[displayedColumn].subValue">
										{{ row[displayedColumn].subValue }}
									</span>
								</ng-container>

								<ng-template #dateRange>
									<span>Du {{ row[displayedColumn].value.startDate | date: "dd/MM/yyyy" }}</span>
									<span *ngIf="row[displayedColumn].value.endDate">
										au {{ row[displayedColumn].value.endDate | date: "dd/MM/yyyy" }}
									</span>
									<span *ngIf="row[displayedColumn].subValue">
										{{ row[displayedColumn].subValue }}
									</span>
								</ng-template>
							</span>

							<span
								[ngClass]="row[displayedColumn].textColor"
								class="text-nowrap text-bold"
								style="align-items: center; display: flex; flex-flow: column"
								*ngSwitchCase="formatTableColumnTypes.dateRange">
								<span>Du {{ row[displayedColumn].value.startDate | date: "dd/MM/yyyy" }}</span>
								<span *ngIf="row[displayedColumn].value.endDate">
									au {{ row[displayedColumn].value.endDate | date: "dd/MM/yyyy" }}
								</span>
								<span *ngIf="row[displayedColumn].subValue">
									{{ row[displayedColumn].subValue }}
								</span>
							</span>

							<span
								class="prescription traitement text-center"
								title="{{ row[displayedColumn].value }}"
								*ngSwitchCase="formatTableColumnTypes.none">
								{{ row[displayedColumn].value }}
							</span>

							<ng-container *ngSwitchCase="formatTableColumnTypes.documents">
								<div *ngIf="row[displayedColumn].value" style="justify-content: center">
									<div *ngFor="let item of row[displayedColumn].value">
										<a
											href="{{ item.link }}"
											target="_blank'"
											title="{{ item.fileName }}"
											class="btn pin-circle text-decoration-none m-1">
											<i
												class="position-absolute top-50 start-50 translate-middle dm-complet icon-fiche-obs"></i>
										</a>
									</div>
								</div>
							</ng-container>

							<div
								class="text-nowrap justify-content-left align-items-left"
								style="display: flex"
								*ngSwitchCase="formatTableColumnTypes.text">
								<span
									class="text-truncate text-truncate-responsive"
									title="{{ row[displayedColumn].value }}">
									{{ row[displayedColumn].value }}
								</span>
							</div>

							<div
								class="text-nowrap justify-content-left align-items-left"
								style="display: flex"
								*ngSwitchCase="formatTableColumnTypes.select">
								<label class="checkbox-wrap checkbox-primary custom-checkbox">
									<input
										type="checkbox"
										(click)="$event.stopPropagation()"
										(change)="$event ? selectRow(row) : null"
										[checked]="selector.isSelected(row)"
										[disabled]="!row[displayedColumn].value" />
								</label>
							</div>

							<div
								class="justify-content-left align-items-left"
								style="display: flex"
								*ngSwitchCase="formatTableColumnTypes.readMoreText">
								<app-read-more
									[title]="row[displayedColumn].value.toolType ?? ''"
									[onExpandReadMore]="onExpandReadMore"
									[id]="row[displayedColumn].value.id"
									[defaultExpand]="defaultExpand"
									[text]="row[displayedColumn].value.content" />
							</div>

							<div
								class="text-nowrap"
								style="align-items: center; display: flex; flex-flow: column"
								*ngSwitchCase="formatTableColumnTypes.twoLines">
								<span *ngIf="row[displayedColumn].value.firstLine" class="text-bold">{{
									row[displayedColumn].value.firstLine
								}}</span>
								<span *ngIf="row[displayedColumn].value.firstLineAsDate" class="text-bold">{{
									row[displayedColumn].value.firstLineAsDate | date: "dd/MM/yyyy"
								}}</span>
								<span *ngIf="row[displayedColumn].value.secondLine">{{
									row[displayedColumn].value.secondLine
								}}</span>
								<span *ngIf="row[displayedColumn].value.secondLineAsDate">{{
									row[displayedColumn].value.secondLineAsDate | date: "dd/MM/yyyy"
								}}</span>
							</div>

							<span
								*ngSwitchCase="formatTableColumnTypes.title"
								[ngClass]="
									tableSettings.columns[displayedColumn].displayName === 'Objet du message'
										? 'text-wrap'
										: 'text-nowrap'
								">
								{{ row[displayedColumn].value | titlecase }}
							</span>

							<span
								class="text-truncate text-truncate-responsive"
								title="{{ row[displayedColumn].value | uppercase }}"
								*ngSwitchCase="formatTableColumnTypes.upper">
								{{ row[displayedColumn].value | uppercase }}
							</span>

							<span
								class="text-truncate text-truncate-responsive"
								title="{{ row[displayedColumn].value }}"
								*ngSwitchCase="formatTableColumnTypes.comments">
								{{ row[displayedColumn].value }}
							</span>

							<ng-container *ngSwitchCase="formatTableColumnTypes.tag">
								<ng-container *ngIf="row[displayedColumn]" class="filters-added w-100 pb-2 d-flex">
									<ng-container class="" *ngFor="let role of row[displayedColumn].value">
										<span
											class="rounded-pill p-1 ps-2 me-2 d-flex"
											style="padding-left: 10px; white-space: nowrap"
											[ngClass]="{ 'bg-odd': rowNumber % 2 !== 0 }">
											{{ role }}
										</span>
										<br />
									</ng-container>
								</ng-container>
							</ng-container>

							<ng-container *ngSwitchCase="formatTableColumnTypes.treatmentTag">
								<ng-container *ngIf="row[displayedColumn]" class="filters-added w-100 pb-2 d-flex">
									<ng-container class="" *ngFor="let treatmentTag of row[displayedColumn].value">
										<span
											class="rounded-pill p-1 ps-2 me-2 d-flex"
											style="padding-left: 10px; white-space: nowrap"
											[ngStyle]="{ 'background-color': treatmentTag.color }">
											{{ treatmentTag.name }}
										</span>
										<br />
									</ng-container>
								</ng-container>
							</ng-container>

							<ng-container *ngSwitchCase="formatTableColumnTypes.duration">
								<div
									[ngClass]="{
										'obs-black':
											row[displayedColumn].value.code === observanceLevel.notMonitored ||
											row[displayedColumn].value.code === observanceLevel.noTransmission,
										'obs-red': row[displayedColumn].value.code === observanceLevel.ge001h_lt2h,
										'obs-orange': row[displayedColumn].value.code === observanceLevel.ge2h_lt4h,
										'obs-green': row[displayedColumn].value.code === observanceLevel.ge4h,
										'obs-purple': row[displayedColumn].value.code === observanceLevel.lt001h,
									}">
									<div>
										<ng-container [ngSwitch]="row[displayedColumn].value.codeforDisplay">
											<span
												*ngSwitchCase="observanceLevel.initialPeriod"
												class="text-nowrap ps-4">
												Période initiale
											</span>

											<span *ngSwitchCase="observanceLevel.notMonitored" class="text-nowrap ps-4">
												Non monitoré
											</span>

											<span
												*ngSwitchCase="observanceLevel.noTransmission"
												class="text-nowrap ps-4">
												Pas de transmission
											</span>

											<span *ngSwitchCase="observanceLevel.lt001h" class="text-nowrap"> 0h </span>

											<span *ngSwitchDefault>
												{{
													row[displayedColumn].value.duration | durationFormat: "min" : "hhmm"
												}}
											</span>
										</ng-container>
									</div>
								</div>
							</ng-container>

							<ng-container *ngSwitchCase="formatTableColumnTypes.messageStatus">
								<div
									*ngIf="row[displayedColumn].value"
									class="text-nowrap p-1"
									style="align-items: center; display: flex; flex-flow: column"
									[ngClass]="getMessageStatusClass(row[displayedColumn].value)">
									<span class="text-nowrap pt-1">{{
										getMessageDisplayStatus(row[displayedColumn].value)
									}}</span>
								</div>
							</ng-container>

							<ng-container *ngSwitchCase="formatTableColumnTypes.prescriptionStatus">
								<div
									*ngIf="row[displayedColumn].value"
									class="text-nowrap p-1"
									style="align-items: center; display: flex; flex-flow: column"
									[ngClass]="{
										'prescription-orange':
											row[displayedColumn].value === prescriptionSigningStatus.toSignEarly ||
											row[displayedColumn].value === prescriptionSigningStatus.toSignFuture,
										'prescription-red':
											row[displayedColumn].value === prescriptionSigningStatus.toSignPast,
										'prescription-green':
											row[displayedColumn].value === prescriptionSigningStatus.upToDate ||
											row[displayedColumn].value === prescriptionSigningStatus.inProgress,
									}">
									<span class="text-nowrap pt-1">{{
										getDisplayStatus(row[displayedColumn].value)
									}}</span>
								</div>
							</ng-container>

							<span
								*ngSwitchCase="formatTableColumnTypes.displayPerson"
								[ngClass]="
									row[displayedColumn].value.hideInitials
										? 'd-flex align-items-center justify-content-center'
										: 'flex-grow-1 d-flex flex-column'
								">
								<a
									[routerLink]="row[displayedColumn].value.link"
									class="d-flex text-decoration-none cs-pointer"
									[class.disabled]="row[displayedColumn].value.link === null"
									title="{{ row[displayedColumn].value.tooltip }}">
									<span
										class="initiales pin-circle white me-4 text-medium align-self- z-index-1"
										*ngIf="!row[displayedColumn].value.hideInitials"
										>{{ row[displayedColumn].value.shortName | uppercase }}
									</span>
									<div class="d-flex flex-column mw-12vw">
										<div class="d-flex flex-wrap">
											<div class="text-bold float-start">
												{{ row[displayedColumn].value.familyName | uppercase }}&nbsp;
											</div>
											<span class="inline-flex">{{
												row[displayedColumn].value.givenName | titlecase
											}}</span>
										</div>
										<span class="flex-grow-1" *ngIf="row[displayedColumn].value.patientId">
											{{ row[displayedColumn].value.patientId }}
										</span>
									</div>
								</a>
							</span>

							<span
								class="text-nowrap w-100"
								*ngSwitchCase="formatTableColumnTypes.patientInterventionType">
								<a
									[class.disabled]="!row[displayedColumn].value.isLinkVisible"
									[routerLink]="row[displayedColumn].value.link"
									class="d-flex align-self-center text-decoration-none cs-pointer"
									title="{{ row[displayedColumn].value.tooltip }}">
									<span
										[style.visibility]="
											!row[displayedColumn].value.treatmentShortLabel ? 'hidden' : ''
										"
										class="initiales pin-circle white me-4 text-medium align-self- z-index-1"
										>{{ row[displayedColumn].value.treatmentShortLabel | uppercase }}
									</span>
									<div class="flex-grow-1 d-flex flex-column">
										<span class="text-nowrap">
											{{ row[displayedColumn].value.eventType | titlecase }}
										</span>
										<span class="flex-grow-1" *ngIf="row[displayedColumn].value.patientId">
											{{ row[displayedColumn].value.patientId }}
										</span>
									</div>
								</a>
							</span>

							<div class="d-flex w-100" *ngSwitchCase="formatTableColumnTypes.interaction">
								<div class="col-7 align-self-center text-center">
									<span>{{ row[displayedColumn].value.eventDisplayName }}</span>
								</div>
								<div
									class="col-5"
									*ngIf="row[displayedColumn].value && row[displayedColumn].value.documents">
									<app-secured-file-link
										*ngFor="let document of row[displayedColumn].value.documents.documentItems"
										[documentId]="document.id"
										[documentDate]="document.date"
										[documentName]="document.filename"
										[documentType]="document.type"></app-secured-file-link>
								</div>
							</div>

							<span class="text-wrap w-100" *ngSwitchCase="formatTableColumnTypes.device">
								<div class="flex-grow-1 align-self-center">
									{{ row[displayedColumn].value.provider | titlecase }}
									<span class="text-bold">{{ row[displayedColumn].value.name | uppercase }}</span>
								</div>
							</span>

							<div class="d-flex" *ngSwitchCase="formatTableColumnTypes.isLocked">
								<div class="align-self-center text-center">
									<span *ngIf="!row[displayedColumn].value" class="obs-green"> Actif </span>
									<span *ngIf="row[displayedColumn].value" class="text-medium obs-red">
										Inactif
									</span>
								</div>
							</div>

							<div class="d-flex" *ngSwitchCase="formatTableColumnTypes.isLocalAdmin">
								<div class="align-self-center text-center">
									<span *ngIf="row[displayedColumn].value === true" class="text-medium">
										Administrateur
									</span>
									<span *ngIf="row[displayedColumn].value === false"> Membre </span>
								</div>
							</div>

							<div
								class="text-nowrap"
								style="align-items: center; display: flex; flex-flow: column"
								*ngSwitchCase="formatTableColumnTypes.twoLinesAddress">
								<span *ngIf="row[displayedColumn].value.firstLine">{{
									row[displayedColumn].value.firstLine
								}}</span>
								<span *ngIf="row[displayedColumn].value.firstLineAsDate">{{
									row[displayedColumn].value.firstLineAsDate
								}}</span>
								<span *ngIf="row[displayedColumn].value.secondLine" class="text-bold">{{
									row[displayedColumn].value.secondLine
								}}</span>
							</div>

							<div
								class="progress position-relative"
								*ngSwitchCase="formatTableColumnTypes.durationProgress">
								<div
									*ngIf="
										row[displayedColumn].value !== undefined && row[displayedColumn].value !== null
									"
									class="progress-bar text-light"
									[ngClass]="getDurationProgressClass(row)"
									role="progressbar"
									[attr.ariaValuenow]="getPercentage(row)"
									aria-valuemin="0"
									aria-valuemax="100">
									<span
										*ngIf="getHighState(row)"
										class="position-absolute top-50 start-50 translate-middle">
										{{ row[displayedColumn].value | durationFormat: "min" : "hhmm" }}
									</span>
									<ng-container *ngIf="!getHighState(row)">
										<span
											*ngIf="getPercentage(row) <= 33 && getPercentage(row) > 5"
											class="text-orange text-regular position-absolute top-50 start-50 translate-middle">
											{{ row[displayedColumn].value | durationFormat: "min" : "hhmm" }}
										</span>
										<span
											*ngIf="getPercentage(row) === 5"
											class="text-red text-regular position-absolute top-50 start-50 translate-middle">
											0h 00 min
										</span>
										<span *ngIf="getPercentage(row) > 33">
											{{ row[displayedColumn].value | durationFormat: "min" : "hhmm" }}
										</span>
									</ng-container>
								</div>
							</div>

							<div *ngSwitchCase="formatTableColumnTypes.actions">
								<ng-container *ngIf="row[displayedColumn]">
									<ng-container *ngFor="let action of row[displayedColumn].value">
										<a
											*ngIf="action.type === typeColumnAction.link"
											[routerLink]="action.link"
											[queryParams]="action.params"
											target="{{ action.targetBlank ? '_blank' : '_self' }}"
											title="{{ action.title ? action.title : 'Voir' }}"
											[class.disabled]="action.disabled"
											class="btn pin-circle me-2 {{ action.iconClass }}">
											<i
												[ngClass]="{ 'icon-see': !action.icon }"
												class="{{
													action.icon
												}} position-absolute top-50 start-50 translate-middle"></i>
										</a>
										<button
											*ngIf="action.type === typeColumnAction.button"
											(click)="callFunction(action.action)"
											type="button"
											title="{{ action.title ? action.title : 'Voir' }}"
											class="btn wesh pin-circle me-1 me-xxl-2 mb-1 mb-xxl-2 {{
												action.iconClass
											}}"
											[class.disabled]="action.disabled">
											<i
												[ngClass]="{ 'icon-see': !action.icon }"
												class="{{
													action.icon
												}} position-absolute top-50 start-50 translate-middle"></i>
										</button>
									</ng-container>
								</ng-container>
							</div>
							<div *ngSwitchCase="formatTableColumnTypes.actionsWithLabel">
								<ng-container *ngIf="row[displayedColumn]">
									<ng-container *ngFor="let action of row[displayedColumn].value">
										<span class="text-nowrap w-100">
											{{
												action.type === typeColumnAction.button && action.title !== null
													? (action.title | date: "dd/MM/yyyy" | titlecase)
													: ""
											}}
											<span>&nbsp;</span>
											<button
												(click)="callFunction(action.action)"
												type="button"
												title="Voir l'historique"
												class="btn pin-circle me-2">
												<i
													[ngClass]="{ 'icon-see': !action.icon }"
													class="{{
														action.icon
													}} position-absolute top-50 start-50 translate-middle"></i>
											</button>
										</span>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</td>
			</ng-container>
			<!-- Filters header rows -->
			<ng-container [cdkColumnDef]="filteredColumn" *ngFor="let filteredColumn of tableSettings.filteredColumns">
				<th cdk-header-cell *cdkHeaderCellDef>
					<ng-container
						*ngIf="filteredColumn.replace('Filter', '') as columnName"
						[ngSwitch]="tableSettings.columns[columnName].filterTableColumnType">
						<div
							*ngIf="
								tableSettings.columns[columnName].filterTableColumnType !== filterTableColumnTypes.none
							"
							class="filters-added w-100 pb-2 d-flex">
							<ng-container *ngFor="let filter of currentFilters">
								<ng-container
									*ngIf="filter.name === tableSettings.columns[columnName].filterTableColumnName">
									<button
										type="button"
										(click)="removeFilter(filter)"
										class="rounded-pill bg-white p-1 ps-2 me-2 d-flex">
										<span class="text-gray me-2 text-truncate"
											>{{ getDisplayNameValueFilter(filter.value, columnName) }}
										</span>
										<i class="icon-closed text-gray fs-5-closed"></i>
									</button>
								</ng-container>
							</ng-container>
						</div>
					</ng-container>
				</th>
			</ng-container>
			<!-- Multi header rows -->
			<ng-container [cdkColumnDef]="multiColumn" *ngFor="let multiColumn of tableSettings.displayedMultiColumns">
				<th
					cdk-header-cell
					*cdkHeaderCellDef
					[attr.colspan]="tableSettings.multiColumns[multiColumn.replace('Multi', '')].columnNames.length"
					class="text-center">
					<ng-container *ngIf="multiColumn.replace('Multi', '') as multiColumnName">
						<div style="display: flex; justify-content: center">
							<ng-container
								*ngIf="tableSettings.singleMultiSortableColumnNames[multiColumnName] as columnName">
								<ng-container
									*ngIf="
										tableSettings.columns[columnName].sortTableColumnType ===
										sortTableColumnTypes.order
									">
									<button
										(click)="onSortClicked(columnName, tableSettings.columns[columnName])"
										class="pin-sort btn position-relative"
										type="button"
										aria-label="sort-asc">
										<i
											class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
									</button>
									<button
										aria-label="sort-desc"
										(click)="onSortClicked(columnName, tableSettings.columns[columnName])"
										class="pin-sort btn position-relative"
										type="button">
										<i
											class="icon-pagination-up position-absolute top-50 start-50 translate-middle"></i>
									</button>
								</ng-container>
							</ng-container>
							{{ tableSettings.multiColumns[multiColumnName].displayName }}
						</div>
					</ng-container>
				</th>
			</ng-container>

			<thead class="thead-primary">
				<ng-container *ngIf="tableSettings.showMultiHeader">
					<tr cdk-header-row *cdkHeaderRowDef="tableSettings.displayedMultiColumns" class=""></tr>
				</ng-container>
				<tr cdk-header-row *cdkHeaderRowDef="tableSettings.displayedColumns" class=""></tr>
				<ng-container *ngIf="tableSettings.showFilters">
					<tr cdk-header-row *cdkHeaderRowDef="tableSettings.filteredColumns" class="alert"></tr>
				</ng-container>
			</thead>

			<tbody>
				<tr
					cdk-row
					*cdkRowDef="let row; columns: tableSettings.displayedColumns"
					[ngClass]="getClassByState(row)"></tr>
			</tbody>
		</table>

		<mat-paginator
			[length]="nbFilteredItems"
			[pageSize]="paginatorPageSize"
			[pageSizeOptions]="paginatorPageSizeOptions"
			showFirstLastButtons
			[hidden]="!showPaginator"
			aria-label="{paginatorLabel}"
			class="custom-paginator">
		</mat-paginator>
	</div>
</div>
