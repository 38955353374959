import { Component, Input } from "@angular/core";
import { TableCellPatientInterventionType } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-patient-intervention-type",
	templateUrl: "./table-cell-patient-intervention-type.component.html",
})
export class TableCellPatientInterventionTypeComponent {
	@Input() item?: TableCellPatientInterventionType;
}
