import { Component, Input, OnInit } from "@angular/core";
import { TableCellStatus } from "src/app/@shared/types/table/table-view";

@Component({
	selector: "app-table-cell-status",
	templateUrl: "./table-cell-status.component.html",
})
export class TableCellStatusComponent implements OnInit {
	@Input() item?: TableCellStatus;

	valueItems: string[] = ["Valide", "Désactivé"];

	ngOnInit(): void {
		if (this.item?.valueItems != null) {
			this.valueItems = this.item.valueItems;
		}
	}
}
