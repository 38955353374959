import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export interface SerializableUserConnection {
	almostExpirationDate: string;
	connectionDate: string;
	expirationDate: string;
	extensionDate: string | null;
	userConnectionId: string;
}

export class UserConnection implements SerializableUserConnection {
	static readonly almostExpirationDateNbMinutes = 5;

	constructor(
		public almostExpirationDate: string,
		public connectionDate: string,
		public expirationDate: string,
		public extensionDate: string | null,
		public userConnectionId: string
	) {}

	isExpired(): boolean {
		if (moment(this.expirationDate) <= moment.utc()) return true;
		return false;
	}

	isAlmostExpired(): boolean {
		if (moment(this.almostExpirationDate) <= moment.utc()) return true;
		return false;
	}

	extends(userSessionDurationNbMinutes: number): void {
		// Current date
		const extensionDate = UserConnection.getCurrentDate();
		// Current date + 1 day
		const expirationDate = UserConnection.getExpirationDate(userSessionDurationNbMinutes);
		// Expiration date - 5 minutes
		const almostExpirationDate = UserConnection.getAlmostExpirationDate(expirationDate);

		this.extensionDate = extensionDate.toISOString();
		this.expirationDate = expirationDate.toISOString();
		this.almostExpirationDate = almostExpirationDate.toISOString();
	}

	private static getCurrentDate(): moment.Moment {
		return moment.utc();
	}

	private static getExpirationDate(userSessionDurationNbMinutes: number): moment.Moment {
		return moment.utc().add(userSessionDurationNbMinutes, "minutes");
	}

	private static getAlmostExpirationDate(expirationDate: moment.Moment): moment.Moment {
		return moment(expirationDate).subtract(UserConnection.almostExpirationDateNbMinutes, "minutes");
	}

	static create(userSessionDurationNbMinutes: number): UserConnection {
		// Current date
		const connectionDate = UserConnection.getCurrentDate();
		// Current date + 1 day
		const expirationDate = UserConnection.getExpirationDate(userSessionDurationNbMinutes);
		// Expiration date - 5 minutes
		const almostExpirationDate = UserConnection.getAlmostExpirationDate(expirationDate);

		return new UserConnection(
			almostExpirationDate.toISOString(),
			connectionDate.toISOString(),
			expirationDate.toISOString(),
			null,
			uuidv4()
		);
	}

	static deserialize(userConnection: SerializableUserConnection): UserConnection | null {
		if (!userConnection) return null;

		return new UserConnection(
			userConnection.almostExpirationDate,
			userConnection.connectionDate,
			userConnection.expirationDate,
			userConnection.extensionDate,
			userConnection.userConnectionId
		);
	}

	static serialize(userConnection: UserConnection): SerializableUserConnection {
		return userConnection;
	}
}
