import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthService } from "src/app/services";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private _authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this._authService.hasValidAccessToken) {
			request = request.clone({
				headers: request.headers.set("Authorization", "Bearer " + this._authService.accessToken),
			});
		}

		return next.handle(request);
	}
}
