import { DatePipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {
	APP_INITIALIZER,
	ErrorHandler,
	LOCALE_ID,
	isDevMode,
	ApplicationConfig,
	provideZoneChangeDetection,
} from "@angular/core";
import { Router, provideRouter } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { OAuthStorage, ValidationHandler, provideOAuthClient } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from "ngx-webstorage";
import localeFr from "@angular/common/locales/fr";

// Services
import {
	AgreementsService,
	AnalysesService,
	AppConfigService,
	AppVersionService,
	AuthService,
	ExchangeService,
	FilesService,
	MedicalUsersService,
	NotificationService,
	ObservanceService,
	PairingService,
	PrescribersService,
	ScriptsService,
	SeoService,
	StorageService,
	UrlService,
	UserProfileService,
	UtilsService,
} from "./services";
// Interceptors
import { ApiPrefixInterceptor, AuthInterceptor, ErrorHandlerInterceptor, LoggingInterceptor } from "./interceptors";
// Guards
import { PermissionsService } from "./guards";
// Handlers
import { GlobalErrorHandler } from "./handlers";

import { RespiratoryMedicalUsersService } from "./services/respiratory/respiratory-medical-users.service";
import { routes } from ".";
import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer, provideMarkdown } from "ngx-markdown";

registerLocaleData(localeFr);

const appInitializerFn = (configService: AppConfigService, authService: AuthService): (() => Promise<boolean>) => {
	return () => authService.configureStartup(configService.appConfig);
};
export function storageFactory(): OAuthStorage {
	return localStorage;
}

export function markedOptionsFactory(): MarkedOptions {
	const renderer = new MarkedRenderer();
	const linkRenderer = renderer.link;
	renderer.link = (href, title, text) => {
		const html = linkRenderer.call(renderer, href, title, text);
		return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
	};

	return {
		renderer: renderer,
		gfm: true,
		breaks: true,
		pedantic: false,
	};
}

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideAnimationsAsync(),
		provideHttpClient(withInterceptorsFromDi()),
		provideOAuthClient(),
		provideNgxWebstorage(
			withNgxWebstorageConfig({ prefix: "custom", separator: ".", caseSensitive: true }),
			withLocalStorage()
		),
		provideServiceWorker("ngsw-worker.js", {
			enabled: !isDevMode(),
			registrationStrategy: "registerWhenStable:30000",
		}),
		provideMarkdown({
			markedOptions: {
				provide: MARKED_OPTIONS,
				useFactory: markedOptionsFactory,
			},
		}),
		AgreementsService,
		AnalysesService,
		AppConfigService,
		AppVersionService,
		AuthService,
		DatePipe,
		ExchangeService,
		FilesService,
		MedicalUsersService,
		NotificationService,
		ObservanceService,
		PairingService,
		PermissionsService,
		PrescribersService,
		RespiratoryMedicalUsersService,
		ScriptsService,
		SeoService,
		StorageService,
		UrlService,
		UserProfileService,
		UtilsService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFn,
			multi: true,
			deps: [AppConfigService, AuthService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true,
			deps: [Router, AppConfigService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiPrefixInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoggingInterceptor,
			multi: true,
		},
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{ provide: ValidationHandler, useClass: JwksValidationHandler },
		{ provide: OAuthStorage, useFactory: storageFactory },
		{ provide: LOCALE_ID, useValue: "fr-FR" },
	],
};

// @NgModule({
// 	declarations: [
// 		AppComponent,
// 		ActivityAreaSelectorComponent,
// 		ForbiddenComponent,
// 		HomeComponent,
// 		MaintenanceComponent,
// 		NotFoundComponent,
// 		SigninCallbackComponent,
// 		SignoutCallbackComponent,
// 		UnauthorizedComponent,
// 	],
// 	imports: [
// 		AppRoutingModule,
// 		BrowserAnimationsModule,
// 		BrowserModule,
// 		provideNgxWebstorage(withNgxWebstorageConfig({ prefix: "custom", separator: ".", caseSensitive: true })),
// 		provideServiceWorker("ngsw-worker.js", {
// 			enabled: !isDevMode(),
// 			registrationStrategy: "registerWhenStable:30000",
// 		}),
// 		OAuthModule.forRoot(),
// 		SharedModule,
// 		,
// 	],
// 	providers: [
// 		AgreementsService,
// 		AnalysesService,
// 		AppConfigService,
// 		AppVersionService,
// 		AuthService,
// 		DatePipe,
// 		ExchangeService,
// 		FilesService,
// 		MedicalUsersService,
// 		NotificationService,
// 		ObservanceService,
// 		PairingService,
// 		PermissionsService,
// 		PrescribersService,
// 		RespiratoryMedicalUsersService,
// 		ScriptsService,
// 		SeoService,
// 		StorageService,
// 		UrlService,
// 		UserProfileService,
// 		UtilsService,
// 		{
// 			provide: APP_INITIALIZER,
// 			useFactory: appInitializerFn,
// 			multi: true,
// 			deps: [AppConfigService, AuthService],
// 		},
// 		{
// 			provide: HTTP_INTERCEPTORS,
// 			useClass: ErrorHandlerInterceptor,
// 			multi: true,
// 			deps: [Router, AppConfigService],
// 		},
// 		{
// 			provide: HTTP_INTERCEPTORS,
// 			useClass: AuthInterceptor,
// 			multi: true,
// 		},
// 		{
// 			provide: HTTP_INTERCEPTORS,
// 			useClass: ApiPrefixInterceptor,
// 			multi: true,
// 		},
// 		{
// 			provide: HTTP_INTERCEPTORS,
// 			useClass: LoggingInterceptor,
// 			multi: true,
// 		},
// 		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
// 		{ provide: ValidationHandler, useClass: JwksValidationHandler },
// 		{ provide: OAuthStorage, useFactory: storageFactory },
// 		{ provide: LOCALE_ID, useValue: "fr-FR" },
// 	],
// 	bootstrap: [AppComponent],
// })
// export class AppModule {}
// function provideAnimationsAsync(): import("@angular/core").Provider | import("@angular/core").EnvironmentProviders {
// 	throw new Error("Function not implemented.");
// }
