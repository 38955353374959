import { BaseApiRequest } from "../@shared/base-api-request";

export class CreatePatientCareCommand {
	prescriberId!: string;
	healthCenterId: string | null;
	isAmelinkSignaturePlatform: boolean;
	medicalUserId!: string;
	patient!: CreatePatientCarePatientItem;
	prescriptionO2!: CreatePatientCarePrescriptionO2tItem | null;
	prescriptionPpc!: CreatePatientCarePrescriptionPpctItem | null;
	prescriptionVni!: CreatePatientCarePrescriptionVnitItem | null;
	userRole!: string;

	constructor(
		prescriberId: string,
		healthCenterId: string | null,
		isAmelinkSignaturePlatform: boolean,
		medicalUserId: string,
		patient: CreatePatientCarePatientItem,
		prescriptionO2: CreatePatientCarePrescriptionO2tItem | null,
		prescriptionPpc: CreatePatientCarePrescriptionPpctItem | null,
		prescriptionVni: CreatePatientCarePrescriptionVnitItem | null,
		userRole: string
	) {
		this.prescriberId = prescriberId;
		this.healthCenterId = healthCenterId;
		this.isAmelinkSignaturePlatform = isAmelinkSignaturePlatform;
		this.medicalUserId = medicalUserId;
		this.patient = patient;
		this.prescriptionO2 = prescriptionO2;
		this.prescriptionPpc = prescriptionPpc;
		this.prescriptionVni = prescriptionVni;
		this.userRole = userRole;
	}
}

export interface CreatePatientCarePatientItem {
	address: string;
	birthdate: Date;
	city: string;
	familyName: string;
	gender: string;
	givenName: string;
	healthInsurancePlanType: string;
	phoneNumber: string;
	postalCode: string;
	socialSecurityNumber: string;
}

export interface CreatePatientCarePrescriptionO2tItem {
	additionalInformation: string;
	amelink: boolean;
	arterialBloodGasPaCo2: number | null;
	arterialBloodGasPaO2: number | null;
	arterialBloodGasPh: number | null;
	arterialBloodGasTestDate: Date;
	arterialBloodGasTestType: string;
	arterialBloodGasSaO2: number | null;
	careType: string;
	consumables: string[];
	duration: number | null;
	durationUnit: string;
	equipments: string[];
	flowDay: number | null;
	flowEffort: number | null;
	flowNight: number | null;
	o2Type: string;
	onceRenewable: boolean | null;
	preferredDeviceId: number | null;
	preferredDeviceLabel: string | null;
	preferredDevicePackage: string | null;
	startDate: Date | null;
	timeOfUse: number;
	withAld: boolean;
	withAldOnList: boolean | null;
	withDiscontinuousFlow: boolean | null;
	withWalker: boolean | null;
}

export interface CreatePatientCarePrescriptionPpctItem {
	additionalInformation: string;
	ahiInit: number | null;
	ahiSymptoms: string;
	ahiOtherSymptoms: string;
	aiMax: number | null;
	aiMin: number | null;
	amelink: boolean;
	documentFileId: string;
	frequency: number | null;
	iaInit: number | null;
	isPressureFixed: boolean | undefined;
	pepMax: number | null;
	pepMin: number | null;
	ppcType: string;
	pressureFixed: number | null;
	pressureMax: number | null;
	pressureMin: number | null;
	withAld: boolean | undefined;
	withAldOnList: boolean | undefined;
	withHumidifier: boolean | undefined;
}

export interface CreatePatientCarePrescriptionVnitItem {
	additionalInformation: string;
	amelink: boolean;
	interfaceType: string;
	maskType: string;
	settingsAiMin: number | null;
	settingsAiMax: number | null;
	settingsFrequency: number | null;
	settingsPep: number | null;
	settingsPepMax: number | null;
	settingsPepMin: number | null;
	settingsPip: number | null;
	settingsPipMax: number | null;
	settingsPipMin: number | null;
	settingsSlope: number | null;
	settingsTi: number | null;
	settingsTiMax: number | null;
	settingsTiMin: number | null;
	settingsTriggerExp: string;
	settingsTriggerInsp: string;
	settingsType: string;
	settingsVolume: number | null;
	timeOfUse: string;
	withAld: boolean;
	withAldOnList: boolean | undefined;
	withHumidifier: boolean | undefined;
}

export interface CreatePatientCareResponse {
	prescriptionAmelinkId: string;
	prescriptionDocumentId: string;
}

export class GetDeviceModelsRequest extends BaseApiRequest {
	flowDay!: number;
	flowEffort!: number;
	flowNight!: number;
	withConcentratorEquipment!: boolean;
	withDiscontinuousFlow!: boolean;
	withLiquidEquipment!: boolean;
	withWalker!: boolean;

	constructor(init?: Partial<GetDeviceModelsRequest>) {
		super();
		Object.assign(this, init);
	}
}

export interface GetDeviceModelsResponse {
	deviceModels: GetDeviceModelsItemResponse[];
}

export interface GetDeviceModelsItemResponse {
	id: string;
	name: string;
	package: string;
}
