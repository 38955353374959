import { Permissions } from "./permissions.constant";

export enum Features {
	AllAccess = "AllAccess",
	Announcements = "Announcements",
	HealthCenters = "HealthCenters",
	Impersonation = "Impersonation",
	MedicalTechnicalAdvisorMessaging = "MedicalTechnicalAdvisorMessaging",
	MessageController = "MessageController",
	PatientCareForm = "PatientCareForm",
	Patients = "Patients",
	PrescriberMessaging = "PrescriberMessaging",
	Prescriptions = "Prescriptions",
	Users = "Users",
}

export class FeaturePermission {
	feature!: Features;
	permission!: Permissions;

	constructor(init?: Partial<FeaturePermission>) {
		Object.assign(this, init);
	}
}

export class FeaturePermissions {
	static readonly AllAccess = new FeaturePermission({
		feature: Features.AllAccess,
		permission: Permissions.Admin,
	});
	static readonly AnnouncementsReadOnly = new FeaturePermission({
		feature: Features.Announcements,
		permission: Permissions.ReadOnly,
	});
	static readonly HealthCentersReadOnly = new FeaturePermission({
		feature: Features.HealthCenters,
		permission: Permissions.ReadOnly,
	});
	static readonly ImpersonationReadOnly = new FeaturePermission({
		feature: Features.Impersonation,
		permission: Permissions.ReadOnly,
	});
	static readonly MedicalTechnicalAdvisorMessagingReadOnly = new FeaturePermission({
		feature: Features.MedicalTechnicalAdvisorMessaging,
		permission: Permissions.ReadOnly,
	});
	static readonly MessageControllerReadWrite = new FeaturePermission({
		feature: Features.MessageController,
		permission: Permissions.ReadWrite,
	});
	static readonly PrescriberMessagingReadOnly = new FeaturePermission({
		feature: Features.PrescriberMessaging,
		permission: Permissions.ReadOnly,
	});
	static readonly PatientCareFormReadOnly = new FeaturePermission({
		feature: Features.PatientCareForm,
		permission: Permissions.ReadOnly,
	});
	static readonly PatientListingAdmin = new FeaturePermission({
		feature: Features.Patients,
		permission: Permissions.Admin,
	});
	static readonly UsersReadOnly = new FeaturePermission({
		feature: Features.Users,
		permission: Permissions.ReadOnly,
	});
}
