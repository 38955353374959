<nav
	id="sidebar"
	appClickOutside
	(clickOutside)="openCloseMenu(true)"
	class="rounded shadow"
	[ngClass]="{ active: !isMenuOpen }">
	<div class="h-80 d-flex justify-content-start flex-column">
		<div class="sidebar-header d-flex align-self-start justify-content-center position-relative">
			<button
				(click)="openCloseMenu(false)"
				title="Ouvrir/fermer le menu"
				type="button"
				id="sidebarCollapse"
				class="navbar-btn">
				<i class="icon-close-menu"></i>
				<i class="icon-burger-menu"></i>
				<span class="info-b text-light fadeInLeft align-self-center">Menu</span>
			</button>
			<span
				title="{{ appConfig.version.name }} - {{ appConfig.version.version }}"
				(click)="hackPreview()"
				(keyup.enter)="hackPreview()"
				role="dialog"
				class="logo icon-logo-{{ appConfig.appCompanyName }}">
				<span class="path1"></span><span class="path2"></span><span class="path3"></span
				><span class="path4"></span><span class="path5"></span><span class="path6"></span
				><span class="path7"></span><span class="path8"></span><span class="path9"></span
				><span class="path10"></span><span class="path11"></span><span class="path12"></span
				><span class="path13"></span><span class="path14"></span><span class="path15"></span
				><span class="path16"></span><span class="path17"></span><span class="path18"></span
				><span class="path19"></span><span class="path20"></span><span class="path21"></span
				><span class="path22"></span><span class="path23"></span><span class="path24"></span
				><span class="path25"></span><span class="path26"></span><span class="path27"></span
				><span class="path28"></span><span class="path29"></span><span class="path30"></span
				><span class="path31"></span><span class="path32"></span>
			</span>
		</div>

		<ul class="components">
			<li *ngIf="canShowDashboard" routerLinkActive="active">
				<a [routerLink]="urlDashboard" class="d-flex align-items-center">
					<i class="icon-dashboard align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center"> Tableau&nbsp;de&nbsp;bord </span>
				</a>
			</li>
			<li *ngIf="canShowPatients" routerLinkActive="active">
				<a [routerLink]="urlPatients" class="d-flex align-items-center">
					<i class="icon-patients-menu align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Patients</span>
				</a>
			</li>
			<li *ngIf="canShowPrescriptions" routerLinkActive="active">
				<a [routerLink]="urlPrescriptions" class="d-flex">
					<i class="icon-ordonance align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Prescriptions</span>
				</a>
			</li>
			<li *ngIf="canShowMessaging" routerLinkActive="active">
				<a
					[routerLink]="urlMessaging"
					[matTooltipDisabled]="hasPrescriberMessagingFeaturePermission"
					matTooltipPosition="after"
					matTooltip="{{ this.adminMtaAdministrativeMessage }}"
					class="d-flex"
					style="z-index: 500">
					<i class="icon-message align-self-center"></i>
					<span
						class="text-light fadeInLeft align-self-center"
						[ngClass]="{ 'info-b': canDisplayMessagingLink }"
						>Messagerie
					</span>
				</a>
			</li>
			<ng-container *ngIf="onPreviewModeChange$ | async as isPreviewMode">
				<li *ngIf="!isScopeAdmin && !isScopeMedicalTechnicalAdvisor" routerLinkActive="active">
					<a [routerLink]="['/prescriber-integration-html']" class="d-flex">
						<i class="icon-dashboard align-self-center"></i>
						<span class="info-b text-light fadeInLeft align-self-center">
							Tableau&nbsp;de&nbsp;bord&nbsp;*
						</span>
					</a>
				</li>
				<li *ngIf="!isScopeAdmin" routerLinkActive="active">
					<a [routerLink]="['/fiche-integration-html']" class="d-flex">
						<i class="icon-patients-menu align-self-center"></i>
						<span class="info-b text-light fadeInLeft align-self-center">Patients&nbsp;*</span>
					</a>
				</li>
				<li *ngIf="!isScopeAdmin && !isScopeMedicalTechnicalAdvisor">
					<a [routerLink]="['modal-test']" class="d-flex">
						<i class="icon-ordonance align-self-center"></i>
						<span class="info-b text-light fadeInLeft align-self-center">Ordonances</span>
					</a>
				</li>
				<li *ngIf="!isScopeAdmin && !isScopeMedicalTechnicalAdvisor">
					<a [routerLink]="['forbidden']" class="d-flex">
						<i class="icon-intervention align-self-center"></i>
						<span class="info-b text-light fadeInLeft align-self-center">Interventions</span>
					</a>
				</li>
			</ng-container>
		</ul>
		<ng-container *ngIf="canShowPatientCareForm">
			<a
				[routerLink]="canDisplayPatientCareFormLink ? urlPatientCareForm : null"
				[matTooltipDisabled]="canDisplayPatientCareFormLink"
				matTooltipPosition="after"
				matTooltip="Cette page n'est pas accessible pour les rôles Administratif, CMT, D.R. / R.DEV. et SRC"
				class="btn-new-patient position-relative rounded shadow py-3 bg-color-primary p-2">
				<i class="icon-new-patients text-white"></i>
				<span class="px-2 pt-2 pb-1 info-active text-light text-white text-wrap"
					>Demande de prise en charge</span
				>
			</a>
		</ng-container>
	</div>

	<div class="sidebar-footer align-items-end">
		<ul
			class="components bg-white fadeInTop hidden mb-0 pb-0 pt-1 rounded"
			[ngClass]="{ active: isMenuAdminOpen }"
			(mouseleave)="openAdminMenu(true)">
			<li *ngIf="hasAnnouncementsFeaturePermission">
				<a [routerLink]="['/admin/announcements']" class="d-flex">
					<i class="icon-alerte-iah align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Annonces</span>
				</a>
			</li>
			<li *ngIf="hasMedicalTechnicalAdvisorMessagingFeaturePermission">
				<a [routerLink]="['/medical-technical-advisor/messaging']" class="d-flex">
					<i class="icon-message align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Messagerie</span>
				</a>
			</li>
			<li *ngIf="isImpersonated">
				<button (click)="resetImpersonation()" type="button" class="d-flex">
					<i class="icon-switch align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Reset&nbsp;impersonation</span>
				</button>
			</li>
			<li *ngIf="!isImpersonated && hasImpersonationFeaturePermission" routerLinkActive="active">
				<a [routerLink]="['/admin']" class="d-flex">
					<i class="icon-switch align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Impersonation</span>
				</a>
			</li>
			<li *ngIf="hasUsersFeaturePermission" routerLinkActive="active">
				<a [routerLink]="['/admin/users']" class="d-flex">
					<i class="icon-patient-unknown align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Utilisateurs</span>
				</a>
			</li>
			<li *ngIf="hasHealthCentersFeaturePermission" routerLinkActive="active">
				<a [routerLink]="['/admin/health-centers']" class="d-flex border-bottom">
					<i class="icon-patients-hospitalises align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Établissements</span>
				</a>
			</li>
		</ul>
		<ul class="components admin-menu pt-1">
			<li
				*ngIf="
					hasAnnouncementsFeaturePermission ||
					hasMedicalTechnicalAdvisorMessagingFeaturePermission ||
					hasImpersonationFeaturePermission ||
					hasUsersFeaturePermission ||
					hasHealthCentersFeaturePermission
				">
				<button
					(mouseenter)="openAdminMenu(false)"
					title="Ouvrir/fermer le menu Admin"
					type="button"
					class="d-flex">
					<i class="icon-admin align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Administration</span>
				</button>
			</li>
			<li *ngIf="canShowSwitchArea">
				<a [routerLink]="urlSwitchArea" class="d-flex">
					<i class="icon-switch-activity align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center text-nowrap"
						>Changement d'activité</span
					>
				</a>
			</li>
			<li *ngIf="canShowPreferences">
				<a [routerLink]="urlPreferences" class="d-flex">
					<i class="icon-preferences align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">Préférences</span>
				</a>
			</li>
			<li *ngIf="isAuthenticated">
				<!-- TODO: Mettre un élément de type button qui ait le même rendu visuel que les anchors -->
				<!-- TODO: Prévoir un système pour la déconnexion -->
				<button (click)="logout()" type="button" class="d-flex">
					<i class="icon-deconnexion align-self-center"></i>
					<span class="info-b text-light fadeInLeft align-self-center">D&eacute;connexion</span>
				</button>
			</li>
		</ul>
	</div>
</nav>

<nav class="sidebar-xs shadow">
	<ul
		class="components d-flex align-self-center justify-content-center align-content-center align-items-center iconbg-menu-xs shadow">
		<ng-container *ngIf="(isImpersonated && isScopeMediviewLocalAdmin) || isScopePrescriber">
			<li class="flex-fill d-flex flex-column" routerLinkActive="active">
				<a [routerLink]="['/prescriber/dashboard']">
					<i class="icon-dashboard"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Dashboard</span>
			</li>
			<li routerLinkActive="active" class="flex-fill">
				<a
					*appCheckPermissions="permissions.ReadOnly; feature: features.PrescriberMessaging"
					[routerLink]="['/prescriber/messaging']">
					<i class="icon-message"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Messages</span>
			</li>

			<li
				routerLinkActive="active"
				class="icon-xs-dashboard shadow flex-fill d-flex align-self-center justify-content-center align-content-center align-items-center">
				<ng-container [ngSwitch]="userMode">
					<a (click)="openSearchDialog()" (keyup.enter)="openSearchDialog()" role="dialog">
						<i class="icon-search"></i>
					</a>
				</ng-container>
			</li>
			<li routerLinkActive="active" class="flex-fill">
				<a [routerLink]="['/prescriber/prescriptions']">
					<i class="icon-ordonance"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Prescriptions</span>
			</li>
			<li routerLinkActive="active" class="flex-fill">
				<a [routerLink]="hasPatientCareFormFeaturePermission ? ['/prescriber/patient-care-form'] : null">
					<i class="icon-new-patients"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Prise en ch.</span>
			</li>
		</ng-container>
		<ng-container *ngIf="!isImpersonated && !isScopePrescriber">
			<li class="flex-fill d-flex flex-column" routerLinkActive="active">
				<a
					*appCheckPermissions="permissions.ReadOnly; feature: features.Announcements"
					[routerLink]="['/admin/announcements']">
					<i class="icon-alerte-iah"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Annonces</span>
			</li>
			<li routerLinkActive="active" class="flex-fill">
				<a
					*appCheckPermissions="permissions.ReadOnly; feature: features.MedicalTechnicalAdvisorMessaging"
					[routerLink]="['/medical-technical-advisor/messaging']">
					<i class="icon-message"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Messagerie</span>
			</li>

			<li
				class="icon-xs-dashboard shadow flex-fill d-flex align-self-center justify-content-center align-content-center align-items-center">
				<a
					*appCheckPermissions="permissions.ReadOnly; feature: features.Impersonation"
					[routerLink]="['/admin']">
					<i class="icon-switch"></i>
				</a>
			</li>
			<li routerLinkActive="active" class="flex-fill">
				<a *appCheckPermissions="permissions.ReadOnly; feature: features.Users" [routerLink]="['/admin/users']">
					<i class="icon-patient-unknown fs-2"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Utilisateurs</span>
			</li>
			<li routerLinkActive="active" class="flex-fill">
				<a
					*appCheckPermissions="permissions.ReadOnly; feature: features.HealthCenters"
					[routerLink]="['/admin/health-centers']">
					<i class="icon-patients-hospitalises fs-2"></i>
				</a>
				<span class="label text-light text-capitalize mt-1">Établissements</span>
			</li>
		</ng-container>
	</ul>
</nav>
