import * as moment from "moment";
export {};

String.prototype.firstLetter = function (this: string): string {
	return this && this.length !== 0 ? this.charAt(0) : "";
};

String.prototype.isConvertibleAsNumber = function (this: string): boolean {
	return !isNaN(Number(this));
};

String.prototype.removeHashtags = function (this: string): string {
	return this?.replaceAll("#", "");
};

String.prototype.removeQueryParams = function (this: string): string {
	return this?.replace(/\?.*$/, "");
};

String.prototype.toTitleCase = function (this: string): string {
	return (
		this.match(/\p{L}+[\p{L}\p{N}-]*/gu)
			?.map(x => x.charAt(0).toUpperCase() + x.slice(1))
			.join(" ") ?? ""
	);
};

String.prototype.toUtcDate = function (this: string): Date {
	return moment.utc(this).toDate();
};
