<app-table-mobile
	[defaultTableView]="defaultTableViewMobile"
	[cacheKey]="cacheKey"
	[cacheKeyMobile]="cacheKeyMobile"
	[browsePagingParameters]="browseMessagingParameters"
	[browseCommandType]="browseCommandType"
	[getItems]="exchangeService.browseMessages.bind(exchangeService)"
	filterDescriptionText="Liste de vos messages classés du plus récent au plus ancien"
	#tableMobile>
	<ng-template let-message #row>
		<div class="position-relative bg-odd mb-2 rounded">
			<div class="d-flex justify-content-between ps-5 pe-2 py-3">
				<div class="flex-column fs-6 w-30 date-full-name">
					<div class="text-medium">{{ message.createdOn | date: "dd/MM/yyyy" }}</div>
				</div>
				<div class="d-none d-sm-block d-flex lh-14 w-30">
					<span class="w-100 text-truncate">Objet</span>
					<span class="text-medium me-1">{{ message.title }}</span>
				</div>
				<div class="d-none d-sm-block w-20">
					<div class="me-3 d-flex h-100 flex-column align-items-center justify-content-center">
						<div class="lh-12">
							<i class="rounded-circle p-2" [ngClass]="getColorStatus(message.status)"></i>
						</div>
						<span class="lh-13 text-center">{{ getDisplayStatus(message.status) }}</span>
					</div>
				</div>
			</div>
			<input
				type="checkbox"
				class="accordeon-tab-{{ message.id }}"
				name="accordeon-tab-{{ message.id }}"
				id="accordeon-tab-{{ message.id }}"
				(click)="tableMobile.activeAccordeon(message.id)" />
			<label for="accordeon-tab-{{ message.id }}" class="d-block btn btn-md-patient pin-circle">
				<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
			</label>
			<!-- CONTENU SMARTPHONE -->
			<div class="border border-light bg-white rounded-bottom d-sm-none">
				<div class="w-100 d-flex">
					<div class="align-self-center px-2">
						<i class="icon-message fs-1 text-ultra-light-gray"></i>
					</div>
					<div class="py-2 w-60">
						<span class="d-block w-100">Objet</span>
						<span class="text-medium me-2">{{ message.title }}</span>
					</div>
					<div class="flex-fill w-20 border-light">
						<div
							class="ms-3 h-100 border-start py-2 d-flex flex-column align-items-center justify-content-center">
							<div>
								<i class="rounded-circle p-2" [ngClass]="getColorStatus(message.status)"></i>
							</div>
							<span class="text-center">{{ getDisplayStatus(message.status) }}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- FIN CONTENU SMARTPHONE -->
			<div
				*ngIf="tableMobile.accordeonRowClickedIds[message.id] === message.id"
				class="accordeon border border-light bg-white rounded-bottom">
				<div class="">
					<div class="d-flex flex-column flex-sm-row my-sm-2 mt-2">
						<!-- CONTENU SMARTPHONE -->
						<div class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
							<span class="text-regular d-block">Contenu du message</span>
							<span class="">{{ message.body }}</span>
						</div>
						<!-- FIN CONTENU SMARTPHONE -->
						<div class="flex-fill w-50 border-light p-2 my-2 my-sm-0 d-none d-sm-block">
							<span class="text-regular d-block">Contenu du message</span>
							<span class="">{{ message.body }}</span>
						</div>
					</div>
					<div class="d-flex flex-column flex-sm-row my-sm-2 mt-2">
						<!-- CONTENU SMARTPHONE -->
						<div
							*ngIf="message.attachments.length > 0"
							class="flex-fill border-bottom border-light d-flex flex-column order-2 p-2 d-sm-none">
							<span class="text-regular d-block">Documents</span>
							<div style="text-align: center; justify-content: center">
								<div *ngFor="let document of getAttachments(message)">
									<app-secured-file-link
										[documentId]="document.fileId"
										[documentDate]="document.fileDate"
										[documentName]="document.fileName"
										[documentType]="document.fileTypeCode"></app-secured-file-link>
								</div>
							</div>
						</div>

						<!-- FIN CONTENU SMARTPHONE -->
						<div
							*ngIf="message.attachments.length > 0"
							class="flex-fill w-50 border-light p-2 my-2 my-sm-0 d-none d-sm-block">
							<span class="text-regular d-block">Documents</span>
							<div style="text-align: center; justify-content: center">
								<div *ngFor="let document of getAttachments(message)">
									<app-secured-file-link
										[documentId]="document.fileId"
										[documentDate]="document.fileDate"
										[documentName]="document.fileName"
										[documentType]="document.fileTypeCode"></app-secured-file-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</app-table-mobile>
