import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { GenerateReportRequest } from "src/app/models/pairing/generate-report";

@Injectable({
	providedIn: "root",
})
export class PairingService {
	constructor(private _httpClient: HttpClient) {}

	getReportByPatient(patientId: string, request: GenerateReportRequest): Observable<HttpResponse<Blob>> {
		const options = {
			params: request.getHttpParams(),
			responseType: "blob" as const,
			observe: "response" as const,
		};
		return this._httpClient.get(`/pairing/patients/${patientId}/reports/generate`, options);
	}
}
