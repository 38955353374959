import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrescriberMedicalTechnical } from "../models/prescribers/prescriber-medical-technical";
import { SearchPrescribersResult } from "../models/prescribers/search-prescribers-result";
import { Contacts } from "../models/prescribers/contacts";

@Injectable({
	providedIn: "root",
})
export class PrescribersService {
	constructor(private _httpClient: HttpClient) {}

	searchPrescribers(terms: string, medicalTechnicalAdvisorId: string): Observable<SearchPrescribersResult> {
		let params = new HttpParams().set("terms", terms);
		if (medicalTechnicalAdvisorId != undefined)
			params = new HttpParams().set("terms", terms).set("medicalTechnicalAdvisorId", medicalTechnicalAdvisorId);
		return this._httpClient.get<SearchPrescribersResult>(`/prescribers/search`, { params: params });
	}

	searchPrescribersByPatient(patientId: string): Observable<SearchPrescribersResult> {
		const params = new HttpParams().set("patientId", patientId);
		return this._httpClient.get<SearchPrescribersResult>(`/prescribers/search`, { params: params });
	}

	getPrescriberMedicalTechnical(prescriberId: string): Observable<PrescriberMedicalTechnical> {
		return this._httpClient.get<PrescriberMedicalTechnical>(
			`/prescribers/${prescriberId}/medical-technical-advisor`
		);
	}

	getContacts(prescriberId: string): Observable<Contacts> {
		return this._httpClient.get<Contacts>(`/prescribers/${prescriberId}/contacts`);
	}
}
