<div *ngIf="tableViewMobile" class="row d-block d-xl-none position-relative">
	<!-- FILTRES -->
	<div *ngIf="tableViewMobile.displayColumns.length > 0" class="col-12 position-relative d-block d-xl-none">
		<div class="d-flex justify-content-between form-date-increment pe-4 mb-3">
			<span class="fs-5 mb-0 align-self-center text-medium d-block pe-3"> Filtres </span>
			<div class="flex-grow-1 align-self-center">
				<div class="w-100 border-bottom"></div>
			</div>
		</div>
		<input
			type="checkbox"
			name="option-popin-filtres"
			id="option-popin-filtres"
			class="popin-filtres accordeon d-block" />
		<label for="option-popin-filtres" class="btn btn-options pin-circle me-2" aria-label="next">
			<i aria-hidden="true" class="icon-icon-plus position-absolute top-50 start-50 translate-middle"></i>
		</label>
		<div class="listing-patient bloc-listing-patient">
			<div class="row g-sm-2 mt-3">
				<div *ngFor="let column of tableViewMobile.displayColumns" class="col-12 col-sm-6">
					<div class="d-flex flex-column pb-2 pb-sm-0 p-xl-2">
						<app-table-header
							[columnName]="column.columnName"
							[filters]="tableViewMobile.filters"
							[filterChoiceList]="column.filterChoiceList"
							[filterOnly]="true"
							[filterColumnName]="column.filterColumnName"
							[filterToolTip]="column.filterToolTip"
							[filterType]="column.filterTableColumnType"
							[headerLabel]="column.headerLabel"
							[headerSubLabel]="column.headerSubLabel"
							[headerWithoutShadow]="column.headerWithoutShadow"
							[isMobile]="tableViewMobile.isMobile"
							[sort]="tableViewMobile.sort"
							[sortColumnName]="column.sortColumnName"
							[sortToolTip]="column.sortToolTip"
							(filterChange)="reloadTable(true)">
						</app-table-header>
						<app-table-header-display-filter
							[columnName]="column.columnName"
							[filters]="tableViewMobile.filters"
							[filterType]="column.filterTableColumnType"
							[isMobile]="tableViewMobile.isMobile"
							(filterChange)="reloadTable(true)">
						</app-table-header-display-filter>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- CONTENUS -->
	<div class="col-12 mt-4 d-block d-sm-none" *ngIf="filterDescriptionText">
		<span *ngIf="!noData" class="pb-2">{{ filterDescriptionText }}</span>
	</div>
	<div class="col-12 mt-1 mt-sm-4 d-block d-xl-none card-message">
		<div class="mt-1 bg-white rounded" [ngClass]="{ 'p-3': tableBordered, shadow: tableBordered }">
			<span *ngIf="!noData" class="d-none d-sm-block pb-2">{{ filterDescriptionText }}</span>
			<span *ngIf="noData">Aucun résultat ne correspond à votre recherche</span>

			<ng-container *ngFor="let item of getItems$ | async; trackBy: trackById">
				<ng-container
					*ngIf="rowTemplateRef"
					[ngTemplateOutlet]="rowTemplateRef"
					[ngTemplateOutletContext]="{ $implicit: item }">
				</ng-container>
			</ng-container>

			<div class="w-100 d-flex justify-content-center">
				<button
					type="button"
					class="btn btn-primary my-3 text-medium fs-4"
					[disabled]="isLoadMoreButtonDisabled"
					*ngIf="!noData && !endOfMessages"
					(click)="loadMoreMessages()">
					<span class="d-none d-sm-block">Voir plus d'éléments</span>
					<span class="d-sm-none">Voir plus</span>
				</button>
			</div>
		</div>
	</div>
</div>
