<!-- when active adds the css class .active on the div -->
<div
	class="upload-drop-zone text-center border color-primary border-2 mb-3 rounded-5 position-relative d-flex justify-content-center align-items-center"
	[style.background]="isDragAndDropZoneActive ? '#e6e5f0' : null"
	*ngIf="nbFiles < nbMaxFiles"
	appDragAndDrop
	[(isActive)]="isDragAndDropZoneActive"
	(filesChange)="onDragAndDropMultiFileChange($event)">
	<input
		class="upload-drop-zone__input w-100 z-index-2 left-0 opacity-0 position-absolute h-100 top-0"
		id="fileDropRef"
		multiple
		type="file"
		title="Upload de fichier"
		[accept]="availableExtensionTypes"
		(change)="onInputMultiFileChange($event)" />
	<div class="upload-drop-zone__content">
		<p class="fs-5-5 text-ultra-light">
			<span class="text-medium">Glisser&nbsp;/ déposer</span> vos fichiers directement
			<br />
			ou
		</p>
		<label
			class="btn btn-primary my-3 text-medium fs-6 d-flex justify-content-between align-items-center w-90 m-auto"
			for="fileDropRef">
			<span class="pt-1">Joindre des fichiers</span>
			<i class="icon-ordonance"></i>
		</label>
	</div>
</div>
<div style="padding: 0 0 8px 0" class="upload-files">
	@for (item of items; track item.internalId; let e = $even) {
		<app-file-uploader-item
			style="border-radius: 20px; display: block"
			[style.background]="e ? '#F0F0F0' : '#FFFFFF'"
			[class.even]="$even"
			(errorDetected)="onErrorDetected($event)"
			(markAsDeleted)="onMarkAsDeleted($event)"
			(uploaded)="uploaded($event)"
			[availableExtensionTypes]="availableExtensionTypes"
			[availableMimeTypes]="availableMimeTypes"
			[item]="item"
			[fileUploadCategoryCode]="fileUploadCategoryCode"
			[maxFilenameLength]="maxFilenameLength"
			[maxSize]="maxSize"
			[medicalUserId]="medicalUserId"
			[prescriberId]="prescriberId"
			[patientId]="patientId"></app-file-uploader-item>
	}
</div>
