export class PrescriberIndicatorCodes {
	static readonly currentHospitalizedPatients: string = "current-hospitalized-patients-indicator"; // TODO: To rename ?
	static readonly currentUnreachablePatients: string = "current-unreachable-patients-indicator"; // TODO: To rename ?
	static readonly initialChangePrescriptionsToRenew: string = "initial-change-prescriptions-to-sign-past-indicator";
	static readonly prescriptionsToSignFuture: string = "prescriptions-to-sign-future-indicator";
	static readonly renewalPrescriptionsToRenew: string = "renewal-prescriptions-to-sign-past-indicator";
	static readonly patientsByPrescriber: string = "patients-by-prescriber-indicator";
	static readonly patientsInteractions: string = "patients-interactions-indicator";
	static readonly treatmentsDistribution: string = "treatments-distribution-indicator";
	static readonly observanceDistribution: string = "observance-distribution-indicator";

	static readonly titleMappings: Record<string, string> = {
		[PrescriberIndicatorCodes.currentHospitalizedPatients]: "Patients sont",
		[PrescriberIndicatorCodes.currentUnreachablePatients]: "Patients",
	};

	static readonly subtitleMappings: Record<string, string> = {
		[PrescriberIndicatorCodes.currentHospitalizedPatients]: "hospitalisés",
		[PrescriberIndicatorCodes.currentUnreachablePatients]: "injoignables",
	};

	static readonly iconMappings: Record<string, string> = {
		[PrescriberIndicatorCodes.currentHospitalizedPatients]: "icon-patients-hospitalises",
		[PrescriberIndicatorCodes.currentUnreachablePatients]: "icon-patients-injoignables",
	};
}
