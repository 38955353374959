import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-read-more",
	templateUrl: "./read-more.component.html",
	styleUrls: ["./read-more.component.css"],
})
export class ReadMoreComponent implements OnInit {
	@Input() id!: string;
	@Input() defaultExpand!: boolean;
	@Input() onExpandReadMore!: (id: string) => void;
	@Input() text!: string;

	expand = false;
	anchor = "Lire la suite ...";

	get isLongText(): boolean {
		return this.text.split(/\r\n|\r|\n/).length > 2;
	}

	constructor() {}

	ngOnInit(): void {
		this.expand = this.defaultExpand ? this.defaultExpand : false;

		if (this.defaultExpand && this.onExpandReadMore && this.id) {
			this.anchor = "Replier";
			this.onExpandReadMore(this.id);
		}
	}

	toggle() {
		this.expand = !this.expand;
		this.anchor = this.expand ? "Replier" : "Lire la suite ...";

		if (this.expand && this.onExpandReadMore && this.id) {
			this.onExpandReadMore(this.id);
		}
	}
}
