<label [for]="inputName">
	{{ label }}
</label>
<div class="input-group input-rounded input-file border color-primary border-2 mb-3 overflow-hidden">
	<input
		type="file"
		title="{{ label }}"
		[id]="inputName"
		class="form-control form-care"
		(change)="onFileChange($event)" />
	<label
		[for]="inputName"
		class="input-group-text py-0 px-1 text-white text-regular text-uppercase pt-1 px-2 cs-pointer">
		<span>
			<span *ngIf="filename">
				<button type="button" color="accent" (click)="removeUpload($event)" title="Supprimer le fichier">
					x
				</button>
				<span>{{ filename }} </span>
			</span>
		</span>
		<strong>Parcourir</strong>
	</label>
</div>
<div class="input-file__content">
	<div class="file-upload__progress" *ngIf="uploadProgressPercentage">
		<mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgressPercentage">
		</mat-progress-bar>
	</div>
	<div class="file-upload__error" *ngIf="isOnError">
		<span *ngIf="errorMessage">{{ errorMessage }}</span>
		<span *ngIf="!errorMessage">Une erreur est survenue lors de l'upload du fichier</span>
	</div>
</div>
