import { formatDate } from "@angular/common";
import { HttpParams } from "@angular/common/http";

export class BaseApiRequest {
	private _apiFormatDate = "yyyy-MM-dd";
	private _apiLocaleDate = "fr";

	constructor() {}

	getHttpParams() {
		let params = new HttpParams();

		Object.keys(this)
			.filter(key => !(key.charAt(0) === "_"))
			.map(key => {
				let value = (this as any)[key];
				if (value) {
					if (value instanceof Date) {
						value = formatDate(value, this._apiFormatDate, this._apiLocaleDate);
					}
					if (value instanceof Array) {
						value.forEach((item: string | number | boolean) => {
							params = params.append(`${key.toString()}[]`, item);
						});
					} else {
						params = params.append(key.toString(), value);
					}
				}
			});

		return params;
	}
}
