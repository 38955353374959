<div *ngIf="items.value.length > 0" style="text-align: center; justify-content: center">
	@for (item of items.value; track $index) {
		<app-secured-file-link
			[documentId]="item.fileId"
			[documentDate]="item.fileDate"
			[documentName]="item.fileName"
			[documentType]="item.fileTypeCode"
			[title]="item.fileName"></app-secured-file-link>
	}
</div>
