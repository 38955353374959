import { RoleLabels, Roles } from "src/app/constants/roles.constant";
import { RoleSelection, RoleTypes, Title } from "src/app/models/users/user.interface";

export class UserRolesSelect {
	static readonly roleSelection: RoleSelection[] = [
		{
			name: RoleLabels.superAdmin,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.superAdmin,
		},
		{
			name: RoleLabels.admin,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.admin,
		},
		{
			name: RoleLabels.medicalTechnicalAdvisor,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.medicalTechnicalAdvisor,
		},
		{
			name: RoleLabels.messageController,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.messageController,
		},
		{
			name: RoleLabels.administrative,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.administrative,
		},
		{
			name: RoleLabels.regionalOrDevelopmentManager,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.regionalOrDevelopmentManager,
		},
		{
			name: RoleLabels.customerRelationDepartement,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.Internal,
			value: Roles.customerRelationDepartement,
		},
		{
			name: RoleLabels.secretary,
			color: "primary",
			selected: false,
			checked: false,
			type: RoleTypes.External,
			value: Roles.secretary,
		},
	];
}

export class UserTitlesSelect {
	static readonly M: string = "M.";
	static readonly Mme: string = "Mme.";
	static readonly Dr: string = "Dr.";
	static readonly Pr: string = "Pr.";

	static readonly titleSelection: Title[] = [
		{ name: this.M, color: "primary", value: this.M },
		{ name: this.Mme, color: "primary", value: this.Mme },
		{ name: this.Dr, color: "primary", value: this.Dr },
		{ name: this.Pr, color: "primary", value: this.Pr },
	];
}

export class UserErrorMessage {
	static readonly message = {
		error: {
			title: "'Title' must not be empty.",
			givenName: "'Given Name' must not be empty.",
			name: "'Family Name' must not be empty.",
			medicalUserId: "Medical user id is already assigned.",
			roles: "'Roles' must not be empty.",
		},
		response: {
			title: "Aucun titre sélectionné",
			givenName: "Aucun prénom rentré.",
			medicalUserId: "Le medical user id est déja assigné.",
			name: "Aucun nom rentré.",
			roles: "Aucun rôle sélectionné.",
			email: "L'email choisi est déja utilisé.",
		},
		sendingMail: {
			error: "Erreur lors de l'envoi de l'email de récupération de mot de passe envoyé.",
			ok: "Email de récupération de mot de passe envoyé.",
		},
		sendingMailAccountCreation: {
			error: "Erreur lors de l'envoi de l'email de création de compte",
			ok: "Email de création de compte envoyé.",
		},
	};
}
