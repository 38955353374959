import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalSearchComponent, NavigationBaseComponent } from "src/app/@shared";
import { Features, Permissions } from "src/app/constants";

@Component({
	selector: "app-navigation",
	templateUrl: "navigation.component.html",
})
export class NavigationComponent extends NavigationBaseComponent implements OnInit, OnDestroy {
	private readonly dialog = inject(MatDialog);

	isMenuOpen = false;
	isMenuAdminOpen = false;
	isSearchPatientOpen = false;

	permissions = Permissions;
	features = Features;

	openCloseMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isMenuOpen = false;
			return;
		}

		this.isMenuOpen = !this.isMenuOpen;
	}

	openAdminMenu(forceToClose: boolean): void {
		if (forceToClose) {
			this.isMenuAdminOpen = false;
		} else {
			this.isMenuAdminOpen = true;
		}
		return;
	}

	openSearchDialog(): void {
		if (this.isSearchPatientOpen) return;
		this.isSearchPatientOpen = true;
		const subscription = this.dialog.open(ModalSearchComponent).componentInstance.closed.subscribe(() => {
			this.isSearchPatientOpen = false;
			subscription.unsubscribe();
		});
	}
}
