import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from "@angular/forms";
import { FileAttachement, FileItem, UploadedItem, UploadErrorItem } from "./types";

@Component({
	selector: "app-multi-file-uploader",
	templateUrl: "multi-file-uploader.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: MultiFileUploaderComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: MultiFileUploaderComponent,
		},
	],
})
export class MultiFileUploaderComponent implements ControlValueAccessor, Validator {
	@Output() readonly remainingNbFilesChange = new EventEmitter<number>();

	@Input() availableExtensionTypes?: string[];
	@Input() availableMimeTypes?: string[];
	@Input() inputName!: string;
	@Input() fileUploadCategoryCode!: string;
	@Input() isRequired = false;
	@Input() maxFilenameLength?: number;
	@Input() maxSize?: number;
	@Input() medicalUserId!: string;
	@Input() prescriberId!: string;
	@Input() patientId?: string;
	@Input() nbMaxFiles = 3;
	@Input() value: FileAttachement[] = [];

	isDragAndDropZoneActive = false;
	disabled = false;
	nbFiles = 0;
	touched = false;

	items: FileItem[] = [];
	uploadedItems: UploadedItem[] = [];
	uploadErrorItems: UploadErrorItem[] = [];

	onChange = (_: FileAttachement[] | null) => {};

	onTouched = () => {};

	onValidationChange = () => {};

	writeValue(obj: FileAttachement[]): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.onValidationChange = fn;
	}

	setDisabledState?(_isDisabled: boolean): void {
		this.disabled = _isDisabled;
	}

	markAsTouched(): void {
		if (this.touched) return;

		this.onTouched();
		this.touched = true;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		const values: string[] = control.value;

		if (!this.isRequired && this.nbFiles === 0 && (!values || values.length === 0)) return null;

		let hasErrors = false;
		const validationMap: ValidationErrors = {};

		if (!values || values.length === 0) {
			hasErrors = true;
			validationMap["required"] = { values };
		}

		for (const uploadErrorItem of this.uploadErrorItems) {
			if (uploadErrorItem.hasWrongMimeType) {
				hasErrors = true;
				if (!validationMap["fileMimeType"]) {
					validationMap["fileMimeType"] = [
						{
							fileName: uploadErrorItem.fileName,
							expected: this.availableMimeTypes,
							actual: uploadErrorItem.fileMimeType,
						},
					];
				} else {
					validationMap["fileMimeType"].push({
						fileName: uploadErrorItem.fileName,
						expected: this.availableMimeTypes,
						actual: uploadErrorItem.fileMimeType,
					});
				}
			}

			if (uploadErrorItem.hasWrongFilenameLength) {
				hasErrors = true;
				if (!validationMap["filenameLength"]) {
					validationMap["filenameLength"] = [
						{
							fileName: uploadErrorItem.fileName,
							expected: this.maxFilenameLength,
							actual: uploadErrorItem.fileNameLength,
						},
					];
				} else {
					validationMap["filenameLength"].push({
						fileName: uploadErrorItem.fileName,
						expected: this.maxFilenameLength,
						actual: uploadErrorItem.fileNameLength,
					});
				}
			}

			if (uploadErrorItem.hasWrongSize) {
				hasErrors = true;
				if (!validationMap["fileSize"]) {
					validationMap["fileSize"] = [
						{
							fileName: uploadErrorItem.fileName,
							expected: this.maxSize,
							actual: uploadErrorItem.fileSize,
						},
					];
				} else {
					validationMap["fileSize"].push({
						fileName: uploadErrorItem.fileName,
						expected: this.maxSize,
						actual: uploadErrorItem.fileSize,
					});
				}
			}

			if (uploadErrorItem.isServerError) {
				hasErrors = true;
				if (!validationMap["serverError"]) {
					validationMap["serverError"] = [
						{
							fileName: uploadErrorItem.fileName,
							expected: this.maxSize,
							actual: uploadErrorItem.fileSize,
						},
					];
				} else {
					validationMap["serverError"].push({
						fileName: uploadErrorItem.fileName,
						expected: this.maxSize,
						actual: uploadErrorItem.fileSize,
					});
				}
			}
		}

		if (hasErrors) return validationMap;

		return null;
	}

	onMarkAsDeleted(internalId: number): void {
		const itemsIndex = this.items.findIndex(e => e.internalId === internalId);
		if (itemsIndex !== -1) {
			this.items.splice(itemsIndex, 1);
		}

		this.nbFiles = this.items.length;

		const errorItemsIndex = this.uploadErrorItems.findIndex(e => e.internalFileId === internalId);
		if (errorItemsIndex !== -1) {
			this.uploadErrorItems.splice(errorItemsIndex, 1);
		}
		const uploadedItemsIndex = this.uploadedItems.findIndex(e => e.internalFileId === internalId);
		if (uploadedItemsIndex !== -1) {
			this.uploadedItems.splice(errorItemsIndex, 1);
		}

		this.value = this.uploadedItems.map(e => {
			return {
				fileId: e.fileId,
				fileName: e.fileName,
			};
		});
		this.onChange(this.value);

		const nbRemainingItems = this.nbMaxFiles - this.items.length;
		this.remainingNbFilesChange.emit(nbRemainingItems);
	}

	onErrorDetected(errorItem: UploadErrorItem): void {
		this.uploadErrorItems.push(errorItem);
		this.onValidationChange();
	}

	uploaded(item: UploadedItem): void {
		this.uploadedItems.push(item);
		this.value = this.uploadedItems.map(e => {
			return {
				fileId: e.fileId,
				fileName: e.fileName,
			};
		});
		this.onChange(this.value);
	}

	onInputMultiFileChange($event: any): void {
		this.markAsTouched();

		if (!$event.target || !$event.target.files || $event.target.files.length === 0) return;

		this.processFiles(Array.from($event.target.files));
	}

	onDragAndDropMultiFileChange(files: File[]): void {
		this.markAsTouched();

		if (!files || files.length === 0) return;

		this.processFiles(files);
	}

	private processFiles(files: File[]): void {
		if (!this.hasCorrectNbFiles()) {
			this.onValidationChange();
			return;
		}
		let nbRemainingItems = this.nbMaxFiles - this.items.length;
		this.items.push(
			...files.slice(0, nbRemainingItems).map(e => {
				return { file: e, internalId: Math.floor(Math.random() * 100) };
			})
		);

		this.nbFiles = this.items.length;
		nbRemainingItems = this.nbMaxFiles - this.items.length;
		this.remainingNbFilesChange.emit(nbRemainingItems);
	}

	private hasCorrectNbFiles(): boolean {
		if (this.nbFiles && this.nbFiles > this.nbMaxFiles) return false;

		return true;
	}
}
