import { Component, OnInit } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthLogoutEvent, CacheKeys } from "src/app/constants";
import { Logger } from "src/app/services/logger.service";
import { StorageService } from "src/app/services/storage.service";
import { Impersonation } from "src/app/types/impersonation";

const log = new Logger("SignoutCallbackComponent");

@Component({
	selector: "app-signout-callback",
	templateUrl: "signout-callback.component.html",
})
export class SignoutCallbackComponent implements OnInit {
	constructor(
		private _storageService: StorageService,
		private _oauthService: OAuthService
	) {}

	ngOnInit(): void {
		Impersonation.resetImpersonationInsideCache();

		const logoutReasonCacheValue: boolean = this._storageService.retrieveCacheValue(
			AuthLogoutEvent.tokenRevokedLogoutEvent
		);

		// Retrieve the prescriberIds to keep them after clear cache
		this._storageService.clearCachesExceptGroup([
			CacheKeys.scopePrescriberIds,
			CacheKeys.appVersion,
			CacheKeys.userConnection,
		]);

		log.debug(`Navigate to skyconnect`, logoutReasonCacheValue);

		if (logoutReasonCacheValue) {
			this._oauthService.initCodeFlow(AuthLogoutEvent.tokenRevokedLogoutEvent, {
				tokenLogout: logoutReasonCacheValue,
			});
		} else {
			this._oauthService.initCodeFlow();
		}
	}
}
