<ng-container
	*ngIf="
		filterType === filterTableColumnTypes.in ||
		filterType === filterTableColumnTypes.contains ||
		filterType === filterTableColumnTypes.stringContains ||
		filterType === filterTableColumnTypes.comparison ||
		filterType === filterTableColumnTypes.date ||
		filterType === filterTableColumnTypes.dateRange ||
		filterType === filterTableColumnTypes.searchTerms
	">
	<div appClickOutside (clickOutside)="closeFilter()">
		<input
			title="Filter on {{ columnName }}"
			type="checkbox"
			name="{{ columnName }}"
			id="{{ columnName }}"
			class="option-popin d-block"
			[checked]="searchChecked"
			(change)="changeChecked($event.target)" />
		<label for="{{ columnName }}" class="btn btn-options pin-circle ms-2 z-index-1">
			<i class="icon-pagination-down position-absolute top-50 start-50 translate-middle"></i>
		</label>
		<div
			class="option-popin-content text-start shadow bg-white rounded-bottom"
			(keyup.enter)="submitSearchForm()"
			role="dialog">
			<ng-container
				*ngIf="
					filterType === filterTableColumnTypes.stringContains ||
					filterType === filterTableColumnTypes.searchTerms
				">
				<div class="p-3">
					<form [formGroup]="searchForm" class="mr-auto ml-md-3 my-2 mw-100">
						<div class="input-group">
							<input
								formControlName="search"
								matinput
								type="text"
								class="form-control rounded-pill bg-white border-1 small py-1 px-3"
								aria-label="Search"
								aria-describedby="basic-addon2" />
						</div>
					</form>
				</div>
			</ng-container>

			<ng-container *ngIf="filterType === filterTableColumnTypes.date">
				<div class="p-3">
					<form [formGroup]="searchForm" class="mr-auto ml-md-3 my-2 mw-100">
						<div class="input-group">
							<input
								formControlName="date"
								matinput
								type="date"
								class="form-control rounded-pill bg-white border-1 small py-1 px-3"
								aria-label="dd/MM/yyyy" />
						</div>
					</form>
				</div>
			</ng-container>

			<ng-container
				*ngIf="filterType === filterTableColumnTypes.in || filterType === filterTableColumnTypes.contains">
				<div class="px-3 py-2">
					<form [formGroup]="searchForm" class="mr-auto ml-md-3 my-2 mw-100">
						<ng-container formArrayName="checkArray">
							<div
								[formGroupName]="i"
								*ngFor="let item of checkArrayControls(); let i = index"
								class="input-group mb-2">
								<input
									class="custom-checkbox"
									id="check-{{ item.value.name }}"
									name="checkbox"
									formControlName="checked"
									[checked]="item.value.checked"
									type="checkbox" />
								<label for="check-{{ item.value.name }}" class="custom-radio position-relative">{{
									filterChoice ? filterChoice[i].name : ""
								}}</label>
							</div>
						</ng-container>
					</form>
				</div>
			</ng-container>
			<ng-container *ngIf="filterType === filterTableColumnTypes.comparison">
				<div class="px-3 py-2">
					<form [formGroup]="searchForm" class="mr-auto ml-md-3 my-2 mw-100">
						<ng-container formArrayName="checkArray">
							<div
								[formGroupName]="i"
								*ngFor="let item of checkArrayControls(); let i = index"
								class="input-group mb-2">
								<input
									class="custom-checkbox"
									id="comparison-{{ i }}"
									name="checkbox"
									formControlName="checked"
									(change)="changeComparisonChecked(item)"
									[checked]="item.value.checked"
									type="checkbox" />
								<label for="comparison-{{ i }}" class="custom-radio position-relative">{{
									filterChoice ? filterChoice[i].name : ""
								}}</label>
							</div>
						</ng-container>
					</form>
				</div>
			</ng-container>

			<ng-container *ngIf="filterType === filterTableColumnTypes.dateRange">
				<div class="p-3">
					<form [formGroup]="searchForm" class="mr-auto ml-md-3 my-2 mw-100">
						<div class="mb-3">
							<label for="startDate" class="form-label">De</label>
							<input
								formControlName="startDate"
								id="startDate"
								type="date"
								class="form-control rounded-pill bg-white border-1 small py-1 px-3"
								aria-label="mm/dd/yyyy"
								aria-describedby="basic-addon2" />
						</div>
						<div class="mb-3">
							<label for="endDate" class="form-label">&#192;</label>
							<input
								formControlName="endDate"
								id="endDate"
								type="date"
								class="form-control rounded-pill bg-white border-1 small py-1 px-3"
								aria-label="mm/dd/yyyy"
								aria-describedby="basic-addon2" />
						</div>
					</form>
				</div>
			</ng-container>

			<div class="row g-0 btn-filters">
				<button type="button" (click)="resetForm()" class="col-6 btn text-uppercase btn-default text-medium">
					effacer
				</button>
				<button
					type="button"
					(click)="submitSearchForm()"
					class="col-6 btn text-uppercase text-white text-medium">
					filtrer
				</button>
			</div>
		</div>
	</div>
</ng-container>
