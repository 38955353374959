export class Treatments {
	static PrescriptionCodes = class {
		static Cpap = "100";
		static O2 = "101";
		static Ventilation = "102";
		static Oximetry = "104";
		static Aerosoltherapy = "106";
		static VentilationAndTrach = "107";
		static CpapAndO2 = "111";
		static VentilationAndO2 = "112";
		static VentilationAndTrachAndO2 = "114";
	};

	static PrescriptionNames = class {
		static readonly O2 = "O2";
		static readonly Cpap = "PPC";
		static readonly VNI = "VNI";
	};

	static readonly PrescriptionCodeMappings = new Map([
		[Treatments.PrescriptionCodes.Cpap, "PPC"],
		[Treatments.PrescriptionCodes.O2, "O2"],
		[Treatments.PrescriptionCodes.Ventilation, "Ventilation"],
		[Treatments.PrescriptionCodes.Aerosoltherapy, "Aérosolthérapie"],
		[Treatments.PrescriptionCodes.VentilationAndTrach, "Ventilation + Trach"],
		[Treatments.PrescriptionCodes.CpapAndO2, "PPC + O2"],
		[Treatments.PrescriptionCodes.VentilationAndO2, "Ventilation + O2"],
		[Treatments.PrescriptionCodes.VentilationAndTrachAndO2, "Ventilation + Trach + O2"],
	]);

	static readonly AllO2Types: Record<string, string> = {
		avf: "AVF",
		long_term: "Long terme",
		short_term: "Court terme",
	};
}
