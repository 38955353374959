import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

interface PatientsSpecificFilters {
	filterName: string;
	checked: boolean;
}

@Injectable({
	providedIn: "root",
})
export class TableCommunicationService {
	private _resetFiltersSubject = new Subject<boolean>();
	onResetFiltersSubject$: Observable<boolean> = this._resetFiltersSubject.asObservable();

	private _changeFiltersSubject = new Subject<boolean>();
	onChangeFiltersSubject$: Observable<boolean> = this._changeFiltersSubject.asObservable();

	private _changeSpecificPrescriptionsFiltersSubject = new Subject<string | null>();
	onChangeSpecificPrescriptionsFiltersSubject$: Observable<string | null> =
		this._changeSpecificPrescriptionsFiltersSubject.asObservable();

	private _changeSpecificPatientsFiltersSubject = new Subject<PatientsSpecificFilters>();
	onChangeSpecificPatientsFiltersSubject$: Observable<PatientsSpecificFilters> =
		this._changeSpecificPatientsFiltersSubject.asObservable();

	constructor() {}

	resetFilters(): void {
		this._resetFiltersSubject.next(true);
	}

	changeFilters(isMobile: boolean): void {
		this._changeFiltersSubject.next(isMobile);
	}

	changeSpecificPrescriptionsFilters(filterName: string | null): void {
		this._changeSpecificPrescriptionsFiltersSubject.next(filterName);
	}

	changeSpecificPatientsFilters(filterName: string, checked: boolean): void {
		this._changeSpecificPatientsFiltersSubject.next({ filterName, checked });
	}
}
