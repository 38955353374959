import { BrowsePaging } from "src/app/@shared/types/browse-paging";

export class ExportPatientsByPrescriberCommand extends BrowsePaging {
	medicalUserId!: string;

	constructor(init?: Partial<ExportPatientsByPrescriberCommand>) {
		super();
		Object.assign(this, init);
	}

	override generateUrlQuery(): string {
		const baseFragmentsUrl: string = super.generateUrlQuery();
		return `medicalUserId=${this.medicalUserId}&${baseFragmentsUrl}`;
	}
}
