import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TableFiltersList } from "src/app/@shared/types/table/table-view";
import { FilterTableColumnTypes } from "src/app/@shared/constants/filter-table-column-types.constant";
import { TableCommunicationService } from "src/app/@shared/services/table.communication.service";

@Component({
	selector: "app-table-header-display-filter",
	templateUrl: "table-header-display-filter.component.html",
})
export class TableHeaderDisplayFilterComponent {
	filterTableColumnTypes = FilterTableColumnTypes;

	@Input() columnName?: string;
	@Input() filters!: TableFiltersList;
	@Input() filterType?: FilterTableColumnTypes;
	@Input() isMobile!: boolean;

	@Output() readonly filterChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private _tableCommunicationService: TableCommunicationService) {}

	removeFilter(filterIndex: number): void {
		if (!this.filters || !this.columnName) return;

		this.filters.items.splice(filterIndex, 1);
		this.filterChange.emit(true);
		this._tableCommunicationService.resetFilters();

		this._tableCommunicationService.changeFilters(this.isMobile);
	}
}
