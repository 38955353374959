import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { AppConfigService } from "src/app/services/app-config.service";
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, startWith, switchMap } from "rxjs";
import { MessageType } from "src/app/constants/message-type.constant";
import { SearchPatientsItem, SearchPatientsResult } from "src/app/models/analyses/search-patient-result";
import { AuthService } from "src/app/services/auth.service";
import { Logger } from "src/app/services/logger.service";
import { AppConfig } from "src/app/types/app-config";
import { Sexes } from "src/app/constants/sexes.constant";
import { RespiratoryMedicalUsersService } from "src/app/services/respiratory/respiratory-medical-users.service";
import { ScyovaMedicalUsersService } from "src/app/services/scyova/scyova-medical-users.service";
import { ActivityAreas } from "src/app/constants";

const log = new Logger("ModalSearchComponent");

@Component({
	selector: "app-modal-search",
	templateUrl: "./modal-search.component.html",
})
export class ModalSearchComponent implements OnInit {
	private readonly authService = inject(AuthService);
	private readonly respiratoryMedicalUsersService = inject(RespiratoryMedicalUsersService);
	private readonly scyovaMedicalUsersService = inject(ScyovaMedicalUsersService);

	@Output() closed = new EventEmitter();

	messageForm!: FormGroup;
	isSpecificType = true;
	isAdmin = false;
	searchPatientActivityArea: string | null;
	appConfig!: AppConfig;

	//Dropdown list entities
	filteredPatients$!: Observable<SearchPatientsItem[]>;
	selectedPatient!: SearchPatientsItem;
	isPatientsSearchLoading = true;
	isPatientsSearchOnError = false;
	showSearchPatientsForm = true;

	searchPatientsForm = new FormGroup({
		familyName: new FormControl("", Validators.required),
		givenName: new FormControl("", Validators.required),
		patientDisplayName: new FormControl(""),
		patientId: new FormControl("", Validators.required),
		prescriptionId: new FormControl("", Validators.required),
	});

	onPreviewModeChange$: Observable<boolean> = this._appConfigService.onPreviewModeChange$;
	sexes = Sexes;

	constructor(
		private _appConfigService: AppConfigService,
		private _diag: MatDialogRef<ModalSearchComponent>,
		private _router: Router,
		private _fb: FormBuilder
	) {
		this.appConfig = _appConfigService.appConfig;

		this.filteredPatients$ = this.searchPatientsForm.controls["patientDisplayName"].valueChanges.pipe(
			startWith(""),
			debounceTime(400),
			distinctUntilChanged(),
			switchMap(value => this.filterSearchPatientsItems(value || ""))
		);

		this.searchPatientActivityArea = this.authService.user.activityArea;
		this.isAdmin =
			this.authService.user.isAdmin ||
			this.authService.user.isAdministrative ||
			this.authService.user.isCustomerRelationDepartment ||
			this.authService.user.isMedicalTechnicalAdvisor ||
			this.authService.user.isRegionalOrDevelopmentManager;
	}

	ngOnInit(): void {
		this.messageForm = this._fb.group({
			familyName: [],
			givenName: [],
			type: [MessageType.specific, Validators.required],
			patientDisplayName: [],
			patientId: ["", Validators.required],
			message: [],
		});
	}

	close(): void {
		this.closed.emit();
	}

	onPatientSelected(selected: SearchPatientsItem): void {
		this.selectedPatient = selected;
		this.searchPatientsForm.patchValue({
			patientId: selected.patientId,
			familyName: selected.familyName,
			givenName: selected.givenName,
			prescriptionId: selected.prescriptionId,
		});

		log.debug(`Patient selected: ${selected.givenName} ${selected.familyName} (${selected.patientId})`);
		this.closed.emit();
		this._diag.close();

		log.debug(`Navigate to /prescriber/patient-file/${selected.patientId}`);
		this._router.navigate([`/prescriber/patient-file/${selected.patientId}`]);
	}

	displayPatient(item: SearchPatientsItem): string {
		if (this.isAdmin)
			return item
				? `${item.givenName?.toTitleCase() ?? ""} ${item.familyName?.toUpperCase() ?? ""} (${item.patientId})`
				: "";
		return item ? `${item.givenName?.toTitleCase() ?? ""} ${item.familyName?.toUpperCase() ?? ""}` : "";
	}
	private filterSearchPatientsItems(value: string | SearchPatientsItem): Observable<SearchPatientsItem[]> {
		if (typeof value !== "string") {
			return of([]);
		}
		//reset patientId field
		this.searchPatientsForm.patchValue({
			patientId: "",
		});

		if (!value || value.length < 3) return of([]);

		this.isPatientsSearchLoading = true;
		this.isPatientsSearchOnError = false;

		const filterValue = value.toLowerCase();
		const medicalUserId: string = this.authService.user.currentMedicalUserId;

		return this.searchPatients$(medicalUserId, filterValue).pipe(
			map(searchResult => searchResult.items),
			catchError(() => {
				this.isPatientsSearchLoading = false;
				this.isPatientsSearchOnError = true;

				log.debug(`An error occurred while searching patients with the following terms: ${filterValue}`);
				return of([]);
			})
		);
	}

	onSubmit(): void {
		if (this.searchPatientsForm.invalid) return;

		log.debug(`Navigate to /prescriber/patient-file/${this.searchPatientsForm.value.patientId}`);
		this._router.navigate([`/prescriber/patient-file/${this.searchPatientsForm.value.patientId}`]);
	}

	private searchPatients$(medicalUserId: string, filterValue: string): Observable<SearchPatientsResult> {
		if (this.searchPatientActivityArea === ActivityAreas.scyova)
			return this.scyovaMedicalUsersService.searchPatientsByTerms(medicalUserId, filterValue);

		return this.respiratoryMedicalUsersService.searchPatientsByTerms(medicalUserId, filterValue);
	}
}
